import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticateODS, registerForBilling } from "../services/Api";
import { Spinner } from 'react-bootstrap';

const EbillingVerification = (props) => {
  const ErrorEmailBlank = "Please enter your E-mail Address."
  const ErrorEmailConfirmBlank = "Please confirm your E-mail Address."
  const ErrorEmailMatch = "Your E-mail Addresses do not match."
  const ErrorTerms = "Please accept the terms and conditions."
  const ErrorProcess =
    " We were unable to process your registration for Paperless Billing at this time. Please try again."
  const InfoPolicyNumberUsed =
    "The policy number you entered is already signed up for Paperless Billing. Please view your account online by logging in here."
  const ErrorPolicy =
    "We could not locate your policy with the information provided. Please try again."
  const regExEmail = /\S+@\S+\.\S+/

  const navigate = useNavigate()
  const { userData, setUserData } = props.user
  const { emailAddress, policyNumber, systemId, firstName, lastName, zip, env } = userData
  const [email, setEmail] = useState("")
  const [emailConfirm, setEmailConfirm] = useState("")
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState("")
  const [load, setLoad] = useState(false)

  const OnSubmit = async (e) => {
    e.preventDefault()
    const inputs = e.target.elements
    const { checked } = inputs
    if (email == "") {
      setError(ErrorEmailBlank)
      return
    } else if (emailConfirm == "") {
      setError(ErrorEmailConfirmBlank)
      return
    } else if (email != emailConfirm) {
      setError(ErrorEmailMatch)
      return
    } else if (!checked.checked) {
      setError(ErrorTerms)
      return
    } else if (await AuthenticateODS(policyNumber)) {
      setError(ErrorPolicy)
      return
    }

    const user = {
      emailAddress,
      lastName,
      policyNumber,
      systemId,
      firstName,
      zip,
      env
    }
    setLoad(true)
    var response = await registerForBilling(user)
    setLoad(false)
    if (response == "Registered Successfully") {
      setUserData({ ...userData, billingRegistered: true })
      navigate("../register-ebilling-finish")
      return
    } else if (response == "Already Registered") {
      setError(InfoPolicyNumberUsed)
      return
    }
  }

  return (
    <>
      <form onSubmit={OnSubmit}>
        <label className="d-flex">
          <div className="flex-column px-2">
            <input
              type="checkbox"
              name="checked"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </div>
          <p className="small flex-column fst-italic text-primary">
            I have read and agree to the terms and conditions outlined in the
            Agreement to Conduct Business Electronically. I have personally
            reviewed the Agreement to Conduct Business Electronically and
            represent that I am the person who is registering for this service.
            By providing the required information and clicking 'Register', I am
            agreeing that this registration process has not been completed by
            any other individual. Even if I have consulted with any such
            persons, I hereby declare that I have completed the registration
            process myself.
          </p>
        </label>
        <div className="small text-center">
          <h6 className="section-title">
            CONFIRM OR CHANGE THE EMAIL ADDRESS BELOW
          </h6>
          <small className="small text-muted">
            FIELDS MARKED WITH AN ASTERISK ARE REQUIRED
          </small>
          <div className="d-flex justify-content-center">
            <div className="">
              {error.length > 0 &&
                error != InfoPolicyNumberUsed &&
                error != ErrorProcess && (
                  <div className="text-alert container-fluid text-start bg-alert border rounded-3 p-3 m-2 mb-3 border-alert">
                    {error}
                  </div>
                )}
              {error == InfoPolicyNumberUsed && (
                <div class="alert alert-info text-center" role="alert">
                  <i class="fa fa-2x fa-info-circle pull-left"></i> The policy
                  number you entered is already signed up for Paperless Billing.
                  Please view your account online by{" "}
                  <a href="/" alt="My Account">
                    logging in here
                  </a>
                  .
                </div>
              )}
              {error == ErrorProcess && (
                <div class="alert alert-danger text-center" role="alert">
                  <i class="fa fa-warning"></i> We were unable to process your
                  registration for Paperless Billing at this time. Please try
                  again.
                </div>
              )}

              <div className="d-flex container-fluid pt-4">
                <div className="flex-column container-fluid align-self-start d-flex justify-self-start">
                  <small className="row">Email Address*</small>
                  <input onChange={(e) => { setEmail(e.target.value) }} className="row" type="email" name="email" />
                </div>
                <div className="flex-column container-fluid  align-self-end d-flex justify-self-end">
                  <small className="row">Confirm Email Address*</small>
                  <input onChange={(e) => { setEmailConfirm(e.target.value) }} className="row" type="email" name="emailConfirm" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <small className="small text-muted">
              NOTE: Your e-billing registration may take up to 48 hours to
              process.
            </small>
          </div>
          <div className="d-flex justify-content-center">
            {!load &&
              <input
                type="submit"
                className="btn btn-primary-grad"
                value="REGISTER FOR PAPERLESS BILLING"
              />
            }
            {load &&
              <div>
                <Spinner animation="border" />
              </div>
            }
          </div>
        </div>
      </form>
    </>
  )
}
export default EbillingVerification
