import React from 'react'
import "./UpdateNote.css"
import { useNavigate } from 'react-router-dom';
import { storageData } from 'services/Services';

const UpdateNote = () => {
    const navigate = useNavigate();

    // Removes changes for extra storage in local storage & navigates to home page
    const removeItems = () => {
        storageData.removeItem("updateVehicleData");
        storageData.removeItem("updateDriverAssignmentData");
        storageData.removeItem("newVehicles");
        storageData.removeItem("dbVehicles");
        storageData.removeItem("vehicleSummaryChanges");
        storageData.removeItem("VehicleChangeConfirmationNumber");
        storageData.removeItem("storedDrivers");
        storageData.removeItem("storedHouseholdMembers");
        storageData.removeItem("storedVehicles");
        storageData.removeItem("PendingAddedVehicles");
        storageData.removeItem("PendingRemovedVehicles");
        storageData.removeItem("PendingAddedDrivers");
        storageData.removeItem("PendingAddedHhms");
        storageData.removeItem("PendingRemovedDrivers");
        storageData.removeItem("PendingRemovedHhms");
        storageData.removeItem("PendingAddress");
        storageData.removeItem("vehicleInfo");
        storageData.removeItem("currResidence");
        storageData.removeItem("AddVehicleClass");
        storageData.removeItem("EndorsementChangeID");
        storageData.removeItem("responseData");
        navigate("/home");
    }
    return (
        <div className='container updateContainer'>
            <p className='text-center'>
                You can also view your updated declarations page, ID card, and billing information in My Account in the next 48 hours.
            </p>

            <p className='my-4 text-center text-primary fw-bold fs-5'>
                Drive well.
            </p>

            <div className="text-center mb-4">
                <input
                    type={"button"}
                    className="btn btn-primary-grad"
                    value={"Return to My Account Home"}
                    onClick={removeItems}
                />
            </div>

            <p className='text-center fst-italic fw-light note'>
                Note: Adjustments to your policy or account are subject to impact the bill amount due and due date.
                <br />
                <br />
                All policies and change requests are subject to underwriting review.
            </p>
        </div>
    )
}

export default UpdateNote