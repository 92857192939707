import { useNavigate } from "react-router-dom";
import { storageData } from "../../../services/Services";
import { Spinner } from 'react-bootstrap';
import { useContext, useState } from "react";
import { VehicleChangeContext } from "../VehicleChangeContext";
import { downloadTempIDCard } from "../../../services/Api";

const VehicleConfirmationMain = () => {
    const context = useContext(VehicleChangeContext)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const date = new Date();

    var vehiclesOnPolicy = context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy
    var storedVehicles = vehiclesOnPolicy?.storedVehicles
    var newVehicles = vehiclesOnPolicy?.newVehicles

    // Get Temp ID Card State
    const tempIDCardState = () => {
        var state = context?.userData?.state
        var obj = {
            "New Jersey": "TempIDCardVehicle_NJ",
            "Pennsylvania": "TempIDCardVehicle_PA",
            "Michigan": "TempIDCardVehicle_MI"
        }
        return obj[state]
    }

    // Downloads Temporary ID Card
    const downloadTempCard = async () => {
        setLoader(true);
        var vehicleChangeID = storageData.getItem("VehicleChangeID");
        var a = document.createElement("a");
        a.href = `data:application/octet-stream;base64,${await downloadTempIDCard(vehicleChangeID, tempIDCardState())}`;
        a.setAttribute("download", "TemporaryIDCard.pdf");
        a.click();
        setLoader(false);
    }

    // Adds whitespace to specific removals
    const displayRemovalReason = (reason) => {
        if (reason == "NotRunning") { return "Not Running" }
        else if (reason == "OtherPolicy") { return "Other Policy" }
        else if (reason == "CancelPolicy") { return "Cancel Policy" }
        else { return reason }
    }

    return (
        <>
            <div className="row px-2">
                <h4 className="text-center text-primary fw-bold">YOUR REQUEST HAS BEEN SUBMITTED.</h4><br /><br />
                <table style={{ width: "auto", margin: "0 auto" }}>
                    <tbody>
                        <tr>
                            <td className="fw-bold" style={{ paddingRight: "10px" }}>
                                Date:
                            </td>
                            <td>
                                {(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()}
                            </td>
                        </tr>
                        <tr>
                            <td className="small fst-italic" style={{ color: 'grey' }}>
                                NOTE: All changes are subject to review by underwriting.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="form-group mt-4">
                <div>
                    <h4 className="no-margin text-primary fw-bold">Overview</h4>
                    <p className="fs-6">The following vehicle change(s) have been requested:</p>
                </div>
                <div className="ms-5">
                    {/* Changes to STORED VEHICLES */}
                    <div className="form-group-sm">
                        {storedVehicles.map((vehicle, index) => {
                            return (
                                <div key={index}>
                                    {/* Replaced Vehicles */}
                                    {"requestedEffDt" in vehicle && "vehicleRemovalReason" in vehicle && "vehicleMake" in vehicle &&
                                        <>
                                            {/* Old vehicle */}
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                                    {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                                </strong>
                                                <em className="small" style={{ color: '#555555' }}>
                                                    {" — "} {vehicle?.vehicleRemovalReason == "Other" ? vehicle?.vehicleRemovalExplanation : displayRemovalReason(vehicle?.vehicleRemovalReason)}
                                                </em>
                                            </div>
                                            {/* Replaced Vehicle */}
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                                    {vehicle?.vehicleYear} {vehicle?.vehicleMake} {vehicle?.vehicleModel}
                                                </strong>
                                                <em className="small" style={{ color: '#555555' }}> — New Vehicle</em>
                                            </div>
                                        </>
                                    }
                                    {/* Removed Vehicles */}
                                    {"requestedEffDt" in vehicle && "vehicleRemovalReason" in vehicle && !("vehicleMake" in vehicle) &&
                                        <>
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                                    {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                                </strong>
                                                <em className="small" style={{ color: '#555555' }}>
                                                    {" — "} {vehicle?.vehicleRemovalReason == "Other" ? vehicle?.vehicleRemovalExplanation : displayRemovalReason(vehicle?.vehicleRemovalReason)}
                                                </em>
                                            </div>
                                        </>
                                    }
                                    {/* Modified Vehicles (MICHIGAN ONLY) */}
                                    {vehicle?.type == "modify" &&
                                        <>
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                                    {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                                </strong>
                                                <em className="small" style={{ color: '#555555' }}> — Modified</em>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    {/* NEW VEHICLES */}
                    <div className="form-group-sm">
                        {newVehicles.map((vehicle, index) => {
                            return (
                                <div key={index}>
                                    <div className="my-2">
                                        <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                            {vehicle?.vehicleYear} {vehicle?.vehicleMake} {vehicle?.vehicleModel}
                                        </strong>
                                        <em className="small" style={{ color: '#555555' }}> — New Vehicle</em>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div><br />
                {/* ID Cards */}
                {context?.userData?.state != "Michigan" &&
                    <div>
                        {/* {storedVehicles?.some(vehicle => vehicle?.vehicleRemovalReason == "CancelPolicy") ? null : ( */}
                        {(storedVehicles.some(vehicle => !vehicle?.vehicleRemovalReason) || storedVehicles.some(vehicle => vehicle?.type == "replace") || newVehicles.length > 0) && (
                            <>
                                <h4 className="no-margin text-primary fw-bold">ID Cards</h4>
                                {/* If ODS Login = 1 */}
                                {!loader &&
                                    <a className="fs-6" onClick={downloadTempCard}>Download Temporary Evidence Card</a>
                                }
                                {loader &&
                                    <div style={{ display: "flex" }}>
                                        <Spinner animation="border" />
                                    </div>
                                }

                                {/* If ODS Login = 0 */}
                                <p className="my-0 fs-6">An updated ID card has been sent to your email address.</p>
                            </>
                        )}
                    </div>
                }
            </div>
            <div className="text-center">
                <br /><br />
                <input type={"button"} className="btn btn-primary-grad" value={"Return to My Account Home"} onClick={() => {
                    navigate("/home");
                    // Removes changes for extra storage in local storage
                    storageData.removeItem("updateVehicleData");
                    storageData.removeItem("updateDriverAssignmentData");
                    storageData.removeItem("newVehicles");
                    storageData.removeItem("dbVehicles");
                    storageData.removeItem("vehicleSummaryChanges");
                    storageData.removeItem("VehicleChangeConfirmationNumber");
                    storageData.removeItem("storedDrivers");
                    storageData.removeItem("storedHouseholdMembers");
                    storageData.removeItem("storedVehicles");
                    storageData.removeItem("PendingAddedVehicles");
                    storageData.removeItem("PendingRemovedVehicles");
                    storageData.removeItem("PendingAddedDrivers");
                    storageData.removeItem("PendingAddedHhms");
                    storageData.removeItem("PendingRemovedDrivers");
                    storageData.removeItem("PendingRemovedHhms");            
                    storageData.removeItem("PendingAddress");
                    storageData.removeItem("vehicleInfo");
                    storageData.removeItem("currResidence");
                    storageData.removeItem("VehicleChangeClass");
                    storageData.removeItem("VehicleChangeID");
                }}
                />
                <br /><br />
            </div>
        </>
    )
}

export default VehicleConfirmationMain