const styles = {
    p: {
        display: "block",
        fontFamily: "Lato, sans-serif",
        margin: "0 0 10px",
        fontSize: 13
    },
    roundy: {
        height: "100px",
        width: "160px",
        float: "right"
    },
}
const VehicleDA_Directions = () => {

    return (
        <>
            <span className="p-1" style={styles.roundy} />
            <p className="col-lg-10 col-sm-8 col-6 col-xs-4 mt-4" style={styles.p}>
                <strong>Please read the following carefully:</strong>
                <br />
                For each driver, select the car that he or she operates on a regular basis. If a driver operates more than
                one car on a regular basis, select the car he or she drives the most. Do not select the same car for more
                than one driver.
            </p>
        </>
    )
}

export default VehicleDA_Directions