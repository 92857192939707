import React from "react";
import AddDriverMI from "../Drivers/MI/AddDriverMI";
import EditDriver from "../Drivers/MI/EditDriverMI"
import { EditMember, RemoveDriver } from "..";
import ValidationModal from "../../ValidationModal";
import { storageData } from "../../../services/Services";

class DriversSect extends React.Component {
    state = {
        showEditStored: [],
        showEditAdded: [],
        newDriver: [],
        pendingDrivers: [],
        hideBtn: false,
        hideBtnMI: false,
        editDriver: false,
        editOrAdd: 0,
        show: false,
        validationMsg: "",
        validationTitle: "",
        userData: "",
        insuredName: "",
        editDriverReason: {}
    };
    // Validation Modal
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    // Check Household Member Status
    checkMemberStatus = (e, i, driver) => {
        // Cancel member from driver list and add to household list
        var copiedState = this.props.currDrivers;
        copiedState[i].editDriverReason = this.props.editDriverReason
        if (e.target.value === "SAVE") {
            if (copiedState[i].editDriverReason.reason === "NODRIVE" && copiedState[i].editDriverReason.hhmObtainedCarInsurance === "1") {
                copiedState[i]["memberStatus"] = "Has Other Insurance";
            }
            else if (copiedState[i].editDriverReason.reason === "NODRIVE" && copiedState[i].editDriverReason.hhmObtainedCarInsurance === "0") {
                copiedState[i]["memberStatus"] = "No Longer Drives Policy Vehicle";
            }
        }
        else if (e.target.value === "CANCEL") {
            copiedState[i]["memberStatus"] = "";
            copiedState[i]["licenseNum"] = null;
        }
        delete copiedState[i].driverStatus;
        var copyEdit = [...this.props.currMemberEdit, copiedState[i]["memberStatus"]]
        var memberList = storageData.getItem('storedHouseholdMembers');
        var updatedMemberList;
        if (memberList) {
            updatedMemberList = JSON.parse(memberList);
        }
        updatedMemberList.push(copiedState[i]);
        storageData.setItem("storedHouseholdMembers", JSON.stringify(updatedMemberList));
        this.props.setCurrMembers(updatedMemberList)
        this.props.setCurrMemberEdit(copyEdit)

        // Removes driver from list
        var newDriverList = this.props.currDrivers.filter(driver => driver.fullName !== copiedState[i].fullName)
        this.props.setCurrDrivers(newDriverList)
        this.setState({ editOrAdd: 0, hideBtn: false });
        storageData.setItem("storedDrivers", JSON.stringify(newDriverList))
        this.props.firstSet("");
        storageData.removeItem('updatedAddedDrivers')
        storageData.removeItem('householdAdditionalData')
        return true
    }
    // Cancel/Edit Button (New Driver)
    editDriver = (e, i) => {
        if (e.target.value === "CANCEL") {
            var cancelArr = this.props.displayDrivers.map((value) => {
                return false;
            });
            this.setState({ showEditAdded: cancelArr, hideBtn: false, editOrAdd: 0 });
            this.props.firstSet("");
        }
        else if ((e.target.value === "EDIT") || (e.target.value === "SAVE")) {
            this.setState({ editOrAdd: 1 })
            var editArr = this.props.displayDrivers.map((value, index) => {
                return (i === index) ? true : false
            });
            if (e.target.value === "EDIT") {
                this.setState({ showEditAdded: editArr, hideBtn: true })
            }
            else {
                this.setState({ showEditAdded: editArr, hideBtn: false, editOrAdd: 0 })
            }
        }
    };
    // Editing an Existing Driver
    editExistingDriver = (e, i, driver) => {
        if (e.target.value === "EDIT") {
            this.props.addedEdit("")
            this.setState({ editOrAdd: 4 })
            var editArr = this.props.currDrivers.map((value, index) => {
                return (i === index) ? true : false
            });
            this.setState({ showEditStored: editArr, hideBtn: true })
        }
        else if (e.target.value === "CANCEL") {
            if (driver.driverStatus === "Adding As Driver") {
                if (this.checkMemberStatus(e, i, driver)) {
                    return
                }
            }
            var cancelArr = this.props.currDrivers.map((value) => {
                return false;
            });
            var currDriverCopy = this.props.currDrivers;
            var currDriverEditCopy = this.props.currDriverEdit;
            currDriverEditCopy[i] = ""
            currDriverCopy[i].driverStatus = ""
            if ("michiganReasonForRemoval" in currDriverCopy[i]) {
                delete currDriverCopy[i]["michiganReasonForRemoval"]
                delete currDriverCopy[i]["michiganRequestedEffectiveDate"]
            }
            this.props.setCurrDrivers(currDriverCopy)
            this.props.setCurrDriverEdit(currDriverEditCopy)
            this.setState({ showEditStored: cancelArr, hideBtn: false, editOrAdd: 0 });
            storageData.setItem('storedDrivers', JSON.stringify(this.props.currDrivers))
        }
    }
    // Clear Driver
    clear = (value) => {
        var drivers = this.props.displayDrivers;
        drivers = drivers.filter(item =>
            item !== value
        );
        this.props.newDrivers(drivers);
        storageData.setItem('newDrivers', JSON.stringify(drivers))
        storageData.removeItem('updatedAddedDrivers')
        storageData.removeItem('householdAdditionalData')
    }
    // Add Driver
    addNewDriver = () => {
        var drivers = storageData.getItem('newDrivers')
        var addedDriver;
        var showEditFalse
        if (drivers && drivers !== "[]") {
            drivers = JSON.parse(drivers)
            for (var i = 0; i < drivers.length; i++) {
                this.props.addedEdit(drivers[i].driverStatus);
                addedDriver = this.state.newDriver;
                addedDriver.push(drivers[i].driverStatus)
                showEditFalse = this.state.showEditAdded;
                showEditFalse.push(false)
            }
        }
        else {
            this.props.addedEdit("New Driver");
            addedDriver = this.state.newDriver;
            addedDriver.push("New Driver")
            showEditFalse = this.state.showEditAdded;
            showEditFalse.push(false)
        }
        this.setState({ newDriver: addedDriver, showEditAdded: showEditFalse });
        storageData.removeItem('updatedAddedDrivers')
        storageData.removeItem('householdAdditionalData')
    }
    // Handle Driver Toggle
    handleDriver = (value) => {
        (value === "remove") ? this.setState({ hideBtn: true, hideBtnMI: true, editOrAdd: 2 }) : this.setState({ hideBtn: true, hideBtnMI: false, editOrAdd: 2 });
    };
    // Save Edit
    saveEditDriver = (e, i, driver) => {
        var copiedState = this.props.currDrivers
        copiedState[i].editDriverReason = this.props.editDriverReason
        if (copiedState[i].editDriverReason.reason === "") {
            this.handleShow()
            this.setState({ validationMsg: "Please select what you would like to edit about the driver.", validationTitle: "Oops!" })
            return
        }
        if (copiedState[i].editDriverReason.reason === "NODRIVE") {
            if (copiedState[i].editDriverReason.hhmObtainedCarInsurance === "") {
                this.handleShow()
                this.setState({ validationMsg: "Please specify if the driver has obtained their own car insurance.", validationTitle: "Oops!" })
                return
            }
            else if (copiedState[i].editDriverReason.hhmObtainedCarInsurance === "1") {
                copiedState[i].driverStatus = "Has Other Insurance";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
            else if (copiedState[i].editDriverReason.hhmObtainedCarInsurance === "0") {
                copiedState[i].driverStatus = "No Longer Drives Policy Vehicle";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
        }
        else if (copiedState[i].editDriverReason.reason === "NOTVALID") {
            if (copiedState[i].editDriverReason.statusOfLicense === "") {
                this.handleShow()
                this.setState({ validationMsg: "Please select a reason why the driver's license is no longer valid.", validationTitle: "Oops!" })
                return
            }
            else if (copiedState[i].editDriverReason.statusOfLicense === "revoked") {
                copiedState[i].driverStatus = "License revoked";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
            else if (copiedState[i].editDriverReason.statusOfLicense === "suspended") {
                copiedState[i].driverStatus = "License suspended";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
            else if (copiedState[i].editDriverReason.statusOfLicense === "expired") {
                copiedState[i].driverStatus = "License expired";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
            else if (copiedState[i].editDriverReason.statusOfLicense === "surrendered") {
                copiedState[i].driverStatus = "License surrendered";
                // if (this.checkMemberStatus(e, i, driver)) {
                //     return
                // }
            }
        }
        else if (copiedState[i].editDriverReason.reason === "MOVED") {
            if (copiedState[i].editDriverReason.bringingVehicles === "") {
                this.handleShow()
                this.setState({ validationMsg: "Please specify if the driver is bringing policy vehicles to the new address.", validationTitle: "Oops!" })
                return
            }
            else if (copiedState[i].editDriverReason.bringingVehicles === "1") {
                if (copiedState[i].editDriverReason.vehiclesBrought.length === 0) {
                    this.handleShow()
                    this.setState({ validationMsg: "Please specify which policy vehicles the driver is bringing to the new address.", validationTitle: "Oops!" })
                    return
                }
                if (copiedState[i].editDriverReason.newAddress.street === "" || copiedState[i].editDriverReason.newAddress.city === "" || copiedState[i].editDriverReason.newAddress.state === "" || copiedState[i].editDriverReason.newAddress.zip === "") {
                    this.handleShow()
                    this.setState({ validationMsg: "Please specify the new address.", validationTitle: "Oops!" })
                    return
                }
                copiedState[i].driverStatus = "Removed from Policy"
            }
            else if (copiedState[i].editDriverReason.bringingVehicles === "0") {
                copiedState[i].driverStatus = "Resides Elsewhere";
            }
            if (copiedState[i].editDriverReason.moveDate === "") {
                this.handleShow()
                this.setState({ validationMsg: "Please specify when the driver moved.", validationTitle: "Oops!" })
                return
            }
        }
        else if (copiedState[i].editDriverReason.effectiveDate === "") {
            this.handleShow()
            this.setState({ validationMsg: "Please specify the requested effective date.", validationTitle: "Oops!" })
            return
        }

        this.props.setCurrDrivers(copiedState)
        var arr = this.props.currDrivers.map((value, index) => {
            return (i === index) ? this.props.currDrivers[index].driverStatus : this.props.currDriverEdit[index];
        });
        this.props.setCurrDriverEdit(arr)
        this.setState({ editOrAdd: 0, hideBtn: false });
        storageData.setItem('storedDrivers', JSON.stringify(this.props.currDrivers))
        storageData.removeItem("householdAdditionalData")
        this.props.firstSet("");
    };

    // Retrieves and returns status for display
    filterStatus = (status) => {
        if (status == "DriverSuspended") {
            return "Driver Suspended"
        }
        else if (status == "DriverRevoked") {
            return "Driver Revoked"
        }
        else if (status == "DriverMoved") {
            return "Driver Moved"
        }
        else if (status == "DriverOwnInsurance") {
            return "Driver Own Insurance"
        }
        else if (status == "OHMAddAsDriver") {
            return "OHM Added as Driver"
        }
        else if (status == "DriverRemoved") {
            return "Driver Removed"
        }
    }

    componentDidMount() {
        // Retrieves stored drivers
        const storedDrivers = storageData.getItem('storedDrivers')
        if (storedDrivers) {
            var retrievedStoredDrivers = JSON.parse(storedDrivers)
            var driverInfo = retrievedStoredDrivers.map((driver) => {
                return driver.driverStatus
            })

            // Retrieves any pending users
            var retrievedPendingAddedDriversMembers = storageData.getItem("PendingDrivers")
            var retrievedPendingRemovedDriversMembers = storageData.getItem("PendingRemovedDrivers")

            // Pending Drivers
            var pendingDriverArr = [];
            var pendingDriverInfo = {};

            if (retrievedPendingAddedDriversMembers || retrievedPendingRemovedDriversMembers) {
                retrievedPendingAddedDriversMembers = JSON.parse(retrievedPendingAddedDriversMembers);
                retrievedPendingRemovedDriversMembers = JSON.parse(retrievedPendingRemovedDriversMembers);
                var retrievedPendingDriversMembers = [...retrievedPendingAddedDriversMembers, ...retrievedPendingRemovedDriversMembers]

                for (var i = 0; i < retrievedPendingDriversMembers.length; i++) {
                    console.log(retrievedPendingRemovedDriversMembers[i])
                    var date = new Date(retrievedPendingDriversMembers[i].DOB);

                    // For Added Drivers
                    if (retrievedPendingDriversMembers[i]["SelectedType"] === "Driver"
                        && retrievedPendingDriversMembers[i]["FirstName"] !== ""
                        && (retrievedPendingDriversMembers[i]["Status"] == "NoChange" || retrievedPendingDriversMembers[i]["Status"] == "NewDriver")) {
                        pendingDriverInfo["fullName"] = retrievedPendingDriversMembers[i].FirstName;
                        pendingDriverInfo["mStatus"] = retrievedPendingDriversMembers[i].MaritalStatus;
                        pendingDriverInfo["gender"] = retrievedPendingDriversMembers[i].Sex;
                        pendingDriverInfo["DOB"] = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                        pendingDriverInfo["driverStatus"] = "ADD PENDING";
                        pendingDriverArr.push(pendingDriverInfo);
                    }

                    // For removed/changed drivers
                    if ((retrievedPendingDriversMembers[i]["SelectedType"] === "Driver" || retrievedPendingDriversMembers[i]["SelectedType"] === "Remove")
                        && retrievedPendingDriversMembers[i]["Status"] != "NoChange" && retrievedPendingDriversMembers[i]["Status"] != "NewDriver" && retrievedPendingDriversMembers[i]["Status"] != "OHMMoved") {
                        retrievedStoredDrivers = retrievedStoredDrivers.filter((driver) => {
                            return retrievedPendingDriversMembers[i].LicenseNumber != driver.licenseNum
                        });
                        var driverObj = {};
                        driverObj["fullName"] = retrievedPendingDriversMembers[i].FirstName
                        driverObj["mStatus"] = retrievedPendingDriversMembers[i].MaritalStatus;
                        driverObj["gender"] = retrievedPendingDriversMembers[i].Sex;
                        driverObj["DOB"] = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
                        driverObj["driverStatus"] = this.filterStatus(retrievedPendingDriversMembers[i].Status);
                        pendingDriverArr.push(driverObj);
                    }
                }
                this.setState({ pendingDrivers: [...pendingDriverArr] })
            }

            this.props.setCurrDrivers(retrievedStoredDrivers);
            this.props.setCurrDriverEdit(driverInfo)
            this.props.setStoredData(retrievedStoredDrivers)
        }
        // Retrieves added drivers
        const driverData = storageData.getItem('newDrivers')
        if (driverData) {
            var retrievedAddedDrivers = JSON.parse(driverData)
            this.props.newDrivers(retrievedAddedDrivers)
            var addedDriver = this.state.newDriver;
            for (var i = 0; i < retrievedAddedDrivers.length; i++) {
                addedDriver.push("New Driver")
                this.setState({ newDriver: addedDriver });
            }
            this.props.setStoredData(retrievedAddedDrivers)
        }
        // Retrieve user information
        var retrievedUserData = storageData.getItem("userData");
        if (retrievedUserData) {
            retrievedUserData = JSON.parse(retrievedUserData)
            this.setState({ userData: retrievedUserData, insuredName: retrievedUserData.firstName + " " + retrievedUserData.lastName })
        }
    }
    render() {
        const { showEditStored, showEditAdded, hideBtn, hideBtnMI, newDriver } = this.state;
        const editCancelToggle = (value) => {
            if ((value === 1) || (value === 2)) {
                return true;
            }
            else {
                return false;
            }
        }
        return (
            <>
                {/* Subtitle */}
                <div>
                    <br />
                    <h6 className="text-center no-margin household-text-blue fw-bold">
                        DRIVERS
                    </h6>
                    {/* Drivers */}
                    <p className="text-center form-group">
                        Our records indicate that these are the current driver(s) that
                        operate the policy vehicle(s):
                    </p>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8 col-md-8">
                            {/* Stored Drivers */}
                            {this.props.currDrivers.map((driver, index) => {
                                return (
                                    <div key={index}>
                                        <hr className="less-margin" />
                                        <div>
                                            {/* Displays Drivers */}
                                            {this.state.userData.state === "Michigan" &&
                                                <strong className="text-blue justify-content-left">
                                                    <span className="text-uppercase">{`${driver.fullName}, ${driver.DOB}`}</span>
                                                    {driver.driverStatus !== "Removed from Policy" ?
                                                        <span className={`fw-bold ${this.props.currDriverEdit[index]}`}>{this.props.currDriverEdit[index] === "" ? "" : ` - ${this.props.currDriverEdit[index]}`}</span>
                                                        :
                                                        <span className={`text-uppercase fst-italic`}> - {driver.driverStatus}</span>
                                                    }
                                                </strong>
                                            }
                                            {this.state.userData.state !== "Michigan" &&
                                                <strong className="text-blue justify-content-left">
                                                    <span className="text-uppercase">{`${driver.fullName}, ${driver.DOB}`}</span>
                                                    {driver.driverStatus !== "Adding As Driver" ?
                                                        <span className={`fw-bold ${this.props.currDriverEdit[index]}`}>{this.props.currDriverEdit[index] === "" ? "" : ` - ${this.props.currDriverEdit[index]}`}</span>
                                                        :
                                                        <span className={`text-uppercase fst-italic`}> - {driver.driverStatus}</span>
                                                    }
                                                </strong>
                                            }
                                            {/* Edit for all drivers that are not the insured name */}
                                            {driver.fullName.toLowerCase() !== this.state.insuredName.toLowerCase() &&
                                                <>
                                                    <button type="button" className={`btn btn-xs btn-default pull-right
                                                    ${this.state.editOrAdd === 4 ? "hide" : ""}`}
                                                        value={"EDIT"} onClick={(e) => { this.editExistingDriver(e, index) }}>
                                                        EDIT
                                                    </button>
                                                    <button type="button" className={`mx-1 btn btn-xs btn-default pull-right
                                                    ${(this.props.currDriverEdit[index] === "") || (this.state.editOrAdd === 4) || (this.props.currDriverEdit[index] === undefined) || (this.props.currDriverEdit.length === 0) ? "hide" : ""}`}
                                                        value={"CANCEL"} onClick={(e) => { this.editExistingDriver(e, index, driver) }}>
                                                        CANCEL
                                                    </button>
                                                </>
                                            }
                                            {(driver.driverStatus === "Adding As Driver") ?
                                                showEditStored[index] && this.state.editOrAdd === 4 &&
                                                <EditMember
                                                    vlQuestion1={this.props.vlQuestion1}
                                                    firstSet={this.props.firstSet}
                                                    vlQuestion2={this.props.vlQuestion2}
                                                    secondSet={this.props.secondSet}
                                                    addEdit={this.props.addEdit}
                                                    addedEdit={this.props.addedEdit}
                                                />
                                                :
                                                showEditStored[index] && this.state.editOrAdd === 4 &&
                                                <EditDriver
                                                    vlQuestion1={this.props.vlQuestion1}
                                                    firstSet={this.props.firstSet}
                                                    addEdit={this.props.addEdit}
                                                    addedEdit={this.props.addedEdit}
                                                    editDriverReason={this.props.editDriverReason}
                                                    setEditDriverReason={this.props.setEditDriverReason}
                                                    vehicleInfo={this.props.vehicleInfo}
                                                />
                                            }
                                            {showEditStored[index] && this.state.editOrAdd === 4 &&
                                                <div className="buttons">
                                                    <button type="button" className="btn btn-sm btn-default" value={"CANCEL"} onClick={(e) =>
                                                        this.editDriver(e, index)}>
                                                        CANCEL&nbsp;
                                                    </button>
                                                    <button type="button" className="btn btn-sm btn-primary-grad ms-1" value={"SAVE"} onClick={(e) =>
                                                        this.saveEditDriver(e, index, driver)}>
                                                        SAVE
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            {/* Pending Drivers */}
                            {this.state.pendingDrivers.map((driver, index) => {
                                return (
                                    <div key={index}>
                                        <hr className="less-margin" />
                                        <strong className="text-blue justify-content-left">
                                            <span className="text-uppercase">{driver.fullName}, {driver.DOB}</span>
                                            <span className={`text-uppercase fst-italic`}> - {driver.driverStatus}</span>
                                        </strong>
                                    </div>
                                )
                            })}
                            {/* Added Drivers */}
                            {this.props.displayDrivers.map((item, index) => {
                                return (
                                    <div>
                                        <hr className="less-margin" />
                                        <div>
                                            {/* Displays Drivers */}
                                            <strong className="text-blue justify-content-left">
                                                <span className="text-uppercase">{item.fullName}, {item.monthSelect}/{item.daySelect}/{item.yearSelect}</span>
                                                <span className={`text-uppercase fst-italic`}> - {newDriver[index]}</span>
                                            </strong>
                                            <span className={`${editCancelToggle(this.state.editOrAdd) ? 'hide' : ''}`}>
                                                <button className={`btn btn-xs btn-default pull-right 
                                                    ${showEditAdded[index] ? "hide" : ""}`} value={"EDIT"}
                                                    onClick={(e) => this.editDriver(e, index)}>
                                                    EDIT
                                                </button>
                                                <button className={`mx-1 btn btn-xs btn-default pull-right
                                                    ${showEditAdded[index] ? "hide" : ""}`} value={"CANCEL"}
                                                    onClick={() => this.clear(item)}>
                                                    CANCEL
                                                </button>
                                            </span>
                                        </div>
                                        {showEditAdded[index] && hideBtn && this.state.editOrAdd === 1 &&
                                            <AddDriverMI
                                                setChangeClass={this.props.setChangeClass}
                                                changeReqContext={this.props.changeReqContext}
                                                setAddDriver={this.props.setAddDriver}
                                                reqChangeClass={this.props.reqChangeClass}
                                                newDrivers={this.props.newDrivers}
                                                displayDrivers={this.props.displayDrivers}
                                                addNewDriver={this.addNewDriver}
                                                newDriver={newDriver}
                                                driverList={this.props.newDrivers}
                                                editDriver={this.state.editDriver}
                                                hideBtnFunc={(e) => { this.editDriver(e) }}
                                                index={index}
                                                vehicleInfo={this.props.vehicleInfo}
                                                userData={this.state.userData}
                                            />}
                                    </div>
                                )
                            })}
                            <hr className="less-margin expandHeight" />
                            {/* Add New Driver Button */}
                            <div className={`${hideBtn ? "hide" : ''}`}>
                                <div className="slide-toggle text-center">
                                    <button type="button" className="btn btn-primary-grad" onClick={() => this.handleDriver("add")}>
                                        Add New Driver
                                    </button>
                                </div>
                            </div>
                            {/* Add New Driver */}
                            {hideBtn && !hideBtnMI && this.state.editOrAdd === 2 &&
                                <AddDriverMI
                                    setChangeClass={this.props.setChangeClass}
                                    changeReqContext={this.props.changeReqContext}
                                    reqChangeClass={this.props.reqChangeClass}
                                    setAddDriver={this.props.setAddDriver}
                                    newDrivers={this.props.newDrivers}
                                    displayDrivers={this.props.displayDrivers}
                                    addNewDriver={this.addNewDriver}
                                    driverList={this.props.newDrivers}
                                    newDriver={newDriver}
                                    editDriver={this.state.editDriver}
                                    hideBtnFunc={(e) => { this.editDriver(e) }}
                                    index={this.props.displayDrivers.length}
                                    vehicleInfo={this.props.vehicleInfo}
                                    userData={this.state.userData}
                                />}
                            {hideBtn && hideBtnMI && this.state.editOrAdd === 2 && this.props.userData.state === "Michigan" &&
                                <RemoveDriver
                                    userData={this.state.userData}
                                    currDrivers={this.props.currDrivers}
                                    setCurrDrivers={this.props.setCurrDrivers}
                                    storedData={this.props.storedData}
                                    setStoredData={this.props.setStoredData}
                                    setCurrDriverEdit={this.props.setCurrDriverEdit}
                                    hideBtnFunc={() => { this.setState({ editOrAdd: 0, hideBtn: false }) }}
                                    insuredName={this.state.insuredName}
                                />}
                            <ValidationModal
                                show={this.state.show}
                                handleClose={this.handleClose}
                                validationMsg={this.state.validationMsg}
                                title={this.state.validationTitle} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default DriversSect
