import React, { useContext, useState } from "react";
import VehicleDA_VehiclePerDriver from "./VehicleSelectVehicle/VehicleDA_VehiclePerDriver";
import { storageData } from "services/Services";

export const VehicleDA_SaveContext = React.createContext()

const VehicleDA_SelectVehicle = ({ addVehReqContext, reqAddVehicleClass, setAddVehicleClass }) => {
    const context = useContext(addVehReqContext);
    const [drivers, setDrivers] = useState(context[reqAddVehicleClass]?.driverAssignment?.drivers); //driverList

    //Check that new vehicle is at the top of list.
    var combinedVehicles = [...context[reqAddVehicleClass]?.vehicles?.vehiclesOnPolicy?.newVehicles, ...context[reqAddVehicleClass]?.vehicles?.vehiclesOnPolicy?.storedVehicles]; //JSON.parse(localStorage.getItem("storedVehicles"));
    combinedVehicles = combinedVehicles.filter((vehicle, id) => vehicle?.type != "remove");
    const [allVehicles, setAllVehicles] = useState([...combinedVehicles]);

    // Adds count for clear button
    const checkForClearButton = () => {
        var vehicleClear = 0
        for (var i = 0; i < drivers.length; i++) {
            if (drivers[i]?.vehicle && "vehicle" in drivers[i]) { vehicleClear++ }
        }
        if (vehicleClear == drivers.length) { return true }
        return false
    }

    const [showClear, setShowClear] = useState(checkForClearButton())

    // Saves vehicle to driver
    const saveVehicleToDriver = (driverIndex, vehicle) => {
        var vehicleName = (vehicle == "NotPrimaryDriver") ?
            vehicle :
            ("vehicleMake" in vehicle) ?
                vehicle?.vehicleYear + " " + vehicle?.vehicleMake + " " + vehicle?.vehicleModel :
                vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model
        var driverListCopy = drivers
        driverListCopy[driverIndex]["vehicle"] = vehicleName
        driverListCopy[driverIndex]["vehicleVIN"] = ("vehicleVIN") in vehicle ? vehicle?.vehicleVIN : vehicle?.VIN

        setDrivers([...driverListCopy])

        var addVehicleClassCopy = context[reqAddVehicleClass]
        var vehicleDriversCopy = addVehicleClassCopy.driverAssignment.drivers
        vehicleDriversCopy = drivers
        addVehicleClassCopy.driverAssignment.drivers = vehicleDriversCopy

        setAddVehicleClass({ ...addVehicleClassCopy })
        storageData.setItem('AddVehicleClass', JSON.stringify(addVehicleClassCopy))
        setShowClear(checkForClearButton())
    }

    // Saves days per week / miles one-way
    const saveVehicleUse = (driverIndex, key, value) => {
        var driverListCopy = drivers
        driverListCopy[driverIndex][key] = value
        setDrivers([...driverListCopy])
    }

    // Clears all vehicle selected for each driver
    const clearVehicleSelections = () => {
        var driverListCopy = drivers
        for (var i = 0; i < driverListCopy?.length; i++) {
            delete driverListCopy[i]?.vehicle
            delete driverListCopy[i]?.vehicleVIN
        }

        setDrivers([...driverListCopy])
        setShowClear(false)
    }

    return (
        <>
            <div>
                {drivers.map((driver, driverIndex) => {
                    return (
                        <div key={driverIndex} className="pb-2 text-dark">
                            <strong className="text-primary">
                                <span className="text-uppercase">{driver.fullName}</span>
                            </strong>
                            <VehicleDA_SaveContext.Provider
                                value={{
                                    drivers, driverIndex,
                                    saveVehicleToDriver
                                }}
                            >
                                <VehicleDA_VehiclePerDriver vehicles={allVehicles} />
                            </VehicleDA_SaveContext.Provider>
                        </div>
                    )
                })}
                <br />
                {/* Clear button */}
                {showClear &&
                    <button className="btn btn-default" onClick={clearVehicleSelections}>
                        Clear
                    </button>
                }
            </div>
        </>
    )
}

export default VehicleDA_SelectVehicle