import Menu from "../components/Menu";
import Payment from "../components/Payment";
import Policy from "../components/PolicyLinks/Policy";
import HappyBirthday from "../components/HappyBirthday";
import Renewal from "../components/Renewal";
import RoundyContainer from "../components/RoundyContainer";
import { verifyLoginTkn } from "../services/Api";
import { Spinner } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { storageData } from "../services/Services";
import OptInModal from "../components/OptInModal";
import { useEffect, useState } from "react";
import SelfServiceMsg from "../components/SelfServiceMsg";
import MaintenanceMsg from "../components/MaintenanceMsg";


const Content = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6 pb-5">
                    <Payment {...props} />
                </div>
                <div className="col-12 col-md-6" >
                    <Policy {...props} />
                </div>
            </div>
        </>
    )
}

const ContentFooter = (props) => {
    const userData = props.userData

    // Birthday check
    var bday = new Date(userData.dob);
    var currDate = new Date();
    var bdayCheck = (bday.getMonth() + 1) === (currDate.getMonth() + 1) && bday.getDate() === currDate.getDate() ? true : false

    return (
        <>
            <div className="row">
                {bdayCheck &&
                    <HappyBirthday />
                }
                <div className="px-5 mt-1">
                    <Renewal {...props} />
                </div>
            </div>
        </>
    )
}

// Pendo script
const Pendo = (userData) => {
    window.pendo.initialize({
        visitor: {
            id: userData?.policyNumber,
            email: userData?.emailAddress,
            full_name: userData?.firstName + " " + userData?.lastName,
            env: userData?.env
        },
        account: {
            id: userData?.systemId,
            policy_number: userData?.policyNumber,
            email: userData?.emailAddress,
            full_name: userData?.firstName + " " + userData?.lastName,
            env: userData?.env
        }
    });
}

const Home = (props) => {
    //console.log("Home Screen:", props)
    const { userData, setUserData } = props.user
    const [showOptInModal, setShowOptInModal] = useState(false)
    const [complete, setComplete] = useState(userData?.complete)
    const location = useLocation()
    let params = new URLSearchParams(location.search);
    //console.log(location.state)

    useEffect(() => {
        // Shows Opt In/Out Pop Up
        if (!userData?.optedInOut && !storageData.getItem('optInOutAnswered')) {
            setShowOptInModal(true);
        }
        // Removes the disclosure modal field from userData
        if ("disclosureModal" in userData) {
            delete userData.disclosureModal;
            storageData.setItem("userData", JSON.stringify(userData));
        }
    }, [])

    // Initializes pendo
    useEffect(() => {
        checkAndCallPendo();

        return () => { };
    }, [complete, params, userData]);

    const checkAndCallPendo = () => {
        if (complete && !params.has("tkn")) {
            Pendo(userData);
            return;
        }

        // Continue checking on the next animation frame
        requestAnimationFrame(checkAndCallPendo);
    };

    const setupUser = async () => {
        if (!complete && params.has("tkn")) {
            storageData.setItem("token", params.get("tkn"))
            setComplete(true);
            var response = await verifyLoginTkn(params.get("tkn"), "")
            if (response.data.Result === "Success") {
                response.data.idCardData = response.data.idCardData.result
                response.data.renewalData = response.data.renewalData != null ? response.data.renewalData.result : ""
                setUserData({ ...response.data, login: "my-account", complete: true })
                window.location.href = "/home"
            }
            else {
                window.location.href = "/"
            }
        } else {
            window.location.href = "/"
        }
    }
    //console.log("complete:" + complete);
    if (!complete && params.has("tkn")) {
        setupUser()
    } else if (userData?.login == "quick-service") {
        window.location.href = "/quick-service"
    } else if (complete && userData.idCardData != null && params.has("tkn")) {
        window.location.href = "/home";
    }

    const InitContentFooter = () => (<ContentFooter userData={userData} />)

    return (
        <>
            {
                (complete && !params.has("tkn")) &&
                <>
                    <OptInModal userData={userData} showModal={showOptInModal} setShowOptInModal={setShowOptInModal} />
                    <Menu Title={"MY ACCOUNT"} FirstName={userData.firstName} userData={userData} />
                    <SelfServiceMsg userData={userData} />
                    <MaintenanceMsg />
                    <RoundyContainer params={props} Content={Content} ContentFooter={InitContentFooter} />
                </>
            }
            {
                (!complete || params.has("tkn")) &&
                // <Landing />
                <div style={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}>
                    <Spinner animation="border" />
                </div>
            }
        </>
    );
};

export default Home;