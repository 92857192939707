import StateDropdown from "../../../RenewalQuestionnaireComp/DriversOHM/StateDropdown"

const CurrentMailAddress = (props) => {
    const { context, michiganData, callback } = props
    var currMailAddress = context?.addressChangeClass?.policyAddressFields

    // Inputs saved to michigan data object
    const mailAddressInput = (key, value) => {
        if (key === "Street Address *") { callback("mailingAddress", value) }
        else if (key === "City *") { callback("mailingCity", value) }
        else if (key === "State *") { callback("mailingState", value) }
        else if (key === "ZIP *") { callback("mailingZip", value) }
    }

    // Provides the values to inputs
    const valueReturn = (value) => {
        if (value === "Street Address *") { return (context?.addressChangeClass?.newResidence?.mailingAddress) ? context?.addressChangeClass?.newResidence?.mailingAddress : michiganData?.mailingAddress }
        else if (value === "City *") { return (context?.addressChangeClass?.newResidence?.mailingCity) ? context?.addressChangeClass?.newResidence?.mailingCity : michiganData?.mailingCity }
        else if (value === "State *") { return (context?.addressChangeClass?.newResidence?.mailingState) ? context?.addressChangeClass?.newResidence?.mailingState : michiganData?.mailingState }
        else if (value === "ZIP *") { return (context?.addressChangeClass?.newResidence?.mailingZip) ? context?.addressChangeClass?.newResidence?.mailingZip : michiganData?.mailingZip }
    }

    return (
        <>
            <div className="justify-content-center row m-2 my-3">
                {/* Current Mailing Address */}
                <div className="mt-4">
                    <strong className="text-primary col-sm-6" style={{ fontSize: '14px' }}>Current Mailing Address: </strong>
                </div>
                <div style={{ fontSize: '14px' }}>
                    <p className="text-primary col-sm-6 m-0">{currMailAddress?.address1}</p>
                    <p className="text-primary col-sm-6 m-0">{currMailAddress?.city}, {currMailAddress?.state} {currMailAddress?.zip}</p>
                </div>

                {/* New Mailing Address */}
                <div className="mt-4">
                    <strong className="text-primary col-sm-6" style={{ fontSize: '14px' }}>New Mailing Address: </strong>
                </div>
                {["Street Address *", "City *", "State *", "ZIP *"].map((value, idKey) => {
                    return (
                        <div key={idKey} className="row align-items-center p-0">
                            {/* Street / City / Zip */}
                            <div className="col-12 col-sm-6">
                                <strong className="text-primary col-sm-6" style={{ fontSize: '.8em' }}>
                                    {value}
                                </strong>
                            </div>

                            {/* State */}
                            <div className="col-12 col-sm-5 col-md-5 my-2">
                                {(value !== "State *") ?
                                    <input className="col-12 btn btn-group btn-default" style={{ textAlign: "left" }}
                                        value={valueReturn(value)}
                                        onChange={(e) => mailAddressInput(value, e.target.value)}
                                    />
                                    :
                                    <select className="col-12 btn btn-group btn-default" style={{ textAlign: "left" }}
                                        value={valueReturn(value)}
                                        onChange={(e) => mailAddressInput(value, e.target.value)}>
                                        {StateDropdown()}
                                    </select>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default CurrentMailAddress