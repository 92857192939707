import { useEffect, useState } from "react"
import Drivers_RQ from "./Drivers_RQ"
import HHM_RQ from "./HHM_RQ"
import { storageData } from "../../../services/Services"
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import ValidationModal from "../../ValidationModal";

const DriverOHM_Main = () => {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [sessionData, setSessionData] = useState({})
    const [userData, setUserData] = useState({})
    const [show, setShow] = useState(false)
    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [validationLoad, setValidationLoad] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Retrieves userData
        var storedUserData = storageData.getItem('userData')
        if (storedUserData) {
            storedUserData = JSON.parse(storedUserData)
            setUserData(storedUserData)
        }

        // Retrieves sessionData
        var storedSessionDataRQ = storageData.getItem('sessionDataRQ')
        if (storedSessionDataRQ) {
            storedSessionDataRQ = JSON.parse(storedSessionDataRQ)

            // Retrieves stored drivers
            if (storedSessionDataRQ?.household?.drivers?.storedDrivers?.length == 0) {
                var storedDrivers = storageData.getItem("storedDrivers")
                if (storedDrivers && storedDrivers.length !== 0) {
                    storedDrivers = JSON.parse(storedDrivers)
                    storedSessionDataRQ.household.drivers.storedDrivers = storedDrivers
                }
            }

            // Retrieves stored household members
            if (storedSessionDataRQ?.household?.householdMembers?.storedHouseholdMembers?.length == 0) {
                var storedHouseholdMembers = storageData.getItem("storedHouseholdMembers")
                if (storedHouseholdMembers && storedHouseholdMembers.length !== 0) {
                    storedHouseholdMembers = JSON.parse(storedHouseholdMembers)
                    storedSessionDataRQ.household.householdMembers.storedHouseholdMembers = storedHouseholdMembers
                }
            }
            setSessionData({ ...storedSessionDataRQ })
        }

    }, [])


    const navigateToPage = (button) => {
        if (button == "back") {
            navigate("/renewalQuestionnaire/residence")
        }
        else {
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            navigate("/renewalQuestionnaire/additionalInfo")
        }
    }

    return (
        <>
            <ValidationModal
                show={show}
                handleClose={handleClose}
                validationMsg={validationMsg}
                title={validationTitle}
            />
            <div className="">
                {/* Drivers */}
                <Drivers_RQ
                    userData={userData}
                    sessionData={sessionData}
                    setSessionData={setSessionData}
                    setShow={setShow}
                    setValidationMsg={setValidationMsg}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    setValidationLoad={setValidationLoad}
                    setValidationTitle={setValidationTitle}
                />
                {/* Other Household Members */}
                <HHM_RQ
                    userData={userData}
                    sessionData={sessionData}
                    setSessionData={setSessionData}
                    setShow={setShow}
                    setValidationMsg={setValidationMsg}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    setValidationLoad={setValidationLoad}
                    setValidationTitle={setValidationTitle}
                />
            </div>
            {/* Next Button */}
            <div>
                <div className="pb-4 my-4">
                    {!load &&
                        <>
                            <input type="submit" value={""} className="backButton" onClick={() => navigateToPage("back")} />
                            <input type="submit" value={""} className="nextButton" onClick={() => navigateToPage("next")} />
                        </>
                    }
                    {load &&
                        <Spinner className='ms-3' animation="border" />
                    }
                </div>
            </div>
        </>
    )
}

// // Makes adjustments to the marital status of the drivers for the procedures
// const adjustValues = (sessionData, setSessionData) => {
//     var sessionDataCopy = sessionData
//     for (let i = 0; i < sessionDataCopy.household.drivers.storedDrivers.length; i++) {
//         sessionDataCopy.household.drivers.storedDrivers[i].mStatus = maritalStatusLabel(sessionDataCopy.household.drivers.storedDrivers[i].mStatus)
//     }
//     for (let i = 0; i < sessionDataCopy.household.drivers.newDrivers.length; i++) {
//         sessionDataCopy.household.drivers.newDrivers[i].mStatus = maritalStatusLabel(sessionDataCopy.household.drivers.newDrivers[i].mStatus)
//     }
//     for (let i = 0; i < sessionDataCopy.household.householdMembers.storedHouseholdMembers.length; i++) {
//         sessionDataCopy.household.householdMembers.storedHouseholdMembers[i].mStatus = maritalStatusLabel(sessionDataCopy.household.householdMembers.storedHouseholdMembers[i].mStatus)
//     }
//     for (let i = 0; i < sessionDataCopy.household.householdMembers.newHouseholdMembers.length; i++) {
//         sessionDataCopy.household.householdMembers.newHouseholdMembers[i].maintainAutoInsurance = maintainAutoInsurance(sessionDataCopy.household.householdMembers.newHouseholdMembers[i].maintainAutoInsurance)
//         sessionDataCopy.household.householdMembers.newHouseholdMembers[i].mStatus = maritalStatusLabel(sessionDataCopy.household.householdMembers.newHouseholdMembers[i].mStatus)
//     }
//     setSessionData({ ...sessionDataCopy })
// }

// // Marital Status Label
// const maritalStatusLabel = (status) => {
//     var objStatus = {
//         "Single (never been married)": "820",
//         "Single": "820",
//         "S": "820",
//         "Married": "830",
//         "M": "830",
//         "Widowed": "825",
//         "W": "825",
//         "Divorced": "832",
//         "D": "832",
//         "Separated": "836",
//         "SP": "836",
//         "X": "836",
//         "Civil Union": "837",
//         "C": "837",
//         "": "0",
//         null: "0"
//     }
//     return objStatus[status]
// }

// // Maintain Auto Insurance Label
// const maintainAutoInsurance = (label) => {
//     var objLabel = {
//         "Yes": "1",
//         "No": "0",
//         "Unknown": "2"
//     }
//     return objLabel[label]
// }

export default DriverOHM_Main