const Footer = (props) => {
    return (
        <>

            <div className="p-2 p-sm-0">
                <div className="d-sm-block">
                    <div className="p-5">

                    </div>
                    <div className="d-flex justify-content-center align-content-center bg-primary text-white container-fluid fixed-bottom ">
                        <div className="d-flex flex-row">
                            <p className="m-3 text-center copyright-text">
                                Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange).
                                <br />
                                All rights reserved.
                            </p>
                            <p className="m-3 text-center footer-text">Customer Service<br />800-535-2873</p>
                            <p className="m-3 text-center footer-text">Roadside Assistance<br />866-522-1991</p>
                            <p className="m-3 text-center footer-text">Report Claim<br />800-229-9151</p>
                            <p className="m-3 text-center footer-text">214 Carnegie Center, Suite 301<br />Princeton, NJ 08540</p>
                        </div>
                    </div>
                </div>
            </div>
            {props.user && <div style={{ position: "fixed", bottom: "1px",display: "flex", flexDirection: "row", gap: "20px", justifyContent: "start", alignItems: "center", background: "#ffffff94", color: "black",zIndex: 1030,height: "22px", padding: "11px 5px 0px 5px" }}>
                <p>Environment: {props.user.env}</p>
            </div>}
            
        </>
    )
}

export default Footer;