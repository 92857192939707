import React, { useContext, useRef, useState } from "react";
import { FormAuto, states } from "../..";
import ValidationModal from "../../../ValidationModal";
import { storageData } from "services/Services";
import EditDriverValidation from "components/ChangeRequest_AddressChangeComp/ValidationConditions/EditDriverValidation";

const EditDriver = (props) => {
    var { driverList, driver, index, changeReqContext, reqChangeClass, setChangeClass, saveResponse } = props
    const context = useContext(changeReqContext)
    var reqChangeClassCopy = context[reqChangeClass]
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"

    const [state, setState] = useState({
        editDriver: "",
        editDriverNested: "",
        ...driver
    })

    const validationMsg = useRef("")
    const validationTitle = useRef("")

    const callback = (key, value) => {
        var info = { ...state };
        info[key] = value;
        if (key == "editDriver") {
            info["editDriverNested"] = ""
        }
        setState({ ...info })
    }

    // Validation Modal
    const handleClose = () => setState({ ...state, show: false });
    const handleShow = () => setState({ ...state, show: true });

    // Sets Validation Message
    const handleValidationMsg = (msg, title) => {
        validationMsg.current = msg
        validationTitle.current = title
    }

    // Saves options for edited driver
    const saveEditOptions = (e) => {
        var editedDriver = state;
        if ((e.target.value) === "SAVE") {

            var check = EditDriverValidation({ editFields: editedDriver, handleValidationMsg, handleShow, userData: context.userData });
            if (check) {
                // Sets status of driver depending on edits
                editDriver = setEditedStatus(state)

                var changeClassCopy = reqChangeClassCopy
                var storedMemberListContextCopy = changeClassCopy?.driversHHMs?.drivers?.storedDrivers

                storedMemberListContextCopy[index] = state;

                setChangeClass(changeClassCopy)
                storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
            }
            else {
                return
            }
        }
        context?.setShowMemberEditMenu(false);
        context?.setShowDriverEditMenu(false);
        context?.setEditStoredMemberArr([])
        context?.setEditStoredDriverArr([])
    };

    // Edited status that will be displayed on UI
    const setEditedStatus = (driver) => {
        // No longer drivers policy vehicle
        if (driver?.editDriver == "No longer drives policy vehicles.") {
            // Resides elsewhere
            if (driver?.editDriverNested == "No longer resides in household and does not have access to the vehicles.") {
                driver.driverStatus = "Resides Elsewhere"
                driver["editType"] = "Resides Elsewhere"
            }
            // Has own insurance
            else {
                driver.driverStatus = "Has Own Insurance"
                driver["editType"] = "Has Own Insurance"
            }
        }
        // Invalid license
        else if (driver?.editDriver == "No longer has a valid driver's license.") {
            // License Suspended
            if (driver?.editDriverNested == "License was Suspended.") {
                driver.driverStatus = "License Suspended"
                driver["editType"] = "License Suspended"
            }
            // License Revoked
            else {
                driver.driverStatus = "License Revoked"
                driver["editType"] = "License Revoked"
            }
        }
        // Reset driver status
        else {
            driver.driverStatus = ""
        }
        return driver
    }

    var { editDriver, editDriverNested } = state

    return (
        <>
            {/* Edit Form */}
            <div className="slide-toggle edit-section form-group">
                <div className="editMember form-group form-group-sm">
                    {/* Driver Edit */}
                    <FormAuto
                        form={
                            [
                                // Type of edit
                                {
                                    id: "editDriver",
                                    type: "radio",
                                    label: "What would you like to edit about this driver?",
                                    value: editDriver,
                                    nested: {
                                        id: "editDriverNested",
                                        value: editDriverNested
                                    },
                                    options: [
                                        {
                                            value: "No longer drives policy vehicles.", id: "No longer drives policy vehicles.",
                                            nested: [
                                                { value: "No longer resides in household and does not have access to the vehicles.", id: "No longer resides in household and does not have access to the vehicles." },
                                                { value: "Obtained own car and own insurance.", id: "Obtained own car and own insurance." },
                                            ]
                                        },
                                        {
                                            value: "No longer has a valid driver's license.", id: "No longer has a valid driver's license.",
                                            nested: [
                                                { value: "License was Suspended.", id: "License was Suspended." },
                                                { value: "License was Revoked.", id: "License was Revoked." },
                                            ]
                                        }
                                    ],
                                    className: {
                                        margin: "my-0",
                                        marginBottom: "mb-2"
                                    },
                                    callback
                                }
                            ]
                        }
                    />
                </div>

                {/* Cancel/Save Function */}
                <hr className="less-margin my-3" />
                <div className="text-right my-3" >
                    <input type={"button"} className="btn btn-default btn-sm editCancelBtn" value={"CANCEL"} onClick={saveEditOptions} />
                    <input type={"button"} className="btn btn-primary-grad btn-sm ms-1 editCancelBtn" value={"SAVE"} onClick={saveEditOptions} />
                </div>
                <ValidationModal
                    show={state.show}
                    handleClose={handleClose}
                    validationMsg={validationMsg.current}
                    title={validationTitle.current} />
            </div>
        </>
    )
}

export default EditDriver