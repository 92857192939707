import { useContext, useState } from "react"
import EffectiveDateReq from "./MichiganPages/EffectiveDateReq";
import CurrentMailAddress from "./MichiganPages/CurrentMailAddress";
import VehicleMI_Questions from "./MichiganPages/VehicleMI_Questions";
import { AddressChangeContext } from "../AddressChangeContext";

const MichiganNewAddress = () => {
    const context = useContext(AddressChangeContext)

    // Michigan values for form
    const [michiganData, setMichiganData] = useState({
        michiganRequestedEffectiveDate: context.getTodaysDate(true),
        moveDate: "",
        reasonForChange: "",
        mailingAddress: "",
        mailingCity: "",
        mailingState: "MI",
        mailingZip: ""
    })

    // Retrieves Form Values
    const callback = (key, value) => {
        var changeClassCopy = context?.addressChangeClass
        var info = { ...michiganData };
        info[key] = value;
        setMichiganData({ ...info })
        changeClassCopy.newResidence = { ...changeClassCopy.newResidence, ...info }
        context.setAddressChangeClass({ ...changeClassCopy })
    }

    return (
        <>
            <EffectiveDateReq
                context={context}
                michiganData={michiganData}
                callback={callback}
            />
            <CurrentMailAddress
                context={context}
                michiganData={michiganData}
                callback={callback}
            />

            <div className="mx-4">
                <VehicleMI_Questions />
            </div>
        </>
    )
}

export default MichiganNewAddress