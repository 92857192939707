import { AddVehicleContext } from "components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext";
import { useContext, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { submitEndorsement } from "services/Api";
import { storageData } from "services/Services";

const VehicleBtnNav = () => {
    const navigate = useNavigate();
    var screen = window.location.pathname.split("/").pop();
    const addVehicleContext = useContext(AddVehicleContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formComponents = ["VehicleOwned", "VehicleLeased", "VehicleFinanced"]

    useEffect(() => {
        if (addVehicleContext.submitEndorsementResponse && !isSubmitting) {
            if (addVehicleContext.submitEndorsementResponse.error || addVehicleContext.submitEndorsementResponse.status >= 400) {
                // Redirect to thank you page if there's an error or the endorsement fails to post
                navigate("/endorsement/thank-you", {
                    state: { submitEndorsementResponse: addVehicleContext.submitEndorsementResponse }
                });
            } else if (addVehicleContext.submitEndorsementResponse.requestStatus === "Blocked") {
                navigate("/endorsement/block", {
                    state: { submitEndorsementResponse: addVehicleContext.submitEndorsementResponse }
                });
            } else {
                navigate("/endorsement/summary", {
                    state: { submitEndorsementResponse: addVehicleContext.submitEndorsementResponse }
                });
            }
        }
    }, [addVehicleContext.submitEndorsementResponse, navigate, isSubmitting]);

    // Next button component navigation
    const nextButtonNavigate = async () => {
        if (screen == "confirm") {
            if (!addVehicleContext?.addVehicleClass?.confirmation) {
                addVehicleContext.setValidationMsgToggle("Oops!", "Please certify that the answers to the questions listed in the change are true and accurate to the best of your knowledge to submit your request.")
                return;
            }
        }
        // Send endorsement class to backend
        var req = {
            "requestType": "Add Vehicle",
            "classRequest": storageData.getItem("AddVehicleClass")
        }
        setIsSubmitting(true);
        addVehicleContext?.setSubmissionLoad(true); // Display spinner and hide nav buttons

        // Api call to send data
        try {
            // Download request locally for test policies
            // if (addVehicleContext?.userData?.env == "uat" || addVehicleContext?.userData?.env == "model") {
            //     var a = document.createElement("a");
            //     a.href = 'data:attachment/text,' + encodeURI(JSON.stringify(req));
            //     a.setAttribute("download", "AddVehicleRequest.json");
            //     a.click();
            // }
            
            const response = await submitEndorsement(req);
            storageData.setItem("responseData", JSON.stringify(response.data));
            redirectWithRemoves(response.data?.requestStatus)
        } catch (error) {
            console.error("Error submitting endorsement:", error);
            addVehicleContext.setSubmitEndorsementResponse({ error: true, message: "An error occurred while submitting the endorsement." });
        } finally {
            setIsSubmitting(false);
            addVehicleContext?.setSubmissionLoad(false); // Hide spinner and display nav buttons
        }
    }

    // Removes last 2 pages from history stack to allow back button to home
    const redirectWithRemoves = (status) => {
        window.history.pushState(null, null, window.location.href);
        window.history.go(-2);
        window.history.replaceState(null, null, window.location.href);

        if (status == "Failed") {
            // window.history.go(-1);
            // window.history.replaceState(null, null, window.location.href);
            window.location.replace('/endorsement/thank-you')
        }
        else if (status == "Blocked") {
            window.location.replace('/endorsement/block')
        }
        else {
            window.location.replace('/endorsement/summary');
        }
    }

    // Updates add vehicle class and navigates BACK to driver assignment page
    const navigateBack = () => {
        // Go to back to form if selected "NO" for vehicle not driven by existing driver
        if (!formComponents.includes(addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles[0]?.componentTracker)) {
            updateComponentTracker()
        }
        window.history.go(-1)
        window.location.replace("/endorsement/add-vehicle")
    }

    // Updates the use-state for immediate re-render
    const updateComponentTracker = () => {
        var addVehicleClassCopy = addVehicleContext.addVehicleClass
        addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles[0]["componentTracker"] = "VehicleDriverAssignment"
        addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
        storageData.setItem("AddVehicleClass", JSON.stringify(addVehicleClassCopy))
    }

    return (
        <>
            <div className="mt-2 mb-3">
                {!addVehicleContext.submissionLoad &&
                    <div>
                        {/* Back Button */}
                        <button type="button" className="btn btn-default editCancelBtn" onClick={navigateBack}>
                            BACK
                        </button>
                        {/* Continue Button */}
                        <button type="submit" className="btn btn-primary-grad right pull-right editCancelBtn" onClick={() => { nextButtonNavigate() }}>
                            SUBMIT
                        </button>
                    </div>
                }
                {/* Spinner for retrieving vehicle info from vin */}
                {addVehicleContext.submissionLoad &&
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                }
            </div>
        </>
    )
}

export default VehicleBtnNav