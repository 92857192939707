import { useEffect, useState } from "react"

const AgreementContent = (props) => {
    const [agreementForm, setAgreementForm] = useState({
        conductBusinessElectronicallyTermsCondition: props?.sessionData?.agreements?.conductBusinessElectronicallyTermsCondition,
        date: "",
        fullName: "",
        paperlessBillingEmail: "",
        paperlessBillingResponse: true,
        termsAndConditionsRQ: ""
    })

    useEffect(() => {
        // Adds default email but can change if they decide to
        if (props?.userData?.emailAddress) {
            var sessionDataCopy = props.sessionData
            var agreementFormCopy = { ...props?.sessionData?.agreements }
            agreementFormCopy.paperlessBillingEmail = props?.userData?.emailAddress
            setAgreementForm({ ...agreementFormCopy })
            sessionDataCopy.agreements = agreementFormCopy
            props.setSessionData({ ...sessionDataCopy })
        }
    }, [props.userData])

    // Updates Agreement Form
    const updateAgreementForm = (key, value) => {
        // Regex for date
        if (key == "date") {
            value = value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')
        }
        var sessionDataCopy = props.sessionData
        var agreementFormCopy = agreementForm
        agreementFormCopy[key] = value
        setAgreementForm({ ...agreementFormCopy })
        sessionDataCopy.agreements = agreementFormCopy
        props.setSessionData({ ...sessionDataCopy })
    }

    return (
        <>
            <div className="row">
                <div>
                    <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                        <span>
                            Please acknowledge your review of the Agreement to Conduct Business Electronically
                        </span>
                    </label>
                    <p className="fw-bold my-2" style={{ fontSize: '13px' }}>
                        You MUST read and scroll to the bottom in order to acknowledge the Agreement to Conduct Business Electronically.
                    </p>
                </div>

                {/* Electronic Agreement */}
                <div className="ms-3 mt-2 terms-and-conditions" style={{ height: '100px' }}>
                    <ul className="p-0">
                        <div>
                            <label className="fw-bold pt-1" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    ELECTRONIC TRANSACTIONS:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                State law permits parties which have agreed to conduct transactions by electronic means to enter into an agreement online provided the requirements of the applicable laws are met. State law requires CURE to obtain your consent to electronically sign insurance application documents and to provide you with an electronic copy of your policy related documents.
                            </p>

                            <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    AN ELECTRONIC SIGNATURE:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                An electronic signature is an electronic sound, symbol, or process attached to or logically associated with a record that is executed or adopted by a person to demonstrate their intent to sign the record. When you decide to apply online for insurance coverage with CURE, you are required to electronically sign the following documents: an Agreement to Conduct Business Electronically, an Application, and a Power of Attorney.
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                You also have the option to obtain a copy of your application documents in a non-electronic format if you so choose. To receive paper copies of documents that are delivered electronically, or to withdraw consent to receive such documents electronically, please follow the instructions set forth below in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                Once coverage is bound, CURE will send you a Coverage Selection Form showing your coverage selections on which you may make any changes and/or corrections. For New Jersey Insureds, a Buyer's Guide, the Insurance Scenarios, and the Consumer Bill of Rights will also accompany the Coverage Selection Form.
                            </p>

                            <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    WITHDRAWAL OF CONSENT AND REQUEST FOR PAPER DOCUMENTS:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                You may withdraw your consent to obtain your application documents electronically or request paper copies of documents that are delivered electronically by sending a written request to CURE, Attention: Underwriting Department, 214 Carnegie Center, Suite 301, Princeton, New Jersey 08540.
                            </p>

                            <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    APPLICATION TO CURE BY TELEPHONE:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                If you have obtained a quote with CURE but do not want to apply for a policy using the electronic transaction option, please call us at 1-800-535-2873. Our representatives will be glad to assist you with your automobile insurance needs.
                            </p>

                            <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    HARDWARE AND SOFTWARE REQUIREMENTS FOR ACCESS TO AND RETENTION OF ELECTRONIC RECORDS:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                To access CUREs website and obtain electronic application documents, you must have the ability to access the Internet using a web browser such as Microsoft Edge, Google Chrome or Firefox. A valid e-mail address, Adobe Acrobat Reader and a printer are also required in order to receive, view, and print certain application documents. CURE uses modern encrypt protocols while accessing sensitive information during on-line transactions.
                            </p>

                            <label className="fw-bold" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    HARDWARE AND SOFTWARE REQUIREMENTS FOR ACCESS TO AND RETENTION OF ELECTRONIC RECORDS:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                To access CUREs website and obtain electronic application documents, you must have the ability to access the Internet using a web browser such as Microsoft Edge, Google Chrome or Firefox. A valid e-mail address, Adobe Acrobat Reader and a printer are also required in order to receive, view, and print certain application documents. CURE uses modern encrypt protocols while accessing sensitive information during on-line transactions.
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                Should CURE change hardware and software requirements for access to and retention of electronic records, such that the change creates a material risk that you will not be able to access or retain your subsequent electronic records (the subject of your prior consent,) CURE will provide you with a statement of:
                            </p>
                            <li className="fw-bold ms-5" style={{ fontSize: '10px' }}>The revised hardware and software requirements for access to and retention of your electronic records.</li>
                            <li className="fw-bold ms-5" style={{ fontSize: '10px' }}>Your right to withdraw consent without imposition of a fee for such withdrawal and without the imposition of any condition or consequence that was not disclosed above.</li>
                            <label className="fw-bold mt-2" style={{ color: '#046ab0', fontSize: '15px' }}>
                                <span>
                                    STATEMENT OF UNDERSTANDING:
                                </span>
                            </label>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                I understand that my application documents will be sent to me electronically if I consent to electronically sign my application. I agree to accept all other documents relating to my policy electronically provided they are not required by law to be mailed, with the knowledge that I may withdraw consent to receive those insurance documents electronically by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                I understand that I have the right to withdraw consent to receive my insurance application documents electronically, without imposition of a fee for such withdrawal and without the imposition of any condition or consequence that was not disclosed above, by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                I understand that I have the option to obtain a copy of my application documents in a non-electronic format by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."
                            </p>
                            <p className="fw-bold" style={{ fontSize: '10px' }}>
                                I understand that withdrawal of consent will terminate the online transaction which I have begun.
                            </p>
                        </div>
                    </ul>
                </div>

                {/* Agreement to Conduct Business Electronically */}
                <div className="ms-1 mt-3 col-12">
                    <label style={{ display: 'flex' }}>
                        <input type="checkbox"
                            defaultChecked={agreementForm.conductBusinessElectronicallyTermsCondition}
                            onChange={(e) => updateAgreementForm("conductBusinessElectronicallyTermsCondition", e.target.checked)} />
                        <span className="fw-bold my-2 ms-2" style={{ fontSize: '13px' }}>
                            I agree to the terms and conditions outlined in the Agreement to Conduct Business Electronically.
                        </span>
                    </label>
                </div>
                {/* Agreement to Register for Paperless Billing */}
                <div className="ms-1 col-12">
                    <label style={{ display: 'flex' }}>
                        <input type="checkbox"
                            defaultChecked={agreementForm.paperlessBillingResponse}
                            onChange={(e) => updateAgreementForm("paperlessBillingResponse", e.target.checked)} />
                        <span className="fw-bold my-2 ms-2" style={{ fontSize: '13px' }}>
                            I agree to register for Paperless Billing. When registered for Paperless Billing, you will receive a courtesy email whenever new bills are available on cure.com. You will no longer receive the bills through the mail.
                        </span>
                    </label>

                    {/* Email Address to Receive Bills */}
                    <div className="row ms-1">
                        <p className="fw-bold my-2 ms-1 col-4" style={{ fontSize: '13px' }}>Email Address to receive bills:</p>
                        <input type="text" className="textFullName col-6" placeholder="Email Address" maxLength={100}
                            value={agreementForm.paperlessBillingEmail} onChange={(e) => updateAgreementForm("paperlessBillingEmail", e.target.value)} />
                    </div>
                </div>

                {/* Content Acknowledgement */}
                <div className="ms-3 mt-2 terms-and-conditions" style={{ height: '100px' }}>
                    <p className="fw-bold mt-2" style={{ fontSize: '10px' }}>
                        I hereby declare that the statements on this questionnaire are true. I acknowledge that the submission of complete and accurate information to CURE is necessary for proper underwriting and rating of my renewal application. I affirm that I am a resident of {props.userData.state}. I understand that I am eligible to be a subscriber/policyholder with CURE only if I remain a resident of the State of {props.userData.state}. I agree that when or if I no longer meet this requirement my reciprocal insurance contract with be invalid.
                    </p>
                    <p className="fw-bold" style={{ fontSize: '10px' }}>
                        I acknowledge that only members who currently reside in my household are listed on this questionnaire, and if any additional person(s) become new residents of my household, I will notify CURE in writing prior to such time.
                    </p>
                    <p className="fw-bold" style={{ fontSize: '10px' }}>
                        Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.
                    </p>
                </div>

                <p className="fw-bold my-2 ms-1" style={{ fontSize: '13px' }}>
                    At the next renewal, CURE will send you a renewal application questionnaire, which must be completed and returned. Failure to complete and return that renewal application questionnaire may result in the non-renewal of the policy.
                </p>

                {/* Agreement to Terms and Conditions */}
                <div className="ms-1 col-12">
                    <label style={{ display: 'flex' }}>
                        <input type="checkbox"
                            checked={agreementForm.termsAndConditionsRQ}
                            onChange={(e) => updateAgreementForm("termsAndConditionsRQ", e.target.checked)}
                        />
                        <span className="fw-bold my-2 ms-2" style={{ fontSize: '13px' }}>
                            I agree to the terms and conditions noted above.
                        </span>
                    </label>
                </div>

                <div>
                    {/* Full Name */}
                    <input type="text" className="textFullName col-12 me-2" placeholder="Full Name" maxLength={100}
                        value={agreementForm.fullName}
                        onChange={(e) => updateAgreementForm("fullName", e.target.value)}
                    />

                    {/* Date */}
                    <input type="text" className="textFullName col-12 ms-2" placeholder="Date" maxLength={10}
                        value={agreementForm.date}
                        onChange={(e) => updateAgreementForm("date", e.target.value)}
                    />
                </div>
            </div>
        </>
    )
}

export default AgreementContent