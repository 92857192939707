import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkPBTStatus, getAutoPayStatus, updateOneIncTokens } from "../services/Api";
import { getDomainOneInc } from "../services/Utils";

const ErrorPayoffNegative = "Your policy is paid in full. No payment required."

const Payment = (props) => {
    // console.log("Payment Component:", props)
    const { userData, setUserData } = props.user
    const { paymentData, policyNumber, zip, env } = userData
    const [enrollStatus, setEnrollStatus] = useState({ autoPay: userData.autoPay, textToPay: false, completed: false })
    const [error, setError] = useState('')
    // const windowSize = useWindowSize()
    // const isSmallDesktop = windowSize.width < 768;
    const [complete, setComplete] = useState(enrollStatus.completed)
    const [paymentToken, setPaymentToken] = useState("")
    const [autopayToken, setAutopayToken] = useState("")
    const [notificationsToken, setNotificationsToken] = useState("")

    var duedt = (parseFloat(paymentData.totalamt) <= 0) ? "No Balance Due" : FormatDate(paymentData.duedt)
    var withdrawaldt = FormatDate(paymentData.duedt)
    var Bln = FormatValue(paymentData.totalamt)
    var Amt = FormatValue(paymentData.openamt)
    var totalamt = FinalValue(Bln)
    var openamt = FinalValue(Amt)
    var dt

    dt = new Date(withdrawaldt.replace(/-/g, "/"))
    withdrawaldt = FormatDate((dt.setDate(dt.getDate() - 1)))
    var stg = (env == "uat") ? "stg" : ""
    const MakePayment = () => {
        if (Amt.includes('-')) {
            setError(ErrorPayoffNegative)
        } else {
            if (paymentToken !== "") {
                window.location.replace(`https://${getDomainOneInc()}/?Token=${paymentToken}`)
            }
        }
    }
    const AutoPay = () => { if (autopayToken !== "") { window.location.replace(`https://${getDomainOneInc()}/?Token=${autopayToken}`) } }
    const TextToPay = () => { if (notificationsToken !== "") { window.location.replace(`https://${getDomainOneInc()}/?Token=${notificationsToken}`) } }
    const PaymentsSite = () => { window.open(`https://${getDomainOneInc()}/?ShowOptions=1`, '_blank') }
    
    const getEnrollmentStatus = async () => {
        var newEnrollStatus = { ...enrollStatus }

        //Text to pay
        var responsePBT = await checkPBTStatus(policyNumber, env, userData.token)
        if (responsePBT[0]?.ResultCode === "Success" || responsePBT?.ResponseCode === "Success") {
            var textToPay = responsePBT[0].TextSubscriptionStatus === "OptedIn"
            newEnrollStatus = { ...newEnrollStatus, textToPay }
        }

        //Auto pay
        var autoPay = await getAutoPayStatus(policyNumber, env)
        if (autoPay !== userData.autoPay) {
            newEnrollStatus = { ...newEnrollStatus, autoPay }
            setUserData({ ...userData, autoPay })
        }
        setEnrollStatus({ ...newEnrollStatus, completed: true })
    }
    const checkOneIncTokens = async () => {
        let paymentToken
        let autopayToken
        let notificationsToken

        const updateOneIncTokens_response = await updateOneIncTokens(policyNumber, zip, env, ["payment", "autopay", "notifications"], "MyAccount")
        paymentToken = updateOneIncTokens_response.Tokens.find(x => x.Type === "payment").Value
        autopayToken = updateOneIncTokens_response.Tokens.find(x => x.Type === "autopay").Value
        notificationsToken = updateOneIncTokens_response.Tokens.find(x => x.Type === "notifications").Value

        setPaymentToken(paymentToken)
        setAutopayToken(autopayToken)
        setNotificationsToken(notificationsToken)
    }
    if (!complete) {
        setComplete(true);
        getEnrollmentStatus()
    }

    useEffect(() => {
        checkOneIncTokens()
    }, [])

    return (
        <>
            <div className="d-flex align-content-center flex-column">
                <h4 className="text-center section-title">
                    BILLING & PAYMENT CENTER
                </h4>
                <div className="d-flex flex-column align-content-center my-4 py-2 border-bottom ">
                    <table className="align-content-start payment-table container-fluid">
                        <tbody>
                            <tr>
                                <td className="text-primary text-end">Amount Due:</td>
                                <td>&emsp;
                                    {(openamt == "No payment due." || openamt == "$0.00") ?
                                        <em>No payment due.</em>
                                        : <>{openamt}</>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-primary text-end">Date Due:</td>
                                <td>&emsp;{duedt}</td>
                            </tr>
                            <tr>
                                <td className="text-primary text-end">Total Due:</td>
                                <td>&emsp;{(totalamt == "$0.00") ? "N/A" : totalamt}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="border border-danger my-4 p-2 payment-message">
                        <strong>Not seeing your recent payment?</strong>

                        <p>Allow 2-3 business days for your payment to show.</p>
                    </div>
                    <a onClick={MakePayment}>
                        <button className="btn btn-primary-grad container-fluid">MAKE A PAYMENT</button>
                    </a>
                    <div className="container-fluid text-center pt-2 small">
                        <small className="small-blue-text">
                            A third party convenience fee, charged by One Inc, of $14.95 will be added to your online payment.
                            For more information about One Inc, visit <a style={{textDecoration:'underline'}} href='https://www.oneinc.com/' target='_blank'>oneinc.com</a>.
                            <br />
                            Looking for other payment options? <a style={{textDecoration:'underline'}} onClick={PaymentsSite}>Click here</a>
                        </small>
                    </div>
                    <div className="container-fluid text-center pt-1 pb-2 small">
                        {error && <div><small className="text-danger">{error}</small></div>}
                    </div>

                    <small className="d-flex ms-5 justify-content-center">
                        <small className="d-flex ms-sm-5 ms-md-0 ms-lg-0 ms-xl-5">
                            <table>
                                <tbody>
                                    {(enrollStatus.autoPay) ?
                                        <>
                                            <tr>
                                                <th className="text-start">Autopay Status:&emsp;Enrolled</th>
                                                {(userData.state != 'Michigan' || enrollStatus.autoPay == "oneinc") &&
                                                    <td>
                                                        <a onClick={AutoPay}>&emsp;Edit</a>
                                                    </td>
                                                }
                                            </tr>
                                            {userData.state == "Michigan" && enrollStatus.autoPay == "gw" &&
                                                <tr className="text-start">
                                                    <td colSpan="2"> Call to change payment method or unenroll </td>
                                                </tr>
                                            }

                                            {(userData.state != 'Michigan' || enrollStatus.autoPay == "oneinc") && paymentData.duedt != "N/A" &&
                                                <tr>
                                                    <th className="text-start"><a>Withdrawal Date:</a>
                                                        <a href="#" >&emsp;{withdrawaldt}</a>
                                                    </th>
                                                    <td>
                                                    </td>
                                                </tr>
                                            }
                                        </> : 
                                        <>
                                            <tr>
                                                <th className="text-start" >Enroll in Autopay:
                                                    <a onClick={AutoPay} >&emsp;&emsp;Enroll Now!</a>
                                                </th>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        {(enrollStatus.textToPay) ?
                                            <>
                                                <th className="text-start">Text to Pay Status:&emsp;Enrolled</th>
                                                <td>
                                                    <a onClick={TextToPay}>&emsp;Edit</a>
                                                </td>
                                            </> : 
                                            <>
                                                <th colSpan="2" className="text-start">Enroll in Text to Pay:
                                                    <a onClick={TextToPay}>&emsp;Enroll Now!</a>
                                                </th>
                                            </>
                                        }
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-start">
                                            A third party convenience fee, 
                                            charged by One Inc, of $5.99 will 
                                            be added to your Text to Pay payment.
                                            For more information about One Inc, 
                                            visit <a style={{textDecoration:'underline'}} href='https://www.oneinc.com/' target='_blank'>oneinc.com</a>.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </small>
                    </small>
                </div>
                <div>
                    {
                        (userData.billingRegistered) ? (

                            <small>
                                <div className="row p-3 m-1 border rounded bg-success border-success text-success small">
                                    <i aria-hidden="true" className="fa fa-2x fa-leaf text-center "></i>
                                    <strong className="text-center">
                                        You are currently enrolled in paperless billing.
                                    </strong>
                                </div>
                            </small>
                        ) : (
                            <>
                                <div className="text-center">
                                    You are <span className="fw-bold">NOT</span> currently enrolled in paperless billing.
                                </div>
                                <div className="d-flex justify-content-center">

                                    <Link to="/register-ebilling">
                                        <button className="btn btn-primary-grad m-2">GO PAPERLESS!</button>
                                    </Link>
                                </div>
                            </>
                        )
                    }
                </div>

            </div>
        </>
    )
}

const FormatValue = (value) => {
    if (value === 'N/A' || value === 'No payment due.')
        return value
    var regex = /[+-]?[0-9]+\.?[0-9]*/g

    var valueString = regex
        .exec(value.replace(",", ""))[0]
    valueString = parseFloat(valueString)
        .toString()
    var newValue = valueString
    if (!valueString.includes('.')) {
        newValue = valueString + '.00'
    } else {
        newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
    }
    return newValue
}
const FinalValue = (value) => {
    if (value !== 'N/A' && value !== 'No payment due.')
        return "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return value
}

const FormatDate = (tempDate) => {
    if (tempDate === null || tempDate === 'N/A' || tempDate === 'No Payment Due' || tempDate.toString().includes('Installment to generate'))
        return 'N/A'
    tempDate = new Date(tempDate)
    var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
    var dateDay = `${(tempDate.getDate()) < 10 ? '0' : ''}${tempDate.getDate()}`
    var dateYear = `${tempDate.getFullYear()}`
    const date = `${dateMonth}-${dateDay}-${dateYear}`
    return date

}

export default Payment;
