import './HelpfulDocumentsPA.css'

const HelpfulDocumentsPA = () => {
  return (
    <>
        <hr className='helpful-divider' />
        <div className="fw-bold">
            Helpful Documents for Reference:
            <br/>
            <div className="helpful-links-container">
                <a href="https://assets.cure.com/pdf/forms/PA-PAC-CSF.pdf " className='helpful-pa-link' target="_blank">
                    PA Coverage Selection Form
                </a>
                <br/>
                <a href="https://assets.cure.com/pdf/forms/PA-UMO.pdf" className='helpful-pa-link' target="_blank">
                    Uninsured Motorist Coverage Options Form
                </a>
                <br/>
                <a href="https://assets.cure.com/pdf/forms/PA-UIM.pdf" className='helpful-pa-link' target="_blank">
                    Underinsured Motorist Coverage Options Form
                </a>
            </div>
        </div>
    </>
  )
}

export default HelpfulDocumentsPA