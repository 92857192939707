import React from 'react'
import UpdateHeader from './3_Summary/1_UpdateHeader/UpdateHeader'
import UpdateNote from './3_Summary/3_UpdateNote/UpdateNote'
import UpdateBody from './3_Summary/2_UpdateBody/UpdateBody'

const EndorsementSummaryMain = () => {
  return (
    <>
      <UpdateHeader />
      <hr />
      <UpdateBody />
      <hr />
      <UpdateNote />
    </>
  )
}

export default EndorsementSummaryMain