import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ValidationModal = (props) => {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton style={{ backgroundColor: "#366898" }}>
                    <Modal.Title className='text-light' style={{fontSize: "16px"}}>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='small'>{props.validationMsg}</Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary-grad' onClick={props.handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ValidationModal
