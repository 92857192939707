import React, { useContext, useState } from 'react';
import DatePick from 'components/DatePicker';

const DateOfChange_NJPA = ({ changeReqContext, reqChangeClass, setChangeClass }) => {
    var context = useContext(changeReqContext)
    const [dateOfChange, setDateOfChange] = useState("")

    const storeValue = (key, value) => {
        var changeClassCopy = context[reqChangeClass]
        changeClassCopy.driversHHMs[key] = value
        setChangeClass({ ...changeClassCopy })
    }

    return (
        <>
            <div className="form-group form-group-sm text-center mt-3">
                <strong className="text-primary">
                    On what date did these drivers/household members change?
                </strong>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-5 col-sm-6 col-xs-6">
                    <div className="form-group text-center">
                        <DatePick
                            dateSubmit={context[reqChangeClass]?.driversHHMs?.dateOfChange ? context[reqChangeClass]?.driversHHMs?.dateOfChange : ""}
                            setDateSubmit={setDateOfChange}
                            storeValue={storeValue}
                            nameValue={"dateOfChange"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DateOfChange_NJPA