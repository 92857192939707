import React from "react";
import ReactDatePicker from "react-datepicker";

class EffectiveDate extends React.Component {
    constructor(props) {
        super(props);
    }

    effectiveDate = (date) => {
        var dateMax = new Date()
        var dateMin = new Date()
        dateMin.setHours(23, 59, 59, 999)
        dateMax.setDate(dateMax.getDate() + 30)
        if (dateMin.getTime() > date.getTime()) {
            date = new Date()
            date.setDate(date.getDate() + 1)
        } else if (dateMax.getTime() < date.getTime()) {
            date = dateMax
        }
        var datePassed = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        return datePassed
    }
    render() {
        return (
            <>
                {/* Effective Date */}
                <div className="row form-group form-group-sm align-items-center">
                    <strong className="text-primary">Will all vehicles on the policy be garaged at this new address overnight?*</strong>
                </div>
                {/* Effective Date response */}
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-sm-12">
                        <ReactDatePicker
                            value={this.props.effectiveDate}
                            placeholderText="MM/DD/YYYY"
                            className="text-sm-start fw-light col-sm-8 col-12 btn btn-default btn-group mt-1"
                            selected={(this.props.effectiveDate !== "") ? new Date(this.props.effectiveDate) : new Date()}
                            onChange={(e) => this.props.handleCallBack("dateMoved", this.effectiveDate(e))}
                            onKeyDown={(e) => { e.preventDefault() }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default EffectiveDate