import { useState } from "react";
import Menu from "../components/Menu";
import RoundyContainer from "../components/RoundyContainer";
import { getPolicyDocumentsByDocID, getPolicyDocumentsByState } from "../services/Api";
import ValidationModal from "../components/ValidationModal";

const dropListLabel = {
    marginTop: '8px',
    marginBottom: '0px',
    width: "70%"
}

const Content = (props) => {
    const [documentList, setDocumentList] = useState([])
    const [formList, setFormList] = useState([])
    const [displayRest, setDisplay] = useState(false);
    const [doc, setDoc] = useState({});
    const [docName, setDocName] = useState("default");

    const [show, setShow] = useState(false);
    const [validationMsg, setValidationMsg] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const policyDocDisplay = (docs) => {
        if (new Date(props.user.userData.idCardData[0].effectivedt) < new Date()) {
            docs = docs.filter(doc => {
                doc.versionName = doc.versionName.replace("-Final", "")
                return doc.versionName == "MIC0770720"
            })
        }
        else {
            docs = docs.map(doc => {
                doc.versionName = doc.versionName.replace("-Final", "")
                return doc
            })
        }
        return docs
    }

    const onStateChange = async (evt) => {
        const state = evt.target.value
        const response = await getPolicyDocumentsByState(state)
        if (!response.error) {
            setDocumentList(response.result)
        }
        setDisplay(true);
    }
    const onDocumentChange = async (evt) => {
        const docID = evt.target.value
        var response = await getPolicyDocumentsByDocID(docID)
        if (props.user.userData.state == "Michigan" && docID == "65") {
            response.result = policyDocDisplay(response.result)
        }
        if (!response.error) {
            setDocName("default")
            setFormList(response.result)
            setDoc({})
        }
    }
    const QueueDocument = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        const obj = {
            file: optionElement.getAttribute('pdf'),
            name: (optionElement.getAttribute('filename').includes("-Final") ? optionElement.getAttribute('filename').replace("-Final", "") : optionElement.getAttribute('filename'))
        };
        setDoc(obj);
    }
    const DownloadDocument = () => {
        if (documentList.length == 0) {
            setShow(true);
            setValidationMsg('Please select the state.')
        }
        else if (formList.length == 0) {
            setShow(true);
            setValidationMsg('Please select the document name.')
        }
        else if (Object.keys(doc).length == 0) {
            setShow(true);
            setValidationMsg('Please select the form number.')
        }
        else {
            const pdf = `data:application/octet-stream;base64,${doc.file}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = pdf;
            downloadLink.download = doc.name;
            downloadLink.click();
        }
    }

    return (
        <>
            <div className="row text-muted">
                <div className="col-12 ">
                    <div className="d-flex justify-content-center flex-column">
                        <ValidationModal show={show} title={"Oops!"} validationMsg={validationMsg} handleClose={handleClose} />
                        <h4 className="text-center section-title">
                            RETRIEVE POLICY DOCUMENT
                        </h4>
                        <div className="row justify-content-center">
                            <form className="col-sm-10 col-md-8 col-lg-10">
                                <div className="row gutters-sm">
                                    <small className="col-xs-12 col-sm-3 mt-2 small">State</small>
                                    <div className="col-xs-12 col-sm-9" style={dropListLabel}>
                                        <select name="state" defaultValue={"ST"} onChange={onStateChange} className="container btn btn-default btn-group dropdown-toggle filter-option">
                                            <option value={"ST"} disabled >Choose a State</option>
                                            {props.user.userData.state === "New Jersey" &&
                                                <option value={"NJ"}>New Jersey</option>
                                            }
                                            {props.user.userData.state === "Pennsylvania" &&
                                                <option value={"PA"}>Pennsylvania</option>
                                            }
                                            {props.user.userData.state === "Michigan" &&
                                                <option value={"MI"}>Michigan</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                {displayRest &&
                                    <>
                                        <div className="row gutters-sm">
                                            <small className="col-xs-12 col-sm-3 mt-2 small">Document Name</small>
                                            <div className="col-xs-12 col-sm-9" style={dropListLabel}>
                                                <select name="document" defaultValue={"default"} onChange={onDocumentChange}
                                                    className="container btn btn-default btn-group dropdown-toggle filter-option">
                                                    <option value={"default"} disabled ></option>
                                                    {documentList.map((value, index) => {
                                                        return <option key={index} value={value.docID}>{value.docName}</option>
                                                    })}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="row gutters-sm">
                                            <small className="col-xs-12 col-sm-3 mt-2 small">Form Number</small>
                                            <div className="col-xs-12 col-sm-9" style={dropListLabel}>
                                                <select name="form" defaultValue={"default"} onChange={QueueDocument} className="container btn btn-default text-start">
                                                    <option value={"default"}>Select a document...</option>
                                                    {formList.map((value, index) => {
                                                        return <option key={value.versionName} pdf={value.pdf} filename={value.versionPath} >{value.versionName}</option>
                                                    })}

                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="m-3" style={{ textAlign: "center" }} >
                                    <input className="btn btn-primary-grad " onClick={() => { DownloadDocument() }} value={"VIEW DOCUMENT"} type={"button"} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const PolicyDocuments = (props) => {
    const { userData, setUserData } = props.user


    return (
        <>
            <Menu Title={"POLICY DOCUMENTS"} FirstName={userData.firstName} userData={userData} />
            <RoundyContainer params={props} Content={Content} />
        </>
    );
};


export default PolicyDocuments;