import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { HouseholdChangeContextComp } from "../../components/ChangeRequest_HouseholdChangeComp/HouseholdChangeContext"
import HouseholdProgressBarSect from "../../components/UpdateDriversComp/HouseholdProgressBarSect"
import HouseholdDA_Main from "components/ChangeRequest_HouseholdChangeComp/2_DriverAssignment/HouseholdDA_Main"
import { useEffect } from "react"

const UpdateHouseholdDriverAssignment = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <HouseholdChangeContextComp>
            <Menu Title={"HOUSEHOLD CHANGE"} FirstName={userData.firstName} userData={userData} />
            <HouseholdProgressBarSect screen={"Driver Assignment"} userData={userData} />
            <RoundyContainer Content={HouseholdDA_Main} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </HouseholdChangeContextComp>
        </>
    )
}

export default UpdateHouseholdDriverAssignment