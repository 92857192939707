import { storageData } from "services/Services"

const VehicleFinanceList = () => {
    let options = []
    try {
        const dropdownOptions = JSON.parse(storageData.getItem('FinanceListGW'))

        if (dropdownOptions.length > 0) {
            options = [
                <option key="default" disabled value="">Select from the following...</option>,
                ...dropdownOptions.map((company, idx) => (
                    <option key={idx+1} value={company.providerMini.partyInfo.nameInfo.commercialName}>
                        {company.providerMini.partyInfo.nameInfo.commercialName}
                    </option>
                ))
            ]
        }
    } catch (error) {
        console.error(error)
        options = [<option key="default" value="">Select from the following...</option>]
    }

    return <>{options}</>
}

export default VehicleFinanceList;