import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const RoundyChatPlaceholder = ({ handleShowMessagingTool, show, setShow }) => {
  const [blocked, setBlocked] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const excludedPathnames = ["/change-address", "/update-drivers", "/update-vehicles", "/change-address/landing", "/update-drivers/landing", "/ext-login", "/endorsement"];
    const pathname = location.pathname;

    //Check the current pathname to see if we should show the sticky chat now.
    const shouldShowStickyChatNow = excludedPathnames.some(path => pathname.includes(path));
    // Shows/hides the live chat icon
    const chatWidgetContainer = document.getElementById('chat-widget-container');
    if (chatWidgetContainer) chatWidgetContainer.style.display = shouldShowStickyChatNow ? 'none' : ''

    // Shows/hides the genesys chat icon
    if (blocked !== shouldShowStickyChatNow) setBlocked(shouldShowStickyChatNow);
  }, [location, blocked]);

  return (
    <>
      <div className='roundy-chatnow' onClick={() => handleShowMessagingTool()} style={{ display: (show && !blocked ? "block" : "none") }}>
        <div className="roundy-chatnow-close" style={{
          position: "absolute",
          display: "block",
          top: "-5px",
          right: "14px",
          width: "16px",
          lineHeight: "16px",
          textAlign: "center",
          cursor: "pointer",
          textDecoration: "none",
          color: "rgb(0, 0, 0)",
          fontFamily: "Arial",
          borderRadius: "50%",
          backgroundColor: "rgba(100, 100, 100, 0.4)",
          cursor: "pointer",
          zIndex: 5000000000
        }}
          onClick={(e) => { e.stopPropagation(); setShow(false) }}>
          ×
        </div>
      </div>
    </>
  );
};

export default RoundyChatPlaceholder;