import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";

const NoPage = () => {
  console.log(useParams())
    return <h1>404</h1>;
  };
  
  export default NoPage;