import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { VehicleChangeContextComp } from "../../components/ChangeRequest_VehicleChangeComp/VehicleChangeContext"
import VehicleSummaryMain from "../../components/ChangeRequest_VehicleChangeComp/3_Summary/VehicleSummaryMain"
import NeedHelp from "../../components/UpdateDriversComp/Summary/NeedHelp"
import VehicleProgressBar from "../../components/ChangeRequest_VehicleChangeComp/VehicleProgressBar"
import { useEffect } from "react"

const VehiclesSummary = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <VehicleChangeContextComp>
                <Menu Title={"VEHICLE CHANGE"} FirstName={userData.firstName} userData={userData} />
                <VehicleProgressBar screen={"Summary"} />
                <RoundyContainer Content={VehicleSummaryMain} NoRoundy
                    params={{
                        storedData: { ...props.storedData },
                        setStoredData: props.setStoredData
                    }}
                />
            </VehicleChangeContextComp>
            
            <div className="col-12">
                <NeedHelp />
            </div>
        </>
    )
}

export default VehiclesSummary