import '../../../css/HeaderRQ.css';

const Menu_RQ = () => {

    return (
        <>
            <div className="container">
                <div className="containerNav col-12">
                    <div className="head clearfix titleDiv">
                        <h1 className="headerTitleRQ">
                            RENEWAL
                            <span className='mx-2 mx-sm-3'>APPLICATION</span>
                            QUESTIONNAIRE
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}



export default Menu_RQ