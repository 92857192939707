import React, { useContext, useState } from "react"
import { storageData } from "services/Services"
import AddDriverMI from "../Drivers/MI/AddDriverMI"
import AddDriver from "../Drivers/NJ_PA/AddDriver"
import EditDriverMI from "../Drivers/MI/EditDriverMI"
import EditDriver from "../Drivers/NJ_PA/EditDriver"
import EditMemberMI from "../Members/MI/EditMemberMI"
import EditMember from "../Members/NJ_PA/EditMember"

const Drivers = ({ changeReqContext, reqChangeClass, setChangeClass }) => {
    const [editType, setEditType] = useState("")
    var context = useContext(changeReqContext)
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"
    var storedDrivers = context[reqChangeClass].driversHHMs.drivers?.storedDrivers
    var storedMembers = context[reqChangeClass].driversHHMs.householdMembers?.storedMembers
    var newDrivers = context[reqChangeClass].driversHHMs.drivers?.newDrivers
    var pendingAddedDrivers = context[reqChangeClass].driversHHMs?.pendingAddedDrivers
    var pendingRemovedDrivers = context[reqChangeClass].driversHHMs?.pendingRemovedDrivers
    var insuredName = context[reqChangeClass]?.firstName + " " + context[reqChangeClass]?.lastName

    // Pending drivers for removed
    const setPendingDrivers = () => {
        if (pendingRemovedDrivers?.length > 0) {
            var driverPending = pendingRemovedDrivers.reduce((map, driver) => {
                map[driver.fullName] = driver
                return map
            }, {})
    
            storedDrivers = storedDrivers.map(driver => {
                const matchingDriver = driverPending[driver.fullName];
                return matchingDriver ? matchingDriver : driver;
            })
        }
    }

    // Clear Driver
    const clearDriver = (driver, index) => {
        var changeClassCopy = context[reqChangeClass]
        var newDriversCopy = changeClassCopy.driversHHMs.drivers.newDrivers;
        newDriversCopy = newDriversCopy.filter((val, i) =>
            index != i
        );
        changeClassCopy.driversHHMs.drivers.newDrivers = newDriversCopy
        setChangeClass({ ...changeClassCopy })
        storageData.setItem(typeOfClass, JSON.stringify(context[reqChangeClass]))
    }

    // Open ADD menu to edit
    const editDriver = (index) => {
        var editAddedArr = newDrivers.map((value, i) => {
            return (index === i) ? true : false
        });
        context.setEditAddedDriverArr(editAddedArr)
        context.setAddDriver(true)
    }

    // Open EDIT menu 
    const editStoredDriver = (driver, index, button) => {
        // Toggle Edit Menu
        if (button == "EDIT") {
            var editStoredArr = storedDrivers.map((value, i) => {
                return (index === i) ? true : false
            });

            context?.setShowDriverEditMenu(true)
            context?.setEditStoredDriverArr(editStoredArr)
        }
        // Cancel saved edit (remove edit)
        else {
            var changeClassCopy = context[reqChangeClass]

            // Set converted driver from member back
            if (driver?.driverStatus == "Adding As Driver") {
                var originalMember = {
                    "DOB": driver?.DOB,
                    "currRel": driver?.currRel,
                    "currStatus": driver?.currStatus,
                    "daySelect": driver?.daySelect,
                    "fullName": driver?.fullName,
                    "gender": driver?.gender,
                    "initialType": driver?.initialType,
                    "licenseNum": driver?.licenseNum,
                    "mStatus": driver?.mStatus,
                    "memberStatus": "",
                    "monthSelect": driver?.monthSelect,
                    "yearSelect": driver?.yearSelect
                }
                // Adds back to stored member list
                var storedMemberListContextCopy = changeClassCopy?.driversHHMs?.householdMembers?.storedMembers
                storedMemberListContextCopy.push(originalMember)

                // Removes from stored driver list
                var storedDriverListContextCopy = changeClassCopy?.driversHHMs?.drivers?.storedDrivers
                storedDriverListContextCopy = storedDriverListContextCopy.filter((driver) => driver != storedDriverListContextCopy[index])
                changeClassCopy.driversHHMs.drivers.storedDrivers = storedDriverListContextCopy
            }
            // Cancel edited driver
            else {
                var originalDriver = {
                    "DOB": driver?.DOB,
                    "currRel": driver?.currRel,
                    "currStatus": driver?.currStatus,
                    "daySelect": driver?.daySelect,
                    "fullName": driver?.fullName,
                    "gender": driver?.gender,
                    "initialType": driver?.initialType,
                    "licenseNum": driver?.licenseNum,
                    "mStatus": driver?.mStatus,
                    "driverStatus": "",
                    "monthSelect": driver?.monthSelect,
                    "yearSelect": driver?.yearSelect
                }
                var changeClassCopy = context[reqChangeClass]
                changeClassCopy.driversHHMs.drivers.storedDrivers[index] = originalDriver
            }
            setChangeClass({ ...changeClassCopy })
            storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
            context?.setShowDriverEditMenu(false)
        }
    }

    setPendingDrivers()

    return (
        <>
            {/* Subtitle */}
            <div>
                <br />
                <h6 className="text-center no-margin household-text-blue fw-bold">
                    DRIVERS
                </h6>
                {/* Drivers */}
                <p className="text-center form-group" style={{ fontSize: '14px', color: 'gray' }}>
                    Our records indicate that these are the current driver(s) that
                    operate the policy vehicle(s):
                </p>
                <div className="clearfix" />
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-8 col-md-8">

                        {/* Stored Drivers */}
                        {storedDrivers.map((driver, index) => {
                            return (
                                <div key={index}>
                                    <hr className="less-margin" />
                                    <div>
                                        {/* Displays Drivers */}
                                        <strong className="text-blue justify-content-left">
                                            <span className="text-uppercase">{driver.fullName}, {driver.DOB}</span>
                                            <span className={`text-uppercase fst-italic`}>{`${driver?.driverStatus != "" ? " - " + driver?.driverStatus : ""}`}</span>
                                        </strong>

                                        {/* Edit for all drivers that are not the insured name */}
                                        {driver.fullName.toLowerCase() != insuredName.toLowerCase() && !context.addDriver && (!context?.showDriverEditMenu || !context?.showDriverEditMenu) && 
                                        driver?.driverStatus != "Remove Pending" && driver?.driverStatus != "Add Pending" && 
                                            <>
                                                <button type="button" className={`btn btn-xs btn-default pull-right editCancelBtn`} value={"EDIT"}
                                                    onClick={(e) => editStoredDriver(driver, index, e.target.value)}>
                                                    EDIT
                                                </button>
                                                {(driver?.editType != "" && "editType" in driver) &&
                                                    <button type="button" className={`mx-1 btn btn-xs btn-default pull-right editCancelBtn`} value={"CANCEL"}
                                                        onClick={(e) => editStoredDriver(driver, index, e.target.value)}>
                                                        CANCEL
                                                    </button>
                                                }
                                            </>
                                        }
                                        {/* Edit Driver Menu MICHIGAN */}
                                        <div className={`slide-up ${context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus != "Adding As Driver" && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus != "Adding As Driver" && context?.userData?.state == "Michigan" &&
                                                <EditDriverMI index={index} driver={driver} driverList={storedDrivers}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>
                                        {/* Edit Driver Menu NEW JERSEY/PENNSYLVANIA */}
                                        <div className={`slide-up ${context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus != "Adding As Driver" && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus != "Adding As Driver" && context?.userData?.state != "Michigan" &&
                                                <EditDriver index={index} driver={driver} driverList={storedDrivers}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>
                                        {/* Edit Member Menu for Added Driver MICHIGAN */}
                                        <div className={`slide-up ${context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus == "Adding As Driver" && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus == "Adding As Driver" && context?.userData?.state == "Michigan" &&
                                                <EditMemberMI index={index} member={driver} memberList={storedMembers} saveResponse={true}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>
                                        {/* Edit Member Menu for Added Driver NEW JERSEY/PENNSYLVANIA */}
                                        <div className={`slide-up ${context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus == "Adding As Driver" && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.showDriverEditMenu && context?.editStoredDriverArr[index] && driver?.driverStatus == "Adding As Driver" && context?.userData?.state != "Michigan" &&
                                                <EditMember index={index} member={driver} memberList={storedMembers} saveResponse={true}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* Pending Drivers */}
                        {pendingAddedDrivers?.map((driver, index) => {
                            return (
                                <div key={index}>
                                    <hr className="less-margin" />
                                    <strong className="text-blue justify-content-left">
                                        <span className="text-uppercase">{driver.fullName}, {driver.DOB}</span>
                                        <span className={`text-uppercase fst-italic`}> - {driver.driverStatus}</span>
                                    </strong>
                                </div>
                            )
                        })}
                        {/* Added Drivers */}
                        {newDrivers.map((driver, index) => {
                            return (
                                <div className={`${context?.showDriverEditMenu ? "hide" : ""}`} key={index}>
                                    <hr className="less-margin" />
                                    <div>
                                        {/* Displays Drivers */}
                                        <strong className="text-blue justify-content-left">
                                            <span className="text-uppercase">{`${driver.fullName}, ${driver.DOB}`}</span>
                                            <span className={`text-uppercase fst-italic`}> - {driver.driverStatus}</span>
                                        </strong>
                                        {/* Edit/Cancel Buttons */}
                                        {!context?.addDriver &&
                                            <>
                                                <button className={`btn btn-xs btn-default pull-right editCancelBtn`} value={"EDIT"} onClick={() => { editDriver(index) }}>
                                                    EDIT
                                                </button>
                                                <button className={`mx-1 btn btn-xs btn-default pull-right editCancelBtn`} value={"CANCEL"} onClick={() => clearDriver(driver, index)}>
                                                    CANCEL
                                                </button>
                                            </>
                                        }
                                    </div>

                                    {/* Editing New Driver MICHIGAN */}
                                    <div className={`slide-up ${context?.addDriver && context?.editAddedDriverArr[index] && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {context?.addDriver && context?.editAddedDriverArr[index] && context?.userData?.state == "Michigan" &&
                                            <AddDriverMI index={index} driverList={newDrivers} setAddDriver={context.setAddDriver}
                                                changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                        }
                                    </div>
                                    {/* Editing New Driver NEW JERSEY/PENNSYLVANIA */}
                                    <div className={`slide-up ${context?.addDriver && context?.editAddedDriverArr[index] && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {context?.addDriver && context?.editAddedDriverArr[index] && context?.userData?.state != "Michigan" &&
                                            <AddDriver index={index} driverList={newDrivers} setAddDriver={context.setAddDriver}
                                                changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {!context.addDriver && context?.editAddedDriverArr?.length == 0 && !context?.showDriverEditMenu &&
                            <hr className="less-margin expandHeight" />
                        }

                        {/* Add New Driver Button */}
                        {!context.addDriver && !context?.showDriverEditMenu &&
                            <div className="slide-toggle text-center">
                                <button type="button" className="btn btn-primary-grad editCancelBtn" onClick={() => context.setAddDriver(true)}>
                                    Add New Driver
                                </button>
                            </div>
                        }

                        {/* Add New Driver MICHIGAN */}
                        <div className={`slide-up ${context.addDriver && context?.editAddedDriverArr?.length == 0 && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                            {context.addDriver && context?.editAddedDriverArr?.length == 0 && context?.userData?.state == "Michigan" &&
                                <AddDriverMI index={newDrivers?.length} driverList={newDrivers} setAddDriver={context.setAddDriver}
                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                            }
                        </div>
                        {/* Add New Driver NEW JERSEY/PENNSYLVANIA */}
                        <div className={`slide-up ${context.addDriver && context?.editAddedDriverArr?.length == 0 && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                            {context.addDriver && context?.editAddedDriverArr?.length == 0 && context?.userData?.state != "Michigan" &&
                                <AddDriver index={newDrivers?.length} driverList={newDrivers} setAddDriver={context.setAddDriver}
                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Drivers