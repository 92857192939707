import { useContext } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"

const VehiclesQuestions = () => {
    const context = useContext(VehicleChangeContext)
    const currentAddress = context.currentResidence

    // Saves responses
    const updateVehicleQuestions = (key, value) => {
        var vehicleClassCopy = context.vehicleChangeClass

        // Adds the current address for vehicles
        if (key == "keptOvernightResponse") {
            vehicleClassCopy.vehicles["keptOvernightAddress"] = currentAddress.address1
            vehicleClassCopy.vehicles["keptOvernightAddress2"] = currentAddress.address2
            vehicleClassCopy.vehicles["keptOvernightCity"] = currentAddress.city
            vehicleClassCopy.vehicles["keptOvernightState"] = currentAddress.state
            vehicleClassCopy.vehicles["keptOvernightZip"] = currentAddress.zip
        }

        vehicleClassCopy.vehicles[key] = value
        context.setVehicleChangeClass({ ...vehicleClassCopy })
    }

    return (
        <>
            <hr className="mt-4" />
            {/* Kept Overnight */}
            <div className="form-group form-group-sm">
                <strong className="text-primary">{`Will all of your vehicle(s) be kept overnight at ${currentAddress?.address1}
                    ${currentAddress?.city}, ${currentAddress?.state} ${(currentAddress?.zip?.substring(0, 5))}?`}</strong>
            </div>
            {/* Response for vehicle kept overnight */}
            <div className="form-group form-group-sm text-dark ms-3 mt-1">
                {['Yes', 'No'].map((value, id) => {
                    return (
                        <div key={id} className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input type={"radio"} className="form-check-input" name="garaged" value={value}
                                    checked={(context.vehicleChangeClass.vehicles.keptOvernightResponse == value)}
                                    onChange={() => { updateVehicleQuestions("keptOvernightResponse", value) }}
                                />{value}
                            </label>
                        </div>
                    )
                })}
            </div>
            {/* Textbox for location of vehicle */}
            {context.vehicleChangeClass.vehicles.keptOvernightResponse == "No" &&
                <div className="mx-3 mb-3">
                    <div className="form-group form-group-sm">
                        <strong className="text-primary mx-1">Please indicate where the vehicles are kept.</strong>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-7 col-sm-8 col-xs-12">
                            <textarea className="form-control" name="garageLocation" placeholder="Address(es)" rows={"3"} style={{ resize: 'none' }}
                                value={context.vehicleChangeClass.vehicles.keptOvernightLocation}
                                onChange={(e) => { updateVehicleQuestions("keptOvernightLocation", e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
            }
            {/* Business Purposes */}
            <div className="form-group form-group-sm">
                <strong className="text-primary">
                    Are any of the vehicle(s) listed above used for business purposes?
                    <a className="info-icon-for-select ms-3">
                        <i className="fa fa-info-circle"
                            onClick={() => {
                                context.setValidationMsgToggle("Business Purposes", `For example, deliveries, delivery services, plowing snow for compensation, or any commercial purposes.`)
                            }}
                        />
                    </a>
                </strong>
            </div>
            {/* Response for business purposes */}
            <div className="form-group form-group-sm text-dark ms-3">
                {['Yes', 'No'].map((value, id) => {
                    return (
                        <div key={id}>
                            <div className="radio-inline form-check form-check-inline">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="VehicleUsedInEmployment" value={value}
                                        onChange={(e) => updateVehicleQuestions("businessPurposeResponse", value)}
                                        checked={context.vehicleChangeClass.vehicles.businessPurposeResponse == value}
                                    />{(value == "No") ? "No, this vehicle is only used to drive to/from work and/or personal use." : "Yes"}
                                </label>
                            </div>
                            <br />
                        </div>
                    )
                })}
            </div>
            {/* Textbox for other purpose */}
            {context.vehicleChangeClass.vehicles?.businessPurposeResponse == "Yes" &&
                <div className="mb-3 mx-3">
                    <div className="row">
                        <div className="col-lg-8 col-md-9 col-sm-10 col-xs-12">
                            <input type={"text"} className="form-control input-sm"
                                placeholder="Please explain how your vehicles are used."
                                value={context.vehicleChangeClass.vehicles.businessPurposeExplanation}
                                onChange={(e) => updateVehicleQuestions("businessPurposeExplanation", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default VehiclesQuestions