import DatePick_MI from 'components/DatePicker_MI'
import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import { NewVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext'
import React, { useContext, useState } from 'react'

const VehiclePurchased = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    const newVehicleContext = useContext(NewVehicleContext)
    const [datePurchased, setDatePurchased] = useState(addVehicleContext.getTodaysDate())

    // Updates the value for the new vehicle and component tracker
    const updateVehicle = (key, value) => {
        var vehicleCopy = newVehicleContext.initialVehicle
        resetVehicleInfo(vehicleCopy, value)

        var addVehicleClassCopy = addVehicleContext.addVehicleClass
        addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles[0] = vehicleCopy
        addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
    }

    // Clears old values (if exists) for new form
    const resetVehicleInfo = (vehicleCopy, value) => {
        vehicleCopy.componentTracker = (value == "Owned" ? "VehicleOwned" : value == "Leased" ? "VehicleLeased" : "VehicleFinanced")
        vehicleCopy.requestedCovEffDate = newVehicleContext.newVehicle.requestedCovEffDate
        vehicleCopy.datePurchased = newVehicleContext.newVehicle.datePurchased
        vehicleCopy.vehicleBody = newVehicleContext.newVehicle.vehicleBody
        vehicleCopy.vehicleMake = newVehicleContext.newVehicle.vehicleMake
        vehicleCopy.vehicleModel = newVehicleContext.newVehicle.vehicleModel
        vehicleCopy.vehicleYear = newVehicleContext.newVehicle.vehicleYear
        vehicleCopy.vehicleVIN = newVehicleContext.newVehicle.vehicleVIN
        vehicleCopy.vehicleOwnedLeasedFinanced = value
        newVehicleContext.setNewVehicle({ ...vehicleCopy });
    }

    return (
        <>
            <hr />
            <div className='col-xl-12 justify-content-center' style={{ margin: "0 0.5rem", padding: "0 0.75rem" }}>
                {/* Date Purchased */}
                <div className='row form-group form-group-sm align-items-center my-3'>
                    <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0 fs-6">
                        Date Purchased*
                    </div>
                    <div className="col-12 col-md-6 col-sm-8">
                        <DatePick_MI
                            dateSubmit={newVehicleContext.newVehicle.datePurchased ? newVehicleContext.newVehicle.datePurchased : datePurchased}
                            setDateSubmit={setDatePurchased}
                            storeValue={newVehicleContext.newVehCallback}
                            nameValue={"datePurchased"}
                            setToday={true}
                            displayCalendar={"bottom-start"}
                        />
                    </div>

                </div>
                {/* Vehicle Owned/Leased/Financed */}
                <div className='row form-group form-group-sm align-items-center my-3'>
                    <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0 fs-6">
                        Is this vehicle owned, leased, or financed?*
                    </div>
                    <div className="col-12 col-md-6 col-sm-8">
                        <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option mt-1"
                            value={newVehicleContext.newVehicle.vehicleOwnedLeasedFinanced}
                            onChange={(e) => { updateVehicle("vehicleOwnedLeasedFinanced", e.target.value) }}>
                            <option value={""} disabled>Select from the following...</option>
                            <option value="Owned">Owned</option>
                            <option value="Leased">Leased</option>
                            <option value="Financed">Financed</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehiclePurchased