import { useContext } from "react"
import { HouseholdChangeContext } from "../HouseholdChangeContext"
import Drivers from "components/ChangeRequestSharedComp/Household/Drivers"
import HouseholdMembers from "components/ChangeRequestSharedComp/Household/HouseholdMembers"
import ValidationModal from "../../ValidationModal"
import HouseholdChangeBtnNavigation from "../HouseholdChangeBtnNavigation"
import DateOfChange_NJPA from "components/ChangeRequestSharedComp/DateOfChange_NJPA"

const HouseholdMain = () => {
    const context = useContext(HouseholdChangeContext)

    return (
        <>
            {(context.addDriver || !context.addMember) && !context?.showMemberEditMenu &&
                <Drivers changeReqContext={HouseholdChangeContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
            }
            {(!context.addDriver || context.addMember) && !context?.showDriverEditMenu &&
                <HouseholdMembers changeReqContext={HouseholdChangeContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
            }

            {(context?.userData?.state != "Michigan") && !context.addDriver && !context.addMember && !context?.showMemberEditMenu && !context?.showDriverEditMenu &&
                <DateOfChange_NJPA changeReqContext={HouseholdChangeContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
            }

            {!context.addDriver && !context.addMember && !context?.showMemberEditMenu && !context?.showDriverEditMenu &&
                <HouseholdChangeBtnNavigation />
            }
            
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default HouseholdMain