export default {
    requestType: "Add Vehicle",
    policyUserId: "",
    policyNumber: "",
    effectiveDate: "",
    expirationDate: "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    emailAddress: "",
    policyState: "",
    vehicles: {
        vehiclesOnPolicy: {
            storedVehicles: [],
            newVehicles: []
        }
    },
    driversHHMs: {
        drivers: {
            pendingNewDrivers: [],
            pendingRemovedDrivers: [],
            storedDrivers: [],
            newDrivers: []
        },
        householdMembers: {
            pendingNewMembers: [],
            pendingRemovedMembers: [],
            storedMembers: [],
            newMembers: []
        }
    },
    driverAssignment: {},
    confirmation: false
}