import React, { useState } from "react";
import { storageData } from "../../services/Services";

const breadcrumb = {
  borderRadius: '0.37rem',
  "--bs-breadcrumb-divider": "'>'",
  textDecoration: 'none'
}

const HouseholdProgressBarSect = (props) => {
  const { screen, userData } = props
  var navSet;

  const navs = [
    '1. Household',
    '2. Summary'
  ];

  const navsMI = [
    '1. Household',
    '2. Driver Assignment',
    '3. Summary'
  ];

  const additionalNavs = [
    '1. Household',
    '2. Additional',
    '3. Summary'
  ];

  const settingNav = () => {
    var changeClass = storageData.getItem("HouseholdChangeClass")
    if (changeClass) {
      changeClass = JSON.parse(changeClass)
      if ((changeClass?.driversHHMs?.drivers?.newDrivers?.length > 0) && ((screen === "Additional")
        || (screen === "Summary"))) {
        navSet = additionalNavs;
      }
      else {
        navSet = (userData?.state == "Michigan") ? navsMI : navs;
      }
    }
    else {
      navSet = (userData?.state == "Michigan") ? navsMI : navs;
    }
  }

  const isCurrent = ((value) => {
    return value.slice(3) === screen;
  });
  settingNav()
  return (
    <>
      <div className="clearfix"></div>

      {/* Address Progress Bar */}
      <div className="col-12">
        <nav>
          <ol className="breadcrumb justify-content-center" style={breadcrumb}>
            {
              navSet.map((nav, navIndex) => {
                const active = isCurrent(nav) ? 'fw-bold text-blue' : 'disabled';
                return (
                  <li key={navIndex} className="breadcrumb-item align-items-center d-flex">
                    <button className={`btn ${active}`}>
                      {nav}
                    </button>
                  </li>
                );
              })
            }
          </ol>
        </nav>
      </div>

    </>
  )
}

export default HouseholdProgressBarSect