import { useEffect, useState } from "react"
import YesNoUnknownResponse from "./YesNoUnknownResponse"
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { storageData } from "../../../services/Services";
import ValidationAdditionalInfo from "./ValidationAdditionalInfo";
import ValidationModal from "../../ValidationModal";

const AdditionalInfo_Main = (props) => {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [sessionData, setSessionData] = useState({})
    const [userData, setUserData] = useState({})
    const [show, setShow] = useState(false)
    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [validationLoad, setValidationLoad] = useState(false)
    const [allDrivers, setAllDrivers] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Retrieves userData
        var storedUserData = storageData.getItem('userData')
        if (storedUserData) {
            storedUserData = JSON.parse(storedUserData)
            setUserData(storedUserData)
        }

        // Retrieves sessionData
        var storedSessionDataRQ = storageData.getItem('sessionDataRQ')
        if (storedSessionDataRQ) {
            storedSessionDataRQ = JSON.parse(storedSessionDataRQ)
            setSessionData({ ...storedSessionDataRQ })
            setAllDrivers([...storedSessionDataRQ.household.drivers.storedDrivers, ...storedSessionDataRQ.household.drivers.newDrivers])
        }

    }, [])

    // Update drivers with conditions
    const updateDrivers = () => {
        var conditionArr = ['suspended', 'seizures', 'narcolepsy', 'syncope', 'blindness', 'impairments']
        var sessionDataCopy = sessionData
        var storedDrivers = sessionData.household.drivers.storedDrivers
        var newDrivers = sessionData.household.drivers.newDrivers
        var updatedStoredDrivers = []
        var updatedNewDrivers = []

        // Iterates through the stored drivers
        storedDrivers.map((driver) => {
            // Iterates through the conditions
            conditionArr.map((condition, index) => {
                // Not driver risk
                if (sessionDataCopy.additionalInfo.conditions[condition].response == "No") {
                    driver[condition] = sessionDataCopy.additionalInfo.conditions[condition].response
                }
                // Driver risk
                else {
                    if (sessionDataCopy.additionalInfo.conditions[condition].drivers.includes(driver.fullName)) {
                        driver[condition] = sessionDataCopy.additionalInfo.conditions[condition].response

                        // Impairment explanation
                        if (condition == "impairments") {
                            sessionDataCopy.additionalInfo.conditions[condition].explanation.map((impairment, index) => {
                                if (impairment.driver == driver.fullName) {
                                    var i = sessionDataCopy.additionalInfo.conditions[condition].explanation.findIndex(e => e.driver == driver.fullName);
                                    driver["impairmentExplanation"] = sessionDataCopy.additionalInfo.conditions[condition].explanation[i].explanation
                                }
                            })
                        }
                    }
                    else {
                        driver[condition] = "No"
                    }
                }
            })
            updatedStoredDrivers.push(driver)
        })

        // Iterates through the new drivers
        newDrivers.map((driver) => {
            // Iterates through the conditions
            conditionArr.map((condition, index) => {
                // Not driver risk
                if (sessionDataCopy.additionalInfo.conditions[condition].response == "No") {
                    driver[condition] = sessionDataCopy.additionalInfo.conditions[condition].response
                }
                // Driver risk
                else {
                    if (sessionDataCopy.additionalInfo.conditions[condition].drivers.includes(driver.fullName)) {
                        driver[condition] = sessionDataCopy.additionalInfo.conditions[condition].response

                        // Impairment explanation
                        if (condition == "impairments") {
                            sessionDataCopy.additionalInfo.conditions[condition].explanation.map((impairment, index) => {
                                if (impairment.driver == driver.fullName) {
                                    var i = sessionDataCopy.additionalInfo.conditions[condition].explanation.findIndex(e => e.driver == driver.fullName);
                                    driver["impairmentExplanation"] = sessionDataCopy.additionalInfo.conditions[condition].explanation[i].explanation
                                }
                            })
                        }
                    }
                    else {
                        driver[condition] = "No"
                    }
                }
            })
            updatedNewDrivers.push(driver)
        })
        sessionDataCopy.household.drivers.storedDrivers = updatedStoredDrivers
        sessionDataCopy.household.drivers.newDrivers = updatedNewDrivers
        setSessionData({ ...sessionDataCopy })

    }

    const navigateToPage = (button) => {
        if (button == "back") {
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            navigate("/renewalQuestionnaire/driverOHM")
        }
        else {
            var check = ValidationAdditionalInfo({ sessionData, setValidationMsg, handleShow, setValidationLoad, setValidationTitle, setLoad })
            if (check) {
                updateDrivers()
                storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
                navigate("/renewalQuestionnaire/vehicle")
            }
        }
    }

    return (
        <>
            <ValidationModal
                show={show}
                handleClose={handleClose}
                validationMsg={validationMsg}
                title={validationTitle}
            />

            {/* Supspended License */}
            <div className="form-group form-group-sm">
                <strong className="text-primary">
                    Have any drivers' licenses been suspended in any state in the past 12 months?
                </strong>
            </div>
            <div className="form-group form-group-sm text-dark ms-3">
                <YesNoUnknownResponse
                    sessionData={sessionData}
                    setSessionData={setSessionData}
                    allDrivers={allDrivers}
                    condition={"suspended"}
                />
            </div>
            <hr className="no-padding mt-0" />

            {/* Conditions */}
            <div className="col-xl-12 form-group form-group-sm">
                <strong className="col-sm-12 text-primary">
                    Have any drivers experienced any of the following conditions within the past 36 months?
                </strong>
                <br /><br />
                <div className="ms-3">
                    {["1) Seizure Disorders", "2) Narcolepsy", "3) Syncope (Fainting)"].map((condition, index) => {
                        return (
                            <div key={index} className="row">
                                <div className="col-lg-12 text-dark">{condition}
                                    <YesNoUnknownResponse
                                        sessionData={sessionData}
                                        setSessionData={setSessionData}
                                        allDrivers={allDrivers}
                                        condition={condition}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <div className="row">
                        <div className="col-lg-12 text-dark">{"4) Blindness in one or both eyes"}
                            <br />
                            <em className="small" style={{ color: "#696969" }}>{"(Unless corrected by prescription eyeglasses or contact lenses)"}</em>
                            <YesNoUnknownResponse
                                sessionData={sessionData}
                                setSessionData={setSessionData}
                                allDrivers={allDrivers}
                                condition={"4) Blindness in one or both eyes"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="no-padding" />

            {/* Impairments */}
            <div className="form-group form-group-sm col-12">
                <div>
                    <strong className="text-primary">
                        Do any of the drivers have any mental or physical impairments, other than listed above, that would impair their ability
                        to drive a motor vehicle safely?
                    </strong>
                    &nbsp;
                    <em className="text-dark">{"(Not including prescription eyeglasses or contact lenses.)"}</em>
                </div>
            </div>
            <div className="ms-3">
                <YesNoUnknownResponse
                    sessionData={sessionData}
                    setSessionData={setSessionData}
                    allDrivers={allDrivers}
                    condition={"impairments"}
                />
            </div>

            {/* Next Button */}
            <div>
                <div className="pb-4 my-4">
                    {!load &&
                        <>
                            <input type="submit" value={""} className="backButton" onClick={() => navigateToPage("back")} />
                            <input type="submit" value={""} className="nextButton" onClick={() => navigateToPage("next")} />
                        </>
                    }
                    {load &&
                        <Spinner className='ms-3' animation="border" />
                    }
                </div>
            </div>
        </>
    )
}

export default AdditionalInfo_Main