const FormText = (props) => {
    const {
        id, 
        value, 
        label, 
        inputType, 
        params, 
        maxLength = 32, 
        placeholder, 
        callback, 
        className = {
            margin: "my-2",
            marginBottom: "mb-0",
            marginSecond: "my-0"
        },
        max = 99
    } = props
    return (
        <>
            <div className={`row form-group form-group-sm align-items-center ${className.margin}`}>
                <div className={`col-xl-4 col-lg-12 fw-bold text-blue ${className?.marginBottom}`}>
                    {label}
                </div>
                <div className={`col-xl-6 col-lg-12 ${className.marginSecond}`}>
                    <input type={inputType} className="form-control fullName"
                        maxLength={maxLength} max={max} value={value}
                        onChange={(e) => { callback(id, e.target.value) }}
                    />
                </div>
            </div>
        </>
    )
}

export default FormText