import React, { useContext, useState } from "react"
import EditMemberMI from "../Members/MI/EditMemberMI"
import AddMemberMI from "../Members/MI/AddMemberMI"
import { storageData } from "services/Services"
import AddMember from "../Members/NJ_PA/AddMember"
import EditMember from "../Members/NJ_PA/EditMember"

const HouseholdMembers = ({ changeReqContext, reqChangeClass, setChangeClass }) => {
    const [editType, setEditType] = useState("")
    var context = useContext(changeReqContext)
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"
    var storedMembers = context[reqChangeClass]?.driversHHMs.householdMembers?.storedMembers
    var newMembers = context[reqChangeClass]?.driversHHMs.householdMembers?.newMembers
    var pendingAddedHhms = context[reqChangeClass]?.driversHHMs?.pendingAddedHhms
    var pendingRemovedHhms = context[reqChangeClass]?.driversHHMs?.pendingRemovedHhms

    const setPendingHhms = () => {
        if (pendingRemovedHhms?.length > 0) {
            var hhmPending = pendingRemovedHhms.reduce((map, hhm) => {
                map[hhm.fullName] = hhm
                return map
            }, {})
    
            storedMembers = storedMembers.map(hhm => {
                const matchingHhm = hhmPending[hhm.fullName];
                return matchingHhm ? matchingHhm : hhm;
            })
        }
    }

    // Clear Member
    const clearMember = (member, index) => {
        var changeClassCopy = context[reqChangeClass]
        var newMembersCopy = changeClassCopy.driversHHMs.householdMembers?.newMembers;
        newMembersCopy = newMembersCopy.filter((val, i) =>
            index != i
        );
        changeClassCopy.driversHHMs.householdMembers.newMembers = newMembersCopy
        setChangeClass({ ...changeClassCopy })
        storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
    }

    // Open ADD menu to edit
    const editMember = (index) => {
        var editAddedArr = newMembers.map((value, i) => {
            return (index === i) ? true : false
        });
        context.setEditAddedMemberArr(editAddedArr)
        context.setAddMember(true)
    }

    // Open EDIT menu
    const editStoredMember = (member, index, button) => {
        // Toggle Edit Menu
        if (button == "EDIT") {
            var editStoredArr = storedMembers.map((value, i) => {
                return (index === i) ? true : false
            });
            context?.setEditStoredMemberArr(editStoredArr)
            context?.setShowMemberEditMenu(true)
        }
        // Cancel edited member
        else {
            var originalMember = {
                "DOB": member?.DOB,
                "currRel": member?.currRel,
                "currStatus": member?.currStatus,
                "daySelect": member?.daySelect,
                "fullName": member?.fullName,
                "gender": member?.gender,
                "initialType": member?.initialType,
                "licenseNum": member?.licenseNum,
                "mStatus": member?.mStatus,
                "memberStatus": "",
                "monthSelect": member?.monthSelect,
                "yearSelect": member?.yearSelect
            }
            var changeClassCopy = context[reqChangeClass]
            changeClassCopy.driversHHMs.householdMembers.storedMembers[index] = originalMember
            setChangeClass({ ...changeClassCopy })
            storageData.setItem("HouseholdChangeClass", JSON.stringify(changeClassCopy))
            context?.setShowMemberEditMenu(false)
        }
    }

    setPendingHhms()

    return (
        <>
            {/* Subtitle */}
            <div>
                <br />
                <h6 className="text-center no-margin household-text-blue fw-bold">
                    HOUSEHOLD MEMBERS
                </h6>
                {/* Household Members */}
                <p className="text-center form-group" style={{ fontSize: '14px', color: 'gray' }}>
                    Our records indicate these are the people that live at the
                    policy address (including children even if away at school):
                </p>
                <div className="clearfix" />
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-7 col-md-8">

                        {/* Stored members */}
                        {storedMembers?.map((member, index) => {
                            return (
                                <div key={index}>
                                    <hr className="less-margin" />
                                    <div>
                                        {/* Displays Members */}
                                        <strong className="text-blue lowerFont">
                                            <span className="text-uppercase">{member.fullName}, {member.DOB}</span>
                                            <span className={`text-uppercase fst-italic`}>{`${member.memberStatus != "" ? " - " + member.memberStatus : ""}`}</span>
                                        </strong>

                                        {/* Edit */}
                                        {!context?.addMember && member?.memberStatus != "Remove Pending" && member?.memberStatus != "Add Pending" &&
                                            <>
                                                {!context?.showMemberEditMenu && 
                                                    <button type="button" className={`btn btn-xs btn-default pull-right editCancelBtn`} value={"EDIT"}
                                                        onClick={(e) => { editStoredMember(member, index, e.target.value) }}>
                                                        EDIT
                                                    </button>
                                                }
                                                {!context?.showMemberEditMenu && member?.memberStatus != "" &&
                                                    <button type="button" className={`mx-1 btn btn-xs btn-default pull-right editCancelBtn`} value={"CANCEL"}
                                                        onClick={(e) => { editStoredMember(member, index, e.target.value) }}>
                                                        CANCEL
                                                    </button>
                                                }
                                            </>
                                        }
                                        {/* Edit Menu MICHIGAN */}
                                        <div className={`slide-up ${context?.editStoredMemberArr[index] && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.editStoredMemberArr[index] && context?.userData?.state == "Michigan" &&
                                                <EditMemberMI index={index} member={member} memberList={storedMembers}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>

                                        {/* Edit Menu NEW JERSEY/PENNSYLVANIA */}
                                        <div className={`slide-up ${context?.editStoredMemberArr[index] && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                            {context?.editStoredMemberArr[index] && context?.userData?.state != "Michigan" &&
                                                <EditMember index={index} member={member} memberList={storedMembers}
                                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* Pending Members */}
                        {pendingAddedHhms?.map((member, index) => {
                            return (
                                <div key={index}>
                                    <hr className="less-margin" />
                                    <strong className="text-blue justify-content-left">
                                        <span className="text-uppercase">{member.fullName}, {member.DOB}</span>
                                        <span className={`text-uppercase fst-italic`}> - {member.memberStatus}</span>
                                    </strong>
                                </div>
                            )
                        })}
                        {/* Added Members */}
                        {newMembers.map((member, index) => {
                            return (
                                <div className={`${context?.showMemberEditMenu ? "hide" : ""}`} key={index}>
                                    <hr className="less-margin" />
                                    <div>
                                        {/* Displays Drivers */}
                                        <strong className="text-blue justify-content-left">
                                            <span className="text-uppercase">{`${member.fullName}, ${member.DOB}`}</span>
                                            <span className={`text-uppercase fst-italic`}> - {member?.memberStatus}</span>
                                        </strong>
                                        {/* Edit/Cancel Buttons */}
                                        {!context?.addMember &&
                                            <>
                                                <button className={`btn btn-xs btn-default pull-right editCancelBtn`} value={"EDIT"} onClick={() => { editMember(index) }}>
                                                    EDIT
                                                </button>
                                                <button className={`mx-1 btn btn-xs btn-default pull-right editCancelBtn`} value={"CANCEL"} onClick={() => clearMember(member, index)}>
                                                    CANCEL
                                                </button>
                                            </>
                                        }
                                    </div>

                                    {/* Editing New Member MICHIGAN */}
                                    <div className={`slide-up ${context?.addMember && context?.editAddedMemberArr[index] && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {context?.addMember && context?.editAddedMemberArr[index] && context?.userData?.state == "Michigan" &&
                                            <AddMemberMI index={index} memberList={newMembers} setAddMember={context.setAddMember}
                                                changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                        }
                                    </div>
                                    {/* Editing New Member NEW JERSEY/PENNSYLVANIA */}
                                    <div className={`slide-up ${context?.addMember && context?.editAddedMemberArr[index] && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {context?.addMember && context?.editAddedMemberArr[index] && context?.userData?.state != "Michigan" &&
                                            <AddMember index={index} memberList={newMembers} setAddMember={context.setAddMember}
                                                changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {!context?.addMember && context?.editAddedMemberArr?.length == 0 && !context?.showMemberEditMenu &&
                            <hr className="less-margin expandHeight" />
                        }

                        {/* Add New Member Button*/}
                        {!context.addMember && !context?.showMemberEditMenu &&
                            <div className="slide-toggle text-center">
                                <button type="button" className="btn btn-primary-grad editCancelBtn" onClick={() => context.setAddMember(true)}>
                                    Add New Household Member
                                </button>
                            </div>
                        }

                        {/* New Member Menu MICHIGAN */}
                        <div className={`slide-up ${context?.addMember && context?.editAddedMemberArr?.length == 0 && context?.userData?.state == "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                            {context?.addMember && context?.editAddedMemberArr?.length == 0 && context?.userData?.state == "Michigan" &&
                                <AddMemberMI index={newMembers?.length} memberList={newMembers} setAddMember={context.setAddMember}
                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                            }
                        </div>
                        {/* New Member Menu NEW JERSEY/PENNSYLVANIA */}
                        <div className={`slide-up ${context?.addMember && context?.editAddedMemberArr?.length == 0 && context?.userData?.state != "Michigan" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                            {context?.addMember && context?.editAddedMemberArr?.length == 0 && context?.userData?.state != "Michigan" &&
                                <AddMember index={newMembers?.length} memberList={newMembers} setAddMember={context.setAddMember}
                                    changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HouseholdMembers