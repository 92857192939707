const ComprehensiveDeductiblesDropdown = () => {
    return (
        <>
            <option value={""} disabled>Please make a selection</option>
            <option value={"$50"}>$50</option>
            <option value={"$100"}>$100</option>
            <option value={"$200"}>$200</option>
            <option value={"$250"}>$250</option>
            <option value={"$500"}>$500</option>
            <option value={"$1000"}>$1000</option>
            <option value={"$1500"}>$1500</option>
            <option value={"$2000"}>$2000</option>
            <option value={"$2500"}>$2500</option>
        </>
    )
}

export default ComprehensiveDeductiblesDropdown