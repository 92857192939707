import React from "react";
import { Link } from "react-router-dom";

class DriverPolicy extends React.Component {
    state = {
        policyDriver: ""
    }
    
    render() {
        const { policyDriver } = this.state;

        return (
            <>
                <div style={this.props.styles.indent} className={(policyDriver === "No") ? "d-none" : "d-block"}>
                    <hr />
                    <div className="mb-1">
                        <strong style={this.props.styles.blueText}>
                            Driver is not listed on my policy. I need to add a driver.
                        </strong>
                    </div>
                    <div className="mb-1">
                        <Link to={"/update-drivers/update-household"} type="button" className="btn btn-success yes" value={"Yes"} 
                        style={{ background: "linear-gradient(#88c149, #73a839, #699934)"}}>
                            Yes
                        </Link>
                        <button type="button" className="btn btn-default mx-1" value={"No"} onClick={(e) => this.setState({ policyDriver: e.target.value })}>
                            No
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

export default DriverPolicy