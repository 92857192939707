import { useEffect, useState } from "react"

const YesNoUnknownResponse = (props) => {
    // Returns the name of key
    const returnKey = (label) => {
        var labelObj = {
            "suspended": "suspended",
            "1) Seizure Disorders": "seizures",
            "2) Narcolepsy": "narcolepsy",
            "3) Syncope (Fainting)": "syncope",
            "4) Blindness in one or both eyes": "blindness",
            "impairments": "impairments"
        }
        return labelObj[label]
    }
    const [formValues, setFormValues] = useState({})

    useEffect(() => {
        if (props?.sessionData) {
            var formCopy = formValues
            formCopy = props?.sessionData?.additionalInfo?.conditions[returnKey(props.condition)]
            setFormValues({ ...formCopy })
        }
    }, [props?.sessionData?.additionalInfo?.conditions[returnKey(props?.condition)]])

    // Updates response on form
    const updateForm = (key, value) => {
        var sessionDataCopy = props.sessionData
        var formCopy = formValues
        formCopy["response"] = value
        if (value == "No") {
            formCopy.drivers = []
        }
        setFormValues({ ...formCopy })
        sessionDataCopy.additionalInfo.conditions[returnKey(key)] = formCopy
        props.setSessionData({ ...sessionDataCopy })
    }

    // Updates conditions for the drivers
    const updateDrivers = (key, value, driver) => {
        var sessionDataCopy = props.sessionData
        var formCopy = formValues
        var driverBoolean = formCopy.drivers.includes(value)

        // Driver gets checked
        if (!driverBoolean) {
            formCopy.drivers.push(value)
        }
        // Driver gets unchecked
        else {
            formCopy.drivers = formCopy.drivers.filter((name, index) => {
                return name != driver.fullName
            })
        }
        setFormValues({ ...formCopy })
        sessionDataCopy.additionalInfo.conditions[returnKey(key)] = formCopy
        props.setSessionData({ ...sessionDataCopy })
    }

    // Updates explanation for impairment
    const updateImpairementExplanation = (condition, driver, value) => {
        var sessionDataCopy = props.sessionData
        var formCopy = formValues

        // Checks if driver is in array first or if it's an empty first
        if (!(formCopy["explanation"].some(e => e.driver == driver.fullName)) || Object.keys(formCopy.explanation).length == 0) {
            var obj = {
                driver: driver.fullName,
                explanation: value
            }
            formCopy.explanation.push(obj)
        }
        // Finds the index of the driver and replaces explanation with current one
        else {
            var i = formCopy["explanation"].findIndex(e => e.driver == driver.fullName)
            formCopy.explanation[i].explanation = value
        }
        sessionDataCopy.additionalInfo.conditions[returnKey(condition)] = formCopy
        props.setSessionData({ ...sessionDataCopy })
        setFormValues({ ...formCopy })
    }

    // Retrieves save explanation for the impaired driver
    const findExplanation = (driver) => {
        return formValues.explanation.findIndex(e => e.driver == driver.fullName)
    }

    return (
        <>
            {/* Yes/No/Unknown Responses */}
            <div className="form-group form-group-sm text-dark my-1">
                {['Yes', 'No', 'Unknown'].map((value, index) => {
                    return (
                        <div key={index} className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input type={"radio"} className="form-check-input" name={props.condition} value={value}
                                    onChange={(e) => updateForm(props.condition, e.target.value)}
                                    checked={formValues.response == value}
                                />{value}
                            </label>
                        </div>
                    )
                })}

                {/* Drivers (if selected yes or unknown) */}
                {(formValues["response"] == "Yes" || formValues["response"] == "Unknown") &&
                    <div className="slide-toggle ms-1">
                        <div className="form-group-sm fw-bold mb-1">
                            <small>Which drivers? </small>
                            {formValues["response"] == "Unknown" &&
                                <small className="text-danger">(This may require follow-up from our Underwriting Department)</small>
                            }
                        </div>

                        {/* Stored Drivers */}
                        {props?.sessionData?.household?.drivers?.storedDrivers.map((driver, index) => {
                            return (
                                <div key={index} className="ms-4">
                                    <div className="radio">
                                        <label className="text-uppercase">
                                            <input type={"checkbox"} value={driver.fullName}
                                                checked={formValues?.drivers?.includes(driver.fullName)}
                                                onChange={(e) => updateDrivers(props.condition, e.target.value, driver)}
                                            />&nbsp;&nbsp;{driver.fullName}
                                        </label>
                                    </div>
                                    {(props?.condition == "impairments" && formValues?.drivers?.includes(driver.fullName)) &&
                                        <>
                                            <input type={"text"} className="form-control row ms-3" placeholder="Please explain"
                                                value={(findExplanation(driver) != -1) ? formValues.explanation[findExplanation(driver)].explanation : ""}
                                                onChange={(e) => updateImpairementExplanation(props.condition, driver, e.target.value)}
                                            />
                                        </>
                                    }
                                </div>
                            )
                        })}

                        {/* New Drivers */}
                        {props?.sessionData?.household?.drivers?.newDrivers.map((driver, index) => {
                            return (
                                <div key={index} className="ms-4">
                                    <div className="radio">
                                        <label className="text-uppercase">
                                            <input type={"checkbox"} value={driver.fullName}
                                                checked={formValues?.drivers?.includes(driver.fullName)}
                                                onChange={(e) => updateDrivers(props.condition, e.target.value, driver)}
                                            />&nbsp;&nbsp;{driver.fullName}
                                        </label>
                                    </div>
                                    {(props?.condition == "impairments" && formValues?.drivers?.includes(driver.fullName)) &&
                                        <>
                                            <input type={"text"} className="form-control row ms-3" placeholder="Please explain"
                                                value={(findExplanation(driver) != -1) ? formValues.explanation[findExplanation(driver)].explanation : ""}
                                                onChange={(e) => updateImpairementExplanation(props.condition, driver, e.target.value)}
                                            />
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </div>
                }
            </div >
        </>
    )
}

export default YesNoUnknownResponse