import React from "react";

class Occupation extends React.Component {
    constructor(props) {
        super(props);
        this.props.handleCallBack();
    }

    render () {   
        return (
            <>
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-4 col-lg-12 fw-bold text-blue">
                        Occupation:*
                    </div>
                    <div className="col-xl-6 col-lg-12">
                        <input type={"text"} className="form-control occupation"
                        minLength={4} maxLength={32} value={this.props.occupation}
                        onChange={(e) => {this.props.handleCallBack("occupation", e.target.value)}}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Occupation
