import { useContext } from "react"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { AddVehicleContext } from "./VehicleAddMainMI"
import { StoredAddedVehicles } from "../VehiclesList"

const VehicleAddMI_2 = () => {
    const context = useContext(VehicleChangeContext)
    const vehicleAddContext = useContext(AddVehicleContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const vehicleInfo = ['vehicleMake', 'vehicleYear', 'vehicleModel', 'vehicleBody', 'vehicleVIN']

    // Clears vehicle info retrieved from vin
    const resetNewVehicle = () => {
        var newVehicleCopy = vehicleAddContext.newVehicle
        vehicleInfo.map((value) => {
            newVehicleCopy[value] = ""
        })
        vehicleAddContext.setNewVehicle({ ...newVehicleCopy })
        storedAddedContext.setVehicleObtained(false)
    }

    return (
        <>
            {!context.vinLoad && storedAddedContext.vehicleObtained &&
                <>
                    <div className="form-group form-group-sm">
                        <table>
                            <tbody>
                                {vehicleInfo.map((value, id) => {
                                    const vehicleDetail = value.slice(value.indexOf('vehicle') + "vehicle".length)
                                    return (
                                        <tr key={id}>
                                            <td style={{ paddingRight: "15px" }}>
                                                <strong className="text-muted">{vehicleDetail}</strong>
                                            </td>
                                            <td className="text-uppercase text-muted">{vehicleAddContext.newVehicle[value]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-sm btn-default" onClick={() => {
                            resetNewVehicle();
                        }}>
                            <i className="fa fa-refresh text-primary">&nbsp;</i>
                            <span className="text-primary">
                                RESET VEHICLE INFORMATION
                            </span>
                        </button>
                    </div>
                </>
            }
        </>
    )
}

export default VehicleAddMI_2