import { useContext } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleAddMain from "./VehicleAdd/VehicleAddMain"
import { StoredAddedVehicles } from "./VehiclesList"
import { storageData } from "../../../services/Services"
import VehicleAddMainMI from "./VehicleAddMichigan/VehicleAddMainMI"

const VehiclesAdded = () => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    var newVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles

    // Toggles add menu with newly added vehicle
    const editAddedVehicle = (index) => {
        var editArr = newVehiclesList.map((value, i) => {
            return (index === i) ? true : false
        });
        storedAddedContext.setEditAddedVehicleArr(editArr)
        storedAddedContext.setVehicleObtained(true)
        context.setShowQuestions(false)
    }

    // Removes specified vehicle
    const clear = (vehicle) => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehiclesCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles
        vehiclesCopyList = vehiclesCopyList.filter(v =>
            v !== vehicle
        );
        vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles = vehiclesCopyList
        storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
        storedAddedContext.submittedVehicleChanges()
    }

    return (
        <>
            {/* New Vehicles */}
            {newVehiclesList.map((vehicle, id) => {
                return (
                    <div key={id}>
                        {(storedAddedContext.displayVehicle[id] || !storedAddedContext.displayVehicle.includes(true)) &&
                            <>
                                <hr className="less-margin expandHeight" />
                                <div className="row">
                                    <div className="col-md-7 col-sm-6 col-5">
                                        <strong className="justify-content-left">
                                            <span>
                                                <span className="small text-uppercase text-muted">{`${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`}</span>
                                                <span className="small fw-light fst-italic text-muted"> - New Vehicle</span>
                                            </span>
                                        </strong>
                                    </div>

                                    {/* Delete/Replace buttons for stored vehicles */}
                                    {(storedAddedContext.editType == "" && !context.addVehicleMenu && storedAddedContext.editAddedVehicleArr.length == 0) &&
                                        <div className="col-md-5 col-sm-6 col-7">
                                            <button type="button" className={`mx-1 btn btn-xs btn-default pull-right modifyReplaceBtn`} value={"CANCEL"} onClick={() => { clear(vehicle) }}>
                                                CANCEL
                                            </button>
                                            <button type="button" className={`mx-1 btn btn-xs btn-default pull-right modifyReplaceBtn`} value={"EDIT"} onClick={() => { editAddedVehicle(id) }}>
                                                EDIT
                                            </button>
                                        </div>
                                    }
                                </div>

                                {/* Editing added vehicle menu */}
                                {storedAddedContext.editAddedVehicleArr[id] &&
                                    <>
                                        {context.userData?.state != "Michigan" ?
                                            // New Jersey/Pennsylvania
                                            <VehicleAddMain index={id} type={"edit"} saveEditOption={storedAddedContext.saveEditOption} />
                                            :
                                            // Michigan 
                                            <VehicleAddMainMI index={id} type={"edit"} saveEditOption={storedAddedContext.saveEditOption} />
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                )
            })}
        </>
    )
}

export default VehiclesAdded