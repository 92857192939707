import React from 'react';
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import OneIncAutoPay from '../components/OneInc/OneIncAutoPay';
import OneIncManagePayment from '../components/OneInc/OneIncManagePayment';
import OneIncPayment from '../components/OneInc/OneIncPayment';
import { getCustomerId } from '../services/Api';
import { paymentEndpoint } from '../services/apiConfig';

const OneInc = (props) => {
    var navigate = useNavigate()
    console.log()
    var Modal

    switch ((useQuery()).get("type").toLowerCase()) {
        case "payment":
            Modal = () => <OneIncPayment{...props} navigate={navigate} />

            break;
        case "autopay":
            Modal = () => <OneIncAutoPay{...props} navigate={navigate} />

            break;
        case "manage-payment":
            Modal = () => <OneIncManagePayment{...props} navigate={navigate} />

            break;
        default:
            Modal = () => <Navigate to="/" />

    }
    return (
        <Modal />
    )
}


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default OneInc