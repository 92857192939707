import { storageData } from "services/Services"

const AddressPageValidation = ({ screen, addressChangeClass }) => {
    var storageDataClass = JSON.parse(storageData.getItem("AddressChangeClass"))

    // Checks for all fields that have been filled out on the new address page
    const residencePage = () => {
        // New Jersey / Pennsylvania
        if (addressChangeClass?.policyState != "Michigan") {
            if (!("mailingAddress" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingAddress == "") {
                return { title: "Oops!", message: 'Please provide the mailing address.', valid: false }
            }
            else if (!("mailingCity" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingCity == "") {
                return { title: "Oops!", message: 'Please provide the mailing city.', valid: false }
            }
            else if (!("mailingState" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingState == "") {
                return { title: "Oops!", message: 'Please provide the mailing state.', valid: false }
            }
            else if (!("mailingZip" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingZip == "") {
                return { title: "Oops!", message: 'Please provide the mailing zip.', valid: false }
            }
            else if (!("dateMoved" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.dateMoved == "") {
                return { title: "Oops!", message: 'Please provide the date moved.', valid: false }
            }
            else if (!("vehiclesKept" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.vehiclesKept == "") {
                return { title: "Oops!", message: 'Please select whether the policy vehicles will be kept overnight at this new address.', valid: false }
            }
            else if (addressChangeClass?.newResidence?.vehiclesKept == "No" && addressChangeClass?.newResidence?.vehicleLocation == "") {
                return { title: "Oops!", message: 'Please indicate where the vehicles are or will be kept.', valid: false }
            }
        }
        // Michigan
        if (addressChangeClass?.policyState == "Michigan") {
            var vehiclesOnPolicy = addressChangeClass?.vehicles?.vehiclesOnPolicy
            var allVehicles = [...vehiclesOnPolicy?.storedVehicles, ...vehiclesOnPolicy?.newVehicles]
            var notGaraged = 0
            if (!("michiganRequestedEffectiveDate" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.michiganRequestedEffectiveDate == "") {
                return { title: "Oops!", message: 'Please provide the requested effective date.', valid: false }
            }
            else if (!("moveDate" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.moveDate == "") {
                return { title: "Oops!", message: 'Please provide the date moved.', valid: false }
            }
            else if (!("reasonForChange" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.reasonForChange == "") {
                return { title: "Oops!", message: 'Please provide the reason for change.', valid: false }
            }
            else if (!("mailingAddress" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingAddress == "") {
                return { title: "Oops!", message: 'Please provide the mailing address.', valid: false }
            }
            else if (!("mailingCity" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingCity == "") {
                return { title: "Oops!", message: 'Please provide the mailing city.', valid: false }
            }
            else if (!("mailingState" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingState == "") {
                return { title: "Oops!", message: 'Please provide the mailing state.', valid: false }
            }
            else if (!("mailingZip" in addressChangeClass?.newResidence) || addressChangeClass?.newResidence?.mailingZip == "") {
                return { title: "Oops!", message: 'Please provide the mailing zip.', valid: false }
            }
            else if (!("keptOvernightResponse" in addressChangeClass?.vehicles) ||
                ("keptOvernightResponse" in addressChangeClass?.vehicles && addressChangeClass?.vehicles?.keptOvernightResponse == "")) {
                return { title: "Oops!", message: 'Please provide a selection of whether all vehicles on the policy will be garaged at this new address.', valid: false }
            }
            else if (addressChangeClass?.vehicles?.keptOvernightResponse == "No") {
                // Iterates through all vehicles to check if any are garaged overnight
                for (var i = 0; i < allVehicles.length; i++) {
                    if (!allVehicles[i]?.garaged || allVehicles[i]?.garaged == undefined) {
                        notGaraged++
                    }
                    else if (allVehicles[i]?.garaged) {
                        if (!("garagedStreetAddress" in allVehicles[i]) || allVehicles[i]?.garagedStreetAddress == "") {
                            return { title: "Oops!", message: 'Please provide the street address for this garaged vehicle.', valid: false }
                        }
                        else if (!("garagedCity" in allVehicles[i]) || allVehicles[i]?.garagedCity == "") {
                            return { title: "Oops!", message: 'Please provide the city for this garaged vehicle.', valid: false }
                        }
                        else if (!("garagedZipCode" in allVehicles[i]) || allVehicles[i]?.garagedZipCode == "") {
                            return { title: "Oops!", message: 'Please provide the zip code for this garaged vehicle.', valid: false }
                        }
                    }
                }
                // If no vehicles are garaged but selected "no" for kept overnight
                if (notGaraged == allVehicles.length) {
                    return { title: "Oops!", message: 'Please select a vehicle that will be garaged overnight.', valid: false }
                }
                else {
                    return
                }
            }
            else {
                return
            }
        }
    }
    // Household page date of change field for non-michigan policies
    const householdPage = () => {
        // if (addressChangeClass?.policyState != "Michigan") {
        //     if (addressChangeClass?.driversHHMs?.dateOfChange == "") {
        //         return { title: "Oops!", message: 'Please provide the date that these drivers/household members changed.', valid: false }
        //     }
        // }
    }
    // Checks vehicle selection and vehicle uses for driver assignment page
    const driverAssignmentPage = () => {
        var drivers = addressChangeClass?.driverAssignment?.drivers
        for (var i = 0; i < drivers.length; i++) {
            // Michigan
            if (storageDataClass?.policyState == "Michigan") {
                if (!("driverStatus" in drivers[i]) || (drivers[i]?.driverStatus == "") || "driverStatus" in drivers[i] && drivers[i]?.driverStatus == "New Driver") {
                    if (drivers[i]?.vehicle != "NotPrimaryDriver") {
                        if (!("vehicle" in drivers[i]) || drivers[i]?.vehicle == null) {
                            return { title: "Oops!", message: 'Please choose a primary driver for each vehicle listed.', valid: false }
                        }
                        else if (!("vehicleUse" in drivers[i]) || drivers[i]?.vehicleUse == "") {
                            return { title: "Oops!", message: 'Please select how the vehicle is being used for ' + drivers[i]?.fullName, valid: false }
                        }
                        else if ((drivers[i]?.vehicleUse == "Business" || drivers[i]?.vehicleUse == "Farm") && (!("vehicleUseExplanation" in drivers[i]) || drivers[i]?.vehicleUseExplanation == "")) {
                            return { title: "Oops!", message: `Please explain the type of ${drivers[i]?.vehicleUse} on how the vehicle is being used.`, valid: false }
                        }
                        else if (!("annualMileage" in drivers[i]) || drivers[i]?.annualMileage == "") {
                            return { title: "Oops!", message: 'Please select an estimated annual mileage of the vehicle for ' + drivers[i]?.fullName, valid: false }
                        }
                    }
                }
            }
            // New Jersey/Pennsylvania
            else {
                if (drivers[i]?.vehicle != "NotPrimaryDriver") {
                    if (!("driverStatus" in drivers[i]) || (drivers[i]?.driverStatus == "") || "driverStatus" in drivers[i] && drivers[i]?.driverStatus == "New Driver") {
                        if (!("vehicle" in drivers[i]) || drivers[i]?.vehicle == null) {
                            return { title: "Oops!", message: 'Please choose a primary driver for each vehicle listed.', valid: false }
                        }
                        else if (!("daysPerWeek" in drivers[i]) || drivers[i]?.daysPerWeek == null) {
                            return { title: "Oops!", message: 'Please provide the number of days per week the vehicle is used for ' + drivers[i]?.fullName, valid: false }
                        }
                        else if (!("milesOneWay" in drivers[i]) || drivers[i]?.milesOneWay == null) {
                            return { title: "Oops!", message: 'Please provide a selection for the number of miles one-way for ' + drivers[i]?.fullName, valid: false }
                        }
                    }
                }
            }
        }
    }

    // Checks for all condition/disorder questions have been answered
    const additionalPage = () => {
        var additionalPage = addressChangeClass?.additionalPage

        if (additionalPage?.suspended == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have been suspended in the past 36 months.', valid: false }
        }
        else if ((additionalPage?.suspended == "Yes" || additionalPage?.suspended == "Unknown") && additionalPage?.suspendedDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have been suspended within the past 36 months.', valid: false }
        }
        else if (additionalPage?.seizures == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have experienced Seizure Disorders in the past 36 months.', valid: false }
        }
        else if ((additionalPage?.seizures == "Yes" || additionalPage?.seizures == "Unknown") && additionalPage?.seizuresDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have experienced Seizure Disorders within the past 36 months.', valid: false }
        }
        else if (additionalPage?.narcolepsy == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have experienced Narcolepsy in the past 36 months.', valid: false }
        }
        else if ((additionalPage?.narcolepsy == "Yes" || additionalPage?.narcolepsy == "Unknown") && additionalPage?.narcolepsyDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have experienced Narcolepsy within the past 36 months.', valid: false }
        }
        else if (additionalPage?.syncope == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have experienced Syncope (Fainting) in the past 36 months.', valid: false }
        }
        else if ((additionalPage?.syncope == "Yes" || additionalPage?.syncope == "Unknown") && additionalPage?.syncopeDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have experienced Syncope (Fainting) within the past 36 months.', valid: false }
        }
        else if (additionalPage?.blindness == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have experienced blindness in one or both eyes in the past 36 months, unless corrected by prescription eyeglasses or contact lenses.', valid: false }
        }
        else if ((additionalPage?.blindness == "Yes" || additionalPage?.blindness == "Unknown") && additionalPage?.blindnessDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have experienced blindness within the past 36 months.', valid: false }
        }
        else if (additionalPage?.impairments == "") {
            return { title: "Oops!", message: 'Please select whether or not any drivers have any mental or physical impairments.', valid: false }
        }
        else if ((additionalPage?.impairments == "Yes" || additionalPage?.impairments == "Unknown") && additionalPage?.impairmentsDrivers?.length == 0) {
            return { title: "Oops!", message: 'Please select which drivers have mental or physical impairments.', valid: false }
        }
        // Kept Overnight Questions
        else if (additionalPage?.keptOvernight?.response == "") {
            return { title: "Oops!", message: 'Please select whether the vehicle(s) on the policy is kept overnight at your home address.', valid: false }
        }
        else if (additionalPage?.keptOvernight?.response == "No" && additionalPage?.keptOvernight?.address == "") {
            return { title: "Oops!", message: 'Please provide the address for where the vehicle is kept.', valid: false }
        }
        else if (additionalPage?.keptOvernight?.response == "No" && additionalPage?.keptOvernight?.city == "") {
            return { title: "Oops!", message: 'Please provide the city for where the vehicle is kept.', valid: false }
        }
        else if (additionalPage?.keptOvernight?.response == "No" && additionalPage?.keptOvernight?.state == "") {
            return { title: "Oops!", message: 'Please provide the state for where the vehicle is kept.', valid: false }
        }
        else if (additionalPage?.keptOvernight?.response == "No" && additionalPage?.keptOvernight?.zip == "") {
            return { title: "Oops!", message: 'Please provide the zip for where the vehicle is kept.', valid: false }
        }
    }

    // Checks phone number input for summary page
    const summaryPage = () => {
        var phoneNumber = addressChangeClass?.summary?.phoneNumber
        if (phoneNumber == "") {
            return { title: "Oops!", message: "You must enter a valid phone number.", valid: false }
        }
    }

    var validCheck

    // Residence
    if (screen == "new-address") {
        validCheck = { ...residencePage() }
    }
    // Household Page
    else if (screen == "household") {
        validCheck = { ...householdPage() }
    }
    // Driver Assignment Page
    else if (screen == "driver-assignment") {
        validCheck = { ...driverAssignmentPage() }
    }
    else if (screen == "additional") {
        validCheck = { ...additionalPage() }
    }
    // Summary Page
    else if (screen == "summary") {
        validCheck = { ...summaryPage() }
    }

    if (validCheck) {
        if (!validCheck?.valid && Object.keys(validCheck) != 0) {
            return validCheck
        }
    }

    return { valid: true }
}

export default AddressPageValidation