import React, { useContext } from 'react'
import { VehicleConfirm_SaveContext } from './VehicleConfirm_Main';

const CheckboxConfirm = ({styles}) => {
    const confirm_saveContext = useContext(VehicleConfirm_SaveContext);
    const confirmChanges = confirm_saveContext.confirmChanges;

    return (
        <>
            <div>
                <h5 className='text-primary text-center fw-bold mt-5 mb-3'>CONFIRM SELECTIONS</h5>
            </div>
            <div className='row mb-3'>
                <div className='col-1' style={styles.checkboxContainer}>
                    <input 
                        type='checkbox' 
                        id='confirm-checkbox'
                        onChange={(e) => { confirm_saveContext.saveConfirmCheckbox(e.target.checked) }}
                        checked={confirmChanges}
                    ></input>
                </div>
                <div className='col-11 text-dark'>
                    <label htmlFor='confirm-checkbox'>
                        I hereby certify that the answers to the questions listed in the change are true and accurate
                        to the best of my knowledge.*
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-12 text-center fst-italic'>
                    To make any changes to your above selections, click BACK.
                </div>
            </div>
        </>
    )
}

export default CheckboxConfirm