const EditDriverValidationMI = (props) => {
    if (props.editFields?.editDriver == "") {
        props.handleShow()
        props.handleValidationMsg("Please select what you would like to edit about this driver.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer drives policy vehicles and still resides in household." && props.editFields?.obtainedOwnCarInsurance == "") {
        props.handleShow()
        props.handleValidationMsg("Please provide a selection if the driver obtained their own car insurance.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer has valid driver's license." && props.editFields?.licenseStatus == "") {
        props.handleShow()
        props.handleValidationMsg("Please confirm the status of this driver's license.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer resides in household." && props.editFields?.takingVehicles == "") {
        props.handleShow()
        props.handleValidationMsg("Please provide a selection if the driver is taking any policy vehicles with them to their new address.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer resides in household." && props.editFields?.takingVehicles == "1" && props.editFields?.vehiclesTaken?.length == 0) {
        props.handleShow()
        props.handleValidationMsg("Please confirm which vehicles are being taken.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer resides in household." && props.editFields?.moveDate == "") {
        props.handleShow()
        props.handleValidationMsg("Please provide the date of when this person moved.", "Oops!")
        return false
    }
    else {
        return true
    }
}

export default EditDriverValidationMI