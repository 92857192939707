import { useContext } from "react"
import ValidationModal from "../../ValidationModal"
import VehiclesList from "./VehiclesList"
import VehiclesQuestions from "./VehiclesQuestions"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleChangeBtnNavigation from "../VehicleChangeBtnNavigation"
import VehicleMI_Questions from "./VehicleAddMichigan/VehicleMI_Questions/VehicleMI_Questions"

const VehicleMain = () => {
    const context = useContext(VehicleChangeContext)

    return (
        <>
            <VehiclesList />
            {context.showQuestions &&
                <>
                    {!context.addVehicleMenu &&
                        <>
                            {/* New Jersey/Pennsylvania */}
                            {context.userData.state != "Michigan" &&
                                <VehiclesQuestions />
                            }
                            {/* Michigan */}
                            {context.userData.state == "Michigan" && !context.cancelPolicyAddVeh &&
                                <VehicleMI_Questions />
                            }
                            <VehicleChangeBtnNavigation />
                        </>
                    }
                </>
            }
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default VehicleMain