const PendingAddress = (props) => {
    var pendingAddress = props.pendingAddress
    var state = props.userState
    return (
        <>
            {/* Subtitle */}
            <div className="d-flex align-content-center flex-column">
                <h6 className="section-title">
                    PENDING ADDRESS CHANGE
                </h6>

                {/* Displays Current Address */}
                <small className="p-2">
                    <strong >
                        {/* NJ / PA */}
                        {(state == "New Jersey" || state == "Pennsylvania") &&
                            <p>
                                {pendingAddress.StreetAddress}<br />
                                {`${pendingAddress.City}, ${pendingAddress.State + " " + pendingAddress.Zip}`}
                            </p>
                        }
                        {/* MI */}
                        {state == "Michigan" &&
                            <div className="row mx-1">
                                <div className="col-6">
                                    <strong className="text-primary">
                                        Mailing Address
                                    </strong>
                                    <p className="my-2">
                                        {pendingAddress.StreetAddress}<br />
                                        {`${pendingAddress.City}, ${pendingAddress.State + " " + pendingAddress.Zip}`}
                                    </p>
                                </div>
                            </div>
                        }
                    </strong>
                </small>

                {/* Note */}
                <small className="p-2 mb-3 mx-3" style={{ color: "#999999" }}>
                    ** The above address is currently under review. Use the
                    <strong> {(state == "Michigan") ? "Change Address" : "New Residence"} </strong>
                    form below if there is a typo or misspelling.
                </small>
            </div>
        </>
    )
}

export default PendingAddress