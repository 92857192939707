const relation = {
    value: "Relation",
    relations: [
        "Select from the following...",
        "Spouse",
        "Child",
        "Parent",
        "Other Relative",
        "Other",
    ]
}
export default relation