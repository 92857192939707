import React from "react";
import { storageData } from "../../../services/Services";
import ValidationModal from "../../ValidationModal";
import { AddMember, EditMember } from "..";
import EditMemberValidation from "../../ChangeRequest_AddressChangeComp/ValidationConditions/EditMemberValidationMI";


class HouseholdSect extends React.Component {
    state = {
        showEditStored: [],
        showEditAdded: [],
        newMember: [],
        pendingMembers: [],
        hideBtn: false,
        editMember: false,
        editOrAdd: 0,
        show: false,
        validationMsg: "",
        validationTitle: "",
        licenseNum: ""
    };
    // Validation Modal
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    // Cancel/Edit Button (New Member)
    editMember = (e, i) => {
        if (e.target.value === "CANCEL") {
            var cancelArr = this.props.displayMembers.map((value) => {
                return false;
            });
            this.setState({ showEditAdded: cancelArr, hideBtn: false, editOrAdd: 0 });
        }
        else if ((e.target.value === "EDIT") || (e.target.value === "SAVE")) {
            this.setState({ editOrAdd: 1 })
            var editArr = this.props.displayMembers.map((value, index) => {
                return (i === index) ? true : false
            });
            if (e.target.value === "EDIT") {
                this.setState({ showEditAdded: editArr, hideBtn: true })
            }
            else {
                this.setState({ showEditAdded: editArr, hideBtn: false, editOrAdd: 0 })
            }
        }
    }
    // Editing an Existing Member
    editExistingMember = (e, i) => {
        if (e.target.value === "EDIT") {
            this.props.addedEdit("")
            this.setState({ editOrAdd: 4 })
            var editArr = this.props.currMembers.map((value, index) => {
                return (i === index) ? true : false
            });
            this.setState({ showEditStored: editArr, hideBtn: true })
        }
        else if (e.target.value === "CANCEL") {
            var cancelArr = this.props.currMembers.map((value) => {
                return false;
            });
            var currMemberCopy = this.props.currMembers;
            var currMemberEditCopy = this.props.currMemberEdit;
            currMemberEditCopy[i] = ""
            currMemberCopy[i].memberStatus = ""
            currMemberCopy[i].licenseNum = ""
            this.props.setCurrMembers(currMemberCopy)
            this.props.setCurrMemberEdit(currMemberEditCopy)
            this.setState({ showEditStored: cancelArr, hideBtn: false, editOrAdd: 0, licenseNum: "" });
            storageData.setItem('storedHouseholdMembers', JSON.stringify(this.props.currMembers))
        }
    }
    // Clear Member
    clear = (value) => {
        var members = this.props.displayMembers;
        members = members.filter(item =>
            item !== value
        );
        this.props.newMembers(members);
        storageData.setItem('newMembers', JSON.stringify(members))
    }
    // Add Member
    addNewMember = () => {
        var householdMembers = storageData.getItem('householdMembers')
        var addedMember;
        var showEditFalse;
        if (householdMembers && householdMembers !== "[]") {
            householdMembers = JSON.parse(householdMembers)
            for (var i = 0; i < householdMembers.length; i++) {
                this.props.addedEdit(householdMembers[i].memberStatus);
                addedMember = this.state.newMember;
                addedMember.push(householdMembers[i].memberStatus)
                showEditFalse = this.state.showEditAdded;
                showEditFalse.push(false)
            }
        }
        else {
            this.props.addedEdit("New Resident");
            addedMember = this.state.newMember;
            addedMember.push("New Resident")
            showEditFalse = this.state.showEditAdded;
            showEditFalse.push(false)
        }
        this.setState({ newMember: addedMember, showEditAdded: showEditFalse });
    }
    // New Member Toggle
    handleMember = () => {
        this.setState({ hideBtn: true, editOrAdd: 2 });
    };
    // Handles changes to usestate
    handleState = (key, value) => {
        var info = {};
        info[key] = value;
        this.setState(info)
    }
    // Save Edit
    saveEditMember = (e, i) => {
        var copiedState = this.props.currMembers

        // EditMemberValidation
        // if(this.props.addEdit){
        //     if (this.props.addEdit === "MOVED") {
        //         copiedState[i].memberStatus = "Resides Elsewhere";
        //     }
        //     else if (this.props.addEdit === "OWNINSURANCE") {
        //         copiedState[i].memberStatus = "Has Other Insurance";
        //     }
        // }
        // else if (this.props.addEdit === "LICENSED") {
        //     this.handleShow()
        //     this.setState({ validationMsg: "Please select if this person should be added as a driver to the policy or if this person has their own insurance.", validationTitle: "Oops!" })
        //     return
        // }
        // else if (this.props.addEdit === "") {
        //     this.handleShow()
        //     this.setState({ validationMsg: "Please select what you would like to edit about the household member.", validationTitle: "Oops!" })
        //     return
        // }
        // Moves member to driver
        // else if (this.props.addEdit === "ADD") {
        //     // Adds member to driver list
        //     delete copiedState[i].memberStatus;
        //     copiedState[i]["driverStatus"] = "Adding As Driver";
        //     copiedState[i]["licenseNum"] = this.state.licenseNum;
        //     var copyEdit = [...this.props.currDriverEdit, copiedState[i].driverStatus]
        //     var driverList = storageData.getItem('storedDrivers');
        //     var updatedDriverList;
        //     if (driverList) {
        //         updatedDriverList = JSON.parse(driverList);
        //     }
        //     updatedDriverList.push(copiedState[i]);
        //     storageData.setItem("storedDrivers", JSON.stringify(updatedDriverList));
        //     this.props.setCurrDrivers(updatedDriverList);
        //     this.props.setCurrDriverEdit(copyEdit);

        //     // Removes member from list
        //     var newMemberList = this.props.currMembers.filter(member => member.fullName !== copiedState[i].fullName)
        //     this.props.setCurrMembers(newMemberList)
        //     var newMemberEditList = this.props.currMemberEdit.filter((status, index) => index !== i)
        //     this.props.setCurrMemberEdit(newMemberEditList)
        //     this.setState({ editOrAdd: 0, hideBtn: false, licenseNum: "" });
        //     storageData.setItem("storedHouseholdMembers", JSON.stringify(newMemberList))
        //     storageData.removeItem("householdAdditionalData")
        //     storageData.removeItem('additionalData')
        //     storageData.removeItem('driverAssignmentData')
        //     this.props.firstSet("");
        //     return
        // }
        this.props.setCurrMembers(copiedState)
        var arr = this.props.currMembers.map((value, index) => {
            return (i === index) ? this.props.currMembers[index].memberStatus : this.props.currMemberEdit[index];
        });
        this.props.setCurrMemberEdit(arr)
        this.setState({ editOrAdd: 0, hideBtn: false, licenseNum: "" });
        storageData.setItem('storedHouseholdMembers', JSON.stringify(this.props.currMembers))
        storageData.removeItem("householdAdditionalData")
        storageData.removeItem('additionalData')
        storageData.removeItem('driverAssignmentData')
        this.props.firstSet("");
    };

    // Retrieves and returns status for display
    filterStatus = (status) => {
        if (status == "OHMAddAsDriver") {
            return "OHM Added as Driver"
        }
        else if (status == "OHMMoved") {
            return "OHM Moved"
        }
        else if (status == "OHMOwnInsurance") {
            return "OHM w/ Own Insurance"
        }
    }

    componentDidMount() {
        // Retrieves stored members
        const storedMembers = storageData.getItem('storedHouseholdMembers')
        if (storedMembers) {
            var retrievedStoredMembers = JSON.parse(storedMembers)
            var memberInfo = retrievedStoredMembers.map((member) => {
                return member.memberStatus
            })

            // Retrieves any pending users
            var retrievedPendingAddedDriversMembers = storageData.getItem("PendingDrivers")
            var retrievedPendingRemovedDriversMembers = storageData.getItem("PendingRemovedDrivers")

            // Pending Members
            var pendingMemberArr = [];
            var pendingMemberInfo = {};

            if (retrievedPendingAddedDriversMembers || retrievedPendingRemovedDriversMembers) {
                retrievedPendingAddedDriversMembers = JSON.parse(retrievedPendingAddedDriversMembers);
                retrievedPendingRemovedDriversMembers = JSON.parse(retrievedPendingRemovedDriversMembers);
                var retrievedPendingDriversMembers = [...retrievedPendingAddedDriversMembers, ...retrievedPendingRemovedDriversMembers]

                for (var i = 0; i < retrievedPendingDriversMembers.length; i++) {
                    var date = new Date(retrievedPendingDriversMembers[i].DOB);

                    // For Added Members
                    if (retrievedPendingDriversMembers[i]["SelectedType"] === "Ohm"
                        && retrievedPendingDriversMembers[i]["FirstName"] !== ""
                        && (retrievedPendingDriversMembers[i]["Status"] == "NoChange" || retrievedPendingDriversMembers[i]["Status"] == "NewHouseholdMember")) {
                        pendingMemberInfo["fullName"] = retrievedPendingDriversMembers[i].FirstName;
                        pendingMemberInfo["mStatus"] = retrievedPendingDriversMembers[i].MaritalStatus;
                        pendingMemberInfo["gender"] = retrievedPendingDriversMembers[i].Sex;
                        pendingMemberInfo["DOB"] = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                        pendingMemberInfo["memberStatus"] = "ADD PENDING";
                        pendingMemberArr.push(pendingMemberInfo);
                    }

                    // For transferred ohm to driver
                    if (retrievedPendingDriversMembers[i]["SelectedType"] === "Driver" && retrievedPendingDriversMembers[i]["Status"] == "OHMAddAsDriver") {
                        retrievedStoredMembers = retrievedStoredMembers.filter((member) => {
                            return retrievedPendingDriversMembers[i]["Status"] == "OHMAddedAsDriver"
                        });
                    }

                    // For removed/changed members
                    if ((retrievedPendingDriversMembers[i]["SelectedType"] === "Remove" || retrievedPendingDriversMembers[i]["SelectedType"] === "Ohm") && (retrievedPendingDriversMembers[i]["Status"] != "NoChange" || retrievedPendingDriversMembers[i]["Status"] == "OHMAddedAsDriver") && retrievedPendingDriversMembers[i]["Status"] != "NewHouseholdMember") {
                        retrievedStoredMembers = retrievedStoredMembers.filter((member) => {
                            return retrievedPendingDriversMembers[i].FirstName.toLowerCase() != member.fullName.toLowerCase()
                        });

                        // Removes drivers from this list
                        if (retrievedPendingDriversMembers[i]["SelectedType"] == "Remove" && retrievedPendingDriversMembers[i]["Status"] != "OHMMoved") {
                            continue
                        }
                        var memberObj = {};
                        memberObj["fullName"] = retrievedPendingDriversMembers[i].FirstName
                        memberObj["mStatus"] = retrievedPendingDriversMembers[i].MaritalStatus;
                        memberObj["gender"] = retrievedPendingDriversMembers[i].Sex;
                        memberObj["DOB"] = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
                        memberObj["memberStatus"] = this.filterStatus(retrievedPendingDriversMembers[i].Status);
                        pendingMemberArr.push(memberObj);
                    }
                }
                this.setState({ pendingMembers: [...pendingMemberArr] })
            }

            this.props.setCurrMembers(retrievedStoredMembers)
            this.props.setCurrMemberEdit(memberInfo)
            this.props.setStoredData(retrievedStoredMembers)
        }
        // Retrieves added members
        const memberData = storageData.getItem('newMembers')
        if (memberData) {
            var retrievedAddedMembers = JSON.parse(memberData)
            this.props.newMembers(retrievedAddedMembers)
            var addedMember = this.state.newMember;
            for (var i = 0; i < retrievedAddedMembers.length; i++) {
                addedMember.push("New Resident")
                this.setState({ newMember: addedMember });
            }
            this.props.setStoredData(retrievedAddedMembers)
        }

    }
    render() {
        const { showEditStored, showEditAdded, hideBtn, newMember } = this.state;
        const editCancelToggle = (value) => {
            if ((value === 1) || (value === 2)) {
                return true;
            }
            else {
                return false;
            }
        }
        return (
            <>
                {/* Subtitle */}
                <div>
                    <br />
                    <h6 className="text-center no-margin household-text-blue fw-bold">
                        HOUSEHOLD MEMBERS
                    </h6>
                    {/* Other Household Members */}
                    <p className="text-center form-group">
                        Our records indicate these are the people that live at the
                        policy address (including children even if away at school):
                    </p>
                    <div className="clearfix" />
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-7 col-md-8">
                            {/* Stored members */}
                            {this.props.currMembers.map((member, index) => {
                                return (
                                    <div key={index}>
                                        <hr className="less-margin" />
                                        <div>
                                            {/* Displays Members */}
                                            <strong className="text-blue">
                                                <span className="text-uppercase lowerFont">{`${member.fullName}, ${member.DOB}`}</span>
                                                <span className={`fw-bold ${this.props.currMemberEdit[index]}`}>{this.props.currMemberEdit[index] === "" ? "" : ` - ${this.props.currMemberEdit[index]}`}</span>
                                            </strong>
                                            {/* Edit */}
                                            <button type="button" className={`mx-1 btn btn-xs btn-default pull-right
                                            ${this.state.editOrAdd === 4 ? "hide" : ""}`}
                                                value={"EDIT"} onClick={(e) => { this.editExistingMember(e, index) }}>
                                                EDIT
                                            </button>
                                            <button type="button" className={`btn btn-xs btn-default pull-right
                                            ${(this.props.currMemberEdit[index] === "") || (this.props.currMemberEdit[index] === undefined) || (this.props.currMemberEdit.length === 0) ? "hide" : ""}`}
                                                value={"CANCEL"} onClick={(e) => { this.editExistingMember(e, index) }}>
                                                CANCEL
                                            </button>
                                            {showEditStored[index] && this.state.editOrAdd === 4 && <EditMember
                                                vlQuestion1={this.props.vlQuestion1}
                                                firstSet={this.props.firstSet}
                                                vlQuestion2={this.props.vlQuestion2}
                                                secondSet={this.props.secondSet}
                                                addEdit={this.props.addEdit}
                                                addedEdit={this.props.addedEdit}
                                                setLicenseNum={this.handleState}
                                            />}
                                            {showEditStored[index] && this.state.editOrAdd === 4 &&
                                                <div className="buttons">
                                                    <button type="button" className="btn btn-sm btn-default" value={"CANCEL"} onClick={(e) =>
                                                        this.editMember(e, index)}>
                                                        CANCEL&nbsp;
                                                    </button>
                                                    <button type="button" className="btn btn-sm btn-primary-grad ms-1" value={"SAVE"} onClick={(e) =>
                                                        this.saveEditMember(e, index)}>
                                                        SAVE
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            {/* Pending Members */}
                            {this.state.pendingMembers.map((member,index) => {
                                return (
                                    <div key={index}>
                                        <hr className="less-margin" />
                                        <strong className="text-blue justify-content-left">
                                            <span className="text-uppercase">{member.fullName}, {member.DOB}</span>
                                            <span className={`text-uppercase fst-italic`}> - {member.memberStatus}</span>
                                        </strong>
                                    </div>
                                )
                            })}
                            {/* Added Members */}
                            {this.props.displayMembers.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <hr className="less-margin expandHeight" />
                                        <div>
                                            {/* Displays Members */}
                                            <strong className="text-blue justify-content-left lowerFont">
                                                <span className="text-uppercase">{item.fullName}, {item.monthSelect}/{item.daySelect}/{item.yearSelect}</span>
                                                <span className="text-uppercase fst-italic"> - {newMember[index]}</span>
                                            </strong>
                                            <span className={`${editCancelToggle(this.state.editOrAdd) ? 'hide' : ''}`}>
                                                <button className={`mx-1 btn btn-xs btn-default pull-right 
                                                    ${showEditAdded[index] ? "hide" : ""}`} value={"EDIT"}
                                                    onClick={(e) => this.editMember(e, index)}>
                                                    EDIT
                                                </button>
                                                <button className={`btn btn-xs btn-default pull-right
                                                    ${showEditAdded[index] ? "hide" : ""}`} value={"CANCEL"}
                                                    onClick={() => this.clear(item)}>
                                                    CANCEL
                                                </button>
                                            </span>
                                        </div>
                                        {showEditAdded[index] && hideBtn && this.state.editOrAdd === 1 &&
                                            <AddMember
                                                newMembers={this.props.newMembers}
                                                displayMembers={this.props.displayMembers}
                                                addNewMember={this.addNewMember}
                                                newMember={newMember}
                                                editMember={this.state.editMember}
                                                hideBtnFunc={(e) => { this.editMember(e) }}
                                                index={index}
                                            />}
                                    </div>
                                )
                            })}
                            <hr className="less-margin expandHeight" />
                            {/* New Member Menu */}
                            {hideBtn && this.state.editOrAdd === 2 &&
                                <AddMember
                                    newMembers={this.props.newMembers}
                                    displayMembers={this.props.displayMembers}
                                    addNewMember={this.addNewMember}
                                    newMember={newMember}
                                    editMember={this.state.editMember}
                                    hideBtnFunc={(e) => { this.editMember(e) }}
                                    index={this.props.displayMembers.length}
                                />}
                            {/* Add New Member Button*/}
                            <div className={`${hideBtn ? "hide" : hideBtn}`}>
                                <div className="slide-toggle text-center">
                                    <button type="button" className="btn btn-primary-grad" onClick={this.handleMember}>
                                        Add New Household Member
                                    </button>
                                </div>
                            </div>
                            <ValidationModal
                                show={this.state.show}
                                handleClose={this.handleClose}
                                validationMsg={this.state.validationMsg}
                                title={this.state.validationTitle} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default HouseholdSect