import { Col } from "react-bootstrap";

const FormRadio = (props) => {
    const {
        id,
        index,
        value,
        label,
        col = {
            label: {
                xs: 12
            },
            input: {
                xs: 12
            },
            caret: false
        },
        className = {
            margin: "my-3",
            marginBottom: "mb-0",
            marginSecond: "my-2"
        },
        nested,
        placeholder,
        options,
        callback
    } = props
    return (
        <>
            <div className={`row form-group form-group-sm ${className.margin}`}>
                <Col {...col?.label}>
                    <div className={`text-blue fw-bold ${className?.marginBottom}`}>
                        {label}
                    </div>
                </Col>
                {options.map((val, i) =>
                    <Col {...col?.input} key={i}>
                        <div className="radio-inline form-check form-check-inline" key={i}>
                            <label className="form-check-label">
                                <input className="form-check-input" name={`${index}`} type={"radio"} value={val.id}
                                    onChange={(e) => callback(id, e.target.value)}
                                    defaultChecked={value == val.id} />{val.value}
                            </label>

                            {/* Dropdown FOR EDITED MEMBER ONLY */}
                            <div className={`${className.marginSecond}`}>
                                {value == val.id && val?.nested?.map((valNested, j) =>
                                    <Col key={j}>
                                        <label className="form-check-label nested-question my-1" key={j}>
                                            <input className="form-check-input" name={`${index}-${i}`} type={"radio"} value={valNested.id}
                                                onChange={(e) => callback(nested.id, e.target.value)}
                                                defaultChecked={nested.value == valNested.id} />{valNested.value}
                                        </label>
                                    </Col>
                                )
                            }
                            </div>
                        </div>
                    </Col>
                )}
            </div>
        </>
    )
}

export default FormRadio