import { Link, useNavigate } from "react-router-dom";
import { storageData } from "../services/Services";
import { getDomain } from "../services/Utils";

const Menu = (props) => {
    var { Title, FirstName } = props
    const navigate = useNavigate()
    const Logout = () => {
        storageData.clear()
        window.location = getDomain()
    }

    const clear = () => {
        // Removes changes for extra storage in local storage
        storageData.removeItem("drivers");
        storageData.removeItem("newDrivers");
        storageData.removeItem("members");
        storageData.removeItem("newMembers");
        storageData.removeItem("effectiveDate");
        storageData.removeItem("currResidence");
        storageData.removeItem("vehicleInfo");
        storageData.removeItem("PendingAddress");
        storageData.removeItem("PendingAddedDrivers");
        storageData.removeItem("PendingAddedHhms");
        storageData.removeItem("PendingRemovedDrivers");
        storageData.removeItem("PendingRemovedHhms");
        storageData.removeItem("PendingAddedVehicles");
        storageData.removeItem("PendingRemovedVehicles");
        storageData.removeItem("newResidenceData");
        storageData.removeItem("householdData");
        storageData.removeItem("additionalData");
        storageData.removeItem("driverAssignmentData");
        storageData.removeItem("summaryChanges");
        storageData.removeItem("updatedAddedDrivers");
        storageData.removeItem("AddressChangeConfirmationNumber");
        storageData.removeItem("updateHouseholdData");
        storageData.removeItem("householdAdditionalData");
        storageData.removeItem("householdSummaryChanges");
        storageData.removeItem("HouseholdChangeConfirmationNumber");
        storageData.removeItem("updateVehicleData");
        storageData.removeItem("updateDriverAssignmentData");
        storageData.removeItem("newVehicles");
        storageData.removeItem("dbVehicles");
        storageData.removeItem("vehicleSummaryChanges");
        storageData.removeItem("VehicleChangeConfirmationNumber");
        storageData.removeItem("storedDrivers");
        storageData.removeItem("storedHouseholdMembers");
        storageData.removeItem("storedVehicles");
        storageData.removeItem("LeasingList");
        storageData.removeItem("FinanceCompanyList");
        storageData.removeItem("LeasingListGW");
        storageData.removeItem("FinanceListGW");
        storageData.removeItem("VehicleChangeClass");
        storageData.removeItem("HouseholdChangeClass");
        storageData.removeItem("AddressChangeClass");
        storageData.removeItem("VehicleChangeID");
        storageData.removeItem("HouseholdChangeID");
        storageData.removeItem("AddressChangeID");
        storageData.removeItem("AddVehicleClass");
        storageData.removeItem("responseData");
    }

    return (
        <>
            <div className=" p-1 d-flex justify-content-center container-fluid header-nav">
                <div className="col-12 col-md-7 row p-1">

                    <div className="col-12 col-md-12">
                        <div>
                            <div className="row text-center text-md-end">
                                <h3 className="menu-font py-2">
                                    {Title}
                                </h3>
                            </div>
                            <div className="d-flex flex-row justify-content-center justify-content-md-end menu-buttons input-group">
                                <button className="btn btn-light disabled text-end d-none d-md-block">
                                    <p className="menu-button">Hi {FirstName}</p>
                                </button>

                                <Link to="/home" className="btn btn-light text-primary border" onClick={clear}>
                                    <i className="fa fa-home" />
                                </Link>
                                <Link to="/settings" className="btn btn-light border">
                                    <p className="menu-button">{props.userData.state === "Michigan" ? "Change Settings" : "Change Login/Password"}</p>
                                </Link>
                                <button onClick={Logout} className="btn btn-light border">
                                    <p className="menu-button">Logout</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Menu;
