import React, { useContext, useRef, useState } from "react";
import { mStatus as mStatusOpt, gender as genderOpt, relation, insuranceType as insuranceTypeOpt, states, dlStatus, FormAuto } from "../..";
import ValidationModal from "../../../ValidationModal";
import AddDriverValidation from "../../../ChangeRequest_AddressChangeComp/ValidationConditions/AddDriverValidation";
import { storageData } from "../../../../services/Services";
import NotSameHousehold from "../../NotSameHousehold";
import { relationship } from "components/ChangeRequestSharedComp/Household/StateObjects/Relationship";

const AddDriver = (props) => {
    var { newDrivers, driverList, index, setAddDriver, changeReqContext, reqChangeClass, setChangeClass } = props
    const context = useContext(changeReqContext)
    var changeClass = context[reqChangeClass]
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"

    const [state, setState] = useState((index >= driverList.length) ? {
        DOB: "",
        mStatus: "",
        gender: "",
        sameHousehold: "",
        residenceAddress: "",
        residenceCity: "",
        residenceState: context?.userData?.state == "Pennsylvania" ? "PA" : "NJ",
        residenceZip: "",
        dLicense: "",
        licenseNum: "",
        licenseState: context?.userData?.state,
        licenseOtherState: "",
        fullName: "",
        daySelect: "",
        yearSelect: "",
        monthSelect: "",
        licenseStatus: "",
        insuredRelationship: "",
        user: "driver",
        driverStatus: "New Driver",
        initialType: "New",
        hideBtn: false,
        toggleAuto: false,
        show: false,
        enrolledInCollege: "",
        collegeName: "",
        collegeCity: "",
        collegeState: "",
        vehicleKeptAtSchool: "",
        over100Miles: ""
    } : {
        ...driverList[index],
    })

    const validationMsg = useRef("")
    const validationTitle = useRef("")

    // Validation Modal
    const handleClose = () => setState({ ...state, show: false });
    const handleShow = () => setState({ ...state, show: true });

    // Retrieves Form Values
    const callback = (key, value) => {
        var info = { ...state };
        info[key] = value;
        setState({ ...info })
    }

    // Sets Validation Message
    const handleValidationMsg = (msg, title) => {
        validationMsg.current = msg
        validationTitle.current = title
    }

    // Adds Driver
    const addNewDriver = (e) => {
        // Adds DOB format
        if (monthSelect && daySelect && yearSelect) {
            state.DOB = monthSelect + "/" + daySelect + "/" + yearSelect
        }

        var addedDriver = state;
        if ((e.target.value) === "SAVE") {
            var check = AddDriverValidation({ infoFields: addedDriver, handleValidationMsg, handleShow, userData: context.userData });
            if (check) {
                var changeClassCopy = changeClass
                var driverListContextCopy = changeClassCopy?.driversHHMs?.drivers?.newDrivers
                var newDriverListCopy = driverList;

                // Adding New Driver
                if (index > driverList.length) {
                    newDriverListCopy.push(state);
                }
                // Editng Added Driver
                else {
                    newDriverListCopy[index] = state;
                }
                driverListContextCopy = newDriverListCopy
                setChangeClass(changeClassCopy)
                storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
                setAddDriver(false)
            }
            else {
                return
            }
        }
        else {
            setAddDriver(false);
        }
        context.setEditAddedDriverArr([])
    };

    // Sets the vehicle and vehicle VIN to the driver
    const setVehicleToDriver = (type, typeOfVehicle, typeOfVehicleVINs, vehicle, checked) => {
        var info = { ...state }
        // Adds vehicle to array
        if (checked) {
            typeOfVehicle.push(vehicle)
            info[(type == "residence") ? "residenceVehicles" : "keptAtSchoolVehicles"] = typeOfVehicle

            if (vehicle != "None of the above") {
                typeOfVehicleVINs.push(vehicle?.VIN)
                info[(type == "residence") ? "residenceVehicleVINs" : "keptAtSchoolVehicleVINs"] = typeOfVehicleVINs
            }
        }
        // Removes vehicle from array
        else {
            typeOfVehicle = typeOfVehicle.filter((veh) => veh != vehicle)
            info[(type == "residence") ? "residenceVehicles" : "keptAtSchoolVehicles"] = typeOfVehicle

            if (vehicle != "None of the above") {
                typeOfVehicleVINs = typeOfVehicleVINs.filter((VIN) => VIN != vehicle.VIN)
                info[(type == "residence") ? "residenceVehicleVINs" : "keptAtSchoolVehicleVINs"] = typeOfVehicleVINs
            }
        }
        setState({ ...info })
    }

    const stateAbbr = (state) => {
        if (state == "New Jersey") { return "NJ" }
        else if (state == "Pennsylvania") { return "PA" }
        else { return "MI"}
    }

    var {
        fullName,
        monthSelect,
        daySelect,
        yearSelect,
        mStatus,
        gender,
        sameHousehold,
        insuredRelationship,
        dLicense,
        licenseStatus,
        licenseOtherState,
        licenseNum,
        enrolledInCollege,
        collegeName,
        collegeCity,
        collegeState,
        vehicleKeptAtSchool,
        over100Miles
    } = state;

    return (
        <>
            <div className="slide-toggle edit-section mt-2">
                <div className="form-group form-group-xs">
                    <h6 className="text-center household-text-blue less-margin fw-bold">
                        NEW DRIVER
                    </h6>
                </div>
                <FormAuto
                    form={
                        [
                            // Full Name
                            {
                                id: "fullName",
                                type: "text",
                                label: "Full Name:",
                                value: fullName,
                                callback
                            },
                            // DOB Select Dropdowns
                            {
                                id: { y: "yearSelect", m: "monthSelect", d: "daySelect" },
                                type: "dateSelect",
                                label: "Date of Birth:",
                                value: { y: yearSelect, m: monthSelect, d: daySelect },
                                params: { driver: true },
                                callback
                            },
                            // Marital Status
                            {
                                id: "mStatus",
                                type: "select",
                                label: "Marital Status:",
                                value: mStatus,
                                options: mStatusOpt,
                                col: {
                                    label: { xl: 4 },
                                    input: { xl: 6 },
                                },
                                callback
                            },
                            // Gender
                            {
                                id: "gender",
                                type: "select",
                                label: "Gender:",
                                value: gender,
                                options: (state.userData?.state == "New Jersey") ? [...genderOpt, "Unspecified"] : genderOpt,
                                col: {
                                    label: { xl: 4 },
                                    input: { xl: 6 },
                                },
                                callback
                            },
                            // Relationship To Insured
                            {
                                id: "insuredRelationship",
                                type: "select",
                                label: "Relationship To Insured:",
                                value: insuredRelationship,
                                options: relationship,
                                col: {
                                    label: { xl: 4 },
                                    input: { xl: 6 },
                                },
                                callback
                            },
                            // Same Household
                            {
                                id: "sameHousehold",
                                type: "radio",
                                label: "Resides in the Same Household?",
                                value: sameHousehold,
                                col: {
                                    label: { xs: 6 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            }
                        ]
                    }
                />
                {/* Same Household */}
                {(sameHousehold == "0") &&
                    <NotSameHousehold callback={callback} setState={setState} state={state} setVehicleToDriver={setVehicleToDriver}
                        changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                }
                <FormAuto
                    form={
                        [
                            // Valid Driver's License
                            {
                                id: "dLicense",
                                type: "radio",
                                label: "Does this driver have a valid " + stateAbbr(context?.userData?.state) + " driver's license?",
                                value: dLicense,
                                col: {
                                    label: { xs: 6 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            },
                            // License Number (Valid License)
                            (dLicense == 1) &&
                            {
                                id: "licenseNum",
                                type: "text",
                                label: "License Number:",
                                value: licenseNum,
                                placeholder: "License Number",
                                callback
                            },
                            // License Status (Invalid License)
                            (dLicense === "0") &&
                            {
                                id: "licenseStatus",
                                type: "select",
                                label: "What is the status of this driver's license?",
                                value: licenseStatus,
                                col: {
                                    label: { xs: 4 }, input: { xs: 6 },
                                },
                                options: dlStatus,
                                callback
                            },
                            // License State (Invalid License)
                            (dLicense == "0" && licenseStatus == "Valid Other State License") &&
                            {
                                id: "licenseOtherState",
                                type: "select",
                                label: "Licensed State:",
                                value: licenseOtherState,
                                col: {
                                    label: { xs: 4 }, input: { xs: 6 },
                                },
                                options: states,
                                callback
                            },
                            // License Number (Invalid License)
                            (dLicense == "0" && (licenseStatus == "Valid Other State License" || licenseStatus == "Permit")) &&
                            {
                                id: "licenseNum",
                                type: "text",
                                label: "License Number:",
                                value: licenseNum,
                                callback
                            },
                            // Enrolled in college
                            (new Date().getFullYear() - parseInt(yearSelect) >= 16 && new Date().getFullYear() - parseInt(yearSelect) <= 24) &&
                            {
                                id: "enrolledInCollege",
                                type: "radio",
                                label: "Is this driver enrolled in a college or university?",
                                value: enrolledInCollege,
                                col: {
                                    label: { xs: 6 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            },
                            // College/University Name
                            (enrolledInCollege == 1) &&
                            {
                                id: "collegeName",
                                type: "text",
                                label: "School Name:",
                                value: collegeName,
                                callback
                            },
                            // College/University City
                            (enrolledInCollege == 1) &&
                            {
                                id: "collegeCity",
                                type: "text",
                                label: "School City:",
                                value: collegeCity,
                                callback
                            },
                            // College/University State
                            (enrolledInCollege == 1) &&
                            {
                                id: "collegeState",
                                type: "select",
                                label: "School State:",
                                value: collegeState,
                                col: {
                                    label: { xs: 4 }, input: { xs: 6 },
                                },
                                options: states,
                                callback
                            },
                            // Vehicle Kept At School
                            (enrolledInCollege == 1) &&
                            {
                                id: "vehicleKeptAtSchool",
                                type: "radio",
                                label: "Is any vehicle on this policy kept at school?",
                                value: vehicleKeptAtSchool,
                                col: {
                                    label: { xs: 6 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            },
                            // School Over 100 Miles from Address
                            (enrolledInCollege == "1") && (vehicleKeptAtSchool == "0") &&
                            {
                                id: "over100Miles",
                                type: "radio",
                                label: "Is the school over 100 miles from your address?",
                                value: over100Miles,
                                col: {
                                    label: { xs: 6 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            }
                        ]
                    }
                />
                <hr className="less-margin my-3" />
                <div className="text-right my-3" >
                    <input type={"button"} className="btn btn-default btn-sm" value={"CANCEL"} onClick={addNewDriver} />
                    <input type={"button"} className="btn btn-primary-grad btn-sm ms-1" value={"SAVE"} onClick={addNewDriver} />
                </div>
                <ValidationModal
                    show={state.show}
                    handleClose={handleClose}
                    validationMsg={validationMsg.current}
                    title={validationTitle.current} />
            </div>
        </>
    )
}

export default AddDriver