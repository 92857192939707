import React from "react";
import { years,months,days } from "../../";

class DateOfBirth extends React.Component {
    constructor(props) {
        super(props);
        this.month = months;
        this.day = days;
        this.year = years;
    }

    state = {
        driverYears: years.years.filter((year) => parseInt(year.year) < 2007)
    }

    render() {
        return (
            <>
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-4 text-blue fw-bold">
                        Date of Birth:*
                    </div>
                    <div className="col-xl-8">
                        <div className="row">
                            {/* Month */}
                            <div className="col-xl-4 col-sm-4 col-4">
                                <div className="btn-group bootstrap-select custom-selector" >
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                        defaultValue={this.props.monthSelect}
                                        onChange={(e) => { this.props.handleCallBack("monthSelect", e.target.value) }}>
                                        {this.month.months.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.month}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {/* Day */}
                            <div className="col-xl-4 col-sm-4 col-4">
                                <div className="btn-group bootstrap-select custom-selector">
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                        defaultValue={this.props.daySelect}
                                        onChange={(e) => { this.props.handleCallBack("daySelect", e.target.value) }}>
                                        {this.day.days.map(item => (
                                            <option key={item.id} value={item.day}>
                                                {item.day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {/* Year */}
                            <div className="col-xl-4 col-sm-4 col-4">
                                <div className="btn-group bootstrap-select custom-selector">
                                    {/* Driver */}
                                    {this.props.person == "driver" ?
                                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                            defaultValue={this.props.yearSelect}
                                            onChange={(e) => { this.props.handleCallBack("yearSelect", e.target.value) }}>
                                            <option value={"Year"}>
                                                Year
                                            </option>
                                            {this.state.driverYears.map((item, id) => (
                                                <option key={id} value={item.year}>
                                                    {item.year}
                                                </option>
                                            ))}
                                        </select>
                                        :
                                        // Member
                                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                            defaultValue={this.props.yearSelect}
                                            onChange={(e) => { this.props.handleCallBack("yearSelect", e.target.value) }}>
                                            <option value={"Year"}>
                                                Year
                                            </option>
                                            {this.year.years.map((item, id) => (
                                                <option key={id} value={item.year}>
                                                    {item.year}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DateOfBirth