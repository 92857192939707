import React from "react";
import states from "../StateObjects/States";

class DisorderConditionQuest extends React.Component {
    constructor(props) {
        super(props);
        this.states = states;
    }
    state = {
        showHousehold: false,
    };

    render() {
        return (
            <>
                {/* Same Household */}
                <div style={{marginBottom: "0px"}} className="row form-group form-group-sm">
                    <div style={{ width: "100%" }} className="col-xxl-8 text-blue inputHeight fw-bold">
                        Does this driver have a disorder or condition that may impair their ability to safely operate a motor vehicle, including but not limited to Narcolepsy, Syncope, Blindness, or Seizures?*
                    </div>
                </div>
                <div style={{marginBottom: "10px"}} className="col-xxl-4" onChange={(e) => { this.props.handleCallBack("disorderCondition", parseInt(e.target.value)) }}>
                    <div className="radio-inline form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={1}
                                defaultChecked={this.props.disorderCondition === 1} />Yes
                        </label>
                    </div>
                    <div className="radio-inline form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={0}
                                defaultChecked={this.props.disorderCondition === 0} />No
                        </label>
                    </div>
                </div>
            </>
        )
    }
}

export default DisorderConditionQuest