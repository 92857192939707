import Images from "../assets/images/Images";

const Banner = () => {

  const miBanner = {
    color: "white",
    fontSize: "1.25em",
    fontFamily: "GlacialIndifference",
    textAlign: "center",
    letterSpacing: "3px",
    paddingRight: "15%",
    paddingLeft: "15%"
  }

  const envSite = () => {
    switch (window.location.hostname) {
      case 'localhost':
        return 'https://staging.cure.com/download-mobile-app' //http://localhost:57761
      case 'myaccountstg.cure.com':
        return 'https://staging.cure.com/download-mobile-app'
      case 'myaccountprep.cure.com':
        return 'https://prepublish.cure.com/download-mobile-app'
      default:
        return 'https://www.cure.com/download-mobile-app'
    }
  }

  return (
    <>

      <div className="">
        <div className="bannerDownloadPage">
          <a href={envSite()} target='_blank'>
            <div className="d-flex flex-row justify-content-center">

              <div style={{ display: 'table' }}>
                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                  <img className="phoneIconSize mb-2" src={Images.bannerPhone} />
                </div>
              </div>

              <div className="flex-column mt-2">
                <div style={{ display: 'table' }}>
                  <div className="flex-row" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <span className='alert-title ms-3 bannerText'>
                      CURE App. Everything at your fingertips. Download Now.
                    </span>
                  </div>
                </div>

                <div className="flex-row">
                  <div>

                  </div>
                  <div className="d-flex justify-content-end bannerUnderline">
                    <img alt="Underline" className="img-fluid alert-nav-img underlineWidth" src={Images.underline} />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div >
      {/* <div className="d-none d-sm-block">

        <div className="bg-primary pt-1" style={miBanner}>
          <div className="pt-1" style={{ width: "100%", textAlign: "center" }}>
            <div className="justify-content-center pb-2" style={{ display: "flex" }}>

              <div style={{ textAlign: "right", width: "10%" }}>
                <img className="img-fluid" alt="Michigan" src={Images.michigan} style={{ width: 52 }} />
              </div>

              <div style={{ width: "27em", textAlign: "right", paddingRight: "0.5em" }}>
                {" GREAT LAKES, GREAT INSURANCE - NOW IN "}
              </div>

              <div style={{ width: "10%", textAlign: "left" }}>
                {"MICHIGAN!"}
              </div>

              <div style={{ width: "10%" }}>
                <img alt="Michigan" src={Images.underline} style={{ width: 143, position: "relative", right: "113%", top: "1em" }} />
              </div>

            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="d-none d-sm-block">

      <div className="d-flex justify-content-center bg-primary container-fluid header-nav">
          <div className="row col-11 col-md-11 col-lg-9 col-xl-8 col-xxl-7 m-0 pt-2">
            <div className="col-1  m-0 p-0">
              <div className="" style={{ width: 60 }} >
                <img className="img-fluid" alt="Michigan" src={Images.michigan} />
              </div>
            </div>

            <div className="col-11 row d-flex banner-font  p-0">
              <p className="col-9 text-end m-0 p-0">
                {"GREAT LAKES, GREAT INSURANCE - NOW IN "}
              </p>
              <div className="col-3 m-0 p-0 ">
                <div className="">
                  <span className="container-fluid text-start p-0">&ensp;{"MICHIGAN!"}</span>
                  <div className="p-0">
                    <img className="img-fluid" alt="Underline" src={Images.underline} style={{ width: 143 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> */}
    </>
  )
}

export default Banner;
