const styles = {
  p: {
      display: "block",
      fontFamily: "Lato, sans-serif",
      margin: "0 0 10px",
      fontSize: 13
  }
}
const VehicleDA_Directions = () => {
  return (
    <>
      <h5 className='text-primary text-center fw-bold mb-5'>DRIVER ASSIGNMENT</h5>
      <div className="col-12 col-sm-12 mb-4">
        <strong className="text-primary col-sm-12" style={{ fontSize: '16px' }}>
          For each driver, select the vehicle that they operate on a regular basis. 
          If a driver operates more than one vehicle on a regular basis, select the car they drive the most. 
          Do not select the same vehicle for more than one driver.
        </strong>
      </div>
    </>
  )
}

export default VehicleDA_Directions