const VehicleValidation_MI = ({ button, index, type, vehicle }) => {

    // Modifying a vehicle
    const modifyVehicleCheck = () => {
        if (!("vehicleFinanceLeasingCompanyResponse" in vehicle) || vehicle?.vehicleFinanceLeasingCompanyResponse == "") {
            return { title: "Oops!", message: 'Please select if your vehicle is now being financed or leased with a different company.', valid: false }
        }
        // Yes to finance/leasing
        else if (vehicle?.vehicleFinanceLeasingCompanyResponse == "1" && (!("vehicleFinanceLeasingCompanyName" in vehicle) || vehicle?.vehicleFinanceLeasingCompanyName == "")) {
            return { title: "Oops!", message: 'Please provide the name of the finance/leasing company.', valid: false }
        }
        else if (vehicle?.vehicleFinanceLeasingCompanyResponse == "1" && (!("vehicleLoanNumber" in vehicle) || vehicle?.vehicleLoanNumber == "")) {
            return { title: "Oops!", message: 'Please provide the loan number.', valid: false }
        }
        // else if (!("vehicleCoverageUpdate" in vehicle) || vehicle?.vehicleCoverageUpdate == "") {
        //     return { title: "Oops!", message: 'Please select if you would like to update your vehicle coverages.', valid: false }
        // }
        // Coverages
        else if (vehicle?.vehicleCoverageUpdate == "1" && (!("otherThanCollisionToggle" in vehicle) || vehicle?.otherThanCollisionToggle == "")) {
            return { title: "Oops!", message: 'Please select if you choose "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleCoverageUpdate == "1" && vehicle?.otherThanCollisionToggle == "1" && (!("standardDeductibleComprehensive" in vehicle) || vehicle?.standardDeductibleComprehensive == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleCoverageUpdate == "1" && vehicle?.otherThanCollisionToggle == "1" && (!("collisionCoverageToggle" in vehicle) || vehicle?.collisionCoverageToggle == "")) {
            return { title: "Oops!", message: 'Please select if you choose "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleCoverageUpdate == "1" && vehicle?.otherThanCollisionToggle == "1" && vehicle?.collisionCoverageToggle == "1" && (!("collisionCoverageType" in vehicle) || vehicle?.collisionCoverageType == "")) {
            return { title: "Oops!", message: 'Please select a "Collision" type.', valid: false }
        }
        else if (vehicle?.vehicleCoverageUpdate == "1" && vehicle?.otherThanCollisionToggle == "1" && vehicle?.collisionCoverageToggle == "1" && (!("standardDeductibleCollision" in vehicle) || vehicle?.standardDeductibleCollision == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleCoverageUpdate == "1" && vehicle?.otherThanCollisionToggle == "1" && (!("rentalReimbursement" in vehicle) || vehicle?.rentalReimbursement == "")) {
            return { title: "Oops!", message: 'Please select a rental reimbursement.', valid: false }
        }
    }
    // Adding a vehicle
    const addVehicleCheck = () => {
        if (!("requestedEffDt" in vehicle) || vehicle?.requestedEffDt == "") {
            return { title: "Oops!", message: 'Please provide a requested effective date.', valid: false }
        }
        else if (!("vehicleVIN" in vehicle) || vehicle?.vehicleVIN == "") {
            return { title: "Oops!", message: 'Please provide a valid vin.', valid: false }
        }
        else if (!("vehicleOwnedLeasedFinanced" in vehicle) || vehicle?.vehicleOwnedLeasedFinanced == "0") {
            return { title: "Oops!", message: 'Please select if the vehicle is owned, financed, or leased.', valid: false }
        }
        // Owned vehicle
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && (!("coOwned" in vehicle) || vehicle?.coOwned == "")) {
            return { title: "Oops!", message: 'Please select if the vehicle is co-owned.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.coOwned == "1" && (!("coOwnerName" in vehicle) || vehicle?.coOwnerName == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s full name.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.coOwned == "1" && (!("coOwnerAddress" in vehicle) || vehicle?.coOwnerAddress == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s address.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.coOwned == "1" && (!("coOwnerCity" in vehicle) || vehicle?.coOwnerCity == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s city.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.coOwned == "1" && (!("coOwnerZip" in vehicle) || vehicle?.coOwnerZip == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s zip code.', valid: false }
        }
        // Leased vehicle
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("vehicleLeasingCompany" in vehicle) || vehicle?.vehicleLeasingCompany == "")) {
            return { title: "Oops!", message: 'Please select the leasing company for this vehicle.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("coLeased" in vehicle) || vehicle?.coLeased == "")) {
            return { title: "Oops!", message: 'Please select if the vehicle is co-leased.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && vehicle?.coLeased == "1" && (!("coLeaseeName" in vehicle) || vehicle?.coLeaseeName == "")) {
            return { title: "Oops!", message: 'Please provide the co-leasee\'s full name.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && vehicle?.coLeased == "1" && (!("coLeaseeAddress" in vehicle) || vehicle?.coLeaseeAddress == "")) {
            return { title: "Oops!", message: 'Please provide the co-leasee\'s address.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && vehicle?.coLeased == "1" && (!("coLeaseeCity" in vehicle) || vehicle?.coLeaseeCity == "")) {
            return { title: "Oops!", message: 'Please provide the co-leasee\'s city.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && vehicle?.coLeased == "1" && (!("coLeaseeZip" in vehicle) || vehicle?.coLeaseeZip == "")) {
            return { title: "Oops!", message: 'Please provide the co-leasee\'s zip code.', valid: false }
        }
        // Financed vehicle
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("vehicleFinanceCompany" in vehicle) || vehicle?.vehicleFinanceCompany == "")) {
            return { title: "Oops!", message: 'Please select the finance company for this vehicle.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("coOwned" in vehicle) || vehicle?.coOwned == "")) {
            return { title: "Oops!", message: 'Please select if the vehicle is co-owned.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && vehicle?.coOwned == "1" && (!("coOwnerName" in vehicle) || vehicle?.coOwnerName == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s full name.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && vehicle?.coOwned == "1" && (!("coOwnerAddress" in vehicle) || vehicle?.coOwnerAddress == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s address.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && vehicle?.coOwned == "1" && (!("coOwnerCity" in vehicle) || vehicle?.coOwnerCity == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s city.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && vehicle?.coOwned == "1" && (!("coOwnerZip" in vehicle) || vehicle?.coOwnerZip == "")) {
            return { title: "Oops!", message: 'Please provide the co-owner\'s zip code.', valid: false }
        }
        // Name registered
        else if (!("nameRegistered" in vehicle) || vehicle?.nameRegistered == "0") {
            return { title: "Oops!", message: 'Please select what name is (will) the vehicle be registered.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantName" in vehicle) || vehicle?.registrantName == "")) {
            return { title: "Oops!", message: 'Please provide the registrant\'s full name.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantAddress" in vehicle) || vehicle?.registrantAddress == "")) {
            return { title: "Oops!", message: 'Please provide the registrant\'s address.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantCity" in vehicle) || vehicle?.registrantCity == "")) {
            return { title: "Oops!", message: 'Please provide the registrant\'s city.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantZip" in vehicle) || vehicle?.registrantZip == "")) {
            return { title: "Oops!", message: 'Please provide the registrant\'s zip.', valid: false }
        }
        // Owned vehicle continued
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && (!("otherThanCollisionToggle" in vehicle) || vehicle?.otherThanCollisionToggle == "")) {
            return { title: "Oops!", message: 'Please select if you choose "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.otherThanCollisionToggle == "1" && (!("standardDeductibleComprehensive" in vehicle) || vehicle?.standardDeductibleComprehensive == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.otherThanCollisionToggle == "1" && (!("collisionCoverageToggle" in vehicle) || vehicle?.collisionCoverageToggle == "")) {
            return { title: "Oops!", message: 'Please select if you choose "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.otherThanCollisionToggle == "1" && vehicle?.collisionCoverageToggle == "1" && (!("collisionCoverageType" in vehicle) || vehicle?.collisionCoverageType == "")) {
            return { title: "Oops!", message: 'Please select a "Collision" type.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.otherThanCollisionToggle == "1" && vehicle?.collisionCoverageToggle == "1" && (!("standardDeductibleCollision" in vehicle) || vehicle?.standardDeductibleCollision == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24470" && vehicle?.otherThanCollisionToggle == "1" && (!("rentalReimbursement" in vehicle) || vehicle?.rentalReimbursement == "")) {
            return { title: "Oops!", message: 'Please select a rental reimbursement.', valid: false }
        }
        // Leased vehicle continued
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("standardDeductibleComprehensive" in vehicle) || vehicle?.standardDeductibleComprehensive == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("collisionCoverageType" in vehicle) || vehicle?.collisionCoverageType == "")) {
            return { title: "Oops!", message: 'Please select a "Collision" type.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("standardDeductibleCollision" in vehicle) || vehicle?.standardDeductibleCollision == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("rentalReimbursement" in vehicle) || vehicle?.rentalReimbursement == "")) {
            return { title: "Oops!", message: 'Please select a rental reimbursement.', valid: false }
        }
        // Financed vehicle continued
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("standardDeductibleComprehensive" in vehicle) || vehicle?.standardDeductibleComprehensive == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("collisionCoverageType" in vehicle) || vehicle?.collisionCoverageType == "")) {
            return { title: "Oops!", message: 'Please select a "Collision" type.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("standardDeductibleCollision" in vehicle) || vehicle?.standardDeductibleCollision == "")) {
            return { title: "Oops!", message: 'Please select a deductible for "Collision" coverage.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("rentalReimbursement" in vehicle) || vehicle?.rentalReimbursement == "")) {
            return { title: "Oops!", message: 'Please select a rental reimbursement.', valid: false }
        }
    }
    // Removing a vehicle
    const removeVehicleCheck = () => {
        if (!("vehicleRemovalReason" in vehicle) || vehicle?.vehicleRemovalReason == "") {
            return { title: "Oops!", message: 'Please select the reason for removing the vehicle.', valid: false }
        }
        // For Cancel Policy
        else if (vehicle?.vehicleRemovalReason == "CancelPolicy" && (!("currentResidenceResponse" in vehicle) || vehicle?.currentResidenceResponse == "")) {
            return { title: "Oops!", message: 'Please confirm if the mailing address listed is your current mailing address.', valid: false }
        }
        else if (vehicle?.vehicleRemovalReason == "CancelPolicy" && vehicle?.currentResidenceResponse == "0" && vehicle?.currentResidenceAddress == "") {
            return { title: "Oops!", message: 'Please provide the street address of your current mailing address.', valid: false }
        }
        else if (vehicle?.vehicleRemovalReason == "CancelPolicy" && vehicle?.currentResidenceResponse == "0" && vehicle?.currentResidenceCity == "") {
            return { title: "Oops!", message: 'Please provide the city of your current mailing address.', valid: false }
        }
        else if (vehicle?.vehicleRemovalReason == "CancelPolicy" && vehicle?.currentResidenceResponse == "0" && vehicle?.currentResidenceZip == "") {
            return { title: "Oops!", message: 'Please provide the zip code of your current mailing address.', valid: false }
        }
        else if (type != "replace") {
            if (!("requestedEffDt" in vehicle) || vehicle?.requestedEffDt == "") {
                return { title: "Oops!", message: 'Please provide a requested effective date.', valid: false }
            }
        }
    }

    var validCheck
    // Adding
    if (type == "add" || type == "edit") {
        validCheck = { ...addVehicleCheck() }
    }
    // Removing
    else if (type == "remove") {
        validCheck = { ...removeVehicleCheck() }
    }
    // Replacing
    else if (type == "replace" || type == "replaceEdit") {
        validCheck = { ...addVehicleCheck(), ...removeVehicleCheck() }
    }
    // Modifying (MICHIGAN ONLY)
    else if (type == "modify") {
        validCheck = { ...modifyVehicleCheck() }
    }

    // Checks if any fields are missing
    if (!validCheck?.valid && Object.keys(validCheck) != 0) {
        return validCheck
    }

    return { valid: true }
}

export default VehicleValidation_MI