const oopsMessages = {
    VehicleIsCoOwned: "Please indicate if this vehicle is co-owned.",
    VehicleIsCoLeased: "Please indicate if this vehicle is co-leased.",
    vehicleLeasingCompany: "Please select the leasing company.",
    vehicleFinanceCompany: "Please select the financing company.",
    Garaged: "Please indicate if this vehicle is primarily kept overnight at TESTING.",
    VehicleRegistrant: "Please select in whose name this vehicle is registered.",
    VehicleUse: "Please select how this vehicle will be used.",
    EstimatedMileage: "Please select the estimated annual mileage for this vehicle.",
    WantsOtherThanCollision: "Please indicate if you want \"Other than Collision\" (Comprehensive) coverage.",
    OtherDeductible: "Please choose your \"Other than Collision\" (Comprehensive) deductible.",
    WantsCollision: "Please indicate if you want \"Collision\" coverage.",
    CollisionType: "Please choose your \"Collision\" coverage type.",
    CollisionDeductible: "Please choose your \"Collision\" coverage deductible.",
    RentalCoverage: "Please select a Rental Reimbursement option.",
    VehicleCustomization: "Please indicate if this vehicle has modifications or customization.",
    VehicleCommercial: "Please indicate if this is a commercial vehicle.",
    VehicleCommercialPurposes: "Please indicate if this vehicle is used for commercial purposes.",
    LeasedRentToOthers: "Please indicate if this vehicle is leased or rented to others.",
    VehiclePlowSnow: "Please indicate if this vehicle is used to plow snow for compensation.",
    DrivenExistingDriver: "Please indicate if this vehicle will be driven by an existing driver on your policy."
};

const validateForm = (newVehicle, modal) => {
    const type = newVehicle.vehicleOwnedLeasedFinanced;

    const commonFields = [
        "Garaged",
        "VehicleRegistrant",
        "VehicleUse",
        "EstimatedMileage",
    ];

    const finalFields = [
        "VehicleCustomization",
        "VehicleCommercial",
        "VehicleCommercialPurposes",
        "LeasedRentToOthers",
        "VehiclePlowSnow",
        "DrivenExistingDriver"
    ];

    const typeSpecificFields = {
        owned: ["VehicleIsCoOwned"],
        leased: ["VehicleIsCoLeased", "vehicleLeasingCompany"],
        financed: ["VehicleIsCoOwned", "vehicleFinanceCompany"]
    };

    const collisionFields = [
        "OtherDeductible",
        "CollisionType",
        "CollisionDeductible",
        "RentalCoverage"
    ];

    const fieldOrder = [
        ...typeSpecificFields[type],
        ...commonFields,
        ...(type === 'owned' ? ['WantsOtherThanCollision'] : collisionFields),
        ...finalFields
    ];

    const validateField = (field) => {
        if (!newVehicle[field]) {
            modal.openModal("Oops!", oopsMessages[field]);
            return { response: false, field };
        }
        return { response: true, field: null };
    };

    const validateOwnedCollisionFields = () => {
        if (newVehicle.WantsOtherThanCollision !== "1") return { response: true, field: null };

        const ownedCollisionFields = [
            "OtherDeductible",
            "WantsCollision"
        ];

        for (const field of ownedCollisionFields) {
            const result = validateField(field);
            if (!result.response) return result;
        }

        if (newVehicle.WantsCollision === "1") {
            for (const field of ["CollisionType", "CollisionDeductible"]) {
                const result = validateField(field);
                if (!result.response) return result;
            }
        }

        return validateField("RentalCoverage");
    };

    for (const field of fieldOrder) {
        const result = validateField(field);
        if (!result.response) return result;

        if (type === 'owned' && field === 'WantsOtherThanCollision') {
            const collisionResult = validateOwnedCollisionFields();
            if (!collisionResult.response) return collisionResult;
        }
    }

    return { response: true, field: null };
};

export const ownedLeasedFinancedValidator = {
    validateForm
};