const ArraysDOB = {
    generateArrayOfYears: () => {
        var max = new Date().getFullYear() - 16
        var min = max - 102
        var years = []

        for (var i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    },

    generateArrayOfYearsHouseholdMember: () => {
        var max = new Date().getFullYear()
        var min = max - 102
        var years = []

        for (var i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    },

    generateArrayOfDays: () => {
        var numDays = []
        for (var i = 1; i < 32; i++) {
            numDays.push(i)
        }
        return numDays
    },

    month: [
        { id: "1", name: "January" },
        { id: "2", name: "February" },
        { id: "3", name: "March" },
        { id: "4", name: "April" },
        { id: "5", name: "May" },
        { id: "6", name: "June" },
        { id: "7", name: "July" },
        { id: "8", name: "August" },
        { id: "9", name: "September" },
        { id: "10", name: "October" },
        { id: "11", name: "November" },
        { id: "12", name: "December" }
    ]
}

export default ArraysDOB