import { useState } from "react"
import { getIdCards } from "../services/Api"
import Menu from "../components/Menu";
import { Spinner } from 'react-bootstrap';


const DigitalIDCard = (props) => {
    const { systemId, firstName, lastName, env, policyNumber, idCardData, token } = props.user.userData
    const [idCardPdfs, setIdCardPdfs] = useState({ result: [], complete: false })

    const getIdCardsPdf = async () => {
        var idList = idCardData.map((value) => {
            return value.id
        })
        var idCardPdfs = await getIdCards(systemId, policyNumber, idList, env)
        if (idCardPdfs.error == null) {
            setIdCardPdfs({ result: idCardPdfs.data, complete: true })
        }
    }

    if (!idCardPdfs.complete) {
        getIdCardsPdf()
    }
    console.log(props);
    console.log(idCardData);
    return (
        <>
            <Menu Title={"Digital ID Card"} FirstName={firstName} userData={lastName} />
            <div className="d-flex flex-wrap">
                {idCardData.map((value, index) => {
                    return (
                        <div key={index}>
                            <div className="d-flex m-3 flex-column container border">
                                <div className="">
                                    <strong>Insured Information</strong>
                                    <p>{lastName}, {firstName}</p>
                                </div>
                                <div className="">
                                    <strong>Agency</strong>
                                    <p>CURE Auto Insurance</p>
                                </div>

                                <div className="flex-row d-flex justify-content-between">
                                    <div className="">
                                        <small><strong>Policy Number</strong></small>
                                        <p>{policyNumber}</p>
                                    </div>
                                    <div className="">
                                        <small><strong>Effective Date</strong></small>
                                        <p>{FormatDate(value.effectivedt)}</p>
                                    </div>
                                    <div className="">
                                        <small><strong>Expiration Date</strong></small>
                                        <p>{FormatDate(value.effectivedt, { m: 6 })}</p>
                                    </div>
                                </div>

                                <div>
                                    <strong>Vehicle Information</strong>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small><strong>Policy Number</strong></small>
                                        <p>{policyNumber}</p>
                                    </div>
                                    <div className="col">
                                        <small><strong>Effective Date</strong></small>
                                        <p>{FormatDate(value.effectivedt)}</p>
                                    </div>
                                </div>
                                <div>
                                    <small><strong>Vehicle Identification Number</strong></small>
                                    <p>{value.vehidentificationnumber}</p>
                                </div>
                                <small>This may or may not be accepted by law enforcement officials as an Insurance ID card in your state.</small>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className=" m-3">
                <div>
                    <h4><i className="fa fa-print" /> Printable Version</h4>
                </div>
                <table className="table border">
                    <thead>
                        <tr><th>Description</th><th>Link</th></tr>
                    </thead>
                    <tbody>
                        {idCardPdfs.complete ?
                            idCardPdfs.result.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{idCardData[index].itemname}</td>
                                        <td><a href={`data:application/octet-stream;base64,${value}`} download={`${idCardData[index].itemname}.pdf`}><i className="fa fa-file-pdf-o" aria-hidden="true"></i> Open Link</a></td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr>
                                    <td colSpan={2} align='center'>
                                        <Spinner animation="border" />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}
const FormatDate = (tempDate, options) => {
    const newOptions = { y: 0, m: 0, d: 0, ...options }
    var newDate = new Date(tempDate)
    newDate.setFullYear(newDate.getFullYear() + newOptions.y, newDate.getMonth() + newOptions.m, newDate.getDate() + newOptions.d)

    var dateMonth = `${(newDate.getMonth() + 1) < 10 ? '0' : ''}${newDate.getMonth() + 1}`
    var dateDay = `${(newDate.getDate() + 1) < 10 ? '0' : ''}${newDate.getDate()}`
    var dateYear = `${(newDate.getFullYear())}`
    const date = `${dateMonth}/${dateDay}/${dateYear}`
    return date
}


export default DigitalIDCard