import React from "react";
import states from "../StateObjects/States";

class MentalPhysicalQuest extends React.Component {
    constructor(props) {
        super(props);
        this.states = states;
    }
    state = {
        showHousehold: false,
    };

    render() {
        return (
            <>
                {/* Same Household */}
                <div style={{marginBottom: "0px"}} className="row form-group form-group-sm">
                    <div style={{ width: "100%" }} className="col-xxl-8 text-blue inputHeight fw-bold">
                        Does this driver have any mental or physical impairments, other than those listed above, that would impair their ability to operate a motor vehicle safely (Not including prescription eyeglasses or contact lenses)?*
                    </div>
                </div>
                <div style={{marginBottom: "10px"}} className="col-xxl-4" onChange={(e) => { this.props.handleCallBack("mentalPhysical", parseInt(e.target.value)) }}>
                    <div className="radio-inline form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={1}
                                defaultChecked={this.props.mentalPhysical === 1} />Yes
                        </label>
                    </div>
                    <div className="radio-inline form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={0}
                                defaultChecked={this.props.mentalPhysical === 0} />No
                        </label>
                    </div>
                </div>
            </>
        )
    }
}

export default MentalPhysicalQuest