import React, { useContext, useState } from "react"
import VehicleDA_VehiclePerDriver from "./VehicleSelectVehicle/VehicleDA_VehiclePerDriver"
import VehicleDA_VehicleUsed from "./VehicleSelectVehicle/VehicleDA_VehicleUsed"

export const VehicleDA_SaveContext = React.createContext()

const VehicleDA_SelectVehicle = ({ changeReqContext, reqChangeClass, stylesContext, setChangeClass }) => {
    const context = useContext(changeReqContext)
    const da_stylesContext = useContext(stylesContext)
    const [drivers, setDrivers] = useState(context[reqChangeClass]?.driverAssignment?.drivers)

    var combinedVehicles = [...context[reqChangeClass]?.vehicles?.vehiclesOnPolicy?.storedVehicles, ...context[reqChangeClass]?.vehicles?.vehiclesOnPolicy?.newVehicles]
    combinedVehicles = combinedVehicles.filter((vehicle, id) => vehicle?.type != "remove")
    const [allVehicles, setAllVehicles] = useState([...combinedVehicles])

    // Adds count for clear button
    const checkForClearButton = () => {
        var vehicleClear = 0
        for (var i = 0; i < drivers.length; i++) {
            if (drivers[i]?.vehicle && "vehicle" in drivers[i]) { vehicleClear++ }
        }
        if (vehicleClear == drivers.length) { return true }
        return false
    }

    const [showClear, setShowClear] = useState(checkForClearButton())

    // Saves vehicle to driver
    const saveVehicleToDriver = (driverIndex, vehicle) => {
        var vehicleName = (vehicle == "NotPrimaryDriver") ? vehicle
            : ("vehicleMake" in vehicle) ? vehicle?.vehicleYear + " " + vehicle?.vehicleMake + " " + vehicle?.vehicleModel
                : vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model
        var driverListCopy = drivers
        driverListCopy[driverIndex]["vehicle"] = vehicleName

        // Sets default vehicle use and annual mileage to driver
        if (context?.userData?.state == "Michigan") {
            driverListCopy[driverIndex]["vehicleUse"] = ""
            driverListCopy[driverIndex]["annualMileage"] = "9,001-12,000"
        }
        setDrivers([...driverListCopy])

        var changeClassCopy = context[reqChangeClass]
        var vehicleDriversCopy = changeClassCopy.driverAssignment.drivers
        vehicleDriversCopy = drivers
        changeClassCopy.driverAssignment.drivers = vehicleDriversCopy

        // Adds vehicle to driverList in the class for Household/Address Change
        if (reqChangeClass == "householdChangeClass" || reqChangeClass == "addressChangeClass") {
            var driversCopy = changeClassCopy?.driversHHMs?.drivers

            // Checks stored drivers list
            for (var i = 0; i < driversCopy?.storedDrivers?.length; i++) {
                var storedDriver = driversCopy?.storedDrivers[i]
                if (storedDriver?.fullName == driverListCopy[driverIndex]?.fullName) {
                    storedDriver["vehicleUse"] = ""
                    storedDriver["annualMileage"] = "9,001-12,000"
                    storedDriver["vehicle"] = vehicleName
                    break
                }
            }
            // Checks new drivers list
            for (var i = 0; i < driversCopy?.newDrivers?.length; i++) {
                var newDriver = driversCopy?.newDrivers[i]
                if (newDriver?.fullName == driverListCopy[driverIndex]?.fullName) {
                    newDriver["vehicleUse"] = ""
                    newDriver["annualMileage"] = "9,001-12,000"
                    newDriver["vehicle"] = vehicleName
                    break
                }
            }
            changeClassCopy.driversHHMs.drivers = driversCopy
        }
        setChangeClass({ ...changeClassCopy })
        setShowClear(checkForClearButton())
    }

    // Saves days per week / miles one-way
    const saveVehicleUse = (driverIndex, key, value) => {
        var driverListCopy = drivers
        driverListCopy[driverIndex][key] = value

        // Adds vehicle to driverHHMs in the class for Household/Address Change
        if (reqChangeClass == "householdChangeClass" || reqChangeClass == "addressChangeClass") {
            var changeClassCopy = context[reqChangeClass]
            var driversCopy = changeClassCopy?.driversHHMs?.drivers

            // Checks stored drivers list
            for (var i = 0; i < driversCopy?.storedDrivers?.length; i++) {
                var storedDriver = driversCopy?.storedDrivers[i]
                if (storedDriver?.fullName == driverListCopy[driverIndex]?.fullName) {
                    storedDriver[key] = value
                }
            }
            // Checks new drivers list
            for (var i = 0; i < driversCopy?.newDrivers?.length; i++) {
                var newDriver = driversCopy?.newDrivers[i]
                if (newDriver?.fullName == driverListCopy[driverIndex]?.fullName) {
                    newDriver[key] = value
                }
            }
            setChangeClass({ ...changeClassCopy })
        }
        setDrivers([...driverListCopy])
    }

    // Clears all vehicle selected for each driver
    const clearVehicleSelections = () => {
        var driverListCopy = drivers
        for (var i = 0; i < driverListCopy?.length; i++) {
            delete driverListCopy[i]?.vehicle
            delete driverListCopy[i]?.daysPerWeek
            delete driverListCopy[i]?.milesOneWay
            delete driverListCopy[i]?.vehicleUse
            delete driverListCopy[i]?.vehicleUseExplanation
            delete driverListCopy[i]?.annualMileage
        }

        // Clears values for drivers (household and address change)
        if (reqChangeClass == "householdChangeClass" || reqChangeClass == "addressChangeClass") {
            var changeClassCopy = context[reqChangeClass]
            var driversCopy = changeClassCopy?.driversHHMs?.drivers

            // Checks stored drivers list
            for (var i = 0; i < driversCopy?.storedDrivers?.length; i++) {
                delete driversCopy?.storedDrivers[i]?.vehicle
                delete driversCopy?.storedDrivers[i]?.daysPerWeek
                delete driversCopy?.storedDrivers[i]?.milesOneWay
                delete driversCopy?.storedDrivers[i]?.vehicleUse
                delete driversCopy?.storedDrivers[i]?.vehicleUseExplanation
                delete driversCopy?.storedDrivers[i]?.annualMileage
            }
            // Checks new drivers list
            for (var i = 0; i < driversCopy?.newDrivers?.length; i++) {
                delete driversCopy?.newDrivers[i]?.vehicle
                delete driversCopy?.newDrivers[i]?.daysPerWeek
                delete driversCopy?.newDrivers[i]?.milesOneWay
                delete driversCopy?.newDrivers[i]?.vehicleUse
                delete driversCopy?.newDrivers[i]?.vehicleUseExplanation
                delete driversCopy?.newDrivers[i]?.annualMileage
            }
            changeClassCopy.driversHHMs.drivers = driversCopy
            setChangeClass({ ...changeClassCopy })
        }
        setDrivers([...driverListCopy])
        setShowClear(false)
    }

    return (
        <>
            <div style={da_stylesContext.styles.indent}>
                {drivers.map((driver, driverIndex) => {
                    return (
                        (driver?.driverStatus == "" || driver?.driverStatus == "New Driver" || driver?.driverStatus == "Adding As Driver") && //Add if needed
                        <div key={driverIndex}>
                            <strong style={da_stylesContext.styles.blueText}>
                                Select the vehicle <span className="text-uppercase">{driver.fullName}</span> primarily drives.
                                <span className="ms-2 fst-italic fw-light text-dark">{(driver.driverStatus === "pending") ? "(Add Pending)" : ""}</span>
                            </strong>
                            <VehicleDA_SaveContext.Provider
                                value={{
                                    drivers, driverIndex,
                                    saveVehicleToDriver, saveVehicleUse
                                }}
                            >
                                <VehicleDA_VehiclePerDriver vehicles={allVehicles} />
                                {drivers[driverIndex]?.vehicle != null && drivers[driverIndex]?.vehicle != "NotPrimaryDriver" &&
                                    <VehicleDA_VehicleUsed changeReqContext={changeReqContext} stylesContext={stylesContext} />
                                }
                            </VehicleDA_SaveContext.Provider>
                        </div>
                    )
                })}
                <br />
                {/* Clear button */}
                {showClear &&
                    <button className="btn btn-default" onClick={clearVehicleSelections}>
                        Clear
                    </button>
                }
            </div>
        </>
    )
}

export default VehicleDA_SelectVehicle