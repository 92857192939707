import { useContext } from "react"
import { VehicleDA_SaveContext } from "../VehicleDA_SelectVehicle"

const VehicleDA_VehicleUsed = ({ changeReqContext, stylesContext }) => {
    const context = useContext(changeReqContext)
    const da_stylesContext = useContext(stylesContext)
    const da_saveContext = useContext(VehicleDA_SaveContext)
    var drivers = da_saveContext?.drivers
    var driverIndex = da_saveContext?.driverIndex
    var policyState = context?.userData?.state

    return (
        <>
            <div style={da_stylesContext.styles.indent}>
                {/* New Jersey/Pennsylvania */}
                {policyState != "Michigan" &&
                    <>
                        {/* Days per week */}
                        <div className="mb-1 mx-4">
                            <strong style={da_stylesContext.styles.blueText}>
                                Number of days per week this car is used to drive to work/school/public transportation site?
                            </strong>
                        </div>
                        <div className="mb-1">
                            <div className="btn-group m-2 mx-4 custom-selector">
                                <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                    value={drivers[driverIndex]?.daysPerWeek != null ? drivers[driverIndex]?.daysPerWeek : ""}
                                    onChange={(e) => { da_saveContext.saveVehicleUse(driverIndex, "daysPerWeek", e.target.value) }}
                                >
                                    <>
                                        <option value={""} disabled>Please select...</option>
                                        {daysPerWeek.map((option, index) => {
                                            return (<option className="text-primary" key={index} value={option}>{option}</option>)
                                        })}
                                    </>
                                </select>
                            </div>
                        </div>

                        {/* Miles one-way */}
                        <div className="mb-1 mx-4">
                            <strong style={da_stylesContext.styles.blueText}>
                                How many miles one-way?
                            </strong>
                        </div>
                        <div className="mb-1">
                            <div className="btn-group m-2 mx-4 custom-selector">
                                <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                    value={drivers[driverIndex]?.milesOneWay != null ? drivers[driverIndex]?.milesOneWay : ""}
                                    onChange={(e) => { da_saveContext.saveVehicleUse(driverIndex, "milesOneWay", e.target.value) }}
                                >
                                    {milesOneWay}
                                </select>
                            </div>
                        </div>
                    </>
                }


                {/* Michigan */}
                {policyState == "Michigan" &&
                    <>
                        {/* Vehicle Use */}
                        <div className="mb-1 mx-4">
                            <strong style={da_stylesContext.styles.blueText}>
                                Vehicle Use*
                            </strong>
                        </div>
                        <div className="mb-1">
                            <div className="btn-group m-2 mx-4 custom-selector">
                                <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                    value={drivers[driverIndex]?.vehicleUse != null ? drivers[driverIndex]?.vehicleUse : ""}
                                    onChange={(e) => { da_saveContext.saveVehicleUse(driverIndex, "vehicleUse", e.target.value) }}
                                >
                                    <>
                                        <option value={""} disabled>Select from the following...</option>
                                        {vehicleUse.map((option, index) => {
                                            return (<option className="text-primary" key={index} value={option}>{option}</option>)
                                        })}
                                    </>
                                </select>
                            </div>
                        </div>

                        {/* Vehicle Use Explanation (Business/Farm ONLY) */}
                        {(drivers[driverIndex]?.vehicleUse == "Business" || drivers[driverIndex]?.vehicleUse == "Farm") &&
                            <>
                                <div className="mb-1 mx-4">
                                    <strong style={da_stylesContext.styles.blueText}>
                                        What type of {drivers[driverIndex]?.vehicleUse?.toLowerCase()} is this vehicle used for?*
                                    </strong>
                                </div>
                                <div className="mb-1">
                                    <div className="m-2 mx-4">
                                        <textarea className="form-control" placeholder="Please explain..." style={{ resize: 'none' }}
                                            value={drivers[driverIndex]?.vehicleUseExplanation != null ? drivers[driverIndex]?.vehicleUseExplanation : ""}
                                            onChange={(e) => { da_saveContext.saveVehicleUse(driverIndex, "vehicleUseExplanation", e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </>
                        }

                        {/* Estimated Annual Mileage */}
                        <div className="mb-1 mx-4">
                            <strong style={da_stylesContext.styles.blueText}>
                                Estimated Annual Mileage*
                            </strong>
                        </div>
                        <div className="mb-1">
                            <div className="btn-group m-2 mx-4 custom-selector">
                                <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                    value={drivers[driverIndex]?.annualMileage != null ? drivers[driverIndex]?.annualMileage : ""}
                                    onChange={(e) => { da_saveContext.saveVehicleUse(driverIndex, "annualMileage", e.target.value) }}
                                >
                                    {annualMileage}
                                </select>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

let vehicleUse = [
    "Pleasure (not used to commute)",
    "Commute to work (less than 15 miles each way)",
    "Commute to work (15 or more miles each way)",
    "Business",
    "Farm"
]

let annualMileage =
    <>
        <option value={"0-3,000"} >0-3,000</option>
        <option value={"3,001-6,000"}>3,001-6,000</option>
        <option value={"6,001-9,000"}>6,001-9,000</option>
        <option value={"9,001-12,000"}>9,001-12,000</option>
        <option value={"12,001-15,000"}>12,001-15,000</option>
        <option value={"15,001-18,000"}>15,001-18,000</option>
        <option value={"18,001-21,000"}>18,001-21,000</option>
        <option value={"21,000-24,000"}>21,000-24,000</option>
        <option value={"24,001-27,000"}>24,001-27,000</option>
        <option value={"27,001-30,000"}>27,001-30,000</option>
        <option value={"30,001-33,000"}>30,001-33,000</option>
        <option value={"33,001-36,000"}>33,001-36,000</option>
        <option value={"36,001-99,999"}>36,001-99,999</option>
    </>

let daysPerWeek = [
    "1 day per week",
    "2 days per week",
    "3 days per week",
    "4 days per week",
    "5 days per week",
    "6 days per week",
    "7 days per week"
]

let milesOneWay =
    <>
        <option value={""} disabled>Please select...</option>
        <option value={"1440"}>Less than 3 miles one-way</option>
        <option value={"1450"}>Less than 10 miles one-way</option>
        <option value={"1460"}>10 or more miles one-way</option>
        <option value={"1470"}>Pleasure use only</option>
        <option value={"1480"}>Business use (traveling sales rep, etc.)</option>
        <option value={"1490"}>Farm use</option>
    </>


export default VehicleDA_VehicleUsed