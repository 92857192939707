import React, { useContext } from 'react'
import TooltipIcon from '../TooltipIcon/TooltipIcon';
import { NewVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext';

const QuestionRow = ({ name, question, onTooltipClick, indentedContent, options = null, display = true }) => {
    const newVehicleContext = useContext(NewVehicleContext);
    const mapOptions = options ? options : {
        "0": "No",
        "1": "Yes",
        "None of the Above": "Other"
    }
    const value = mapOptions[newVehicleContext?.newVehicle?.[name]] || newVehicleContext?.newVehicle?.[name];

    if (!display) {
        return null;
    }

    return (
        <div className="row align-items-center my-3">
            <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0">
                {question}
                {onTooltipClick && <TooltipIcon onClick={onTooltipClick} />}
                {indentedContent && <IndentedContent content={indentedContent} />}
            </div>
            <div className="col-12 col-md-6 col-sm-8 text-dark">
                <label>{value}</label>
            </div>
        </div>
    )
}

const IndentedContent = ({ content }) => (
    <div className="ms-3 mt-2 fw-bold" style={{ paddingRight: '20%' }}>
        {content}
    </div>
);

export { QuestionRow, IndentedContent };