import  secureLocalStorage  from  "react-secure-storage";

//#region Storage
class StorageCookie {
    constructor() {
        // this.storage = localStorage
        this.storage = document
    }
    clear() {
        // this.storage.clear()
        let cKeyList = this.storage.cookie.split(';');
        for (let i = 0; i < cKeyList.length; i++) {
            let cKey = cKeyList[i];
            let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
            this.storage.cookie = cKey + "=;" + expires + ";path=/";
        }
        return undefined;
    }
    getItem(key) {
        let name = key + "=";
        let cKeyList = this.storage.cookie.split(';');
        for (let i = 0; i < cKeyList.length; i++) {
            let cKey = cKeyList[i];
            while (cKey.charAt(0) === ' ') {
                cKey = cKey.substring(1);
            }
            if (cKey.indexOf(name) === 0) {
                return cKey.substring(name.length, cKey.length);
            }
        }
        return undefined;
    }
    key(index) {
        this.storage.cookie.key(index)
    }
    setItem(key, value) {
        // this.storage.setItem(key, value)
        const d = new Date();
        d.setTime(d.getTime() + (15 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        let item = key + "=" + value + ";" + expires + ";path=/";
        this.storage.cookie = item
    }
    removeItem(key) {
        // this.storage.removeItem(key)
        let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
        this.storage.cookie = key + "=;" + expires + ";path=/";
    }
    length() {
        let cKeyList = this.storage.cookie.split(';')
        let length = (cKeyList[0] === '') ? 0 : cKeyList.length
        return length
        // return this.storage.length
    }
}

class StorageLocal {
    constructor() {
        
        this.storage = (window.location.hostname =='localhost')? localStorage: secureLocalStorage
        this.timeExtend = (window.location.hostname =='localhost')? (15000*60*10): (15000*60*1)
    }

    clear() {
        this.storage.clear()
    }
    getItem(key) {
        if(key == "userData"){
            var expiration =  parseInt(this.storage.getItem("expiration", Date.now()))
            if (expiration < Date.now()){
                this.storage.clear()
                return undefined
            }else{
                this.storage.setItem("expiration", Date.now() +(this.timeExtend))
            }
        }
        return this.storage.getItem(key)
    }
    key(index) {
        return this.storage.key(index)
    }
    restart(){
        this.storage.setItem("expiration", Date.now() +(this.timeExtend))
    }
    setItem(key, value) {
        if(key == "userData"){
            this.restart()
        }
        this.storage.setItem(key, value)
    }
    removeItem(key) {
        this.storage.removeItem(key)
    }
    length() {
        return this.storage.length
    }
}

export const storageData = new StorageLocal()

//#endregion