import { useContext } from "react"
import Drivers from "components/ChangeRequestSharedComp/Household/Drivers"
import HouseholdMembers from "components/ChangeRequestSharedComp/Household/HouseholdMembers"
import ValidationModal from "../../ValidationModal"
import { AddressChangeContext } from "../AddressChangeContext"
import AddressChangeBtnNavigation from "../AddressChangeBtnNavigation"

const HouseholdMain = () => {
    const context = useContext(AddressChangeContext)

    return (
        <>
            {(context.addDriver || !context.addMember) && !context?.showMemberEditMenu &&
                <Drivers changeReqContext={AddressChangeContext} reqChangeClass={"addressChangeClass"} setChangeClass={context.setAddressChangeClass} />
            }
            {(!context.addDriver || context.addMember) && !context?.showDriverEditMenu &&
                <HouseholdMembers changeReqContext={AddressChangeContext} reqChangeClass={"addressChangeClass"} setChangeClass={context.setAddressChangeClass} />
            }

            {!context.addDriver && !context.addMember && !context?.showMemberEditMenu && !context?.showDriverEditMenu &&
                <AddressChangeBtnNavigation />
            }
            
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default HouseholdMain