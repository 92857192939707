import { useLocation, useNavigate } from "react-router-dom";
import HeaderQS from "../../components/QuickService/HeaderQS";
import { loadByPolicyNumber } from "../../services/Api";
import { Spinner } from 'react-bootstrap';

const QuickService = (props) => {
    const { userData, setUserData } = props.user
    const navigate = useNavigate()
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    const setupUser = async () => {
        if (params.has("tkn")) {
            var response = await loadByPolicyNumber(params.get("tkn"))
            // var response = await verifyLoginQS(location.state.phone, location.state.zip)

            if (response.data.Result === "Success") {
                //Policy Component
                response.data.idCardData = response.data?.idCardData?.result
                //Payment Component
                // response.data.paymentData = (!paymentData.error) ? paymentData : null
                setUserData({ ...response.data, login: "quick-service", complete: true })
            } else {
                navigate("/")
            }
        } else {
            navigate("/")
        }
    }

    if (!userData.complete) {
        setupUser()
    }

    return (

        <>
            {
                (userData.complete) ?
                    <>
                        <HeaderQS title={"WELCOME"} />
                        <div className="pt-3">
                            <p className="small text-center">
                                Welcome to the CURE Auto Insurance Quick Service Center. What can we help you with today?
                            </p>
                        </div>
                        <div className="align-content-center justify-content-center d-flex">
                            <div className="col-12 row justify-content-center">

                                <QuickServiceBtn topTitle={"VIEW MY"} bottomTitle={"BILLING INFORMATION"} link={"BillingInfo"} />
                                <QuickServiceBtn topTitle={"EMAIL MY"} bottomTitle={"REPLACEMENT ID CARDS"} link={"IDCards"} />
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}>
                        <Spinner animation="border" />
                    </div>

            }
        </>
    );
};

const QuickServiceBtn = (props) => {
    const navigate = useNavigate()
    return (
        < button className="m-2 shadow btn border border-primary rounded-3 btn-outline-primary col-11 col-md-6" style={{ maxWidth: "280px", height: "180px" }}
            onClick={() => { navigate(props.link) }}>
            <div className="mt-4 pb-1">
                <h6 className="m-0 text-muted">
                    {props.topTitle}
                </h6>
            </div>
            <div className="mb-4 border-secondary border-top px-2 pt-1">
                <h5>
                    {props.bottomTitle}
                </h5>
            </div>
        </button >
    )
}

export default QuickService;