import { useEffect, useState } from "react"


const NavBar_RQ = (props) => {
    const [subTitle, setSubTitle] = useState('')
    const [progressTitle, setProgressTitle] = useState('');
    var screen = props.screen

    const progress = [
        'Address',
        'Household',
        'Additional',
        'Vehicles',
        'Sign',
    ]

    useEffect(() => {
        if (screen == "residence") {
            const policyName = props.userData.firstName + " " + props.userData.lastName
            setSubTitle(`${policyName.toLocaleUpperCase()}'S RESIDENCE`)
            setProgressTitle('Address')
        }
        else if (screen == "driverOHM") {
            setSubTitle('DRIVERS/HOUSEHOLD MEMBERS')
            setProgressTitle('Household')
        }
        else if (screen == "additionalInfo") {
            setSubTitle('ADDITIONAL INFORMATION')
            setProgressTitle('Additional')
        }
        else if (screen == "vehicles") {
            setSubTitle('VEHICLE INFORMATION')
            setProgressTitle('Vehicles')
        }
        else if (screen == "sign") {
            setSubTitle('AGREEMENTS')
            setProgressTitle('Sign')
        }
    })

    const isCurrent = ((value) => {
        return value == progressTitle;
    });

    return (
        <>
            <div className="container" >
                <div className="containerNav mt-5 col-12">
                    <div className="container row p-0">
                        <div className="col-6 p-0" style={{ textAlign: 'start' }}>
                            <h5 className="section-title subsectionTitle">
                                {subTitle}
                            </h5>
                        </div>
                        <nav className="col-6 p-0" style={{ textAlign: 'end', marginTop: '-5px' }}>
                            <ol className="breadcrumb" style={breadcrumb}>
                                {
                                    progress.map((title, id) => {
                                        const active = isCurrent(title) ? 'fw-bold text-blue' : 'disabled';
                                        return (
                                            <li key={id} className="breadcrumb-item align-items-center d-flex">
                                                <button className={`btn p-0 ${active}`}>
                                                    <span className="navBarSize">{title}</span>
                                                </button>
                                            </li>
                                        );
                                    })
                                }
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

const breadcrumb = {
    display: 'inline-flex',
    "--bs-breadcrumb-divider": "'>'"
}

export default NavBar_RQ