import { storageData } from "services/Services"
import { useContext } from 'react'
import { NewVehicleContext } from '../AddVehicleContext/NewVehicleContext'

const CollisionDeductibleList = () => {
    const { newVehicle } = useContext(NewVehicleContext);
    let options = []

    try {
        const dropdownOptions = JSON.parse(storageData.getItem('DropdownOptions'))
        let deductibleOptions = dropdownOptions.rows.filter(row => row.QuestionID === "81")

        // Filter options based on collisionType
        if (newVehicle.collisionType === 'Limited') {
            deductibleOptions = [{
                AnswerID: '$0',
                AnswerText: '$0'
            }]
        } else if (newVehicle.collisionType === 'Standard' || newVehicle.collisionType === 'Broadened') {
            const allowedDeductibles = ["$100", "$200", "$250", "$500", "$1000", "$1500", "$2000", "$2500"]
            deductibleOptions = deductibleOptions.filter(option => allowedDeductibles.includes(option.AnswerText))
        }

        if (deductibleOptions.length > 0) {
            options = [
                <option key="default" disabled value="">Select from the following...</option>,
                ...deductibleOptions.map(answer => (
                    <option key={answer.AnswerID} value={answer.AnswerText}>{answer.AnswerText}</option>
                ))
            ]
        }
    } catch (error) {
        console.error(error)
        options = [<option key="default" value="">Select from the following...</option>]
    }

    return <>{options}</>
}

export default CollisionDeductibleList