import { useContext, useEffect } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleAddMain from "./VehicleAdd/VehicleAddMain"
import VehicleRemove from "./VehicleRemove"
import { StoredAddedVehicles } from "./VehiclesList"
import { storageData } from "../../../services/Services"
import VehicleAddMainMI from "./VehicleAddMichigan/VehicleAddMainMI"
import VehicleModify_MI from "./VehicleAddMichigan/VehicleModify_MI"

const VehiclesStored = () => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    var pendingRemovedVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy?.pendingRemovedVehicles
    var storedVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles
    // Filters the removed vehicle out of the stored vehicles list
    storedVehiclesList = storedVehiclesList?.filter(storedVehicle => {
        // Check if any pending vehicles has a matching VIN
        return !pendingRemovedVehiclesList?.some(pendingVehicle => pendingVehicle?.VIN == storedVehicle?.VIN);
    });
    var newVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles
    var allVehicles = [...storedVehiclesList, ...newVehiclesList]

    useEffect(() => {
        // Toggles cancel policy (remove) menu if filled out
        storedVehiclesList.map((vehicle, id) => {
            if (vehicle?.vehicleRemovalReason == "CancelPolicy") {
                editReplacedRemovedVehicle(id)
                return
            }
        })
    }, [])

    // Toggles add menu with newly replaced/removed vehicle
    const editReplacedRemovedVehicle = (index) => {
        var editArr = storedVehiclesList.map((value, i) => {
            return (index === i) ? true : false
        });

        // For removing vehicle
        if (storedVehiclesList[index]?.type == "remove") {
            storedAddedContext.setDisplayVehicle(editArr)
        }
        storedAddedContext.setEditStoredVehicle(editArr)
        storedAddedContext.setEditType(storedVehiclesList[index]?.type + "Edit")
        storedAddedContext.setVehicleObtained(true)
        context.setShowQuestions(false)
    }

    // Removes replaced vehicle
    const clear = (vehicle, index) => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var oldVehicleReset = {}
        oldVehicleReset["Year"] = vehicle["Year"]
        oldVehicleReset["Make"] = vehicle["Make"]
        oldVehicleReset["Model"] = vehicle["Model"]
        oldVehicleReset["VIN"] = vehicle["VIN"]
        vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles[index] = oldVehicleReset
        storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
        context.setCancelPolicyAddVeh(false)
        storedAddedContext.submittedVehicleChanges()
    }

    // Displays edit text next to vehicle
    const displayChange = (change) => {
        var obj = {
            "replace": " - New Vehicle",
            "modify": " - MODIFIED",
            "remove": " - Removed"
        }
        return obj[change]
    }

    return (
        <>
            {/* Stored Vehicles */}
            {storedVehiclesList.map((vehicle, id) => {
                return (
                    <div key={id}>
                        {(storedAddedContext.displayVehicle[id] || !storedAddedContext.displayVehicle.includes(true)) &&
                            <>
                                <hr className="less-margin expandHeight" />
                                <div className="row">
                                    <div className="col-md-7 col-sm-6 col-5">
                                        <strong className="justify-content-left">
                                            <span>
                                                <span className="small text-uppercase text-muted">
                                                    {/* Displays replaced or stored vehicle */}
                                                    {vehicle?.type == "replace" ?
                                                        `${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
                                                        :
                                                        `${vehicle.Year} ${vehicle.Make} ${vehicle.Model}`
                                                    }
                                                </span>
                                                <span className="small fw-light fst-italic text-muted">{displayChange(vehicle?.type)}</span>
                                            </span>
                                        </strong>
                                    </div>

                                    {/* DELETE/REPLACE buttons for STORED vehicles */}
                                    {storedAddedContext.editType == "" && !context.addVehicleMenu && storedAddedContext.editAddedVehicleArr.length == 0 && vehicle?.type != "replace" && vehicle?.type != "modify" && vehicle?.type !== "remove" &&
                                        <div className="col-md-5 col-sm-6 col-7">
                                            <button type="button" className={`mx-1 btn btn-xs btn-danger pull-right text-light`} value={"DELETE"} onClick={() => { storedAddedContext.setEditOption("delete", id) }}>
                                                DELETE
                                            </button>
                                            <button type="button" className={`btn btn-xs btn-default pull-right text-dark modifyReplaceBtn`} value={"REPLACE"} onClick={() => { storedAddedContext.setEditOption("replace", id) }}>
                                                REPLACE
                                            </button>
                                            {context.userData.state == "Michigan" &&
                                                <button type="button" className={`btn btn-xs btn-default pull-right text-dark modifyReplaceBtn`} value={"MODIFY"} onClick={() => { storedAddedContext.setEditOption("modify", id) }} >
                                                    MODIFY
                                                </button>

                                            }
                                        </div>
                                    }

                                    {/* EDIT/CANCEL buttons for REPLACED/MODIFIED vehicles */}
                                    {(vehicle?.type == "replace" || vehicle?.type == "modify" || vehicle?.type == "remove") && !context.addVehicleMenu && storedAddedContext.editAddedVehicleArr.length == 0 && storedAddedContext.editStoredVehicle.length == 0 &&
                                        <div className="col-md-5 col-sm-6 col-7">
                                            <button type="button" className={`mx-1 btn btn-xs btn-default pull-right modifyReplaceBtn`} value={"CANCEL"} onClick={() => { clear(vehicle, id) }}>
                                                CANCEL
                                            </button>
                                            <button type="button" className={`mx-1 btn btn-xs btn-default pull-right modifyReplaceBtn`} value={"EDIT"} onClick={() => { editReplacedRemovedVehicle(id) }}>
                                                EDIT
                                            </button>
                                        </div>
                                    }
                                </div>

                                {/* Removing vehicle menu */}
                                {storedAddedContext.editType == "delete" && storedAddedContext.editStoredVehicle[id] &&
                                    <VehicleRemove allVehicles={allVehicles} id={id} saveEditOption={storedAddedContext.saveEditOption} displayVehicle={storedAddedContext.displayVehicle} />
                                }

                                {/* Modifying vehicle menu */}
                                {storedAddedContext.editType == "modify" && storedAddedContext.editStoredVehicle[id] &&
                                    <VehicleModify_MI id={id} type={storedAddedContext.editType} saveEditOption={storedAddedContext.saveEditOption} displayVehicle={storedAddedContext.displayVehicle} />
                                }

                                {/* Replacing vehicle menu */}
                                {storedAddedContext.editType == "replace" && storedAddedContext.editStoredVehicle[id] &&
                                    <>
                                        <VehicleRemove allVehicles={storedVehiclesList} id={id} type={"replace"} saveEditOption={storedAddedContext.saveEditOption} displayVehicle={storedAddedContext.displayVehicle} />

                                        {context.userData.state != "Michigan" ?
                                            // New Jersey / Pennsylvania
                                            <VehicleAddMain index={id} type={"replace"} saveEditOption={storedAddedContext.saveEditOption} />
                                            :
                                            // Michigan
                                            <VehicleAddMainMI index={id} type={"replace"} saveEditOption={storedAddedContext.saveEditOption} />
                                        }
                                    </>
                                }

                                {/* Editing removed vehicle menu */}
                                {storedAddedContext.editType == "removeEdit" && storedAddedContext.editStoredVehicle[id] &&
                                    <VehicleRemove allVehicles={storedVehiclesList} id={id} type={"removeEdit"} saveEditOption={storedAddedContext.saveEditOption} displayVehicle={storedAddedContext.displayVehicle} />
                                }

                                {/* Editing replaced vehicle menu */}
                                {storedAddedContext.editType == "replaceEdit" && storedAddedContext.editStoredVehicle[id] &&
                                    <>
                                        <VehicleRemove allVehicles={storedVehiclesList} id={id} type={"replaceEdit"} saveEditOption={storedAddedContext.saveEditOption} displayVehicle={storedAddedContext.displayVehicle} />

                                        {context.userData.state != "Michigan" ?
                                            // New Jersey / Pennsylvania 
                                            <VehicleAddMain index={id} type={"replaceEdit"} saveEditOption={storedAddedContext.saveEditOption} />
                                            :
                                            // Michigan
                                            <VehicleAddMainMI index={id} type={"replaceEdit"} saveEditOption={storedAddedContext.saveEditOption} />
                                        }
                                    </>
                                }

                                {/* Editing modified vehicle menu (MICHIGAN ONLY) */}
                                {storedAddedContext.editType == "modifyEdit" && storedAddedContext.editStoredVehicle[id] &&
                                    <>
                                        <VehicleModify_MI id={id} type={"modifyEdit"} saveEditOption={storedAddedContext.saveEditOption} />
                                    </>
                                }
                            </>
                        }
                    </div>
                )
            })}
        </>
    )
}

export default VehiclesStored