import { useState } from "react"
import StateDropdown from "./StateDropdown"
import LicenseStatusDropdown from "./LicenseStatusDropdown"
import { storageData } from "../../../services/Services"
import ArraysDOB from "./ArraysDOB"
import ValidationDriver from "./ValidationDriver"

const AddAdditionalDriver = (props) => {
    const mStatus = ["Single (never been married)", "Married", "Widowed", "Divorced", "Separated", "Civil Union"]
    const [days, setDays] = useState(ArraysDOB.generateArrayOfDays)
    const [years, setYears] = useState(ArraysDOB.generateArrayOfYears)
    const [newDriver, setNewDriver] = useState(
        (props.index >= props.newDrivers.length) ?
            {
                fullName: "",
                dobMonth: "",
                dobDay: "",
                dobYear: "",
                DOB: "",
                age: "",
                mStatus: "",
                gender: "",
                sameHousehold: "",
                driverResidenceAddress: "",
                driverResidenceCity: "",
                driverResidenceState: "",
                driverResidenceZip: "",
                validLicense: "",
                stateLicenseNumber: "",
                licenseStatus: "",
                otherLicenseState: "",
                otherStateLicenseNumber: "",
                permitNumber: "",
                enrolledInCollege: "",
                collegeName: "",
                collegeCity: "",
                collegeState: "",
                keptAtCollege: "",
                over100Miles: ""
            } :
            {
                ...props.newDrivers[props.index]
            })

    // Add New Driver button
    const saveNewDriver = async (driver, index, action) => {
        // Checks Validation
        var validationResponse = await ValidationDriver({
            newDriver,
            setShow: props.setShow,
            setValidationMsg: props.setValidationMsg,
            handleClose: props.handleClose,
            handleShow: props.handleShow,
            setValidationLoad: props.setValidationLoad,
            setValidationTitle: props.setValidationTitle
        })
        // Clears validation
        if (validationResponse) {
            var sessionDataCopy = props.sessionData
            if (action == "add") {
                sessionDataCopy.household.drivers.newDrivers.push(newDriver)
                props.setAddDriverMenu(false)
            }
            else if (action == "edit") {
                sessionDataCopy.household.drivers.newDrivers[props.index] = newDriver
                props.setNewDriverMenu([])
            }
            props.setSessionData(sessionDataCopy)
            storageData.setItem("sessionDataRQ", JSON.stringify(props.sessionData))
        }
    }

    // Checks age for toggle
    const checksAgeToggle = (dob) => {
        var ageDiff = new Date(Date.now() - dob.getTime())
        return Math.abs(ageDiff.getUTCFullYear() - 1970)
    }

    // Onchange function for all fields of form
    const updateSessionData = (key, value) => {
        var newDriverCopy = newDriver
        newDriverCopy[key] = value

        // Adds DOB
        if (newDriverCopy.dobMonth && newDriverCopy.dobDay && newDriverCopy.dobYear) {
            var dob = (newDriverCopy.dobMonth + "/" + newDriverCopy.dobDay + "/" + newDriverCopy.dobYear)
            newDriverCopy["age"] = checksAgeToggle(new Date(dob))
            newDriverCopy["DOB"] = dob
        }
        setNewDriver({ ...newDriverCopy })
    }

    return (
        <>
            <div className="mt-4">
                {/* Full Name */}
                <div className="row">
                    <div>
                        <input type="text" value={newDriver.fullName} className="textFullName ms-2" placeholder="Full Name" maxLength={150} onChange={(e) => updateSessionData("fullName", e.target.value)} />
                    </div>
                </div>

                {/* DOB */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Date of Birth:</p>
                    </div>

                    {/* Month */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newDriver.dobMonth} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobMonth", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Month</option>
                            {ArraysDOB.month.map((month, id) => {
                                return (
                                    <option value={month.id} key={id}>{month.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    {/* Day */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newDriver.dobDay} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobDay", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Day</option>
                            {days.map((day, id) => {
                                return (
                                    <option value={day} key={id}>{day}</option>
                                )
                            })}
                        </select>
                    </div>

                    {/* Year */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newDriver.dobYear} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobYear", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Year</option>
                            {years.map((year, id) => {
                                return (
                                    <option value={year} key={id}>{year}</option>
                                )
                            })}
                        </select>
                    </div><br />
                </div>

                {/* Marital Status */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Marital Status:</p>
                    </div>

                    <div className="col-12 col-md-8">
                        <select defaultValue={newDriver.mStatus} className="form-select-sm dobSelect px-0 col-12 textDOB" onChange={(e) => updateSessionData("mStatus", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Marital Status</option>
                            {mStatus.map((status, id) => {
                                return (
                                    <option value={status} key={id}>{status}</option>
                                )
                            })}
                        </select>
                    </div><br />
                </div>


                {/* Gender */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Gender:</p>
                    </div>

                    <div className="col-12 col-md-8">
                        <select defaultValue={newDriver.gender} className="form-select-sm dobSelect px-0 col-12 textDOB" onChange={(e) => updateSessionData("gender", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Gender</option>
                            <option value={'Female'}>Female</option>
                            <option value={'Male'}>Male</option>
                        </select>
                    </div><br />
                </div>

                {/* Same Household */}
                <div>
                    <div className="mt-3 row">
                        <div>
                            <label className="sameHouseholdLabel px-2">
                                <span>
                                    Resides in Same Household
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="mt-3 row">
                        <div className="p-0">
                            <div className="radio-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input m-0" defaultChecked={newDriver.sameHousehold == "Yes"} style={{ verticalAlign: 'middle' }} name="rqSameHH" type={"radio"} value={"Yes"} onClick={(e) => { updateSessionData("sameHousehold", e.target.value) }} />
                                    <span className="ms-2">Yes</span>
                                </label>
                            </div>
                            <div className="radio-inline">
                                <label className="form-check-label">
                                    <input className="form-check-input m-0" defaultChecked={newDriver.sameHousehold == "No"} style={{ verticalAlign: 'middle' }} name="rqSameHH" type={"radio"} value={"No"} onClick={(e) => { updateSessionData("sameHousehold", e.target.value) }} />
                                    <span className="ms-2">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div><br />

                {/* Driver Resides */}
                {(newDriver.sameHousehold != "" && newDriver.sameHousehold == "No") &&
                    <div>
                        <div className="my-3 row">
                            <div>
                                <label className="sameHouseholdLabel px-2">
                                    <span>
                                        Please provide the address where this driver resides
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div>
                            {/* Driver Residence */}
                            <div className="row">
                                {/* Address */}
                                <div>
                                    <input type="text" className="textResidence ms-2" value={newDriver.driverResidenceAddress} placeholder="Address" maxLength={50} style={{ width: '350px' }} onChange={(e) => updateSessionData("driverResidenceAddress", e.target.value)} />
                                </div><br />

                                <div className="row">
                                    {/* City */}
                                    <div className="mt-2 col-4">
                                        <input type="text" className="textResidence ms-2" value={newDriver.driverResidenceCity} placeholder="City" maxLength={50} style={{ width: '150px' }} onChange={(e) => updateSessionData("driverResidenceCity", e.target.value)} />
                                    </div>

                                    {/* State */}
                                    <div className="mt-2 col-4">
                                        <select defaultValue={newDriver.driverResidenceState} className="col-12 form-select-sm dobSelect px-0 textResidence" onChange={(e) => updateSessionData("driverResidenceState", e.target.value)} style={{ backgroundColor: 'white' }}>
                                            <option value={''}>State</option>
                                            {StateDropdown()}
                                        </select>
                                    </div>

                                    {/* Zip */}
                                    <div className="mt-2 col-4">
                                        <input type="text" className="textResidence" value={newDriver.driverResidenceZip} placeholder="Zip" maxLength={5} style={{ width: '100px' }} onChange={(e) => updateSessionData("driverResidenceZip", e.target.value)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {/* Valid State License */}
                <div>
                    <div className="mt-3 row">
                        <div>
                            <label className="sameHouseholdLabel px-2">
                                <span>
                                    Does this driver have a valid {props.userData.state} driver's license?
                                </span>
                                <span className="radiotip">
                                    <a>
                                        <i className="fa fa-info-circle ms-2" onClick={() => {
                                            props.handleShow();
                                            props.setValidationTitle("Invalid License Notice")
                                            props.setValidationMsg(`You must have a valid driver's license to be considered a licensed driver. 
                                        This is a license that has been issued to you by the state, is not expired, suspended, or revoked, 
                                        and includes all driving rights and privileges.`)
                                        }}></i>
                                    </a>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="mt-3 row">
                        <div className="p-0">
                            <div className="radio-inline">
                                <label className="form-check-label" onChange={(e) => updateSessionData("validLicense", e.target.value)}>
                                    <input className="form-check-input m-0" defaultChecked={newDriver.validLicense == "Yes"} style={{ verticalAlign: 'middle' }} name="rqValidLicenseDriver" type={"radio"} value={"Yes"} />
                                    <span className="ms-2">Yes</span>
                                </label>
                            </div>
                            <div className="radio-inline">
                                <label className="form-check-label" onChange={(e) => updateSessionData("validLicense", e.target.value)}>
                                    <input className="form-check-input m-0" defaultChecked={newDriver.validLicense == "No"} style={{ verticalAlign: 'middle' }} name="rqValidLicenseDriver" type={"radio"} value={"No"} />
                                    <span className="ms-2">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div><br />

                {/* License Number */}
                {(newDriver.validLicense != "" && newDriver.validLicense == "Yes") &&
                    <div className="row">
                        <div className="ms-2">
                            <input type="text" className="textFullName" value={newDriver.stateLicenseNumber} placeholder="License Number" maxLength={50} onChange={(e) => updateSessionData("stateLicenseNumber", e.target.value)} />
                            <div className="important-note">(Not required right now, but needed in next 30 days)</div>
                        </div>
                    </div>
                }

                {/* Status of Driver License */}
                {(newDriver.validLicense != "" && newDriver.validLicense == "No") &&
                    <div className="row">
                        <div>
                            <select defaultValue={newDriver.licenseStatus} className="col-8 px-0 ms-2 form-select-sm dobSelect textResidence" onChange={(e) => updateSessionData("licenseStatus", e.target.value)} style={{ backgroundColor: 'white' }}>
                                <option value="" disabled>What is the current status of this driver's license?</option>
                                {LicenseStatusDropdown()}
                            </select>
                        </div>
                    </div>
                }

                {/* Other State License */}
                {(newDriver.validLicense == "No" && newDriver.licenseStatus == "Valid Other State License") &&
                    <div className="mt-3 row ps-5">
                        <div>
                            <label className="sameHouseholdLabel">
                                <span>
                                    Please provide the information for this driver's out of state license.
                                </span>
                            </label>
                        </div>

                        {/* Other License State */}
                        <div className="mt-3 row">
                            <div className="col-12 col-md-3">
                                <p className="">License State:</p>
                            </div>

                            <div className="col-12 col-md-9">
                                <select defaultValue={newDriver.otherLicenseState} className="form-select-sm textDOB dobSelect px-0 col-md-4 col-7" onChange={(e) => updateSessionData("otherLicenseState", e.target.value)} style={{ backgroundColor: 'white' }}>
                                    <option value={''}>State</option>
                                    {StateDropdown()}
                                </select>
                            </div>
                        </div><br />

                        {/* Other License Number */}
                        <div className="mt-2 row">
                            <div className="col-12 col-md-3">
                                <p className="">License Number:</p>
                            </div>
                            <div className="col-12 col-md-9">
                                <input type="text" className="textFullName" value={newDriver.otherStateLicenseNumber} placeholder="License Number" maxLength={50} onChange={(e) => updateSessionData("otherStateLicenseNumber", e.target.value)} />
                                <div className="important-note">(Not required right now, but needed in next 30 days)</div>
                            </div>
                        </div>
                    </div>
                }

                {/* Permit Number */}
                {(newDriver.validLicense == "No" && newDriver.licenseStatus == "Permit") &&
                    <div className="row mt-2">
                        <div className="ms-2">
                            <input type="text" className="textFullName" value={newDriver.permitNumber} placeholder="Permit Number" maxLength={50} onChange={(e) => updateSessionData("permitNumber", e.target.value)} />
                            <div className="important-note">(Not required right now, but needed in next 30 days)</div>
                        </div>
                    </div>
                }

                {/* College Enrollment (If 26 or younger) */}
                {(checksAgeToggle(new Date(newDriver.DOB)) <= 26) &&
                    <div>
                        <div className="mt-3 row">
                            <div>
                                <label className="sameHouseholdLabel px-2">
                                    <span>
                                        Is this driver currently enrolled in a college or university?
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="mt-3 row">
                            <div className="p-0">
                                <div className="radio-inline">
                                    <label className="form-check-label" onChange={(e) => updateSessionData("enrolledInCollege", e.target.value)}>
                                        <input className="form-check-input m-0" defaultChecked={newDriver.enrolledInCollege == "Yes"} style={{ verticalAlign: 'middle' }} name="rqEnrolledCollege" type={"radio"} value={"Yes"} />
                                        <span className="ms-2">Yes</span>
                                    </label>
                                </div>
                                <div className="radio-inline">
                                    <label className="form-check-label" onChange={(e) => updateSessionData("enrolledInCollege", e.target.value)}>
                                        <input className="form-check-input m-0" defaultChecked={newDriver.enrolledInCollege == "No"} style={{ verticalAlign: 'middle' }} name="rqEnrolledCollege" type={"radio"} value={"No"} />
                                        <span className="ms-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {newDriver.enrolledInCollege == "Yes" &&
                            <div className="mt-3 row">
                                <div className="col-10 my-1 mx-2">
                                    <input type="text" className="textFullName w-100" value={newDriver.collegeName} placeholder="School Name" maxLength={100} onChange={(e) => updateSessionData("collegeName", e.target.value)} />
                                </div>
                                <div className="col-5 my-1 ms-2">
                                    <input type="text" className="textFullName w-100" value={newDriver.collegeCity} placeholder="City" maxLength={100} onChange={(e) => updateSessionData("collegeCity", e.target.value)} />
                                </div>
                                <div className="col-5 my-1 mx-0">
                                    <select defaultValue={newDriver.collegeState} className="w-100 form-select-sm dobSelect textResidence" onChange={(e) => updateSessionData("collegeState", e.target.value)} style={{ backgroundColor: 'white' }}>
                                        <option value={''}>State</option>
                                        {StateDropdown()}
                                    </select>
                                </div>

                                {/* Insured Vehicle Kept At School */}
                                <div className="mt-3 row">
                                    <div>
                                        <label className="sameHouseholdLabel px-2">
                                            <span>
                                                Is any vehicle insured under this policy kept at school?
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="mt-3 row">
                                    <div className="p-0">
                                        <div className="radio-inline">
                                            <label className="form-check-label" onChange={(e) => updateSessionData("keptAtCollege", e.target.value)}>
                                                <input className="form-check-input m-0" defaultChecked={newDriver.keptAtCollege == "Yes"} style={{ verticalAlign: 'middle' }} name="rqKeptAtSchool" type={"radio"} value={"Yes"} />
                                                <span className="ms-2">Yes</span>
                                            </label>
                                        </div>
                                        <div className="radio-inline">
                                            <label className="form-check-label" onChange={(e) => updateSessionData("keptAtCollege", e.target.value)}>
                                                <input className="form-check-input m-0" defaultChecked={newDriver.keptAtCollege == "No"} style={{ verticalAlign: 'middle' }} name="rqKeptAtSchool" type={"radio"} value={"No"} />
                                                <span className="ms-2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Insured Vehicle Kept At School */}
                                {newDriver.keptAtCollege == "Yes" &&
                                    <>
                                        <div className="mt-3 row">
                                            <div>
                                                <label className="sameHouseholdLabel px-2">
                                                    <span>
                                                        Is the school over 100 miles from your address?
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mt-3 row">
                                            <div className="p-0">
                                                <div className="radio-inline">
                                                    <label className="form-check-label" onChange={(e) => updateSessionData("over100Miles", e.target.value)}>
                                                        <input className="form-check-input m-0" defaultChecked={newDriver.over100Miles == "Yes"} style={{ verticalAlign: 'middle' }} name="rqSchoolOver100Miles" type={"radio"} value={"Yes"} />
                                                        <span className="ms-2">Yes</span>
                                                    </label>
                                                </div>
                                                <div className="radio-inline">
                                                    <label className="form-check-label" onChange={(e) => updateSessionData("over100Miles", e.target.value)}>
                                                        <input className="form-check-input m-0" defaultChecked={newDriver.over100Miles == "No"} style={{ verticalAlign: 'middle' }} name="rqSchoolOver100Miles" type={"radio"} value={"No"} />
                                                        <span className="ms-2">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }




                    </div>
                }
                {/* Save/Cancel New Driver */}
                <div className="row justify-content-end p-3">
                    <input type="submit" value={"Save"} className="saveButton" onClick={() => saveNewDriver(newDriver, props.index, (props.index == props.newDrivers.length) ? "add" : "edit")} />
                    <input type="submit" value={"Cancel"} className="cancelButton ms-3" onClick={() => { (props.index == props.newDrivers.length) ? props.setAddDriverMenu(false) : props.setNewDriverMenu([]) }} />
                </div>

            </div>

        </>
    )
}


export default AddAdditionalDriver