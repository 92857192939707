import React, { useState } from "react";
import { storageData } from "../../services/Services";

const breadcrumb = {
  borderRadius: '0.37rem',
  "--bs-breadcrumb-divider": "'>'",
  textDecoration: 'none'
}

const AddressProgressBarSect = (props) => {
  var navSet;

  const [navs, setNavs] = useState([
    '1. New Address',
    '2. Household',
    '3. Driver Assignment',
    '4. Summary'
  ]);

  const [additionalNavs, setAdditionalNavs] = useState([
    '1. New Address',
    '2. Household',
    '3. Additional',
    '4. Driver Assignment',
    '5. Summary'
  ]);

  const settingNav = () => {
    var drivers = storageData.getItem('drivers');
    if (drivers) {
      drivers = JSON.parse(drivers)
      if ((drivers.length > 0) && ((props.screen === "Additional")
        || (props.screen === "Driver Assignment")
        || (props.screen === "Summary"))) {
        navSet = additionalNavs;
      }
      else {
        navSet = navs;
      }
    }
    else {
      navSet = navs;
    }
  }

  const isCurrent = ((value) => {
    return value.slice(3) === props.screen;
  });
  settingNav()
  return (
    <>
      <div className="clearfix"></div>

      {/* Address Progress Bar */}
      <div className="col-12">
        <nav>
          <ol className="breadcrumb justify-content-center" style={breadcrumb}>
            {
              navSet.map((nav, navIndex) => {
                const active = isCurrent(nav) ? 'fw-bold text-blue' : 'disabled';
                return (
                  <li key={navIndex} className="breadcrumb-item align-items-center d-flex">
                    <button className={`btn ${active}`}>
                      {nav}
                    </button>
                  </li>
                );
              })
            }
          </ol>
        </nav>
      </div>

    </>
  )
}

export default AddressProgressBarSect