import { Col } from "react-bootstrap"

const FormSelect = (props) => {
    const {
        id,
        value,
        label,
        options,
        col = {
            label: {
                xl: 4
            },
            input: {
                xl: 4
            },
        },
        defaultValue = options[0],
        caret = true,
        placeholder,
        className = {
            margin: "",
            marginBottom: "",
            marginSecond: ""
        },
        callback
    } = props
    return (
        <>
            <div className={`row form-group form-group-sm align-items-center ${className.margin}`}>
                <Col {...col.label}>
                    <div className={`text-blue fw-bold ${className?.marginBottom}`}>
                        {label}
                    </div>
                </Col>
                <Col {...col.input}>
                    <div className={`btn-group bootstrap-select ${caret && " custom-selector"} ${className.marginSecond}`}>
                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                            defaultValue={value || defaultValue}
                            onChange={(e) => { callback(id, e.target.value) }}>
                            {options.map((item, i) => (
                                <option key={i} value={item} disabled={!i}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </Col>
            </div>
        </>
    )
}

export default FormSelect