import { useContext } from "react";
import { AddressChangeContext } from "../AddressChangeContext";

const CurrentResidenceSect = () => {
  var context = useContext(AddressChangeContext)
  var currAddress = context?.currentResidence

  return (
    <>
      {/* Subtitle */}
      <div className="d-flex align-content-center flex-column">
        <h6 className="section-title">
          CURRENT RESIDENCE
        </h6>

        {/* Displays Current Address */}
        <small className="p-2 mb-3">
          <strong >
            <p>
              {currAddress.address1}<br />
              {`${currAddress?.city}, ${currAddress?.state + " " + currAddress?.zip}`}
            </p>
          </strong>
        </small>
      </div>
    </>
  )
}

export default CurrentResidenceSect