import StateDropdown from "../../../RenewalQuestionnaireComp/DriversOHM/StateDropdown"
import NameRegisteredDropdown from "../../VehicleDropdowns/NameRegisteredDropdown"
import VehicleFinanceList from "../../VehicleDropdowns/VehicleFinanceList"
import VehicleAddMI_Coverages from "./VehicleAddMI_Coverages"
import VehiclePersonInfo from "./VehiclePersonInfo"

const VehicleAddMI_Financed = ({ vehicleAddContext, context }) => {

    return (
        <>
            {/* Finance Company Name */}
            <div className="row form-group form-group-sm align-items-center my-3">
                <div className="col-6 text-primary fw-bold">
                    Finance Company Name*
                </div>
                <div className="col-sm-8 col-md-6">
                    <select className="btn btn-default btn-group dropdown-toggle filter-option mt-2"
                        value={vehicleAddContext.newVehicle?.vehicleFinanceCompany}
                        onChange={(e) => vehicleAddContext.saveAddition("vehicleFinanceCompany", e.target.value)}
                    >
                        <option value={""} disabled>Select from the following...</option>
                        {VehicleFinanceList()}
                    </select>
                </div>
            </div>

            {/* Co-Owned */}
            <div className="row form-group form-group-sm align-items-center my-3">
                <div className="col-6 text-primary fw-bold">
                    Is this vehicle co-owned?*
                </div>
                <div className="col-md-6 col-sm-8 px-0 pb-1">
                    <div className="radio-inline form-check-inline px-3 mx-0">
                        <label>
                            <input type={"radio"} className="form-check-input me-2" name="coOwned" value={"1"}
                                checked={(vehicleAddContext?.newVehicle?.coOwned === "1") ? vehicleAddContext?.newVehicle?.coOwned : ""}
                                onChange={(e) => vehicleAddContext.saveAddition("coOwned", e.target.value)}
                            />Yes
                        </label>
                    </div>
                    <div className="radio-inline form-check-inline">
                        <label>
                            <input type={"radio"} className="form-check-input me-2" name="coOwned" value={"0"}
                                checked={(vehicleAddContext?.newVehicle?.coOwned === "0") ? vehicleAddContext?.newVehicle?.coOwned : ""}
                                onChange={(e) => vehicleAddContext.saveAddition("coOwned", e.target.value)}
                            />No
                        </label>
                    </div>
                </div>
            </div>

            {/* Co Owned Selected Yes */}
            {vehicleAddContext?.newVehicle?.coOwned === "1" &&
                <VehiclePersonInfo vehicleAddContext={vehicleAddContext} type={"Co-Owner"} />
            }

            {/* Vehicle Registered to PERSON Dropdown */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    In what name is (will) the vehicle be registered?
                </div>
                <div className="col-8">
                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option mt-2"
                        value={vehicleAddContext?.newVehicle?.nameRegistered}
                        onChange={(e) => vehicleAddContext.saveAddition("nameRegistered", e.target.value)}
                    >
                        {NameRegisteredDropdown()}
                    </select>
                </div>
            </div>

            {/* Name Registered as None of the Above */}
            {vehicleAddContext?.newVehicle?.nameRegistered == "43095" &&
                <VehiclePersonInfo vehicleAddContext={vehicleAddContext} type={"Registrant"} />
            }
            {/* Vehicle Registered to STATE Dropdown */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    In what state is (will) the vehicle be registered?*
                </div>
                <div className="col-8">
                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option mt-2"
                        value={vehicleAddContext?.newVehicle?.stateRegistered}
                        onChange={(e) => vehicleAddContext.saveAddition("stateRegistered", e.target.value)}
                    >
                        {StateDropdown()}
                    </select>
                </div>
            </div>

            <VehicleAddMI_Coverages vehicleAddContext={vehicleAddContext} context={context} />
        </>
    )
}

export default VehicleAddMI_Financed