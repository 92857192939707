const NeedHelp = () => {
    return (
        <>
            <div className="my-3 text-center">
                <p className="fw-bold text-primary my-0">NEED HELP?</p>
                <h4 className="my-0" style={{color: "#6c757d"}}>1(800)535-2873</h4>
                <p className="lh-3"  style={{color: "#6c757d"}}>
                    Monday-Friday 8:00am - 7:00pm<br/>
                    Saturday 9:30am - 4:00pm
                </p>
            </div>
        </>
    )
}

export default NeedHelp