import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Policy.css'

const Policy = (props) => {
    const [pastEffectiveDate, setPastEffectiveDate] = useState(false);
    const { idCardData, policyNumber, state } = props.user.userData

    // First Effective Date on Policy
    const effDate = new Date(props.user.userData.boundDate);
    const setEffectiveDt = effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + effDate.getFullYear()
    const effectiveDate = new Date(setEffectiveDt)
    
    // Checks effective date for policy change requests availability
    const policyRequestChangeDate = new Date(effectiveDate.setDate(effectiveDate.getDate() + 31))
    useEffect(() => {
        if (policyRequestChangeDate < new Date()) {
            setPastEffectiveDate(true);
        }
    }, [])

    // Renewal Effective Date
    const renewalEffectiveDt = !!idCardData[0] && 'effectivedt' in idCardData[0] ? new Date(idCardData[0].effectivedt) : effDate
    const sDate = FormatDate(renewalEffectiveDt)
    const tempDate = new Date(renewalEffectiveDt.setMonth(renewalEffectiveDt.getMonth() + 6))
    const eDate = FormatDate(tempDate)

    return (
        <>
            <div className="d-flex align-content-center flex-column">
                <h4 className="text-center section-title">
                    MANAGE MY POLICY
                </h4>
                <div className="d-flex flex-column align-content-center my-4 p-2 small">
                    <table className="align-content-start policy-table">
                        <tbody>
                            <tr>
                                <th>
                                    Policy Number:
                                </th>
                                <td>
                                    &nbsp;<strong>{policyNumber}</strong>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Policy Period:
                                </th>
                                <td>
                                    &nbsp;{sDate} - {eDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <div className="my-4 p-2">
                        <ul>
                            <Link to="/policy-id-cards"><li className="linkColors">ID Cards</li></Link>
                            <Link to="/payment-history"><li className="linkColors">View Payment History</li></Link>
                            <Link to="/documents-vault"><li className="linkColors">View Bill/Declaration Pages</li></Link>
                            {pastEffectiveDate &&
                                <>
                                    <br />
                                    <Link to="/change-address/landing"><li className="linkColors">Change Address</li></Link>
                                    <Link to="/update-drivers/landing"><li className="linkColors">Update Drivers/Household</li></Link>
                                    <Link to="/endorsement/landing"><li className="linkColors">Add Vehicle</li></Link>
                                    <Link to="/update-vehicles/landing"><li className="linkColors">Replace Vehicle</li></Link>
                                    <Link to="/update-vehicles/landing"><li className="linkColors">Remove Vehicle</li></Link>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const FormatDate = (tempDate) => {
    var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
    var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
    var dateYear = `${tempDate.getFullYear()}`
    const date = `${dateMonth}/${dateDay}/${dateYear}`
    return date

}

export default Policy;