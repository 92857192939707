import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import VehicleMain from "../../components/ChangeRequest_VehicleChangeComp/1_Vehicles/VehicleMain"
import { VehicleChangeContextComp } from "../../components/ChangeRequest_VehicleChangeComp/VehicleChangeContext"
import VehicleProgressBar from "../../components/ChangeRequest_VehicleChangeComp/VehicleProgressBar"
import { useEffect } from "react"

const Vehicles = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <VehicleChangeContextComp>
            <Menu Title={"VEHICLE CHANGE"} FirstName={userData.firstName} userData={userData} />
            <VehicleProgressBar screen={"Vehicles"} />
            <RoundyContainer Content={VehicleMain} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </VehicleChangeContextComp>
        </>
    )
}

export default Vehicles