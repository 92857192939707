import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { useEffect, useState } from "react"
import AddVehicleMain from "components/StraightThroughVehicleComp/AddVehicleMain"
import DisclosureAgreement from "components/StraightThroughVehicleComp/DisclosureAgreement/DisclosureAgreement"
import { storageData } from "services/Services"
import AddVehicleProgress from "components/StraightThroughVehicleComp/AddVehicleProgress"
import { AddVehicleContext } from "components/ChangeRequest_VehicleChangeComp/1_Vehicles/VehicleAdd/VehicleAddMain"
import { AddVehicleContextComp } from "components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext"
import { useNavigate } from "react-router-dom"

const AddVehicle = (props) => {
    const navigate = useNavigate()
    const { userData } = props.user
    const [showModal, setShowModal] = useState(true);
    const websiteMaintenance = JSON.parse(storageData?.getItem("websiteMaintenance"));

    const handleClose = () => {
        setShowModal(false);
        userData["disclosureModal"] = true
        storageData.setItem("userData", JSON.stringify(userData));
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        // Redirect to maintenance page if maintenance is on for Add Vehicle Endorsement
        if (websiteMaintenance?.status?.Result == "Active" && websiteMaintenance?.status?.Pages?.includes("EndorsementAddVehicle")) {
            navigate("/maintenance")
            return
        }
    }, [])

    return (
        <>
            <AddVehicleContextComp>
                <Menu Title={"ADD VEHICLE"} FirstName={userData.firstName} userData={userData} />
                <AddVehicleProgress screen={"Vehicle"} />
                <RoundyContainer Content={AddVehicleMain} NoRoundy
                    params={{
                        storedData: { ...props.storedData },
                        setStoredData: props.setStoredData
                    }}
                />

                {!userData?.disclosureModal &&
                    <DisclosureAgreement
                        showModal={showModal}
                        handleClose={handleClose}
                        title="DISCLOSURE AGREEMENT"
                        policyState={userData.state}
                    />
                }
            </AddVehicleContextComp>
        </>
    )
}

export default AddVehicle