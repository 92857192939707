import { useEffect, useState } from "react"

const SelfServiceMsg = ({ userData }) => {
    const [displaySelfService, setDisplaySelfService] = useState(false)
    var message = userData?.selfService?.Message
    var currentDate = new Date()

    useEffect(() => {
        if (userData?.selfService?.SelfServiceSwitch) {
            if (userData?.selfService?.Expiration) {
                var startDate = new Date(userData?.selfService?.StartDate)
                var expDate = new Date(userData?.selfService?.EndDate)
                
                // Checks if is between start date and expiration date
                if (currentDate > startDate && currentDate < expDate) {
                    setDisplaySelfService(true)
                }
            }
            else {
                setDisplaySelfService(true)
            }
        }
    }, [displaySelfService])


    return (
        <>
            {(displaySelfService) &&
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1 offset-lg-1 col-md-12">
                            <div className="row">
                                <div className="col-lg-10 col-lg-push-1 offset-lg-1">
                                    <div className="pt-3 mx-1 mt-1">
                                        <p className="fw-bold" style={{ color: 'rgb(255 0 24)' }}>
                                            {message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SelfServiceMsg