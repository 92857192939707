import { useEffect, useState } from "react"
import { getWebsiteMaintenanceStatus, getWebsiteMaintenanceMessage } from "services/Api"

const MaintenanceMsg = () => {
    const [maintenanceMessage, setMaintenanceMessage] = useState(null);

    useEffect(() => {
        async function fetchWebsiteMaintainence() {
            var websiteStatusResponse = await getWebsiteMaintenanceStatus();
            var websiteMessageResponse = await getWebsiteMaintenanceMessage();
        
            const status = websiteStatusResponse?.data
            if (status.Result == "Active" && status?.Pages.includes("MyAccountLogin")) {
                if (status?.AllowAccessPages.includes("MyAccountLogin"))
                    setMaintenanceMessage(websiteMessageResponse?.data?.Result);
                else
                    window.location = "https://www.cure.com";
            }
            else {
                setMaintenanceMessage(null);
            }
        }
        
        fetchWebsiteMaintainence();
    }, [maintenanceMessage]);

    return (
        <>
            {(maintenanceMessage) &&
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1 offset-lg-1 col-md-12">
                            <div className="row">
                                <div className="col-lg-10 col-lg-push-1 offset-lg-1">
                                    <div className="pt-3 mx-1 mt-1">
                                        <p className="fw-bold" style={{ color: 'rgb(255 0 24)' }}>
                                            {maintenanceMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MaintenanceMsg