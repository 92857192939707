import React from "react";
import ReactDatePicker from "react-datepicker";

class MoveDate extends React.Component {
    constructor(props) {
        super(props);
    }

    moveDate = (date) => {
        var datePassed = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        return datePassed
    }

    render() {
        return (
            <>
                {/* Move Date */}
                <div className="row form-group form-group-sm align-items-center">
                    <strong className="text-primary">When did this household member move in?*</strong>
                </div>
                {/* Move Date response */}
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-sm-12">
                        <ReactDatePicker
                            value={this.props.moveDate}
                            placeholderText="MM/DD/YYYY"
                            className="text-sm-start fw-light col-sm-8 col-12 btn btn-default btn-group mt-1"
                            selected={(this.props.moveDate !== "") ? new Date(this.props.moveDate) : new Date()}
                            onChange={(e) => this.props.handleCallBack("moveDate", this.moveDate(e))}
                            onKeyDown={(e) => { e.preventDefault() }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default MoveDate