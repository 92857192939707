import { useContext } from "react"
import { HouseholdChangeContext } from "../HouseholdChangeContext"

const HouseholdSummary_Changes = () => {
    const context = useContext(HouseholdChangeContext)
    var storedDrivers = context?.householdChangeClass?.driversHHMs?.drivers?.storedDrivers
    var newDrivers = context?.householdChangeClass?.driversHHMs?.drivers?.newDrivers
    var storedMembers = context?.householdChangeClass?.driversHHMs?.householdMembers?.storedMembers
    var newMembers = context?.householdChangeClass?.driversHHMs?.householdMembers?.newMembers
    var allDrivers = [...storedDrivers, ...newDrivers ]
    var allHHMs = [...storedMembers, ...newMembers]

    return (
        <>
            <div>
                <h3 className="text-center text-primary fw-bold">SUMMARY OF CHANGES</h3>
                <p className="small">
                    All policy changes are subject to Underwriting review and approval.
                    Please review your changes and click Confirm below.
                </p>
            </div>
            <div className="text-center">
                <div>
                    {/* Drivers */}
                    {allDrivers?.map((driver, id) => {
                        return (
                            ("driverStatus" in driver && driver?.driverStatus != "") &&
                            <div key={id} className="small">
                                <strong className="text-uppercase text-primary">{driver?.fullName}</strong>
                                <span className="fst-italic"> - {driver?.driverStatus}</span>
                            </div>
                        )
                    })}

                    {/* HHMs */}
                    {allHHMs?.map((member, id) => {
                        return (
                            ("memberStatus" in member && member?.memberStatus != "") &&
                            <div key={id} className="small">
                                <strong className="text-uppercase text-primary">{member?.fullName}</strong>
                                <span className="fst-italic"> - {member?.memberStatus}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default HouseholdSummary_Changes