const LicenseStatusDropdownHouseholdMember = () => {

    return (
        <>
            <option value="No License or Identification Card">No License or Identification Card</option>
            <option value="Valid Other State License">Valid Other State License</option>
            <option value="Valid Foreign License">Valid Foreign License</option>
            <option value="International License">International License</option>
            <option value="Suspended">Suspended</option>
            <option value="Permanently Revoked">Permanently Revoked</option>
            <option value="Permit">Permit</option>
            <option value="Expired">Expired</option>
            <option value="Identification Only">Identification Only</option>
            <option value="Surrendered">Surrendered</option>
            <option value="Medical Reasons">Never had a License - Medical Reasons</option>
            <option value="By Choice">Never had a License - By Choice</option>
        </>
    )
}

export default LicenseStatusDropdownHouseholdMember