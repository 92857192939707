import { useContext, useState } from "react"
import { AddVehicleContext } from "./VehicleAddMainMI"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { StoredAddedVehicles } from "../VehiclesList"
import DatePick from "../../../DatePicker"
import PossessionVehicleDropdown from "../../VehicleDropdowns/PossessionVehicleDropdown"
import VehicleAddMI_Owned from "./VehicleAddMI_Owned"
import VehicleAddMI_Leased from "./VehicleAddMI_Leased"
import VehicleAddMI_Financed from "./VehicleAddMI_Financed"

const VehicleAddMI_3 = () => {
    const vehicleAddContext = useContext(AddVehicleContext)
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const [dateObtained, setDateObtained] = useState("")

    return (
        <>
            {!context.vinLoad && storedAddedContext.vehicleObtained &&
                <>
                    <div className="text-center">
                        <strong className="text-primary">ADDITIONAL INFORMATION</strong>
                    </div>
                    <hr className="less-margin" />

                    {/* Date of Receiving Vehicle */}
                    <div className="col-xl-12 justify-content-center mt-3">
                        <div className="row form-group form-group-sm align-items-center">
                            <div className="col-xl-4 col-sm-4 text-primary fw-bold">
                                Date Obtained*
                            </div>
                            <div className="col-xl-8 col-sm-8">
                                <DatePick
                                    dateSubmit={vehicleAddContext.newVehicle?.dateObtained ? vehicleAddContext.newVehicle?.dateObtained : ""}
                                    setDateSubmit={setDateObtained}
                                    storeValue={vehicleAddContext.saveAddition}
                                    nameValue={"dateObtained"}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Vehicle Owned/Leased/Financed */}
                    <div className="row form-group form-group-sm align-items-center my-3">
                        <div className="col-6 text-primary fw-bold">
                            Is this vehicle owned, leased, or financed?*
                        </div>
                        <div className="col-sm-8 col-md-6">
                            <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option mt-1"
                                value={vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced}
                                onChange={(e) => vehicleAddContext.saveAddition("vehicleOwnedLeasedFinanced", e.target.value)}
                            >
                                {PossessionVehicleDropdown()}
                            </select>
                        </div>
                    </div>

                    {/* Vehicle Co Owned */}
                    {vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced !== "0" && vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced == "24470" &&
                        <VehicleAddMI_Owned vehicleAddContext={vehicleAddContext} context={context} />
                    }

                    {/* Vehicle Leased */}
                    {vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced !== "0" && vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced == "24570" &&
                        <VehicleAddMI_Leased vehicleAddContext={vehicleAddContext} context={context} />
                    }

                    {/* Vehicle Financed */}
                    {vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced !== "0" && vehicleAddContext?.newVehicle?.vehicleOwnedLeasedFinanced == "24670" &&
                        <VehicleAddMI_Financed vehicleAddContext={vehicleAddContext} context={context} />
                    }
                </>
            }
        </>
    )
}

export default VehicleAddMI_3