import { useNavigate } from "react-router-dom"
import { saveRenewalQuestionnaire } from "../../services/Api"
import "../../css/HeaderRQ.css"
import { storageData } from "../../services/Services"
import RQ_ApplicationClass from "../../screens/RenewalQuestionnaire/RQ_ApplicationClass"

const SaveHelpContainer = () => {
    const navigate = useNavigate()

    const saveForLater = async () => {
        // Retrieves sessionData
        var storedSessionDataRQ = storageData.getItem('sessionDataRQ')
        if (storedSessionDataRQ) {
            storedSessionDataRQ = JSON.parse(storedSessionDataRQ)
        }
        // Retrieves the class there isn't any data
        else {
            storedSessionDataRQ = RQ_ApplicationClass
        }

        // Object to save
        var saveObjRQ = {
            "renewalQuestionnaireID": storageData.getItem("renewalQuestionnaireID"),
            "data": JSON.stringify(storedSessionDataRQ),
            "pageSavedOn": window.location.pathname
        }
        var response = await saveRenewalQuestionnaire(saveObjRQ)

        // Clears room for local storage
        if (response == "Successful save") {
            storageData.removeItem("effectiveDate");
            storageData.removeItem("currResidence");
            storageData.removeItem("vehicleInfo");
            storageData.removeItem("PendingAddress");
            storageData.removeItem("PendingDrivers");
            storageData.removeItem("PendingRemovedDrivers");
            storageData.removeItem("PendingAddedVehicles");
            storageData.removeItem("PendingRemovedVehicles");
            storageData.removeItem("renewalQuestionnaireID");
            storageData.removeItem("sessionDataRQ");
        }
        navigate("/home")
    }

    return (
        <>
            <div className="container col-12 col-lg-4 saveBox">
                <div className="col-12">
                    <div className="sidebar-block" >
                        <h5>Out of Time?</h5>

                        <div className="sidebar-block-inn">
                            <div className="help-box">
                                <p className="my-3">
                                    You can save your progress and return at a later time. You can access the questionnaire again by logging into My Account.
                                </p>
                                <div className="my-3" style={{ display: 'grid', placeItems: 'center' }}>
                                    <input type="submit" value={'Save For Later'} className="btn-blue" onClick={saveForLater} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="sidebar-block">
                        <h5>Need Help?</h5>

                        <div className="sidebar-block-inn">
                            <div className="help-box">
                                <span style={{ fontSize: '20px' }}>
                                    <a className="call fw-bold" href="tel:8005352873">1(800)535-2873</a>
                                </span>
                                <p>
                                    Monday-Friday 8:00am - 7:00pm
                                    <br />
                                    Saturday 9:30am - 4:00pm
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaveHelpContainer