import React, { useEffect, useState } from "react";
import { storageData } from "../../services/Services";
import DatePick from "../DatePicker";

const MichiganLicenseQuest = (props) => {
    const [user, setUser] = useState(props.user);
    const [dateLicenseSelected, setDateLicenseSelected] = useState("");

    useEffect(() => {
        var retrievedMichiganData = storageData.getItem("drivers")
        if (retrievedMichiganData) {
            retrievedMichiganData = JSON.parse(retrievedMichiganData)
        }

        // Michigan default
        if (props.michiganLicenseState === "") {
            props.handleCallBack("michiganLicenseState", "MI")
        }

        // If Editing Added Driver
        if (props.michiganLicenseDate !== "") {
            setDateLicenseSelected(props.michiganLicenseDate)
        }
    }, [])

    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                {/* State Licensed */}
                <div className="col-sm-12">
                    <hr className="less-margin" />
                </div>
                <div className="col-xl-8 text-blue fw-bold">
                    In what state is this {user} licensed?
                </div>
                <div className="col-xl-4">
                    <div className="btn-group bootstrap-select custom-selector">
                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize" value={props.michiganLicenseState}
                            onChange={(e) => { props.handleCallBack("michiganLicenseState", e.target.value) }}>
                            <option key={"1"} value={"MI"}>
                                Michigan
                            </option>
                            <option key={"2"} value={"NJ"}>
                                New Jersey
                            </option>
                            <option key={"3"} value={"PA"}>
                                Pennsylvania
                            </option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-12">
                    <hr className="less-margin" />
                </div>
                {/* License Number */}
                <div className="col-xl-12 justify-content-center mb-2 mt-3">
                    <div className="row form-group form-group-sm align-items-center">
                        <div className="col-xl-4 text-blue fw-bold">
                            License Number:
                        </div>
                        <div className="col-xl-8">
                            <input type={"text"} className="form-control" name="LicenseNumber" maxLength={15}
                                value={props.michiganLicenseNum}
                                onChange={(e) => { props.handleCallBack("michiganLicenseNum", e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <hr className="less-margin" />
                </div>
                {/* Date of License */}
                <div className="col-xl-12 justify-content-center my-2">
                    <div className="row form-group form-group-sm align-items-center">
                        <div className="col-xl-4 text-blue fw-bold">
                            Date of License:
                        </div>
                        <div className="col-xl-8 mt-2 text-center">
                            <DatePick
                                dateSubmit={dateLicenseSelected}
                                setDateSubmit={setDateLicenseSelected}
                                storeValue={props.handleCallBack}
                                nameValue={"michiganLicenseDate"}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <hr className="less-margin" />
                </div>
                {/* Why driver is added */}
                <div className="col-xl-12 justify-content-center mt-3">
                    <div className="row form-group form-group-sm align-items-center">
                        <div className="col-xl-4 text-blue fw-bold">
                            Why is this driver being added?
                        </div>
                        <div className="col-xl-8">
                            <input type={"text"} className="form-control" name="DriverAddedPurpose" value={props.michiganDriverAddedPurpose}
                                onChange={(e) => { props.handleCallBack("michiganDriverAddedPurpose", e.target.value) }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MichiganLicenseQuest