import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import AddressProgressBarSect from "../../components/ChangeRequest_AddressChangeComp/AddressProgressBarSect";
import RoundyContainer from "../../components/RoundyContainer";
import { AddressChangeContextComp } from "../../components/ChangeRequest_AddressChangeComp/AddressChangeContext";
import NewAddressMain from "components/ChangeRequest_AddressChangeComp/1_NewAddress/NewAddressMain";

const AddressChange = (props) => {
    const { userData } = props.user
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <AddressChangeContextComp>
                <Menu Title={"ADDRESS CHANGE"} FirstName={userData.firstName} userData={userData} />
                <AddressProgressBarSect screen={'New Address'} />
                <RoundyContainer Content={NewAddressMain} NoRoundy
                    params={{
                        storedData: { ...props.storedData },
                        setStoredData: props.setStoredData
                    }}
                />
            </AddressChangeContextComp>

        </>
    );
};

export default AddressChange;