import React from "react";
import { states } from "../../";
import { useState, useEffect } from "react";
import DatePick from "../../../DatePicker";
import { storageData } from "../../../../services/Services";

const SameHousehold = (props) => {
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)); //next day
    const minDate = new Date(new Date().setDate(new Date().getDate() - 30)); //30 days in past
    const maxDate = new Date(new Date().setDate(new Date().getDate() + 30)); //30 days in future

    const [vehicleInfo, setVehicleInfo] = useState();
    const [dateMoveInSelected, setDateMoveInSelected] = useState(tomorrow)
    useEffect(() => {
        if (props.dateMoveIn !== "") {
            setDateMoveInSelected(props.dateMoveIn)
        }
        if (storageData.getItem("vehicleInfo")) {
            setVehicleInfo(JSON.parse(storageData.getItem("vehicleInfo")))
        }
    }, [])

    useEffect(() => {
        if (!!vehicleInfo) {
            resetVehiclesAtNewAddress()
        }
    }, [vehicleInfo])

    const resetVehiclesAtNewAddress = () => {
        props.handleCallBack("vehiclesAtNewAddress", vehicleInfo.map(v => ({ VIN: v.VIN, selected: false })))
    }

    return (
        <>
            {/* Same Household */}
            <div className="row form-group form-group-sm">
                <div style={{ width: "unset" }} className="col-xxl-8 text-blue inputHeight fw-bold">
                    Does this driver reside in the same household?*
                </div>
                <div style={{ width: "unset" }} className="col-xxl-4 mt-1" onChange={(e) => { props.handleCallBack("sameHH", parseInt(e.target.value)) }}>
                    <div className="radio-inline form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={1}
                                defaultChecked={props.sameHH === 1} />Yes
                        </label>
                    </div>
                    <div className="radio-inline form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={0}
                                defaultChecked={props.sameHH === 0} />No
                        </label>
                    </div>
                </div>
            </div>

            {/* Same Household Toggle */}
            {props.sameHH === 1 &&
                <div className="slide-toggle">
                    <div className="row form-group form-group-sm justify-content-center">
                        <div>
                            <div className="form-group form-group-sm">
                                <strong className="text-blue">
                                    When did this driver move in?*
                                </strong>
                            </div>
                            <div style={{ width: "50%" }} className="form-group form-group-sm">
                                <DatePick
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dateSubmit={dateMoveInSelected}
                                    setDateSubmit={setDateMoveInSelected}
                                    storeValue={props.handleCallBack}
                                    nameValue={"dateMoveIn"}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            }
            {
                props.sameHH === 0 &&
                <div className="slide-toggle">
                    <div className="row form-group form-group-sm justify-content-center">
                        <div className="col-xl-10">
                            <div className="form-group form-group-sm">
                                <strong className="text-blue">
                                    Please provide the address where this driver resides.*
                                </strong>
                            </div>
                            <div className="form-group form-group-sm">
                                <input type={"text"} className="form-control" placeholder="Address" name="OtherAddress" maxLength={200}
                                    value={props.address}
                                    onChange={(e) => { props.handleCallBack("address", e.target.value) }} />
                            </div>
                            <div className="row form-group form-group-sm">
                                <div className="col-xl-5 my-1">
                                    <input type={"text"} className="form-control" placeholder="City" name="OtherCity" maxLength={100}
                                        value={props.city}
                                        onChange={(e) => { props.handleCallBack("city", e.target.value) }} />
                                </div>
                                <div className="col-xl-4">
                                    <div className="btn-group bootstrap-select custom-selector">
                                        <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                            defaultValue={props.state}
                                            onChange={(e) => { props.handleCallBack("state", e.target.value) }}>
                                            {states.states.map(item => (
                                                <option key={item.id} value={item.state}>
                                                    {item.state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-3 my-1">
                                    <input type={"text"} className="form-control" placeholder="Zip" name="OtherZip" maxLength={15}
                                        value={props.zip}
                                        onChange={(e) => { props.handleCallBack("zip", e.target.value) }} />
                                </div>
                            </div>
                            <div className="form-group form-group-sm">
                                <strong className="text-blue">
                                    Will any of the vehicles on this policy be kept at this address?*
                                </strong>
                            </div>
                            <div className="form-group form-group-sm" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                                {!!vehicleInfo && vehicleInfo.map((v, idx) => <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                    <input
                                        type="checkbox"
                                        value={v.VIN}
                                        checked={props.vehiclesAtNewAddress.find(p => p.VIN === v.VIN).selected === true}
                                        onChange={(e) => props.handleCallBack("vehiclesAtNewAddress", [...props.vehiclesAtNewAddress.filter(p => p.VIN !== v.VIN), { VIN: v.VIN, selected: e.target.checked }])}
                                    /><span style={{ fontSize: "13px", display: "inline" }}>{`${v.Year} ${v.Make} ${v.Model}`}</span>
                                </div>)}
                                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                    <input
                                        type="checkbox"
                                        value={""}
                                        checked={props.vehiclesAtNewAddress.every(p => p.selected === false)}
                                        onChange={resetVehiclesAtNewAddress}
                                    /><span style={{ fontSize: "13px", display: "inline" }}>None of the above</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SameHousehold