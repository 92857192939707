import { useContext, useEffect, useState } from "react"
import RemovalReasonDropdown from "../VehicleDropdowns/RemovalReasonDropdown"
import { VehicleChangeContext } from "../VehicleChangeContext"
import DatePick from "../../DatePicker"
import DatePick_MI from "../../DatePicker_MI"
import { StoredAddedVehicles } from "./VehiclesList"
import StateDropdown from "../../RenewalQuestionnaireComp/DriversOHM/StateDropdown"

const VehicleRemove = ({ allVehicles, id, saveEditOption, displayVehicle, type }) => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    var storedVehicles = context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles
    var currentResidence = context.currentResidence
    const [requestedEffDt, setRequestedEffDt] = useState(storedAddedContext.getTodaysDate(true))

    useEffect(() => {
        // Saves requested date for new vehicle being removed
        if (!("requestedEffDt" in storedVehicles[id])) {
            saveRemoval("requestedEffDt", requestedEffDt)
        }
    }, [])

    // Saves values for removing vehicle
    const saveRemoval = (key, value) => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehiclesStoredCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles
        vehiclesStoredCopyList[id][key] = value

        // Sets address values for different mailing address
        if (key == "currentResidenceResponse" && value == "0") {
            vehiclesStoredCopyList[id]["currentResidenceAddress"] = ""
            vehiclesStoredCopyList[id]["currentResidenceCity"] = ""
            vehiclesStoredCopyList[id]["currentResidenceState"] = "MI"
            vehiclesStoredCopyList[id]["currentResidenceZip"] = ""
        }
        // Sets address values to current residence
        else if (key == "currentResidenceResponse" && value == "1") {
            vehiclesStoredCopyList[id]["currentResidenceAddress"] = currentResidence?.address1
            vehiclesStoredCopyList[id]["currentResidenceCity"] = currentResidence?.city
            vehiclesStoredCopyList[id]["currentResidenceState"] = currentResidence?.state
            vehiclesStoredCopyList[id]["currentResidenceZip"] = currentResidence?.zip
        }

        vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesStoredCopyList
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
    }

    // Returns current address values for vehicle
    const returnValues = (value, id) => {
        var obj = {
            "currentResidenceAddress": storedVehicles[id]?.currentResidenceAddress,
            "currentResidenceCity": storedVehicles[id]?.currentResidenceCity,
            "currentResidenceState": storedVehicles[id]?.currentResidenceState,
            "currentResidenceZip": storedVehicles[id]?.currentResidenceZip,
        }
        return obj[value]
    }

    return (
        <>
            <div>
                {/* Removal Reasoning Dropdown */}
                <div className="form-group text-primary my-3">
                    <p className="fw-bold">
                        What is the primary reason for removing this vehicle?*
                    </p>
                </div>
                <div className="btn-group bootstrap-select custom-selector">
                    <select className="btn btn-default btn-group dropdown-toggle filter-option"
                        value={storedVehicles[id]?.vehicleRemovalReason != null ? storedVehicles[id].vehicleRemovalReason : ""}
                        onChange={(e) => { saveRemoval("vehicleRemovalReason", e.target.value) }}
                    >
                        {RemovalReasonDropdown({ list: allVehicles, currentVehicle: storedVehicles[id], state: context.userData.state })}
                    </select>
                </div>

                {/* "Other" selected as the answer (New Jersey & Pennsylvania ONLY) */}
                {context.userData?.state != "Michigan" && "vehicleRemovalReason" in storedVehicles[id] && storedVehicles[id]?.vehicleRemovalReason == "Other" &&
                    <div className="mt-2">
                        <input className="form-control" placeholder="Please explain..."
                            value={storedVehicles[id]?.vehicleRemovalExplanation != null ? storedVehicles[id]?.vehicleRemovalExplanation : ""}
                            onChange={(e) => { saveRemoval("vehicleRemovalExplanation", e.target.value) }}
                        />
                    </div>
                }

                {/* Explanation text box */}
                {context.userData.state == "Michigan" &&
                    <>
                        <div className="form-group text-primary my-3">
                            <p className="fw-bold">
                                Please explain...
                            </p>
                        </div>
                        <div className="mt-2">
                            <textarea className="form-control" placeholder="Please explain..." style={{ resize: 'none', height: '100px' }}
                                value={storedVehicles[id]?.vehicleRemovalExplanation != null ? storedVehicles[id]?.vehicleRemovalExplanation : ""}
                                onChange={(e) => { saveRemoval("vehicleRemovalExplanation", e.target.value) }}
                            />
                        </div>
                    </>
                }

                {/* Mailing Address for Cancelled Selected */}
                {storedVehicles[id]?.vehicleRemovalReason == "CancelPolicy" &&
                    <>
                        <div className="form-group text-primary my-3">
                            <p className="fw-bold">
                                Mailing Address On File:
                            </p>
                            <p className="text-primary col-sm-6 m-0">{currentResidence?.address1}</p>
                            <p className="text-primary col-sm-6 m-0">{currentResidence?.city}, {currentResidence?.state} {currentResidence?.zip}</p>
                        </div>

                        {/* Mailing address yes/no response */}
                        <div className="form-group text-primary my-3">
                            <p className="fw-bold">
                                Is the address above your current mailing address?*
                            </p>
                        </div>
                        <div className="form-group form-group-sm text-dark mb-0">
                            <div className="radio-inline form-check form-check-inline">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="collision" value={"1"}
                                        checked={(storedVehicles[id]?.currentResidenceResponse === "1") ? storedVehicles[id]?.currentResidenceResponse : ""}
                                        onChange={(e) => { saveRemoval("currentResidenceResponse", e.target.value) }}
                                    />Yes
                                </label>
                            </div>
                            <div className="radio-inline form-check form-check-inline">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="collision" value={"0"}
                                        checked={(storedVehicles[id]?.currentResidenceResponse === "0") ? storedVehicles[id]?.currentResidenceResponse : ""}
                                        onChange={(e) => { saveRemoval("currentResidenceResponse", e.target.value) }}
                                    />No
                                </label>
                            </div>
                        </div>

                        {/* New Mailing Address if selected NO */}
                        {storedVehicles[id]?.currentResidenceResponse === "0" &&
                            <>
                                <div className="form-group text-primary my-3">
                                    <p className="fw-bold">
                                        New Mailing Address:
                                    </p>
                                </div>

                                {/* Current Residence of Address */}
                                {["currentResidenceAddress", "currentResidenceCity", "currentResidenceState", "currentResidenceZip"].map((value, residenceId) => {
                                    return (
                                        <div className="col-xl-12 justify-content-center mt-3" key={residenceId}>
                                            <div className="row form-group form-group-sm align-items-center">
                                                <div className="col-xl-4 col-sm-4 text-blue fw-bold">
                                                    {value.replace("currentResidence", "")} *
                                                </div>
                                                <div className="col-xl-8 col-sm-8">
                                                    {value === "currentResidenceState" ? // State Dropdown
                                                        <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                            value={storedVehicles[id]?.currentResidenceState ? storedVehicles[id]?.currentResidenceState : "MI"}
                                                            onChange={(e) => { saveRemoval("currentResidenceState", e.target.value) }}
                                                        >
                                                            {StateDropdown()}
                                                        </select>
                                                        :
                                                        <input style={{ textAlign: "left" }} className="col-sm-12 col-12 btn btn-default btn-group mt-1"
                                                            value={returnValues(value, id)} onChange={(e) => { saveRemoval(value, e.target.value) }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </>
                }

                {/* Requested Effective Date for only NJ/PA */}
                {(context.userData.state == "New Jersey" || context.userData.state == "Pennsylvania") &&
                    <>
                        <div className="form-group text-primary mt-3">
                            <p className="fw-bold">
                                Requested Effective Date *
                            </p>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-6">
                            <DatePick
                                dateSubmit={(storedVehicles[id]?.requestedEffDt) ? storedVehicles[id]?.requestedEffDt : requestedEffDt}
                                setDateSubmit={setRequestedEffDt}
                                storeValue={saveRemoval}
                                nameValue={"requestedEffDt"}
                            />
                        </div>
                    </>
                }

                {/* New Jersey/Pennsylvania */}
                {context.userData.state !== "Michigan" &&
                    <p className="text-muted fst-italic mt-3" style={{ fontSize: "12px" }}>
                        NOTE: {context.userData.state} Law requires that you have insurance coverage on each of your automobiles as long as it is registered or principally garaged in {context.userData.state}
                    </p>
                }
                {/* Michigan */}
                {context.userData.state == "Michigan" && type != "replace" && type != "replaceEdit" &&
                    <div className="col-xl-12 justify-content-center my-3">
                        <div className="row form-group form-group-sm">
                            <p className="col-xl-5 col-sm-5 text-primary fw-bold d-flex">
                                Requested Effective Date *
                            </p>
                            <div className="col-xl-7 col-sm-7">
                                <DatePick_MI
                                    dateSubmit={(storedVehicles[id]?.requestedEffDt) ? storedVehicles[id]?.requestedEffDt : requestedEffDt}
                                    setDateSubmit={setRequestedEffDt}
                                    storeValue={saveRemoval}
                                    nameValue={"requestedEffDt"}
                                />
                            </div>
                        </div>
                    </div>
                }

                {/* Save/Cancel button for menus */}
                {displayVehicle[id] && type != "replace" && type != "replaceEdit" &&
                    <div className={`text-right ${!context.showQuestions ? "mb-3" : ""}`}>
                        <input type={"button"} className="btn btn-default" value={"CANCEL"} onClick={() => saveEditOption("cancel", id, "remove")} />
                        <input type={"button"} className="btn btn-primary-grad ms-1" value={"SAVE"} onClick={() => saveEditOption("save", id, "remove")} />
                    </div>
                }
            </div>
        </>
    )
}

export default VehicleRemove