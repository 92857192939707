import Images from "../assets/images/Images";

const HappyBirthday = () => {

    return (
        <>
            <div className="ms-1 mb-2">
                <img src={Images.happyBirthday} title="Happy Birthday from Cure!" alt="Happy Birthday from Cure!" ></img>
            </div>
        </>
    )
}

export default HappyBirthday