import React from 'react'
import EndorsementInfoNeeded from './4_BlockPage/EndorsementInfoNeeded'
import EndorsementReturnHome from './4_BlockPage/EndorsementReturnHome'

const BlockMain = () => {
  return (
    <>
      <EndorsementInfoNeeded />
      <hr />
      <EndorsementReturnHome />
    </>
  )
}

export default BlockMain