import React, { useContext } from 'react'
import "./UpdateBody.css"
import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import { storageData } from 'services/Services'

const UpdateBody = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    var response = storageData.getItem("responseData")
    response = JSON.parse(response)

    const downloadPdf = (pdfBytes, fileName) => {
        const pdf = `data:application/octet-stream;base64,${pdfBytes}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    return (
        <div className='container'>
            {/* Requested Effective Date */}
            <div className='row justify-content-center mb-3'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        Change Requested Effective Date:
                        <span className='ms-1 text-primary'>
                            {response?.effectiveDate}
                        </span>
                    </p>
                </div>
            </div>

            {/* Vehicle */}
            <div className='row justify-content-center my-3'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        The following change(s) has been processed:
                    </p>
                    <span className='ms-1 text-primary fw-bold'>
                        {`${response?.vehicle?.VehicleYear} ${response?.vehicle?.VehicleMake} ${response?.vehicle?.VehicleModel} ${response?.vehicle?.VehicleVIN} - New Vehicle`}
                    </span>
                </div>
            </div>

            {/* Updated total 6-month term cost */}
            {/* <div className='row justify-content-center my-3'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        Updated total 6-month term cost:
                    </p>
                    <span className='ms-1 text-primary fw-bold'>
                        ${response?.new6MonthCost}
                    </span>
                </div>
            </div> */}

            {/* Difference from previous 6-month cost */}
            {/* <div className='row justify-content-center my-3'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        Difference from previous 6-month cost:
                    </p>
                    <span className='ms-1 text-primary fw-bold'>
                        ${response?.diff6MonthCost}
                    </span>
                </div>
            </div> */}

            {/* Download ID Card and Dec Page */}
            <div className='row justify-content-center my-3'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        Download your new ID card(s) and declarations page below.
                    </p>
                    <span className='ms-1 text-primary fw-bold text-decoration-underline'>
                        <a onClick={() => downloadPdf(response?.idCardPdfBytes, `${addVehicleContext.userData?.policyNumber} - ID Card.pdf`)}>
                            ID Card
                        </a>
                    </span>
                    <br />
                    <span className='ms-1 text-primary fw-bold text-decoration-underline'>
                        <a onClick={() => downloadPdf(response?.declarationsPagePdfBytes, `${addVehicleContext.userData?.policyNumber} - Declarations Page.pdf`)}>
                            Declarations Page
                        </a>
                    </span>
                </div>
            </div>

            <div className='row justify-content-center my-4'>
                <div className='col-12 text-center'>
                    <p className='fw-bold m-0'>
                        You’ll receive an email in the next 1 to 2 hours with a confirmation of your policy update and a breakdown of your new 6-month term cost.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default UpdateBody