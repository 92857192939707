import React, { useState } from "react";
import DatePick_MI from "components/DatePicker_MI";
import { Col } from "react-bootstrap";

const FormDateTextMask = (props) => {
    const [dateMoved, setDateMoved] = useState("")
    const {
        id,
        value,
        label,
        callback,
        noMinMax,
        col = {
            label: {
                xs: 4,
            },
            input: {
                xs: 6
            }
        },
        className = {
            label: "",
            input: ""
        }
    } = props

    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                <Col {...col.label}>
                    <div className={className.label}>
                        <strong className="text-primary">{label}</strong>
                    </div>
                </Col>
                <Col {...col.input}>
                    <div className={className.input}>
                        <DatePick_MI
                            dateSubmit={value}
                            nameValue={id}
                            setDateSubmit={setDateMoved}
                            storeValue={callback}
                            noMinMax={noMinMax}
                        />
                    </div>
                </Col>
            </div>
        </>
    )
}

export default FormDateTextMask