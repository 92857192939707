import React, { useState, useEffect } from "react"
import { storageData } from "services/Services"
import { useLocation } from "react-router-dom";

export const AddVehicleContext = React.createContext()

const getTodaysDate = (addOne) => {
    // If adding a day --> seconds * minutes * hours * milliseconds
    var today = (addOne) ? new Date(new Date().getTime() + (60 * 60 * 24 * 1000)) : new Date()
    var yyyy = today.getFullYear()
    var mm = today.getMonth() + 1
    var dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    return mm + '/' + dd + '/' + yyyy
}

const getStateAbbr = (state) => {
    const statesMap = {
        "Michigan": "MI",
        "New Jersey": "NJ",
        "Pennsylvania": "PA"
    }

    return statesMap[state] || "";
}

export const AddVehicleContextComp = ({ children }) => {
    const location = useLocation();
    const [addVehicleClass, setAddVehicleClass] = useState(JSON.parse(storageData.getItem("AddVehicleClass")))
    const [userData, setUserData] = useState(JSON.parse(storageData.getItem("userData")))
    const [submitEndorsementResponse, setSubmitEndorsementResponse] = useState()

    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [showModal, setShowModal] = useState(false)
    const setValidationMsgToggle = (title, value) => {
        setValidationTitle(title);
        setValidationMsg(value);
        setShowModal(true)
    }

    useEffect(() => {
        // console.log("submitEndorsementResponse", submitEndorsementResponse)
    }, [submitEndorsementResponse])

    const [vinLoad, setVinLoad] = useState(false)
    const [submissionLoad, setSubmissionLoad] = useState(false)

    return (
        <AddVehicleContext.Provider
            value={{
                addVehicleClass, setAddVehicleClass,
                userData, setUserData,
                validationTitle, setValidationTitle,
                validationMsg, setValidationMsg,
                showModal, setShowModal,
                submitEndorsementResponse, setSubmitEndorsementResponse,
                setValidationMsgToggle,
                vinLoad, setVinLoad,
                submissionLoad, setSubmissionLoad,
                getTodaysDate,
                getStateAbbr
            }}>
            {children}
        </AddVehicleContext.Provider>
    )
}
