const dlStatus = [
    "Select from the following...",
    "No License or Identification Card",
    "Valid Other State License",
    "Valid Foreign License",
    "International License",
    "Suspended",
    "Revoked",
    "Permit",
    "Expired",
    "Identification Only",
]

export default dlStatus