import axios from "axios";
import {
  addressChangeEndpoint,
  driverInfoEndpoint,
  policyChangeEndpoint,
  portalOne,
  vehicleChangeEndpoint,
  websiteMaintenanceEndpoint
} from "./apiConfig";
import { loginEndpoint, idCardEndpoint, paymentEndpoint, renewalQuestionnaireEndpoint } from "./apiConfig";
import { getSiteEnvironment, domainName, getDomain, getSiteEnv } from "./Utils";
import { storageData } from "./Services";

const Env = "uat";

const headers = {
  "Content-Type": "application/json",
  "Authorization" : ""
};

const GW_DOMAIN_UAT = "cure-uat.mu-1-us-west-2";
const GW_DOMAIN_PROD = "cure-prod.xi-1-us-west-2";
const GW_DOMAIN_MODEL = "cure-model.mu-1-us-west-2";
headers.Authorization = "Bearer " + storageData.getItem("token");
// #region Service Portal

export const getVehicles = async (policyNumber, env) => {
  const { method, url } = driverInfoEndpoint.getVehicles;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
  } catch (e) {
    console.log(e);
  }
  return apiResponse;
};


export const getEffectiveDate = async (policyNumber) => {
  const { method, url } = driverInfoEndpoint.getEffectiveDate;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
  } catch (e) {
    console.log(e);
  }
  return apiResponse;
};



export const pushVehicle = async (UserState, RequestType, vehicles, platform) => {
  const { method, url } = addressChangeEndpoint.pushVehicle;
  console.log("HI", vehicles)

  const config = {
    method,
    url,
    headers,
    data: {
      UserState,
      RequestType,
      vehicles,
      platform
    },
  };
  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e.errorCode)
  }
  return apiResponse
}

export const getDrivers = async (SystemId, policyNumber, env) => {
  const { method, url } = driverInfoEndpoint.getDrivers

  const config = {
    method,
    url,
    headers,
    data: {
      SystemId,
      policyNumber,
      env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e.errorCode)
  }
  return apiResponse
}



export const getAddress = async (systemId, policyNumber, env) => {
  const { method, url } = driverInfoEndpoint.getAddress

  const config = {
    method,
    url,
    headers,
    data: {
      systemId,
      policyNumber,
      env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getDropdownOptions = async () => {
  const { method, url } = vehicleChangeEndpoint.getDropdownOptions;

  const config = {
    method,
    url,
    headers,
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetDropdownOptions", apiResponse);
  } catch (e) {
    console.error("GetDropdownOptions", e);
  }
  return apiResponse;
};

export const vinLookup = async (vin) => {
  const { method, url } = vehicleChangeEndpoint.vinLookup

  const config = {
    method,
    url,
    headers,
    data: {
      vin
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getLeasingList = async () => {
  const { method, url } = vehicleChangeEndpoint.getLeasingList

  const config = {
    method,
    url,
    headers
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getFinanceCompanyList = async () => {
  const { method, url } = vehicleChangeEndpoint.getFinanceCompanyList

  const config = {
    method,
    url,
    headers
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getFinanceLeasedCompaniesGWList = async () => {
  const { method, url } = vehicleChangeEndpoint.getFinanceLeasedCompaniesGWList

  const config = {
    method,
    url,
    headers
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getPendingPolicyChanges = async (PolicyNumber) => {
  const { method, url } = addressChangeEndpoint.getPendingPolicyChanges

  const config = {
    method,
    url,
    headers,
    data: {
      PolicyNumber
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const pushMain = async (fields) => {
  const { method, url } = addressChangeEndpoint.pushMain

  const config = {
    method,
    url,
    headers,
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}


export const pushPerson = async (UserState, requestType, fields, platform) => {
  const { method, url } = addressChangeEndpoint.pushPerson

  const config = {
    method,
    url,
    headers,
    data: {
      UserState: UserState,
      RequestType: requestType,
      people: fields,
      platform: platform
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const submitChangeRequest = async (fields) => {
  const { method, url } = policyChangeEndpoint.submitChangeRequest

  const config = {
    method,
    url,
    headers,
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const checkCodes = async (fields) => {
  const { method, url } = policyChangeEndpoint.checkCodes

  const config = {
    method,
    url,
    headers,
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const twilioRegistration = async (user) => {
  const { method, url } = policyChangeEndpoint.twilioRegistration

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
    console.log("TwilioRegistration", apiResponse)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getUseGenesysWebMessaging = async (Env) => {
  const { method, url } = paymentEndpoint.getUseGenesysWebMessaging
  const config = {
    method,
    url: url + "?Env=" + Env,
    headers,
  }
  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}
//#endregion

// #region my-account
export const validateAddress = async (fields) => {
  const { method, url } = driverInfoEndpoint.validateAddress

  const config = {
    method,
    url,
    headers,
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse;
};
// #endregion

// #region Login
export const verifyLoginTkn = async (tkn, userAgent = "") => {
  const { method, url } = loginEndpoint.verify
  const data = {
    tkn: tkn,
    ua: userAgent
  }
  headers.Authorization = "Bearer " + tkn;
  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {    
    apiResponse = await axios(config)
    console.log("VerifyLogin", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const verifyLogin = async (username, password, userAgent) => {
  const { method, url } = loginEndpoint.verifyUser

  const data = {
    un: username,
    pw: password,
    ua: userAgent
  }

  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("VerifyLogin", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const verifyLoginQS = async (phone_ssn, zipcode) => {
  const { method, url } = loginEndpoint.verifyQS

  const config = {
    method,
    url,
    headers,
    data: {
      phone_ssn,
      zipcode
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("VerifyLoginQS", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const externalLogin = async (token) => {
  const { method, url } = loginEndpoint.externalLogin
  headers.Authorization = "Bearer " + token;
  const config = {
    method,
    url,
    headers,
    data: {
      token
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    headers.Authorization = "Bearer " + apiResponse.data.token;
    console.log("ExternalLogin", apiResponse)
  } catch (error) {
    console.log("ExternalLogin", error)
  }
  return apiResponse
}

export const AuthenticateODS = async (policyNumber) => {
  const { method, url } = loginEndpoint.authenticateODS;
  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
    },
  };

  let apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("AuthenticateODS", apiResponse);
  } catch (error) {
    console.log("AuthenticateODS", error);
    return false;
  }
  return apiResponse.data;
};

export const getSecurityQuestions = async () => {
  const { method, url } = loginEndpoint.getSecurityQuestions

  const config = {
    method,
    url,
    headers
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const registerAccount = async user => {
  const { method, url } = loginEndpoint.registerAccount
  // const data = JSON.stringify(user)
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const checkSecurityQuestions = async user => {
  const { method, url } = loginEndpoint.checkSecurityQuestions
  // const data = JSON.stringify(user)
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }
  //alert(JSON.stringify(user))

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log('apiResponse', apiResponse)
  } catch (error) {
    // console.log(error)
    console.log('error', error)
  }
  return apiResponse
}

export const changeSecurityQuestions = async user => {
  const { method, url } = loginEndpoint.changeSecurityQuestions
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const retrievePolicyUser = async (LastName, DOB, ZipCode) => {
  const { method, url } = loginEndpoint.retrievePolicyUser
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({
      LastName,
      DOB,
      ZipCode
    })
  }
  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log("Try catch >>>>>", error)
  }
  return apiResponse
}

export const resetPasswordWithTemp = async (user) => {
  const { method, url } = loginEndpoint.resetPassword
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const updateEmail = async (user) => {
  const { method, url } = loginEndpoint.updateEmail
  const config = {
    method,
    url,
    headers,
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const setPaperlessEmail = async (user) => {
  const { method, url } = loginEndpoint.setPaperlessEmail
  const config = {
    method,
    url,
    headers,
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updateUsername = async (user) => {
  const { method, url } = loginEndpoint.updateUsername
  const config = {
    method,
    url,
    headers,
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updatePassword = async (user) => {
  const { method, url } = loginEndpoint.updatePassword
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const registerForBilling = async (user) => {
  const { method, url, ...props } = paymentEndpoint.registerForBilling
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      ...user,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("RegisterForBilling", apiResponse);
  } catch (error) {
    console.error("RegisterForBilling", error);
    return { error };
  }
  return apiResponse.data;
};

export const loadByPolicyNumber = async ( Token) => {
  const { method, url, ...props } = loginEndpoint.loadByPolicyNumber
  // const Env = await getEnv()
  headers.Authorization = "Bearer " + Token;
  const config = {
    method,
    url,
    headers,
    data: {
      Token,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("LoadByPolicyNumber", apiResponse);
  } catch (error) {
    console.error("LoadByPolicyNumber", error);
    return { error };
  }
  return apiResponse;
};
//#endregion

// #region Payment

export const getPaymentInfo = async (policyNumber, systemID, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaymentInfo
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemID,
      env,
      ...props,
    },
  };
  var apiResponse;
  try {
    apiResponse = await axios(config);

    console.log("GetPaymentInfo", apiResponse);
  } catch (error) {
    console.error("GetPaymentInfo", error);
    return { error };
  }
  return apiResponse.data;
};

export const getLastPayment = async (policyNumber) => {
  const { method, url, ...props } = paymentEndpoint.getLastPayment;
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: { "{{PolicyNumber}}": policyNumber },
      Env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    apiResponse.data = JSON.parse(apiResponse.data);
    console.log("GetLastPayment", apiResponse);
  } catch (error) {
    console.error("GetLastPayment", error);
    return { error };
  }
  return apiResponse.data.result;
};

export const getPaymentHistory = async (policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaymentHistory

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetPaymentHistory", apiResponse);
  } catch (error) {
    console.error("GetPaymentHistory", error);
    return { error };
  }
  return apiResponse.data;
};

export const getPaperlessBillHistory = async (accountRef, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaperlessBillHistory;

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: {
        "{{AccountRef}}": accountRef,
        "{{SystemId}}": systemId,
      },
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    apiResponse.data = JSON.parse(apiResponse.data);
    console.log("GetPaymentHistory", apiResponse);
  } catch (error) {
    console.error("GetPaymentHistory", error);
    return { error };
  }
  return apiResponse.data.result;
};

export const getInstallmentBill = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getInstallmentBill;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetInstallmentBill", apiResponse);
  } catch (error) {
    console.error("GetInstallmentBill", error);
    return { error };
  }
  return apiResponse.data;
};

export const getPaymentPdf = async (systemId, id, env) => {
  const { method, url, endpoint, ...props } = paymentEndpoint.getPaymentPdf;

  const endpointUpdated = endpoint
    .replace("{{systemId}}", systemId)
    .replace("{{id}}", id)
    .replace("{{GW_DOMAIN}}", env === "uat" ? GW_DOMAIN_UAT : (env === "model" ? GW_DOMAIN_MODEL : GW_DOMAIN_PROD));

  const config = {
    method,
    url,
    headers,
    data: {
      endpoint: endpointUpdated,
      env,
      ...props,
    },
  };

  let apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("getPaymentPdf", apiResponse);
  } catch (error) {
    console.error("getPaymentPdf", error);
    return { error };
  }
  return apiResponse.data;
};

export const getAllPolicyDocs = async (systemId) => {
  const { method, url, ...props } = paymentEndpoint.getAllPolicyDocs;
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: { "{{systemId}}": systemId },
      Env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    apiResponse.data = JSON.parse(apiResponse.data)
    console.log("getAllPolicyDocs", apiResponse)
  } catch (error) {
    console.error("getAllPolicyDocs", error)
    return { error }
  }
  return apiResponse.data.result
}

export const GetArchive = async (policyNumber, systemId, accountRef, env) => {
  const { method, url, ...props } = paymentEndpoint.GetArchive;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      accountRef,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetArchive", apiResponse)
  } catch (error) {
    console.error("GetArchive", error)
    return { error }
  }
  return apiResponse.data
}
export const getDocuments = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getDocuments;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetDocuments", apiResponse)
  } catch (error) {
    console.error("GetDocuments", error)
    return { error }
  }
  return apiResponse.data
}

export const getDocument = async (endpoint, policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.getDocument;

  const config = {
    method,
    url,
    headers,
    data: {
      endpoint,
      policyNumber,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetDocument", apiResponse)
  } catch (error) {
    console.error("GetDocument", error)
    return { error }
  }
  return apiResponse.data
}

export const getAutoPayStatus = async (policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.GetAutoPayStatus;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetAutoPayStatus", apiResponse)
  } catch (error) {
    console.error("GetAutoPayStatus", error)
    return { error }
  }
  return apiResponse.data
}

export const updateOneIncTokens = async (PolicyNumber, Zip, Env, Types, Source) => {
  const { method, url, ...props } = paymentEndpoint.updateOneIncTokens;
  const config = {
    method,
    url,
    headers,
    data: {
      PolicyNumber,
      Zip,
      Env,
      Types,
      Source,
      ...props,
    },
  };
  
  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("UpdateOneIncTokens", apiResponse);
  } catch (error) {
    console.error("UpdateOneIncTokens", error);
    return { error };
  }
  return apiResponse.data;
};

//#endregion

// #region Id Card

export const getVehicleInfo = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = idCardEndpoint.getVehicleInfo;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetVehicleInfo", apiResponse);
  } catch (error) {
    console.error("GetVehicleInfo", error);
    return { error };
  }
  return apiResponse.data;
};

export const getRenewalQuestionnaires = async (policyNumber) => {
  const { method, url, ...props } = idCardEndpoint.getRenewalQuestionnaires;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetRenewalQuestionnaires", apiResponse);
  } catch (error) {
    console.error("GetRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const getRenewalQuestionnairePDF = async (req) => {
  const { method, url, ...props } = idCardEndpoint.getRenewalQuestionnairePDF;

  const config = {
    method,
    url,
    headers,
    data: {
      ...req,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetRenewalQuestionnaires", apiResponse);
  } catch (error) {
    console.error("GetRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const saveRenewalQuestionnaire = async (req) => {
  const { method, url, ...props } = renewalQuestionnaireEndpoint.saveRenewalQuestionnaire;

  const config = {
    method,
    url,
    headers,
    data: {
      ...req
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("SaveRenewalQuestionnaire", apiResponse);
  } catch (error) {
    console.error("SaveRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const submitRenewalQuestionnaire = async (req) => {
  const { method, url, ...props } = renewalQuestionnaireEndpoint.submitRenewalQuestionnaire;

  const config = {
    method,
    url,
    headers,
    data: {
      ...req,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("SubmitRenewalQuestionnaires", apiResponse);
  } catch (error) {
    console.error("SubmitRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const fetchRenewalQuestionnaire = async (req) => {
  const { method, url, ...props } = renewalQuestionnaireEndpoint.fetchRenewalQuestionnaire;

  const config = {
    method,
    url,
    headers,
    data: {
      ...req,
    },
  };

  var apiResponse;

  try {
    apiResponse = await axios(config);
  } catch (error) {
    return { error };
  }
  return apiResponse.data;
};

export const getPolicyDocumentsByState = async (state) => {
  const { method, url, ...props } = idCardEndpoint.getPolicyDocumentsByState;

  const config = {
    method,
    url,
    headers,
    data: {
      state,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetPolicyDocumentsByState", apiResponse);
  } catch (error) {
    console.error("GetPolicyDocumentsByState", error);
    return { error };
  }
  return apiResponse.data;
}

export const getPolicyDocumentsByDocID = async (docID) => {
  const { method, url, ...props } = idCardEndpoint.getPolicyDocumentsByDocID;

  const config = {
    method,
    url,
    headers,
    data: {
      docID,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetPolicyDocumentsByState", apiResponse);
  } catch (error) {
    console.error("GetPolicyDocumentsByState", error);
    return { error };
  }
  return apiResponse.data;
};

export const getIdCards = async (systemId, policyNumber, idList, env) => {
  const { method, url, ...props } = idCardEndpoint.getIdCard;
  const config = {
    method,
    url,
    headers,
    data: {
      systemId,
      policyNumber,
      idList,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    
    console.log("GetIdCard", apiResponse);
  } catch (error) {
    console.error("GetIdCard", error);
    return { error };
  }
  return apiResponse.data;
};

export const sendIdCardEmail = async (systemId, policyNumber, idList, emailAddress, bodyParams, env) => {
  const { method, url, ...props } = idCardEndpoint.sendIdCardEmail;

  const config = {
    method,
    url,
    headers,
    data: {
      systemId,
      emailAddress,
      policyNumber,
      idList,
      bodyParams,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("SendIdCardEmail", apiResponse);
  } catch (error) {
    console.error("SendIdCardEmail", error);
    return { error };
  }
  return apiResponse.data;
};
//#endregion

// #region Portal One

export const getCustomerId = async (ExternalCustomerId, CustomerName, env) => {
  const { method, url, ...props } = portalOne.getCustomerId;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      ExternalCustomerId,
      CustomerName,
      PortalOneAuthenticationKey: portalOne.portalOneAuthKey(env),
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetCustomerId", apiResponse);
  } catch (error) {
    console.error("GetCustomerId", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const getPaymentMethod = async (CustomerId, env) => {
  const { method, url, ...props } = portalOne.getPaymentMethod;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      CustomerId,
      PortalOneAuthenticationKey: portalOne.portalOneAuthKey(env),
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetPaymentMethod", apiResponse);
  } catch (error) {
    console.error("GetPaymentMethod", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const enrollAutoPay = async (req, env) => {
  const { method, url, ...props } = portalOne.enrollAutoPay;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      AuthenticationKey: portalOne.authKey(env),
      ...req,
      PortalOneAuthenticationKey: portalOne.portalOneAuthKey(env),
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("EnrollAutoPay", apiResponse);
  } catch (error) {
    console.error("EnrollAutoPay", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const checkAutoPay = async (TokenId, env) => {
  const { method, url, ...props } = portalOne.checkAutoPay;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      AuthenticationKey: portalOne.authKey(env),
      TokenId,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("CheckAutoPay", apiResponse);
  } catch (error) {
    console.error("CheckAutoPay", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const getActiveInstallmentPlan = async (PolicyRefNumber, env) => {
  const { method, url, ...props } = portalOne.getActiveInstallmentPlan;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      AuthenticationKey: portalOne.authKey(env),
      PolicyRefNumber,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetActiveInstallmentPlan", apiResponse);
  } catch (error) {
    console.error("GetActiveInstallmentPlan", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const checkPBTStatus = async (PolicyRefNumber, Env, token) => {
  const { method, url, ...props } = portalOne.checkPBTStatus;
  headers.Authorization = "Bearer " + token;
  const data = JSON.stringify({
    PortalOneAuthenticationKey: portalOne.portalOneAuthKey(Env),
    ExternalCustomerIdList: [PolicyRefNumber],
    Env,
    ...props,
  })

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("CheckPBTStatus", apiResponse);
  } catch (error) {
    console.error("CheckPBTStatus", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const downloadTempIDCard = async (changeId, requestType) => {
  const { method, url } = idCardEndpoint.getTempIDCard;

  var data = {
    changeId,
    requestType
  }

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
  } catch (error) {
    console.error("DownloadIDCard", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const emailTempIDCard = async (ChangeID, requestType, UserState, EmailAddress, FirstName) => {
  const { method, url } = idCardEndpoint.sendTempIDCard;

  var data = {
    ChangeID,
    requestType,
    UserState,
    EmailAddress,
    FirstName
  }

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("EmailTempIDCard", apiResponse);
  } catch (error) {
    console.error("EmailTempIDCard", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const GetEnvironmentSettings = async (host) => {
  const { method, url } = loginEndpoint.GetEnvironmentSettings;
  var data = {
    host
  }

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetEnvironmentSettings", apiResponse);
  } catch (error) {
    console.error("GetEnvironmentSettings", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const getWebsiteMaintenanceStatus = async (content) => {
  const { method, url } = websiteMaintenanceEndpoint.status
  var Environment = domainName(getDomain(window.location.hostname))

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  };

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebsiteMaintenanceMessage = async (content) => {
  const { method, url } = websiteMaintenanceEndpoint.message
  var Environment = domainName(getDomain(window.location.hostname))

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const submitEndorsement = async (fields) => {
  const { method, url } = policyChangeEndpoint.submitEndorsementRequest

  const config = {
    method,
    url,
    headers,
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}
// #endregion
