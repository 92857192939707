const AddMemberValidationMI = (props) => {
    if (props.infoFields.fullName === "") {
        props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
        props.handleShow()
        return false
    }
    else if (props.infoFields.fullName) {
        var containsNumerical = /\d/;
        if (containsNumerical.test(props.infoFields.fullName)) {
            props.handleValidationMsg("Name contains a numeric character. Please enter a valid Name.", "Oops!")
            props.handleShow()
            return false
        }
        var valid = /^(([a-zA-Z'-.]{2,})+ ([a-zA-Z'-.]+){2,})$/;
        if (!valid.test(props.infoFields.fullName)) {
            props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.monthSelect === "" || props.infoFields.daySelect === "" || props.infoFields.yearSelect === "") {
            props.handleValidationMsg("Please complete the household member's date of birth.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.mStatus === "") {
            props.handleValidationMsg("Please select the household member's Marital Status.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.gender === "") {
            props.handleValidationMsg("Please select the household member's Gender.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.moveDate === "") {
            props.handleValidationMsg("Please provide the date this household member moved in.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "") {
            props.handleValidationMsg("Please specify whether or not the household member has a valid driver's license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.ownInsurance === "" && props.infoFields.dLicense == "1") {
            props.handleValidationMsg("Please specify whether or not the household member maintains his or her own auto insurance.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "1" && props.infoFields.ownInsurance == "0" && !props.infoFields.licenseNum) {
            props.handleValidationMsg("Please enter this household member's license number.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "0" && props.infoFields.licenseStatus === "") {
            props.handleValidationMsg("Please specify the current status of the household member's license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "0" && props.infoFields.licenseStatus === "Valid Foreign License" && props.infoFields.licenseCountry == "") {
            props.handleValidationMsg("Please provide the country of the household member's license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "0" && props.infoFields.licenseStatus === "Valid Other State License" && props.infoFields.licenseOtherState == "") {
            props.handleValidationMsg("Please provide the other state of the household member's license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "0" && (props.infoFields.licenseStatus === "Valid Foreign License" || props.infoFields.licenseStatus === "Valid Other State License") 
            && props.infoFields.ownInsurance == "") {
            props.handleValidationMsg("Please specify whether or not the household member maintains his or her own auto insurance.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "1" && props.infoFields.ownInsurance === "0" && !props.infoFields.licenseAge) {
            props.handleValidationMsg("Please enter the age this household member was first licensed.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "1" && props.infoFields.ownInsurance === "0" && !props.infoFields.disorder) {
            props.handleValidationMsg("Please specify whether or not the household member has a disorder or condition that may impair their ability to safely operate a motor vehicle.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.toggleAuto && props.infoFields.dLicense == "1" && props.infoFields.ownInsurance === "0" && !props.infoFields.impairments) {
            props.handleValidationMsg("Please specify whether or not the household member has any mental or physical impairments.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.insuredRelationship === "") {
            props.handleValidationMsg("Please provide a relationship selection to the insured driver.", "Oops!")
            props.handleShow()
            return false
        }
        else if ((props?.userData?.pipOption >= 4 && props?.userData?.pipOption <= 6) &&
            props.infoFields?.insuredRelationship != "" && props.infoFields?.insuredRelationship != "Other" && props.infoFields.insurance === "") {
            props.handleValidationMsg("Please select if the household member has health insurance.", "Oops!")
            props.handleShow()
            return false
        }
        else if ((props?.userData?.pipOption >= 4 && props?.userData?.pipOption <= 6) &&
            props.infoFields?.insuredRelationship != "" && props.infoFields?.insuredRelationship != "Other" && props.infoFields.insurance === "1" && props.infoFields.insuranceType == "") {
            props.handleValidationMsg("Please confirm what type of health insurance this household member has.", "Oops!")
            props.handleShow()
            return false
        }
        else {
            return true
        }
    }
    else {
        return true
    }
}

export default AddMemberValidationMI