import { useEffect, useMemo } from "react";
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom"
import { verifyLoginTkn } from "../services/Api";
import { storageData } from "../services/Services";

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}


const ExtLogin = (props) => {
    const { userData, setUserData } = props.user
    const navigate = useNavigate()
    let query = useQuery()
    const redirect = query.get("redirect")
    const token = query.get("tkn")
    useEffect(() => {
        if (token) {

            const Setup = async () => {
                const response = await verifyLoginTkn(token)
                if (response.data.Result === "Success") {
                    response.data.idCardData = response.data.idCardData.result
                    response.data.renewalData = response.data.renewalData.result

                    storageData.restart()
                    setUserData({ ...response.data, complete: true })
                } else {
                    navigate("/")
                }
            }
            if (userData?.complete) {
                navigate(`/${(redirect ? redirect : "home")}`)
            } else {
                Setup()
            }
        } else {
            navigate("/")
        }
    }, [userData])

    return (
        <div style={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}>
            <Spinner animation="border" />
        </div>
    )
}



export default ExtLogin