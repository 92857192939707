import React, { useState } from "react"
import { storageData } from "../../services/Services"

export const AddressChangeContext = React.createContext()

/**
 * Sets effective date within range
 * @param {Date} date 
 * @returns date within range
 */
const setEffectiveDate = (date) => {
    var dateMax = new Date()
    var dateMin = new Date()
    dateMin.setHours(24, 0, 0, 0)
    dateMax.setDate(dateMax.getDate() + 30)
    if (dateMin.getTime() > date.getTime()) {
        date = dateMin
    } else if (dateMax.getTime() < date.getTime()) {
        date = dateMax
    }
    var datePassed = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
    return datePassed
}

const getTodaysDate = (addOne) => {
    // If adding a day --> seconds * minutes * hours * milliseconds
    var today = (addOne) ? new Date(new Date().getTime() + (60 * 60 * 24 * 1000)) : new Date()
    var yyyy = today.getFullYear()
    var mm = today.getMonth() + 1
    var dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    return mm + '/' + dd + '/' + yyyy
}

const getAbbrState = (state) => {
    var obj = {
        "Michigan": "MI",
        "New Jersey": "NJ",
        "Pennsylvania": "PA"
    }
    return obj[state]
}

export const AddressChangeContextComp = ({ children }) => {
    const [addressChangeClass, setAddressChangeClass] = useState(JSON.parse(storageData.getItem("AddressChangeClass")))
    const [userData, setUserData] = useState(JSON.parse(storageData.getItem("userData")))
    const [driverList, setDriverList] = useState(JSON.parse(storageData.getItem("storedDrivers")))
    const [memberList, setMemberList] = useState(JSON.parse(storageData.getItem("storedHouseholdMembers")))
    const [pendingDrivers, setPendingDriverList] = useState(JSON.parse(storageData.getItem("PendingDrivers")))
    const [PendingRemovedDrivers, setPendingRemovedList] = useState(JSON.parse(storageData.getItem("PendingRemovedDrivers")))
    const [currentResidence, setCurrentResidence] = useState(JSON.parse(storageData.getItem("currResidence")))

    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [showModal, setShowModal] = useState(false)
    const setValidationMsgToggle = (title, value) => {
        setValidationTitle(title);
        setValidationMsg(value);
        setShowModal(true)
    }

    const [addDriver, setAddDriver] = useState(false)
    const [showDriverEditMenu, setShowDriverEditMenu] = useState(false)
    const [editStoredDriverArr, setEditStoredDriverArr] = useState([])
    const [editAddedDriverArr, setEditAddedDriverArr] = useState([])

    const [addMember, setAddMember] = useState(false)
    const [showMemberEditMenu, setShowMemberEditMenu] = useState(false)
    const [editStoredMemberArr, setEditStoredMemberArr] = useState([])
    const [editAddedMemberArr, setEditAddedMemberArr] = useState([])

    return (
        <AddressChangeContext.Provider
            value={{
                addressChangeClass, setAddressChangeClass,
                userData, setUserData,
                currentResidence, setCurrentResidence,
                driverList, setDriverList,
                memberList, setMemberList,
                validationTitle, setValidationTitle,
                validationMsg, setValidationMsg,
                showModal, setShowModal,
                setValidationMsgToggle,
                addDriver, setAddDriver,
                addMember, setAddMember,
                showDriverEditMenu, setShowDriverEditMenu,
                showMemberEditMenu, setShowMemberEditMenu,
                pendingDrivers, setPendingDriverList,
                PendingRemovedDrivers, setPendingRemovedList,
                getTodaysDate, setEffectiveDate,
                editStoredDriverArr, setEditStoredDriverArr,
                editStoredMemberArr, setEditStoredMemberArr,
                editAddedDriverArr, setEditAddedDriverArr,
                editAddedMemberArr, setEditAddedMemberArr,
                getAbbrState
            }}>
            {children}
        </AddressChangeContext.Provider>
    )
}