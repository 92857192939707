import { FormDateSelect, FormDateText, FormDateTextMask, FormRadio, FormSelect, FormText } from "..";

const FormAuto = (props) => {
    const { form } = props
    const formFinal = form.map((input, i) => {
        var { type, ...rest } = input
        rest.key = rest.index = i.toString()
        
        switch (type) {
            case "text":
            case "number":
                return <FormText {...rest} />
            case "dateSelect":
                return <FormDateSelect {...rest} />
            case "dateText":
                return <FormDateText {...rest} />
            case "dateTextMask":
                return <FormDateTextMask {...rest} />
            case "select":
                return <FormSelect {...rest} />
            case "radio":
                return <FormRadio {...rest} />
            case "checkbox":
                return
        }

    })
    return formFinal
}

export default FormAuto