import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { HouseholdChangeContextComp } from "../../components/ChangeRequest_HouseholdChangeComp/HouseholdChangeContext"
import HouseholdProgressBarSect from "../../components/UpdateDriversComp/HouseholdProgressBarSect"
import HouseholdConfirmationMain from "components/ChangeRequest_HouseholdChangeComp/4_Confirmation/HouseholdConfirmationMain"
import { useEffect } from "react"

const UpdateHouseholdConfirmation = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <HouseholdChangeContextComp>
            <Menu Title={"HOUSEHOLD CHANGE"} FirstName={userData.firstName} userData={userData} />
            {/* <HouseholdProgressBarSect screen={"Confirmation"} /> */}
            <RoundyContainer Content={HouseholdConfirmationMain} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </HouseholdChangeContextComp>
        </>
    )
}

export default UpdateHouseholdConfirmation