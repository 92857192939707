import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { HouseholdChangeContextComp } from "../../components/ChangeRequest_HouseholdChangeComp/HouseholdChangeContext"
import HouseholdProgressBarSect from "../../components/UpdateDriversComp/HouseholdProgressBarSect"
import HouseholdSummaryMain from "components/ChangeRequest_HouseholdChangeComp/3_Summary/HouseholdSummaryMain"
import { useEffect } from "react"

const UpdateHouseholdSummary = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <HouseholdChangeContextComp>
            <Menu Title={"HOUSEHOLD CHANGE"} FirstName={userData.firstName} userData={userData} />
            <HouseholdProgressBarSect screen={"Summary"} />
            <RoundyContainer Content={HouseholdSummaryMain} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </HouseholdChangeContextComp>
        </>
    )
}

export default UpdateHouseholdSummary