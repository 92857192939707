import { storageData } from "services/Services"

const VehicleLeasingList = (state = null) => {
    var leasingCompanies = storageData.getItem('LeasingList')

    if (leasingCompanies) {
        leasingCompanies = JSON.parse(leasingCompanies).LeasingCompanies

        if (leasingCompanies.length != 0) {
            leasingCompanies = leasingCompanies.filter(company => company.Name !== "").map((company) => {
                return (
                    <option key={company.LeasingID} value={state == "New Jersey" || state == "Pennsylvania" ? company.LeasingID : company.Name.trim()}>{company.Name.trim()}</option>
                )
            })
        }
    }

    return (
        <>
            {leasingCompanies}
        </>
    )
}

export default VehicleLeasingList