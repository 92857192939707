import React from "react";
import { years, months, days } from "..";

const FormDateSelect = (props) => {
    const { id, value, label, params, callback } = props

    var minimumDriverYear = new Date().getFullYear() - 16
    var yearsList = (params?.driver) ? years.years.filter((year) => parseInt(year.year) <= minimumDriverYear) : years.years

    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                <div className="col-xl-4 text-blue fw-bold">
                    {label}
                </div>
                <div className="col-xl-8">
                    <div className="row">
                        {[{ value: value.m, id: id.m, list: months.months, title: "month" },
                        { value: value.d, id: id.d, list: days.days, title: "day" },
                        { value: value.y, id: id.y, list: yearsList, title: "year" }].map((val, i) =>
                            <div key={i} className="col-xl-4 col-sm-4 col-4">
                                <div className="btn-group bootstrap-select custom-selector" >
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                        defaultValue={val.value}
                                        onChange={(e) => { callback(val.id, e.target.value) }}>
                                        {val.title == "year" && <option value={"Year"}>
                                            Year
                                        </option>}
                                        {val.list.map((item, j) => (
                                            <option key={j} value={item.id}>
                                                {item[val.title]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormDateSelect