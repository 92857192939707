import React, { useContext } from "react"
import ValidationModal from "../../ValidationModal"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleChangeBtnNavigation from "../VehicleChangeBtnNavigation"
import VehicleDA_Directions from "./VehicleDA_Directions"
import VehicleDA_DriverPolicy from "./VehicleDA_DriverPolicy"
import VehicleDA_SelectVehicle from "./VehicleDA_SelectVehicle"

export const VehicleDA_StylesContext = React.createContext()

const VehicleDA_Main = () => {
    const context = useContext(VehicleChangeContext)

    const styles = {
        indent: {
            paddingLeft: "15px",
        },
        blueText: {
            color: "#046ab0",
            fontSize: '13.5px'
        }
    }

    return (
        <>
            <VehicleDA_StylesContext.Provider
                value={{ styles }}>
                <div className="row text-muted bg-block">
                    <div className="col-12">
                        <VehicleDA_Directions />
                        <VehicleDA_SelectVehicle changeReqContext={VehicleChangeContext} stylesContext={VehicleDA_StylesContext} reqChangeClass={"vehicleChangeClass"} setChangeClass={context.setVehicleChangeClass} />
                        <VehicleDA_DriverPolicy />
                    </div>
                </div>
            </VehicleDA_StylesContext.Provider>

            <VehicleChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default VehicleDA_Main