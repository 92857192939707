const days = {
    value: "Day",
    days: [
        { id: "0", day: "Day" },
        { id: "01", day: "01" },
        { id: "02", day: "02" },
        { id: "03", day: "03" },
        { id: "04", day: "04" },
        { id: "05", day: "05" },
        { id: "06", day: "06" },
        { id: "07", day: "07" },
        { id: "08", day: "08" },
        { id: "09", day: "09" },
        { id: "10", day: "10" },
        { id: "11", day: "11" },
        { id: "12", day: "12" },
        { id: "13", day: "13" },
        { id: "14", day: "14" },
        { id: "15", day: "15" },
        { id: "16", day: "16" },
        { id: "17", day: "17" },
        { id: "18", day: "18" },
        { id: "19", day: "19" },
        { id: "20", day: "20" },
        { id: "21", day: "21" },
        { id: "22", day: "22" },
        { id: "23", day: "23" },
        { id: "24", day: "24" },
        { id: "25", day: "25" },
        { id: "26", day: "26" },
        { id: "27", day: "27" },
        { id: "28", day: "28" },
        { id: "29", day: "29" },
        { id: "30", day: "30" },
        { id: "31", day: "31" }
    ]
}

export default days