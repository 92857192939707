import { useState } from "react"

const PendingAddressNotice = (props) => {
    const [close, closeNotice] = useState(false);
    return (
        <>
            {!close &&
                <div className="col-12 p-2">
                    <div className="alert alert-warning alert-dismissible row" style={{ color: "#c09853" }} role={"alert"}>
                        <i className="fa fa-2x fa-warning my-1 col-1"></i>
                        <div className="col-10">
                            <strong>NOTE: This policy had an address change requested recently.</strong><br />
                            <p>If the "Pending Address Change" shown below has a typo or you are moving to a different address, submit a new address using the 
                                {(props.state == "Michigan") ? " \"Change Address\"" : " \"New Residence\""} form below. 
                            </p>
                        </div>
                        <button type="button" className="col-1" style={{ marginBottom: "100px", backgroundColor: "#fff3cd", border: "none", color: "#c09853" }} onClick={() => closeNotice(true)}>
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

export default PendingAddressNotice