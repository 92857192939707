import React from "react";
import { states, dlStatus } from "../../";
import ValidationModal from "../../../ValidationModal";

class ValidLicense extends React.Component {
    constructor(props) {
        super(props);
        this.dlStatus = dlStatus;
        this.state = states;
    }

    statePolicy = (userState) => {
        if (userState === "New Jersey") {
            return "NJ"
        }
        else if (userState === "Michigan") {
            return "MI"
        }
        else {
            return "PA"
        }
    }

    render() {
        return (
            <>
                <div className="row form-group form-group-sm">
                    <ValidationModal />
                    {/* <div className="col-sm-12">
                        <hr className="less-margin" />
                    </div> */}
                    <div className="col-xl-8 text-blue fw-bold">
                        {/* Does this {this.props.user} have a valid {this.statePolicy(this.props.userData.state)} driver's license? */}
                        Does this {this.props.user} have a valid driver's license?*
                    </div>
                    <div className="col-xl-4" onChange={(e) => this.props.handleCallBack("dLicense", parseInt(e.target.value))}>
                        <div className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input className="form-check-input" name="003" type={"radio"} value={1}
                                    defaultChecked={this.props.dLicense === 1}
                                />Yes
                            </label>
                        </div>
                        <div className="radio-inline form-check ">
                            <label className="form-check-label">
                                <input className="form-check-input" name="003" type={"radio"} value={0}
                                    defaultChecked={this.props.dLicense === 0}
                                />No&nbsp;&nbsp;&nbsp;
                            </label>
                            <a>
                                <i className="fa fa-info-circle" onClick={() => {
                                    this.props.handleShow();
                                    this.props.handleValidationMsg(`You must have a valid driver's license to be considered a licensed household member. 
                                        This is a license that has been issued to you by the state, is not expired, suspended, or revoked, 
                                        and includes all driving rights and privileges.`, "Invalid License Notice")
                                }}></i>
                            </a>
                        </div>
                    </div>
                </div>
                {/* Valid NJ DL Toggle */}
                {(this.props.dLicense === 0) &&
                    <div className="row">
                        <div className="col-xl-10">
                            <div className="form-group form-group-sm">
                                <div className="col-xl-8 text-blue fw-bold">
                                    {/* Does this {this.props.user} have a valid {this.statePolicy(this.props.userData.state)} driver's license? */}
                                    What is the current status of this driver's license?*
                                </div>
                                <div className="col-lg-12 btn-group bootstrap-select custom-selector">
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                        defaultValue={this.props.licenseStatus || "default"}
                                        onChange={(e) => { this.props.handleCallBack("licenseStatus", e.target.value) }} style={{ fontSize: "12px" }}>
                                        <option disabled value={"default"}>
                                            Select from the following...
                                        </option>
                                        {this.dlStatus.dlStatus.map(item => (
                                            <option key={item.id} value={item.dlStatus}>
                                                {item.dlStatus}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {(this.props.licenseStatus === "Valid Other State License") &&
                            <div className="col-xl-10 justify-content-center">
                                <div className="row form-group form-group-sm align-items-center">
                                    <div className="col-xl-4 text-blue fw-bold">
                                        Licensed State:*
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="btn-group bootstrap-select custom-selector">
                                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                defaultValue={this.props.licenseState}
                                                onChange={(e) => { this.props.handleCallBack("licenseState", e.target.value) }}>
                                                {this.state.map((item,i) => (
                                                    <option key={i} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {((this.props.licenseStatus === "Valid Other State License") ||
                            (this.props.licenseStatus === "Permit"))
                            &&
                            <div className="col-xl-10 justify-content-center">
                                <div className="row form-group form-group-sm inputHeight">
                                    <div className="col-xl-4 text-blue fw-bold">
                                        License Number:*
                                    </div>
                                    <div className="col-xl-8">
                                        <input type={"text"} className="form-control" name="LicenseNumber" maxLength={15}
                                            defaultValue={this.props.licenseNum}
                                            onChange={(e) => { this.props.handleCallBack("licenseNum", e.target.value) }} />
                                        <div className="small text-muted">
                                            Optional now but required within 30 days.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* {(this.props.dLicense === 1) && this.props.user === "driver" && this.props.userData.state !== "Michigan" &&
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="row form-group form-group-sm"
                                onChange={(e) => { this.props.handleCallBack("licenseNum", e.target.value) }}>
                                <div className="col-xl-5 col-lg-12">
                                    <input className="form-control" name="licenseNumber" placeholder="License Number" maxLength={15}
                                        defaultValue={this.props.licenseNum} />
                                    <div className="small text-muted"></div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="small text-muted">
                                        Optional now but required within 30 days
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
            </>
        )
    }
}

export default ValidLicense