import { useContext, useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { storageData } from "services/Services";
import { AddressChangeContext } from "../AddressChangeContext";
import { downloadTempIDCard } from "services/Api";

const AddressConfirmationMain = () => {
    const context = useContext(AddressChangeContext)
    const [loader, setLoader] = useState(false)
    const [disableTempIDCard, setDisableTempIDCard] = useState(false)
    const navigate = useNavigate();
    const date = new Date();
    var newResidenceData = context?.addressChangeClass?.newResidence
    var storedDrivers = context?.addressChangeClass?.driversHHMs?.drivers?.storedDrivers
    var newDrivers = context?.addressChangeClass?.driversHHMs?.drivers?.newDrivers
    var storedMembers = context?.addressChangeClass?.driversHHMs?.householdMembers?.storedMembers
    var newMembers = context?.addressChangeClass?.driversHHMs?.householdMembers?.newMembers
    var allDrivers = [...storedDrivers, ...newDrivers]
    var allHHMs = [...storedMembers, ...newMembers]
    var currentPolicyState = context.getAbbrState(context?.userData?.state)

    // Downloads Temporary ID Card
    const downloadTempCard = async () => {
        setLoader(true);
        var addressChangeID = storageData.getItem("AddressChangeID");
        var a = document.createElement("a");
        a.href = `data:application/octet-stream;base64,
        ${await downloadTempIDCard(addressChangeID, (context?.userData?.state == "New Jersey") ? "TempIDCardAddress_NJ" :
            (context?.userData?.state == "Pennsylvania") ? "TempIDCardAddress_PA" : "TempIDCardAddress_MI")}`;
        a.setAttribute("download", "TemporaryIDCard.pdf");
        a.click();
        setLoader(false);
    }

    // Moved to new policy covered state but different from current state on policy
    const compareNewPolicyState = () => {
        var policyStates = ["MI", "NJ", "PA"]
        if (policyStates?.includes(newResidenceData?.mailingState) && newResidenceData?.mailingState != context.getAbbrState(context?.userData?.state)) {
            return true
        }
        else { return false }
    }

    useEffect(() => {
        const checkAddressChangeID = () => {
            const addressChangeID = storageData.getItem("AddressChangeID");
            if (addressChangeID) { setDisableTempIDCard(false) }
            else { setDisableTempIDCard(true) }
        };

        checkAddressChangeID();

        // Set up an interval to check every, 2 seconds
        const intervalId = setInterval(checkAddressChangeID, 2000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [])

    return (
        <>
            <div className="row px-2">
                <h4 className="text-center text-primary fw-bold">YOUR REQUEST HAS BEEN SUBMITTED.</h4><br /><br />
                <table style={{ width: "auto", margin: "0 auto" }}>
                    <tbody>
                        <tr>
                            <td className="fw-bold" style={{ paddingRight: "10px" }}>
                                Date:
                            </td>
                            <td>
                                {(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()}
                            </td>
                        </tr>
                        <tr>
                            <td className="small fst-italic" style={{ color: 'grey' }}>
                                NOTE: All changes are subject to review by underwriting.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-4">
                <div className="mt-4">
                    {/* Not in NJ / PA / MI */}
                    {newResidenceData?.mailingState != "NJ" && newResidenceData?.mailingState != "PA" && newResidenceData?.mailingState != "MI" &&
                        <p>We currently do not provide insurance for residents of your state. We will be contacting you shortly to discuss options regarding your current policy.</p>
                    }
                    {/* New Address in another state (either NJ / PA / MI) from previous location */}
                    {compareNewPolicyState() &&
                        <p>Your current policy does not provide coverage in your new state. A representative will be contacting you shortly to discuss options regarding your recent move.</p>
                    }
                    {/* New Address within same state */}
                    {!compareNewPolicyState() &&
                        <div>
                            <hr />
                            <h4 className="no-margin text-primary fw-bold">Overview</h4>
                            <p>You have requested your address be changed to: </p>

                            <div>
                                <strong className="text-primary">
                                    <h5><u>Mailing Address:</u></h5>
                                    {newResidenceData?.mailingAddress}<br />
                                    {newResidenceData?.mailingCity + ", " + newResidenceData?.mailingState + " " + newResidenceData?.mailingZip}
                                </strong>
                            </div>
                            <br />

                            {(newResidenceData?.mailingState == "NJ" || newResidenceData?.mailingState == "PA" || newResidenceData?.mailingState == "MI") &&
                                <>
                                    {context?.userData?.state != "Michigan" &&
                                        <>
                                            <p className="small text-muted fst-italic">New ID cards will be mailed after your request has been processed.</p>

                                            {/* ID Cards */}
                                            <div>
                                                <h4 className="no-margin text-primary fw-bold">ID Cards</h4>

                                                {/* Loaded Temp ID Card */}
                                                {!loader && !disableTempIDCard &&
                                                    <a className="active-link" onClick={downloadTempCard}>
                                                        Download Temporary Evidence Card
                                                    </a>
                                                }
                                                {/* Processing Temp ID Card */}
                                                {!loader && disableTempIDCard &&
                                                    <div style={{ display: "flex" }}>
                                                        <a className="disabled-link" disabled>
                                                            ID Card Processing
                                                        </a>
                                                        <div className="mx-3">
                                                            <Spinner animation="border" />
                                                        </div>
                                                    </div>
                                                }
                                                {loader &&
                                                    <div style={{ display: "flex" }}>
                                                        <Spinner animation="border" />
                                                    </div>
                                                }

                                                {!disableTempIDCard ? (
                                                    <p className="my-0">An updated ID card has been sent to your email address.</p>
                                                ) : (
                                                    <p className="my-0" style={{ color: 'gray' }}>An updated ID card will be sent to your email address.</p>
                                                )}
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {/* Drivers */}
                            {allDrivers &&
                                <div>
                                    <hr />
                                    <h4 className="no-margin text-primary fw-bold">Drivers</h4>
                                    {allDrivers?.map((driver, id) => {
                                        return (
                                            <div key={id}>
                                                {/* Non Changed Drivers */}
                                                <p className="fw-bold text-primary ms-5">
                                                    <span className="text-uppercase">{`${(driver.driverStatus == "" ? `${driver.fullName}` : "")}`}</span>
                                                    <span className="fst-italic">{`${(driver.driverStatus == "" ? ` - No Change` : "")}`}</span>
                                                </p>
                                                {/* Changed Drivers */}
                                                <p className="fw-bold text-primary ms-5">
                                                    <span className="text-uppercase">{`${(driver.driverStatus !== "" ? `${driver.fullName}` : "")}`}</span>
                                                    <span className="fst-italic">{`${(driver.driverStatus !== "" ? ` - ${driver.driverStatus}` : "")}`}</span>
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {/* Household Members */}
                            {allHHMs &&
                                <div>
                                    <hr />
                                    <h4 className="no-margin text-primary fw-bold">Household Members</h4>
                                    {allHHMs?.map((member, id) => {
                                        return (
                                            <div key={id}>
                                                {/* Non Changed Members */}
                                                <p className="fw-bold text-primary ms-5">
                                                    <span className="text-uppercase">{`${(member.memberStatus == "" ? `${member.fullName}` : "")}`}</span>
                                                    <span className="fst-italic">{`${(member.memberStatus == "" ? ` - No Change` : "")}`}</span>
                                                </p>
                                                {/* Changed Members */}
                                                <p className="fw-bold text-primary ms-5">
                                                    <span className="text-uppercase">{`${(member.memberStatus !== "" ? `${member.fullName}` : "")}`}</span>
                                                    <span className="fst-italic">{
                                                        // Added HHM
                                                        (member.memberStatus === "New Resident") ? " - New Household Member" :
                                                            // Modified HHM
                                                            (member.memberStatus !== "") ? " - " + (member.memberStatus) :
                                                                // No Change
                                                                ""
                                                    }</span>
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    }
                </div>

            </div>
            <div className="text-center">
                <br /><br />
                <input type={"button"} className="btn btn-primary-grad" value={"Return to My Account"} onClick={() => {
                    navigate("/home");
                    // Removes changes for extra storage in local storage
                    storageData.removeItem("updateVehicleData");
                    storageData.removeItem("updateDriverAssignmentData");
                    storageData.removeItem("newVehicles");
                    storageData.removeItem("dbVehicles");
                    storageData.removeItem("vehicleSummaryChanges");
                    storageData.removeItem("AddressChangeConfirmationNumber");
                    storageData.removeItem("storedDrivers");
                    storageData.removeItem("storedHouseholdMembers");
                    storageData.removeItem("storedVehicles");
                    storageData.removeItem("PendingAddedVehicles");
                    storageData.removeItem("PendingRemovedVehicles");
                    storageData.removeItem("PendingAddedDrivers");
                    storageData.removeItem("PendingAddedHhms");
                    storageData.removeItem("PendingRemovedDrivers");
                    storageData.removeItem("PendingRemovedHhms");
                    storageData.removeItem("PendingAddress");
                    storageData.removeItem("vehicleInfo");
                    storageData.removeItem("currResidence");
                    storageData.removeItem("AddressChangeClass");
                    storageData.removeItem("AddressChangeID");
                }} />
                <br /><br />
            </div>
        </>
    )
}

export default AddressConfirmationMain