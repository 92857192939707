const months = {
    value: "Month",
    months: [
        { id: "0", month: "Month" },
        { id: "01", month: "Jan" },
        { id: "02", month: "Feb" },
        { id: "03", month: "Mar" },
        { id: "04", month: "Apr" },
        { id: "05", month: "May" },
        { id: "06", month: "June" },
        { id: "07", month: "Jul" },
        { id: "08", month: "Aug" },
        { id: "09", month: "Sept" },
        { id: "10", month: "Oct" },
        { id: "11", month: "Nov" },
        { id: "12", month: "Dec" }
    ]
}

export default months