import React from 'react';
import HeaderSection from './HeaderSection';
import OverviewSection from './OverviewSection';
import IDCardSection from './IDCardSection';
import FooterSection from './FooterSection';
import './EndorsementThankYou.css';

const EndorsementThankYou = () => {
  return (
    <div className="endorsement-thank-you">
      <HeaderSection />
      <OverviewSection />
      <IDCardSection />
      <FooterSection />
    </div>
  );
};

export default EndorsementThankYou;