import React from 'react'

const VehicleNote = () => {
    return (
        <div style={{ fontSize: '14px' }}>
            <p>
                If your option is not available online for this change, please call 800-535-2873 to discuss your change request with a CURE representative.
            </p>
            <p>
                Note: This request will result in a change to your policy and is not for quoting purposes. Once your change request has been processed, you will receive updated policy documents and billing schedule.
            </p>
        </div>
    )
}

export default VehicleNote