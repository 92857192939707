import { storageData } from "services/Services"

const VehicleFinanceList = (state = null) => {
    var financeCompanies = storageData.getItem('FinanceCompanyList')

    if (financeCompanies) {
        financeCompanies = JSON.parse(financeCompanies).FinanceCompanies

        if (financeCompanies.length != 0) {
            financeCompanies = financeCompanies.filter(company => company.Name !== "").map((company) => {
                return (
                    <option key={company.FinanceID} value={state == "New Jersey" || state == "Pennsylvania" ? company.FinanceID : company.Name.trim()}>{company.Name.trim()}</option>
                )
            })
        }
    }

    return (
        <>
            {financeCompanies}
        </>
    )
}

export default VehicleFinanceList