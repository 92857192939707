
export const getAPIEndpoint = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'http://localhost:57761' //http://localhost:57761
        return 'https://svcstg.cure.com'
      case 'myaccountstg.cure.com':
        return 'https://svcstg.cure.com'
      case 'myaccountdev.cure.com':
        return 'http://localhost:57761'
      case 'myaccountprep.cure.com':
        return 'https://svcprep.cure.com'
      case 'myaccount.cure.com':
        return 'https://svc.cure.com'
      default:
        return 'https://svcstg.cure.com'
    }
  }
}

export const getSiteEnv = (GetEnvironmentSettings) => {
  if (typeof window !== 'undefined') {
    if(window.location.hostname == "myaccount.cure.com" || window.location.hostname == "localhost"){
      return 'prod'
    }
    return GetEnvironmentSettings(window.location.hostname).GWApiEnv;
  }
}

export const getSiteEnvironment = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'myaccount.cure.com':
        return 'Production'
      case 'myaccountprep.cure.com':
        return 'Prepublish'
      default:
        return 'Staging'
    }
  }
}

export const getTestingEnvToken = () => {
  if (typeof window !== 'undefined') {
        return "44874035-568c-4db4-aed0-69a0a3203cd6"
  }else{
    return "";
  }
}


export const getDomainOneInc = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'myaccountdev.cure.com':
        return 'paymentsdev.cure.com'
      case 'myaccountstg.cure.com':
        return 'paymentsstg.cure.com'
      case 'myaccountgw.cure.com':
        return 'paymentsgw.cure.com'
      case 'myaccountprep.cure.com':
        return 'paymentsprep.cure.com'
      case 'myaccount.cure.com':
        return 'payments.cure.com'
      default:
        return window.location.hostname
        break;
    }
  }
}

export const getDomainChat = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'chatstg.cure.com'
      case 'myaccountdev.cure.com':
        return 'chatstg.cure.com'
      case 'myaccountstg.cure.com':
        return 'chatstg.cure.com'
      case 'myaccountprep.cure.com':
        return 'chatprep.cure.com'
      case 'myaccount.cure.com':
        return 'chat.cure.com'
      default:
        return 'chatstg.cure.com'
    }
  }
}

export const getDomain = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'https://staging.cure.com' //http://localhost:57761
      case 'myaccountdev.cure.com':
        return 'https://dev.cure.com'
      case 'myaccountstg.cure.com':
        return 'https://staging.cure.com'
      case 'myaccountprep.cure.com':
        return 'https://prepublish.cure.com'
      default:
        return 'https://www.cure.com'
    }
  }
}

export const domainName = (location) => {
  var name = {
    'dev.cure.com': 'Staging',
    'localhost': 'Staging',
    'staging.cure.com': 'Staging',
    'prepublish.cure.com': 'Prepublish',
    'cure.com': 'Production',
    'gw.cure.com': 'Production'
  }
  return name[location?.replace(/^https?:\/\//, '')]
}
