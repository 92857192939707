import { useEffect, useState } from "react"

const VehicleQuestions = (props) => {
    const updateSessionData = (key, value) => {
        var sessionDataCopy = props.sessionData
        sessionDataCopy.vehicles[key] = value
        props.setSessionData({ ...sessionDataCopy })
    }
    return (
        <>
            <div>
                {/* Business Purposes */}
                <div className="form-group form-group-sm">
                    <strong className="text-primary">
                        Are any of the vehicle(s) listed above used for business purposes?
                        <a className="info-icon-for-select ms-2">
                            <i className="fa fa-info-circle"
                                onClick={() => {
                                    props.setShow(true)
                                    props.setValidationTitle("Business Purposes")
                                    props.setValidationMsg(`For example, deliveries, delivery services, plowing snow for compensation, or any commercial purposes.`)
                                }}
                            />
                        </a>
                    </strong>
                </div>

                {/* Response for business purposes */}
                <div className="form-group form-group-sm text-dark ms-4">
                    {['Yes', 'No'].map((value, id) => {
                        return (
                            <div key={id}>
                                <div className="radio-inline form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input type={"radio"} className="form-check-input" name="VehicleUsedInEmployment" value={value}
                                            checked={props?.sessionData?.vehicles?.businessPurposeResponse == value}
                                            onChange={(e) => updateSessionData("businessPurposeResponse", e.target.value)}
                                        />{(value == "No") ? "No, this vehicle is only used to drive to/from work and/or personal use." : value}
                                    </label>
                                </div><br />
                            </div>
                        )
                    })}
                </div>

                {/* Textbox for other purpose */}
                {props?.sessionData?.vehicles?.businessPurposeResponse == "Yes" &&
                    <div className="ms-4">
                        <div className="row">
                            <div className="col-10">
                                <input type={"text"} className="form-control input-sm" placeholder="Please explain how your vehicles are used. *" 
                                    onChange={(e) => updateSessionData("businessPurposeExplanation", e.target.value)}
                                    value={props?.sessionData?.vehicles?.businessPurposeExplanation}
                                />
                            </div>
                        </div>
                    </div>
                }

                {/* Kept Overnight */}
                <div className="form-group form-group-sm mt-3">
                    <strong className="text-primary">{`Are all of the vehicles listed above kept overnight at ${props?.address?.streetAddress1}
                    ${props?.address?.city}, ${props?.address?.state} ${(props?.address?.zip)}?`}</strong>
                </div>
                {/* Response for vehicle kept overnight */}
                <div className="form-group form-group-sm text-dark ms-3">
                    {['Yes', 'No'].map((value, id) => {
                        return (
                            <div key={id} className="radio-inline form-check form-check-inline ms-2">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="garaged" value={value}
                                        checked={props?.sessionData?.vehicles?.keptOvernightResponse === value}
                                        onChange={(e) => updateSessionData("keptOvernightResponse", e.target.value)}
                                    />{value}
                                </label>
                            </div>
                        )
                    })}
                </div>

                {/* Textbox for location of vehicle */}
                {props?.sessionData?.vehicles?.keptOvernightResponse == "No" &&
                    <div className="mx-4">
                        <div className="form-group form-group-sm">
                            <strong className="text-primary">Please indicate where the vehicles are kept.</strong>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <textarea className="form-control" style={{ resize: 'none' }} name="garageLocation" placeholder="Address(es)" rows={"3"}
                                    value={props?.sessionData?.vehicles?.keptOvernightLocation} onChange={(e) => updateSessionData("keptOvernightLocation", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                }
                <p className="fw-bold mt-4 mb-0" style={{ fontSize: '13px' }}>
                    Please call us to add or change any of your policy vehicles.
                </p>
            </div>
        </>
    )
}

export default VehicleQuestions