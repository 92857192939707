const RemovalReasonDropdown = ({ list, currentVehicle, state }) => {
    const allButOneHasReason = list.filter(item => item !== currentVehicle).every(item => "vehicleRemovalReason" in item);

    return (
        <>
            <option value="" disabled>Select from the following...</option>
            <option value="Sold">Sold/Gifted/Donated</option>
            <option value="Returned">Traded In/Lease Ended/Returned to Dealer/Repossessed</option>
            <option value="Storage">Garaged/Placed in storage</option>
            <option value="Totaled">Totaled in an accident/Stolen</option>
            <option value="NotRunning">Mechanical Failure/Not Running</option>
            <option value="OtherPolicy">Divorce/Separation and now insured on another policy</option>
            {(list.length === 1 || (list.length > 1 && allButOneHasReason)) && state == "Michigan" && <option value="CancelPolicy">Cancel Policy</option>}
            <option value="Other">Other</option>
        </>
    );
}

export default RemovalReasonDropdown;
