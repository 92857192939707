const AddDriverValidationMI = (props) => {
    // Name Empty
    if (props.infoFields.fullName === "") {
        props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
        props.handleShow()
        return false
    }
    else if (props.infoFields.fullName) {
        var containsNumerical = /\d/;
        // Name contains numerical value
        if (containsNumerical.test(props.infoFields.fullName)) {
            props.handleValidationMsg("Name contains a numeric character. Please enter a valid Name.", "Oops!")
            props.handleShow()
            return false
        }
        // var valid = /^(([a-zA-Z'-.]{2,})+ ([a-zA-Z'-.]+){2,})$/;
        // if (!valid.test(props.infoFields.fullName)) {
        //     props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
        //     props.handleShow()
        //     return false
        // }
        else if (props.infoFields.monthSelect === "" || props.infoFields.daySelect === "" || props.infoFields.yearSelect === "") {
            props.handleValidationMsg("Please complete the driver's date of birth.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.mStatus === "") {
            props.handleValidationMsg("Please select the driver's Marital Status.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.gender === "") {
            props.handleValidationMsg("Please select the driver's Gender.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.sameHousehold === "") {
            props.handleValidationMsg("Please specify if this driver resides in same household.", "Oops!")
            props.handleShow()
            return false
        }

        // Same household --> no
        else if (props.infoFields?.sameHousehold == "0" && props.infoFields?.residenceAddress === "") {
            props.handleValidationMsg("Please provide the address for the driver's current residence.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.sameHousehold == "0" && props.infoFields?.residenceCity === "") {
            props.handleValidationMsg("Please enter the city where this driver currently resides.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.sameHousehold == "0" && (props.infoFields?.residenceState === "")) {
            props.handleValidationMsg("Please select the state where this driver currently resides.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.sameHousehold == "0" && props.infoFields?.residenceZip === "") {
            props.handleValidationMsg("Please select the zip where this driver currently resides.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.sameHousehold == "0" && props.infoFields?.residenceVehicles?.length == 0) {
            props.handleValidationMsg("Please provide any vehicles on the policy will be kept at this new address.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.occupation == "") {
            props.handleValidationMsg("Please provide an occupation.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "") {
            props.handleValidationMsg("Please specify whether or not the driver has a valid " + props?.userData?.state + " license.", "Oops!")
            props.handleShow()
            return false
        }

        // Valid license --> no
        else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus === "") {
            props.handleValidationMsg("Please specify the current status of the driver's license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Valid Foreign License" && props.infoFields?.licenseCountry == "") {
            props.handleValidationMsg("Please provide the country of this license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Valid Other State License" && props.infoFields?.licenseOtherState == "") {
            props.handleValidationMsg("Please provide the state of this license.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Valid Other State License" && props.infoFields?.licenseNum == "") {
            props.handleValidationMsg("Please provide the license number.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Permit" && props.infoFields?.licenseNum == "") {
            props.handleValidationMsg("Please provide the permit number.", "Oops!")
            props.handleShow()
            return false
        }

        // Valid license --> yes
        else if (props.infoFields.dLicense == "1" && props.infoFields.licenseNum == "") {
            props.handleValidationMsg("Please provide the license number.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.dLicense == "1" && props.infoFields.licenseAge == "") {
            props.handleValidationMsg("Please provide the age when first licensed.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.disorder == "") {
            props.handleValidationMsg("Does this driver have a disorder or condition that may impair their ability to safely operate a motor vehicle, including but not limited to Narcolepsy, Syncope, Blindness, or Seizures?", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.impairments == "") {
            props.handleValidationMsg("Does this driver have any mental or physical impairments, other than those listed above, that would impair their ability to operate a motor vehicle safely (Not including prescription eyeglasses or contact lenses)?", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "") {
            props.handleValidationMsg("Please specify whether or not the driver is enrolled in a college or university.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.collegeName == "") {
            props.handleValidationMsg("Please enter the name of the school that the driver is currently attending.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.collegeCity == "") {
            props.handleValidationMsg("Please enter the city of the school that the driver is currently attending.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.collegeState == "") {
            props.handleValidationMsg("Please select the state of the school that the driver is currently attending.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.vehicleKeptAtSchool == "") {
            props.handleValidationMsg("Please specify if this driver has a vehicle that is kept at school.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.vehicleKeptAtSchool == "1" && props.infoFields?.keptAtSchoolVehicles?.length == 0) {
            props.handleValidationMsg("Please provide the vehicles on the policy that will be kept at school.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.enrolledInCollege == "1" && props.infoFields?.vehicleKeptAtSchool == "1" && props.infoFields?.over100Miles == "") {
            props.handleValidationMsg("Please specify if this driver attends a school that is over 100 miles from your address.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.insuredRelationship == "") {
            props.handleValidationMsg("Please provide a relationship selection to the insured driver.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.insuredRelationship != "" && props.infoFields?.insuredRelationship != "Other" 
        && (props?.userData?.pipOption == "4" || props?.userData?.pipOption == "5" || props?.userData?.pipOption == "6") 
        && props.infoFields?.insurance == "") {
            props.handleValidationMsg("Please provide whether this driver has health insurance.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields?.insuredRelationship != "" && props.infoFields?.insuredRelationship != "Other" 
        && (props?.userData?.pipOption == "4" || props?.userData?.pipOption == "5" || props?.userData?.pipOption == "6") 
        && props.infoFields?.insurance == "1" && props.infoFields?.insuranceType == "") {
            props.handleValidationMsg("Please provide type of health insurance this driver has.", "Oops!")
            props.handleShow()
            return false
        }
        else {
            return true
        }
    }
    // Removing a driver
    if ("driverInfo" in props) {
        if (props.driverInfo.michiganRequestedEffectiveDate === "") {
            props.handleValidationMsg("Please provide a requested effective date.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.driverInfo.michiganReasonForRemoval === "") {
            props.handleValidationMsg("Please provide an explanation for removal.", "Oops!")
            props.handleShow()
            return false
        }
        else if ("driversForValidation" in props) {
            for (var i = 0; i < props.driversForValidation.length; i++) {
                console.log(i)
                if (props.driversForValidation[i].answered) {
                    return true
                }
            }
            props.handleValidationMsg("Please select a driver to remove.", "Oops!")
            props.handleShow()
            return false
        }
    }
    else if ("michiganRequestedEffectiveDate" in props.infoFields) {
        if (props.userData.state === "Michigan" && props.infoFields.michiganRequestedEffectiveDate === "") {
            props.handleValidationMsg("Please provide a requested effective date.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.userData.state === "Michigan" && props.infoFields.michiganReasonForRemoval === "") {
            props.handleValidationMsg("Please provide an explanation for removal.", "Oops!")
            props.handleShow()
            return false
        }
        else {
            return true
        }
    }
    else {
        return true
    }
}

export default AddDriverValidationMI