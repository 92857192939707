import StateDropdown from "components/RenewalQuestionnaireComp/DriversOHM/StateDropdown"
import { useContext } from "react"
import VehicleSelections from "./VehicleSelections"

const NotSameHousehold = ({ typeOfForm, callback, setState, state, setVehicleToDriver, changeReqContext, reqChangeClass, setChangeClass, }) => {
    const context = useContext(changeReqContext)
    var { residenceAddress, residenceCity, residenceState, residenceZip, residenceVehicles, residenceVehicleVINs } = state

    return (
        <>
            <div className="slide-toggle">
                <div className={`row form-group form-group-sm ${typeOfForm != "vehiclesTaken" ? "justify-content-center" : ""}`}>
                    <div className="col-xl-10">

                        {/* Driver residence for not being in the same household */}
                        <div className="form-group form-group-sm">
                            <strong className="text-blue">

                                {/* For editing a driver */}
                                {typeOfForm != "vehiclesTaken" ?
                                    "Please provide the address where this driver resides."
                                    :
                                    "What is the new address?"
                                }
                            </strong>
                        </div>
                        <div className="form-group form-group-sm">
                            <input type={"text"} className="form-control" placeholder="Address" name="OtherAddress" maxLength={200}
                                value={residenceAddress}
                                onChange={(e) => { callback("residenceAddress", e.target.value) }}
                            />
                        </div>
                        <div className="row form-group form-group-sm">
                            <div className="col-xl-5 my-1">
                                <input type={"text"} className="form-control" placeholder="City" name="OtherCity" maxLength={100}
                                    value={residenceCity}
                                    onChange={(e) => { callback("residenceCity", e.target.value) }}
                                />
                            </div>
                            <div className="col-xl-4">
                                <div className="btn-group bootstrap-select custom-selector">
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                        value={residenceState}
                                        onChange={(e) => { callback("residenceState", e.target.value) }}
                                    >
                                        {StateDropdown()}
                                    </select>
                                </div>
                            </div>
                            <div className="col-xl-3 my-1">
                                <input type={"text"} className="form-control" placeholder="Zip" name="OtherZip" maxLength={15}
                                    value={residenceZip}
                                    onChange={(e) => { callback("residenceZip", e.target.value) }}
                                />
                            </div>
                        </div>

                        {/* Vehicles on the policy */}
                        {typeOfForm != "vehiclesTaken" && context?.userData?.state == "Michigan" &&
                            <VehicleSelections type={"residence"} typeOfVehicle={residenceVehicles} typeOfVehicleVINs={residenceVehicleVINs} setVehicleToDriver={setVehicleToDriver}
                                changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} />
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default NotSameHousehold