import { useContext } from "react"
import { HouseholdChangeContext } from "../HouseholdChangeContext"
import HouseholdChangeBtnNavigation from "../HouseholdChangeBtnNavigation"
import ValidationModal from "components/ValidationModal"
import Summary_PhoneNumber from "components/ChangeRequestSharedComp/Summary_PhoneNumber"
import HouseholdSummary_Changes from "./HouseholdSummary_Changes"

const HouseholdSummaryMain = () => {
    const context = useContext(HouseholdChangeContext)

    return (
        <>
            <div className="row text-mute">
                <div className="col-12">
                    <HouseholdSummary_Changes />
                    <Summary_PhoneNumber changeReqContext={HouseholdChangeContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
                </div>
            </div>

            <HouseholdChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default HouseholdSummaryMain