import Menu from "../components/Menu";
import PaymentHistSect from "../components/PaymentHistSect";
import RoundyContainer from "../components/RoundyContainer";
import CurrentBillSect from "../components/CurrentBillSect";
import { getAllPolicyDocs, getInstallmentBill, getPaperlessBillHistory, getPaymentHistory, getPaymentPdf } from "../services/Api";

const Content = (props) => {
  const { paymentData } = props.payment
  return (
    <>
      <div className="row text-muted">
        <div className="col-12 col-md-7">
          <PaymentHistSect paymentData={paymentData} />
        </div>
        <div className="col-12 col-md-5" >
          <CurrentBillSect paymentData={paymentData} />
        </div>
      </div>
    </>
  )
}

const PaymentHistory = (props) => {
  console.log("Payment History Screen:", props)
  const { userData, setUserData } = props.user

  const { paymentData, setPaymentData } = props.payment


  const setupPayment = async () => {
    //Table data
    var paymentHist = await getPaymentHistory(userData.policyNumber, userData.env)

    //Pdf download
    var paymentPdf = await getInstallmentBill(userData.policyNumber, userData.systemId, userData.env)

    setPaymentData({ ...paymentData, paymentHist, paymentPdf: paymentPdf.data, paymentDoc:paymentPdf.result , complete: true})
  }
  if (!paymentData.complete) {
    setupPayment()
  }

  const content = () => { return (<Content payment={props.payment} />) }
  return (
    (!Object.keys(paymentData).length == 0) &&
    <>
      <Menu Title={"PAYMENT HISTORY"} FirstName={userData.firstName} userData={userData} />
      <RoundyContainer params={props} Content={content} />
    </>
  );
};



export default PaymentHistory;