import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { AddressChangeContextComp } from "components/ChangeRequest_AddressChangeComp/AddressChangeContext"
import AddressProgressBarSect from "components/ChangeRequest_AddressChangeComp/AddressProgressBarSect"
import AddressSummaryMain from "components/ChangeRequest_AddressChangeComp/5_Summary/AddressSummaryMain"
import { useEffect } from "react"

const Summary = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <AddressChangeContextComp>
            <Menu Title={"ADDRESS CHANGE"} FirstName={userData.firstName} userData={userData} />
            <AddressProgressBarSect screen={"Summary"} />
            <RoundyContainer Content={AddressSummaryMain} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </AddressChangeContextComp>
        </>
    )
}

export default Summary