import { useState } from "react"
import { getIdCards } from "../services/Api"
import { Spinner } from 'react-bootstrap';


const IdCardTable = (props) => {
    const { systemId, env, policyNumber, idCardData, state, token } = props.userData
    const [idCardPdfs, setIdCardPdfs] = useState({ results: [], complete: false })

    const getIdCardsPdf = async () => {
        var idList = idCardData.map((value) => {
            return value.id
        })
        console.log(systemId, policyNumber, idList, env)
        var idCardPdfs = await getIdCards(systemId, policyNumber, idList, env)
        if (idCardPdfs.error == null) {
            setIdCardPdfs({ results: idCardPdfs.data, complete: true })
        }
    }
    if (!idCardPdfs.complete) {
        getIdCardsPdf()
    }
    const PDFAlert = () => {

        if (state != "Michigan")
            alert(`${state} accepts electronic ID Cards as valid proof of insurance. Other states may not accept electronic ID Cards as proof.`)
    }
    return (
        <>
            <div className="grad-window p-2 pb-0 col-12 col-sm-10 m-2 table-responsive">
                <table className="table m-0 text-muted">
                    <tbody>
                        <tr>
                            <th className="col-2">Year</th>
                            <th className="col-3">Make</th>
                            <th className="col-3">Model</th>
                            <th className="col-4">Download/Print</th>
                        </tr>
                        {

                            (idCardPdfs.complete) ?
                                idCardData.map((value, index) => {
                                    return (
                                        <tr className={"text-muted " + ((!(index % 2)) ? "table-light" : "")} key={index}>
                                            <td className="col-2">{value.modelyr}</td>
                                            <td className="col-3">{value.manufacturer}</td>
                                            <td className="col-3">{value.model}</td>
                                            <td className="col-4"><a onClick={PDFAlert} download={`${value.itemname}.pdf`} href={`data:application/octet-stream;base64,${idCardPdfs.results[index]}`}>Download/Print</a></td>
                                        </tr>
                                    )
                                }) :
                                <>
                                    <tr className={"text-muted table-light"}>
                                        <td colSpan={4} align='center'>
                                            <Spinner animation="border" />
                                        </td>
                                    </tr>
                                </>
                        }


                    </tbody>
                </table>
            </div>
        </>
    )
}

export default IdCardTable