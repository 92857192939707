import React, { useContext, useState } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleAddMain from "./VehicleAdd/VehicleAddMain"
import VehiclesStored from "./VehiclesStored"
import VehiclesAdded from "./VehiclesAdded"
import VehicleAddMainMI from "./VehicleAddMichigan/VehicleAddMainMI"
import { storageData } from "../../../services/Services"
import VehicleValidation_MI from "./VehicleValidation_MI"
import { useNavigate } from "react-router-dom"
import VehiclesPending from "./VehiclesPending"

export const StoredAddedVehicles = React.createContext()

const getTodaysDate = (addOne) => {
    // If adding a day --> seconds * minutes * hours * milliseconds
    var today = (addOne) ? new Date(new Date().getTime() + (60 * 60 * 24 * 1000)) : new Date()
    var yyyy = today.getFullYear()
    var mm = today.getMonth() + 1
    var dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    return mm + '/' + dd + '/' + yyyy
}

const VehiclesList = () => {
    const context = useContext(VehicleChangeContext)
    const [editType, setEditType] = useState("")
    const [displayVehicle, setDisplayVehicle] = useState([])
    const [editStoredVehicle, setEditStoredVehicle] = useState([])
    const [editAddedVehicleArr, setEditAddedVehicleArr] = useState([])
    const [vehicleObtained, setVehicleObtained] = useState(false)
    const navigate = useNavigate()

    // Toggles remove/replace menu for specified vehicle
    const setEditOption = (value, index) => {
        var editArr = context.vehiclesList.map((value, i) => {
            return (index === i) ? true : false
        });
        setEditStoredVehicle(editArr)
        setDisplayVehicle(editArr)
        setEditType(value)
        context.setShowQuestions(false)
    }

    // Save Edited Stored Vehicle
    const saveEditOption = (button, index, type) => {
        if (button == "save") {
            // Validation check
            var { title, message, valid } = VehicleValidation_MI({ button, index, type, vehicle: context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles[index] })
            if (!valid) {
                context.setValidationMsgToggle(title, message)
                return
            }
            // Saves vehicle
            var storedVehiclesCopy = context.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles
            if (type == "remove") { // Removed vehicle
                storedVehiclesCopy[index]["type"] = type
                context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles[index] = storedVehiclesCopy[index]
                context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles = storedVehiclesCopy
                context.setVehicleChangeClass({ ...context.vehicleChangeClass })
                storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))

                // Directly move onto the Summary page if removal is CANCEL POLICY 
                if (storedVehiclesCopy[index]?.vehicleRemovalReason == "CancelPolicy") {
                    // Adds fields of policy and mailing address to class
                    context.vehicleChangeClass.vehicles["keptOvernightAddress"] = context?.currentResidence?.address1
                    context.vehicleChangeClass.vehicles["keptOvernightCity"] = context?.currentResidence?.city
                    context.vehicleChangeClass.vehicles["keptOvernightState"] = context?.currentResidence?.state
                    context.vehicleChangeClass.vehicles["keptOvernightZip"] = context?.currentResidence?.zip
                    context.vehicleChangeClass.vehicles["isPolicyMailingAddr"] = storedVehiclesCopy[index]?.currentResidenceResponse
                    context.vehicleChangeClass.vehicles["requestedEffDt"] = storedVehiclesCopy[index]?.requestedEffDt
                    context.vehicleChangeClass.vehicles["mailingAddress"] = storedVehiclesCopy[index]?.currentResidenceAddress
                    context.vehicleChangeClass.vehicles["mailingCity"] = storedVehiclesCopy[index]?.currentResidenceCity
                    context.vehicleChangeClass.vehicles["mailingState"] = storedVehiclesCopy[index]?.currentResidenceState
                    context.vehicleChangeClass.vehicles["mailingZip"] = storedVehiclesCopy[index]?.currentResidenceZip
                    context.setVehicleChangeClass({ ...context.vehicleChangeClass })
                    storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
                    navigate("/update-vehicles/summary")
                }
            }
            else if (type == "modify") { // Modified vehicle
                var vehicleChangeClassCopy = context.vehicleChangeClass
                var storedVehiclesCopy = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles
                storedVehiclesCopy[index]["type"] = type
                vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = storedVehiclesCopy
                context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
                storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
            }
        }
        // Saves values back to original when canceling edit
        else if (button == "cancel" && (type == "modify" || type == "remove")) {
            var storedVehiclesCopy = context.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles
            if (storedVehiclesCopy[index]?.vehicleRemovalReason == "CancelPolicy") { // For "cancel policy" reason
                context.setCancelPolicyAddVeh(true)
                clear(storedVehiclesCopy[index], index)
            }

            var vehClass = JSON.parse(storageData.getItem("VehicleChangeClass"))
            context.setVehicleChangeClass({ ...vehClass })
        }
        setEditStoredVehicle([])
        setDisplayVehicle([])
        setEditType("")
        context.setShowQuestions(true)
    }

    // Submitted Changes
    const submittedVehicleChanges = () => {
        context.setAddVehicleMenu(false)
        context.setShowQuestions(true)
        setVehicleObtained(false)
    }

    // Modifications for Financing a Vehicle (ONLY MICHIGAN)
    const [vehicleFinanceInfo, setVehicleFinanceInfo] = useState({
        vehicleFinanceLeasingCompanyResponse: "",
        vehicleFinanceLeasingCompanyName: "",
        vehicleLoanNumber: "",
        requestedEffDtFinancedLeased: getTodaysDate(true),
        vehicleCoverageUpdate: "",
        requestedEffDtVehicleCoverage: getTodaysDate(true),
        standardDeductibleComprehensive: "",
        collisionCoverageType: "",
        standardDeductibleCollision: "",
        vehicleFinanceCompany: "",
        rentalReimbursement: "",
        otherThanCollisionToggle: "",
        collisionCoverageToggle: ""
    })

    // Removes replaced vehicle
    const clear = (vehicle, index) => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var oldVehicleReset = {}
        oldVehicleReset["Year"] = vehicle["Year"]
        oldVehicleReset["Make"] = vehicle["Make"]
        oldVehicleReset["Model"] = vehicle["Model"]
        oldVehicleReset["VIN"] = vehicle["VIN"]
        vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles[index] = oldVehicleReset
        storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
        context.setCancelPolicyAddVeh(false)
        submittedVehicleChanges()
    }

    return (
        <>
            {/* Subtitle */}
            <h6 className="text-center household-text-blue fw-bold mt-3">
                VEHICLES
            </h6>

            {/* Vehicles */}
            <div className="row justify-content-center">
                <div className="col-xxl-8 col-xl-10 col-lg-11 col-md-10">

                    <StoredAddedVehicles.Provider
                        value={{
                            editType, setEditType,
                            displayVehicle, setDisplayVehicle,
                            editStoredVehicle, setEditStoredVehicle,
                            editAddedVehicleArr, setEditAddedVehicleArr,
                            vehicleObtained, setVehicleObtained,
                            setEditOption, saveEditOption,
                            submittedVehicleChanges,
                            vehicleFinanceInfo, setVehicleFinanceInfo,
                            getTodaysDate
                        }}
                    >
                        {/* Stored Vehicles */}
                        <VehiclesStored />

                        {/* Pending Vehicles */}
                        <VehiclesPending />

                        {/* Added Vehicles */}
                        <VehiclesAdded />
                    </StoredAddedVehicles.Provider>
                    <hr className={`less-margin expandHeight ${(editType != "" || editAddedVehicleArr.length != 0 || context.addVehicleMenu) ? "hide" : ""}`} />

                    {/* Add New Vehicle Button */}
                    {/* <div className={`slide-toggle text-center mt-4 ${(editStoredVehicle.length != 0 || editAddedVehicleArr.length != 0 || context.addVehicleMenu || context.cancelPolicyAddVeh) ? "hide" : ""}`}>
                        <button type="button" className="btn btn-primary-grad editCancelBtn" onClick={() => context.setAddVehicleMenu(true)}>
                            Add New Vehicle
                        </button>
                    </div> */}

                    {/* Adding a Vehicle Menu */}
                    {context.addVehicleMenu &&
                        <StoredAddedVehicles.Provider
                            value={{
                                editType, setEditType,
                                displayVehicle, setDisplayVehicle,
                                editStoredVehicle, setEditStoredVehicle,
                                vehicleObtained, setVehicleObtained,
                                setEditOption, saveEditOption,
                                submittedVehicleChanges,
                                vehicleFinanceInfo, setVehicleFinanceInfo,
                                getTodaysDate
                            }}
                        >
                            {/* New Jersey/Pennsylvania */}
                            {context.userData.state != "Michigan" &&
                                <VehicleAddMain index={context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles.length} type={"add"} />
                            }

                            {/* Michigan */}
                            {context.userData.state == "Michigan" &&
                                <VehicleAddMainMI index={context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles.length} type={"add"} />
                            }


                        </StoredAddedVehicles.Provider>
                    }

                </div>
            </div>
        </>
    )
}

export default VehiclesList