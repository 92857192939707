import Menu from "../components/Menu";
import RoundyContainer from "../components/RoundyContainer";
import IdCardTable from "../components/IdCardTable";
import { Link } from "react-router-dom";

const Content = (props) => {
    const { state } = props.user.userData

    console.log("PolicyIdCards Component:", props)
    return (
        <>
            <div className="row text-muted">
                <div className="col-12 ">
                    <div className="d-flex justify-content-center flex-column">
                        <h4 className="text-center section-title">
                            STATE OF {state.toUpperCase()} ID CARDS
                        </h4>
                        <strong className="text-center"><small>Select the vehicle(s) for which you would like a replacement ID card.</small></strong>
                        <div className="d-flex justify-content-center pb-4">
                            <IdCardTable userData={props.user.userData} />
                        </div>
                        <small className="text-center">Make sure your pop up blocker is turned off</small>

                        <h6 className="text-center section-title m-4"> 1(800)535-2873</h6>

                        <small className="text-center">Monday-Friday: 8:00 AM - 7:00 PM<br />Saturday: 9:30 AM - 4:00 PM</small>
                        <div className="d-flex justify-content-center m-2">
                            <Link to={"/digital-id-card"} className="btn btn-primary-grad text-light">Digital ID Card</Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

const PolicyIdCards = (props) => {
    const { userData } = props.user
    return (
        <>
            <Menu Title={"POLICY ID CARDS"} FirstName={userData.firstName} userData={userData} />
            <RoundyContainer params={props} Content={Content} />
        </>
    );
};

export default PolicyIdCards;