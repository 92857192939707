import React, { useContext, useRef, useState } from "react";
import { FormAuto, states } from "../..";
import ValidationModal from "../../../ValidationModal";
import { storageData } from "services/Services";
import EditMemberValidationMI from "components/ChangeRequest_AddressChangeComp/ValidationConditions/EditMemberValidationMI";

const EditMemberMI = (props) => {
    var { memberList, member, index, saveResponse, changeReqContext, reqChangeClass, setChangeClass } = props
    const context = useContext(changeReqContext)
    var changeClass = context[reqChangeClass]
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"

    const [state, setState] = useState({
        edithhm: "",
        edithhmNested: "",
        effectiveDate: context?.getTodaysDate(true),
        moveOutDate: "",
        licenseState: "",
        licenseNumber: "",
        licenseAge: "",
        disorder: "",
        impairments: "",
        college: "",
        ...member
    })

    const validationMsg = useRef("")
    const validationTitle = useRef("")

    const callback = (key, value) => {
        var info = { ...state };
        info[key] = value;
        setState({ ...info })
    }

    // Validation Modal
    const handleClose = () => setState({ ...state, show: false });
    const handleShow = () => setState({ ...state, show: true });

    // Sets Validation Message
    const handleValidationMsg = (msg, title) => {
        validationMsg.current = msg
        validationTitle.current = title
    }

    // Saves options for edited member
    const saveEditOptions = (e) => {
        var editedMember = state;
        if ((e.target.value) === "SAVE") {

            var check = EditMemberValidationMI({ editFields: editedMember, handleValidationMsg, handleShow, userData: context.userData });
            if (check) {
                // Sets status of member depending on edits
                editedMember = setEditedStatus(state)

                var changeClassCopy = changeClass
                var storedMemberListContextCopy = changeClassCopy?.driversHHMs?.householdMembers?.storedMembers

                // Not adding member as driver to policy
                if (editedMember?.memberStatus != "Adding As Driver") {
                    // Already added as driver (saving change on edit)
                    if (saveResponse) {
                        if (editedMember?.memberStatus != "Adding As Driver") {
                            // Adds back to stored member list
                            storedMemberListContextCopy.push(editedMember)
                            changeClassCopy.driversHHMs.householdMembers.storedMembers = storedMemberListContextCopy

                            // Removes from stored driver list
                            var storedDriverListContextCopy = changeClassCopy?.driversHHMs?.drivers?.storedDrivers
                            storedDriverListContextCopy = storedDriverListContextCopy.filter((driver) => driver != storedDriverListContextCopy[index])
                            changeClassCopy.driversHHMs.drivers.storedDrivers = storedDriverListContextCopy
                        }
                        else {
                            storedDriverListContextCopy[index] = state
                        }
                    }
                    else {
                        storedMemberListContextCopy[index] = state;
                    }
                }
                // Converting member to driver on policy
                else {
                    var storedDriverListContextCopy = changeClassCopy?.driversHHMs?.drivers?.storedDrivers
                    // Already added as driver (saving change on edit)
                    if (saveResponse) {
                        storedDriverListContextCopy[index] = state
                    }
                    // Pushes to driver list and removes from members list
                    else {
                        // Pushes to driver list and removes from members list
                        storedDriverListContextCopy.push(editedMember)
                        storedMemberListContextCopy = storedMemberListContextCopy.filter((member) => member != storedMemberListContextCopy[index])
                        changeClassCopy.driversHHMs.householdMembers.storedMembers = storedMemberListContextCopy
                    }
                }

                setChangeClass(changeClassCopy)
                storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
                context?.setShowMemberEditMenu(false)
                context?.setShowDriverEditMenu(false);
                context?.setEditStoredMemberArr([])
                context?.setEditStoredDriverArr([])
            }
            else {
                return
            }
        }
        else {
            context?.setShowMemberEditMenu(false);
            context?.setShowDriverEditMenu(false);
            context?.setEditStoredMemberArr([])
            context?.setEditStoredDriverArr([])
        }
    };

    const setEditedStatus = (member) => {
        // Resides elsewhere
        if (member?.edithhm == "0") {
            member.memberStatus = "Resides Elsewhere"
        }
        // Has own insurance
        else if (member?.edithhm == "1" && member?.edithhmNested == "1") {
            member.memberStatus = "Has Own Insurance"
        }
        // Adding driver to policy
        else if (member?.edithhm == "1" && member?.edithhmNested == "0") {
            member.memberStatus = "Adding As Driver"
            member["driverStatus"] = "Adding As Driver"
            member["editType"] = "Adding As Driver"
        }
        // Reset member status
        else {
            member.memberStatus = ""
        }
        return member
    }

    var { edithhm, edithhmNested, effectiveDate, moveOutDate, licenseState, licenseNumber, licenseAge, disorder, impairments, college } = state
    return (
        <>
            {/* Edit Form */}
            <div className="slide-toggle edit-section form-group">
                <div className="editMember form-group form-group-sm">

                    {/* No Longer in Household */}
                    <FormAuto
                        form={
                            [
                                {
                                    id: "edithhm",
                                    value: edithhm,
                                    type: "radio",
                                    label: "What would you like to edit about this household member?*",
                                    nested: {
                                        id: "edithhmNested",
                                        value: edithhmNested
                                    },
                                    options: [
                                        { value: "No longer resides in household.", id: "0" },
                                        {
                                            value: "This person now has a Driver's License.", id: "1",
                                            nested: [
                                                { value: "Add as a driver on to policy.", id: "0" },
                                                { value: "This person has their own car insurance.", id: "1" },
                                            ]
                                        }
                                    ],
                                    className: {
                                        margin: "my-0",
                                        marginBottom: "mb-2"
                                    },
                                    callback
                                },
                                (edithhm == "0") &&
                                {
                                    id: "moveOutDate",
                                    type: "dateTextMask",
                                    label: "When did this person move out of the household?*",
                                    value: moveOutDate,
                                    noMinMax: true,
                                    col: {
                                        label: { xl: 12 },
                                        input: { xl: 8 }
                                    },
                                    className: {
                                        label: "my-1",
                                        input: "my-1",
                                        datePicker: "col-12"
                                    },
                                    callback
                                },
                                {
                                    id: "effectiveDate",
                                    type: "dateTextMask",
                                    label: "Requested Effective Date*",
                                    col: {
                                        label: { xl: 4 },
                                        input: { xl: 6 },
                                        caret: false
                                    },
                                    value: effectiveDate,
                                    callback
                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "licenseState",
                                    type: "select",
                                    label: "In what state is this driver licensed?*",
                                    value: licenseState,
                                    defaultValue: "Michigan",
                                    options: states,
                                    col: {
                                        label: { xl: 4 },
                                        input: { xl: 6 },
                                        caret: false
                                    },
                                    callback
                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "licenseNumber",
                                    type: "text",
                                    label: "License Number:*",
                                    value: licenseNumber,
                                    callback
                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "licenseAge",
                                    type: "text",
                                    inputType: "number",
                                    maxLength: 2,
                                    label: "What age was this driver first licensed?*",
                                    value: licenseAge,
                                    callback: (key, value) => { callback(key, (value > 99) ? 99 : value) }
                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "disorder",
                                    type: "radio",
                                    label: "Does this driver have a disorder or condition that may impair their ability to safely operate a motor vehicle, including but not limited to Narcolepsy, Syncope, Blindness or Seizures?*",
                                    col: {
                                        label: { xs: 12 },
                                        input: { xs: 2 },
                                    },
                                    value: disorder,
                                    options: [
                                        { value: "Yes", id: "1" },
                                        { value: "No", id: "0" },

                                    ],
                                    callback
                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "impairments",
                                    type: "radio",
                                    label: "Does this driver have any mental or physical impairments, other than listed above, that would impair their ability to operate a motor vehicle safely? (Not including prescription eyeglasses or contact lenses.)*",
                                    value: impairments,
                                    col: {
                                        label: { xs: 12 }, input: { xs: 2 },
                                    },
                                    options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                    callback

                                },
                                (edithhm == "1") && (edithhmNested == "0") &&
                                {
                                    id: "college",
                                    type: "radio",
                                    label: "Is this driver enrolled in college or university?*",
                                    value: college,
                                    col: {
                                        label: { lg: 6 },
                                        input: { xs: 2 },
                                    },
                                    caret: false,
                                    options: [
                                        { value: "Yes", id: "1" },
                                        { value: "No", id: "0" },
                                    ],
                                    callback
                                }
                            ]
                        }
                    />
                </div>

                {/* Cancel/Save Function */}
                <hr className="less-margin my-3" />
                <div className="text-right my-3" >
                    <input type={"button"} className="btn btn-default btn-sm editCancelBtn" value={"CANCEL"} onClick={saveEditOptions} />
                    <input type={"button"} className="btn btn-primary-grad btn-sm ms-1 editCancelBtn" value={"SAVE"} onClick={saveEditOptions} />
                </div>

                <ValidationModal
                    show={state.show}
                    handleClose={handleClose}
                    validationMsg={validationMsg.current}
                    title={validationTitle.current} />
            </div>
        </>
    )
}

export default EditMemberMI