import { useContext } from "react"
import StateDropDown from "../../../RenewalQuestionnaireComp/DriversOHM/StateDropdown"
import PossessionVehicleDropdown from "../../VehicleDropdowns/PossessionVehicleDropdown"
import NameRegisteredDropdown from "../../VehicleDropdowns/NameRegisteredDropdown"
import VehicleLeasingList from "../../VehicleDropdowns/VehicleLeasingList"
import VehicleFinanceList from "../../VehicleDropdowns/VehicleFinanceList"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { AddVehicleContext } from "./VehicleAddMain"
import { StoredAddedVehicles } from "../VehiclesList"

const VehicleAdd_4 = () => {
    const context = useContext(VehicleChangeContext)
    const vehicleAddContext = useContext(AddVehicleContext)
    const storedAddedContext = useContext(StoredAddedVehicles)

    return (
        <>
            {!context.vinLoad && storedAddedContext.vehicleObtained &&
                <>
                    {/* State Registered For Vehicle */}
                    <div className="form-group">
                        <div className="form-group-sm text-primary mb-2">
                            <strong>In what state is the vehicle registered?</strong>
                        </div>
                        <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                value={vehicleAddContext.newVehicle.stateRegistered}
                                onChange={(e) => vehicleAddContext.saveAddition("stateRegistered", e.target.value)}
                            >
                                {StateDropDown()}
                            </select>
                        </div>
                    </div>

                    {/* Vehicle Owned/Leased/Financed */}
                    <div className="form-group">
                        <div className="form-group-sm text-primary mb-2">
                            <strong>
                                Is this vehicle owned, leased, or financed? *
                            </strong>
                        </div>
                        <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                value={vehicleAddContext.newVehicle.vehicleOwnedLeasedFinanced}
                                onChange={(e) => vehicleAddContext.saveAddition("vehicleOwnedLeasedFinanced", e.target.value)}
                            >
                                {PossessionVehicleDropdown()}
                            </select>
                        </div>

                        {/* Vehicle Co Owned */}
                        {vehicleAddContext.newVehicle.vehicleOwnedLeasedFinanced !== "0" && vehicleAddContext.newVehicle.vehicleOwnedLeasedFinanced !== "" &&
                            <div className="form-group-sm text-primary mt-3 ms-4">
                                <strong>Is this vehicle {vehicleAddContext.newVehicle?.vehicleOwnedLeasedFinanced == "24570" ? "Co-Leased" : "Co-Owned"}?</strong>
                                <div className="form-group form-group-sm text-dark ms-3 mt-3">
                                    <div className="radio-inline form-check form-check-inline ms-2">
                                        <label className="form-check-label">
                                            <input type={"radio"} className="form-check-input" name="coOwned" value={"1"}
                                                checked={(vehicleAddContext.newVehicle.coOwned === "1") ? vehicleAddContext.newVehicle.coOwned : ""}
                                                onChange={(e) => vehicleAddContext.saveAddition("coOwned", e.target.value)}
                                            />Yes
                                        </label>
                                    </div>
                                    <div className="radio-inline form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input type={"radio"} className="form-check-input" name="coOwned" value={"0"}
                                                checked={(vehicleAddContext.newVehicle.coOwned === "0") ? vehicleAddContext.newVehicle.coOwned : ""}
                                                onChange={(e) => vehicleAddContext.saveAddition("coOwned", e.target.value)}
                                            />No
                                        </label>
                                    </div>
                                </div>

                                {/* Co Owned Person Info */}
                                {vehicleAddContext.newVehicle?.coOwned == "1" &&
                                    <div>
                                        <div className="form-group-sm text-primary mt-2 ms-4">
                                            <strong className="fst-italic">{vehicleAddContext.newVehicle?.vehicleOwnedLeasedFinanced == "24570" ? "Co-Lessee" : "Co-Owner"} Name & Address</strong>
                                            <div className="row">
                                                {/* Full Name */}
                                                <div className="form-group-spacer mt-2">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerName"
                                                        placeholder="Full Name..." maxLength={64}
                                                        value={vehicleAddContext?.newVehicle?.coOwnerName}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("coOwnerName", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {/* Address */}
                                                <div className="form-group-spacer mt-2">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerAddress"
                                                        placeholder="Address..." maxLength={32}
                                                        value={vehicleAddContext?.newVehicle?.coOwnerAddress}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("coOwnerAddress", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group mt-2">
                                                {/* City */}
                                                <div className="col-xs-5 col-lg-5 mt-1">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerCity"
                                                        placeholder="City..." maxLength={64}
                                                        value={vehicleAddContext?.newVehicle?.coOwnerCity}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("coOwnerCity", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {/* State */}
                                                <div className="col-xs-4 col-lg-4">
                                                    <div className="btn-group bootstrap-select custom-selector" style={{ width: "100%" }}>
                                                        <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                            value={vehicleAddContext?.newVehicle?.coOwnerState}
                                                            onChange={(e) => vehicleAddContext.saveAddition("coOwnerState", e.target.value)}
                                                        >
                                                            {StateDropDown()}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* Zip */}
                                                <div className="col-xs-3 col-lg-3 mt-1">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerZip"
                                                        placeholder="Zip..." maxLength={15}
                                                        value={vehicleAddContext?.newVehicle?.coOwnerZip}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("coOwnerZip", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* Vehicle Registered to Person Dropdown */}
                                <div className="form-group-sm text-primary">
                                    <strong>In what name is (will) the vehicle be registered?</strong>
                                </div>
                                <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                        value={vehicleAddContext.newVehicle.nameRegistered}
                                        onChange={(e) => vehicleAddContext.saveAddition("nameRegistered", e.target.value)}
                                    >
                                        {NameRegisteredDropdown()}
                                    </select>
                                </div>

                                {/* Registrant Person Info */}
                                {vehicleAddContext.newVehicle.nameRegistered == "43095" &&
                                    <div>
                                        <div className="form-group-sm text-primary mt-3 ms-4">
                                            <strong>Registrant Information</strong>
                                            <div className="row mt-2">
                                                {/* Registrant Name */}
                                                <div className="form-group-spacer">
                                                    <input type={"text"} className="form-control" name="VehicleRegistrantName"
                                                        placeholder="Name as listed on vehicle registration..." size={20} maxLength={200}
                                                        value={vehicleAddContext?.newVehicle?.registrantName}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("registrantName", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {/* Registrant Address */}
                                                <div className="form-group-spacer mt-2">
                                                    <input type={"text"} className="form-control" name="VehicleRegistrantAddress"
                                                        placeholder="Address" maxLength={32}
                                                        value={vehicleAddContext?.newVehicle?.registrantAddress}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("registrantAddress", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group mt-2">
                                                {/* Registrant City */}
                                                <div className="col-xs-5 col-lg-5 mt-1">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerCity"
                                                        placeholder="City..." maxLength={64}
                                                        value={vehicleAddContext?.newVehicle?.registrantCity}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("registrantCity", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {/* Registrant State */}
                                                <div className="col-xs-4 col-lg-4">
                                                    <div className="btn-group bootstrap-select custom-selector" style={{ width: "100%" }}>
                                                        <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                            value={vehicleAddContext?.newVehicle?.registrantState}
                                                            onChange={(e) => vehicleAddContext.saveAddition("registrantState", e.target.value)}
                                                        >
                                                            {StateDropDown()}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* Registrant Zip */}
                                                <div className="col-xs-3 col-lg-3 mt-1">
                                                    <input type={"text"} className="form-control" name="VehicleCoOwnerZip"
                                                        placeholder="Zip..." maxLength={15}
                                                        value={vehicleAddContext?.newVehicle?.registrantZip}
                                                        onChange={(e) => {
                                                            vehicleAddContext.saveAddition("registrantZip", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* Leasing List */}
                                {vehicleAddContext.newVehicle.vehicleOwnedLeasedFinanced === "24570" &&
                                    <div className="ms-4 mt-3">
                                        <div className="form-group-sm text-primary">
                                            <strong>Leasing Company Name:</strong>
                                        </div>
                                        <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                value={vehicleAddContext.newVehicle?.vehicleLeasingCompany}
                                                onChange={(e) => vehicleAddContext.saveAddition("vehicleLeasingCompany", e.target.value)}
                                            >
                                                <option value={""} disabled>Select from list</option>
                                                {VehicleLeasingList(context?.userData?.state)}
                                            </select>
                                        </div>
                                    </div>
                                }

                                {/* Finance Company List */}
                                {vehicleAddContext.newVehicle.vehicleOwnedLeasedFinanced === "24670" &&
                                    <div className="ms-4 mt-3">
                                        <div className="form-group-sm text-primary">
                                            <strong>Financing Company Name:</strong>
                                        </div>
                                        <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                                value={vehicleAddContext.newVehicle?.vehicleFinanceCompany}
                                                onChange={(e) => vehicleAddContext.saveAddition("vehicleFinanceCompany", e.target.value)}
                                            >
                                                <option value={""} disabled>Select from list</option>
                                                {VehicleFinanceList(context?.userData?.state)}
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {/* Premier Roadside Assistance */}
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 text-center-xs text-left-sm">
                            <strong className="text-primary">PREMIER ROADSIDE ASSISTANCE</strong>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 mt-2 text-muted" style={{ fontSize: '14px' }}>
                        <p>When you need roadside assistance, simply call the toll-free number and a service provider will be dispatched to you for the following services:</p>
                        <ul className="unorderedList">
                            <li>Emergency Towing</li>
                            <li>Battery Jump Start</li>
                            <li>Flat Tire Change</li>
                            <li>Fuel Delivery</li>
                            <li>Lockout Service</li>
                        </ul>
                        <p>
                            Your Premier Roadside Assistance covers up to $125 per incident.
                            <a target="_blank" href="https://myaccountstg.cure.com/PDFVersions/PA/PAC-RAC%2001-17.pdf"><strong> Click here </strong></a>
                            to see all of the additional benefits included with your Premier Roadside Assistance package.
                        </p>
                    </div>
                </>
            }
        </>
    )
}

export default VehicleAdd_4