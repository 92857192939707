import { useState } from "react"
import { storageData } from "../../../services/Services"
import ValidationDriver from "./ValidationDriver"

const EditDriver = (props) => {
    const [edit, setEdit] = useState(
        !("editType" in props.currDriver) ?
            {
                editType: "",
                editReason: "",
                displayChange: ""
            }
            :
            {
                editType: props.currDriver.editType,
                editReason: props.currDriver.editReason,
                displayChange: props.currDriver.displayChange,
            }
    )

    // For displaying the type of change on front-end
    const displayEditChange = (label) => {
        var editChange = {
            "MOVED": "Resides Elsewhere",
            "INSURANCE": "Has Other Insurance",
            "SUSPENDED": "License Suspended",
            "REVOKED": "License Revoked"
        }
        return editChange[label]
    }

    // Saves Edit Change to Driver
    const saveEdit = async () => {
        // Checks Validation
        var validationResponse = await ValidationDriver({
            edit,
            setShow: props.setShow,
            setValidationMsg: props.setValidationMsg,
            handleClose: props.handleClose,
            handleShow: props.handleShow,
            setValidationLoad: props.setValidationLoad,
            setValidationTitle: props.setValidationTitle
        })
        // Clears validation
        if (validationResponse) {
            var sessionDataCopy = props.sessionData
            var currDriver = props.currDriver
            currDriver = { ...currDriver, ...edit }
            sessionDataCopy.household.drivers.storedDrivers[props.index] = currDriver
            props.setEditExistingDriverMenu([])
            props.setSessionData(sessionDataCopy)
            storageData.setItem("sessionDataRQ", JSON.stringify(props.sessionData))
        }
    }

    // Onchange update function
    const updateEdit = (key, value) => {
        var editCopy = edit
        if (key == "editType") {
            editCopy["editReason"] = ""
        }
        if (key == "editReason") {
            editCopy["displayChange"] = displayEditChange(value)
        }
        editCopy[key] = value
        setEdit({ ...edit })
    }

    return (
        <>
            <div className="row mx-4">
                <div className="col-12 col-lg-4 ">
                    <p>What would you like to edit about this person?</p>
                </div>

                <div className="col-12 col-lg-8 px-4 mb-2">

                    {/* No longer drivers policy vehicles */}
                    <div className="col-12 mb-1">
                        <label className="form-check-label" style={{ display: 'flex' }}>
                            <input className="form-check-input" defaultChecked={edit.editType == "NODRIVE"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="driverEditType"
                                value={"NODRIVE"} onChange={(e) => updateEdit('editType', e.target.value)} />
                            <span className="ms-2 fw-bold" style={{ color: '#366898' }}>No longer drives policy vehicles.</span>
                        </label>
                    </div>

                    {/* Nested Set 1/No longer drivers policy vehicles */}
                    {edit.editType == "NODRIVE" &&
                        <div className="col-12 ms-4 my-2">

                            {/* No Longer Resides in Household */}
                            <div className="col-12 mb-1">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "MOVED"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editNoDrive0"
                                        value={"MOVED"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>No longer resides in household and does not have access to the vehicles.</span>
                                </label>
                            </div>

                            {/* Different Insurance */}
                            <div className="col-12">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "INSURANCE"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editNoDrive0"
                                        value={"INSURANCE"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>Obtained own car and own insurance.</span>
                                </label>
                            </div>
                        </div>
                    }

                    {/* Invalid Driver's License */}
                    <div className="col-12">
                        <label className="form-check-label" style={{ display: 'flex' }}>
                            <input className="form-check-input" defaultChecked={edit.editType == "NOTVALID"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="driverEditType"
                                value={"NOTVALID"} onChange={(e) => updateEdit("editType", e.target.value)} />
                            <span className="ms-2 fw-bold" style={{ color: '#366898' }}>No longer has a valid Driver's License.</span>
                        </label>
                    </div>

                    {/* Nested Set 2/invalid driver's license */}
                    {edit.editType == "NOTVALID" &&
                        <div className="col-12 ms-4 mt-2">

                            {/* Suspended License */}
                            <div className="col-12 mb-1">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "SUSPENDED"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editNotValid0"
                                        value={"SUSPENDED"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>License was Suspended.</span>
                                </label>
                            </div>

                            {/* Revoked License */}
                            <div className="col-12">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "REVOKED"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editNotValid0"
                                        value={"REVOKED"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>License was Revoked.</span>
                                </label>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* Save/Cancel Stored Driver */}
            <div className="row justify-content-end p-3">
                <input type="submit" value={"Save"} className="saveButton" onClick={saveEdit} />
                <input type="submit" value={"Cancel"} className="cancelButton ms-3" onClick={() => props.setEditExistingDriverMenu([])} />
            </div>
        </>
    )
}

export default EditDriver