import React from "react";
import { gender } from "../../";

const Gender = (props) => {

    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                <div className="col-xl-4 fw-bold text-blue">
                    Gender:
                </div>
                <div className="col-xl-6">
                    <div className="btn-group bootstrap-select custom-selector">
                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                            defaultValue={props.gender}
                            onChange={(e) => { props.handleCallBack("gender", e.target.value) }}>
                            <option id="0" value={'Select from the following...'}>Gender</option>
                            <option id="1" value={'Female'}>Female</option>
                            <option id="2" value={'Male'}>Male</option>
                            {this?.props?.userData?.state == "New Jersey" &&
                                <option id="3" value={'Unspecified'}>Unspecified</option>
                            }
                        </select>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Gender