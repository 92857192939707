import DatePick_MI from 'components/DatePicker_MI'
import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import { NewVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext'
import React, { useContext, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { vinLookup } from 'services/Api'
import "./BodyVIN.css"

const BodyVIN = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    const newVehicleContext = useContext(NewVehicleContext)
    const [vinResponse, setVinResponse] = useState("")
    const vinRef = useRef(null)

    // Generates vehicle from vin
    const checkVin = async () => {
        addVehicleContext.setVinLoad(true)
        const checkVin = checkVinExists(vinRef.current.value)

        // New Vin
        if (checkVin) {
            setVinResponse("The vehicle related to the VIN you entered is already on your policy. If you have any questions, please live chat with us on cure.com or call 800-535-2873 to speak with a representative.")
            addVehicleContext.setVinLoad(false)
            return;
        }

        const specificVin = ["1C6RR7KG7DS661851"].includes(vinRef.current.value);
        if (specificVin) {
            setVinResponse("We are unable to verify the VIN that you entered. Please try again. If you continue to run into the same error, please call 800-535-2873 to proceed with your request.")
            addVehicleContext.setVinLoad(false)
            return;
        }

        var vinResponse = await vinLookup(vinRef.current.value)
        // Successful vin input
        if (!(vinResponse?.data?.error == null && vinResponse != undefined && vinResponse?.data?.isEmpty != "1" && parseInt(vinResponse?.data?.GrossVehicleWeight) < 10000)) {
            setVinResponse("We are unable to verify the VIN that you entered. Please try again. If you continue to run into the same error, please call 800-535-2873 to proceed with your request.")
            addVehicleContext.setVinLoad(false)
            return;
        }

        var vehicle = newVehicleContext.newVehicle
        vehicle.componentTracker = "VehicleInfo"
        vehicle.vehicleVIN = vinResponse.data?.Vin
        vehicle.vehicleYear = vinResponse.data?.Year
        vehicle.vehicleMake = vinResponse.data?.Make
        vehicle.vehicleModel = vinResponse.data?.Model
        vehicle.vehicleBody = vinResponse.data?.Body
        newVehicleContext.setNewVehicle({ ...newVehicleContext.newVehicle, ...vehicle })

        // Set the vehicle class with new vehicle under vehicles --> vehiclesOnPolicy --> newVehicles
        var addVehicleClassCopy = addVehicleContext.addVehicleClass
        addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles.push(vehicle)
        addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy })

        addVehicleContext.setVinLoad(false)
    }

    // Verifies that the vin entered doesn't already exist on policy
    const checkVinExists = (vin) => {
        var vehicles = addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.storedVehicles
        return vehicles.some(vehicle => vehicle.VIN.toLowerCase() == vin.toLowerCase());
    }

    return (
        <>
            {/* Vin Input */}
            {!addVehicleContext.vinLoad &&
                <div className='col-xl-12 justify-content-center' style={{ margin: "0 0.5rem", padding: "0 0.75rem" }}>
                    <div className="row form-group form-group-sm align-items-center my-3">
                        <div className="col-12 col-md-4 text-primary fw-bold mb-2 mb-md-0 fs-6">
                            VIN*
                        </div>
                        <div className="col-12 col-md-8 col-sm-8">
                            <div className="input-group">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="Enter your VIN..."
                                    style={{ fontSize: "15px" }}
                                    ref={vinRef}
                                />
                                <span className="input-group-btn">
                                    <input
                                        type="button"
                                        className="btn btn-default"
                                        value="SUBMIT"
                                        onClick={() => checkVin()}
                                        style={{ position: 'static', fontSize: "15px" }}
                                    />
                                </span>
                            </div>
                        </div>
                        {vinResponse != "" &&
                            <p className='text-danger text-center fw-bold fs-6 p-2'>
                                {vinResponse}
                            </p>
                        }
                    </div>
                </div>
            }

            {/* Spinner for retrieving vehicle info from vin */}
            {addVehicleContext.vinLoad &&
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            }
        </>
    )
}

export default BodyVIN