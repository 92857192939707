import StateDropdown from "../../../RenewalQuestionnaireComp/DriversOHM/StateDropdown";

const VehiclePersonInfo = ({ vehicleAddContext, type }) => {
    var personType =
        (type == "Co-Owner") ?
            "coOwner" :
            (type == "Co-Leasee") ?
                "coLeasee" : "registrant"

    return (
        <>
            {/* Full Name */}
            <div className="col-xl-4 col-sm-4 text-primary fw-bold" style={{ fontSize: '12px' }}>
                {type}'s full name:*
            </div>
            <div className="my-2 col-8">
                <input type={"text"} className="btn-group form-control"
                    placeholder={type + "'s full name"} maxLength={64}
                    value={vehicleAddContext?.newVehicle[personType + "Name"]}
                    onChange={(e) => {
                        vehicleAddContext.saveAddition(personType + "Name", e.target.value);
                    }}
                />
            </div>

            {/* Address */}
            <div className="col-xl-4 col-sm-4 text-primary fw-bold" style={{ fontSize: '12px' }}>
                Address of {type}:*
            </div>
            <div className="form-group-spacer mt-2 col-8">
                <input type={"text"} className="form-control"
                    placeholder="Street Address" maxLength={50}
                    value={vehicleAddContext?.newVehicle[personType + "Address"]}
                    onChange={(e) => {
                        vehicleAddContext.saveAddition(personType + "Address", e.target.value);
                    }}
                />
            </div>
            {/* City */}
            <div className="form-group-spacer mt-2 col-8">
                <input type={"text"} className="form-control"
                    placeholder="City" maxLength={64}
                    value={vehicleAddContext?.newVehicle[personType + "City"]}
                    onChange={(e) => {
                        vehicleAddContext.saveAddition(personType + "City", e.target.value);
                    }}
                />
            </div>
            <div className="row form-group">
                {/* State */}
                <div className="col-4 mt-2">
                    <select className="btn btn-default btn-group dropdown-toggle filter-option"
                        value={vehicleAddContext?.newVehicle[personType + "State"]}
                        onChange={(e) => vehicleAddContext.saveAddition(personType + "State", e.target.value)}
                    >
                        {StateDropdown()}
                    </select>
                </div>
                {/* Zip */}
                <div className="col-4 mt-2 ms-2">
                    <input type={"number"} className="form-control"
                        placeholder="Zip Code" maxLength={5}
                        value={vehicleAddContext?.newVehicle[personType + "Zip"]}
                        onChange={(e) => {
                            vehicleAddContext.saveAddition(personType + "Zip", e.target.value);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default VehiclePersonInfo