import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import RoundyContainer from "../components/RoundyContainer";
import SettingsWindow from "../components/SettingsWindow";
import { changeSecurityQuestions, updateEmail, updateUsername, updatePassword, setPaperlessEmail } from "../services/Api";

const Content = (props) => {
    const { userData, setUserData } = props.user
    const { zip, policyNumber, paperlessEmailAddress, emailAddress, userName, billingRegistered } = props.user.userData
    const [display, setDisplay] = useState("")
    const [changedVal, setChangedVal] = useState("")

    useEffect(() => {

        if (changedVal.length) {
            setTimeout(() => {
                setChangedVal("")
            }, 5000)
        }
    }, [changedVal])

    const onToggle = (key) => {
        if (display !== key) {
            setDisplay(key)
        } else {
            setDisplay("")
        }
    }

    // Updates Email
    const updatesEmail = async (x) => {
        var response = await updateEmail(x)
        console.log({ response })

        if (response.data.Result !== "Success") {
            return "Invalid";
        }
        else {
            if (x.input) {
                await setPaperlessEmail({ ...x, Email: x.EmailAddress })
            }
            setUserData({ ...userData, emailAddress: x.EmailAddress, paperlessEmailAddress: (x.input) ? x.EmailAddress : userData.paperlessEmailAddress })
            return "Valid";
        }
    }

    // Updates Paperless Email
    const updatesPaperlessEmail = async (x) => {
        var response = await setPaperlessEmail(x)
        console.log({ response })
        setUserData({ ...userData, paperlessEmailAddress: x.Email })
        return "Successful"
    }

    // Updates Username
    const updatesUsername = async (x) => {
        var response = await updateUsername(x);
        console.log({ response })

        // Failed to update username
        if (response.data.Result === "Username/Email Address already exists" || response.data.Result === "Error") {
            return "Invalid";
        }
        // Successful update
        else {
            setUserData({ ...userData, userName: x.Username });
            return "Valid";
        }
    }
    return (
        <>
            <h4 className="text-start section-title">
                SETTINGS
            </h4>
            <div className="m-1">
                Click the links below to edit your account info
            </div>
            <small>{changedVal.length > 0 &&
                <p className={`row p-3 m-1 border bg-success border-success text-success`}>Your {changedVal} has been successfully changed!</p>}
            </small>
            <div className="d-flex flex-column align-content-center m-4 p-2 text-muted">
                <small>
                    {/* <div className="row py-2 bg-success border border-success"> */}
                    {/* </div> */}
                    {/* Phone Number Setting */}
                    {/* <div className="row py-2">
                        <div className="col-xs-4 col-sm-3">
                            <strong>Phone Number</strong>
                        </div>
                        <div className="col-xs-8 col-sm-9 col-md-7">
                            555-555-5555<br />
                            <strong onClick={() => onToggle("phone")}>
                                <small className="text-primary">Change Phone Number</small></strong>
                            {display == "phone" && <SettingsWindow
                                onCancel={() => { onToggle("pass") }}
                                setChangedVal={() => { setChangedVal("password") }}
                                apiFunction={updatePassword}
                                paramName={"Phone"}
                                zip={zip}
                                policyNumber={policyNumber}
                                userValue={emailAddress}
                                phone />}
                        </div>
                    </div> */}

                    {/* Email Address */}
                    <div className="row py-2">
                        <div className="col-xs-4 col-sm-3">
                            <strong>Email Address</strong>
                        </div>
                        <div className="col-xs-8 col-sm-9 col-md-7">
                            {emailAddress}<br />
                            <strong onClick={() => { onToggle("email") }}>
                                <small className="text-primary">Change Login Email Address</small></strong>

                            <div>
                                {display === "email" && <SettingsWindow
                                    onCancel={() => { onToggle("email") }}
                                    setChangedVal={() => { setChangedVal("email") }}
                                    apiFunction={(x) => updatesEmail(x)}
                                    paramName={"EmailAddress"}
                                    zip={zip}
                                    policyNumber={policyNumber}
                                    email
                                    billingRegistered={billingRegistered} />}
                            </div>
                            {/* {
                                billingRegistered &&
                                <div>
                                    <strong onClick={() => { onToggle("paperless") }}>
                                        <small className="text-primary">Change Paperless Email Address</small></strong>
                                    {display === "paperless" && <SettingsWindow
                                        onCancel={() => { onToggle("paperless") }}
                                        setChangedVal={() => { setChangedVal("paperless") }}
                                        apiFunction={(x) => updatesPaperlessEmail(x)}
                                        paramName={"Email"}
                                        zip={zip}
                                        policyNumber={policyNumber}
                                        emailAddress={paperlessEmailAddress}
                                        billingRegistered />}
                                </div>
                            } */}
                        </div>
                    </div>

                    {/* Username */}
                    <div className="row py-2">
                        <div className="col-xs-4 col-sm-3">
                            <strong>Username</strong>
                        </div>
                        <div className="col-xs-8 col-sm-9 col-md-7">
                            {(userName === "" || !("userName" in props.user.userData)) ? emailAddress : userName}<br />
                            <strong onClick={() => { onToggle("username") }}>
                                <small className="text-primary">Change Username</small>
                            </strong>
                            {display === "username" && <SettingsWindow
                                onCancel={() => { onToggle("username") }}
                                setChangedVal={() => { setChangedVal("username") }}
                                apiFunction={(x) => updatesUsername(x)}
                                paramName={"Username"}
                                zip={zip}
                                policyNumber={policyNumber}
                                emailAddress={emailAddress}
                                userName

                            />}
                        </div>
                    </div>

                    {/* Password */}
                    <div className="row py-2">
                        <div className="col-xs-4 col-sm-3">
                            <strong>Password</strong>
                        </div>
                        <div className="col-xs-8 col-sm-9 col-md-7">
                            ********<br />
                            <strong onClick={() => onToggle("pass")}>
                                <small className="text-primary">Change Password</small></strong>
                            {display === "pass" && <SettingsWindow
                                onCancel={() => { onToggle("pass") }}
                                setChangedVal={() => { setChangedVal("password") }}
                                apiFunction={updatePassword}
                                paramName={"NewPassword"}
                                zip={zip}
                                policyNumber={policyNumber}
                                userValue={emailAddress}
                                pass />}
                        </div>
                    </div>

                    {/* Security Questions */}
                    <div className="row py-2">
                        <div className="col-xs-4 col-sm-3">
                            <strong>Security Question</strong>
                        </div>
                        <div className="col-xs-8 col-sm-9 col-md-7">
                            Used for identity verification purposes<br />
                            <strong onClick={() => { onToggle("security") }}>
                                <small className="text-primary">Change Security Questions</small></strong>
                            {display === "security" && <SettingsWindow
                                onCancel={() => { onToggle("security") }}
                                setChangedVal={() => { setChangedVal("security questions") }}
                                apiFunction={changeSecurityQuestions}
                                paramName={"Questions"}
                                zip={zip}
                                policyNumber={policyNumber}
                                security />}
                        </div>
                    </div>

                </small>
            </div>
            <Link to="/home" className="col-sm-1">
                <button className="btn btn-default border">BACK</button>
            </Link>
        </>
    )
}

const Settings = (props) => {
    console.log("Settings Screen:", props)
    const { userData } = props.user
    return (
        <>
            <Menu Title={"MY ACCOUNT"} FirstName={userData.firstName} userData={userData} />
            <RoundyContainer params={props} Content={Content} />
        </>
    )
};


export default Settings