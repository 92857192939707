import { storageData } from "services/Services"

const OtherDeductibleList = () => {
    let options = []
    try {
        const dropdownOptions = JSON.parse(storageData.getItem('DropdownOptions'))
        const deductibleOptions = dropdownOptions.rows.filter(row => row.QuestionID === "81")

        if (deductibleOptions.length > 0) {
            options = [
                <option key="default" disabled value="">Select from the following...</option>,
                ...deductibleOptions.map(answer => (
                    <option key={answer.AnswerID} value={answer.AnswerText}>{answer.AnswerText}</option>
                ))
            ]
        }
    } catch (error) {
        console.error(error)
        options = [<option key="default" value="">Select from the following...</option>]
    }

    return <>{options}</>
}

export default OtherDeductibleList