import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import { NewVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext'
import React, { useContext } from 'react'
import "./VehicleInfo.css"
import { storageData } from 'services/Services'

const VehicleInfo = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    const newVehicleContext = useContext(NewVehicleContext)
    const vehicleInfo = ['vehicleMake', 'vehicleYear', 'vehicleModel', 'vehicleBody', 'vehicleVIN']
    var newVehicles = addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles

    // Component to render based on response
    const navigate = (value) => {
        var vehicle = newVehicleContext.newVehicle
        var addVehicleClassCopy = addVehicleContext.addVehicleClass

        // Remove the new vehicle from the newVehicles list in class
        if (value === "reset") {
            addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles.length = 0;
        }
        // Moves onto the next component
        else {
            vehicle.componentTracker = "VehiclePurchased"

            // Set the vehicle class with new vehicle under vehicles --> vehiclesOnPolicy --> newVehicles
            newVehicleContext.setNewVehicle({ ...newVehicleContext.newVehicle, ...vehicle })
            addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles[0] = newVehicleContext.newVehicle
        }

        addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
        storageData.setItem("AddVehicleClass", JSON.stringify(addVehicleClassCopy))
    }

    return (
        <div className='row mx-2 my-3 align-items-center'>
            <div className="form-group form-group-sm">
                <table>
                    <tbody>
                        {vehicleInfo.map((value, id) => {
                            const vehicleDetail = value.slice(value.indexOf('vehicle') + "vehicle".length)
                            return (
                                <tr key={id}>
                                    <td className='fw-bold fs-6 text-primary'>
                                        {vehicleDetail}
                                    </td>
                                    <td className="text-uppercase fs-6 text-dark fw-bold vehicleValues">
                                        {newVehicleContext.newVehicle[value]}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                {newVehicles[0]?.componentTracker == "VehicleInfo" &&
                    <>
                        <p className='py-3 fs-6'>
                            Please confirm that the above vehicle information is correct and click CONTINUE. If you need to update the vehicle information, click RESET.
                        </p>
                        <button className="button reset-button fw-bold" onClick={() => navigate("reset")}>RESET</button>
                        <button className="button continue-button fw-bold" onClick={() => navigate("continue")}>CONTINUE</button>
                    </>
                }

            </div>
        </div>
    )
}

export default VehicleInfo