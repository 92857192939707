import { pushMain, pushPerson, pushVehicle, checkCodes, emailTempIDCard } from "../../../services/Api";
import { storageData } from "../../../services/Services";
import { StateConversion } from "../../ChangeRequestSharedComp";

var platform = (window.innerWidth < 768) ? "Mobile" : "Desktop"

// Inserts all main fields
const InsertMain = async (RequestType, pdfByte, props, PolicyNumber, userData) => {
    var UserState = userData.state;
    var AddressStreet;
    var AddressApartmentNum;
    var PolicyState;
    var PolicyAddress;
    var PolicyCity;
    var PolicyZip;
    var PolicyHolderName;
    var PhoneNumber;
    var EffectiveDate;
    var ExpirationDate;
    var VehiclesKeptAtAddress;
    var VehiclesOtherAddress;
    var VehicleUsedInEmployment;
    var UsedInEmploymentDescript;
    var Agent;
    // Retrieves Current Address
    var retrievedAddressData = storageData.getItem("currResidence");
    if (retrievedAddressData) {
        retrievedAddressData = JSON.parse(retrievedAddressData);
        AddressStreet = retrievedAddressData.address1;
        (retrievedAddressData.streetAddress2 !== null) ? AddressApartmentNum = retrievedAddressData.streetAddress2 : AddressApartmentNum = "";
        PolicyAddress = retrievedAddressData.address1;
        PolicyCity = retrievedAddressData.city;
        PolicyZip = retrievedAddressData.zip;
        PolicyHolderName = userData.firstName + " " + userData.lastName;
    }
    // Sets Policy State
    if (UserState === "New Jersey") {
        PolicyState = "NJ";
    }
    else if (UserState === "Pennsylvania") {
        PolicyState = "PA";
    }
    else {
        PolicyState = "MI";
    }
    // Retrieves Phone Number
    var retrievedPhoneNum = storageData.getItem("vehicleSummaryChanges");
    if (retrievedPhoneNum) {
        retrievedPhoneNum = JSON.parse(retrievedPhoneNum).vehicleSummaryChanges;
        PhoneNumber = retrievedPhoneNum["Phone Number"];
    }
    // Retrieves Effective & Expiration Date
    var effectiveDt = new Date(userData.idCardData[0].effectivedt);
    EffectiveDate = `${effectiveDt.getMonth() + 1}/${effectiveDt.getDate()}/${effectiveDt.getFullYear()}`

    var effectiveDtCopy = effectiveDt;
    var expirationDt = new Date(effectiveDtCopy.setMonth(effectiveDtCopy.getMonth() + 6));
    ExpirationDate = expirationDt.getMonth() + 1 + "/" + expirationDt.getDate() + "/" + expirationDt.getFullYear()

    // Retrieves Vehicle Use
    var retrievedVehicleData = storageData.getItem("updateVehicleData");
    if (retrievedVehicleData) {
        retrievedVehicleData = JSON.parse(retrievedVehicleData).updateVehicleData;
        VehiclesKeptAtAddress = retrievedVehicleData.keptOvernightToggleResponse;
        VehiclesOtherAddress = retrievedVehicleData.vehiclesKeptResponse;
        VehicleUsedInEmployment = retrievedVehicleData.businessPurposeToggleResponse;
        UsedInEmploymentDescript = retrievedVehicleData.otherPurposeResponse;
    }

    try {
        const response = await pushMain({
            UserState,
            RequestType,
            PolicyNumber,
            AddressStreet,
            AddressApartmentNum,
            PolicyState,
            PolicyAddress,
            PolicyCity,
            PolicyZip,
            PolicyHolderName,
            PhoneNumber,
            EffectiveDate,
            ExpirationDate,
            VehiclesKeptAtAddress,
            VehiclesOtherAddress,
            VehicleUsedInEmployment,
            UsedInEmploymentDescript,
            platform
        });
        storageData.setItem("VehicleChangeConfirmationNumber", JSON.stringify(response?.data?.confirmationNumber).replace(/['"]+/g, ''))
        const changeId = response?.data?.changeId;
        storageData.setItem("VehicleChangeID", changeId);
        InsertVehicles(UserState, RequestType, changeId, pdfByte, props, PolicyNumber, userData)

    }
    catch (e) {
        console.log(e)
    }
}

// Inserts all vehicles
const InsertVehicles = async (UserState, RequestType, changeId, pdfByte, props, PolicyNumber, userData) => {
    // Retrieves Stored Vehicles
    var retrievedStoredVehicles = storageData.getItem("vehicleInfo");
    // Retrieves Modified of Stored Vehicles
    var retrievedModifiedVehicles = storageData.getItem("dbVehicles");
    // Retrieves Added Vehicles
    var retrievedAddedVehicles = storageData.getItem("newVehicles");
    var allVehicles;
    if (retrievedModifiedVehicles && retrievedModifiedVehicles !== "[]" && retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedModifiedVehicles), ...JSON.parse(retrievedAddedVehicles)];
    }
    else if (retrievedStoredVehicles && retrievedStoredVehicles !== "[]" && retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedStoredVehicles), ...JSON.parse(retrievedAddedVehicles)];
    }
    else if (retrievedModifiedVehicles && retrievedModifiedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedModifiedVehicles)];
    }
    else if (retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedAddedVehicles)];
    }

    // Creates array to send
    var arrToStore = [];
    for (var i = 0; i < allVehicles.length; i++) {
        var objToStore = {};
        if (UserState === "New Jersey" || UserState === "Pennsylvania") {
            // New Vehicle
            if ("vehicleMake" in allVehicles[i]) {
                objToStore["IsSDS"] = 0;
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["VIN"] = allVehicles[i].vehicleVin.trim();
                objToStore["Make"] = allVehicles[i].vehicleMake.trim();
                objToStore["Model"] = allVehicles[i].vehicleModel.trim();
                objToStore["Year"] = allVehicles[i].vehicleYear;
                objToStore["BodyType"] = allVehicles[i].vehicleBody.trim();
                objToStore["Status"] = (allVehicles[i].removalReason != "") ? allVehicles[i].removalReason : null;
                objToStore["OtherReason"] = allVehicles[i].removalExplanation;
                objToStore["RequestedEffectiveDate"] = allVehicles[i].reqEffectiveDate;
                objToStore["DateObtained"] = allVehicles[i].dateObtained;
                objToStore["SecurityAlarm"] = allVehicles[i].securityType;
                objToStore["StateRegistered"] = StateConversion({ "newState": allVehicles[i].stateRegistered });
                objToStore["WantsOtherThanCollision"] = allVehicles[i].otherThanCollisionToggle.response;
                objToStore["DiscussOtherDeductible"] = allVehicles[i].otherThanCollisionToggle.standardDeductible;
                objToStore["WantsRentalCoverage"] = allVehicles[i].otherThanCollisionToggle.rentalReimbursement;
                objToStore["WantsCollision"] = allVehicles[i].collisionCoverageToggle.response;
                objToStore["DiscussCollisionDeductible"] = allVehicles[i].collisionCoverageToggle.standardDeductible;
                objToStore["IsCoOwned"] = allVehicles[i].vehicledOwnedLeasedFin.coOwned;
                objToStore["CoOwnerName"] = allVehicles[i].vehicledOwnedLeasedFin.name;
                objToStore["CoOwnerAddress"] = allVehicles[i].vehicledOwnedLeasedFin.address;
                objToStore["CoOwnerCity"] = allVehicles[i].vehicledOwnedLeasedFin.city;
                objToStore["CoOwnerState"] = StateConversion({ "newState": allVehicles[i].vehicledOwnedLeasedFin.state });
                objToStore["CoOwnerZip"] = allVehicles[i].vehicledOwnedLeasedFin.zip;

                if (allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.response === "None of the Above") {
                    objToStore["Registrant"] = "43095"
                }
                else if (allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.response === "Self & Spouse") {
                    objToStore["Registrant"] = "43270"
                }
                else if (allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.response === "Spouse") {
                    objToStore["Registrant"] = "43170"
                }
                else if (allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.response === "Self") {
                    objToStore["Registrant"] = "43070"
                }
                objToStore["RegistrantName"] = allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.registrantName;
                objToStore["RegistrantAddress"] = allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.registrantAddress;
                objToStore["RegistrantCity"] = allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.registrantCity;
                objToStore["RegistrantState"] = StateConversion({ "newState": allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.registrantState });
                objToStore["RegistrantZip"] = allVehicles[i].vehicledOwnedLeasedFin.nameRegistered.registrantZip;

                if (allVehicles[i].stateRegistered === "PA") {
                    objToStore["Has4WheelAntiLockBreaks"] = "1";
                }
                if (allVehicles[i].vehicledOwnedLeasedFin.response === "Financed") {
                    objToStore["OwnStatus"] = "24670";
                    objToStore["LeinHolder"] = allVehicles[i].vehicledOwnedLeasedFin.financeCompany;
                }
                else if (allVehicles[i].vehicledOwnedLeasedFin.response === "Leased") {
                    objToStore["OwnStatus"] = "24570";
                    objToStore["LeinHolder"] = allVehicles[i].vehicledOwnedLeasedFin.leasingCompany;
                }
                else if (allVehicles[i].vehicledOwnedLeasedFin.response === "Owned") {
                    objToStore["OwnStatus"] = "24470";
                }
                arrToStore.push(objToStore)
            }
            // Replacement Vehicle
            else if ("replacementVehicle" in allVehicles[i] && allVehicles[i].status === "New Vehicle") {
                // Old Vehicle
                var oldVehicle = {};
                oldVehicle["PolicyNumber"] = PolicyNumber;
                oldVehicle["ChangeID"] = changeId;
                oldVehicle["IsSDS"] = 0;
                oldVehicle["VIN"] = allVehicles[i].VIN.trim();
                oldVehicle["Make"] = allVehicles[i].Make.trim();
                oldVehicle["Model"] = allVehicles[i].Model.trim();
                oldVehicle["Year"] = allVehicles[i].Year;
                oldVehicle["RequestedEffectiveDate"] = allVehicles[i].reqEffectiveDate;
                if (allVehicles[i].removalExplanation != "") {
                    oldVehicle["Status"] = "Other"
                    oldVehicle["StatusText"] = allVehicles[i].removalExplanation
                }
                else {
                    oldVehicle["Status"] = allVehicles[i].replacementVehicle.removalReason
                }
                arrToStore.push(oldVehicle)

                // New Vehicle
                objToStore["IsSDS"] = 0;
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["VIN"] = allVehicles[i].replacementVehicle.vehicleVin.trim();
                objToStore["Make"] = allVehicles[i].replacementVehicle.vehicleMake.trim();
                objToStore["Model"] = allVehicles[i].replacementVehicle.vehicleModel.trim();
                objToStore["Year"] = allVehicles[i].replacementVehicle.vehicleYear;
                objToStore["BodyType"] = allVehicles[i].replacementVehicle.vehicleBody.trim();
                objToStore["Status"] = null;
                objToStore["OtherReason"] = allVehicles[i].replacementVehicle.removalExplanation;
                objToStore["RequestedEffectiveDate"] = allVehicles[i].reqEffectiveDate;
                objToStore["DateObtained"] = allVehicles[i].replacementVehicle.dateObtained;
                objToStore["SecurityAlarm"] = allVehicles[i].replacementVehicle.securityType;
                objToStore["StateRegistered"] = StateConversion({ "newState": allVehicles[i].replacementVehicle.stateRegistered });
                objToStore["WantsOtherThanCollision"] = allVehicles[i].replacementVehicle.otherThanCollisionToggle.response;
                objToStore["DiscussOtherDeductible"] = allVehicles[i].replacementVehicle.otherThanCollisionToggle.standardDeductible;
                objToStore["WantsRentalCoverage"] = allVehicles[i].replacementVehicle.otherThanCollisionToggle.rentalReimbursement;
                objToStore["WantsCollision"] = allVehicles[i].replacementVehicle.collisionCoverageToggle.response;
                objToStore["DiscussCollisionDeductible"] = allVehicles[i].replacementVehicle.collisionCoverageToggle.standardDeductible;
                objToStore["IsCoOwned"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.coOwned;
                objToStore["CoOwnerName"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.name;
                objToStore["CoOwnerAddress"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.address;
                objToStore["CoOwnerCity"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.city;
                objToStore["CoOwnerState"] = StateConversion({ "newState": allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.state });
                objToStore["CoOwnerZip"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.zip;

                if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.response === "None of the Above") {
                    objToStore["Registrant"] = "43095"
                }
                else if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.response === "Self & Spouse") {
                    objToStore["Registrant"] = "43270"
                }
                else if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.response === "Spouse") {
                    objToStore["Registrant"] = "43170"
                }
                else if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.response === "Self") {
                    objToStore["Registrant"] = "43070"
                }
                objToStore["RegistrantName"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.registrantName;
                objToStore["RegistrantAddress"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.registrantAddress;
                objToStore["RegistrantCity"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.registrantCity;
                objToStore["RegistrantState"] = StateConversion({ "newState": allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.registrantState });
                objToStore["RegistrantZip"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.nameRegistered.registrantZip;

                if (allVehicles[i].replacementVehicle.stateRegistered === "PA") {
                    objToStore["Has4WheelAntiLockBreaks"] = "1";
                }
                if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.response === "Financed") {
                    objToStore["OwnStatus"] = "24670";
                    objToStore["LeinHolder"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.financeCompany;
                }
                else if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.response === "Leased") {
                    objToStore["OwnStatus"] = "24570";
                    objToStore["LeinHolder"] = allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.leasingCompany;
                }
                else if (allVehicles[i].replacementVehicle.vehicledOwnedLeasedFin.response === "Owned") {
                    objToStore["OwnStatus"] = "24470";
                }
                arrToStore.push(objToStore)
            }
            // Removed Vehicle
            else if (allVehicles[i].removalReason !== "" && allVehicles[i].status === "") {
                objToStore["IsSDS"] = 0;
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                objToStore["Status"] = (allVehicles[i].removalReason != "") ? allVehicles[i].removalReason : null;
                objToStore["OtherReason"] = allVehicles[i].removalExplanation;
                objToStore["RequestedEffectiveDate"] = (allVehicles[i]?.reqEffectiveDate != "") ? allVehicles[i]?.reqEffectiveDate : null;
                arrToStore.push(objToStore)
            }
            // Sends the other/non-changed vehicles
            else {
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                arrToStore.push(objToStore)
            }
        }
        else if (UserState === "Michigan") {
            // Adds vehicle that has not been changed
            if (allVehicles[i].removalReason === "" && !("modifyMichiganReasonForChange" in allVehicles[i])) {
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                objToStore["Status"] = "";
                objToStore["OtherReason"] = "";
                arrToStore.push(objToStore)
            }
            // Replaced vehicle
            else if ("Make" in allVehicles[i] && "michiganVehicleMake" in allVehicles[i]) {
                // Old Vehicle
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                objToStore["Status"] = (allVehicles[i].removalReason != "") ? allVehicles[i].removalReason : null;
                objToStore["OtherReason"] = allVehicles[i].removalExplanation;
                objToStore["RequestedRemovalEffectiveDate"] = allVehicles[i].michiganRequestedEffectiveDate;
                objToStore["RequestedEffectiveDate"] = allVehicles[i].michiganRequestedEffectiveDate;
                arrToStore.push(objToStore);

                // New Vehicle
                var newVehicleObj = {};
                newVehicleObj["PolicyNumber"] = PolicyNumber;
                newVehicleObj["ChangeID"] = changeId;
                newVehicleObj["IsSDS"] = 0;
                newVehicleObj["Status"] = (allVehicles[i].michiganVehicleStatus != "New Vehicle" && allVehicles[i].michiganVehicleStatus != "") ? allVehicles[i].michiganVehicleStatus : null;
                newVehicleObj["FinancedOrLeased"] = allVehicles[i].michiganFinancedOrLeased; // New
                newVehicleObj["FinanceCompanyAddress"] = allVehicles[i].Address
                newVehicleObj["FinanceCompanyCity"] = allVehicles[i].City; //New
                newVehicleObj["FinanceCompanyState"] = StateConversion({ "financeCompanyState": allVehicles[i].State });  //New
                newVehicleObj["FinanceCompanyZip"] = allVehicles[i].ZIP; //New
                newVehicleObj["OdometerReading"] = allVehicles[i].michiganOdometerReading; // New
                newVehicleObj["ReasonForChange"] = allVehicles[i].michiganReasonForChange; // New
                newVehicleObj["RequestedEffectiveDate"] = allVehicles[i].michiganRequestedEffectiveDate; // New
                newVehicleObj["VIN"] = allVehicles[i].michiganVehicleVIN.trim();
                newVehicleObj["Make"] = allVehicles[i].michiganVehicleMake.trim();
                newVehicleObj["Model"] = allVehicles[i].michiganVehicleModel.trim();
                newVehicleObj["Year"] = allVehicles[i].michiganVehicleYear;

                if (allVehicles[i].michiganFinancedOrLeased == "Yes") {
                    if (allVehicles[i].financeCompanyName === "Other") {
                        newVehicleObj["FinanceCompanyName"] = allVehicles[i].otherFinanceCompanyName;
                    }
                    else {
                        newVehicleObj["FinanceCompanyName"] = allVehicles[i].financeCompanyName;
                        newVehicleObj["LeinHolder"] = allVehicles[i].financeCompanyName;
                    }
                    newVehicleObj["OwnStatus"] = "24670";
                }

                // Other Than Collision Coverage
                if (allVehicles[i].michiganOtherThanCollision === "Yes") {
                    newVehicleObj["WantsOtherThanCollision"] = "1";
                    newVehicleObj["OtherThanCollisionDeductible"] = allVehicles[i].michiganOtherThanCollisionDeductible;
                    newVehicleObj["WantsRentalCoverage"] = allVehicles[i].michiganRentalReimbursement;

                    // Collision Coverage
                    if (allVehicles[i].michiganCollisionCoverage === "Yes") {
                        newVehicleObj["WantsCollision"] = "1";
                        newVehicleObj["CollisionType"] = allVehicles[i].michiganCollisionType;
                        newVehicleObj["CollisionDeductible"] = allVehicles[i].michiganCollisionDeductible;
                    }
                    else {
                        newVehicleObj["WantsCollision"] = "0";
                    }
                }
                else {
                    newVehicleObj["WantsOtherThanCollision"] = "0";
                    newVehicleObj["WantsRentalCoverage"] = "71000";
                }

                arrToStore.push(newVehicleObj);
            }
            // Modified vehicle
            else if ("modifyMichiganReasonForChange" in allVehicles[i] && !("michiganVehicleMake" in allVehicles[i])) {
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                objToStore["LoanNumber"] = allVehicles[i].loanNum; //New
                objToStore["ModifyReasonForChange"] = allVehicles[i].modifyMichiganReasonForChange; //New
                objToStore["ModifyRequestedEffectiveDate"] = allVehicles[i].modifyMichiganRequestedEffectiveDate; //New
                objToStore["RequestedEffectiveDate"] = allVehicles[i].modifyMichiganRequestedEffectiveDate; //New
                objToStore["Type"] = allVehicles[i].type; //New
                objToStore["Status"] = (allVehicles[i].removalReason != "New Vehicle" && allVehicles[i].removalReason != "") ? allVehicles[i].removalReason : null;
                objToStore["OtherReason"] = allVehicles[i].removalExplanation;
                objToStore["FinanceCompanyAddress"] = allVehicles[i].Address; //New
                objToStore["FinanceCompanyCity"] = allVehicles[i].City; //New
                objToStore["FinanceCompanyState"] = StateConversion({ "financeCompanyState": allVehicles[i].State });  //New
                objToStore["FinanceCompanyZip"] = allVehicles[i].ZIP; //New  
                objToStore["WantsRentalCoverage"] = "71000";

                // Financed or Leased
                if (allVehicles[i].michiganFinancedOrLeased == "Yes") {
                    if (allVehicles[i].financeCompanyName === "Other") {
                        objToStore["FinanceCompanyName"] = allVehicles[i].otherFinanceCompanyName;
                        objToStore["OwnStatus"] = "24670";
                    }
                    else {
                        objToStore["FinanceCompanyName"] = allVehicles[i].financeCompanyName;
                        objToStore["LeinHolder"] = allVehicles[i].financeCompanyName;
                        objToStore["OwnStatus"] = "24670";
                    }
                }
                arrToStore.push(objToStore);
            }
            // Removed vehicle
            else if ("removalReason" in allVehicles[i] && allVehicles[i].removalReason !== "" && !("modifyMichiganReasonForChange" in allVehicles[i])) {
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["VIN"] = allVehicles[i].VIN.trim();
                objToStore["Make"] = allVehicles[i].Make.trim();
                objToStore["Model"] = allVehicles[i].Model.trim();
                objToStore["Year"] = allVehicles[i].Year;
                objToStore["Status"] = (allVehicles[i].removalReason != "") ? allVehicles[i].removalReason : null;
                objToStore["OtherReason"] = allVehicles[i].removalExplanation;
                objToStore["RequestedRemovalEffectiveDate"] = allVehicles[i].reqEffectiveDate;
                objToStore["RequestedEffectiveDate"] = allVehicles[i].reqEffectiveDate;
                arrToStore.push(objToStore);
            }
            // Added Vehicle
            else if ("michiganVehicleMake" in allVehicles[i] && !("Make" in allVehicles[i])) {
                objToStore["PolicyNumber"] = PolicyNumber;
                objToStore["ChangeID"] = changeId;
                objToStore["IsSDS"] = 0;
                objToStore["Status"] = (allVehicles[i].michiganVehicleStatus != "New Vehicle" && allVehicles[i].michiganVehicleStatus != "") ? allVehicles[i].michiganVehicleStatus : null;
                objToStore["FinancedOrLeased"] = allVehicles[i].michiganFinancedOrLeased; // New
                objToStore["FinanceCompanyAddress"] = allVehicles[i].Address; //New
                objToStore["FinanceCompanyCity"] = allVehicles[i].City; //New
                objToStore["FinanceCompanyState"] = StateConversion({ "financeCompanyState": allVehicles[i].State });  //New
                objToStore["FinanceCompanyZip"] = allVehicles[i].ZIP; //New
                objToStore["OdometerReading"] = allVehicles[i].michiganOdometerReading; // New
                objToStore["ReasonForChange"] = allVehicles[i].michiganReasonForChange; // New
                objToStore["RequestedEffectiveDate"] = allVehicles[i].michiganRequestedEffectiveDate; // New
                objToStore["VIN"] = allVehicles[i].michiganVehicleVIN.trim();
                objToStore["Make"] = allVehicles[i].michiganVehicleMake.trim();
                objToStore["Model"] = allVehicles[i].michiganVehicleModel.trim();
                objToStore["Year"] = allVehicles[i].michiganVehicleYear;
                objToStore["LoanNumber"] = allVehicles[i].loanNum; //New
                objToStore["ModifyReasonForChange"] = allVehicles[i].modifyMichiganReasonForChange; //New
                objToStore["ModifyRequestedEffectiveDate"] = allVehicles[i].modifyMichiganRequestedEffectiveDate; //New
                objToStore["Type"] = allVehicles[i].type; //New

                // Other Than Collision Coverage
                if (allVehicles[i].michiganOtherThanCollision == "Yes") {
                    objToStore["WantsOtherThanCollision"] = "1";
                    objToStore["OtherThanCollisionDeductible"] = allVehicles[i].michiganOtherThanCollisionDeductible;
                    objToStore["WantsRentalCoverage"] = allVehicles[i].michiganRentalReimbursement;

                    // Collision Coverage
                    if (allVehicles[i].michiganCollisionCoverage === "Yes") {
                        objToStore["WantsCollision"] = "1";
                        objToStore["CollisionType"] = allVehicles[i].michiganCollisionType;
                        objToStore["CollisionDeductible"] = allVehicles[i].michiganCollisionDeductible;
                    }
                    else {
                        objToStore["wantsCollision"] = "0";
                    }
                }
                else {
                    objToStore["WantsOtherThanCollision"] = "0";
                    objToStore["WantsRentalCoverage"] = "71000";
                }

                // Financed or Leased
                if (allVehicles[i].michiganFinancedOrLeased == "Yes") {
                    if (allVehicles[i].financeCompanyName === "Other") {
                        objToStore["FinanceCompanyName"] = allVehicles[i].otherFinanceCompanyName;
                        objToStore["OwnStatus"] = "24670";
                    }
                    else {
                        objToStore["FinanceCompanyName"] = allVehicles[i].financeCompanyName;
                        objToStore["LeinHolder"] = allVehicles[i].financeCompanyName;
                        objToStore["OwnStatus"] = "24670";
                    }
                }
                arrToStore.push(objToStore)
            }
        }
    }
    try {
        const response = await pushVehicle(UserState, RequestType, arrToStore, platform);
        var vehicleChangeID = response?.data?.results;
        InsertPerson(UserState, RequestType, changeId, vehicleChangeID, pdfByte, props, PolicyNumber, userData)
    }
    catch (e) {
        console.log(e)
    }
}

// Inserts all drivers and members
const InsertPerson = async (UserState, RequestType, changeId, vehicleChangeID, pdfByte, props, PolicyNumber, userData) => {

    // Vehicle Info to update vehicle table
    // Retrieves Stored Vehicles
    var retrievedStoredVehicles = storageData.getItem("vehicleInfo");
    // Retrieves Modified of Stored Vehicles
    var retrievedModifiedVehicles = storageData.getItem("dbVehicles");
    // Retrieves Added Vehicles
    var retrievedAddedVehicles = storageData.getItem("newVehicles");
    var allVehicles;
    if (retrievedModifiedVehicles && retrievedModifiedVehicles !== "[]" && retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedModifiedVehicles), ...JSON.parse(retrievedAddedVehicles)];
    }
    else if (retrievedStoredVehicles && retrievedStoredVehicles !== "[]" && retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedStoredVehicles), ...JSON.parse(retrievedAddedVehicles)];
    }
    else if (retrievedModifiedVehicles && retrievedModifiedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedModifiedVehicles)];
    }
    else if (retrievedAddedVehicles && retrievedAddedVehicles !== "[]") {
        allVehicles = [...JSON.parse(retrievedAddedVehicles)];
    }

    // Driver fields
    var allDrivers;
    // Primary vehicle fields
    var driverAssignmentInfo = storageData.getItem('updateDriverAssignmentData');
    if (driverAssignmentInfo) {
        driverAssignmentInfo = JSON.parse(driverAssignmentInfo).updateDriverAssignmentData;

        // Converts driver data to array
        var arrToStore = [];
        var arrToPush = [];
        var keys = Object.keys(driverAssignmentInfo);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            arrToStore[key] = driverAssignmentInfo[key];

            // Checks for the vehicle change id that was inserted
            for (var k = 0; k < vehicleChangeID.length; k++) {
                var vehicleChangeVehicle = `${vehicleChangeID[k].Year} ${vehicleChangeID[k].Make} ${vehicleChangeID[k].Model}`
                if (arrToStore[key].vehicle.toLowerCase().trim() == vehicleChangeVehicle.toLowerCase().trim() || arrToStore[key]?.VIN == vehicleChangeID[k].VIN || arrToStore[key]?.vehicleVin == vehicleChangeID[k].VIN) {
                    arrToStore[key]["VehicleChangeID"] = vehicleChangeID[k].VehicleID;
                    arrToStore[key]["VIN"] = vehicleChangeID[k].VIN;
                }
            }

            for (var j = 0; j < allVehicles.length; j++) {
                var objToStore = {};
                if (UserState !== "Michigan") {
                    if (("vehicleMake" in allVehicles[j] || "Make" in allVehicles[j]) && !("replacementVehicle" in allVehicles[j])) {
                        var vehicle;

                        // No new/replaced vehicle
                        if (!("vehicleMake" in allVehicles[j]) && "Make" in allVehicles[j]) {
                            vehicle = `${allVehicles[j].Year} ${allVehicles[j].Make} ${allVehicles[j].Model}`
                        }
                        // New vehicle
                        else {
                            vehicle = `${allVehicles[j].vehicleYear} ${allVehicles[j].vehicleMake} ${allVehicles[j].vehicleModel}`
                        }
                        // Not a Primary Driver for a Vehicle
                        if (arrToStore[key].vehicle == "NotPrimaryDriver") {
                            objToStore["ChangeID"] = changeId
                            objToStore["Name"] = arrToStore[key].fullName;
                            objToStore["MilesOneWay"] = "1500";
                            objToStore["DaysDriven"] = "0";

                            // If person is already in array to push
                            if (arrToPush.some((name) => name.Name == arrToStore[key].fullName)) {
                                continue
                            }
                            else {
                                arrToPush.push(objToStore)
                            }
                        }
                        else if ((arrToStore[key].vehicle == vehicle) || (arrToStore[key].VIN == allVehicles[j].VIN) || (arrToStore[key].VIN == allVehicles[j].vehicleVin)) {
                            objToStore["ChangeID"] = changeId
                            objToStore["Name"] = arrToStore[key].fullName;
                            objToStore["DrivesVehicleID"] = arrToStore[key].VehicleChangeID
                            objToStore["DaysDriven"] = arrToStore[key].daysPerWeek.slice(0, 1)
                            if (arrToStore[key].milesOneWay === "Less than 3 miles one-way") {
                                objToStore["MilesOneWay"] = "1440";
                            }
                            else if (arrToStore[key].milesOneWay === "Less than 10 miles one-way") {
                                objToStore["MilesOneWay"] = "1450";
                            }
                            else if (arrToStore[key].milesOneWay === "10 or more miles one-way") {
                                objToStore["MilesOneWay"] = "1460";
                            }
                            else if (arrToStore[key].milesOneWay === "Pleasure use only") {
                                objToStore["MilesOneWay"] = "1470";
                            }
                            else if (arrToStore[key].milesOneWay === "Business use (traveling sales rep, etc.)") {
                                objToStore["MilesOneWay"] = "1480";
                            }
                            else if (arrToStore[key].milesOneWay === "Farm use") {
                                objToStore["MilesOneWay"] = "1490";
                            }
                            arrToPush.push(objToStore)
                        }
                    }
                    else if ("replacementVehicle" in allVehicles[j]) {
                        var vehicle = `${allVehicles[j].replacementVehicle.vehicleYear} ${allVehicles[j].replacementVehicle.vehicleMake} ${allVehicles[j].replacementVehicle.vehicleModel}`
                        if (arrToStore[key].vehicle === vehicle) {
                            objToStore["ChangeID"] = changeId
                            objToStore["Name"] = arrToStore[key].fullName;
                            objToStore["DrivesVehicleID"] = arrToStore[key].VehicleChangeID
                            objToStore["DaysDriven"] = arrToStore[key].daysPerWeek.slice(0, 1)
                            if (arrToStore[key].vehicle === "NotPrimaryDriver") {
                                objToStore["MilesOneWay"] = "1500";
                                objToStore["DaysDriven"] = "0";
                            }
                            else if (arrToStore[key].milesOneWay === "Less than 3 miles one-way") {
                                objToStore["MilesOneWay"] = "1440";
                            }
                            else if (arrToStore[key].milesOneWay === "Less than 10 miles one-way") {
                                objToStore["MilesOneWay"] = "1450";
                            }
                            else if (arrToStore[key].milesOneWay === "10 or more miles one-way") {
                                objToStore["MilesOneWay"] = "1460";
                            }
                            else if (arrToStore[key].milesOneWay === "Pleasure use only") {
                                objToStore["MilesOneWay"] = "1470";
                            }
                            else if (arrToStore[key].milesOneWay === "Business use (traveling sales rep, etc.)") {
                                objToStore["MilesOneWay"] = "1480";
                            }
                            else if (arrToStore[key].milesOneWay === "Farm use") {
                                objToStore["MilesOneWay"] = "1490";
                            }
                            arrToPush.push(objToStore)
                        }
                        else {
                            continue
                        }
                    }
                }
                else if (UserState === "Michigan") {
                    var vehicle;
                    if ("Make" in allVehicles[j] && !("michiganVehicleMake" in allVehicles[j])) {
                        vehicle = `${allVehicles[j].Year} ${allVehicles[j].Make} ${allVehicles[j].Model}`
                    }
                    else if ("michiganVehicleMake" in allVehicles[j]) {
                        vehicle = `${allVehicles[j].michiganVehicleYear} ${allVehicles[j].michiganVehicleMake} ${allVehicles[j].michiganVehicleModel}`
                    }

                    if (arrToStore[key].vehicle === vehicle) {
                        objToStore["ChangeID"] = changeId;
                        objToStore["Name"] = arrToStore[key].fullName;
                        objToStore["DrivesVehicleID"] = arrToStore[key].VehicleChangeID;
                        objToStore["DaysDriven"] = arrToStore[key].daysPerWeek.slice(0, 1);
                        if (arrToStore[key].vehicle === "NotPrimaryDriver") {
                            continue;
                        }
                        else if (arrToStore[key].milesOneWay === "Less than 3 miles one-way") {
                            objToStore["MilesOneWay"] = "1440";
                        }
                        else if (arrToStore[key].milesOneWay === "Less than 10 miles one-way") {
                            objToStore["MilesOneWay"] = "1450";
                        }
                        else if (arrToStore[key].milesOneWay === "10 or more miles one-way") {
                            objToStore["MilesOneWay"] = "1460";
                        }
                        else if (arrToStore[key].milesOneWay === "Pleasure use only") {
                            objToStore["MilesOneWay"] = "1470";
                        }
                        else if (arrToStore[key].milesOneWay === "Business use (traveling sales rep, etc.)") {
                            objToStore["MilesOneWay"] = "1480";
                        }
                        else if (arrToStore[key].milesOneWay === "Farm use") {
                            objToStore["MilesOneWay"] = "1490";
                        }
                        arrToPush.push(objToStore)
                    }
                    // Not a primary driver (from driver assignment selection)
                    else if (arrToStore[key].vehicle === "NotPrimaryDriver") {
                        objToStore["ChangeID"] = changeId
                        objToStore["Name"] = arrToStore[key].fullName;
                        objToStore["MilesOneWay"] = "1500";
                        objToStore["DaysDriven"] = "0";

                        // If the driver is already in the array to insert
                        if (arrToPush.some(e => e.Name == arrToStore[key].fullName)) {
                            continue
                        }
                        else {
                            arrToPush.push(objToStore)
                        }
                    }
                }
            }
        }
        allDrivers = arrToStore;
    }
    try {
        const response = await pushPerson(UserState, RequestType, arrToPush, platform);
        console.log(response)
        CheckCodes(changeId, pdfByte, props, userData);
    }
    catch (e) {
        console.log(e)
    }
}

// Checks ChangeId and type
const CheckCodes = async (changeId, pdfByte, props, userData) => {
    var ChangeID = changeId;
    var UserState = userData.state;
    var EmailAddress = userData.emailAddress;
    // var EmailAddress = "christopher.chi@vazkor.com";
    var FirstName = userData.firstName;

    try {
        var emailResp;
        // Emails Temp ID Cards
        if (UserState == "New Jersey") {
            emailResp = emailTempIDCard(ChangeID, "TempIDCardVehicle_NJ", UserState, EmailAddress, FirstName)
        }
        else if (UserState == "Pennsylvania") {
            emailResp = emailTempIDCard(ChangeID, "TempIDCardVehicle_PA", UserState, EmailAddress, FirstName)
        }
        const response = await checkCodes({ ChangeID, requestType: "Vehicle", UserState, EmailAddress });
        // pdfByte = response.data;
        // var link = document.createElement('a');
        // link.href = "data:application/octet-stream;base64," + pdfByte;
        // link.download = "VehicleChange.pdf";
        // link.click();

        props.setConfirmationLoad(false);
        props.navigateToConfirmation("/update-vehicles/confirmation");
    }
    catch (e) {
        console.log(e)
    }
}

const InsertIntoDatabase = async (pdfByte, props, PolicyNumber, userData) => {
    var RequestType = "Vehicle";
    await InsertMain(RequestType, pdfByte, props, PolicyNumber, userData);
}

const SaveVehicleChange = async (props) => {
    var PolicyNumber = storageData.getItem('policyNumber');
    const userData = props.userData
    if (PolicyNumber) {
        var pdfByte;
        await InsertIntoDatabase(pdfByte, props, PolicyNumber, userData);
    }
}

export default SaveVehicleChange