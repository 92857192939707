import { useEffect, useState } from "react"
import HeaderQS from "../../components/QuickService/HeaderQS"
import { updateOneIncTokens } from "../../services/Api"
import { Spinner } from 'react-bootstrap';
import { getDomainOneInc } from "../../services/Utils";


const QSBillingInfo = (props) => {
    const getPaymentData = async () => {
        setPaymentData({ result: props.user.userData.paymentData, complete: true })
    }
    const { policyNumber, systemId, env, zip } = props.user.userData
    const [paymentData, setPaymentData] = useState({ result: {}, complete: false })
    const [paymentToken, setPaymentToken] = useState("")

    if (!paymentData.complete) {
        getPaymentData()
    }

    const MakePayment = () => {
        var Amt = FormatValue(paymentData.result.openamt)
        if (!Amt.includes('-')) {
            window.location.replace(`https://${getDomainOneInc()}/?Token=${paymentToken}`)
        }
    }

    const checkOneIncTokens = async () => {
        let paymentToken
        const updateOneIncTokens_response = await updateOneIncTokens(policyNumber, zip, env, ["payment"], "QuickSVC" )
        paymentToken = updateOneIncTokens_response.Tokens.find(x => x.Type === "payment").Value
        setPaymentToken(paymentToken)
    }
    useEffect(() => {
        checkOneIncTokens()
    },[])
    return (
        <>
            <HeaderQS title={"BILLING INFORMATION"} />
            <div className="d-flex align-content-center justify-content-center">
                <div className="m-3 p-2 rounded-3  container" style={{ width: "1000px", border: "1px solid gray", borderTop: "10px solid #366898" }}>

                    <div className="panel">
                        <div className="billing-info row clearfix">
                            <div className="col-sm-7">
                                {
                                    paymentData.complete ?
                                        <div className="text-primary font-weight-bold">

                                            <div className="border-top">
                                                <h5><strong>TOTAL BALANCE:</strong></h5>
                                                <h6><strong>{FinalValue(FormatValue(paymentData.result.totalamt))}</strong></h6>
                                            </div>
                                            <div className="border-top">
                                                <h5><strong>NEXT INSTALLMENT DUE:</strong></h5>
                                                <h6><strong>{FinalValue(FormatValue(paymentData.result.openamt))}</strong></h6>
                                            </div>
                                            <div className="border-top">
                                                <h5><strong>NEXT INSTALLMENT DUE DATE:</strong></h5>
                                                <h6><strong>{FormatDate(paymentData.result.duedt)}{(paymentData.result.duedt === 'N/A' || paymentData.result.duedt === 'No Payment Due') ? '' : " at 12:01 AM"}</strong></h6>
                                            </div>
                                            <div className="border-top">
                                                <h5><strong>LAST PAYMENT AMOUNT:</strong></h5>
                                                <h6><strong>{FinalValue(FormatValue(paymentData.result.paidamt))}</strong></h6>
                                            </div>
                                            <div className="border-top">
                                                <h5><strong>LAST PAYMENT REC'D:</strong></h5>
                                                <h6><strong>{FormatDate(paymentData.result.accountingdt)}</strong></h6>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="border-top text-primary font-weight-bold">
                                                <h5><strong>LOADING</strong></h5>
                                                <Spinner animation="border" />
                                            </div>
                                        </>
                                }
                            </div>
                            <div className="col-sm-4 ">
                                <div>
                                    <p>Not seeing your recent payment? Allow 2-3 business days for your payment to show.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                If your Next Installment is due and has not been paid, click the button below to go make your payment.<br /><br />

                                <div className="d-flex container-fluid justify-content-center">
                                    <button className="btn btn-primary px-4 flex-grow-1 flex-md-grow-0" onClick={MakePayment} >
                                        Make a Payment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <br clear="all" />
                    </div>
                </div>
            </div>
        </>
    )
}

const FormatValue = (value) => {
    if (value === 'N/A' || value === 'No payment due.' || value == null)
        return value
    var regex = /[+-]?[0-9]+\.?[0-9]*/g

    var valueString = regex
        .exec(value.replace(",", ""))[0]
    valueString = parseFloat(valueString)
        .toString()
    var newValue = valueString
    if (!valueString.includes('.')) {
        newValue = valueString + '.00'
    } else {
        newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
    }
    return newValue
}
const FinalValue = (value) => {
    if (!value || value == 'N/A' || value?.toLowerCase()?.includes('no payment due'))
        return value
    var newValue = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return newValue
}

const FormatDate = (tempDate) => {
    if (tempDate === 'N/A' || tempDate?.toLowerCase()?.includes('no payment due') || tempDate === null)
        return tempDate
    tempDate = new Date(tempDate)
    var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
    var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
    var dateYear = `${tempDate.getFullYear()}`
    const date = `${dateMonth}-${dateDay}-${dateYear}`
    return date

}

export default QSBillingInfo