import CollisionDeductibles from "../../VehicleDropdowns/CollisionDeductibles"
import ComprehensiveDeductiblesDropdown from "../../VehicleDropdowns/ComprehensiveDeductibles"

const VehicleAddMI_Coverages = ({ vehicleAddContext, context }) => {

    return (
        <>
            {/* Other than Collision Deductibles */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    Choose your "Other than Collision" (Comprehensive) deductible. *
                    <a className="info-icon-for-select ms-2">
                        <i className="fa fa-question-circle"
                            onClick={() => {
                                context.setValidationMsgToggle(`CURE Auto "Other than Collision" (Comprehensive Coverage)`,
                                    `"Other than Collision" (Comprehensive) covers your vehicle, and sometimes other vehicles you may be driving, for losses resulting from incidents other than collision. 
                                    For example, "Other than Collision" (Comprehensive) insurance covers damage to your car if it is stolen, or damaged by flood, fire, or animals. 
                                    It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a deductible as you feel comfortable paying out of pocket. 
                                    Coverage is limited to the terms and conditions contained in the policy.`)
                            }}
                        />
                    </a>
                </div>

                <div className="col-8">
                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option"
                        value={vehicleAddContext.newVehicle?.standardDeductibleComprehensive ? vehicleAddContext.newVehicle?.standardDeductibleComprehensive : ""}
                        onChange={(e) => vehicleAddContext.saveAddition('standardDeductibleComprehensive', e.target.value)}
                    >
                        {ComprehensiveDeductiblesDropdown()}
                    </select>
                </div>
            </div>

            {/* Collision Types */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    Choose your "Collision" type. *
                    <a className="info-icon-for-select ms-2">
                        <i className="fa fa-question-circle"
                            onClick={() => {
                                context.setValidationMsgToggle("What are the options for Collision coverage?", collisionCoverages)
                            }}
                        />
                    </a>
                </div>

                {/* Collision Dropdown */}
                <div className="col-8">
                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option"
                        value={vehicleAddContext.newVehicle?.collisionCoverageType ? vehicleAddContext.newVehicle?.collisionCoverageType : ""}
                        onChange={(e) => vehicleAddContext.saveAddition('collisionCoverageType', e.target.value)}
                    >
                        <option value={""} disabled>Please make a selection</option>
                        <option value={"Standard"}>Standard</option>
                        <option value={"Limited"}>Limited</option>
                        <option value={"Broadened"}>Broadened</option>
                    </select>
                </div>
            </div>

            {/* Collision Coverage Deductible */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    Choose your "Collision" coverage deductible. *
                    <a className="info-icon-for-select ms-2">
                        <i className="fa fa-question-circle"
                            onClick={() => {
                                context.setValidationMsgToggle("CURE Auto - Collision Coverage",
                                    `Collision covers damage to your car when your car hits, or is hit by, another vehicle, or other object. This coverage pays to fix your vehicle less the deductible you choose. 
                                    To keep your premiums low, select as large a deductible as you feel comfortable paying out of pocket. 
                                    Coverage is limited to the terms and conditions contained in the policy.`)
                            }}
                        />
                    </a>
                </div>

                <div className="col-8">
                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option"
                        value={vehicleAddContext.newVehicle?.standardDeductibleCollision ? vehicleAddContext.newVehicle?.standardDeductibleCollision : ""}
                        onChange={(e) => vehicleAddContext.saveAddition('standardDeductibleCollision', e.target.value)}
                    >
                        {CollisionDeductibles(vehicleAddContext.newVehicle?.collisionCoverageType)}
                    </select>
                </div>
            </div>

            {/* Rental Reimbursement */}
            <div className="row form-group form-group-sm align-items-center my-4">
                <div className="text-primary fw-bold">
                    Rental Reimbursement *
                    <a className="info-icon-for-select ms-2">
                        <i className="fa fa-question-circle"
                            onClick={() => {
                                context.setValidationMsgToggle("Rental Reimbursement",
                                    `Rental Reimbursement pays for the cost of a rental car if you can't drive the insured vehicle due to theft or damage 
                                    from an accident. Extended Transportation coverage cannot be purchased unless the car is covered under 'Other than Collision' 
                                    (Comprehensive) coverage. Coverage is limited to the terms and conditions contained in the policy.`)
                            }}
                        />
                    </a>
                </div>

                {/* Rental Reimbursement Response */}
                <div className="col-xl-12 mt-2 text-muted">
                    <div className="radio-inline form-check form-check-inline col-12">
                        <label className="form-check-label">
                            <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={'71000'}
                                checked={(vehicleAddContext.newVehicle?.rentalReimbursement === '71000')}
                                onChange={(e) => { vehicleAddContext.saveAddition("rentalReimbursement", e.target.value) }}
                            />None
                        </label>
                    </div>
                    <div className="radio-inline form-check form-check-inline col-12">
                        <label className="form-check-label">
                            <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={'71200'}
                                checked={(vehicleAddContext.newVehicle?.rentalReimbursement == '71200')}
                                onChange={(e) => { vehicleAddContext.saveAddition("rentalReimbursement", e.target.value) }}
                            />$30 per day/$900 max
                        </label>
                    </div>
                    <div className="radio-inline form-check form-check-inline col-12">
                        <label className="form-check-label">
                            <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={'75043'}
                                checked={(vehicleAddContext.newVehicle?.rentalReimbursement == '75043')}
                                onChange={(e) => { vehicleAddContext.saveAddition("rentalReimbursement", e.target.value) }}
                            />$40 per day/$1,200 max
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

const collisionCoverages =
    <>
        <strong>Broad Collision</strong>: Pays for damages to your vehicle that was damaged regardless of fault, with a deductible that applies only if you are more than 50% at fault <br /><br />
        <strong>Standard Collision</strong>: Pays for damages to your vehicle that was caused regardless of fault, with a deductible that always applies. <br /><br />
        <strong>Limited Collision</strong>: Pays for damage to your vehicle if you are less than 50% at fault for an accident. If you are 50% or more at fault Collision coverage for this option will not apply to repair your vehicle.
    </>

export default VehicleAddMI_Coverages