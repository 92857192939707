import Images from "../assets/images/Images"
import ErrorBoundary from "./ErrorBoundary"

const RoundyContainer = (props) => {

    var { Content, ContentFooter, NoRoundy, params } = props
    ContentFooter = (ContentFooter) ? props.ContentFooter : () => <></>
    Content = (Content) ? props.Content : () => <></>

    try {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-lg-push-1 offset-lg-1 col-md-12">
                            <div className="row">
                                <div className="col-lg-10 col-lg-push-1 offset-lg-1">
                                    <div className="border shadow-sm rounded-3 pt-3 border-2 mx-1 mt-1 row">
                                        <div className="d-flex justify-content-center flex-column">
                                            <ErrorBoundary>
                                                <Content {...params} />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {
                                                !NoRoundy &&
                                                <img src={Images.halfRoundy} alt="Roundy" />
                                            }
                                        </div>
                                    </div>

                                    <ContentFooter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    catch (err) {
        throw new Error(err)
    }
}

export default RoundyContainer