import { useNavigate } from "react-router-dom";
import EbillingTerms from "../components/EbillingTerms";
import EbillingVerification from "../components/EbillingVerification";
import Menu from "../components/Menu";
import RoundyContainer from "../components/RoundyContainer";

const Content = (props) => {
  console.log(props);
  const { userData } = props.user;

  const navigate = useNavigate()

  const OnClick =()=>{
    navigate("/home")
  }

  return (
    <>
      <h4 className="text-center mt-3 section-title">
        CONGRATULATIONS!
      </h4>
      <small className="m-1 text-muted text-center">
        <p>You have successfully registered to receive your policy documents electronically.</p>
        <p>You will no longer receive paper copies of your bills in the mail.</p>
        <p>Please keep us advised of your current email address so that we can notify you when your bills become available.</p>


      </small>
      <div className="d-flex justify-content-center">
        <input
        onClick={OnClick}
          type="button"
          className="btn btn-primary-grad"
          value="Return to My Account Home"
        />
      </div>

    </>
  )
};

const RegisterEbillingFinish = (props) => {
  const { userData } = props.user
  return (
    <>
      <Menu Title={"GO PAPERLESS"} FirstName={userData.firstName} userData={userData}  />
      <RoundyContainer Content={Content} params={props} />
    </>
  );
};
export default RegisterEbillingFinish;
