export const relationship = [
    "Please select...",
    "Aunt",
    "Brother",
    "Daughter",
    "Father",
    "Granddaughter",
    "Grandfather",
    "Grandmother",
    "Grandson",
    "In-Law",
    "Mother",
    "Nephew",
    "Niece",
    "Non-Relative",
    "Other",
    "Significant Other",
    "Sister",
    "Son",
    "Spouse",
    "Uncle",
]
