import { useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import { storageData } from "../../services/Services";
import { AddressChangeContext } from "./AddressChangeContext";
import { submitChangeRequest, validateAddress } from "services/Api";
import AddressPageValidation from "./AddressPageValidation";

const AddressChangeBtnNavigation = (props) => {
    const context = useContext(AddressChangeContext)
    const navigate = useNavigate()
    var screen = window.location.pathname.split("/").pop()
    var state = context?.userData?.state
    const [validationLoad, setValidationLoad] = useState(false)
    const [confirmationLoad, setConfirmationLoad] = useState(false)
    const attestRef = useRef(false)

    const nextButtonNavigate = async () => {
        // Validation check
        var { title, message, valid } = AddressPageValidation({ screen, addressChangeClass: context.addressChangeClass })
        if (!valid) {
            context.setValidationMsgToggle(title, message)
            return
        }

        // API call for validating address
        if (screen == "new-address") {
            setValidationLoad(true)
            var data = {}
            data["addrTypeCd"] = "ContactAddr"
            data["addr1"] = context?.addressChangeClass?.newResidence?.mailingAddress
            data["city"] = context?.addressChangeClass?.newResidence?.mailingCity
            data["postalCode"] = context?.addressChangeClass?.newResidence?.mailingZip
            data["stateProvCd"] = context?.addressChangeClass?.newResidence?.mailingState
            data["regionCd"] = "US"
            data["regionISOCd"] = "US"

            const response = await validateAddress(data)
            setValidationLoad(false)
            if (response.data == "Invalid") {
                context.setValidationMsgToggle("Oops!", "That is not a valid city/zip combination for " + context?.userData?.state + " for a mailing address.")
                return
            }
        }

        // Update for garaged vehicles
        if (screen == "new-address") {
            if (context.addressChangeClass.vehicles.keptOvernightResponse == "No") {
                updateGaragedVehicles(context)
            }
        }
        // Updates the driver assignment drivers with edited/new drivers
        if (screen == "household") {
            var storedDriversCopy = context?.addressChangeClass?.driversHHMs?.drivers?.storedDrivers
            var newDriversCopy = context?.addressChangeClass?.driversHHMs?.drivers?.newDrivers
            var allDrivers = [...storedDriversCopy, ...newDriversCopy]
            context.addressChangeClass.driverAssignment.drivers = allDrivers
        }
        if (screen == "summary") {
            if (!(attestRef.current.checked) && context?.userData?.state == "Michigan") {
                context.setValidationMsgToggle("Oops!", "Please attest to confirm all answers to the questions listed in the change are true and accurate to the best of your knowledge.")
                return
            }
        }
        storageData.setItem("AddressChangeClass", JSON.stringify(context.addressChangeClass))

        // Sends change class to backend
        if (screen == "summary") {
            setConfirmationLoad(true)

            var req = {
                "requestType": "Address",
                "classRequest": storageData.getItem("AddressChangeClass")
            }
            // Api call to send data
            navigate(navigateToNext(screen))
            var resp = await submitChangeRequest(req)
            var changeId = resp?.data
            storageData.setItem("AddressChangeID", changeId)
            return
        }
        navigate(navigateToNext(screen))
    }

    const checkNewDrivers = () => {
        var newDrivers = context?.addressChangeClass?.driversHHMs?.drivers?.newDrivers
        if (newDrivers?.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    const navigateBack = (screen) => {
        var screenObj = {
            "new-address": "/home",
            "household": "/change-address/new-address",
            "additional": "/change-address/household",
            "driver-assignment": (checkNewDrivers() && state != "Michigan") ? "/change-address/additional" : "/change-address/household",
            "summary": "/change-address/driver-assignment",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    const navigateToNext = (screen) => {
        var screenObj = {
            "new-address": "/change-address/household",
            "household": (checkNewDrivers() && state != "Michigan") ? "/change-address/additional" : "/change-address/driver-assignment",
            "additional": "/change-address/driver-assignment",
            "driver-assignment": "/change-address/summary",
            "summary": "/change-address/confirmation",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    // Updates garage location for garaged vehicles if there is no location set for the garaged vehicle (i.e. garagedStreetAddress = undefined and garaged)
    const updateGaragedVehicles = (context) => {
        const getUpdatedVehicle = (vehicle, location) => ({
            ...vehicle,
            garagedStreetAddress: vehicle?.garaged && vehicle?.garagedStreetAddress === undefined ? location?.garagedStreetAddress : vehicle?.garagedStreetAddress,
            garagedCity: vehicle?.garaged && vehicle?.garagedCity === undefined ? location?.garagedCity : vehicle?.garagedCity,
            garagedState: vehicle?.garaged && vehicle?.garagedState === undefined ? location?.garagedState : vehicle?.garagedState,
            garagedZipCode: vehicle?.garaged && vehicle?.garagedZipCode === undefined ? location?.garagedZipCode : vehicle?.garagedZipCode,
        });

        const storedVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.storedVehicles;
        const newVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.newVehicles;

        const vehLocationStored = storedVehicles?.find((veh) => veh?.garaged && veh?.garagedStreetAddress !== undefined);
        const vehLocationNew = newVehicles?.find((veh) => veh?.garaged && veh?.garagedStreetAddress !== undefined);
        const vehLocation = vehLocationStored || vehLocationNew;

        const updatedStoredVeh = storedVehicles.map((vehicle) => getUpdatedVehicle(vehicle, vehLocation));
        const updatedNewVeh = newVehicles.map((vehicle) => getUpdatedVehicle(vehicle, vehLocation));

        context.addressChangeClass.vehicles.vehiclesOnPolicy.storedVehicles = updatedStoredVeh
        context.addressChangeClass.vehicles.vehiclesOnPolicy.newVehicles = updatedNewVeh
    }

    return (
        <>
            <hr className="my-2" />
            <div className="mt-2 mb-3">
                {validationLoad &&
                    <div className="text-center">
                        Validating Address... &nbsp;
                        <Spinner animation="border" />
                    </div>
                }
                {confirmationLoad &&
                    <div className="text-center">
                        Confirming Changes... &nbsp;
                        <Spinner animation="border" />
                    </div>
                }
                {!confirmationLoad &&
                    <div>
                        {/* Attestation - only on SUMMARY page (MICHIGAN) */}
                        {screen == "summary" && context?.userData?.state == "Michigan" &&
                            <>
                                <label className="mb-4" style={checkBoxText}>
                                    <input type="checkbox" ref={attestRef} />
                                    <span className="ms-2">I hereby certify that the answers to the questions listed in the change are true and accurate to the best of my knowledge.*</span>
                                    <br />
                                </label>
                            </>
                        }

                        {/* Back Button */}
                        {screen !== "new-address" &&
                            <>
                                <button type="button" className="btn btn-default editCancelBtn" onClick={() => { navigate(navigateBack(screen)) }}>
                                    BACK
                                </button>
                            </>
                        }
                        {/* Continue Button */}
                        {!validationLoad &&
                            <button type="submit" className="btn btn-primary-grad right pull-right editCancelBtn" onClick={() => { nextButtonNavigate() }}>
                                NEXT
                            </button>
                        }
                    </div>
                }
            </div>
        </>
    )
}

const checkBoxText = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '13px'
}

export default AddressChangeBtnNavigation