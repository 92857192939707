import StateDropdown from "components/RenewalQuestionnaireComp/DriversOHM/StateDropdown"

const Additional_KeptOvernightResponse = ({ setResponse, setAddressFields, changeClass }) => {

    return (
        <>
            <hr />
            <div className="form-group form-group-sm">
                <strong className="text-primary">
                    Is the vehicle(s) listed on your policy primarily kept overnight
                    at your home address?
                </strong>
            </div>
            <div className="form-group form-group-sm text-dark ms-3">
                <div className="radio-inline form-check form-check-inline ms-2">
                    <label className="form-check-label">
                        <input type={"radio"} className="form-check-input" name="radOvernightAtHome" value={"Yes"}
                            checked={changeClass?.additionalPage["keptOvernight"]["response"] == "Yes"}
                            onChange={(e) => setResponse("keptOvernight", e.target.value)}
                        />Yes
                    </label>
                </div>
                <div className="radio-inline form-check form-check-inline">
                    <label className="form-check-label">
                        <input type={"radio"} className="form-check-input" name="radOvernightAtHome" value={"No"}
                            checked={changeClass?.additionalPage["keptOvernight"]["response"] == "No"}
                            onChange={(e) => setResponse("keptOvernight", e.target.value)}
                        />No
                    </label>
                </div>
            </div>
            {/* Display address fields for where vehicles are kept elsewhere */}
            <div className={`slide-up ${changeClass?.additionalPage?.keptOvernight?.response == "No" ? 'slide-up-enter' : 'slide-up-exit'}`}>
                {changeClass?.additionalPage?.keptOvernight?.response == "No" &&
                    <div className="ms-3">
                        <div className="form-group form-group-sm">
                            <strong className="text-primary">Please indicate where the vehicle is kept.</strong>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <strong className="form-group form-group-sm text-primary">Address</strong>
                                <input type={"text"} className="form-control" placeholder="Address..."
                                    value={changeClass?.additionalPage?.keptOvernight?.address ? changeClass?.additionalPage?.keptOvernight?.address : ""}
                                    onChange={(e) => setAddressFields("address", e.target.value)}
                                />
                            </div>
                            <div className="col-sm-6 mt-1">
                                <strong className="form-group form-group-sm text-primary">City</strong>
                                <input type={"text"} className="form-control" placeholder="City..."
                                    value={changeClass?.additionalPage?.keptOvernight?.city ? changeClass?.additionalPage?.keptOvernight?.city : ""}
                                    onChange={(e) => setAddressFields("city", e.target.value)}
                                />
                            </div>
                            <div className="col-sm-3 mt-1">
                                <strong className="form-group form-group-sm text-primary">State</strong>
                                <div className="col-xl-8">
                                    <div className="btn-group bootstrap-select">
                                        <select className="btn btn-default btn-group dropdown-toggle filter-option" style={{ marginTop: "-5px" }}
                                            value={changeClass?.additionalPage?.keptOvernight?.state ? changeClass?.additionalPage?.keptOvernight?.state : "State"}
                                            onChange={(e) => setAddressFields("state", e.target.value)}
                                        >
                                            {StateDropdown()}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 mt-1">
                                <strong className="form-group form-group-sm text-primary">Zip</strong>
                                <input type={"text"} className="form-control" placeholder="Zip..."
                                    value={changeClass?.additionalPage?.keptOvernight?.zip ? changeClass?.additionalPage?.keptOvernight?.zip : ""}
                                    onChange={(e) => setAddressFields("zip", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Additional_KeptOvernightResponse