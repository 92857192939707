import { AddVehicleContext } from "components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext";
import { NewVehicleContext } from "components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext";
import { ValidationForm } from "components/StraightThroughVehicleComp/ValidationForms/ValidationsForm";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { storageData } from "services/Services";

const VehicleBtnNav = () => {
    const navigate = useNavigate()
    var screen = window.location.pathname.split("/").pop()

    const addVehicleContext = useContext(AddVehicleContext)
    const newVehicleContext = useContext(NewVehicleContext)
    var newVehicles = addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles
    var currentComponent = newVehicles[0]?.componentTracker
    const components = ["VinInput", "VehicleInfo", "VehiclePurchased"]
    const formComponents = ["VehicleOwned", "VehicleLeased", "VehicleFinanced"]

    // Back button component navigation
    const navigateBack = () => {
        // Return to home page
        if (newVehicles.length == 0 || currentComponent == "VehicleInfo") {
            window.location.href = "/home"
        }
        // Return to vin input page
        else if (formComponents.includes(currentComponent) || currentComponent == "VehiclePurchased") {
            var addVehicleClassCopy = addVehicleContext.addVehicleClass
            addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles.length = 0;
            addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
            newVehicleContext.setNewVehicle({ ...newVehicleContext.initialVehicle })
            storageData.setItem("AddVehicleClass", JSON.stringify(addVehicleClassCopy))
        }
        // Return to form (owned, leased, financed)
        else if (currentComponent == "VehicleDriverAssignment") {
            updateComponentTracker("Vehicle" + newVehicleContext.newVehicle.vehicleOwnedLeasedFinanced)
        }
    }

    // Next button component navigation
    const nextButtonNavigate = async () => {
        var incomplete = false;
        var alertMsg = ValidationForm(newVehicleContext.newVehicle, addVehicleContext.addVehicleClass.driverAssignment.drivers, addVehicleContext.userData.state)

        if (alertMsg) {
            addVehicleContext.setValidationMsgToggle(
                alertMsg?.title || 'Oops!',
                alertMsg?.message || 'Please certify that the answers to the questions listed in the change are true and accurate to the best of your knowledge to submit your request.'
            );
            return;
        }

        // Go to driver assignment page
        if (formComponents.includes(currentComponent)) {
            // Go to confirm page if selected "NO" for vehicle not driven by existing driver
            if (newVehicleContext.newVehicle?.drivenExistingDriver == "0") {
                var addVehicleClassCopy = addVehicleContext.addVehicleClass
                addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles[0] = newVehicleContext.newVehicle
                addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
                storageData.setItem("AddVehicleClass", JSON.stringify(addVehicleClassCopy))
                navigate("/endorsement/confirm")
                return
            }
            updateComponentTracker("VehicleDriverAssignment")
        }
        // Navigate to Confirm page
        else if (currentComponent == "VehicleDriverAssignment") {
            navigate("/endorsement/confirm")
        }
    }

    // Updates the use-state for immediate re-render
    const updateComponentTracker = (componentName) => {
        newVehicleContext.newVehCallback("componentTracker", componentName)

        var addVehicleClassCopy = addVehicleContext.addVehicleClass
        addVehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles[0] = newVehicleContext.newVehicle
        addVehicleContext.setAddVehicleClass({ ...addVehicleClassCopy });
        storageData.setItem("AddVehicleClass", JSON.stringify(addVehicleClassCopy))
    }

    return (
        <>
            <div className="mt-2 mb-3">
                <div>
                    {/* Back Button */}
                    <button type="button" className="btn btn-default editCancelBtn" onClick={() => { navigateBack() }}>
                        BACK
                    </button>
                    {/* Continue Button */}
                    {newVehicles?.length > 0 && (formComponents.includes(newVehicles[0]?.componentTracker) || currentComponent == "VehicleDriverAssignment") &&
                        <button type="submit" className="btn btn-primary-grad right pull-right editCancelBtn" onClick={() => { nextButtonNavigate() }}>
                            NEXT
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export default VehicleBtnNav