import { useContext } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"

const VehiclesPending = () => {
    const context = useContext(VehicleChangeContext)
    var pendingNewVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy?.pendingNewVehicles
    var pendingRemovedVehiclesList = context.vehicleChangeClass.vehicles.vehiclesOnPolicy?.pendingRemovedVehicles

    return (
        <>
            {/* Removed Vehicles Pending */}
            {pendingRemovedVehiclesList.map((vehicle, key) => {
                return (
                    <div key={key}>
                        <hr className="less-margin expandHeight" />
                        <strong className="justify-content-left">
                            <span>
                                <span className="small text-uppercase text-muted">
                                    {vehicle.Year + " " + vehicle.Make + " " + vehicle.Model}

                                </span>
                                <span className="small fw-bold fst-italic text-muted">{vehicle?.Status == "Modified" ? " - Modify Pending" : " - Remove Pending"}</span>
                            </span>
                        </strong>
                    </div>
                )
            })}
            {/* New Vehicles Pending */}
            {pendingNewVehiclesList.map((vehicle, key) => {
                return (
                    <div key={key}>
                        <hr className="less-margin expandHeight" />
                        <strong className="justify-content-left">
                            <span>
                                <span className="small text-uppercase text-muted">
                                    {vehicle.Year + " " + vehicle.Make + " " + vehicle.Model}

                                </span>
                                <span className="small fw-bold fst-italic text-muted"> - Add Pending</span>
                            </span>
                        </strong>
                    </div>
                )
            })}
        </>
    )
}

export default VehiclesPending