import EbillingTerms from "../components/EbillingTerms";
import EbillingVerification from "../components/EbillingVerification";
import Menu from "../components/Menu";
import RoundyContainer from "../components/RoundyContainer";

const Content = (props) => {
  console.log(props);
  const { userData } = props.user;

  return (
    <>
      {userData.billingRegistered ? (
        <>
          <h4 className="text-center section-title">
            CHANGE YOUR PAPERLESS BILLING SETTINGS
          </h4>
          <small className="m-1 text-muted">
            If you wish to unsubscribe,{" "}
            <strong>please complete the form above</strong> and submit by
            clicking the Unsubscribe button below.
          </small>
          <div className="d-flex justify-content-center">
            <button className="btn btn-secondary btn-sm m-1 border">
              CANCEL
            </button>
            <button className="btn btn-primary-grad btn-sm m-1">
              UNSUBSCRIBE
            </button>
          </div>
        </>
      ) : (
        <>
          <h4 className="text-start section-title">
            AGREE TO THE TERMS & CONDITIONS
          </h4>
          <small className="m-1 text-muted">
            You <strong>MUST</strong> read and scroll to the bottom in order to
            register to receive all of your policy documents electronically.
          </small>
          <hr />
          <EbillingTerms />
          <hr />
          <EbillingVerification {...props} />
        </>
      )}
    </>
  );
};

const RegisterEbilling = (props) => {
  const { userData } = props.user
  return (
    <>
      <Menu Title={"GO PAPERLESS"} FirstName={userData.firstName} userData={userData} />
      <RoundyContainer Content={Content} params={props} />
    </>
  );
};
export default RegisterEbilling;
