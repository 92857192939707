import './HelpfulDocumentsNJ.css'

const HelpfulDocumentsNJ = ({policyType}) => {
  return (
    <>
        <hr className='helpful-divider' />
        <div className="fw-bold">
            Helpful Documents for Reference:
            <br/>
            <div className="helpful-links-container">
                <a href={`https://assets.cure.com/pdf/forms/${policyType === "Standard" ? "Standard" : "Basic"}_CSF-0223.pdf`} className='helpful-nj-link' target="_blank">
                    NJ {policyType === "Standard" ? "Standard" : "Basic"} Coverage Selection Form
                </a>
                <br/>
                <a href="https://assets.cure.com/pdf/forms/NJC-BG-0123.pdf" className='helpful-nj-link' target="_blank">
                    NJ Buyer's Guide
                </a>
            </div>
        </div>
    </>
  )
}

export default HelpfulDocumentsNJ