const EditMemberValidationMI = (props) => {
    if (props.editFields?.edithhm == "") {
        props.handleShow()
        props.handleValidationMsg("Please select what you would like to edit about the household member.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "0" && props.editFields?.moveOutDate == "") {
        props.handleShow()
        props.handleValidationMsg("Please select when this person moved out the household.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "") {
        props.handleShow()
        props.handleValidationMsg("Please select how this person's insurance will be handled now having a driver's license.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "0" && props.editFields.licenseNumber == "") {
        props.handleShow()
        props.handleValidationMsg("Please provide the license number.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "0" && props.editFields.licenseAge == "") {
        props.handleShow()
        props.handleValidationMsg("Please provide the age of when this driver was first licensed.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "0" && props.editFields.disorder == "") {
        props.handleShow()
        props.handleValidationMsg("Please specify whether or not the household member has a disorder or condition that may impair their ability to safely operate a motor vehicle.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "0" && props.editFields.impairments == "") {
        props.handleShow()
        props.handleValidationMsg("Please specify whether or not the household member has any mental or physical impairments.", "Oops!")
        return false
    }
    else if (props.editFields?.edithhm == "1" && props.editFields.edithhmNested == "0" && props.editFields.college == "") {
        props.handleShow()
        props.handleValidationMsg("Please specify whether or not the driver is enrolled in a college or university.", "Oops!")
        return false
    }
    else {
        return true
    }
}

export default EditMemberValidationMI