const NameRegisteredDropdown = () => {
    return (
        <>
            <option value={"0"} disabled>Select from following...</option>
            <option value={"43070"}>Self</option>
            <option value={"43170"}>Spouse</option>
            <option value={"43270"}>Self & Spouse</option>
            <option value={"43095"}>None of the Above</option>
        </>
    )
}

export default NameRegisteredDropdown