import React, { useContext } from 'react';
import { AddVehicleContext } from '../AddVehicleContext/AddVehicleContext';

const HeaderSection = () => {
  const { getTodaysDate } = useContext(AddVehicleContext);
  const currentDate = getTodaysDate();

  return (
    <div className='row justify-content-center mb-3'>
      <div className='col-12 text-center'>
        <h3 className='fw-bold text-primary text-center m-0 endorsementUpdateTitle'>
          YOUR REQUEST HAS BEEN SUBMITTED.
        </h3>
        <div className='mt-3'>
          <p className='fw-bold m-0' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20%' }}>
            Date: <span className='ms-1 text-primary'>{currentDate}</span>
          </p>
          <p className='small fst-italic' style={{ color: 'grey', padding: '0 20%', display: 'flex' }}>
            NOTE: All changes are subject to review by underwriting.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;