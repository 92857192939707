const MaritalStatus = (props) => {

    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                <div className="col-xl-4 text-blue fw-bold">
                    Marital Status:
                </div>
                <div className="col-xl-6">
                    <div className="btn-group bootstrap-select custom-selector">
                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                            defaultValue={mStatus}
                            onChange={(e) => { props.handleCallBack("mStatus", e.target.value) }}>
                            <option value={""}>Select from the following...</option>
                            {mStatus.map((item, id) => (
                                <option key={id} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

const mStatus = [
    "Single",
    "Married",
    "Divorced/Widowed",
    "Separated"
]

export default MaritalStatus