const LicenseStatusDropdown = () => {

    return (
        <>
            <option value="No License or Identification Card">No License or Identification Card</option>
            <option value="Valid Other State License">Valid Other State License</option>
            <option value="Valid Foreign License">Valid Foreign License</option>
            <option value="International License">International License</option>
            <option value="Suspended">Suspended</option>
            <option value="Revoked">Revoked</option>
            <option value="Permit">Permit</option>
            <option value="Expired">Expired</option>
            <option value="Identification Only">Identification Only</option>
        </>
    )
}

export default LicenseStatusDropdown