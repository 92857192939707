import { useContext, useEffect, useRef } from "react"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { vinLookup } from "../../../../services/Api"
import { AddVehicleContext } from "./VehicleAddMain";
import { StoredAddedVehicles } from "../VehiclesList";

const VehicleAdd_1 = () => {
    const vehicleAddContext = useContext(AddVehicleContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const context = useContext(VehicleChangeContext)
    const vinRef = useRef(null)

    useEffect(() => {
        vinRef.current?.focus();
    }, []);

    // Generates vehicle from vin
    const checkVin = async () => {
        context.setVinLoad(true)
        var vinResponse = await vinLookup(vinRef.current.value)

        // Successful vin input
        if (vinResponse?.data?.error == null && vinResponse != undefined && vinResponse?.data?.isEmpty != "1") {
            var vehicle = vehicleAddContext.newVehicle
            vehicle.vehicleVIN = vinResponse.data?.Vin
            vehicle.vehicleYear = vinResponse.data?.Year
            vehicle.vehicleMake = vinResponse.data?.Make
            vehicle.vehicleModel = vinResponse.data?.Model
            vehicle.vehicleBody = vinResponse.data?.Body
            vehicleAddContext.setNewVehicle({ ...vehicleAddContext.newVehicleDetails, ...vehicle })
            storedAddedContext.setVehicleObtained(true)

            if (vehicleAddContext.type == "replace") {
                var vehicleChangeClassCopy = context.vehicleChangeClass
                var vehiclesCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles
                vehiclesCopyList[vehicleAddContext.index] = { ...vehicleAddContext.newVehicleDetails, ...vehicle }
                vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesCopyList
                context.setVehicleChangeClass({ ...vehicleChangeClassCopy })    
            }
        }
        else {
            context.setValidationMsgToggle("Oops", "We are unable to verify the VIN that you entered. Please try again. If you continue to run into the same error, please call 800-535-2873 to proceed with your request.")
        }
        context.setVinLoad(false)
    }

    return (
        <>
            {!context.vinLoad && !storedAddedContext.vehicleObtained && vehicleAddContext?.type != "edit" &&
                <>
                    <div>
                        <p className="small text-dark">Enter your VIN number below to retrieve precise make, model and year information about your vehicle.</p>
                        <div className="row form-group">
                            <div className="col-xs-12 col-sm-8 col-md-7 col-lg-8">
                                <div className="input-group form-group">
                                    <input type={"text"} autoComplete="off" className="form-control" placeholder="Enter your VIN..." ref={vinRef} />
                                    <span className="input-group-btn">
                                        <input type={"button"} className="btn btn-default" value={"SUBMIT"} onClick={() => checkVin()} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default VehicleAdd_1