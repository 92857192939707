import Menu from 'components/Menu'
import RoundyContainer from 'components/RoundyContainer'
import BlockMain from 'components/StraightThroughVehicleComp/BlockMain'
import { AddVehicleContextComp } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { storageData } from 'services/Services'

const EndorsementBlock = (props) => {
    const navigate = useNavigate();
    const { userData } = props.user
    const websiteMaintenance = JSON.parse(storageData?.getItem("websiteMaintenance"));

    useEffect(() => {
        window.scrollTo(0, 0)

        // Redirect to maintenance page if maintenance is on for Add Vehicle Endorsement
        if (websiteMaintenance?.status?.Result == "Active" && websiteMaintenance?.status?.Pages?.includes("EndorsementAddVehicle")) {
            navigate("/maintenance")
            return
        }

    }, [])

    return (
        <>
            <AddVehicleContextComp>
                <Menu Title={"ADD VEHICLE"} FirstName={userData.firstName} userData={userData} />
                <RoundyContainer Content={BlockMain} NoRoundy
                    params={{
                        storedData: { ...props.storedData },
                        setStoredData: props.setStoredData
                    }}
                />
            </AddVehicleContextComp>
        </>
    )
}

export default EndorsementBlock