import React, { useContext } from 'react'
import { NewVehicleContext } from '../../AddVehicleContext/NewVehicleContext';
import { QuestionRow } from './QuestionRow/QuestionRow';

const AddVehicleConfirm = ({policyStateAbbr, addVehReqContext}) => {
  const context = useContext(addVehReqContext);
  const newVehicleContext = useContext(NewVehicleContext);
  const type = newVehicleContext.newVehicle.vehicleOwnedLeasedFinanced?.toLowerCase();

  // Tooltips
  const tooltips = {
    otherThanCollision: {
      title: "\"Other than Collision\" (Comprehensive)",
      content: "\"Other than Collision\" (Comprehensive) covers your vehicle, and sometimes other vehicles you may be driving, for losses resulting from incidents other than collision. For example, \"Other than Collision\" (Comprehensive) insurance covers damage to your car if it is stolen, or damaged by flood, fire, or animals. It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the policy."
    },
    collisionCoverage: {
      title: "Collision Coverage",
      content: "Collision covers damage to your car when your car hits, or is hit by, another vehicle, or other object. This coverage pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as large a deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the policy."
    },
    collisionOptions: {
      title: "What are the options for Collision coverage?",
      content: (
        <>
          <strong>Broad Collision</strong>: Pays for damages to your vehicle that was damaged regardless of fault, with a deductible that applies only if you are more than 50% at fault <br /><br />
          <strong>Standard Collision</strong>: Pays for damages to your vehicle that was caused regardless of fault, with a deductible that always applies. <br /><br />
          <strong>Limited Collision</strong>: Pays for damage to your vehicle if you are less than 50% at fault for an accident. If you are 50% or more at fault Collision coverage for this option will not apply to repair your vehicle.
        </>
      )
    },
    rentalReimbursement: {
      title: "Rental Reimbursement",
      content: "Rental Reimbursement pays for the cost of a rental car if you can't drive the insured vehicle due to theft or damage from an accident. Extended Transportation coverage cannot be purchased unless the car is covered under 'Other than Collision' (Comprehensive) coverage. Coverage is limited to the terms and conditions contained in the policy."
    },
    antiTheftSystem: {
      title: "Vehicle Security Systems",
      content: (
        <>
            <h5 className="text-primary fw-bold">TYPE OF VEHICLE SECURITY SYSTEM/ALARM</h5>
            <p><b>What is an Active Alarm?</b><br/>
            An active alarm is a device or system that must be engaged by the user.</p>
            <p><b>What is a Passive Alarm?</b><br/>
            A passive alarm is a device or system that is automatically engaged when the ignition key is moved or stationed in the &ldquo;off&rdquo; position.</p>
            <p><b>What is a Recovery Device?</b><br/>
            A recovery device is an electronic homing device or similar device which uses a radio frequency network in conjunction with a participating police agency to locate an automobile.</p>
            <p><b>What is VIN Etching?</b><br/>
            VIN etching involves the use of a stencil and glass etching paste to etch your car&rsquo;s vehicle identification number onto its windshield and windows.</p>
        </>
      )
    }
  }

  const garagedQuestion = {
    "MI": `Is this vehicle primarily kept overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`,
    "NJ": `Is this vehicle garaged overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`,
    "PA": `Is this vehicle parked overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`
  }

  return (
    <>
      {/* Confirm these questions' answer */}
      <QuestionRow 
        name="requestedCovEffDate" 
        question="Requested Coverage Effective Date*"
      />
      <span className='fw-bold text-dark' style={{paddingLeft: '3px'}}>
        {`${newVehicleContext.newVehicle.vehicleYear} ${newVehicleContext.newVehicle.vehicleMake} ${newVehicleContext.newVehicle.vehicleModel} - ${newVehicleContext.newVehicle.vehicleVIN}`}
      </span>
      <QuestionRow 
        name="datePurchased" 
        question="Date Purchased*"
      />

      <QuestionRow 
        name="vehicleOwnedLeasedFinanced" 
        question={`Is this vehicle owned, leased or financed?*`}
      />
      <QuestionRow 
        name={type === 'leased' ? "vehicleIsCoLeased" : "vehicleIsCoOwned"}
        question={`Is this vehicle co-${type==='leased' ? 'leased' : 'owned'}?*`}
      />
      {/* {newVehicleContext.newVehicle.vehicleIsCoOwned === "1" && (
        <>
          <QuestionRow 
            name="vehicleCoOwnerName" 
            question="Name of co-owner?*"
          />
          <QuestionRow 
            name="vehicleCoOwnerAddress" 
            question="Address of co-owner?*"
          />
        </>
      )} */}
      {type !== "" && type !== "owned" &&
        <QuestionRow 
          name={type === 'leased' ? "vehicleLeasingCompany" : "vehicleFinanceCompany"}
          question={`${type === 'leased' ? 'Leasing' : 'Financing'} Company Name:*`}
        />
      }
      
      <QuestionRow 
        name="garaged" 
        question={`${garagedQuestion[policyStateAbbr]}`}
      />
      <QuestionRow 
        name="vehicleRegistrant" 
        question="In what name is this vehicle registered, or going to be registered?*"
      />
      <QuestionRow 
        display= {["NJ", "PA"].includes(policyStateAbbr)}
        name={policyStateAbbr == "NJ" ? "vehicleIsNJ" : "vehicleIsPA"}
        question={`Is this vehicle registered in the state of ${policyStateAbbr == "NJ"? "New Jersey" : "Pennsylvania"}?*`}
      />
      <QuestionRow 
        name="vehicleUse" 
        question="How will this vehicle be used?*"
      />
      <QuestionRow 
        display= {["NJ", "PA"].includes(policyStateAbbr)}
        name="daysDrivenPerWeek"
        question="Days Driven per Week:*"
      />
      <QuestionRow 
        display= {["MI"].includes(policyStateAbbr)}
        name="estimatedMileage" 
        question="Estimated Annual Mileage:*"
      />

      {type === "owned" && 
        <>
          <QuestionRow 
            name="wantsOtherThanCollision"
            question='Do you choose "Other than Collision" (Comprehensive) coverage?*'
            onTooltipClick={() => context.setValidationMsgToggle(tooltips.otherThanCollision.title, tooltips.otherThanCollision.content)}
          />
          {newVehicleContext.newVehicle.wantsOtherThanCollision === "1" && (
            <>
              <QuestionRow 
                name="otherDeductible" 
                question='Choose your "Other than Collision" (Comprehensive) deductible.*'
              />
              <QuestionRow 
                name="wantsCollision" 
                question='Do you choose "Collision" coverage?*'
                onTooltipClick={() => context.setValidationMsgToggle(tooltips.collisionCoverage.title, tooltips.collisionCoverage.content)}
              />

              {newVehicleContext.newVehicle.wantsCollision === "1" && (
                <>
                  <QuestionRow 
                    display= {["MI"].includes(policyStateAbbr)}
                    name="collisionType" 
                    question='Choose your "Collision" type.*'
                    onTooltipClick={() => context.setValidationMsgToggle(tooltips.collisionOptions.title, tooltips.collisionOptions.content)}
                  />
                  <QuestionRow 
                    name="collisionDeductible" 
                    question='Choose your "Collision" coverage deductible.*'
                  />
                </>
              )}

              <QuestionRow 
                name="rentalCoverage" 
                question="Rental Reimbursement*"
                onTooltipClick={() => context.setValidationMsgToggle(tooltips.rentalReimbursement.title, tooltips.rentalReimbursement.content)}
              />
            </>
          )}
        </>
      }

      {type !== "owned" && 
        <>
          <QuestionRow 
            name="otherDeductible" 
            question='Choose your "Other than Collision" (Comprehensive) deductible.*'
            onTooltipClick={() => context.setValidationMsgToggle(tooltips.otherThanCollision.title, tooltips.otherThanCollision.content)}
          />
          <QuestionRow 
            display= {["MI"].includes(policyStateAbbr)}
            name="collisionType" 
            question='Choose your "Collision" type.*'
            onTooltipClick={() => context.setValidationMsgToggle(tooltips.collisionOptions.title, tooltips.collisionOptions.content)}
          />
          <QuestionRow 
            name="collisionDeductible" 
            question='Choose your "Collision" coverage deductible.*'
            onTooltipClick={() => context.setValidationMsgToggle(tooltips.collisionCoverage.title, tooltips.collisionCoverage.content)}
          />
          <QuestionRow 
            name="rentalCoverage" 
            question="Rental Reimbursement*"
            onTooltipClick={() => context.setValidationMsgToggle(tooltips.rentalReimbursement.title, tooltips.rentalReimbursement.content)}
          />
        </>
      }
      
      <QuestionRow 
        display= {["NJ"].includes(policyStateAbbr)}
        name="antiTheftSystem" 
        question="The standard factory equipped anti-theft system devices will be automatically applied. Does this vehicle carry an additional alarm or recovery device?*"
        onTooltipClick={() => context.setValidationMsgToggle(tooltips.antiTheftSystem.title, tooltips.antiTheftSystem.content)}
      />
      <QuestionRow 
        name="vehicleCustomization" 
        question="Does this vehicle have modifications or customization?*"
      />
      <QuestionRow 
        name="vehicleCommercial" 
        question="Is this a commercial vehicle?*"
      />
      <QuestionRow 
        name="vehicleCommercialPurposes" 
        question="Is this vehicle used for commercial purposes, including but not limited to:"
        indentedContent={
          <>
              a) limousine, taxi, or other delivery services other than through transportation network companies;<br />
              b) in the business of pickup or delivery of magazines, newspapers, food, or any other products;<br />
              c) and/or emergency services?*
          </>
      }
      />
      <QuestionRow 
        name="leasedRentToOthers" 
        question="Does the applicant or any other driver on the application lease or rent this vehicle to others?*"
      />
      <QuestionRow 
        name="vehiclePlowSnow" 
        question="Is this vehicle used to plow snow for compensation?*"
      />
      <QuestionRow 
        name="drivenExistingDriver" 
        question={`Will this vehicle ${newVehicleContext.newVehicle.vehicleYear} ${newVehicleContext.newVehicle.vehicleMake} ${newVehicleContext.newVehicle.vehicleModel} - ${newVehicleContext.newVehicle.vehicleVIN} be driven by an existing driver on your policy?*`}
      />
    </>
  )
}

export default AddVehicleConfirm