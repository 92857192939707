import React from "react"
import { getPaymentPdf } from "../services/Api"

const ArchiveBillHistorySect = (props) => {
  const { archive } = props.paymentData
  const user = props.userData
  var complete = props.paymentData.complete && "archive" in props.paymentData
  const LoadBill = async (innerHTML) => {
    var billTab = window.open();
    billTab.document.open();
    if (innerHTML.html) {
      billTab.document.write(innerHTML.html);
    }
    else {
      var response = await getPaymentPdf(user.systemId, innerHTML.id, user.env)
      billTab.document.write(`<iframe src="data:application/octet-stream;base64,${response}"style="width: 100%;height: 100%;"></iframe>`);
      billTab.document.title = 'Payment_' + FormatDate(new Date(innerHTML.transaction)) + '.pdf'
    }
    billTab.document.close();
  }

  return (
    <>
      <div className="d-flex align-content-center flex-column">
        <h4 className="text-center section-title">
          PAPERLESS BILL HISTORY
        </h4>
        <small>
          {
            complete && <>
              {
                (archive?.length) ?
                  <table className="table table-sm table-striped">
                    <tbody>
                      <tr>
                        <th className="col-3 small"><small>Email Sent</small></th>
                        <th className="col-6 small"><small>Due Date</small></th>
                        <th className="text-end col-3 small"><small>Amount Billed</small></th>
                        <th className="text-end col-3 small"><small>View Bill</small></th>
                      </tr>
                      {archive.map((value, index) => {
                        return (<tr key={index}>
                          <td className="col-3"><small>{FormatDate(new Date(value.trans_date))}</small></td>
                          <td className="col-5"><small>{FormatDate(new Date(value.transaction))}</small></td>
                          <td className="text-end col-3"><small>{FormatValue(value.cash_amt)}</small></td>
                          <td className="text-end col-3 "><small ><a title={'test.pdf'} target="_blank" onClick={() => { LoadBill(value) }} className="btn btn-secondary border btn-sm">Bill</a></small></td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                  :
                  <p className="text-center">No history available.</p>
              }
            </>
          }
        </small>
        <div className="p-2 payment-message">
          <strong>Not seeing your recent payment?</strong>
          <p>Allow 2-3 business days for your payment to show.</p>
        </div>
      </div>
    </>
  )
}


const FormatDate = (tempDate) => {
  var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
  var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
  var dateYear = `${tempDate.getFullYear()}`
  const date = `${dateMonth}/${dateDay}/${dateYear}`
  return date
}
const FormatType = (type) => {
  return type.replace(/([A-Z])/g, ' $1').trim()
}

const FormatValue = (value) => {
  var regex = /[+-]?[0-9]+\.?[0-9]*/g

  var valueString = regex
    .exec(value.replace(",", ""))[0]
  valueString = parseFloat(valueString)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var newValue = valueString
  if (!valueString.includes('.')) {
    newValue = valueString + '.00'
  } else {
    newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
  }
  return "$" + newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default ArchiveBillHistorySect