import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { AddressChangeContextComp } from "components/ChangeRequest_AddressChangeComp/AddressChangeContext"
import AddressConfirmationMain from "components/ChangeRequest_AddressChangeComp/6_Confirmation/AddressConfirmationMain"
import { useEffect } from "react"

const Confirmation = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <AddressChangeContextComp>
                <Menu Title={"ADDRESS CHANGE"} FirstName={userData.firstName} userData={userData} />
                <RoundyContainer Content={AddressConfirmationMain} NoRoundy
                    params={{
                        storedData: { ...props.storedData },
                        setStoredData: props.setStoredData
                    }}
                />
            </AddressChangeContextComp>
        </>
    )
}

export default Confirmation