import { Link } from "react-router-dom";
import Images from "../assets/images/Images";


const CurrentBillSect = (props) => {
  console.log(props)
  const viewPolicy = ("viewPolicy" in props) ? true : false
  const { paymentPdf, paymentDoc } = props.paymentData
  return (
    <>
      <div className="d-flex align-content-center flex-column">
        <h5 className="text-center section-title">
          CURRENT BILL
        </h5>
        <small>
          <p>
            View/Save your current installment bill in PDF format.
          </p>
          {
            paymentPdf && <>
              <strong> Current  Installment Bill</strong><br />
              <em>{FormatDate(new Date(paymentDoc[0].adddt))}</em>
              <div>

                <a href={`data:application/octet-stream;base64,${paymentPdf}`} download={'Installment Invoice.pdf'}>
                  <img src={Images.statement} alt="Statement" />
                  View/Download
                </a>
              </div>
            </>
          }
          {
            !paymentPdf && <em className="text-muted text-center ">
              <p>
              &emsp;Your next installment bill that generates will be displayed here.
              </p>
            </em>
          }
          {
            viewPolicy &&
            <Link to="/policy-documents" >
              <br/>
              <ins>View Policy Forms/Documents</ins>
            </Link>
          }
          <br /><br />
          <em className="text-muted text-center">
            <p>
              &emsp;Note: If you recently submitted an online endorsement to add a vehicle to your policy 
              it may take up to 48 hours to be reflected in My Account and the CURE App.
            </p>
          </em>
        </small>
      </div>
    </>
  )
}

const FormatDate = (tempDate) => {
  var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
  var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
  var dateYear = `${tempDate.getFullYear()}`
  const date = `${dateMonth}/${dateDay}/${dateYear}`
  return date
}


export default CurrentBillSect