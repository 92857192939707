import React from 'react'
import "./UpdateHeader.css"

const UpdateHeader = () => {
    return (
        <div>
            <h3 className='fw-bold text-primary text-center m-0 endorsementUpdateTitle'>
                Your policy has been updated!
            </h3>
        </div>
    )
}

export default UpdateHeader