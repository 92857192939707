import { Navigate, Outlet, useLocation, } from "react-router-dom";
import { useState, lazy, Suspense } from 'react';

import Footer from "../components/Footer";
import Header from "../components/Header";
import { storageData } from "../services/Services";
import Banner from "../components/Banner";
import RoundyChatPlaceholder from "../components/RoundyChatPlaceholder/RoundyChatPlaceholder"
const StickyRoundyChatNow = lazy(() => import("../components/StickyRoundyChatNow"));
// import StickyRoundyChatNow from "../components/StickyRoundyChatNow";

const Layout = () => {
    const [hasLoadedWebMessage, setHasLoadedWebMessage] = useState(false)
    const [showWebTool, setShowWebTool] = useState(true)

    const handleShowWebMessage = event => {
        if(!hasLoadedWebMessage) {
            setHasLoadedWebMessage(true);
        }
    };

    const location = useLocation()
    const userData = JSON.parse(storageData.getItem("userData"));
    const pathname = location.pathname
    const redirect = !(storageData.getItem("userData"))
        && pathname !== '/home'
        && pathname !== '/ext-login'
        && pathname !== '/quick-service'
        && pathname !== '/oneinc.aspx'
        && pathname !== '/'

    const maintenance = pathname == "/maintenance"
    return (
        <>
            {redirect && <Navigate to='/' replace />}
            {!maintenance && <Banner />}
            <Header />
            <Outlet />
            <Footer user={userData}/>

            {(!hasLoadedWebMessage) && (
                <RoundyChatPlaceholder 
                    handleShowMessagingTool={handleShowWebMessage}
                    show={showWebTool}
                    setShow={setShowWebTool}
                />
            )}
            <Suspense fallback={<div></div>}>
            {
                hasLoadedWebMessage ? 
                <StickyRoundyChatNow /> : 
                null
            }
            </Suspense>
            {/* <StickyRoundyChatNow/> */}
        </>
    )
};

export default Layout;