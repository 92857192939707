import { BrowserRouter, Navigate, Route, Routes, } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserContextProvider } from './context'
import Home from "./screens/Home";
import Settings from "./screens/Settings";
import PaymentHistory from "./screens/PaymentHistory";
import PolicyIdCards from "./screens/PolicyIdCards";

import NewAddress from "./screens/AddressChange/NewAddress";
import Household from "./screens/AddressChange/Household";
import Additional from "./screens/AddressChange/Additional";
import DriverAssignment from "./screens/AddressChange/DriverAssignment";
import Summary from "./screens/AddressChange/Summary";
import Confirmation from "./screens/AddressChange/Confirmation";

// New Household Pages
import UpdateHousehold from "./screens/HouseholdChange/UpdateHousehold";
import UpdateHouseholdDriverAssignment from "screens/HouseholdChange/UpdateHouseholdDriverAssignment";
import UpdateHouseholdAdditional from "screens/HouseholdChange/UpdateHouseholdAdditional";
import UpdateHouseholdSummary from "screens/HouseholdChange/UpdateHouseholdSummary";
import UpdateHouseholdConfirmation from "screens/HouseholdChange/UpdateHouseholdConfirmation";

// Old Vehicle Pages
import UpdateVehicles from "./screens/UpdateVehicles_Old/UpdateVehicles";
import UpdateDriverAssignment from "./screens/UpdateVehicles_Old/UpdateDriverAssignment";
import UpdateVehicleSummary from "./screens/UpdateVehicles_Old/UpdateVehicleSummary";
import UpdateVehicleConfirmation from "./screens/UpdateVehicles_Old/UpdateVehicleConfirmation";

// New Vehicle pages
import Vehicles from "./screens/VehicleChange/Vehicles";
import VehiclesDriverAssignment from "./screens/VehicleChange/VehiclesDriverAssignment"
import VehiclesSummary from "./screens/VehicleChange/VehiclesSummary"
import VehiclesConfirmation from "./screens/VehicleChange/VehiclesConfirmation"

// Straight Through Endorsements (Vehicle)
import AddVehicle from "screens/StraightThroughVehicle/AddVehicle";
import ConfirmVehicle from "screens/StraightThroughVehicle/ConfirmVehicle";
import EndorsementSummary from "screens/StraightThroughVehicle/EndorsementSummary";
import EndorsementBlock from "screens/StraightThroughVehicle/EndorsementBlock";
import EndorsementFail from "screens/StraightThroughVehicle/EndorsementFail";

// Renewal Questionnaire
import RQ_Residence from "./screens/RenewalQuestionnaire/RQ_Residence";
import RQ_DriverOHM from "./screens/RenewalQuestionnaire/RQ_DriverOHM";
import RQ_AdditionalInfo from "./screens/RenewalQuestionnaire/RQ_AdditionalInfo";
import RQ_Vehicles from "./screens/RenewalQuestionnaire/RQ_Vehicles";
import RQ_Sign from "./screens/RenewalQuestionnaire/RQ_Sign";

import Login from "./screens/Login";
import Layout from "./screens/Layout";
import NoPage from "./screens/NoPage";
import Landing from "./components/Landing";
import LoadPolicyChangeInfo from "./components/LoadPolicyChangeInfo";
import OneInc from "./screens/OneInc";
// import AutoPay from "./components/OneInc/OneIncAutoPay";
import RegisterEbilling from "./screens/RegisterEbilling";
import RegisterEbillingFinish from "./screens/RegisterEbillingFinish";
import DocumentsVault from "./screens/DocumentsVault";
import QuickService from "./screens/QuickService/QuickService";
import QSIDCards from "./screens/QuickService/QSIDCards";
import QSBillingInfo from "./screens/QuickService/QSBillingInfo";
import DigitalIDCard from "./screens/DigitalIDCard";
import { storageData } from "./services/Services";
import PolicyDocuments from "./screens/PolicyDocuments";
import ExtLogin from "./screens/ExtLogin";
import Maintenance from "screens/Maintenance";
import { getDomainOneInc, getSiteEnv, getTestingEnvToken } from "./services/Utils";
import Cookies from 'js-cookie';
import { GetEnvironmentSettings } from "services/Api";
// import "./css/App.css";

function App() {
  const [storedData, setStoredDataOrig] = useState({});
  const setStoredData = (newStoredData) => {
    if (JSON.stringify(storedData) !== JSON.stringify(newStoredData)) {
      setStoredDataOrig(newStoredData)
    }
  }
  const [userData, setUserData] = useState(storageData.getItem("userData") !== null ? JSON.parse(storageData.getItem("userData")) : { complete: false })
  const [paymentData, setPaymentData] = useState(storageData.getItem("paymentData") !== null ? JSON.parse(storageData.getItem("paymentData")) : { complete: false })

  useEffect(() => {
    if (userData?.complete) {
      storageData.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (Cookies.get('CTLE', { domain: '.cure.com' }) != getTestingEnvToken() && getSiteEnv(GetEnvironmentSettings) != 'prod') {
      window.location = "https://www.cure.com";
    }
  }, [])

  const routesList = [
    { path: "settings", element: <Settings user={{ userData, setUserData }} /> },
    { path: "payment-history", element: <PaymentHistory user={{ userData, setUserData }} payment={{ paymentData, setPaymentData }} /> },
    { path: "oneinc", element: <OneInc user={{ userData, setUserData }} payment={{ paymentData, setPaymentData }} /> },
    { path: "register-ebilling", element: <RegisterEbilling user={{ userData, setUserData }} payment={{ paymentData, setPaymentData }} /> },
    { path: "register-ebilling-finish", element: <RegisterEbillingFinish user={{ userData, setUserData }} /> },
    { path: "documents-vault", element: <DocumentsVault user={{ userData, setUserData }} payment={{ paymentData, setPaymentData }} /> },
    { path: "policy-id-cards", element: <PolicyIdCards user={{ userData, setUserData }} /> },
    { path: "quick-service/BillingInfo", element: <QSBillingInfo user={{ userData, setUserData }} /> },
    { path: "quick-service/IDCards", element: <QSIDCards user={{ userData, setUserData }} /> },
    {
      path: "change-address/landing", element: <LoadPolicyChangeInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/new-address", element: <NewAddress storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/household", element: <Household storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/additional", element: <Additional storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/driver-assignment", element: <DriverAssignment storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/summary", element: <Summary storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "change-address/confirmation", element: <Confirmation storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    { path: "*", element: <NoPage /> },
    {
      path: "update-drivers/landing", element: <LoadPolicyChangeInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-drivers/update-household", element: <UpdateHousehold storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-drivers/driver-assignment", element: <UpdateHouseholdDriverAssignment storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-drivers/additional", element: <UpdateHouseholdAdditional storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-drivers/summary", element: <UpdateHouseholdSummary storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-drivers/confirmation", element: <UpdateHouseholdConfirmation storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-vehicles/landing", element: <LoadPolicyChangeInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-vehicles/vehicles", element: <Vehicles storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-vehicles/driver-assignment", element: <VehiclesDriverAssignment storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-vehicles/summary", element: <VehiclesSummary storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "update-vehicles/confirmation", element: <VehiclesConfirmation storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/landing", element: <LoadPolicyChangeInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/residence", element: <RQ_Residence storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/driverohm", element: <RQ_DriverOHM storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/additionalInfo", element: <RQ_AdditionalInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/vehicle", element: <RQ_Vehicles storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "renewalQuestionnaire/sign", element: <RQ_Sign storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "digital-id-card", element: <DigitalIDCard user={{ userData, setUserData }} />
    },
    {
      path: "policy-documents", element: <PolicyDocuments user={{ userData, setUserData }} />
    },
    {
      path: "maintenance", element: <Maintenance user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/landing", element: <LoadPolicyChangeInfo storedData={{ ...storedData }} setStoredData={setStoredData} user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/add-vehicle", element: <AddVehicle user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/confirm", element: <ConfirmVehicle user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/summary", element: <EndorsementSummary user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/thank-you", element: <EndorsementFail user={{ userData, setUserData }} />
    },
    {
      path: "endorsement/block", element: <EndorsementBlock user={{ userData, setUserData }} />
    },

  ].map((value, index) => {
    return (
      <Route key={index} path={value.path} element={value.element} />
    )
  })
  const OneIncRedirect = () => { window.location.href = `https://${getDomainOneInc()}/`; }

  return (
    <BrowserRouter>
      <UserContextProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login user={{ userData, setUserData }} />} />
            {
              storageData.getItem("userData") &&
              <Route path="*" element={<Navigate to='/home' replace />} />
            }
            <Route path="home" element={
              <Home user={{ userData, setUserData }} payment={{ paymentData, setPaymentData }} />
            } />
            <Route path="quick-service" element={<QuickService user={{ userData, setUserData }} />} />
            <Route path="ext-login" element={<ExtLogin user={{ userData, setUserData }} />} />
            <Route path="oneinc.aspx" element={<OneIncRedirect />} />
            {
              routesList
            }
          </Route >
        </Routes>
      </UserContextProvider>
    </BrowserRouter >
  );
}

export default App;