import { useState } from "react"
import "../../../css/HeaderRQ.css"
import { storageData } from "../../../services/Services"
import AddAdditionalDriver from "./AddAdditionalDriver"
import EditDriver from "./EditDriver"
import EditHouseholdMember from "./EditHouseholdMember"

const Drivers_RQ = (props) => {
    const [addDriverMenu, setAddDriverMenu] = useState(false)
    const [editExistingDriverMenu, setEditExistingDriverMenu] = useState([])
    const [editNewDriverMenu, setNewDriverMenu] = useState([])

    // Stored Driver Edit
    const editStoredDriverMenu = (driver, index, action) => {
        if (action == "cancel") {
            var sessionDataCopy = props.sessionData
            delete driver["editType"]
            delete driver["editReason"]
            delete driver["displayChange"]
            sessionDataCopy.household.drivers.storedDrivers[index] = driver
            props.setSessionData({ ...sessionDataCopy });
            storageData.setItem('sessionDataRQ', JSON.stringify(sessionDataCopy))
        }
        else {
            var editArr = props.sessionData.household.drivers.storedDrivers.map((value, i) => {
                return (index === i) ? true : false
            });
            setEditExistingDriverMenu(editArr)
        }
    }

    // Added Driver Edit
    const editAddDriverMenu = (driver, index, action) => {
        // Remove added driver
        if (action == "cancel") {
            var sessionDataCopy = props.sessionData
            var removedNewDrivers = sessionDataCopy.household.drivers.newDrivers
            removedNewDrivers = removedNewDrivers.filter(filteredDriver =>
                filteredDriver !== driver
            );
            sessionDataCopy.household.drivers.newDrivers = removedNewDrivers

            // Added driver from stored household member
            if (driver?.initialType == "Ohm") {
                delete driver["editType"]
                delete driver["editReason"]
                delete driver["displayChange"]
                delete driver["stateLicenseNumber"]
                sessionDataCopy.household.householdMembers.storedHouseholdMembers.push(driver)
            }

            props.setSessionData({ ...sessionDataCopy });
            storageData.setItem('sessionDataRQ', JSON.stringify(sessionDataCopy))
        }
        // Displays added driver form for edit
        else {
            var editArr = props.sessionData.household.drivers.newDrivers.map((value, i) => {
                return (index === i) ? true : false
            });
            setNewDriverMenu(editArr)
        }
    }

    return (
        <>
            <div className="mt-4">
                <div className="sidebar-block">
                    <h5>Drivers</h5>

                    <div className="sidebar-block-inn">
                        <p className="driversTitle mt-2">
                            Our records indicate that these are the current driver(s) that operate the policy vehicle(s):
                        </p>
                        {/* Stored Drivers */}
                        <div className="row">
                            {props?.sessionData?.household?.drivers?.storedDrivers.map((driver, id) => {
                                return (
                                    <div key={id} className="name-plate">
                                        {("displayChange" in driver) ?
                                            <>
                                                <h4 className="drivers ms-2 text-uppercase" style={{ color: '#585959', fontSize: '15px' }}>
                                                    {driver.fullName}, {driver.DOB} - {driver.displayChange}
                                                </h4>

                                                {!addDriverMenu && editExistingDriverMenu.length == 0 && editNewDriverMenu.length == 0 &&
                                                    <div className="fakeButton">
                                                        <a className="mx-1" onClick={() => editStoredDriverMenu(driver, id, "edit")}>[Edit]</a>
                                                        <a className="mx-1" onClick={() => editStoredDriverMenu(driver, id, "cancel")}>[Cancel]</a>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <h3 className="drivers ms-2 text-uppercase" style={{ color: '#046ab0' }}>
                                                    {driver.fullName}, {driver.DOB}
                                                </h3>
                                                {!addDriverMenu && editExistingDriverMenu.length == 0 && editNewDriverMenu.length == 0 &&
                                                    <div className="fakeButton">
                                                        <a className="mx-1" onClick={() => editStoredDriverMenu(driver, id, "edit")}>[Edit]</a>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <br /><br />

                                        {/* Edit Stored/Existing Driver */}
                                        {editExistingDriverMenu[id] &&
                                            <EditDriver
                                                sessionData={props.sessionData}
                                                setSessionData={props.setSessionData}
                                                storedDrivers={props.sessionData.household.drivers.storedDrivers}
                                                currDriver={driver}
                                                setShow={props.setShow}
                                                setValidationMsg={props.setValidationMsg}
                                                handleClose={props.handleClose}
                                                handleShow={props.handleShow}
                                                setValidationLoad={props.setValidationLoad}
                                                setValidationTitle={props.setValidationTitle}
                                                index={id}
                                                setEditExistingDriverMenu={setEditExistingDriverMenu}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {/* New Drivers */}
                        <div className="row">
                            {props?.sessionData?.household?.drivers?.newDrivers.map((driver, id) => {
                                return (
                                    <div key={id} className="name-plate">
                                        <h3 className="drivers ms-2" style={{ color: '#678062' }}>
                                            <span className="text-uppercase">{driver.fullName}, {driver.DOB}</span> 
                                            {/* For added drivers from stored members and adding new drivers */}
                                            <span>{driver?.initialType == "Ohm" ? " - " + driver?.displayChange : "- New Driver"}</span>
                                        </h3>

                                        {!addDriverMenu && editExistingDriverMenu.length == 0 && editNewDriverMenu.length == 0 &&
                                            <div className="fakeButton">
                                                <a className="mx-1" onClick={() => editAddDriverMenu(driver, id, "edit")}>[Edit]</a>
                                                <a className="mx-1" onClick={() => editAddDriverMenu(driver, id, "cancel")}>[Cancel]</a>
                                            </div>
                                        }
                                        <br />
                                        {/* Edit New Driver */}
                                        {editNewDriverMenu[id] && driver?.initialType != "Ohm" &&
                                            <AddAdditionalDriver
                                                userData={props.userData}
                                                sessionData={props.sessionData}
                                                setSessionData={props.setSessionData}
                                                newDrivers={props.sessionData.household.drivers.newDrivers}
                                                setShow={props.setShow}
                                                setValidationMsg={props.setValidationMsg}
                                                handleClose={props.handleClose}
                                                handleShow={props.handleShow}
                                                setValidationLoad={props.setValidationLoad}
                                                setValidationTitle={props.setValidationTitle}
                                                setAddDriverMenu={setAddDriverMenu}
                                                setNewDriverMenu={setNewDriverMenu}
                                                index={id}
                                            />
                                        }

                                        {/* Edit Added Driver from Stored Household Member */}
                                        {editNewDriverMenu[id] && driver?.initialType == "Ohm" &&
                                            <>
                                                <br/>
                                                <EditHouseholdMember
                                                    sessionData={props.sessionData}
                                                    setSessionData={props.setSessionData}
                                                    storedHouseholdMembers={props.sessionData.household.householdMembers.storedHouseholdMembers}
                                                    currHouseholdMember={driver}
                                                    setShow={props.setShow}
                                                    setValidationMsg={props.setValidationMsg}
                                                    handleClose={props.handleClose}
                                                    handleShow={props.handleShow}
                                                    setValidationLoad={props.setValidationLoad}
                                                    setValidationTitle={props.setValidationTitle}
                                                    index={id}
                                                    setEditExistingHouseholdMemberMenu={setNewDriverMenu}
                                                />
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {/* Add Additional Driver */}
                        {addDriverMenu && editNewDriverMenu.length == 0 &&
                            <AddAdditionalDriver
                                userData={props.userData}
                                sessionData={props.sessionData}
                                setSessionData={props.setSessionData}
                                newDrivers={props.sessionData.household.drivers.newDrivers}
                                setAddDriverMenu={setAddDriverMenu}
                                setShow={props.setShow}
                                setValidationMsg={props.setValidationMsg}
                                handleClose={props.handleClose}
                                handleShow={props.handleShow}
                                setValidationLoad={props.setValidationLoad}
                                setValidationTitle={props.setValidationTitle}
                                index={props.sessionData.household.drivers.newDrivers.length}
                            />
                        }

                        {!addDriverMenu && editExistingDriverMenu.length == 0 && editNewDriverMenu.length == 0 &&
                            <div className="p-2">
                                <div>
                                    <input type="button" value={'Add Additional Driver'} className="additionalMember" onClick={setAddDriverMenu} />
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Drivers_RQ