import { useContext, useEffect, useRef, useState } from "react"
import { AddVehicleContext } from "./VehicleAddMainMI"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { StoredAddedVehicles } from "../VehiclesList"
import DatePick_MI from "components/DatePicker_MI"

const VehicleAddMI_1 = () => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const vehicleAddContext = useContext(AddVehicleContext)
    const vinRef = useRef(null)
    const [requestedEffDt, setRequestedEffDt] = useState(storedAddedContext.getTodaysDate(true))

    useEffect(() => {
        vinRef.current?.focus();
    }, []);


    return (
        <>
            {/* Effective Date Request */}
            <div className="col-xl-12 justify-content-center mt-3">
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-4 col-sm-4 text-primary fw-bold">
                        Requested Effective Date*
                    </div>
                    <div className="col-xl-8 col-sm-8">
                        <DatePick_MI
                            dateSubmit={vehicleAddContext.newVehicle?.requestedEffDt ? vehicleAddContext.newVehicle?.requestedEffDt : requestedEffDt}
                            setDateSubmit={setRequestedEffDt}
                            storeValue={vehicleAddContext.saveAddition}
                            nameValue={"requestedEffDt"}
                        />
                    </div>
                </div>
            </div>

            {!context.vinLoad && !storedAddedContext.vehicleObtained && vehicleAddContext?.type != "edit" &&
                <>
                    <div>
                        <p className="small text-dark">Enter your VIN number below to retrieve precise make, model and year information about your vehicle.</p>
                        <div className="row form-group">
                            <div className="col-xs-12 col-sm-8 col-md-7 col-lg-8">
                                <div className="input-group form-group">
                                    <input type={"text"} autoComplete="off" className="form-control" placeholder="Enter your VIN..." ref={vinRef} />
                                    <span className="input-group-btn">
                                        <input type={"button"} className="btn btn-default" value={"SUBMIT"} onClick={() => vehicleAddContext.checkVin(vinRef.current.value)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default VehicleAddMI_1