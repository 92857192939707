import React, { useContext, useState } from "react";
import { VehicleChangeContext } from "./VehicleChangeContext";

const breadcrumb = {
  borderRadius: '0.37rem',
  "--bs-breadcrumb-divider": "'>'",
  textDecoration: 'none'
}

const VehicleProgressBar = (props) => {
  const context = useContext(VehicleChangeContext)

  var combinedVehicles = [...context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles, ...context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.newVehicles]
  combinedVehicles = combinedVehicles.filter((vehicle, id) => vehicle?.type != "remove")
  const [allVehicles, setAllVehicles] = useState([...combinedVehicles])

  const navs = (allVehicles?.length == 0) ? 
  [
    '1. Vehicles',
    '2. Summary'
  ]
  :
  [
    '1. Vehicles',
    '2. Driver Assignment',
    '3. Summary'
  ]

  const isCurrent = ((value) => {
    return value.slice(3) === props.screen;
  });

  return (
    <>
      <div className="clearfix"></div>

      {/* Vehicle Progress Bar */}
      <div className="col-12">
        <nav>
          <ol className="breadcrumb justify-content-center" style={breadcrumb}>
            {
              navs.map((nav, navIndex) => {
                const active = isCurrent(nav) ? 'fw-bold text-blue' : 'disabled';
                return (
                  <li key={navIndex} className="breadcrumb-item align-items-center d-flex">
                    <button className={`btn ${active}`}>
                      {nav}
                    </button>
                  </li>
                );
              })
            }
          </ol>
        </nav>
      </div>

    </>
  )
}

export default VehicleProgressBar