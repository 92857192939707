import Menu_RQ from "../../components/RenewalQuestionnaireComp/Header/Menu_RQ"
import NavBar_RQ from "../../components/RenewalQuestionnaireComp/Header/NavBar_RQ"

const RQ_Header = (props) => {

    return (
        <>
            <div className="">
                <Menu_RQ />
                <NavBar_RQ userData={props.userData} screen={props.screen} />
            </div>
        </>
    )
}

export default RQ_Header