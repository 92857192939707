import { Link } from "react-router-dom"
import { storageData } from "../../services/Services"
import { getDomain } from "../../services/Utils"

const HeaderQS = (props) => {
    const Logout = () => {
        storageData.clear()
        window.location = getDomain()
    }
    return (
        <>
            <div className="container col-sm-11 col-md-9"  style={{ maxWidth: "1000px" }}>
                <div className="d-flex flex-column bg-muted px-5 py-3">
                    <div className="text-center text-md-start">

                        <div className="">
                            <h2 className="section-title">
                                CURE QUICK SERVICE CENTER
                            </h2>
                        </div>
                        <div className="">
                            <h5 className="section-subtitle mx-3">
                                {props.title}
                            </h5>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center justify-content-md-end">
                        <button onClick={Logout} className="btn btn-primary flex-grow-1 flex-md-grow-0">
                            Log out
                        </button>
                    </div>
                </div>
                <div className="d-flex justify-content-end px-5">
                    <div className="border">

                        <Link to={"../quick-service"} className="btn text-primary">Welcome</Link>
                        <Link to={"../quick-service/BillingInfo"} className="btn text-primary">Billing Information</Link>
                        <Link to={"../quick-service/IDCards"} className="btn text-primary">Replacement ID Cards</Link>
                    </div>
                </div>

            </div>
        </>
    )
}
export default HeaderQS