import React, { useContext, useState } from "react"
import { Spinner } from 'react-bootstrap';
import VehicleAdd_1 from "../VehicleAdd/VehicleAdd_1"
import VehicleAdd_2 from "../VehicleAdd/VehicleAdd_2"
import VehicleAdd_3 from "../VehicleAdd/VehicleAdd_3";
import VehicleAdd_4 from "../VehicleAdd/VehicleAdd_4";
import { VehicleChangeContext } from "../../VehicleChangeContext";
import { storageData } from "../../../../services/Services";
import { StoredAddedVehicles } from "../VehiclesList";
import VehicleValidation_NJPA from "../VehicleValidation_NJPA";

export const AddVehicleContext = React.createContext()

const VehicleAddMain = ({ index, type, saveEditOption }) => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const vehiclesOnPolicy = context.vehicleChangeClass.vehicles.vehiclesOnPolicy

    // Gets the correct list of vehicles
    const getAllVehicles = () => {
        if (type == "add" || type == "edit") {
            return vehiclesOnPolicy.newVehicles
        }
        else {
            return vehiclesOnPolicy.storedVehicles
        }
    }
    const allVehicles = getAllVehicles()

    // Fields for adding a vehicle
    const newVehicleDetails = {
        vehicleVIN: "",
        vehicleYear: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleBody: "",
        requestedEffDt: "",
        dateObtained: "",
        otherThanCollisionToggle: "",
        vehicleOwnedLeasedFinanced: "0",
        coOwned: "",
        coOwnerName: "",
        coOwnerAddress: "",
        coOwnerCity: "",
        coOwnerState: "State",
        coOwnerZip: "",
        nameRegistered: "0",
        registrantName: "",
        registrantAddress: "",
        registrantCity: "",
        registrantState: "State",
        registrantZip: "",
        stateRegistered: context.userData.state == "New Jersey" ? "NJ" : "PA",
        vehicleLeasingCompany: "",
        standardDeductibleComprehensive: "",
        rentalReimbursement: "",
        standardDeductibleCollision: "",
        vehicleFinanceCompany: "",
        collisionCoverageToggle: "",
        securityType: "0"
    }

    // State for a new/newly added vehicle
    const [newVehicle, setNewVehicle] = useState(
        (index >= allVehicles.length) ?
            newVehicleDetails
            :
            allVehicles[index]
    )

    if (type == "replace" || type == "replaceEdit") {
        if (JSON.stringify(newVehicle) != JSON.stringify(allVehicles[index])) {
            setNewVehicle(allVehicles[index])
        }
    }

    // Saves values for adding vehicle
    const saveAddition = (key, value) => {
        var newVehicleCopy = newVehicle
        newVehicleCopy[key] = value
        setNewVehicle({ ...newVehicleCopy })

        if (type == "replace" || type == "replaceEdit") {
            var vehicleChangeClassCopy = context.vehicleChangeClass
            var vehiclesCopyList = vehiclesOnPolicy.storedVehicles
            vehiclesCopyList[index] = newVehicleCopy
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesCopyList
            context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
        }
    }

    // Cancel Menu
    const cancelMenu = () => {
        if (type == "replace" || type == "replaceEdit") {
            saveEditOption()
        }
        else if (type == "edit") {
            storedAddedContext.setEditAddedVehicleArr([])
            context.setShowQuestions(true)
        }
        else {
            context.setAddVehicleMenu(false)
        }
        storedAddedContext.setVehicleObtained(false)
    }

    // Adds vehicle to list
    const submitNewVehicle = () => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehiclesNewCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles
        var vehiclesStoredCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles

        // Validation check
        var { title, message, valid } = VehicleValidation_NJPA({ type, vehicle: newVehicle })
        if (!valid) {
            context.setValidationMsgToggle(title, message)
            return
        }
        // Adding new vehicle
        if (type == "add") {
            vehiclesNewCopyList.push(newVehicle)
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles = vehiclesNewCopyList
        }
        // Editing a newly added vehicle
        else if (type == "edit") {
            vehiclesNewCopyList[index] = newVehicle
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles = vehiclesNewCopyList
            storedAddedContext.setEditAddedVehicleArr([])
        }
        // Replacing stored vehicle with newly added
        else if (type == "replace" || type == "replaceEdit") {
            if (type == "replace") {
                newVehicle["type"] = type
            }
            vehiclesStoredCopyList[index] = newVehicle
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesStoredCopyList
            storedAddedContext.saveEditOption()
        }

        storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
        context.setVehicleChangeClass(vehicleChangeClassCopy)
        storedAddedContext.submittedVehicleChanges()
    }

    return (
        <>
            {/* Title */}
            <div className="text-center text-primary mt-4">
                <strong>ADD A VEHICLE</strong>
            </div>
            <hr className="less-margin" />

            {/* All components for adding a vehicle */}
            <AddVehicleContext.Provider
                value={{
                    newVehicle, setNewVehicle,
                    newVehicleDetails, saveAddition,
                    index, type
                }}>
                <VehicleAdd_1 />
                <VehicleAdd_2 />
                <VehicleAdd_3 />
                <VehicleAdd_4 />
            </AddVehicleContext.Provider>

            {/* Spinner for retrieving vehicle info from vin */}
            {context.vinLoad &&
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            }

            {/* Save/Cancel button for add vehicle menu */}
            {(context.addVehicleMenu || type == "replace" || type == "replaceEdit" || type == "edit") &&
                <div className={`text-right ${!context.showQuestions ? "mb-3" : "my-3"}`}>
                    <input type={"button"} className="btn btn-default" value={"CANCEL"} onClick={() => cancelMenu()} />
                    <input type={"button"} className="btn btn-primary-grad ms-1" value={"SAVE"} onClick={() => submitNewVehicle()} />
                </div>
            }
        </>
    )
}

export default VehicleAddMain