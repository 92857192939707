const ValidationVehicle = (props) => {
    // Checks responses
    if (props.sessionData.vehicles.businessPurposeResponse == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any of the vehicles listed are used for business purposes.")
        return false
    }
    else if (props.sessionData.vehicles.businessPurposeResponse == "Yes" && props.sessionData.vehicles.businessPurposeExplanation == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please explain how your vehicles are used for business purposes.")
        return false
    }
    else if (props.sessionData.vehicles.keptOvernightResponse == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any of the vehicles listed are not kept overnight at your primary address.")
        return false
    }
    else if (props.sessionData.vehicles.keptOvernightResponse == "No" && props.sessionData.vehicles.keptOvernightLocation == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please indicate where your vehicles are kept.")
        return false
    }
    return true
}

export default ValidationVehicle