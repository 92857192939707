import { useContext } from "react"
import { AddressChangeContext } from "../../AddressChangeContext"
import VehicleMI_Garaged from "./VehicleMI_Garaged"
import { storageData } from "../../../../services/Services"


const VehicleMI_Questions = () => {
    const context = useContext(AddressChangeContext)
    const currentAddress = context.currentResidence
    var storedVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.storedVehicles
    var newVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.newVehicles

    const updateVehicleQuestions = (key, value) => {
        var vehicleClassCopy = context.addressChangeClass

        // Adds the current address for vehicles
        if (key == "keptOvernightResponse") {
            vehicleClassCopy.vehicles["keptOvernightAddress"] = currentAddress.address1
            vehicleClassCopy.vehicles["keptOvernightAddress2"] = currentAddress.address2
            vehicleClassCopy.vehicles["keptOvernightCity"] = currentAddress.city
            vehicleClassCopy.vehicles["keptOvernightState"] = currentAddress.state
            vehicleClassCopy.vehicles["keptOvernightState"] = currentAddress.zip

            // Removes any keys that were assigned to original vehicles while filling out overnight address
            if (value == "Yes") {
                storedVehicles.map((veh, id) => {
                    delete veh?.garaged
                    delete veh?.garagedStreetAddress
                    delete veh?.garagedCity
                    delete veh?.garagedState
                    delete veh?.garagedZipCode
                    delete veh?.showGarageFields
                    delete veh?.sameAddress
                })
                newVehicles.map((veh, id) => {
                    delete veh?.garaged
                    delete veh?.garagedStreetAddress
                    delete veh?.garagedCity
                    delete veh?.garagedState
                    delete veh?.garagedZipCode
                    delete veh?.showGarageFields
                    delete veh?.sameAddress
                })
            }
        }

        vehicleClassCopy.vehicles[key] = value
        context.setAddressChangeClass({ ...vehicleClassCopy })
        storageData.setItem("AddressChangeClass", JSON.stringify(context.addressChangeClass))
    }

    const updateVehicleLocation = (key, vehicleType, vehicle, id, value) => {
        // Sets/Resets the fields once checked
        if (key == "garaged") {
            vehicle["garagedStreetAddress"] = ""
            vehicle["garagedCity"] = ""
            vehicle["garagedState"] = "MI"
            vehicle["garagedZipCode"] = ""
        }

        var vehicleClassCopy = context.addressChangeClass
        vehicle[key] = value

        // Stored Vehicles
        if (vehicleType == "stored") {
            storedVehicles[id] = vehicle
            var storedVehiclesCopy = vehicleClassCopy.vehicles.vehiclesOnPolicy?.storedVehicles
            vehicleClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = storedVehiclesCopy
            if (key == "garaged") {
                delete storedVehicles[id]?.showGarageFields
                delete storedVehicles[id]?.sameAddress
            }
        }
        // New Vehicles
        else {
            newVehicles[id] = vehicle
            var newVehiclesCopy = vehicleClassCopy.vehicles.vehiclesOnPolicy?.newVehicles
            vehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles = newVehiclesCopy
            if (key == "garaged") {
                delete newVehicles[id]?.showGarageFields
                delete newVehicles[id]?.sameAddress
            }
        }
        context.setAddressChangeClass({ ...vehicleClassCopy })
        storageData.setItem("AddressChangeClass", JSON.stringify(context.addressChangeClass))
    }

    return (
        <>
            <hr className="mt-4" />
            {/* Kept Overnight */}
            <div className="form-group form-group-sm" style={{ fontSize: '0.8em' }}>
                <strong className="text-primary" >Will all vehicles on the policy be garaged at this new address overnight?*</strong>
            </div>
            {/* Response for vehicle kept overnight */}
            <div className="form-group form-group-sm text-dark ms-3 mt-1 vehicleOvernight">
                {['Yes', 'No'].map((value, id) => {
                    return (
                        <div key={id} className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input type={"radio"} className="form-check-input" name="garaged" value={value}
                                    checked={(context.addressChangeClass.vehicles.keptOvernightResponse == value)}
                                    onChange={() => { updateVehicleQuestions("keptOvernightResponse", value) }}
                                />{value}
                            </label>
                        </div>
                    )
                })}
            </div>

            {/* Location of vehicle */}
            {context.addressChangeClass.vehicles.keptOvernightResponse == "No" &&
                <div className="mx-3 mb-3">
                    {/* Not Garaged */}
                    <div className="form-group form-group-sm fw-bold text-primary mx-1" style={{ fontSize: '0.8em' }}>
                        Which vehicles will not be garaged overnight at this address?
                    </div>

                    {/* Stored Vehicles */}
                    {storedVehicles.map((vehicle, id) => {
                        return (
                            <div key={id} className="mx-2" style={checkBoxText}>
                                <label className="mb-2">
                                    <input type="checkbox" style={{ verticalAlign: 'middle' }}
                                        checked={vehicle?.garaged ? vehicle?.garaged : ""}
                                        value={vehicle?.garaged ? vehicle?.garaged : ""}
                                        onChange={(e) => updateVehicleLocation("garaged", "stored", vehicle, id, e.target.checked)}
                                    />
                                    <span className="ms-3 text-dark">{vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model}</span>
                                </label>

                                {vehicle?.garaged &&
                                    <VehicleMI_Garaged context={context} updateVehicleLocation={updateVehicleLocation} type={"stored"} vehicle={vehicle} id={id} />
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}

const checkBoxText = {
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '12px'
}

export default VehicleMI_Questions