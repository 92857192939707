import AddressProgressBarSect from "components/ChangeRequest_AddressChangeComp/AddressProgressBarSect"
import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import HouseholdMain from "components/ChangeRequest_AddressChangeComp/2_Household/HouseholdMain"
import {  AddressChangeContextComp } from "components/ChangeRequest_AddressChangeComp/AddressChangeContext"
import { useEffect } from "react"

const UpdateHousehold = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <AddressChangeContextComp>
            <Menu Title={"ADDRESS CHANGE"} FirstName={userData.firstName} userData={userData} />
            <AddressProgressBarSect screen={"Household"} state={userData?.state} />
            <RoundyContainer Content={HouseholdMain} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </AddressChangeContextComp>
        </>
    )
}

export default UpdateHousehold