import { useContext, useState } from "react"
import SecurityAlarmDropdown from "../../VehicleDropdowns/SecurityAlarmDropdown"
import DatePick from "../../../DatePicker"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { AddVehicleContext } from "./VehicleAddMain"
import { StoredAddedVehicles } from "../VehiclesList"

const VehicleAdd_3 = () => {
    const context = useContext(VehicleChangeContext)
    const vehicleAddContext = useContext(AddVehicleContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const [requestedEffDt, setRequestedEffDt] = useState("")
    const [dateObtained, setDateObtained] = useState("")

    return (
        <>
            {!context.vinLoad && storedAddedContext.vehicleObtained &&
                <div>
                    <div className="text-center">
                        <strong className="text-primary">ADDITIONAL INFORMATION</strong>
                    </div>
                    <hr className="less-margin" />

                    {/* Type of Security */}
                    <div className="form-group form-group-sm text-center">
                        <div className="btn-group bootstrap-select custom-selector" style={{ width: "90%" }}>
                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                value={vehicleAddContext.newVehicle?.securityType}
                                onChange={(e) => vehicleAddContext.saveAddition("securityType", e.target.value)}
                            >
                                {SecurityAlarmDropdown()}
                            </select>
                        </div>
                        <a className="ms-2">
                            <i className="fa fa-info-circle"
                                onClick={() => {
                                    context.setValidationMsgToggle("CURE Auto - Alarms/Recovery Devices", alarmsRecovery)
                                }}
                            />
                        </a>
                    </div>
                    <br />

                    {/* Requested Effective Date */}
                    {vehicleAddContext?.type != "replace" && vehicleAddContext?.type != "replaceEdit" &&
                        <>
                            <div className="form-group form-group-sm">
                                <strong className="text-primary">Requested Effective Date*</strong>
                            </div>
                            <div className="form-group row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                                    <DatePick
                                        dateSubmit={vehicleAddContext.newVehicle?.requestedEffDt}
                                        setDateSubmit={setRequestedEffDt}
                                        storeValue={vehicleAddContext.saveAddition}
                                        nameValue={"requestedEffDt"}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {/* Date of Receiving Vehicle */}
                    <div className="form-group form-group-sm">
                        <strong className="text-primary">On what date did you obtain this vehicle?</strong>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <DatePick
                                dateSubmit={vehicleAddContext.newVehicle?.dateObtained}
                                setDateSubmit={setDateObtained}
                                storeValue={vehicleAddContext.saveAddition}
                                nameValue={"dateObtained"}
                            />
                        </div>

                        {/* Other Than Collision (Comprehensive) Coverage */}
                        <div className="form-group form-group-sm mt-3">
                            <strong className="text-primary">Do you choose "Other than Collision" (Comprehensive) coverage? *</strong>
                            <a>
                                <i className="fa fa-info-circle ms-2"
                                    onClick={() => {
                                        context.setValidationMsgToggle(`CURE Auto "Other than Collision" (Comprehensive Coverage)`,
                                            `"Other than Collision" (Comprehensive) covers your vehicle, and sometimes other vehicles you may be driving, for losses resulting from incidents other than collision. 
                                            For example, "Other than Collision" (Comprehensive) insurance covers damage to your car if it is stolen, or damaged by flood, fire, or animals. 
                                            It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a deductible as you feel comfortable paying out of pocket. 
                                            Coverage is limited to the terms and conditions contained in the policy.`)
                                    }} />
                            </a>
                        </div>
                        <div className="form-group form-group-sm text-dark ms-3 mb-0">
                            <div className="radio-inline form-check form-check-inline ms-2">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="collision" value={"1"}
                                        checked={(vehicleAddContext?.newVehicle?.otherThanCollisionToggle === "1") ? vehicleAddContext?.newVehicle?.otherThanCollisionToggle : ""}
                                        onChange={(e) => { vehicleAddContext.saveAddition("otherThanCollisionToggle", e.target.value) }}
                                    />Yes
                                </label>
                            </div>
                            <div className="radio-inline form-check form-check-inline">
                                <label className="form-check-label">
                                    <input type={"radio"} className="form-check-input" name="collision" value={"0"}
                                        checked={(vehicleAddContext?.newVehicle?.otherThanCollisionToggle === "0") ? vehicleAddContext?.newVehicle?.otherThanCollisionToggle : ""}
                                        onChange={(e) => { vehicleAddContext.saveAddition("otherThanCollisionToggle", e.target.value) }}
                                    />No
                                </label>
                            </div>
                        </div>

                        {/* Standard Deductible for Other Than Collision */}
                        {vehicleAddContext?.newVehicle?.otherThanCollisionToggle == "1" &&
                            <div className="ms-5 col-10">
                                <div className="form-group form-group-sm">
                                    <strong className="text-primary">The Standard Deductible in {context.userData.state} is {context.userData.state === "New Jersey" ? "$750." : "$500."}</strong>
                                </div>
                                <div className="ms-3">
                                    <label>
                                        <input type={"checkbox"}
                                            checked={(vehicleAddContext.newVehicle?.standardDeductibleComprehensive) ? vehicleAddContext.newVehicle?.standardDeductibleComprehensive : false}
                                            onChange={(e) => { vehicleAddContext.saveAddition("standardDeductibleComprehensive", e.target.checked) }}
                                        />
                                        <span className="ms-2">Check this box if you would like a CURE representative to reach out and go over higher and lower deductible options.</span>
                                    </label>
                                </div>
                                <div className="form-group form-group-sm mt-2">
                                    <strong className="text-primary">Do you choose Rental Reimbursement coverage? *</strong>
                                    <a>
                                        <i className="fa fa-info-circle ms-2"
                                            onClick={() => {
                                                context.setValidationMsgToggle("CURE Auto - Rental Reimbursement",
                                                    `Rental Reimbursement pays for the cost of a rental car if you can't drive the insured vehicle due to theft 
                                                    or damage from an accident.  Extended Transportation coverage cannot be purchased unless the car is covered under 'Other than Collision' (Comprehensive) coverage.  
                                                    Coverage is limited to the terms and conditions contained in the policy.`)
                                            }}>
                                        </i>
                                    </a>

                                    {/* Rental Coverage for NJ Only */}
                                    {context.userData.state == "New Jersey" &&
                                        <div className="form-group form-group-sm text-dark ms-3">
                                            <div className="radio-inline form-check form-check-inline mt-2">
                                                <label className="form-check-label">
                                                    <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={"71100"}
                                                        checked={(vehicleAddContext.newVehicle?.rentalReimbursement === "71100") ? vehicleAddContext.newVehicle?.rentalReimbursement : ""}
                                                        onChange={(e) => vehicleAddContext.saveAddition("rentalReimbursement", e.target.value)}
                                                    />$15 per day/$450 max
                                                </label>
                                            </div><br />
                                            <div className="radio-inline form-check form-check-inline">
                                                <label className="form-check-label">
                                                    <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={"71200"}
                                                        checked={(vehicleAddContext.newVehicle?.rentalReimbursement === "71200") ? vehicleAddContext.newVehicle?.rentalReimbursement : ""}
                                                        onChange={(e) => vehicleAddContext.saveAddition("rentalReimbursement", e.target.value)}
                                                    />$30 per day/$900 max
                                                </label>
                                            </div><br />
                                            <div className="radio-inline form-check form-check-inline">
                                                <label className="form-check-label">
                                                    <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={"71000"}
                                                        checked={(vehicleAddContext.newVehicle?.rentalReimbursement === "71000") ? vehicleAddContext.newVehicle?.rentalReimbursement : ""}
                                                        onChange={(e) => vehicleAddContext.saveAddition("rentalReimbursement", e.target.value)}
                                                    />No
                                                </label>
                                            </div>
                                        </div>
                                    }

                                    {/* Rental Coverage for PA Only */}
                                    {context.userData.state == "Pennsylvania" &&
                                        <div className="form-group form-group-sm text-dark ms-3">
                                            <div className="radio-inline form-check form-check-inline mt-2">
                                                <label className="form-check-label">
                                                    <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={"75050"}
                                                        checked={(vehicleAddContext.newVehicle?.rentalReimbursement === "75050") ? vehicleAddContext.newVehicle?.rentalReimbursement : ""}
                                                        onChange={(e) => vehicleAddContext.saveAddition("rentalReimbursement", e.target.value)}
                                                    />Yes
                                                </label>
                                            </div><br />
                                            <div className="radio-inline form-check form-check-inline">
                                                <label className="form-check-label">
                                                    <input type={"radio"} className="form-check-input" name="rentalReimbursement" value={"75040"}
                                                        checked={(vehicleAddContext.newVehicle?.rentalReimbursement === "75040") ? vehicleAddContext.newVehicle?.rentalReimbursement : ""}
                                                        onChange={(e) => vehicleAddContext.saveAddition("rentalReimbursement", e.target.value)}
                                                    />No
                                                </label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {/* Collision Coverage */}
                        {vehicleAddContext?.newVehicle?.otherThanCollisionToggle == "1" &&
                            <>
                                <div className="form-group form-group-sm">
                                    <strong className="text-primary">Do you choose "Collision" coverage? *</strong>
                                    <a>
                                        <i className="fa fa-info-circle ms-2"
                                            onClick={() => {
                                                context.setValidationMsgToggle("CURE Auto - Collision Coverage",
                                                    `Collision covers damage to your car when your car hits, or is hit by, another vehicle, or other object. This coverage pays to fix your vehicle less the deductible you choose. 
                                            To keep your premiums low, select as large a deductible as you feel comfortable paying out of pocket. 
                                            Coverage is limited to the terms and conditions contained in the policy.`)
                                            }}>
                                        </i>
                                    </a>
                                </div>
                                {/* Collision Coverage Response */}
                                <div className="form-group form-group-sm text-dark ms-3 mb-0">
                                    <div className="radio-inline form-check form-check-inline ms-2">
                                        <label className="form-check-label">
                                            <input type={"radio"} className="form-check-input" name="collisionCoverage" value={"1"}
                                                checked={(vehicleAddContext.newVehicle?.collisionCoverageToggle === "1") ? vehicleAddContext.newVehicle?.collisionCoverageToggle : ""}
                                                onChange={(e) => vehicleAddContext.saveAddition("collisionCoverageToggle", e.target.value)}
                                            />Yes
                                        </label>
                                    </div>
                                    <div className="radio-inline form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input type={"radio"} className="form-check-input" name="collisionCoverage" value={"0"}
                                                checked={(vehicleAddContext.newVehicle?.collisionCoverageToggle === "0") ? vehicleAddContext.newVehicle?.collisionCoverageToggle : ""}
                                                onChange={(e) => vehicleAddContext.saveAddition("collisionCoverageToggle", e.target.value)}
                                            />No
                                        </label>
                                    </div>
                                </div>

                                {/* Standard Deductible for Collision Coverage */}
                                {vehicleAddContext.newVehicle.collisionCoverageToggle == "1" &&
                                    <div className="ms-5 mb-2 col-10">
                                        <div className="form-group form-group-sm">
                                            <strong className="text-primary">The Standard Deductible in {context.userData.state} is {context.userData.state === "New Jersey" ? "$750." : "$500."}</strong>
                                        </div>
                                        <div className="ms-3">
                                            <label>
                                                <input type={"checkbox"}
                                                    checked={(vehicleAddContext.newVehicle?.standardDeductibleCollision) ? vehicleAddContext.newVehicle?.standardDeductibleCollision : false}
                                                    onChange={(e) => vehicleAddContext.saveAddition("standardDeductibleCollision", e.target.checked)}
                                                />
                                                <span className="ms-2">Check this box if you would like a CURE representative to reach out and go over higher and lower deductible options.</span>
                                            </label>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default VehicleAdd_3

const alarmsRecovery =
    <span>
        <span className="text-primary fw-bold" style={{ fontSize: "16px" }}>TYPE OF VEHICLE SECURITY SYSTEM/ALARM</span><br />
        <div className="mt-2">
            <span className="fw-bold">What is an Active Alarm?</span><br />
            <span>An active alarm is a device or system that must be engaged by the user.</span>
        </div><br />
        <div>
            <span className="fw-bold">What is a Passive Alarm?</span><br />
            <span>A passive alarm is a device or system that is automatically engaged when the ignition key is moved or stationed in the “off” position.</span>
        </div><br />
        <div>
            <span className="fw-bold">What is a Recovery Device?</span><br />
            <span>A recovery device is an electronic homing device or similar device which uses a radio frequency network in conjunction with a participating police agency to locate an automobile.</span>
        </div><br />
        <div>
            <span className="fw-bold">What is VIN Etching?</span><br />
            <span>VIN etching involves the use of a stencil and glass etching paste to etch your car’s vehicle identification number onto its windshield and windows.</span>
        </div>
    </span>