const ValidationHouseholdMember = async (props) => {
    console.log(props)

    // Edit Menu Validation
    if ("edit" in props) {
        if (props.edit.editType == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please select what you would like to edit about the household member.`)
            return false
        }
        else if (props.edit.editType == "VALIDLICENSE" && props.edit.editReason == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please select if this person should be added as a driver to the policy or if this person has their own insurance.`)
            return false
        }
        else {
            return true
        }
    }
    // Add New Household Member Validation
    else {
        // Name
        if (props?.newHouseholdMember?.fullName == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please enter the household member's name.`)
            return false
        }
        else {
            // Checks if first name is valid
            var containsNumerical = /\d/;
            if (containsNumerical.test(props?.newHouseholdMember?.fullName)) {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg("Name contains a numeric character. Please enter a valid Name.")
                return false
            }
            var valid = /^(([a-zA-Z'-.]{2,})+ ([a-zA-Z'-.]+){2,})$/;
            if (!valid.test(props?.newHouseholdMember?.fullName)) {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.")
                return false
            }
            // DOB
            else if (props?.newHouseholdMember?.dobMonth == "" || props?.newHouseholdMember?.dobDay == "" || props?.newHouseholdMember?.dobYear == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please complete the household member's date of birth.`)
                return false
            }
            // Marital Status
            else if (props?.newHouseholdMember?.mStatus == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the household member's Marital Status.`)
                return false
            }
            // Gender
            else if (props?.newHouseholdMember?.gender == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the household member's Gender.`)
                return false
            }
            // Valid License
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.validLicense == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify whether or not the driver has a valid license.`)
                return false
            }
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.validLicense == "No" && props?.newHouseholdMember?.licenseStatus == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify the current status of the driver's license.`)
                return false
            }
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.validLicense == "No" && props?.newHouseholdMember?.licenseStatus == "Permit" && props?.newHouseholdMember?.permitNumber == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the permit number.`)
                return false
            }
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.validLicense == "No" && props?.newHouseholdMember?.licenseStatus == "Valid Other State License" && props?.newHouseholdMember?.otherLicenseState == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the state of the driver's license.`)
                return false
            }
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.validLicense == "No" && props?.newHouseholdMember?.licenseStatus == "Valid Other State License" && props?.newHouseholdMember?.otherStateLicenseNumber == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the license number of the other state's license.`)
                return false
            }
            // Maintain Auto Insurance
            else if (props?.newHouseholdMember?.age >= 16 && props?.newHouseholdMember?.maintainAutoInsurance == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify whether or not the household member maintains his or her own auto insurance.`)
                return false
            }
            return true
        }
    }
}

export default ValidationHouseholdMember