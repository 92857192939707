const AddMemberValidation = (props) => {
    if (props.infoFields.fullName === "") {
        props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
        props.handleShow()
        return false
    }
    else if (props.infoFields.fullName) {
        var containsNumerical = /\d/;
        if (containsNumerical.test(props.infoFields.fullName)) {
            props.handleValidationMsg("Name contains a numeric character. Please enter a valid Name.", "Oops!")
            props.handleShow()
            return false
        }
        var valid = /^(([a-zA-Z'-.]{2,})+ ([a-zA-Z'-.]+){2,})$/;
        if (!valid.test(props.infoFields.fullName)) {
            props.handleValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.monthSelect === "" || props.infoFields.daySelect === "" || props.infoFields.yearSelect === "") {
            props.handleValidationMsg("Please complete the household member's date of birth.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.mStatus === "") {
            props.handleValidationMsg("Please select the household member's Marital Status.", "Oops!")
            props.handleShow()
            return false
        }
        else if (props.infoFields.gender === "") {
            props.handleValidationMsg("Please select the household member's Gender.", "Oops!")
            props.handleShow()
            return false
        }
        else if (new Date().getFullYear() - parseInt(props.infoFields.yearSelect) >= 16) {
            if (props.infoFields.dLicense == "") {
                props.handleValidationMsg("Please specify whether or not the household member has a valid " + props?.userData?.state + " license.", "Oops!")
                props.handleShow()
                return false
            }
            // Valid license --> no
            else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "") {
                props.handleValidationMsg("Please specify the current status of the driver's license.", "Oops!")
                props.handleShow()
                return false
            }
            else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Valid Other State License" && props.infoFields?.licenseOtherState == "") {
                props.handleValidationMsg("Please provide the state of this license.", "Oops!")
                props.handleShow()
                return false
            }
            else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Valid Other State License" && props.infoFields?.licenseNum == "") {
                props.handleValidationMsg("Please provide the license number.", "Oops!")
                props.handleShow()
                return false
            }
            else if (props.infoFields.dLicense == "0" && props.infoFields.licenseStatus == "Permit" && props.infoFields?.licenseNum == "") {
                props.handleValidationMsg("Please provide the permit number.", "Oops!")
                props.handleShow()
                return false
            }
            // Valid license --> yes
            else if (props.infoFields.dLicense == "1" && props.infoFields.licenseNum == "") {
                props.handleValidationMsg("Please provide the license number.", "Oops!")
                props.handleShow()
                return false
            }
            else if (props.infoFields.dLicense == "1" && props.infoFields.ownInsurance == "") {
                props.handleValidationMsg("Please specify whether or not the household member maintains his or her own auto insurance.", "Oops!")
                props.handleShow()
                return false
            }
            else {
                return true
            }
        }
        else {
            return true
        }
    }
    else {
        return true
    }
}

export default AddMemberValidation