import React, { useContext, useState } from 'react'
import AddVehicleConfirm from './AddVehicleConfirm'
import DriverAssignmentConfirm from './DriverAssignmentConfirm'
import { AddVehicleContext } from '../../AddVehicleContext/AddVehicleContext'
import { NewVehicleContext } from '../../AddVehicleContext/NewVehicleContext'
import ValidationModal from 'components/ValidationModal'
import CheckboxConfirm from './CheckboxConfirm'

export const VehicleConfirm_SaveContext = React.createContext();

const VehicleConfirm_Main = () => {
  const context = useContext(AddVehicleContext);
  const newVehicleContext = useContext(NewVehicleContext);
  const [confirmChanges, setConfirmChanges] = useState(context["addVehicleClass"]?.confirmChanges); 

  const styles = {
    checkboxContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0'
    }
  }

  {/* Ask about where to save value */}
  const saveConfirmCheckbox = (value) => {
    setConfirmChanges(value);

    var addVehicleClassCopy = context["addVehicleClass"];
    addVehicleClassCopy.confirmation = value;
  }

  return (
    <>
      {/* <VehicleDA_StylesContext.Provider
        value={{ styles }}> */}
        <div className="row text-dark">
          <div className="col-12">
            {/* Ask about keeping this here and margin bottom */}
            <h5 className='text-primary text-center fw-bold mb-4'>ADD VEHICLE</h5>

            <AddVehicleConfirm policyStateAbbr={context.getStateAbbr(context.addVehicleClass.policyState)} addVehReqContext={AddVehicleContext} />
            {newVehicleContext?.newVehicle.drivenExistingDriver === "1" && 
              <DriverAssignmentConfirm addVehReqContext={AddVehicleContext} reqAddVehicleClass={"addVehicleClass"} />
            }

            <VehicleConfirm_SaveContext.Provider
              value={{
                saveConfirmCheckbox,
                confirmChanges
              }}
            >
              <CheckboxConfirm styles={styles} />
            </VehicleConfirm_SaveContext.Provider>
          </div>
        </div>
      {/* </VehicleDA_StylesContext.Provider> */}

      <ValidationModal
        title={context.validationTitle}
        show={context.showModal}
        handleClose={() => context.setShowModal(false)}
        validationMsg={context.validationMsg}
      />
    </>
  )
}

export default VehicleConfirm_Main