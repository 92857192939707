import React, { useState } from "react"
import { storageData } from "../../services/Services"

export const VehicleChangeContext = React.createContext()

export const VehicleChangeContextComp = ({ children }) => {
    const [vehicleChangeClass, setVehicleChangeClass] = useState(JSON.parse(storageData.getItem("VehicleChangeClass")))
    const [userData, setUserData] = useState(JSON.parse(storageData.getItem("userData")))
    const [vehiclesList, setVehiclesList] = useState(JSON.parse(storageData.getItem("storedVehicles")))
    const [currentResidence, setCurrentResidence] = useState(JSON.parse(storageData.getItem("currResidence")))
    const [driverList, setDriverList] = useState(JSON.parse(storageData.getItem("storedDrivers")))
    const [memberList, setMemberList] = useState(JSON.parse(storageData.getItem("storedHouseholdMembers")))

    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [showModal, setShowModal] = useState(false)
    const setValidationMsgToggle = (title, value) => {
        setValidationTitle(title);
        setValidationMsg(value);
        setShowModal(true)
    }

    const [showQuestions, setShowQuestions] = useState(true)
    const [addVehicleMenu, setAddVehicleMenu] = useState(false)
    const [cancelPolicyAddVeh, setCancelPolicyAddVeh] = useState(false)
    const [vinLoad, setVinLoad] = useState(false)

    return (
        <VehicleChangeContext.Provider
            value={{
                vehicleChangeClass, setVehicleChangeClass,
                userData, setUserData,
                vehiclesList, setVehiclesList,
                currentResidence, setCurrentResidence,
                driverList, setDriverList,
                memberList, setMemberList,
                validationTitle, setValidationTitle,
                validationMsg, setValidationMsg,
                showModal, setShowModal,
                setValidationMsgToggle,
                showQuestions, setShowQuestions,
                addVehicleMenu, setAddVehicleMenu,
                vinLoad, setVinLoad,
                cancelPolicyAddVeh, setCancelPolicyAddVeh
            }}>
            {children}
        </VehicleChangeContext.Provider>
    )
}

