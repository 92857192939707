import { storageData } from "services/Services"

const VehiclePageValidation = ({ screen, vehicleChangeClass }) => {
    var storageDataClass = JSON.parse(storageData.getItem("VehicleChangeClass"))

    // Checks question/responses for vehicles page
    const vehiclesPage = () => {
        if (!("keptOvernightResponse" in vehicleChangeClass?.vehicles) || vehicleChangeClass?.vehicles?.keptOvernightResponse == "") {
            return { title: "Oops!", message: 'Please select whether or not the vehicles will be kept overnight at your current address.', valid: false }
        }
        else if (vehicleChangeClass?.vehicles?.keptOvernightResponse == "No") {
            // Michigan
            if (storageDataClass?.policyState == "Michigan") {
                var vehiclesOnPolicy = vehicleChangeClass?.vehicles?.vehiclesOnPolicy
                var allVehicles = [...vehiclesOnPolicy?.storedVehicles, ...vehiclesOnPolicy?.newVehicles]
                var notGaraged = 0
                // Iterates through all vehicles to check if any are garaged overnight
                for (var i = 0; i < allVehicles.length; i++) {
                    if (!allVehicles[i]?.garaged || allVehicles[i]?.garaged == undefined) {
                        notGaraged++
                    }
                    else if (allVehicles[i]?.garaged) {
                        if (!("garagedStreetAddress" in allVehicles[i]) || allVehicles[i]?.garagedStreetAddress == "") {
                            return { title: "Oops!", message: 'Please provide the street address for this garaged vehicle.', valid: false }
                        }
                        else if (!("garagedCity" in allVehicles[i]) || allVehicles[i]?.garagedCity == "") {
                            return { title: "Oops!", message: 'Please provide the city for this garaged vehicle.', valid: false }
                        }
                        else if (!("garagedZipCode" in allVehicles[i]) || allVehicles[i]?.garagedZipCode == "") {
                            return { title: "Oops!", message: 'Please provide the zip code for this garaged vehicle.', valid: false }
                        }
                    }
                }
                // If no vehicles are garaged but selected "no" for kept overnight
                if (notGaraged == allVehicles.length) {
                    return { title: "Oops!", message: 'Please select a vehicle that will be garaged overnight.', valid: false }
                }
            }
            // New Jersey/Pennsylvania
            else {
                if (vehicleChangeClass?.vehicles?.keptOvernightResponse == "No" && (!("keptOvernightLocation" in vehicleChangeClass?.vehicles) || vehicleChangeClass?.vehicles?.keptOvernightLocation == "")) {
                    return { title: "Oops!", message: 'Please indicate where the vehicles are kept.', valid: false }
                }
                else if (!("businessPurposeResponse" in vehicleChangeClass?.vehicles) || vehicleChangeClass?.vehicles?.businessPurposeResponse == "") {
                    return { title: "Oops!", message: 'Please select if any vehicles listed above are used for business purposes.', valid: false }
                }
                else if (vehicleChangeClass?.vehicles?.businessPurposeResponse == "Yes" && (!("businessPurposeExplanation" in vehicleChangeClass?.vehicles) || vehicleChangeClass?.vehicles?.businessPurposeExplanation == "")) {
                    return { title: "Oops!", message: 'Please indicate where the vehicles are kept.', valid: false }
                }
            }
        }

        // Checks for any new vehicles
        if (storageDataClass?.vehicles?.vehiclesOnPolicy?.newVehicles?.length != 0) { return } // No validation

        // Checks for changes in stored vehicles if no new vehicles
        else {
            var storedVehicles = vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles
            for (var i = 0; i < storedVehicles?.length; i++) {
                if ("requestedEffDt" in storedVehicles[i]) { return }
                if ("requestedEffDtVehicleCoverage" in storedVehicles[i]) { return }
                if ("requestedEffDtFinancedLeased" in storedVehicles[i]) { return }
                if (vehicleChangeClass?.vehicles?.keptOvernightResponse == "No") { return }
            }
            // Returns validation since no changes have been made
            return { title: "Oops!", message: 'No changes are made.', valid: false }
        }

    }

    // Checks vehicle selection and vehicle uses for driver assignment page
    const driverAssignmentPage = () => {
        var drivers = vehicleChangeClass?.driverAssignment?.drivers
        for (var i = 0; i < drivers.length; i++) {
            // Michigan
            if (storageDataClass?.policyState == "Michigan") {
                if (drivers[i]?.vehicle != "NotPrimaryDriver") {
                    if (!("vehicle" in drivers[i]) || drivers[i]?.vehicle == null) {
                        return { title: "Oops!", message: 'Please choose a primary driver for each vehicle listed.', valid: false }
                    }
                    else if (!("vehicleUse" in drivers[i]) || drivers[i]?.vehicleUse == "") {
                        return { title: "Oops!", message: 'Please select how the vehicle is being used for ' + drivers[i]?.fullName, valid: false }
                    }
                    else if ((drivers[i]?.vehicleUse == "Business" || drivers[i]?.vehicleUse == "Farm") && (!("vehicleUseExplanation" in drivers[i]) || drivers[i]?.vehicleUseExplanation == "")) {
                        return { title: "Oops!", message: `Please explain the type of ${drivers[i]?.vehicleUse} on how the vehicle is being used.`, valid: false }
                    }
                    else if (!("annualMileage" in drivers[i]) || drivers[i]?.annualMileage == "") {
                        return { title: "Oops!", message: 'Please select an estimated annual mileage of the vehicle for ' + drivers[i]?.fullName, valid: false }
                    }
                }
            }
            // New Jersey/Pennsylvania
            else {
                if (drivers[i]?.vehicle != "NotPrimaryDriver") {
                    if (!("vehicle" in drivers[i]) || drivers[i]?.vehicle == null) {
                        return { title: "Oops!", message: 'Please choose a primary driver for each vehicle listed.', valid: false }
                    }
                    else if (!("daysPerWeek" in drivers[i]) || drivers[i]?.daysPerWeek == null) {
                        return { title: "Oops!", message: 'Please provide the number of days per week the vehicle is used for ' + drivers[i]?.fullName, valid: false }
                    }
                    else if (!("milesOneWay" in drivers[i]) || drivers[i]?.milesOneWay == null) {
                        return { title: "Oops!", message: 'Please provide a selection for the number of miles one-way for ' + drivers[i]?.fullName, valid: false }
                    }
                }
            }
        }
    }

    // Checks phone number input for summary page
    const summaryPage = () => {
        var phoneNumber = vehicleChangeClass?.summary?.phoneNumber
        if (phoneNumber == "") {
            return { title: "Oops!", message: "You must enter a valid phone number.", valid: false }
        }
    }

    var validCheck
    // Vehicles Page
    if (screen == "vehicles") {
        validCheck = { ...vehiclesPage() }
    }
    // Driver Assignment Page
    else if (screen == "driver-assignment") {
        validCheck = { ...driverAssignmentPage() }
    }
    // Summary Page
    else if (screen == "summary") {
        validCheck = { ...summaryPage() }
    }

    if (!validCheck?.valid && Object.keys(validCheck) != 0) {
        return validCheck
    }

    return { valid: true }
}

export default VehiclePageValidation