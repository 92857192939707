import React from "react";
import { getCustomerId } from "../../services/Api";
import { paymentEndpoint } from "../../services/apiConfig";


class OneIncManagePayment extends React.Component {

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        const script = document.createElement("script");

        script.src = `${paymentEndpoint.PortalOne.url(this.props.user.userData.env)}/GenericModalV2/PortalOne.js`;
        console.log(script.src)
        script.async = true;
        document.body.appendChild(script);
    }
 
    initEventHandlers(container,props) {
        // remove all old handlers for case when dialog is reopened to avoid double handling
        container.off()
        
        container.on('portalOne.load', function () {
            console.log('portalOne.load event raised')
        })
        container.on('portalOne.unload', function () {
            props.navigate("/")
        })

        container.on('portalOne.paymentComplete', function (e, data) {
            console.log('portalOne.paymentComplete event raised')
            console.log('portalOne.paymentComplete transactionId : ' + data.transactionId)
            console.log('portalOne.paymentComplete saved payment token : ' + data.tokenId)
        })

        container.on('portalOne.error', function (e, data) {
            console.log(data.Description)
        })
    }

    handleSubmit(event) {
        // event.preventDefault()
        getCustomerId(this.props.user.userData.policyNumber, `${this.props.user.userData.firstName} ${this.props.user.userData.lastName}`, this.props.user.userData.env).then((custId) => {
            fetch(`${paymentEndpoint.PortalOne.url(this.props.user.userData.env)}/Api/Api/Session/Create?portalOneAuthenticationKey=${paymentEndpoint.PortalOne.authKey(this.props.user.userData.env)}&CustomerId=${custId.CustomerId}`)
                .then(response => response.json())
                .then(data => {
                    let container = window.$('#portalOneContainer')
                    container.portalOne()
                    this.initEventHandlers(container,this.props)
                    let dialog = container.data('portalOne')
                    //This information would come from carrier's system
                    // var makePayment = {
                    //     'paymentCategory': 'UserSelect',
                    //     'feeContext': 'PaymentWithFee',
                    //     'convenienceFeeType': 'Renewal',
                    //     'amountContext': FormatValue(this.props.user.userData.paymentData.openamt) == "0.00" ? 'AmountDueOnly' : 'SelectOrEnterAmount',
                    //     'minAmountDue': FormatValue(this.props.user.userData.paymentData.openamt),
                    //     'accountBalance': FormatValue(this.props.user.userData.paymentData.totalamt),
                    //     'billingZip': this.props.user.userData.zip,
                    //     // 'billingAddressStreet': '602 Coolidge Dr., Folsom, CA',
                    //     'policyHolderName': `${this.props.user.userData.firstName} ${this.props.user.userData.lastName}`,
                    //     'clientReferenceData1': this.props.user.userData.policyNumber,
                    //     'confirmationDisplay': 'true',
                    //     'saveOption': 'Save',
                    //     'accountGroupCode': 'FallsLake',
                    //     'acknowledgmentRequired': 'true',
                    //     'customerId': custId.CustomerId,
                    //     'sessionId': data.PortalOneSessionKey
                    // }
                    // dialog.makePayment(makePayment)
                    var makePayment = {
                        'customerId': custId.CustomerId,
                        'clientReferenceData1': this.props.user.userData.policyNumber,
                        'paymentCategory': 'UserSelect',
                        'feeContext': 'PaymentWithFee',
                        'feeContext': 'PaymentWithFee',
                        'convenienceFeeType': 'Renewal',
                        'policyHolderName': `${this.props.user.userData.firstName} ${this.props.user.userData.lastName}`,
                        'email': `${this.props.user.userData.emailAddress}`,
                        'autoPayOptions': [{ 'frequency': 'Monthly', 'amount': 100, 'numberOfInstallments': 12, 'withdrawalDates': [1, 2, 3, 4, 5] }, { 'frequency': 'Quarterly', 'amount': 300, 'numberOfInstallments': 6, 'withdrawalDates': [5, 6, 7, 8, 9, 10] }],

                        'amountContext': FormatValue(this.props.user.userData.paymentData.openamt) == "0.00" ? 'AmountDueOnly' : 'SelectOrEnterAmount',
                        'minAmountDue': FormatValue(this.props.user.userData.paymentData.openamt),
                        'accountBalance': FormatValue(this.props.user.userData.paymentData.totalamt),
                        'billingZip': this.props.user.userData.zip,
                        // 'billingAddressStreet': '602 Coolidge Dr., Folsom, CA',
                        'confirmationDisplay': 'true',
                        'saveOption': 'Save',
                        'accountGroupCode': 'FallsLake',
                        'acknowledgmentRequired': 'true',
                        'sessionId': data.PortalOneSessionKey

                    }
                    dialog.managePaymentMethods(makePayment)
                })
        })

    }

    render() {
        return (

            <div>

                <div id="portalOneContainer">{this.handleSubmit()}</div>
                {/* <script async defer src="https://portalone.processonepayments.com/GenericModalV2/PortalOne.js" type="text/javascript"></script> */}
            </div>
        )
    }
}

const FormatValue = (value) => {
    var regex = /[+-]?[0-9]+\.?[0-9]*/g

    var valueString = regex
        .exec(value.replace(",", ""))[0]
    valueString = parseFloat(valueString)
        .toString()
    var newValue = valueString
    if (!valueString.includes('.')) {
        newValue = valueString + '.00'
    } else {
        newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
    }
    return newValue
}

export default OneIncManagePayment