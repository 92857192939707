import DatePick from "components/DatePicker"
import DatePick_MI from "components/DatePicker_MI"
import { useState } from "react"

const EffectiveDateReq = (props) => {
    const { context, michiganData, callback } = props
    const [reqEffDate, setReqEffDate] = useState("")
    const [moveDate, setMoveDate] = useState("")

    return (
        <>
            <h5 className="text-center text-primary fw-bold">CHANGE ADDRESS</h5>

            {/* Effective Date Request */}
            <div className="row mx-2 my-3 align-items-center">
                <div className="col-12 col-sm-6">
                    <strong className="text-primary col-sm-6 align-items-center" style={{ fontSize: '14px' }}>
                        Requested Effective Date *
                    </strong>
                </div>
                <div className="col-12 col-sm-5 col-md-5">
                    <DatePick_MI
                        dateSubmit={(context?.addressChangeClass?.newResidence?.michiganRequestedEffectiveDate) ? context?.addressChangeClass?.newResidence?.michiganRequestedEffectiveDate : michiganData?.michiganRequestedEffectiveDate}
                        setDateSubmit={setReqEffDate}
                        storeValue={callback}
                        nameValue={"michiganRequestedEffectiveDate"}
                        displayCalendar={"bottom-start"}
                    />
                </div>
            </div>

            {/* Date Moved */}
            <div className="row mx-2 my-3 align-items-center">
                <div className="col-12 col-sm-6">
                    <strong className="text-primary col-sm-6 align-items-center" style={{ fontSize: '14px' }}>
                        Date Moved *
                    </strong>
                </div>
                <div className="col-12 col-sm-5 col-md-5">
                    <DatePick
                        dateSubmit={(context?.addressChangeClass?.newResidence?.moveDate) ? context?.addressChangeClass?.newResidence?.moveDate : michiganData?.moveDate}
                        setDateSubmit={setMoveDate}
                        storeValue={callback}
                        nameValue={"moveDate"}
                        displayCalendar={"bottom-start"}
                    />
                </div>
            </div>

            {/* Reason for Change */}
            <div className="row mx-2 my-3">
                <div className="col-12 col-sm-6">
                    <strong className="text-primary col-sm-6" style={{ fontSize: '14px' }}>
                        Reason for Change *
                    </strong>
                </div>
                <div className="col-12 col-sm-5 col-md-5">
                    <textarea rows='8' maxLength={500} style={{ textAlign: "left", whiteSpace: 'pre-wrap', resize: 'none' }}
                        className="col-12 btn btn-default btn-group mt-1"
                        value={(context?.addressChangeClass?.newResidence?.reasonForChange) ? context?.addressChangeClass?.newResidence?.reasonForChange : michiganData?.reasonForChange}
                        onChange={(e) => callback("reasonForChange", e.target.value)}
                    />
                </div>
            </div>

        </>
    )
}

export default EffectiveDateReq