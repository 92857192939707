import React from "react"
import { Spinner } from 'react-bootstrap';
import { getDocument } from "../services/Api";

const PolicyDocumentsSect = (props) => {
  let { documents, complete } = props.paymentData
  complete = complete && "documents" in props.paymentData

  console.log(props)
  const [userData,setUserData]  = React.useState(props.userData) 

  const DownloadPdf = async (endpoint,filename) =>{
    const pdf = `data:application/octet-stream;base64,${await getDocument(endpoint,userData.policyNumber, userData.env)}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = pdf;
    downloadLink.download = filename;
    downloadLink.click();

  }

  return (
    <>
      <div className="d-flex align-content-center flex-column">
        <h4 className="text-center section-title">
          POLICY DOCUMENTS
        </h4>
        <small>
          <table className="table table-sm table-striped">
            <tbody>
              <tr>
                <th className="col-3 small"><small>Document</small></th>
                <th className="col-6 small"><small>Issued</small></th>
                <th className="text-end col-3 small"><small>Download</small></th>
              </tr>
              {complete ?
                documents.map((value, index) => {
                  return (<tr key={index}>
                    <td className="col-3"><small>{value.recordType}</small></td>
                    <td className="col-6"><small>{FormatDate(new Date(value.processedDate))}</small></td>
                    <td className="text-end container-fluid justify-content-end col-3"><a onClick={()=>{DownloadPdf(value.endpoint,`${value.recordType}.pdf`)}} className="fa fa-lg fa-download " style={{ cursor: "pointer" }} /></td>
                  </tr>)
                })
                :
                <>
                  <tr >
                    <td colSpan={3} align='center'>
                      <Spinner animation="border" />
                    </td>
                  </tr>
                </>}
            </tbody>
          </table>
        </small>
        {/* <div className="p-2 payment-message">
          <strong>Not seeing your recent payment?</strong>
          <p>Allow 2-3 business days for your payment to show.</p>
        </div> */}

      </div>
    </>
  )
}



const FormatDate = (tempDate) => {
  var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
  var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
  var dateYear = `${tempDate.getFullYear()}`
  const date = `${dateMonth}-${dateDay}-${dateYear}`
  return date
}
const FormatType = (type) => {
  return type.replace(/([A-Z])/g, ' $1').trim()
}

const FormatValue = (value) => {
  var regex = /[+-]?[0-9]+\.?[0-9]*/g

  var valueString = regex
    .exec(value.replace(",", ""))[0]
  valueString = parseFloat(valueString)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var newValue = valueString
  if (!valueString.includes('.')) {
    newValue = valueString + '.00'
  } else {
    newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
  }
  return "$" + newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default PolicyDocumentsSect