const PossessionVehicleDropdown = () => {
    return (
        <>
            <option value={"0"} disabled>Select from the following...</option>
            <option value={"24470"}>Owned</option>
            <option value={"24570"}>Leased</option>
            <option value={"24670"}>Financed</option>
        </>
    )
}

export default PossessionVehicleDropdown