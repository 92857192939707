import DriverOHM_Main from "../../components/RenewalQuestionnaireComp/DriversOHM/DriverOHM_Main"
import RQ_Header from "./RQ_Header"
import { storageData } from "../../services/Services"
import FormContainer from "../../components/RenewalQuestionnaireComp/FormContainer"
import SaveHelpContainer from "../../components/RenewalQuestionnaireComp/SaveHelpContainer"
import { useEffect, useState } from "react"


const RQ_DriverOHM = () => {
    const [RQ_Data, setRQ_Data] = useState({});
    const user = storageData.getItem('userData')
    const userData = JSON.parse(user)

    useEffect(() => {
        if (storageData.getItem("sessionDataRQ")) {
            setRQ_Data(storageData.getItem("sessionDataRQ"))
        }
    }, [])

    return (
        <>
            <div className="container widthScreen">
                <RQ_Header userData={userData} screen={'driverOHM'} />

                <div className="col-12 mt-2 row">
                    <FormContainer Content={DriverOHM_Main} params={RQ_Data} saveRQ={setRQ_Data} />
                    <SaveHelpContainer />
                </div>
            </div>
        </>
    )
}

export default RQ_DriverOHM