

export default {
    cureLogo: "https://assets.cure.com/img/main-site/cure-logo-resized.webp",
    michigan: require("./mi.png"),
    underline: require("./mi_underline.png"),
    halfRoundy: require("./roundy_up2.png"),
    roundyIcon: require("./icon-roundy.png"),
    roundyBlueSmiling: require("./roundy-blue-smiling.png"),
    happyBirthday: require("./happyBirthday.png"),
    statement: require("./icon-statement.png"),
    bannerPhone: require("./bannerPhone.png"),
    portalOne: {
        cureLogo: require("./c-logo.gif"),
        paymentMethod: {
            Undefined: "../images/bank-icon.png",
            Visa: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-visa-50x32.svg",
            MasterCard: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-master-50x32.svg",
            AmericanExpress: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-amex-50x32.svg",
            Discover: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-dscvr-50x32.svg",
            Eft: "https://portalone.processonepayments.com/GenericModalV2/assets/icon-accounts-bank-50x32.dcb0f6ddf79779b5ea60.svg",
            Other: "../images/card-icon.png"
        }
    }

}