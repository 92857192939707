import { useState } from "react"
import { verifyLogin } from "../services/Api"
import SettingsWindowSecurity from "./SettingsWindowSecurity"
import { Spinner } from 'react-bootstrap';

const SettingsWindow = (props) => {
    const ErrorPassMatch = "The new passwords do not match."
    const ErrorPassWrong = "The current password is incorrect."
    const ErrorPassInvalid = "Passwords must be at least 5 characters which include at least 1 capital letter and 1 number."
    const ErrorUsernameInvalid = "Usernames must be at least 3 characters. Can contain letters, special characters and numbers."
    const ErrorEmailMatch = "The new e-mail addresses do not match."
    const ErrorEmailValid = "The new email address is not valid."
    const ErrorEmailUsed = "That email is already associated with another policy."
    const ErrorUsernameMatch = "The new usernames do not match."
    const ErrorSecurityBlank = "Please select a security question and corresponding answer for all (3) drop-downs above."

    const regExEmail = /\S+@\S+\.\S+/

    const { email, userName, pass, security, phone, zip, emailAddress, billingRegistered, policyNumber, paramName, apiFunction, userValue, setChangedVal } = props
    var currWindow
    switch (true) {
        case email:
        case billingRegistered:
            currWindow = "Email Address"
            break;
        case userName:
            currWindow = "Username"
            break;
        case pass:
            currWindow = "Password"
            break;
        case phone:
            currWindow = "Phone Number"
    }
    const onCancel = props.onCancel
    const [newVal, setNewVal] = useState((security) ? ([]) : "")
    const [input, setInput] = useState(false)
    const [currVal, setCurrVal] = useState("")
    const [confirmVal, setConfirmVal] = useState("")
    const [error, setError] = useState("")
    const [loading, isLoading] = useState(false);


    const onSubmit = async () => {
        isLoading(true);
        if (!security) {
            if (email || billingRegistered) {
                if (newVal.toLowerCase() == confirmVal.toLowerCase()) {
                    if (!regExEmail.test(newVal)) {
                        setError(ErrorEmailValid)
                        isLoading(false);
                        return
                    }
                } else {
                    setError(ErrorEmailMatch)
                    isLoading(false);
                    return
                }
            } else if (userName || billingRegistered) {
                if (newVal !== confirmVal) {
                    setError(ErrorUsernameMatch);
                    isLoading(false);
                    return
                }
                else if (newVal === confirmVal) {
                    // Validates username to length 3, with all characters/numbers/special characters
                    if (!(/^[\d\D]{3,}$/).test(newVal)) {
                        setError(ErrorUsernameInvalid)
                        isLoading(false);
                        return
                    }
                }
            } else if (pass) {
                if (newVal == confirmVal) {
                    if (!(/[A-Z]/.test(newVal) && /[0-9]/.test(newVal))) {
                        setError(ErrorPassInvalid)
                        isLoading(false);
                        return
                    } else {
                        var response = await verifyLogin(userValue, currVal, "")
                        console.log(response)
                        if (response?.data?.Result == "Fail" || response?.data?.Result == "Incorrect Password") {
                            setError(ErrorPassWrong)
                            isLoading(false);
                            return
                        }
                    }
                } else {
                    setError(ErrorPassMatch)
                    isLoading(false);
                    return
                }
            }
        } else {
            var newValFiltered = newVal.filter(val => val.Code != "default" && val.Answer != "")
            if (!newValFiltered.length) {
                setError(ErrorSecurityBlank)
                isLoading(false);
                return
            }
        }
        console.log(props.showError)
        setError("")
        var params = {
            ZipCode: zip,
            PolicyNumber: policyNumber,
            input
        }
        if (paramName == "Username") {
            params["EmailAddress"] = emailAddress
        }
        params[paramName] = newVal
        console.log(params)
        var response = await apiFunction(params)
        console.log(response)

        // Response for email/username updates
        if (response === "Invalid") {
            setError("That email or username is already associated with another policy")
        }
        else {
            setError("");
            setChangedVal()
            onCancel()
        }
        isLoading(false);
    }


    return (
        <>
            <div className="container-fluid p-3 pb-4 mt-3 d-flex flex-column grad-window">
                {(error.length > 0) && !pass &&
                    <div className="text-alert text-center bg-alert border rounded-3 p-3 m-2 mb-3 border-alert">{error}</div>
                }
                {!security &&
                    <>
                        {/* {billingRegistered && !email &&
                            <p className="text-mute small mt-2" >Your current paperless email address is: <strong>{emailAddress}</strong>.</p>
                        } */}
                        <small className="input-group-sm d-flex flex-column">

                            {pass &&
                                <input className="m-2 p-1 flex-row" type="text"
                                    placeholder={`Current ${currWindow}`}
                                    title={`Current ${currWindow}`}
                                    value={currVal}
                                    onChange={(text) => setCurrVal(text.target.value)} />}

                            <input className="m-2 p-1" type="text"
                                title={`New ${currWindow}`}
                                placeholder={`New ${currWindow}`}
                                value={newVal}
                                onChange={(text) => setNewVal(text.target.value)} />

                            <input className="m-2 p-1 flex-row" type="text"
                                placeholder={`Confirm New ${currWindow}`}
                                title={`Confirm New ${currWindow}`}
                                value={confirmVal}
                                onChange={(text) => setConfirmVal(text.target.value)} />
                            {email && <>
                                <label className="row mt-1 mx-1">
                                    <p className="text-primary fw-bold mb-2">This change will not affect your communication preferences with CURE.</p>
                                </label>
                                {/* {billingRegistered &&

                                    <label className="row m-1">
                                        <input className="align-self-start col-1" type="checkbox" value={input} onChange={(e) => { setInput(e.target.checked) }} />
                                        <small className="text-primary col-11"> Use this email address for paperless billing, too.</small>
                                    </label>
                                } */}
                            </>}
                            {pass && ((error.length == 0) ?
                                <p className="text-primary"><strong>NOTE:</strong> Passwords must be at least 5 characters and include at least 1 capital letter and 1 number.</p>
                                :
                                <div className="text-alert text-left bg-alert border rounded-3 p-3 m-2 mb-3 border-alert">{error}</div>)
                            }
                        </small>
                    </>
                }
                {security &&
                    <>
                        <SettingsWindowSecurity
                            newVal={newVal}
                            setNewVal={setNewVal}
                        />
                    </>}
                {!loading &&
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-secondary btn-sm m-1" onClick={onCancel} >CANCEL</button>
                        <button className="btn btn-primary-grad btn-sm m-1" onClick={onSubmit}>CHANGE</button>
                    </div>
                }
                {/* Spinning Loader */}
                {loading && 
                    <div>
                        <Spinner animation="border" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}/>
                    </div>
                }
                {/* {billingRegistered && !email &&
                    <p className="text-mute small mt-3" >IMPORTANT: An email address change for Paperless Billing will not change the email address used to login to <strong>My Account</strong>.</p>
                } */}
            </div>

        </>
    )
}

export default SettingsWindow;