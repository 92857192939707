import { useContext, useState } from "react"
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { StoredAddedVehicles } from "../VehiclesList"
import { AddVehicleContext } from "./VehicleAddMainMI"
import DatePick_MI from "../../../DatePicker_MI"
import VehicleOwned_Coverages from "./VehicleOwned_Coverages"

const VehicleModify_MI = ({ id, type, saveEditOption, newVehicle }) => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const vehicleAddContext = useContext(AddVehicleContext)
    const [requestedEffDt, setRequestedEffDt] = useState("")
    const [financeInfo, setFinanceInfo] = useState({ ...storedAddedContext.vehicleFinanceInfo })

    // Adds finance fields to current vehicle
    var modifyVehicle =
        (type == "modify") ?
            { ...context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles[id], ...financeInfo }
            :
            (type == "modifyEdit" || type == "replaceEdit") ?
                { ...context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles[id] }
                :
                (type == "edit") ?
                    { ...context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles[id] }
                    :
                    { ...newVehicle, ...financeInfo }

    // Saves values for modifying vehicle
    const saveInfo = (key, value) => {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehicleFinanceInfoCopy = modifyVehicle
        vehicleFinanceInfoCopy[key] = value

        // Reset the "collision" coverage deductible when "collision" coverage changes
        if (key == "collisionCoverageType") {
            vehicleFinanceInfoCopy["standardDeductibleCollision"] = ""
        }
        // For modifying stored vehicles only
        if (type == "modify") {
            setFinanceInfo({ ...vehicleFinanceInfoCopy })
            var vehiclesCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles
            vehiclesCopyList[id] = vehicleFinanceInfoCopy
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesCopyList
        }
        // For editing stored vehicles only
        else if (type == "modifyEdit") {
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles[id] = modifyVehicle
        }
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
    }

    return (
        <>
            {/* Financed/Leased with a diff company */}
            <div className="col-xl-12 mt-3">
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-12 text-primary fw-bold">
                        Is your vehicle now being financed or leased with a different company?*
                    </div>

                    <div className="col-xl-12">
                        {["1", "0"].map((value, id) => {
                            return (
                                <div className="radio-inline form-check form-check-inline mt-3" key={id}>
                                    <label className="form-check-label">
                                        <input type={"radio"} className="form-check-input" name="financedLeasedDiffCompany"
                                            checked={modifyVehicle?.vehicleFinanceLeasingCompanyResponse == value ? modifyVehicle?.vehicleFinanceLeasingCompanyResponse : ""}
                                            value={value} onChange={(e) => saveInfo("vehicleFinanceLeasingCompanyResponse", e.target.value)}
                                        />{value == "1" ? "Yes" : "No"}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Selected yes to different finance/leasing company */}
            {modifyVehicle?.vehicleFinanceLeasingCompanyResponse == "1" &&
                <>
                    {/* Finance/Leasing Company Name */}
                    <div className="col-sm-12 text-primary fw-bold" style={{ fontSize: '12px' }}>
                        New Vehicle Finance or Leasing Company Name*
                    </div>
                    <div className="mt-2 mb-3 col-7">
                        <input type={"text"} className="btn-group form-control"
                            placeholder="Finance/Leasing Company Name"
                            value={modifyVehicle?.vehicleFinanceLeasingCompanyName}
                            onChange={(e) => { saveInfo("vehicleFinanceLeasingCompanyName", e.target.value) }}
                        />
                    </div>

                    {/* Loan Number */}
                    <div className="col-sm-12 text-primary fw-bold" style={{ fontSize: '12px' }}>
                        Loan Number*
                    </div>
                    <div className="mt-2 mb-3 col-7">
                        <input type={"text"} className="btn-group form-control"
                            placeholder="Loan Number"
                            value={modifyVehicle?.vehicleLoanNumber}
                            onChange={(e) => { saveInfo("vehicleLoanNumber", e.target.value) }}
                        />
                    </div>

                    {/* Requested Date for financing/leasing company */}
                    <div className="col-sm-12 text-primary fw-bold" style={{ fontSize: '12px' }}>
                        Requested Effective Date*
                    </div>
                    <div className="mt-2 mb-3 col-7">
                        <DatePick_MI
                            dateSubmit={modifyVehicle?.requestedEffDtFinancedLeased}
                            setDateSubmit={setRequestedEffDt}
                            storeValue={saveInfo}
                            nameValue={"requestedEffDtFinancedLeased"}
                        />
                    </div>
                </>
            }

            {/* Update vehicle coverages */}
            {/* <div className="col-xl-12 mt-3">
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-12 text-primary fw-bold">
                        Would you like to update your vehicle coverages?*
                    </div>

                    <div className="col-xl-12">
                        {["1", "0"].map((value, id) => {
                            return (
                                <div className="radio-inline form-check form-check-inline mt-3" key={id}>
                                    <label className="form-check-label">
                                        <input type={"radio"} className="form-check-input" name="updateVehicleCoverage"
                                            checked={modifyVehicle?.vehicleCoverageUpdate == value ? modifyVehicle?.vehicleCoverageUpdate : ""}
                                            value={value} onChange={(e) => saveInfo("vehicleCoverageUpdate", e.target.value)}
                                        />{value == "1" ? "Yes" : "No"}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div> */}

            {/* Selected yes to different finance/leasing company */}
            {modifyVehicle?.vehicleCoverageUpdate == "1" &&
                <>
                    {/* Requested Date for vehicle coverages */}
                    <div className="col-xl-12 justify-content-center my-3">
                        <div className="row form-group form-group-sm">
                            <p className="col-xl-5 col-sm-5 text-primary fw-bold d-flex">
                                Requested Effective Date *
                            </p>
                            <div className="col-xl-7 col-sm-7">
                                <DatePick_MI
                                    dateSubmit={modifyVehicle?.requestedEffDtVehicleCoverage}
                                    setDateSubmit={setRequestedEffDt}
                                    storeValue={saveInfo}
                                    nameValue={"requestedEffDtVehicleCoverage"}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Coverages */}
                    <VehicleOwned_Coverages context={context} newVehicle={modifyVehicle} saveResponses={saveInfo} />
                </>
            }

            {/* Save/Cancel button for menus */}
            {(type == "modify" || type == "modifyEdit") &&
                <div className={`text-right ${!context.showQuestions ? "mb-3" : ""}`}>
                    <input type={"button"} className="btn btn-default" value={"CANCEL"} onClick={() => saveEditOption("cancel", id, "modify")} />
                    <input type={"button"} className="btn btn-primary-grad ms-1" value={"SAVE"} onClick={() => saveEditOption("save", id, "modify")} />
                </div>
            }
        </>
    )
}


export default VehicleModify_MI