import React, { useContext } from "react"
import { HouseholdChangeContext } from "../HouseholdChangeContext"
import VehicleDA_Directions from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_Directions"
import VehicleDA_SelectVehicle from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_SelectVehicle"
import VehicleDA_DriverPolicy from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_DriverPolicy"
import ValidationModal from "components/ValidationModal"
import HouseholdChangeBtnNavigation from "../HouseholdChangeBtnNavigation"

export const HouseholdDA_StylesContext = React.createContext()

const HouseholdDA_Main = () => {
    const context = useContext(HouseholdChangeContext)

    const styles = {
        indent: {
            paddingLeft: "15px",
        },
        blueText: {
            color: "#046ab0",
            fontSize: '13.5px'
        }
    }
    
    return (
        <>
            <HouseholdDA_StylesContext.Provider
                value={{ styles }}>
                <div className="row text-muted bg-block">
                    <div className="col-12">
                        <VehicleDA_Directions />
                        <VehicleDA_SelectVehicle changeReqContext={HouseholdChangeContext} stylesContext={HouseholdDA_StylesContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
                        <VehicleDA_DriverPolicy />
                    </div>
                </div>
            </HouseholdDA_StylesContext.Provider>

            <HouseholdChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default HouseholdDA_Main