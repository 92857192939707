import React, { useContext } from "react"
import { NewVehicleContext } from "components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext"
import { AddVehicleContext } from "components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext"
import { RadioButtonsRow } from "components/StraightThroughVehicleComp/1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/RadioButtonsRow/RadioButtonsRow"
import DropdownRow from "components/StraightThroughVehicleComp/1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/DropdownRow/DropdownRow"
import TooltipModal from "components/StraightThroughVehicleComp/1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/TooltipModal/TooltipModal"
import TextFieldRow from './TextFieldRow/TextFieldRow';

import VehicleRegistrantList from "components/StraightThroughVehicleComp/Dropdowns/VehicleRegistrantList"
import VehicleUseList from "components/StraightThroughVehicleComp/Dropdowns/VehicleUseList"
import EstimatedMileageList from "components/StraightThroughVehicleComp/Dropdowns/EstimatedMileageList"
import OtherDeductibleList from "components/StraightThroughVehicleComp/Dropdowns/OtherDeductibleList"
import CollisionTypeList from "components/StraightThroughVehicleComp/Dropdowns/CollisionTypeList"
import CollisionDeductibleList from "components/StraightThroughVehicleComp/Dropdowns/CollisionDeductibleList"
import DaysDrivenList from "components/StraightThroughVehicleComp/Dropdowns/DaysDrivenList"
import AntiTheftSystemList from "components/StraightThroughVehicleComp/Dropdowns/AntiTheftSystemList"
import HelpfulDocumentsNJ from "./HelpfulDocumentsNJ/HelpfulDocumentsNJ"
import HelpfulDocumentsPA from "./HelpfulDocumentsPA/HelpfulDocumentsPA"

const EndorsementVehicleOwned = ({policyStateAbbr}) => {
    const context = useContext(AddVehicleContext);
    const { newVehicle, setNewVehicle, modal, validateVehicles_3 } = useContext(NewVehicleContext);

    // Only render the form if the vehicle is owned
    if (newVehicle?.vehicleOwnedLeasedFinanced?.toLowerCase() !== "owned") {
        return null;
    }

    // Resets collision deductible for collision type selection
    const setsCollision = (value) => {
        setNewVehicle(prev => ({ ...prev, collisionType: value }))
        setNewVehicle(prev => ({ ...prev, collisionDeductible: "" }))
    }

    // Tooltips
    const tooltips = {
        otherThanCollision: {
            title: "\"Other than Collision\" (Comprehensive)",
            content: "\"Other than Collision\" (Comprehensive) covers your vehicle, and sometimes other vehicles you may be driving, for losses resulting from incidents other than collision. For example, \"Other than Collision\" (Comprehensive) insurance covers damage to your car if it is stolen, or damaged by flood, fire, or animals. It pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as high a deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the policy."
        },
        collisionCoverage: {
            title: "Collision Coverage",
            content: "Collision covers damage to your car when your car hits, or is hit by, another vehicle, or other object. This coverage pays to fix your vehicle less the deductible you choose. To keep your premiums low, select as large a deductible as you feel comfortable paying out of pocket. Coverage is limited to the terms and conditions contained in the policy."
        },
        collisionOptions: {
            title: "What are the options for Collision coverage?",
            content: (
                <>
                    <strong>Broad Collision</strong>: Pays for damages to your vehicle that was damaged regardless of fault, with a deductible that applies only if you are more than 50% at fault <br /><br />
                    <strong>Standard Collision</strong>: Pays for damages to your vehicle that was caused regardless of fault, with a deductible that always applies. <br /><br />
                    <strong>Limited Collision</strong>: Pays for damage to your vehicle if you are less than 50% at fault for an accident. If you are 50% or more at fault Collision coverage for this option will not apply to repair your vehicle.
                </>
            )
        },
        rentalReimbursement: {
            title: "Rental Reimbursement",
            content: "Rental Reimbursement pays for the cost of a rental car if you can't drive the insured vehicle due to theft or damage from an accident. Extended Transportation coverage cannot be purchased unless the car is covered under 'Other than Collision' (Comprehensive) coverage. Coverage is limited to the terms and conditions contained in the policy."
        },
        antiTheftSystem: {
            title: "Vehicle Security Systems",
            content: (
                <>
                    <h5 className="text-primary fw-bold">TYPE OF VEHICLE SECURITY SYSTEM/ALARM</h5>
                    <p><b>What is an Active Alarm?</b><br/>
                    An active alarm is a device or system that must be engaged by the user.</p>
                    <p><b>What is a Passive Alarm?</b><br/>
                    A passive alarm is a device or system that is automatically engaged when the ignition key is moved or stationed in the &ldquo;off&rdquo; position.</p>
                    <p><b>What is a Recovery Device?</b><br/>
                    A recovery device is an electronic homing device or similar device which uses a radio frequency network in conjunction with a participating police agency to locate an automobile.</p>
                    <p><b>What is VIN Etching?</b><br/>
                    VIN etching involves the use of a stencil and glass etching paste to etch your car&rsquo;s vehicle identification number onto its windshield and windows.</p>
                </>
            )
        }
    }

    const garagedQuestion = {
        "MI": `Is this vehicle primarily kept overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`,
        "NJ": `Is this vehicle garaged overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`,
        "PA": `Is this vehicle parked overnight at ${context.addVehicleClass.policyAddressFields.address1}?*`
    }

    const rentalReimbursementOptions = {
        "MI": 
            <>
                <option value="" disabled>Select from the following...</option>
                <option value="$30 per day/ $900 max">$30 per day/ $900 max</option>
                <option value="$40 per day/ $1,200 max">$40 per day/ $1,200 max</option>
                <option value="None">None</option>
            </>,
        "NJ": 
            <>
                <option value="" disabled>Select from the following...</option>
                <option value="$15 per day/ $450 max">$15 per day/ $450 max</option>
                <option value="$30 per day/ $900 max">$30 per day/ $900 max</option>
                <option value="$40 per day/ $1,200 max">$40 per day/ $1,200 max</option>
                <option value="$50 per day/ $1,500 max">$50 per day/ $1,500 max</option>
                <option value="None">None</option>
            </>,
        "PA": 
            <>
                <option value="" disabled>Select from the following...</option>
            </>
    }

    return (
        <form className="col-xl-12 justify-content-center" style={{ margin: "0 0.5rem", padding: "0 0.75rem" }}>
            <RadioButtonsRow
                name="vehicleIsCoOwned"
                question="Is this vehicle co-owned?*"
                value={newVehicle.vehicleIsCoOwned}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleIsCoOwned: e.target.value }))}
                onTooltipClick={tooltips.vehicleIsCoOwned ? () => modal.openModal(tooltips.vehicleIsCoOwned.title, tooltips.vehicleIsCoOwned.content) : null}
            />
            {/* {newVehicle.vehicleIsCoOwned === "1" && (
                <>
                    <TextFieldRow
                        name="vehicleCoOwnerName"
                        question="Name of co-owner?*"
                        value={newVehicle.vehicleCoOwnerName}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleCoOwnerName: e.target.value }))}
                        placeholder="Enter co-owner's name"
                    />
                    <TextFieldRow
                        name="vehicleCoOwnerAddress"
                        question="Address of co-owner?*"
                        value={newVehicle.vehicleCoOwnerAddress}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleCoOwnerAddress: e.target.value }))}
                        placeholder="Enter co-owner's address"
                    />
                </>
            )} */}
            <RadioButtonsRow
                name="garaged"
                question={`${garagedQuestion[policyStateAbbr]}`}
                value={newVehicle.garaged}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, garaged: e.target.value }))}
                onTooltipClick={tooltips.garaged ? () => modal.openModal(tooltips.garaged.title, tooltips.garaged.content) : null}
            />
            <DropdownRow
                name="vehicleRegistrant"
                question="In what name is this vehicle registered, or going to be registered?*"
                options={<VehicleRegistrantList />}
                value={newVehicle.vehicleRegistrant}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleRegistrant: e.target.value }))}
                onTooltipClick={tooltips.vehicleRegistrant ? () => modal.openModal(tooltips.vehicleRegistrant.title, tooltips.vehicleRegistrant.content) : null}
            />
            <RadioButtonsRow
                display= {["NJ", "PA"].includes(policyStateAbbr)}
                name={policyStateAbbr == "NJ" ? "vehicleIsNJ" : "vehicleIsPA"}
                question={`Is this vehicle registered in the state of ${policyStateAbbr == "NJ"? "New Jersey" : "Pennsylvania"}?*`}
                value={policyStateAbbr == "NJ" ? newVehicle.vehicleIsNJ : newVehicle.vehicleIsPA}
                onChange={(e) => policyStateAbbr == "NJ" ? setNewVehicle(prev => ({ ...prev, vehicleIsNJ: e.target.value })) : setNewVehicle(prev => ({ ...prev, vehicleIsPA: e.target.value })) }
            /> 
            <DropdownRow
                name="vehicleUse"
                question="How will this vehicle be used?*"
                options={<VehicleUseList state={context?.userData?.state} />}
                value={newVehicle.vehicleUse}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleUse: e.target.value }))}
                onTooltipClick={tooltips.vehicleUse ? () => modal.openModal(tooltips.vehicleUse.title, tooltips.vehicleUse.content) : null}
            />
            <DropdownRow
                display= {["NJ", "PA"].includes(policyStateAbbr)}
                name="daysDrivenPerWeek"
                question="Days Driven per Week:*"
                options={<DaysDrivenList />}
                value={newVehicle.daysDrivenPerWeek}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, daysDrivenPerWeek: e.target.value }))}
                defaultValue="5 days per week"
            />
            <DropdownRow
                display= {["MI"].includes(policyStateAbbr)}
                name="estimatedMileage"
                question="Estimated Annual Mileage:*"
                options={<EstimatedMileageList />}
                value={newVehicle.estimatedMileage}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, estimatedMileage: e.target.value }))}
                onTooltipClick={tooltips.estimatedMileage ? () => modal.openModal(tooltips.estimatedMileage.title, tooltips.estimatedMileage.content) : null}
                defaultValue="9,001-12,000"
            />
            <RadioButtonsRow
                name="wantsOtherThanCollision"
                question='Do you choose "Other than Collision" (Comprehensive) coverage?*'
                value={newVehicle.wantsOtherThanCollision}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, wantsOtherThanCollision: e.target.value }))}
                onTooltipClick={tooltips.otherThanCollision ? () => modal.openModal(tooltips.otherThanCollision.title, tooltips.otherThanCollision.content) : null}
            />
            {newVehicle.wantsOtherThanCollision === "1" && (
                <>
                    <DropdownRow
                        display= {["MI"].includes(policyStateAbbr)}
                        name="otherDeductible"
                        question='Choose your "Other than Collision" (Comprehensive) deductible.*'
                        options={<OtherDeductibleList />}
                        value={newVehicle.otherDeductible}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, otherDeductible: e.target.value }))}
                    />
                    <RadioButtonsRow
                        display= {["NJ"].includes(policyStateAbbr)}
                        name="otherDeductible"
                        question='Choose your "Other than Collision" (Comprehensive) deductible.*'
                        options={[
                            { value: "$750", label: "$750" }
                        ]}
                        noteContent={"If you are interested in a deductible other than the standard $750, you will be required to sign a new Coverage Selection Form. Please call 800-535-2873 to speak to a CURE representative."}
                        alignItemsCenter={false}
                        value={newVehicle.otherDeductible}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, otherDeductible: e.target.value }))}
                    />
                    <RadioButtonsRow
                        display= {["PA"].includes(policyStateAbbr)}
                        name="otherDeductible"
                        question='Choose your "Other than Collision" (Comprehensive) deductible.*'
                        options={[
                            { value: "$500", label: "$500" }
                        ]}
                        noteContent={"If you are interested in a deductible other than the standard $500, you will be required to sign a new Coverage Selection Form. Please call 800-535-2873 to speak to a CURE representative."}
                        alignItemsCenter={false}
                        value={newVehicle.otherDeductible}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, otherDeductible: e.target.value }))}
                    />
                    <RadioButtonsRow
                        name="wantsCollision"
                        question='Do you choose "Collision" coverage?*'
                        value={newVehicle.wantsCollision}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, wantsCollision: e.target.value }))}
                        onTooltipClick={tooltips.collisionCoverage ? () => modal.openModal(tooltips.collisionCoverage.title, tooltips.collisionCoverage.content) : null}
                    />

                    {newVehicle.wantsCollision === "1" && (
                        <>
                            <DropdownRow
                                display= {["MI"].includes(policyStateAbbr)}
                                name="collisionType"
                                question='Choose your "Collision" type.*'
                                options={<CollisionTypeList />}
                                value={newVehicle.collisionType}
                                onChange={(e) => setsCollision(e.target.value)}
                                onTooltipClick={tooltips.collisionOptions ? () => modal.openModal(tooltips.collisionOptions.title, tooltips.collisionOptions.content) : null}
                            />
                            <DropdownRow
                                display= {["MI"].includes(policyStateAbbr)}
                                name="collisionDeductible"
                                question='Choose your "Collision" coverage deductible.*'
                                options={<CollisionDeductibleList />}
                                value={newVehicle.collisionDeductible}
                                onChange={(e) => setNewVehicle(prev => ({ ...prev, collisionDeductible: e.target.value }))}
                            />
                            <RadioButtonsRow
                                display= {["NJ"].includes(policyStateAbbr)}
                                name="collisionDeductible"
                                question='Choose your "Collision" coverage deductible.*'
                                options={[
                                    { value: "$750", label: "$750" }
                                ]}
                                noteContent={"If you are interested in a deductible other than the standard $750, you will be required to sign a new Coverage Selection Form. Please call 800-535-2873 to speak to a CURE representative."}
                                alignItemsCenter={false}
                                value={newVehicle.collisionDeductible}
                                onChange={(e) => setNewVehicle(prev => ({ ...prev, collisionDeductible: e.target.value }))}
                            />
                            <RadioButtonsRow
                                display= {["PA"].includes(policyStateAbbr)}
                                name="collisionDeductible"
                                question='Choose your "Collision" coverage deductible.*'
                                options={[
                                    { value: "$500", label: "$500" }
                                ]}
                                noteContent={"If you are interested in a deductible other than the standard $500, you will be required to sign a new Coverage Selection Form. Please call 800-535-2873 to speak to a CURE representative."}
                                alignItemsCenter={false}
                                value={newVehicle.collisionDeductible}
                                onChange={(e) => setNewVehicle(prev => ({ ...prev, collisionDeductible: e.target.value }))}
                            />
                        </>
                    )}

                    <DropdownRow
                        display= {["MI", "NJ"].includes(policyStateAbbr)}
                        name="rentalCoverage"
                        question="Rental Reimbursement*"
                        options={rentalReimbursementOptions[policyStateAbbr]}
                        value={newVehicle.rentalCoverage}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, rentalCoverage: e.target.value }))}
                        onTooltipClick={() => modal.openModal(tooltips.rentalReimbursement.title, tooltips.rentalReimbursement.content)}
                    />
                    <RadioButtonsRow
                        display= {["PA"].includes(policyStateAbbr)}
                        name="rentalCoverage"
                        question="Rental Reimbursement*"
                        value={newVehicle.rentalCoverage}
                        onChange={(e) => setNewVehicle(prev => ({ ...prev, rentalCoverage: e.target.value }))}
                        onTooltipClick={() => modal.openModal(tooltips.rentalReimbursement.title, tooltips.rentalReimbursement.content)}
                    />
                </>
            )}
            <DropdownRow
                display= {["NJ"].includes(policyStateAbbr)}
                name="antiTheftSystem"
                question="The standard factory equipped anti-theft system devices will be automatically applied. Does this vehicle carry an additional alarm or recovery device?*"
                options={<AntiTheftSystemList />}
                value={newVehicle.antiTheftSystem}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, antiTheftSystem: e.target.value }))}
                onTooltipClick={tooltips.antiTheftSystem ? () => modal.openModal(tooltips.antiTheftSystem.title, tooltips.antiTheftSystem.content) : null}
                defaultValue="None"
            />

            <RadioButtonsRow
                name="vehicleCustomization"
                question="Does this vehicle have modifications or customization?*"
                value={newVehicle.vehicleCustomization}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleCustomization: e.target.value }))}
                onTooltipClick={tooltips.vehicleCustomization ? () => modal.openModal(tooltips.vehicleCustomization.title, tooltips.vehicleCustomization.content) : null}
            />
            <RadioButtonsRow
                name="vehicleCommercial"
                question="Is this a commercial vehicle?*"
                value={newVehicle.vehicleCommercial}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleCommercial: e.target.value }))}
                onTooltipClick={tooltips.vehicleCommercial ? () => modal.openModal(tooltips.vehicleCommercial.title, tooltips.vehicleCommercial.content) : null}
            />
            <RadioButtonsRow
                name="vehicleCommercialPurposes"
                question="Is this vehicle used for commercial purposes, including but not limited to:"
                value={newVehicle.vehicleCommercialPurposes}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehicleCommercialPurposes: e.target.value }))}
                onTooltipClick={tooltips.vehicleCommercialPurposes ? () => modal.openModal(tooltips.vehicleCommercialPurposes.title, tooltips.vehicleCommercialPurposes.content) : null}
                indentedContent={
                    <>
                        a) limousine, taxi, or other delivery services other than through transportation network companies;<br />
                        b) in the business of pickup or delivery of magazines, newspapers, food, or any other products;<br />
                        c) and/or emergency services?*
                    </>
                }
            />
            <RadioButtonsRow
                name="leasedRentToOthers"
                question="Does the applicant or any other driver on the application lease or rent this vehicle to others?*"
                value={newVehicle.leasedRentToOthers}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, leasedRentToOthers: e.target.value }))}
                onTooltipClick={tooltips.leasedRentToOthers ? () => modal.openModal(tooltips.leasedRentToOthers.title, tooltips.leasedRentToOthers.content) : null}
            />
            <RadioButtonsRow
                name="vehiclePlowSnow"
                question="Is this vehicle used to plow snow for compensation?*"
                value={newVehicle.vehiclePlowSnow}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, vehiclePlowSnow: e.target.value }))}
                onTooltipClick={tooltips.vehiclePlowSnow ? () => modal.openModal(tooltips.vehiclePlowSnow.title, tooltips.vehiclePlowSnow.content) : null}
            />
            <RadioButtonsRow
                name="drivenExistingDriver"
                question={`Will this vehicle ${newVehicle.vehicleYear} ${newVehicle.vehicleMake} ${newVehicle.vehicleModel} ${newVehicle.vehicleVIN} be driven by an existing driver on your policy?*`}
                value={newVehicle.drivenExistingDriver}
                onChange={(e) => setNewVehicle(prev => ({ ...prev, drivenExistingDriver: e.target.value }))}
                onTooltipClick={tooltips.drivenExistingDriver ? () => modal.openModal(tooltips.drivenExistingDriver.title, tooltips.drivenExistingDriver.content) : null}
            />
            <TooltipModal
                title={modal.modalTitle}
                msg={modal.modalMsg}
                show={modal.showModal}
                handleClose={modal.closeModal}
            />

            {policyStateAbbr === "NJ" && <HelpfulDocumentsNJ policyType= {context.userData.policyType} />}
            {policyStateAbbr === "PA" && <HelpfulDocumentsPA />}
        </form>
    )
}

export default EndorsementVehicleOwned