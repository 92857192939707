import { useContext } from "react"
import { VehicleChangeContext } from "../VehicleChangeContext"

const VehicleSummary_Changes = () => {
    const context = useContext(VehicleChangeContext)
    var vehiclesOnPolicy = context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy
    var storedVehicles = vehiclesOnPolicy?.storedVehicles
    var newVehicles = vehiclesOnPolicy?.newVehicles

    // Adds whitespace to specific removals
    const displayRemovalReason = (reason) => {
        if (reason == "NotRunning") { return "Not Running" }
        else if (reason == "OtherPolicy") { return "Other Policy" }
        else if (reason == "CancelPolicy") { return "Cancel Policy" }
        else { return reason }
    }
    return (
        <>
            <div className="mt-3">
                <h6 className="text-primary fw-bold col-7">SUMMARY OF VEHICLE CHANGES</h6>
                <p className="small col-sm-8 col-6">
                    Please review your changes and click Confirm below.
                    All policy changes are subject to Underwriting review and approval.
                </p>
            </div>

            {/* Changes to STORED VEHICLES */}
            <div className="form-group-sm">
                {storedVehicles.map((vehicle, index) => {
                    return (
                        <div key={index}>
                            {/* Replaced Vehicles */}
                            {"requestedEffDt" in vehicle && "vehicleRemovalReason" in vehicle && "vehicleMake" in vehicle &&
                                <>
                                    {/* Old vehicle */}
                                    <div className="my-2">
                                        <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                            {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                        </strong>
                                        <em className="small" style={{ color: '#555555' }}>
                                            — {vehicle?.vehicleRemovalReason == "Other" ? vehicle?.vehicleRemovalExplanation : displayRemovalReason(vehicle?.vehicleRemovalReason)}
                                        </em>
                                    </div>
                                    {/* Replaced Vehicle */}
                                    <div className="my-2">
                                        <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                            {vehicle?.vehicleYear} {vehicle?.vehicleMake} {vehicle?.vehicleModel}
                                        </strong>
                                        <em className="small" style={{ color: '#555555' }}>
                                            — New Vehicle
                                        </em>
                                    </div>
                                </>
                            }
                            {/* Removed Vehicles */}
                            {"requestedEffDt" in vehicle && "vehicleRemovalReason" in vehicle && !("vehicleMake" in vehicle) &&
                                <>
                                    <div className="my-2">
                                        <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                            {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                        </strong>
                                        <em className="small" style={{ color: '#555555' }}>
                                            — {vehicle?.vehicleRemovalReason == "Other" ? vehicle?.vehicleRemovalExplanation : displayRemovalReason(vehicle?.vehicleRemovalReason)}
                                        </em>
                                    </div>
                                </>
                            }
                            {/* Modified Vehicles (MICHIGAN ONLY) */}
                            {vehicle?.type == "modify" &&
                                <>
                                    <div className="my-2">
                                        <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                            {vehicle?.Year} {vehicle?.Make} {vehicle?.Model}
                                        </strong>
                                        <em className="small" style={{ color: '#555555' }}> — Modified</em>
                                    </div>
                                </>
                            }
                        </div>
                    )
                })}
            </div>

            {/* NEW VEHICLES */}
            <div className="form-group-sm">
                {newVehicles.map((vehicle, index) => {
                    return (
                        <div key={index}>
                            <div className="my-2">
                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>
                                    {vehicle?.vehicleYear} {vehicle?.vehicleMake} {vehicle?.vehicleModel}
                                </strong>
                                <em className="small" style={{ color: '#555555' }}> — New Vehicle</em>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default VehicleSummary_Changes