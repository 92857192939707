import { useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { HouseholdChangeContext } from "./HouseholdChangeContext"
import { storageData } from "services/Services";
import { Spinner } from 'react-bootstrap';
import { submitChangeRequest } from "services/Api";
import HouseholdPageValidation from "./HouseholdPageValidation";

const HouseholdChangeBtnNavigation = () => {
    const context = useContext(HouseholdChangeContext)
    const navigate = useNavigate()
    var screen = window.location.pathname.split("/").pop()
    var state = context?.userData?.state
    const [confirmationLoad, setConfirmationLoad] = useState(false)
    const attestRef = useRef(false)

    const checkNewDrivers = () => {
        var newDrivers = context?.householdChangeClass?.driversHHMs?.drivers?.newDrivers
        if (newDrivers?.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    const navigateBack = (screen) => {
        var screenObj = {
            "update-household": "/home",
            "driver-assignment": "/update-drivers/update-household",
            "additional": "/update-drivers/update-household",
            "summary": (state == "Michigan") ? "/update-drivers/driver-assignment" : (checkNewDrivers()) ? "/update-drivers/additional" : "/update-drivers/update-household",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    const navigateToNext = (screen) => {
        var screenObj = {
            "update-household": (state == "Michigan") ? "/update-drivers/driver-assignment" : (checkNewDrivers()) ? "/update-drivers/additional" : "/update-drivers/summary",
            "driver-assignment": "/update-drivers/summary",
            "additional": "/update-drivers/summary",
            "summary": "/update-drivers/confirmation",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    const nextButtonNavigate = async () => {

        // Validation check
        var { title, message, valid } = HouseholdPageValidation({ screen, householdChangeClass: context.householdChangeClass })
        if (!valid) {
            context.setValidationMsgToggle(title, message)
            return
        }
        // Updates the driver assignment drivers with edited/new drivers
        if (screen == "update-household") {
            var storedDriversCopy = context?.householdChangeClass?.driversHHMs?.drivers?.storedDrivers
            var newDriversCopy = context?.householdChangeClass?.driversHHMs?.drivers?.newDrivers
            var allDrivers = [...storedDriversCopy, ...newDriversCopy]
            context.householdChangeClass.driverAssignment.drivers = allDrivers
        }
        if (screen == "summary") {
            if (!(attestRef.current.checked) && context?.userData?.state == "Michigan") {
                context.setValidationMsgToggle("Oops!", "Please attest to confirm all answers to the questions listed in the change are true and accurate to the best of your knowledge.")
                return
            }
        }
        storageData.setItem("HouseholdChangeClass", JSON.stringify(context.householdChangeClass))

        // Sends change class to backend
        if (screen == "summary") {
            setConfirmationLoad(true)

            var req = {
                "requestType": "Household",
                "classRequest": storageData.getItem("HouseholdChangeClass")
            }
            // Api call to send data
            await submitChangeRequest(req)
        }
        navigate(navigateToNext(screen))
    }

    return (
        <>
            <hr className="my-2" />
            <div className="mt-2 mb-3">
                {confirmationLoad &&
                    <div className="text-center">
                        Confirming Changes... &nbsp;
                        <Spinner animation="border" />
                    </div>
                }
                {!confirmationLoad &&
                    <div>
                        {/* Attestation - only on SUMMARY page (MICHIGAN) */}
                        {screen == "summary" && context?.userData?.state == "Michigan" &&
                            <>
                                <label className="mb-4" style={checkBoxText}>
                                    <input type="checkbox" ref={attestRef} />
                                    <span className="ms-2">I hereby certify that the answers to the questions listed in the change are true and accurate to the best of my knowledge.*</span>
                                    <br />
                                </label>
                            </>
                        }

                        {/* Back Button */}
                        {screen !== "update-household" &&
                            <>
                                <button type="button" className="btn btn-default editCancelBtn" onClick={() => { navigate(navigateBack(screen)) }}>
                                    BACK
                                </button>
                            </>
                        }
                        {/* Continue Button */}
                        <button type="submit" className="btn btn-primary-grad right pull-right editCancelBtn" onClick={() => { nextButtonNavigate() }}>
                            NEXT
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

const checkBoxText = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '13px'
}

export default HouseholdChangeBtnNavigation

