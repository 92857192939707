import { useState } from "react"
import { getSecurityQuestions } from "../services/Api"

const SettingsWindowSecurity = (props) => {
    var { newVal, setNewVal } = props
    const questionsNum = 3
    const [securityQuestions, setSecurityQuestions] = useState([])
    var tempArray = []
    for (var i = 0; i < questionsNum; i++) {
        tempArray.push((newVal[i]) ? ({ ...newVal[i] }) : ({ Code: "default", Answer: '' }))
    }

    const questionsInit = async () => {

        const response = await getSecurityQuestions()
        var list = ([x, ...data]) => data
        setSecurityQuestions(list(response.data))
    }
    if (!securityQuestions.length) {
        questionsInit()
    }
    // // const { email, pass, security, zip, policyNumber, paramName, apiFunction } = props
    // // const currWindow = (email) ? "Email Address" : "Password"
    // const onCancel = props.onCancel
    // // const [newVal, setNewVal] = useState()
    // // const [currVal, setCurrVal] = useState()
    // // const [confirmVal, setConfirmVal] = useState()
    // const securityQuestions = [
    //     {
    //         Code: "MAIDEN",
    //         Text: "What is your mother's maiden name?",
    //     },
    //     {
    //         Code: "SINGERBAND",
    //         Text: "Who is your favorite singer or band?",
    //     },
    //     {
    //         Code: "MOVIE",
    //         Text: "What is your favorite movie?",
    //     },
    //     {
    //         Code: "FIRSTCARCOLOR",
    //         Text: "What was the color of your first car?",
    //     },
    //     {
    //         Code: "STREETNAME",
    //         Text: "What is the name of the street you grew up on?",
    //     },
    // ];
    var questionsObj = {}
    for (let question of securityQuestions) {
        questionsObj[question.Text] = question.Code
    }
    var usedQuestions = tempArray.map((val) => { return (val.Code) })

    var formattedQuestions = []
    for (var i = 0; i < tempArray.length; i++) {
        var formattedQ = []
        for (var j = 0; j < securityQuestions.length; j++) {
            if (usedQuestions.indexOf(questionsObj[securityQuestions[j].Text]) == i ||
                !usedQuestions.includes(questionsObj[securityQuestions[j].Text])) {
                formattedQ.push(securityQuestions[j])
            }
        }
        formattedQuestions.push(formattedQ)
    }


    const onChange = (text, index, key) => {
        tempArray[index][key] = text.target.value
        setNewVal(tempArray)
    }

    var QuestionsOptions = ({ value }) => {
        return (
            value.map((question, index) => {
                return <option className="text-primary" key={index} value={question.Code}>{question.Text}</option>
            })
        )
    }


    return (
        <>
            <>
                <strong className="p-2">Security Question {0 + 1}</strong>
                <select className="m-2 p-1 mx-4" value={tempArray[0].Code} onChange={(text) => { onChange(text, 0, "Code") }} >
                    <option value={"default"} disabled >Select a security question...</option>
                    <QuestionsOptions value={formattedQuestions[0]} />
                </select>
                <input className="m-2 p-2 mx-4" type="text" placeholder="Answer" title="Answer" value={tempArray[0].Answer} onChange={(text) => { onChange(text, 0, "Answer") }} />
            </>
            <>
                <strong className="p-2">Security Question {1 + 1}</strong>
                <select className="m-2 p-1 mx-4" value={tempArray[1].Code} onChange={(text) => { onChange(text, 1, "Code") }} >
                    <option value={"default"} disabled >Select a security question...</option>
                    <QuestionsOptions value={formattedQuestions[1]} />
                </select>
                <input className="m-2 p-2 mx-4" type="text" placeholder="Answer" title="Answer" value={tempArray[1].Answer} onChange={(text) => { onChange(text, 1, "Answer") }} />
            </>
            <>
                <strong className="p-2">Security Question {1 + 2}</strong>
                <select className="m-2 p-1 mx-4" value={tempArray[2].Code} onChange={(text) => { onChange(text, 2, "Code") }} >
                    <option value={"default"} disabled >Select a security question...</option>
                    <QuestionsOptions value={formattedQuestions[2]} />
                </select>
                <input className="m-2 p-2 mx-4" type="text" placeholder="Answer" title="Answer" value={tempArray[2].Answer} onChange={(text) => { onChange(text, 2, "Answer") }} />
            </>
        </>
    )
}

export default SettingsWindowSecurity;