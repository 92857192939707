import { useEffect, useState } from "react"
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { storageData } from "../../../services/Services";
import AgreementContent from "./AgreementContent";
import { submitRenewalQuestionnaire } from "../../../services/Api";
import ValidationModal from "../../ValidationModal";
import ValidationAgreement from "./ValidationAgreement";

const SignAndAgree_Main = () => {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [sessionData, setSessionData] = useState({})
    const [userData, setUserData] = useState({})
    const [show, setShow] = useState(false)
    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [validationLoad, setValidationLoad] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Retrieves userData
        var storedUserData = storageData.getItem('userData')
        if (storedUserData) {
            storedUserData = JSON.parse(storedUserData)
            setUserData(storedUserData)
        }

        // Retrieves sessionData
        var storedSessionDataRQ = storageData.getItem('sessionDataRQ')
        if (storedSessionDataRQ) {
            storedSessionDataRQ = JSON.parse(storedSessionDataRQ)
            setSessionData({ ...storedSessionDataRQ })
        }

    }, [])

    const back = () => {
        storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
        navigate("/renewalQuestionnaire/vehicle")
    }

    const submit = async () => {
        var check = ValidationAgreement({ sessionData, setValidationMsg, handleShow, setValidationLoad, setValidationTitle, setLoad })
        if (check) {
            setLoad(true)
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            var resp = await submitRenewalQuestionnaire(sessionData)
            setLoad(false)
            navigate("/home")
        }
    }

    return (
        <>

            <ValidationModal
                show={show}
                handleClose={handleClose}
                validationMsg={validationMsg}
                title={validationTitle}
            />

            <AgreementContent
                userData={userData}
                sessionData={sessionData}
                setSessionData={setSessionData}
            />

            {/* Next Button */}
            <div>
                <div className="pb-4 my-4">
                    {!load &&
                        <>
                            <input type="submit" value={"Back"} className="signBackButton me-2" onClick={back} />
                            <input type="submit" value={"Complete Renewal Application Questionnaire"} className="submitRQButton mb-2" onClick={submit} />
                        </>
                    }
                    {load &&
                        <Spinner className='ms-3' animation="border" />
                    }
                </div>
            </div>
        </>
    )
}

export default SignAndAgree_Main