import { Spinner } from 'react-bootstrap';
import { useEffect, useState } from "react"
import { storageData } from "../../../services/Services"
import { useNavigate } from "react-router-dom"
import ValidationResidence from "./ValidationResidence"
import ValidationModal from "../../ValidationModal"
import StateDropdown from '../DriversOHM/StateDropdown';

const CurrentResidence = (props) => {
    const navigate = useNavigate()
    const [currAddress, setCurrAddress] = useState({})
    const [sessionData, setSessionData] = useState(props.data)
    const [show, setShow] = useState(false)
    const [load, setLoad] = useState(false)
    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [validationLoad, setValidationLoad] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Gets current residence for policy
        var currStoredAddress = storageData.getItem('currResidence')
        if (currStoredAddress) {
            currStoredAddress = JSON.parse(currStoredAddress)
            var address = {
                streetAddress1: currStoredAddress.address1,
                streetAddress2: currStoredAddress.address2,
                city: currStoredAddress.city,
                county: currStoredAddress.county,
                state: currStoredAddress.state,
                zip: currStoredAddress.zip.substring(0, 5)
            }
            setCurrAddress({ ...address })
        }

        // Gets session data if not a new questionnaire
        var storedSessionData = storageData.getItem('sessionDataRQ')
        if (storedSessionData) {
            storedSessionData = JSON.parse(storedSessionData)
            setSessionData(storedSessionData)
        }
        // Sets up the renewal questionnaire class and default policy info
        else {
            var userData = storageData.getItem("userData")
            userData = JSON.parse(userData)
            var effDate = new Date(userData.idCardData[0].effectivedt)
            var expDate = new Date(effDate.setMonth(effDate.getMonth() + 6))
            expDate = expDate.getMonth() + "/" + expDate.getDate() + "/" + expDate.getFullYear()

            var sessionDataCopy = sessionData
            sessionDataCopy.renewalQuestionnaireID = storageData.getItem("renewalQuestionnaireID")
            sessionDataCopy.policyNumber = userData.policyNumber
            sessionDataCopy.effectiveDate = userData.idCardData[0].effectivedt
            sessionDataCopy.expirationDate = expDate
            sessionDataCopy.firstName = userData.firstName
            sessionDataCopy.lastName = userData.lastName
            sessionDataCopy.policyState = returnPolicyState(userData.state)
            setSessionData({ ...sessionDataCopy })
        }
    }, [])

    // Yes/No Response
    const verifyResidence = (selection) => {
        var resData = sessionData
        if (selection == "Yes") {
            resData.currentResidence = { ...sessionData.currentResidence, ...currAddress }
        }
        else {
            resData.currentResidence = props.data.currentResidence
        }
        resData.currentResidence.response = selection
        setSessionData({ ...resData })
    }

    // Set address values if no
    const setValues = (key, value) => {
        var resData = sessionData
        resData.currentResidence.newResidence[key] = value
        setSessionData({ ...resData })
    }

    // Returns saved values from local storage
    const returnValue = (label) => {
        var savedValues = {
            "Street Address": sessionData.currentResidence.newResidence.streetAddress1,
            "Street Address Line 2": sessionData.currentResidence.newResidence.streetAddress2,
            "City": sessionData.currentResidence.newResidence.city,
            "State": sessionData.currentResidence.newResidence.state,
            "Zip": sessionData.currentResidence.newResidence.zip
        }
        return savedValues[label]
    }

    // Returns abbreviated policy state
    const returnPolicyState = (state) => {
        var abbrState = {
            "Michigan": "MI",
            "New Jersey": "NJ",
            "Pennsylvania": "PA",
        }
        return abbrState[state]
    }

    // Next button
    const submit = async () => {
        var check = await ValidationResidence({ sessionData, setValidationMsg, handleShow, setValidationLoad, setValidationTitle, setLoad })
        if (check) {
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            navigate("/renewalQuestionnaire/driverohm")
        }
    }

    return (
        <>
            <div className="col-12">
                {validationLoad &&
                    <ValidationModal
                        show={show}
                        handleClose={handleClose}
                        validationMsg={validationMsg}
                        title={validationTitle}
                    />
                }
                <div>
                    <p className="labelAddr">
                        Is {(currAddress.streetAddress2 != null ? currAddress.streetAddress2 : currAddress.streetAddress1) + ", " + currAddress.city + ", " + currAddress.state + " " + currAddress.zip} the named insured's current residence?
                    </p>
                </div>
                <div className="radio">
                    <div className="radio-inline form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input" name="sameAddress" type={"radio"} value={"Yes"}
                                checked={sessionData.currentResidence.response == "Yes"} onChange={(e) => verifyResidence(e.target.value)} />Yes
                        </label>
                    </div>
                    <div className="radio-inline form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" name="sameAddress" type={"radio"} value={"No"}
                                checked={sessionData.currentResidence.response == "No"} onChange={(e) => verifyResidence(e.target.value)} />No
                        </label>
                    </div>
                </div>

                {sessionData.currentResidence.response == "No" &&
                    <>
                        <div className="mt-2">
                            <p className="currResidenceTitle mb-0" style={{ fontSize: '14px' }}>
                                CURRENT RESIDENCE
                            </p>
                        </div>
                        {/* Street Address */}
                        {['Street Address', 'Street Address Line 2'].map((label, key) => {
                            return (
                                <div key={key}>
                                    <input value={returnValue(label)} name={label} className="textStreet" maxLength={100} placeholder={label}
                                        onChange={(e) => setValues((label == "Street Address") ? "streetAddress1" : "streetAddress2", e.target.value)} />
                                    <br />
                                </div>
                            )
                        })}

                        {/* City and State */}
                        <div className="cityAndState" style={{ display: 'inline-flex' }}>
                            {/* City */}
                            <div className="col-6 pe-2">
                                <input value={returnValue("City")} name={'newCity'} id="newCity" className="textStreet textCity" maxLength={100} placeholder='City'
                                    onChange={(e) => setValues("city", e.target.value)} />
                                <br />
                            </div>
                            {/* State */}
                            <div className="col-6">
                                <select defaultValue={returnValue("State")} className="textStreet selectState" id="statesList" style={{ WebkitAppearance: 'listbox', backgroundColor: 'white' }} onClick={(e) => setValues("state", e.target.value)}>
                                    <option value={''}>State</option>
                                    {StateDropdown()}
                                </select>
                                <br />
                            </div>
                            <br />
                        </div>

                        {/* Postal/Zip */}
                        <div>
                            <input value={returnValue("Zip")} name={'Zip'} className="textStreet" style={{ width: '30%' }} maxLength={100} placeholder='Zip Code'
                                onChange={(e) => setValues("zip", e.target.value)} />
                            <br />
                        </div>

                    </>
                }
                {/* Next Button */}
                <div>
                    <div className="pb-4 my-4">
                        {!load &&
                            <input type="submit" value={""} className="nextButton" onClick={submit} />
                        }
                        {load &&
                            <Spinner className='ms-3' animation="border" />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CurrentResidence