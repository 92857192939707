const VehicleValidation_NJPA = ({ button, index, type, vehicle }) => {

    // Adding a vehicle
    const addVehicleCheck = () => {
        if (!("vehicleVIN" in vehicle) || vehicle?.vehicleVIN == "") {
            return { title: "Oops!", message: "Please enter a valid VIN.", valid: false }
        }
        else if (!("securityType" in vehicle) || vehicle?.securityType == "0") {
            return { title: "Oops!", message: 'Please specify the type of security system/alarm for this vehicle.', valid: false }
        }
        else if (!("requestedEffDt" in vehicle) || vehicle?.requestedEffDt == "") {
            return { title: "Oops!", message: 'Please provide a requested effective date.', valid: false }
        }
        else if (!("dateObtained" in vehicle) || vehicle?.dateObtained == "") {
            return { title: "Oops!", message: 'Please enter the date you obtained the vehicle in the form MM/DD/YYYY.', valid: false }
        }
        else if (!("otherThanCollisionToggle" in vehicle) || vehicle?.otherThanCollisionToggle == "") {
            return { title: "Oops!", message: 'Please specify if you choose "Other than Collision" (Comprehensive) coverage.', valid: false }
        }
        else if (vehicle?.otherThanCollisionToggle == "1" && (!("rentalReimbursement" in vehicle) || vehicle?.rentalReimbursement == "")) {
            return { title: "Oops!", message: 'Please select the rental reimbursement.', valid: false }
        }
        else if (vehicle?.otherThanCollisionToggle == "1" && (!("collisionCoverageToggle" in vehicle) || vehicle?.collisionCoverageToggle == "")) {
            return { title: "Oops!", message: 'Please select if the vehicle will have "Collision" coverage.', valid: false }
        }
        else if (!("stateRegistered" in vehicle) || vehicle?.stateRegistered == "State") {
            return { title: "Oops!", message: 'Please select which state the vehicle is registered in.', valid: false }
        }
        else if (!("vehicleOwnedLeasedFinanced" in vehicle) || vehicle?.vehicleOwnedLeasedFinanced == "") {
            return { title: "Oops!", message: 'Please select whether the vehicle is owned, leased, or financed.', valid: false }
        }
        else if (!("coOwned" in vehicle) || vehicle?.coOwned == "") {
            return { title: "Oops!", message: 'Please select whether or not the vehicle is co-owned.', valid: false }
        }
        else if (vehicle?.coOwned == "1" && (!("coOwnerName" in vehicle) || vehicle?.coOwnerName == "")) {
            return { title: "Oops!", message: 'Please enter the name of the co-owner.', valid: false }
        }
        else if (vehicle?.coOwned == "1" && (!("coOwnerAddress" in vehicle) || vehicle?.coOwnerAddress == "")) {
            return { title: "Oops!", message: 'Please enter the address of the co-owner.', valid: false }
        }
        else if (vehicle?.coOwned == "1" && (!("coOwnerCity" in vehicle) || vehicle?.coOwnerCity == "")) {
            return { title: "Oops!", message: 'Please enter the city where the co-owner lives.', valid: false }
        }
        else if (vehicle?.coOwned == "1" && (!("coOwnerState" in vehicle) || vehicle?.coOwnerState == "State")) {
            return { title: "Oops!", message: 'Please enter the state where the co-owner lives.', valid: false }
        }
        else if (vehicle?.coOwned == "1" && (!("coOwnerZip" in vehicle) || vehicle?.coOwnerZip == "")) {
            return { title: "Oops!", message: 'Please enter the zip where the co-owner lives.', valid: false }
        }
        else if (!("nameRegistered" in vehicle) || vehicle?.nameRegistered == "0") {
            return { title: "Oops!", message: 'Please select the name the vehicle will be registered with.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantName" in vehicle) || vehicle?.registrantName == "")) {
            return { title: "Oops!", message: 'Please enter the name of the registrant.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantAddress" in vehicle) || vehicle?.registrantAddress == "")) {
            return { title: "Oops!", message: 'Please enter the address of the registrant.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantCity" in vehicle) || vehicle?.registrantCity == "")) {
            return { title: "Oops!", message: 'Please enter the city where the registrant lives.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantState" in vehicle) || vehicle?.registrantState == "State")) {
            return { title: "Oops!", message: 'Please enter the state where the registrant lives.', valid: false }
        }
        else if (vehicle?.nameRegistered == "43095" && (!("registrantZip" in vehicle) || vehicle?.registrantZip == "")) {
            return { title: "Oops!", message: 'Please enter the zip where the registrant lives.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24570" && (!("vehicleLeasingCompany" in vehicle) || vehicle?.vehicleLeasingCompany == "")) {
            return { title: "Oops!", message: 'Please select the name of the Leasing Company.', valid: false }
        }
        else if (vehicle?.vehicleOwnedLeasedFinanced == "24670" && (!("vehicleFinanceCompany" in vehicle) || vehicle?.vehicleFinanceCompany == "")) {
            return { title: "Oops!", message: 'Please select the name of the Financing Company.', valid: false }
        }
    }
    // Removing a vehicle
    const removeVehicleCheck = () => {
        if (!("vehicleRemovalReason" in vehicle) || vehicle?.vehicleRemovalReason == "") {
            return { title: "Oops!", message: 'Please select the reason for removing the vehicle.', valid: false }
        }
        else if (!("requestedEffDt" in vehicle) || vehicle?.requestedEffDt == "") {
            return { title: "Oops!", message: 'Please provide a requested effective date.', valid: false }
        }
    }

    var validCheck
    // Adding
    if (type == "add" || type == "edit") {
        validCheck = { ...addVehicleCheck() }
    }
    // Removing
    else if (type == "remove") {
        validCheck = { ...removeVehicleCheck() }
    }
    // Replacing
    else if (type == "replace" || type == "replaceEdit") {
        validCheck = { ...addVehicleCheck(), ...removeVehicleCheck() }
    }

    // Checks if any fields are missing
    if (!validCheck?.valid && Object.keys(validCheck) != 0) {
        return validCheck
    }

    return { valid: true }
}

export default VehicleValidation_NJPA