import CurrentResidence from "../../components/RenewalQuestionnaireComp/Residence/CurrentResidence"
import RQ_Header from "./RQ_Header"
import { storageData } from "../../services/Services"
import FormContainer from "../../components/RenewalQuestionnaireComp/FormContainer"
import SaveHelpContainer from "../../components/RenewalQuestionnaireComp/SaveHelpContainer"
import RQ_ApplicationClass from "./RQ_ApplicationClass"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const RQ_Residence = () => {
    const navigate = useNavigate()
    const [RQ_Data, setRQ_Data] = useState(RQ_ApplicationClass);
    const user = storageData.getItem('userData')
    const userData = JSON.parse(user)

    return (
        <>
            <div className="container widthScreen">
                <RQ_Header userData={userData} screen={"residence"} />

                <div className="col-12 mt-4 row">
                    <FormContainer Content={CurrentResidence} params={RQ_Data} saveRQ={setRQ_Data} />
                    <SaveHelpContainer />
                </div>
            </div>
        </>
    )
}

export default RQ_Residence