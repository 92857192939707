import React, { useContext, useState } from 'react'

const DriverAssignmentConfirm = ({addVehReqContext, reqAddVehicleClass}) => {
  const context = useContext(addVehReqContext);
  const [drivers, ] = useState(context[reqAddVehicleClass]?.driverAssignment?.drivers); //driverList

  return (
    <>
      {/* Ask about margin bottom */}
      <div className='col-12 mt-5 mb-4' style={{ fontSize: '16px' }}>
        {/* Ask about margin bottom */}
        <h5 className='text-primary text-center fw-bold mb-3'>DRIVER ASSIGNMENT</h5>

        <div className='row'>
          {drivers.map((driver, driverIndex) => {
            return (
                (driver?.driverStatus == "" || driver?.driverStatus == "New Driver" || driver?.driverStatus == "Adding As Driver") && //Add if needed
                <div className='row pb-2' key={driverIndex}>
                  <div className='col-xs-12 col-md-6'>
                    <strong className="text-primary">
                      <span className="text-uppercase">{driver.fullName}</span>
                    </strong>
                  </div>
                  <div className='col-xs-12 col-md-6'>
                      <span className="text-uppercase">{driver.vehicle}</span><br/>
                      <span className="text-uppercase">{driver.vehicleVIN}</span>
                  </div>
                </div>
                )
            })}
        </div>
      </div>
    </>
  )
}

export default DriverAssignmentConfirm