import React from 'react';
import { useNavigate } from 'react-router-dom';
import { storageData } from 'services/Services';

const FooterSection = () => {
    const navigate = useNavigate();

    // Removes changes for extra storage in local storage & navigates to home page
    const removeItems = () => {
        storageData.removeItem("updateVehicleData");
        storageData.removeItem("updateDriverAssignmentData");
        storageData.removeItem("newVehicles");
        storageData.removeItem("dbVehicles");
        storageData.removeItem("vehicleSummaryChanges");
        storageData.removeItem("VehicleChangeConfirmationNumber");
        storageData.removeItem("storedDrivers");
        storageData.removeItem("storedHouseholdMembers");
        storageData.removeItem("storedVehicles");
        storageData.removeItem("PendingAddedVehicles");
        storageData.removeItem("PendingRemovedVehicles");
        storageData.removeItem("PendingAddedDrivers");
        storageData.removeItem("PendingAddedHhms");
        storageData.removeItem("PendingRemovedDrivers");
        storageData.removeItem("PendingRemovedHhms");
        storageData.removeItem("PendingAddress");
        storageData.removeItem("vehicleInfo");
        storageData.removeItem("currResidence");
        storageData.removeItem("AddVehicleClass");
        storageData.removeItem("EndorsementChangeID");
        storageData.removeItem("responseData");
        navigate("/home");
    }

  return (
    <div className="footer">
        <div className="text-center mb-4">
            <input
                type={"button"}
                className="btn btn-primary-grad"
                value={"Return to My Account Home"}
                onClick={removeItems}
            />
        </div>
    </div>
  );
};

export default FooterSection;