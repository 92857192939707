import { Spinner } from 'react-bootstrap';
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAddress, getDrivers, getVehicles, getEffectiveDate, getLeasingList, getFinanceCompanyList, getDropdownOptions, getPendingPolicyChanges, fetchRenewalQuestionnaire, getWebsiteMaintenanceStatus, getWebsiteMaintenanceMessage, getFinanceLeasedCompaniesGWList } from "../services/Api";
import VehicleChangeClass from './ChangeRequest_VehicleChangeComp/VehicleChangeClass';
import HouseholdChangeClass from './ChangeRequest_HouseholdChangeComp/HouseholdChangeClass';
import { storageData } from '../services/Services';
import AddressChangeClass from './ChangeRequest_AddressChangeComp/AddressChangeClass';
import AddVehicleClass from './StraightThroughVehicleComp/AddVehicleContext/AddVehicleClass';

const LoadPolicyChangeInfo = (props) => {
  const navigate = useNavigate()
  var currScreen = useLocation().pathname
  const [addressData, setAddressData] = useState(false)
  const [driverData, setDriverData] = useState(false)
  const [memberData, setMemberData] = useState(false)
  const [vehicleData, setVehicleData] = useState(false)
  const [pendingData, setPendingData] = useState(false)
  const [loadDone, setLoadDone] = useState(false)
  const [websiteStatus, setWebsiteStatus] = useState(null);
  const [websiteMsg, setWebsiteMsg] = useState(null);

  var userData = storageData.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }
  const { systemId, policyNumber, env } = userData

  useEffect(() => {
    if (currScreen.includes("vehicle") || currScreen.includes("drivers") || currScreen.includes("address") || currScreen.includes("renewalQuestionnaire") || currScreen.includes("endorsement")) {
      if (!currScreen.includes("renewalQuestionnaire")) {
        // Map screen names to their corresponding classes and type names
        const screenMappings = {
          "vehicle": { class: VehicleChangeClass, name: "VehicleChangeClass" },
          "drivers": { class: HouseholdChangeClass, name: "HouseholdChangeClass" },
          "address": { class: AddressChangeClass, name: "AddressChangeClass" },
          "endorsement": { class: AddVehicleClass, name: "AddVehicleClass" }
        };

        // Find the matching screen key from the mapping
        const mapping = Object.entries(screenMappings).find(([key]) => currScreen.includes(key));
        if (mapping) {
          const [screenKey, { class: type, name: typeName }] = mapping;
          storageData.setItem(typeName, JSON.stringify(type));
        }
      }
      navigateToNext()
    }
    else {
      navigate("/home")
    }
  }, [loadDone])

  useEffect(() => {
    // Retrieves maintenance status and message from server
    const fetchData = async () => {
      try {
        const websiteStatusResponse = await getWebsiteMaintenanceStatus()
        const websiteMessageResponse = await getWebsiteMaintenanceMessage()
        storageData.setItem("websiteMaintenance", JSON.stringify({
          status: websiteStatusResponse?.data,
          message: websiteMessageResponse?.data
        }))
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    // Redirect to maintenance page if maintenance is on for Add Vehicle Endorsement
    const checkMaintenance = () => {
      const websiteMaintenance = JSON.parse(storageData?.getItem("websiteMaintenance"));
      if (currScreen.includes("endorsement")) {
        if (websiteMaintenance?.status?.Result == "Active" && websiteMaintenance?.status?.Pages?.includes("EndorsementAddVehicle")) {
          navigate("/maintenance")
          return
        }
      }
    }

    fetchData().then(() => {
      checkMaintenance();
    });
  }, [])

  // Navigates to according page
  const navigateToNext = async () => {
    var addressRetrieved = storageData.getItem('currResidence')
    var driversRetrieved = storageData.getItem('storedDrivers')
    var membersRetrieved = storageData.getItem('storedHouseholdMembers')
    var vehiclesRetrieved = storageData.getItem('storedVehicles')
    if (addressRetrieved && driversRetrieved && membersRetrieved && vehiclesRetrieved) {
      if (currScreen === "/change-address/landing") {
        addToClass("AddressChangeClass")
        navigate("/change-address/new-address")
      }
      else if (currScreen === "/update-drivers/landing") {
        addToClass("HouseholdChangeClass")
        navigate("/update-drivers/update-household")
      }
      else if (currScreen === "/update-vehicles/landing") {
        addToClass("VehicleChangeClass")
        navigate("/update-vehicles/vehicles")
      }
      else if (currScreen === "/endorsement/landing") {
        addToClass("AddVehicleClass")
        window.location.replace("/endorsement/add-vehicle")
      }
      else if (currScreen === "/renewalQuestionnaire/landing") {
        var renewalQuestionnaireID = storageData.getItem("renewalQuestionnaireID")

        // Checks if renewal questionnaire saved data exists
        if (renewalQuestionnaireID) {
          var renewalQuestionnaireReq = { renewalQuestionnaireID: renewalQuestionnaireID }
          const responseRQ = await fetchRenewalQuestionnaire(renewalQuestionnaireReq)
          if (responseRQ?.renewalQuestionnaireID) {
            var sessionData = JSON.parse(responseRQ?.data)
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            navigate(responseRQ?.pageSavedOn)
          }
          else {
            navigate("/renewalQuestionnaire/residence")
          }
        }
        else {
          navigate("/renewalQuestionnaire/residence")
        }
      }
    }
    else {
      await fetchAddress()
      await fetchDriversMembers()
      await fetchVehicles()
      await fetchPendingChanges(userData?.policyNumber)
      await fetchLeasingList()
      await fetchFinanceCompanyList()
      await fetchFinanceLeasedCompaniesList()
      await fetchDropdownOptions()
      setLoadDone(true)
    }
  }

  // Get Address
  const fetchAddress = useCallback(async () => {
    const response = await getAddress(systemId, policyNumber, env)
    const data = response?.data?.result
    var currResidence = {};
    var currResidenceBill = {};
    if (Object.keys(data)?.length !== 0) {
      if ("Address1" in data[0]) {
        var dataBill = data.filter((val) => {
          return val.AddressType == "InsuredBillingAddr"
        })
        var dataMail = data.filter((val) => {
          return val.AddressType == "InsuredMailingAddr" || val.AddressType == null
        })
        if (dataBill?.length) {
          currResidenceBill = {
            address1: dataBill[dataBill.length - 1].Address1,
            address2: dataBill[dataBill.length - 1].Address2,
            city: dataBill[dataBill.length - 1].City,
            state: dataBill[dataBill.length - 1].State,
            zip: dataBill[dataBill.length - 1].Zip,
            county: dataBill[dataBill.length - 1].County,
            region: dataBill[dataBill.length - 1].Region,
            insuredDriver: dataBill[dataBill.length - 1].FullName
          }
        }
        if (dataMail?.length) {
          currResidence = {
            address1: dataMail[dataMail.length - 1].Address1,
            address2: dataMail[dataMail.length - 1].Address2,
            city: dataMail[dataMail.length - 1].City,
            state: dataMail[dataMail.length - 1].State,
            zip: dataMail[dataMail.length - 1].Zip,
            county: dataMail[dataMail.length - 1].County,
            region: dataMail[dataMail.length - 1].Region,
            insuredDriver: dataMail[dataMail.length - 1].FullName
          }
          currResidenceBill = (dataBill?.length) ? currResidenceBill : currResidence
        }
      }
      else {
        currResidence = {
          address1: data[data.length - 1].Address1,
          address2: data[data.length - 1].Address2,
          city: data[data.length - 1].City,
          state: data[data.length - 1].State,
          zip: data[data.length - 1].Zip,
          county: data[data.length - 1].County,
          region: data[data.length - 1].Region,
          firstName: data[data.length - 1].FirstName,
          lastName: data[data.length - 1].LastName,
          insuredDriver: data[data.length - 1].FirstName + " " + data[data.length - 1].LastName
        }
        currResidenceBill = currResidence
      }
    }
    storageData.setItem('currResidence', JSON.stringify(currResidence))
    storageData.setItem('currResidenceBill', JSON.stringify(currResidenceBill))
    setAddressData(true)
  }, [addressData])

  // Get Drivers
  const fetchDriversMembers = useCallback(async () => {
    const response = await getDrivers(systemId, policyNumber, env)
    const data = response.data.result
    var driversArr = []
    var hMembers = []

    for (var i = 0; i < Object.keys(data).length; i++) {
      var date = new Date(data[i].dob)
      var year;
      var month;
      var day;
      var newDateFormat;
      // Formats date of birth (edge)
      if (data[i].dob[2] === "-") {
        year = date.getFullYear()
        month = date.getMonth() + 1
        day = date.getDate()
        newDateFormat = `${month}/${day}/${year}`
      }
      else {
        year = date.getFullYear()
        month = date.getMonth() + 1
        day = date.getDate()
        newDateFormat = `${month}/${day}/${year}`
      }
      // Organizes drivers/household members
      if (data[i].type === "Driver") {
        var driversStored = {}
        driversStored["fullName"] = data[i].name
        driversStored["monthSelect"] = month
        driversStored['daySelect'] = day
        driversStored['yearSelect'] = year
        driversStored["DOB"] = newDateFormat
        driversStored["mStatus"] = data[i].maritalStatus
        driversStored["gender"] = data[i].gender
        driversStored["licenseNum"] = data[i].licensenumber
        driversStored["currRel"] = data[i].relationship
        driversStored["driverStatus"] = ""
        driversStored["initialType"] = "Driver"
        driversStored["currStatus"] = data[i].driverStatus
        driversArr.push(driversStored)
      }
      else if (data[i].type === "OHM") {
        var membersStored = {}
        membersStored["fullName"] = data[i].name
        membersStored["monthSelect"] = month
        membersStored['daySelect'] = day
        membersStored['yearSelect'] = year
        membersStored["DOB"] = newDateFormat
        membersStored["mStatus"] = data[i].maritalStatus
        membersStored["gender"] = data[i].gender
        membersStored["licenseNum"] = data[i].licensenumber
        membersStored["memberStatus"] = ""
        membersStored["initialType"] = "Ohm"
        membersStored["currRel"] = data[i].relationship
        membersStored["currStatus"] = data[i].driverStatus
        hMembers.push(membersStored)
      }
    }
    storageData.setItem('storedDrivers', JSON.stringify(driversArr))
    storageData.setItem('storedHouseholdMembers', JSON.stringify(hMembers))
    setDriverData(true)
    setMemberData(true)
  }, [driverData, memberData])

  // Get Vehicles
  const fetchVehicles = useCallback(async () => {
    const vehicleList = [];
    for (var i = 0; i < userData?.idCardData?.length; i++) {
      var vehicleInfo = {
        "Year": userData.idCardData[i].modelyr,
        "Make": userData.idCardData[i].manufacturer.toUpperCase(),
        "Model": userData.idCardData[i].model.toUpperCase(),
        "VIN": userData.idCardData[i].vehidentificationnumber
      }
      vehicleList.push(vehicleInfo);
    }

    storageData.setItem('storedVehicles', JSON.stringify(vehicleList))
    setVehicleData(true)
  }, [vehicleData])

  // Get leasing list
  const fetchLeasingList = useCallback(async () => {
    const response = await getLeasingList()
    const data = response.data;
    storageData.setItem('LeasingList', JSON.stringify(data))
  }, [])

  // Get finance company list
  const fetchFinanceCompanyList = useCallback(async () => {
    const response = await getFinanceCompanyList()
    const data = response.data;
    storageData.setItem('FinanceCompanyList', JSON.stringify(data))
  }, [])

  // Get finance company list
  const fetchFinanceLeasedCompaniesList = useCallback(async () => {
    const response = await getFinanceLeasedCompaniesGWList()
    const data = response.data;
    const companiesList = data.FinanceLeasedCompanies.providerListItems;
    
    const leasedCompanies = companiesList.filter((company) => company.providerMini.partyInfo.taxInfo.legalEntityCd === "Leasing Company");
    const financedCompanies = companiesList.filter((company) => company.providerMini.partyInfo.taxInfo.legalEntityCd === "Finance Company");

    const leasedCompaniesStr = JSON.stringify(leasedCompanies);
    const financedCompaniesStr = JSON.stringify(financedCompanies);
    //console.log(leasedCompaniesStr);
    //console.log(financedCompaniesStr);

    storageData.setItem('LeasingListGW', leasedCompaniesStr);
    storageData.setItem('FinanceListGW', financedCompaniesStr);
  }, [])

  const fetchDropdownOptions = useCallback(async () => {
    const questionIDs = [
      60,  // VehicleRegistrant
      151, // VehicleUse
      103, // EstimatedMileage
      21,  // OtherDeductible
      104, // CollisionType
      20   // CollisionDeductible
    ];
    const response = await getDropdownOptions()
    const data = response.data;
    storageData.setItem('DropdownOptions', JSON.stringify(data))
  }, [])

  // Sort pending drivers/hhms
  const sortPendingDrivers = (personList, type, status) => {
    var driversArr = []
    var hMembers = []

    for (var i = 0; i < Object.keys(personList).length; i++) {
      var date = new Date(personList[i]?.DOB)
      var year;
      var month;
      var day;
      var newDateFormat;

      year = date.getFullYear()
      month = date.getMonth() + 1
      day = date.getDate()
      newDateFormat = `${month}/${day}/${year}`

      const driverStatus = ["DriverOwnInsurance", "DriverMoved", "DriverRemoved", "LicenseRevoked", "LicenseSuspended", "LicenseExpired", "LicenseSurrendered"]
      const memberStatus = ["OHMOwnInsurance", "OHMMoved", "NewHouseholdMember"]
      // Organizes drivers/household members
      var driversPending = {}
      driversPending["fullName"] = personList[i]?.FirstName
      driversPending["DOB"] = newDateFormat
      driversPending["gender"] = personList[i]?.Sex == "850" ? "Male" : "Female"
      driversPending["licenseNum"] = personList[i]?.LicenseNumber
      // Removed Drivers
      if ((personList[i]?.SelectedType == "Remove" || personList[i]?.SelectedType == "Driver") && driverStatus.includes(personList[i].Status) && status == "Remove Pending") {
        driversPending["type"] = "Driver"
        driversPending["driverStatus"] = status
        driversArr.push(driversPending)
      }
      // Added Drivers
      else if ((personList[i]?.SelectedType === "Driver" && !driverStatus?.includes(personList[i]?.Status)) && personList[i]?.Status == "NewDriver"
        && personList[i]?.SelectedType != "Ohm" && status != "Remove Pending" && type == "pendingDrivers" && !memberStatus.includes(personList[i]?.Status)) {
        driversPending["type"] = "Driver"
        driversPending["driverStatus"] = status
        driversArr.push(driversPending)
      }
      // OHM to Driver
      else if (personList[i]?.InitialType == "New" && personList[i]?.Status == "OHMAddAsDriver" && status == "Add Pending") {
        driversPending["type"] = "Driver"
        driversPending["driverStatus"] = "Add Pending"
        driversArr.push(driversPending)
      }
      // Household Members
      else if (personList[i]?.SelectedType === "Ohm" || memberStatus.includes(personList[i]?.Status) || personList[i]?.Status == "OHMAddAsDriver") {
        var membersPending = {}
        membersPending["fullName"] = personList[i]?.FirstName
        membersPending["DOB"] = newDateFormat
        membersPending["gender"] = personList[i]?.Sex == "850" ? "Male" : "Female"
        membersPending["licenseNum"] = personList[i]?.LicenseNumber
        membersPending["memberStatus"] = status
        membersPending["type"] = "Hhm"
        hMembers.push(membersPending)
      }
    }
    if (type == "pendingDrivers") {
      return driversArr
    }
    else if (type == "pendingHhms") {
      return hMembers
    }
  }

  // Gets pending data
  const fetchPendingChanges = useCallback(async (policyNumber) => {
    const response = await getPendingPolicyChanges(policyNumber)

    var addressData = response?.data?.PendingChangesInfo;
    var sortedAddedDrivers = sortPendingDrivers(response.data?.PendingNewPersonChangesInfo, "pendingDrivers", "Add Pending")
    var sortedAddedHhms = sortPendingDrivers(response.data?.PendingNewPersonChangesInfo, "pendingHhms", "Add Pending")
    var sortedRemovedDrivers = sortPendingDrivers(response.data?.PendingChangedPersonChangesInfo, "pendingDrivers", "Remove Pending")
    var sortedRemovedHhms = sortPendingDrivers(response.data?.PendingChangedPersonChangesInfo, "pendingHhms", "Remove Pending")
    var checkOHMToDriver = sortPendingDrivers(response.data?.PendingChangedPersonChangesInfo, "pendingDrivers", "Add Pending")
    if (checkOHMToDriver?.length > 0) {
      sortedAddedDrivers = [...sortedAddedDrivers, ...checkOHMToDriver]
    }

    var newVehicleData = response?.data?.PendingNewVehicleChangesInfo;
    var removedVehicleData = response?.data?.PendingRemovedVehicleChangesInfo;
    if (addressData !== undefined) {
      storageData.setItem("PendingAddress", JSON.stringify(addressData));
    }
    if (sortedAddedDrivers !== undefined) {
      storageData.setItem("PendingAddedDrivers", JSON.stringify(sortedAddedDrivers));
    }
    if (sortedAddedHhms !== undefined) {
      storageData.setItem("PendingAddedHhms", JSON.stringify(sortedAddedHhms));
    }
    if (sortedRemovedDrivers !== undefined) {
      storageData.setItem("PendingRemovedDrivers", JSON.stringify(sortedRemovedDrivers));
    }
    if (sortedRemovedHhms !== undefined) {
      storageData.setItem("PendingRemovedHhms", JSON.stringify(sortedRemovedHhms));
    }
    if (newVehicleData !== undefined) {
      storageData.setItem("PendingAddedVehicles", JSON.stringify(newVehicleData));
    }
    if (removedVehicleData !== undefined) {
      storageData.setItem("PendingRemovedVehicles", JSON.stringify(removedVehicleData));
    }
    setPendingData(true);
  }, [pendingData])

  // Adds pulled data to stored classes
  const addToClass = (classType) => {
    var storedClass = storageData.getItem(classType)
    var userData = JSON.parse(storageData.getItem("userData"))
    var currResidence = storageData.getItem("currResidence")
    var storedDrivers = storageData.getItem("storedDrivers")
    var storedHouseholdMembers = storageData.getItem("storedHouseholdMembers")
    var storedVehicles = storageData.getItem("storedVehicles")
    var pendingAddress = storageData.getItem("PendingAddress")
    var pendingAddedDrivers = storageData.getItem("PendingAddedDrivers")
    var pendingAddedHhms = storageData.getItem("PendingAddedHhms")
    var pendingRemovedDrivers = storageData.getItem("PendingRemovedDrivers")
    var pendingRemovedHhms = storageData.getItem("PendingRemovedHhms")
    var pendingNewVehicles = storageData.getItem("PendingAddedVehicles")
    var pendingRemovedVehicles = storageData.getItem("PendingRemovedVehicles")
    var platform = (window.innerWidth < 768) ? "Mobile" : "Desktop"

    storedClass = JSON.parse(storedClass)
    storedClass.policyUserId = userData?.policyUserId
    storedClass.effectiveDate = userData?.effectiveDate
    storedClass.expirationDate = getExpirationDate(userData?.effectiveDate)
    storedClass.firstName = userData?.firstName
    storedClass.lastName = userData?.lastName
    storedClass.policyNumber = userData?.policyNumber
    storedClass.policyState = userData?.state
    storedClass.emailAddress = userData?.policyEmailAddress != null && userData?.policyEmailAddress != "" ? userData?.policyEmailAddress : userData?.emailAddress
    storedClass.vehicles.vehiclesOnPolicy.storedVehicles = JSON.parse(storedVehicles)
    storedClass.driverAssignment.drivers = JSON.parse(storedDrivers)
    storedClass.policyAddressFields = JSON.parse(currResidence)
    storedClass.driversHHMs.drivers.storedDrivers = JSON.parse(storedDrivers)
    storedClass.driversHHMs.householdMembers.storedMembers = JSON.parse(storedHouseholdMembers)
    storedClass.driversHHMs.pendingAddedDrivers = JSON.parse(pendingAddedDrivers)
    storedClass.driversHHMs.pendingAddedHhms = JSON.parse(pendingAddedHhms)
    storedClass.driversHHMs.pendingRemovedDrivers = JSON.parse(pendingRemovedDrivers)
    storedClass.driversHHMs.pendingRemovedHhms = JSON.parse(pendingRemovedHhms)
    storedClass["platform"] = platform
    storedClass["env"] = userData?.env
    storedClass["systemId"] = userData?.systemId
    storedClass["renewalSystemId"] = userData?.renewalSystemId
    storedClass["renewalEffectiveDate"] = userData?.renewalEffectiveDate
    storedClass["policyStatusCd"] = userData?.policyStatusCd
    storedClass["accountRef"] = userData?.accountRef
    storedClass["zip"] = userData?.zip

    if (classType.includes("Address")) {
      storedClass.newResidence.pendingAddress = JSON.parse(pendingAddress)
    }
    else if (classType.includes("Vehicle")) {
      storedClass.vehicles.vehiclesOnPolicy.pendingNewVehicles = JSON.parse(pendingNewVehicles)
      storedClass.vehicles.vehiclesOnPolicy.pendingRemovedVehicles = JSON.parse(pendingRemovedVehicles)
    }

    // Sets the class back to local storage with updated data
    if (typeof (storedClass) != "string") {
      storageData.setItem(classType, JSON.stringify(storedClass))
    }
  }

  // Gets expiration date from effective date
  const getExpirationDate = (effDate) => {
    var effectiveDtCopy = new Date(effDate);
    effectiveDtCopy.setMinutes(effectiveDtCopy.getMinutes() + effectiveDtCopy.getTimezoneOffset());
    effectiveDtCopy.setMonth(effectiveDtCopy.getMonth() + 6);
    // Adjusting for the day (in case the original day is greater than the number of days in the new month)
    effectiveDtCopy.setDate(Math.min(effectiveDtCopy.getDate(), new Date(effectiveDtCopy.getFullYear(), effectiveDtCopy.getMonth() + 1, 0).getDate()));
    return `${effectiveDtCopy.getMonth() + 1}/${effectiveDtCopy.getDate()}/${effectiveDtCopy.getFullYear()}`;
  }

  return (
    <div style={{ position: "fixed", top: "50%", left: "50%" }}>
      <Spinner animation="border" />
    </div>
  );
};

export default LoadPolicyChangeInfo;
