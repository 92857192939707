import React from "react";
import { relationship } from "../StateObjects/Relationship";
// import { healthinsurance } from "../StateObjects/HealthInsurance";

class CurrentRelationship extends React.Component {
    constructor(props) {
        super(props);
        this.relationship = relationship
    }
    render() {
        return (
            <>
                <div className="row form-group form-group-sm align-items-center">
                    <div style={{ width: "unset" }} className="col-xxl-4 text-blue fw-bold mt-2">
                        Relationship to Insured:*
                    </div>
                    <div style={{ width: "50%" }} className="col-xxl-4">
                        <div className="btn-group bootstrap-select custom-selector">
                            <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                defaultValue={this.props.currRel}
                                onChange={(e) => { this.props.handleCallBack("currRel", e.target.value) }}>
                                {this.relationship.relationships.map(item => (
                                    <option key={item.id} value={item.relationship}>
                                        {item.relationship}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {/* if pip is 4-6 and the driver is a relative, ask health insurance question */}
                {[1, 2, 3, 4, 5, 6].includes(this.props.pipOption) && ["Spouse", "Child", "Parent", "Other Relative"].includes(this.props.currRel) && <>
                    <div className="col-xl-8 text-blue fw-bold">
                        Does this driver have health insurance?*
                    </div>
                    <div className="col-xl-4" onChange={(e) => this.props.handleCallBack("healthInsurance", parseInt(e.target.value))}>
                        <div className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input className="form-check-input" name="003" type={"radio"} value={1}
                                    defaultChecked={this.props.healthInsurance === 1}
                                />Yes
                            </label>
                        </div>
                        <div className="radio-inline form-check ">
                            <label className="form-check-label">
                                <input className="form-check-input" name="003" type={"radio"} value={0}
                                    defaultChecked={this.props.healthInsurance === 0}
                                />No
                            </label>
                        </div>
                    </div>
                    {this.props.healthInsurance === 1 && <>
                        <div className="row form-group form-group-sm align-items-center">
                            <div style={{ width: "unset" }} className="col-xxl-4 text-blue fw-bold mt-2">
                               Please confirm what type of health insurance this driver has:*
                            </div>
                            {/* <div style={{ width: "50%" }} className="col-xxl-4">
                                <div className="btn-group bootstrap-select custom-selector">
                                    <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                                        defaultValue={this.props.healthInsuranceType}
                                        onChange={(e) => { this.props.handleCallBack("healthInsuranceType", e.target.value) }}>
                                        {healthinsurance.healthinsurance.map(item => (
                                            <option key={item.id} value={item.relationship}>
                                                {item.relationship}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}
                        </div>
                    </>}
                </>}

            </>
        )
    }
}

export default CurrentRelationship