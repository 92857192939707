import { useState } from "react"
import Images from "../../assets/images/Images"
import HeaderQS from "../../components/QuickService/HeaderQS"
import { Spinner } from 'react-bootstrap';
import { sendIdCardEmail } from "../../services/Api"

const QSIDCards = (props) => {
    const GetIdCardData = async () => {
        setIdCardData({ result: props.user.userData.idCardData, complete: true })
    }
    const { policyNumber, systemId, env, emailAddress, firstName } = props.user.userData
    const [idCardData, setIdCardData] = useState({ result: [], complete: false })
    const [emailConfirm, setEmailConfirm] = useState("")

    if (!idCardData.complete) {
        GetIdCardData()
    }

    const OnClick = async (id, year, make, model) => {
        var bodyParams = {
            "{{FirstName}}":firstName,
            "{{Year}}": (new Date()).getFullYear(),
            "{{YearMakeModel}}": `${year} ${make} ${model}`
        }
        const response = await sendIdCardEmail((systemId) ? systemId : "", policyNumber, [id], emailAddress, bodyParams, env)
        if (response.Result == "Success") {
            setTimeout(() => {
                setEmailConfirm("")
            }, 2000)
            setEmailConfirm("Success")
        } else {
            setTimeout(() => {
                setEmailConfirm("")
            }, 2000)
            setEmailConfirm("Fail")
        }
    }

    return (
        <>
            <HeaderQS title = {"REPLACEMENT ID CARDS"} />
            <div className="d-flex align-content-center justify-content-center">
                <div className="m-3 p-2 rounded-3  container" style={{ width: "1000px", border: "1px solid gray", borderTop: "10px solid #366898" }}>
                    <div>
                        <h3 className="text-center section-title">
                            EMAIL MY FINANCIAL RESPONSIBILITY ID CARDS
                        </h3>
                    </div>
                    <div className="my-3">
                        <small >Please select the vehicle(s) for which you would like to receive a replacement ID card.</small>
                    </div>
                    {emailConfirm == "Success" &&
                        <div className="text-center text-success small">
                            <strong >*** Your ID cards have been delivered to your email address. Check your inbox. ***</strong>
                        </div>
                    }
                    {
                        emailConfirm == "Fail" &&
                        <div className="text-center small">
                            <div ><strong>We do not have a valid email address on file. <a href="https://myaccount.cure.com/my-account/register">Click here to create an account</a>.</strong></div>
                        </div>
                    }
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th colSpan="4" className="text-center bg-secondary text-light">SELECT WHICH ID CARDS ARE NEEDED</th>
                            </tr>
                        </thead>

                        <tbody>
                            {idCardData.complete ?
                                idCardData.result.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.modelyr}</td>
                                            <td>{value.model}</td>
                                            <td>{value.manufacturer}</td>
                                            <td className="text-right">
                                                <a onClick={() => { OnClick(value.id, value.modelyr, value.model, value.manufacturer) }} >
                                                    <span><img alt="Statement" src={Images.roundyIcon} /></span> EMAIL IT TO ME...</a></td>
                                        </tr>
                                    )
                                })
                                :
                                <>
                                    <tr >
                                        <td colSpan={4} align='center'>
                                            <Spinner animation="border" />
                                        </td>
                                    </tr>
                                </>
                            }

                        </tbody></table>
                </div>
            </div>
        </>
    )
}

export default QSIDCards