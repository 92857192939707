import { useContext } from "react"
import { AddressChangeContext } from "../AddressChangeContext"

const AddressSummary_Changes = () => {
    const context = useContext(AddressChangeContext)
    var newResidenceData = context?.addressChangeClass?.newResidence
    var storedDrivers = context?.addressChangeClass?.driversHHMs?.drivers?.storedDrivers
    var newDrivers = context?.addressChangeClass?.driversHHMs?.drivers?.newDrivers
    var storedMembers = context?.addressChangeClass?.driversHHMs?.householdMembers?.storedMembers
    var newMembers = context?.addressChangeClass?.driversHHMs?.householdMembers?.newMembers
    var allDrivers = [...storedDrivers, ...newDrivers]
    var allHHMs = [...storedMembers, ...newMembers]

    return (
        <>
            <div>
                <h3 className="text-center text-primary fw-bold">SUMMARY OF CHANGES</h3>
                <p className="small">
                    All policy changes are subject to Underwriting review and approval.
                    Please review your changes and click Confirm below.
                </p>
            </div>
            <div className="row text-center">
                <div className="col-xs-12 col-sm-4">
                    <strong className="text-primary">NEW RESIDENCE</strong>
                    <hr className="less-margin" />
                    <div className="small">

                        {/* MI Users */}
                        {newResidenceData !== undefined &&
                            <>
                                <p className="text-primary mb-1">Mailing Address</p>
                                {`${newResidenceData?.mailingAddress + " " + newResidenceData?.mailingCity + " " + newResidenceData?.mailingState + ", " + newResidenceData?.mailingZip}`}
                            </>
                        }
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 summaryDriver">
                    <strong className="text-primary">DRIVER(S)</strong>
                    <hr className="less-margin" />
                    {allDrivers?.length !== 0 ?
                        (() => {
                            const driversWithChanges = allDrivers.filter(driver => "driverStatus" in driver && driver?.driverStatus !== "");

                            if (driversWithChanges.length > 0) {
                                return (
                                    <>
                                        {driversWithChanges.map((driver, id) => (
                                            <div key={id} className="small">
                                                <strong className="text-uppercase text-primary">{driver?.fullName}</strong>
                                                <span className="fst-italic"> - {driver?.driverStatus}</span>
                                            </div>
                                        ))}
                                    </>
                                );
                            } else {
                                return (
                                    <div className="small fst-italic">
                                        No Changes
                                    </div>
                                );
                            }
                        })()
                        :
                        <div className="small fst-italic">
                            No Changes
                        </div>
                    }
                </div>
                <div className="col-xs-12 col-sm-4">
                    <strong className="text-primary">HOUSEHOLD MEMBERS</strong>
                    <hr className="less-margin" />
                    {allHHMs?.length !== 0 ?
                        (() => {
                            const membersWithChanges = allHHMs.filter(member => "memberStatus" in member && member?.memberStatus !== "");

                            if (membersWithChanges.length > 0) {
                                return (
                                    <>
                                        {membersWithChanges.map((member, id) => (
                                            <div key={id} className="small">
                                                <strong className="text-uppercase text-primary">{member?.fullName}</strong>
                                                <span className="fst-italic"> - {member?.memberStatus}</span>
                                            </div>
                                        ))}
                                    </>
                                );
                            } else {
                                return (
                                    <div className="small fst-italic">
                                        No Changes
                                    </div>
                                );
                            }
                        })()
                        :
                        <div className="small fst-italic">
                            No Changes
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AddressSummary_Changes