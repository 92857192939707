import Menu from "../components/Menu";
import PaymentHistSect from "../components/PaymentHistSect";
import RoundyContainer from "../components/RoundyContainer";
import CurrentBillSect from "../components/CurrentBillSect";
import { getAllPolicyDocs, GetArchive, getDocuments, getInstallmentBill, getPaperlessBillHistory, getPaymentHistory, getPaymentPdf } from "../services/Api";
import ArchiveBillHistorySect from "../components/ArchiveBillHistorySect";
import PolicyDocumentsSect from "../components/PolicyDocumentsSect";
import { useLocation } from "react-router-dom";

const Content = (props) => {
  const { paymentData } = props.payment
  return (
    <>
      <div className="row text-muted">
        <div className="col-12 col-md-7">
          <ArchiveBillHistorySect paymentData={paymentData} userData={props.user}/>
          <PolicyDocumentsSect paymentData={paymentData} userData={props.user} />
        </div>
        <div className="col-12 col-md-5" >
          <CurrentBillSect paymentData={paymentData} viewPolicy />

        </div>
      </div>
    </>
  )
}

const DocumentsVault = (props) => {
  console.log("Payment History Screen:", props)
  const { userData, setUserData } = props.user

  const { paymentData, setPaymentData } = props.payment


  const setupDocuments = async () => {
    //Table data
    var documents = await getDocuments(userData.policyNumber, userData.systemId, userData.env)
    documents = (!documents.error) ? documents.result : []
    var archive = await GetArchive(userData.policyNumber, userData.systemId, userData.accountRef, userData.env)
    archive = (!archive.error) ? archive.result : []

    var paymentHist = await getPaymentHistory(userData.policyNumber, userData.env)

    //Pdf download
    var paymentPdf = await getInstallmentBill(userData.policyNumber, userData.systemId, userData.env)

    setPaymentData({ ...paymentData, paymentHist, paymentPdf: paymentPdf.data, paymentDoc: paymentPdf.result, documents, archive, complete: true })
  }
  if (!paymentData.complete || !("documents" in paymentData)) {
    setupDocuments()
  }

  const content = () => { return (<Content payment={props.payment} user={userData} />) }
  return (
    <>
      <Menu Title={"PAYMENT HISTORY"} FirstName={userData.firstName} userData={userData}/>
      <RoundyContainer params={props} Content={content} />
    </>
  );
};


export default DocumentsVault;