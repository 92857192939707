import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { VehicleChangeContext } from "./VehicleChangeContext";
import VehiclePageValidation from "./VehiclePageValidation";
import { storageData } from "../../services/Services";
import { submitChangeRequest } from "../../services/Api";

const VehicleChangeBtnNavigation = () => {
    const context = useContext(VehicleChangeContext)
    const navigate = useNavigate()
    var screen = window.location.pathname.split("/").pop()
    const [confirmationLoad, setConfirmationLoad] = useState(false)
    const attestRef = useRef(false)

    // All vehicles
    var combinedVehicles = [...context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles, ...context?.vehicleChangeClass?.vehicles?.vehiclesOnPolicy?.newVehicles]
    combinedVehicles = combinedVehicles.filter((vehicle, id) => vehicle?.type != "remove")
    const [allVehicles, setAllVehicles] = useState([...combinedVehicles])

    const navigateBack = (screen) => {
        var screenObj = {
            "vehicles": "/home",
            "driver-assignment": "/update-vehicles/vehicles",
            "summary": (allVehicles?.length == 0) ? "/update-vehicles/vehicles" : "/update-vehicles/driver-assignment",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    const navigateToNext = (screen) => {
        var screenObj = {
            "vehicles": (allVehicles?.length == 0) ? "/update-vehicles/summary" : "/update-vehicles/driver-assignment",
            "driver-assignment": "/update-vehicles/summary",
            "summary": "/update-vehicles/confirmation",
            "confirmation": "/home"
        }
        return screenObj[screen]
    }

    const nextButtonNavigate = async () => {
        if (!context.cancelPolicyAddVeh) { // Cancel policy skips validation when moving on to next page

            // Validation check
            var { title, message, valid } = VehiclePageValidation({ screen, vehicleChangeClass: context.vehicleChangeClass })
            if (!valid) {
                context.setValidationMsgToggle(title, message)
                return
            }
        }
        if (screen == "vehicles") {
            if (context.vehicleChangeClass.vehicles.keptOvernightResponse == "No") {
                updateGaragedVehicles(context)
            }
        }
        if (screen == "summary") {
            if (!(attestRef.current.checked) && context?.userData?.state == "Michigan") {
                context.setValidationMsgToggle("Oops!", "Please attest to confirm all answers to the questions listed in the change are true and accurate to the best of your knowledge.")
                return
            }
        }
        storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))

        // Sends change class to backend
        if (screen == "summary") {
            setConfirmationLoad(true)

            var req = {
                "requestType": "Vehicle",
                "classRequest": storageData.getItem("VehicleChangeClass")
            }
            // Api call to send data
            var resp = await submitChangeRequest(req)
            var changeId = resp?.data
            storageData.setItem("VehicleChangeID", changeId)
        }
        navigate(navigateToNext(screen))
    }

    // Updates garage location for garaged vehicles if there is no location set for the garaged vehicle (i.e. garagedStreetAddress = undefined and garaged)
    const updateGaragedVehicles = (context) => {
        const getUpdatedVehicle = (vehicle, location) => ({
            ...vehicle,
            garagedStreetAddress: vehicle?.garaged && vehicle?.garagedStreetAddress === undefined ? location?.garagedStreetAddress : vehicle?.garagedStreetAddress,
            garagedCity: vehicle?.garaged && vehicle?.garagedCity === undefined ? location?.garagedCity : vehicle?.garagedCity,
            garagedState: vehicle?.garaged && vehicle?.garagedState === undefined ? location?.garagedState : vehicle?.garagedState,
            garagedZipCode: vehicle?.garaged && vehicle?.garagedZipCode === undefined ? location?.garagedZipCode : vehicle?.garagedZipCode,
        });

        const storedVehicles = context.vehicleChangeClass.vehicles.vehiclesOnPolicy?.storedVehicles;
        const newVehicles = context.vehicleChangeClass.vehicles.vehiclesOnPolicy?.newVehicles;

        const vehLocationStored = storedVehicles?.find((veh) => veh?.garaged && veh?.garagedStreetAddress !== undefined);
        const vehLocationNew = newVehicles?.find((veh) => veh?.garaged && veh?.garagedStreetAddress !== undefined);
        const vehLocation = vehLocationStored || vehLocationNew;

        const updatedStoredVeh = storedVehicles.map((vehicle) => getUpdatedVehicle(vehicle, vehLocation));
        const updatedNewVeh = newVehicles.map((vehicle) => getUpdatedVehicle(vehicle, vehLocation));

        context.vehicleChangeClass.vehicles.vehiclesOnPolicy.storedVehicles = updatedStoredVeh
        context.vehicleChangeClass.vehicles.vehiclesOnPolicy.newVehicles = updatedNewVeh
    }

    return (
        <>
            <hr className="my-2" />
            <div className="mt-2 mb-3">
                {confirmationLoad &&
                    <div className="text-center">
                        Confirming Changes... &nbsp;
                        <Spinner animation="border" />
                    </div>
                }
                {!confirmationLoad &&
                    <div>
                        {/* Attestation - only on SUMMARY page (MICHIGAN) */}
                        {screen == "summary" && context?.userData?.state == "Michigan" &&
                            <>
                                <label className="mb-4" style={checkBoxText}>
                                    <input type="checkbox" ref={attestRef} />
                                    <span className="ms-2">I hereby certify that the answers to the questions listed in the change are true and accurate to the best of my knowledge.*</span>
                                    <br />
                                </label>
                            </>
                        }

                        {/* Back Button */}
                        {screen !== "vehicles" &&
                            <>
                                <button type="button" className="btn btn-default editCancelBtn" onClick={() => { navigate(navigateBack(screen)) }}>
                                    BACK
                                </button>
                            </>
                        }
                        {/* Continue Button */}
                        <button type="submit" className="btn btn-primary-grad right pull-right editCancelBtn" onClick={() => { nextButtonNavigate() }}>
                            NEXT
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

const checkBoxText = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '13px'
}

export default VehicleChangeBtnNavigation