import { useContext } from "react";
import MichiganNewAddress from "./MichiganNewAddress";
import NewResidenceSect from "./NewResidenceSect";
import PendingAddress from "./PendingAddress";
import PendingAddressNotice from "./PendingAddressNotice";
import { storageData } from "services/Services";
import { AddressChangeContext } from "../AddressChangeContext";
import AddressChangeBtnNavigation from "../AddressChangeBtnNavigation";
import ValidationModal from "components/ValidationModal";
import NewResidence_NJPA from "./NewResidence_NJPA";
import CurrentResidenceSect from "./CurrentResidenceSect";

const NewAddressMain = () => {
    const context = useContext(AddressChangeContext)
    var showUpdate = false;

    // Pending changed address
    var pendingData = storageData.getItem("PendingAddress");
    if (pendingData && pendingData !== '[]') {
        pendingData = JSON.parse(pendingData);
        // NJ/PA
        if (pendingData[0].StreetAddress !== "" && pendingData[0].StreetAddress !== null) {
            pendingData = pendingData[0]
            showUpdate = true;
        }
        // MI
        else if (pendingData[0].MailingAddress !== "" && pendingData[0].Mailiing !== null) {
            pendingData = pendingData[0]
            showUpdate = true;
        }
    }

    return (
        <>
            <div className="row text-muted">
                {/* Pending Address Notice */}
                {showUpdate &&
                    <div>
                        <PendingAddressNotice state={context?.userData.state} />
                    </div>
                }
                {(context?.userData.state === "New Jersey" || context?.userData.state === "Pennsylvania") &&
                    <div>
                        <div className="col-12 d-flex">
                            <div className="col-5">
                                <CurrentResidenceSect />
                            </div>

                            {/* Pending Address */}
                            {showUpdate &&
                                <div className="col-7">
                                    <PendingAddress
                                        pendingAddress={pendingData}
                                        userState={context?.userData.state} />
                                </div>
                            }
                        </div>
                        <div className="col-12">
                            <NewResidence_NJPA />
                        </div>
                    </div>
                }
                {context?.userData.state === "Michigan" &&
                    <div>
                        {/* Pending Address */}
                        {showUpdate &&
                            <div className="col-12">
                                <PendingAddress
                                    pendingAddress={pendingData}
                                    userState={context?.userData.state} />
                            </div>
                        }
                        <div className="col-12">
                            <MichiganNewAddress />
                        </div>
                    </div>
                }

                <AddressChangeBtnNavigation />

                <ValidationModal
                    title={context.validationTitle}
                    show={context.showModal}
                    handleClose={() => context.setShowModal(false)}
                    validationMsg={context.validationMsg}
                />
            </div>
        </>
    )
}

export default NewAddressMain