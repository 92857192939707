import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Images from '../../assets/images/Images';
import { enrollAutoPay, getCustomerId, getPaymentMethod } from '../../services/Api';
import { Link, Navigate } from "react-router-dom";


const OneIncAutoPay = (props) => {
    console.log(props)
    const { policyNumber, firstName, lastName, env ,emailAddress} = props.user.userData
    /**
     *  {
                {"AuthenticationKey", If(APIInsuranceNow.IsProd(), "f8e7ef9c-e029-44f9-9e29-41e83cc50a7e", "b0cffc04-1846-4ddb-bb60-46d6a0bd53a9")},
                {"ProfileDetails", New Dictionary(Of String, Object) From {
                    {"CustomerId", CustomerID},
                    {"CustomerName", CustomerName},
                    {"Email", Email}
                }},
                {"PolicyRefNumber", Policy},
                {"InstallmentPlanTotalAmount", If(CInt(AmountPerInstallment) = 0, 0.01, CDbl(AmountPerInstallment) * 6)},
                {"NumberOfInstallments", 6},
                {"InstallmentPlanEffectiveDate", EffectiveDate.ToString("yyyy-MM-dd")},
                {"InstallmentFrequency", WithdrawalFrequency.Monthly},
                {"InstallmentPlanEngineType", AutoPayEngineType.Carrier},
                {"ExternalSchedulerEndpoint", "http://oneinc.cure.com/api/Autopay/Postback"},
                {"InstallmentPlanPaymentMethodDetails", New Dictionary(Of String, Object) From {
                    {"TokenId", PayMethodTokenId}
                }},
                {"FeeContext", 1}
            }
     */
            getCustomerId(props.user.userData.policyNumber, `${props.user.userData.firstName} ${props.user.userData.lastName}`, props.user.userData.env)
    var x = {
        ProfileDetails: {
            Email: emailAddress,
            CustomerName: `${firstName} ${lastName}`,
            CustomerId: '',
        },
        PolicyRefNumber: policyNumber,
        InstallmentPlanTotalAmount: '',
        NumberOfInstallments: 6,
        InstallmentPlanEffectiveDate: FormatDate(new Date()),
        InstallmentFrequency:1,
        InstallmentPlanEngineType:1,
        ExternalSchedulerEndpoint:"http://oneinc.cure.com/api/Autopay/Postback",
        InstallmentPlanPaymentMethodDetails:{
            TokenId:''
        },
        FeeContext:1,
        }
    // enrollAutoPay()
    getCustomerId(policyNumber, `${firstName} ${lastName}`, env)
    const [paymentMethod, setPaymentMethods] = useState([])
    const [paymentForm, setPaymentForm] = useState()
    const [show, setShow] = useState(true)

    // const handleClose = () => setShow(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleClick = (i) => setPaymentForm(i)

    const getPaymentMethodData = async () => {
        const responseCustId = await getCustomerId(policyNumber, `${firstName} ${lastName}`, env)
        if (responseCustId.ResponseCode == "Success") {
            const responsePayment = await getPaymentMethod(responseCustId.CustomerId, env)
            if (responsePayment.ResponseCode == "Success") {
                setPaymentMethods(responsePayment.PaymentMethods)
            }
        }
    }
    if (paymentMethod.length == 0) {
        getPaymentMethodData()
    }

    return (
        <>
            {
                !show &&
                <Navigate to="/" />
            }
            <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>

            <Modal className='' show={show} >
                <Modal.Header className='border-top border-5 border-bottom-0 border-primary rounded flex' >
                    <div>
                        <img className="img-fluid" alt="Cure Logo" src={Images.portalOne.cureLogo} style={{ width: 150 }} />
                    </div>
                    <div>
                        <Button variant="secondary" onClick={handleClose}>
                            CLOSE
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body className='p-5'>
                    <div >
                        <h5 className="text-primary">
                            SELECT PAYMENT METHOD
                        </h5 >
                    </div>
                    <div className='p-4 d-flex flex-column'>
                        {/* <div className=''> */}
                            {paymentMethod.map((value, index) => {
                                var img, description
                                if (value.PaymentMethodType == "Eft") {
                                    img = Images.portalOne.paymentMethod[value.PaymentMethodType]
                                    description = `${value.BankName} ${value.BankAccountType} ending in ${value.LastFour}`
                                } else {
                                    img = Images.portalOne.paymentMethod[value.CreditCardType]
                                    description = `${value.CreditCardType} ending in ${value.LastFour} Expires ${value.CardExpirationMonth}/${value.CardExpirationYear}`
                                }
                                return (
                                    <form key={index} className='align-content-center border p-2 m-1 font-monsterrat'>
                                        < div className='d-flex' onClick={() => handleClick(index)}>
                                            <div className='d-flex justify-content-center mx-2' >
                                                <input onChange={() => handleClick(index)} type={'radio'} value={value.ProcessOneToken} checked={index == paymentForm} />
                                            </div>
                                            <div className='me-3 d-flex justify-content-center'>
                                                <i alt="Cure Logo" style={{ width: 42, backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
                                            </div>
                                            <div className='d-flex justify-content-center' >
                                                <div className='me-3 d-flex text-align-center'>{description}</div>
                                            </div>
                                        </ div >
                                    </form>
                                )
                            })}
                        {/* </div> */}
                        <Link className="container-fluid" to="/oneinc?type=manage-payment">
                            <div className='d-flex flex-row align-items-center justify-items-center p-2 btn btn-secondary'>
                                <div className='justify-content-center align-content-center text-center text-muted px-2'>
                                    <h4>+</h4>
                                </div>
                                <div className='justify-content-center align-content-center'>
                                    ADD OR MANAGE PAYMENT METHODS
                                </div>
                            </div>
                        </Link>
                        <div className='d-flex justify-content-center'>
                            <button type='button' disabled={paymentForm == null} className='btn btn-primary container-fluid' onClick={() => { }}>
                                ENROLL IN AUTOPAY
                            </button>
                        </div>
                        <div className='d-flex justify-content-center pt-2'>

                            <button onClick={handleClose} type='button' className='btn btn-light border border-dark'>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const FormatDate = (tempDate) => {
    var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
    var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
    var dateYear = `${tempDate.getFullYear()}`
    const date = `${dateMonth}/${dateDay}/${dateYear}`
    return date
}


export default OneIncAutoPay