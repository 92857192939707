import React from 'react'
import VehicleNote from './2_Confirm/VehicleNote/VehicleNote'
import VehicleBtnNav from './2_Confirm/VehicleBtnNav/VehicleBtnNav'
import { NewVehicleContextComp } from './AddVehicleContext/NewVehicleContext'
import VehicleConfirm_Main from './2_Confirm/ConfirmBody/VehicleConfirm_Main'


const ConfirmVehicleMain = () => {
    return (
        <>
            <NewVehicleContextComp>
                <span className='px-md-5'>
                    <VehicleConfirm_Main /> 
                </span>
                <hr />
                <VehicleNote />
                <hr />
                <VehicleBtnNav />
            </NewVehicleContextComp>
        </>
    )
}

export default ConfirmVehicleMain