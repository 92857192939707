import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../css/OptInModal.css';

const TermsConditionsModal = (props) => {


    return (
        <>
            <Modal
                dialogClassName='termsModal'
                show={props.showTerms}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            >

                <Modal.Header style={{ backgroundColor: "#366898" }}>
                    <Modal.Title className='text-light'>
                        <h4 style={{ letterSpacing: '-1px', fontWeight: 'bold', fontSize: '20px' }}>TERMS AND CONDITIONS</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='small'>
                    By clicking OKAY, I am providing my electronic signature expressly authorizing CURE to contact me by email, text,
                    or phone (including an automatic dialing system or artificial/prerecorded voice) at the home or mobile phone number provided
                    within the application for information and documents related to my policy. Messaging and data rates may apply. I
                    understand I am not required to sign or agree to this as a condition to apply for a policy with CURE.

                </Modal.Body>
                <div className='roundyUp' />

                <Modal.Footer>
                    <div>
                        <div>
                            <Button className='btn btn-primary-grad' onClick={props.handleClose}>
                                CLOSE
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default TermsConditionsModal
