import { useEffect, useState } from "react"
import StateDropdown from "../../../RenewalQuestionnaireComp/DriversOHM/StateDropdown"
import { storageData } from "../../../../services/Services"

const VehicleMI_Garaged = ({ context, updateVehicleLocation, type, vehicle, id }) => {
    const [vehicleLocation, setVehicleLocation] = useState({})
    const [displayGarageFields, setDisplayGarageFields] = useState(false)
    var storedVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.storedVehicles
    var newVehicles = context.addressChangeClass.vehicles.vehiclesOnPolicy?.newVehicles
    var allVehicles = [...storedVehicles, ...newVehicles]

    // Sets the location that has been filled out already
    useEffect(() => {
        var garageCount = 0 // Counter for the first vehicle being selected to display text fields of address

        for (var i = 0; i < allVehicles.length; i++) {
            // Increments when all vehicles are not garaged
            if (!(allVehicles[i]?.garaged)) {
                garageCount++
            }
            // Saves the location for an existing location already saved
            else if (allVehicles[i]?.garaged && allVehicles[i]?.garagedStreetAddress != "") {
                var location = {
                    "garagedStreetAddress": allVehicles[i]?.garagedStreetAddress,
                    "garagedCity": allVehicles[i]?.garagedCity,
                    "garagedState": allVehicles[i]?.garagedState,
                    "garagedZipCode": allVehicles[i].garagedZipCode
                }
                setVehicleLocation({ ...location })
            }
        }
        // Checks if address fields should be displayed on a second vehicle selection
        if ("showGarageFields" in vehicle && vehicle?.showGarageFields == "No") {
            setDisplayGarageFields(false)
        }
        // Displays only the address fields if this is the first vehicle being selected
        else if (garageCount + 1 == allVehicles.length || (vehicle?.garaged && vehicle?.garagedStreetAddress != "")) {
            setDisplayGarageFields(true)
        }
    }, [])

    // Same overnight location as another vehicle
    const setSameLocation = (vehicle, value) => {
        // Sets vehicle location to one that has been filled out
        if (value == "Yes") {
            vehicle["garaged"] = true
            vehicle["garagedStreetAddress"] = vehicleLocation?.garagedStreetAddress
            vehicle["garagedCity"] = vehicleLocation?.garagedCity
            vehicle["garagedState"] = vehicleLocation?.garagedState
            vehicle["garagedZipCode"] = vehicleLocation?.garagedZipCode
            vehicle["showGarageFields"] = "No"
            vehicle["sameAddress"] = value
            setDisplayGarageFields(false)
        }
        // Displays fields for new address
        else {
            vehicle["garaged"] = true
            vehicle["garagedStreetAddress"] = ""
            vehicle["garagedCity"] = ""
            vehicle["garagedState"] = "MI"
            vehicle["garagedZipCode"] = ""
            vehicle["showGarageFields"] = "Yes"
            vehicle["sameAddress"] = value
            setDisplayGarageFields(true)
        }

        var vehicleClassCopy = context.addressChangeClass
        // Stored vehicle
        if (type == "stored") {
            var storedVehiclesCopy = vehicleClassCopy.vehicles.vehiclesOnPolicy?.storedVehicles
            storedVehiclesCopy[id] = vehicle
            vehicleClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = storedVehiclesCopy
        }
        // New vehicle
        else {
            var newVehiclesCopy = vehicleClassCopy.vehicles.vehiclesOnPolicy?.newVehicles
            newVehiclesCopy[id] = vehicle
            vehicleClassCopy.vehicles.vehiclesOnPolicy.newVehicles = newVehiclesCopy
        }
        context.setAddressChangeClass({ ...vehicleClassCopy })
        storageData.setItem("AddressChangeClass", JSON.stringify(context.addressChangeClass))

    }

    return (
        <>
            {/* Garaged Location */}
            <div className="form-group form-group-sm fw-bold text-primary" style={{ fontSize: 'addressChangeClass' in context ? '1.1em' : '' }}>
                Where will this vehicle be garaged overnight?*
            </div>

            {/* If one location has been fulfilled */}
            {(!displayGarageFields || ((type == "stored") ? "sameAddress" in storedVehicles[id] : "sameAddress" in newVehicles[id])) &&
                <div className="form-group form-group-sm text-dark">
                    <div className="radio-inline form-check form-check-inline overnightAddressOptions">
                        <label className="form-check-label">
                            <input type={"radio"} className="form-check-input"
                                name={"garagedOvernightYesNo" + ("vehicleMake" in vehicle) ? vehicle?.vehicleVIN : vehicle?.VIN} value={"Yes"}
                                onChange={(e) => setSameLocation(vehicle, e.target.value)}
                                checked={(("sameAddress" in vehicle) ? vehicle?.sameAddress : "") == "Yes"}
                            />This vehicle will be garaged overnight at the same address for the other vehicle selected.
                        </label>
                    </div>
                    <br />
                    <div className="radio-inline form-check form-check-inline overnightAddressOptions">
                        <label className="form-check-label">
                            <input type={"radio"} className="form-check-input"
                                name={"garagedOvernightYesNo" + ("vehicleMake" in vehicle) ? vehicle?.vehicleVIN : vehicle?.VIN} value={"No"}
                                onChange={(e) => setSameLocation(vehicle, e.target.value)}
                                checked={(("sameAddress" in vehicle) ? vehicle?.sameAddress : "") == "No"}
                            />This vehicle will be garaged overnight at a different address than the other vehicle selected.
                        </label>
                    </div>
                </div>
            }

            {/* Overnight address fields */}
            {(displayGarageFields || vehicle?.showGarageFields == "Yes") &&
                <>
                    {/* Address */}
                    <div className="form-group-spacer mt-2 col-md-8">
                        <input type={"text"} className="form-control"
                            placeholder="Street Address" maxLength={60}
                            value={vehicle?.garagedStreetAddress ? vehicle?.garagedStreetAddress : ""}
                            onChange={(e) => {
                                updateVehicleLocation("garagedStreetAddress", type, vehicle, id, e.target.value);
                            }}
                        />
                    </div>
                    {/* City */}
                    <div className="form-group-spacer mt-2 col-md-8">
                        <input type={"text"} className="form-control"
                            placeholder="City" maxLength={64}
                            value={vehicle?.garagedCity ? vehicle?.garagedCity : ""}
                            onChange={(e) => {
                                updateVehicleLocation("garagedCity", type, vehicle, id, e.target.value)
                            }}
                        />
                    </div>
                    <div className="row form-group">
                        {/* State */}
                        <div className="col-md-4 mt-2">
                            <select className="btn btn-default btn-group dropdown-toggle filter-option"
                                value={vehicle?.garagedState ? vehicle?.garagedState : ""}
                                onChange={(e) => updateVehicleLocation("garagedState", type, vehicle, id, e.target.value)}
                            >
                                {StateDropdown()}
                            </select>
                        </div>
                        {/* Zip */}
                        <div className="col-md-4 mt-2 ms-2 garagedOvernightZip">
                            <input type={"number"} className="form-control"
                                placeholder="Zip Code" maxLength={5}
                                value={vehicle?.garagedZipCode ? vehicle?.garagedZipCode : ""}
                                onChange={(e) => {
                                    updateVehicleLocation("garagedZipCode", type, vehicle, id, e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default VehicleMI_Garaged