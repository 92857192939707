import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const DatePick = (props) => {
    var displayCalendar = props?.displayCalendar ? props?.displayCalendar : "top-start"
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

    // Formats date
    const dateFormat = (date) => {
        var saveFormattedDate
        if (date != "" && date !== null) {
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var year = date.getFullYear();

            // Appends 0 to the front of a single digit
            if (month > 0 && month < 10) {
                month = "0" + month.toString();
            }
            if (day < 10) {
                day = "0" + day.toString();
            }

            // Sets year to current year if input exceeds range
            if (year > new Date().getFullYear()) {
                year = new Date().getFullYear();
            }

            saveFormattedDate = month + '/' + day + '/' + year
            props.setDateSubmit(new Date(saveFormattedDate));
        }
        else {
            saveFormattedDate = "";
            props.setDateSubmit("")
        }
        props.storeValue(props.nameValue, saveFormattedDate);
    }

    return (
        <>
            <ReactDatePicker
                popperPlacement={displayCalendar}
                popperModifiers={[
                    {
                        name: "offset"
                    },
                    {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                        },
                    },
                ]}
                value={props.dateSubmit}
                type={"text"}
                autoComplete="off"
                name="DateChanged"
                placeholderText="MM/DD/YYYY"
                className="fw-light col-sm-12 btn btn-default btn-group"
                isClearable
                selected={props.dateSubmit !== "" ? new Date(props.dateSubmit) : new Date()}
                customInput={
                    <MaskedInput
                        pipe={autoCorrectedDatePipe}
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions={true}
                        guide={true}
                        inputMode='numeric'
                    />
                }
                onChange={(text) => {
                    dateFormat(text);
                }}
            />
        </>
    )
}

export default DatePick