export default {
    renewalQuestionnaireID: "",
    policyNumber: "",
    effectiveDate: "",
    expirationDate: "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    policyState: "",
    currentResidence: {
        response: "",
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        state: "",
        zip: "",
        newResidence: {
            streetAddress1: "",
            streetAddress2: "",
            city: "",
            state: "",
            zip: "",
        }
    },
    household: {
        drivers: {
            storedDrivers: [],
            newDrivers: []
        },
        householdMembers: {
            storedHouseholdMembers: [],
            newHouseholdMembers: []
        },
    },
    additionalInfo: {
        conditions: {
            suspended: {
                response: "",
                drivers: []
            },
            seizures: {
                response: "",
                drivers: []
            },
            narcolepsy: {
                response: "",
                drivers: []
            },
            syncope: {
                response: "",
                drivers: []
            },
            blindness: {
                response: "",
                drivers: []
            },
            impairments: {
                response: "",
                drivers: [],
                explanation: []
            }
        }
    },
    vehicles: {
        vehiclesOnPolicy: [],
        businessPurposeResponse: "",
        businessPurposeExplanation: "",
        keptOvernightResponse: "",
        keptOvernightLocation: ""
    },
    agreements: {
        conductBusinessElectronicallyTermsCondition: "",
        paperlessBillingResponse: true,
        paperlessBillingEmail: "",
        termsAndConditionsRQ: "",
        fullName: "",
        date: ""
    }
}