import React, { useContext } from 'react';
import { AddVehicleContext } from '../AddVehicleContext/AddVehicleContext';
import { storageData } from 'services/Services';

const IDCardSection = () => {
    const addVehicleContext = useContext(AddVehicleContext);

    const downloadIDCard = () => {
        var responseData = storageData.getItem("responseData")
        if (responseData) {
            responseData = JSON.parse(responseData);
            const fileName = `${addVehicleContext.userData?.policyNumber} - ID Card.pdf`;

            const pdf = `data:application/octet-stream;base64,${responseData?.idCardPdfBytes}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = pdf;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        else {
            console.error("ID Card PDF data not available");
            // Optionally, you can show an error message to the user here
        }
    }

    return (
        <div className='row justify-content-center my-3' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <div className='col-12 text-center'>
                <h4 className='fw-bold text-primary' style={{ display: 'flex' }}>ID Card</h4>
                <p className='fw-bold m-0' style={{ display: 'flex' }}>
                    <a className='text-black fw-bold text-decoration-underline' onClick={downloadIDCard}>
                        Click Here to Download
                    </a>
                </p>
            </div>
        </div>
    );
};

export default IDCardSection;