import { storageData } from "services/Services"

const VehicleRegistrantList = () => {
    let options = []
    try {
        const dropdownOptions = JSON.parse(storageData.getItem('DropdownOptions'))
        const registrantOptions = dropdownOptions.rows.filter(row => row.QuestionID === "60")

        if (registrantOptions.length > 0) {
            options = [
                <option key="default" disabled value="">Select from the following...</option>,
                ...registrantOptions.map(answer => (
                    <option key={answer.AnswerID} value={answer.AnswerText}>
                        {answer.AnswerText === "None of the Above" ? "Other" : answer.AnswerText}
                    </option>
                ))
            ]
        }
    } catch (error) {
        console.error(error)
        options = [<option key="default" value="">Select from the following...</option>]
    }

    return <>{options}</>
}

export default VehicleRegistrantList