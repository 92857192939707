const ValidationAdditionalInfo = (props) => {
    var check = props.sessionData.additionalInfo.conditions
    if (check.suspended.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have been suspended in the past 12 months.")
        return false
    }
    else if ((check.suspended.response == "Yes" || check.suspended.response == "Unknown") && check.suspended.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have been suspended within the past 12 months.")
        return false
    }
    // Seizures
    else if (check.seizures.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have experienced Seizure Disorders in the past 36 months.")
        return false
    }
    else if ((check.seizures.response == "Yes" || check.seizures.response == "Unknown") && check.seizures.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have experienced Seizure Disorders within the past 36 months.")
        return false
    }
    // Narcolepsy
    else if (check.narcolepsy.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have experienced Narcolepsy in the past 36 months.")
        return false
    }
    else if ((check.narcolepsy.response == "Yes" || check.narcolepsy.response == "Unknown") && check.narcolepsy.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have experienced Narcolepsy within the past 36 months.")
        return false
    }
    // Syncope
    else if (check.syncope.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have experienced Syncope (Fainting) in the past 36 months.")
        return false
    }
    else if ((check.syncope.response == "Yes" || check.syncope.response == "Unknown") && check.syncope.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have experienced Syncope (Fainting) within the past 36 months.")
        return false
    }
    // Blindness
    else if (check.blindness.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have experienced blindness is one or both eyes in the past 36 months, unless corrected by prescription eyeglasses or contact lenses.")
        return false
    }
    else if ((check.blindness.response == "Yes" || check.blindness.response == "Unknown") && check.blindness.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have experienced blindness within the past 36 months.")
        return false
    }
    // Impairments
    else if (check.impairments.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select whether or not any drivers have any mental or physical impairments.")
        return false
    }
    else if ((check.impairments.response == "Yes" || check.impairments.response == "Unknown") && check.impairments.drivers.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please select which drivers have mental or physical impairments.")
        return false
    }
    else if ((check.impairments.response == "Yes" || check.impairments.response == "Unknown") && check.impairments.drivers.length > 0 && check.impairments.explanation.length == 0) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please explain the mental or physical impairments that ail the driver.")
        return false
    }
    // Please explain the mental or physical impairments that ail Majid Preko.
    return true
}

export default ValidationAdditionalInfo