import React from 'react';
import TooltipIcon from '../TooltipIcon/TooltipIcon';

const DropdownRow = ({ name, question, options, value, onChange, defaultValue = "", onTooltipClick, display = true }) => {
  if (!display) {
    return null;
  }

  return (
    <div className="row form-group form-group-sm align-items-center my-3">
      <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0 fs-6">
        {question}
        {onTooltipClick && <TooltipIcon onClick={onTooltipClick} />}
      </div>
      <div className="col-12 col-md-6 col-sm-8">
        <select
          className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option mt-1"
          value={value || defaultValue}
          onChange={onChange}
        >
          {/* {(name === "vehicleLeasingCompany" || name === "vehicleFinanceCompany") && (
            <option value="" disabled>Select from the following...</option>
          )} */}
          {options}
        </select>
      </div>
    </div>
  );
} 

export default DropdownRow;