import DatePick_MI from 'components/DatePicker_MI'
import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
import { NewVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/NewVehicleContext'
import React, { useContext, useState } from 'react'

const RequestedCoverageEffDate = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    const newVehicleContext = useContext(NewVehicleContext)
    const [requestedCovEffDate, setRequestedCovEffDate] = useState(addVehicleContext.getTodaysDate(true))

    return (
        <>
            {/* Requested Effective Date */}
            <div className='col-xl-12 justify-content-center' style={{ margin: "0 0.5rem", padding: "0 0.75rem" }}>
                <div className="row form-group form-group-sm align-items-center my-3">
                    <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0 fs-6">
                        Requested Coverage Effective Date*
                    </div>
                    <div className="col-12 col-md-6 col-sm-8">
                        <DatePick_MI
                            dateSubmit={newVehicleContext.newVehicle.requestedCovEffDate ? newVehicleContext.newVehicle.requestedCovEffDate : requestedCovEffDate}
                            setDateSubmit={setRequestedCovEffDate}
                            storeValue={newVehicleContext.newVehCallback}
                            nameValue={"requestedCovEffDate"}
                            displayCalendar={"bottom-start"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestedCoverageEffDate