const VehiclesOnPolicy = (props) => {

    return (
        <>
            <div className="mt-4">
                <div className="sidebar-block">
                    <h5>Vehicles</h5>

                    <div className="sidebar-block-inn">
                        <div className="row">
                            {props?.sessionData?.vehicles?.vehiclesOnPolicy.map((vehicle, id) => {
                                return (
                                    <div key={id} className="name-plate mt-2">
                                        <h3 className="drivers ms-2 text-uppercase" style={{ color: '#046ab0' }}>
                                            {vehicle.Year} {vehicle.Make} {vehicle.Model}
                                        </h3>
                                    </div>
                                )
                            })

                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehiclesOnPolicy