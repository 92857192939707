import React, { useContext } from "react"
import ValidationModal from "../../ValidationModal"
import { VehicleChangeContext } from "../VehicleChangeContext"
import VehicleChangeBtnNavigation from "../VehicleChangeBtnNavigation"
import VehicleSummary_Changes from "./VehicleSummary_Changes"
import Summary_PhoneNumber from "../../ChangeRequestSharedComp/Summary_PhoneNumber"

const VehicleSummaryMain = () => {
    const context = useContext(VehicleChangeContext)

    return (
        <>
            <div className="row text-muted bg-block">
                <div className="col-12">
                    <VehicleSummary_Changes />
                    <Summary_PhoneNumber changeReqContext={VehicleChangeContext} reqChangeClass={"vehicleChangeClass"} setChangeClass={context.setVehicleChangeClass} />
                </div>
            </div>

            <VehicleChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default VehicleSummaryMain