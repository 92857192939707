import Menu from "../../components/Menu"
import RoundyContainer from "../../components/RoundyContainer"
import { AddressChangeContextComp } from "components/ChangeRequest_AddressChangeComp/AddressChangeContext"
import AddressProgressBarSect from "components/ChangeRequest_AddressChangeComp/AddressProgressBarSect"
import AddressDA_Main from "components/ChangeRequest_AddressChangeComp/3_DriverAssignment/AddressDA_Main"
import { useEffect } from "react"

const DriverAssignment = (props) => {
    const { userData } = props.user

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <AddressChangeContextComp>
            <Menu Title={"ADDRESS CHANGE"} FirstName={userData.firstName} userData={userData} />
            <AddressProgressBarSect screen={"Driver Assignment"} userData={userData} />
            <RoundyContainer Content={AddressDA_Main} NoRoundy
                params={{
                    storedData: { ...props.storedData },
                    setStoredData: props.setStoredData
                }}
            />
        </AddressChangeContextComp>
        </>
    )
}

export default DriverAssignment