import React, { useContext, useState } from 'react'
import BodyVIN from './1_Vehicle/AddVehicleBody/2_BodyVIN/BodyVIN'
import VehicleNote from './1_Vehicle/VehicleNote/VehicleNote'
import VehicleBtnNav from './1_Vehicle/VehicleBtnNav/VehicleBtnNav'
import { NewVehicleContext, NewVehicleContextComp } from './AddVehicleContext/NewVehicleContext'
import RequestedCoverageEffDate from './1_Vehicle/AddVehicleBody/1_RequestedCoverageEffDate/RequestedCoverageEffDate'
import VehicleInfo from './1_Vehicle/AddVehicleBody/3_VehicleInfo/VehicleInfo'
import { AddVehicleContext } from './AddVehicleContext/AddVehicleContext'
import VehiclePurchased from './1_Vehicle/AddVehicleBody/4_VehiclePurchased/VehiclePurchased'
import EndorsementVehicleOwned from './1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/EndorsementVehicleOwned.js'
import EndorsementVehicleLeasedFinanced from './1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/EndorsementVehicleLeasedFinanced'
import VehicleDA from './1_Vehicle/AddVehicleBody/6_DriverAssignment/VehicleDA_Main'
import "./AddVehicleMain.css"
import ValidationModal from 'components/ValidationModal'

const AddVehicleMain = () => {
    const addVehicleContext = useContext(AddVehicleContext)
    var newVehicles = addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles

    return (
        <>
            <NewVehicleContextComp>
                {(newVehicles?.length < 1 || newVehicles[0]?.componentTracker != "VehicleDriverAssignment") &&
                    <h5 className='text-primary text-center fw-bold mb-4'>ADD VEHICLE</h5>
                }

                <span className='px-md-5'>
                    {(newVehicles?.length < 1 || newVehicles[0]?.componentTracker != "VehicleDriverAssignment") &&
                        <RequestedCoverageEffDate />
                    }

                    {(newVehicles?.length < 1 || newVehicles[0].componentTracker == "VinInput") &&
                        <BodyVIN />
                    }
                    {newVehicles?.length > 0 && (["VehicleInfo", "VehiclePurchased", "VehicleOwned", "VehicleLeased", "VehicleFinanced"].includes(newVehicles[0]?.componentTracker)) &&
                        <VehicleInfo />
                    }
                    {newVehicles?.length > 0 && (["VehicleOwned", "VehiclePurchased", "VehicleLeased", "VehicleFinanced"].includes(newVehicles[0]?.componentTracker)) &&
                        <VehiclePurchased />
                    }
                    {newVehicles?.length > 0 && (["VehicleOwned"].includes(newVehicles[0]?.componentTracker)) &&
                        <>
                            <EndorsementVehicleOwned policyStateAbbr={addVehicleContext.getStateAbbr(addVehicleContext.addVehicleClass.policyState)} />
                        </>
                    }
                    {newVehicles?.length > 0 && (["VehicleLeased", "VehicleFinanced"].includes(newVehicles[0]?.componentTracker)) &&
                        <EndorsementVehicleLeasedFinanced policyStateAbbr={addVehicleContext.getStateAbbr(addVehicleContext.addVehicleClass.policyState)} />
                    }
                    {newVehicles?.length > 0 && (["VehicleDriverAssignment"].includes(newVehicles[0]?.componentTracker)) &&
                        <VehicleDA />
                    }

                </span>
                <hr />
                <VehicleNote />
                <hr />
                <VehicleBtnNav />
            </NewVehicleContextComp>

            <ValidationModal
                title={addVehicleContext.validationTitle}
                show={addVehicleContext.showModal}
                handleClose={() => addVehicleContext.setShowModal(false)}
                validationMsg={addVehicleContext.validationMsg}
            />
        </>
    )
}

export default AddVehicleMain