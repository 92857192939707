import { Spinner } from 'react-bootstrap';

const PaymentHistSect = (props) => {
  const { paymentHist, complete } = props.paymentData

  return (
    <>
      <div className="d-flex align-content-center flex-column">
        <h4 className="text-center section-title">
          PAYMENT HISTORY
        </h4>
        <small>
          <table className="table table-sm table-striped">
            <tbody>
              <tr>
                <th className="col-3"><small>Processed Date</small></th>
                <th className="col-6"><small>Transaction</small></th>
                <th className="text-end col-3"><small>Amount Paid</small></th>
              </tr>
              {complete ?
                paymentHist.result.map((value, index) => {
                  return (<tr key={index}>
                    <td className="col-3"><small>{FormatDate(new Date(value.accountingdt))}</small></td>
                    <td className="col-6"><small>{FormatType(value.transactiontypecd)}</small></td>
                    <td className="text-end col-3"><small>{FormatValue(value.paidamt)}</small></td>
                  </tr>)
                })
                :
                <>
                  <tr >
                    <td colSpan={3} align='center'>
                      <Spinner animation="border" />
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </small>
        <div className="p-2 payment-message">
          <strong>Not seeing your recent payment?</strong>
          <p>Allow 2-3 business days for your payment to show.</p>
        </div>

      </div>
    </>
  )
}

const FormatDate = (tempDate) => {
  var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
  var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
  var dateYear = `${tempDate.getFullYear()}`
  const date = `${dateMonth}/${dateDay}/${dateYear}`
  return date
}
const FormatType = (type) => {
  return type.replace(/([A-Z])/g, ' $1').trim()
}

const FormatValue = (value) => {
  var regex = /[+-]?[0-9]+\.?[0-9]*/g

  var valueString = regex
    .exec(value.replace(",", ""))[0]
  valueString = parseFloat(valueString)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var newValue = valueString
  if (!valueString.includes('.')) {
    newValue = valueString + '.00'
  } else {
    newValue = valueString + ((valueString.split(".")[1].length > 1) ? "" : "0")
  }
  return "$" + newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default PaymentHistSect