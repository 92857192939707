import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import TermsConditionsModal from './TermsConditionsModal';
import { storageData } from '../services/Services';
import { twilioRegistration } from '../services/Api';
import '../css/OptInModal.css';

const OptInModal = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumError, setPhoneNumError] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [thankYou, setThankYou] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);

    // Submission Response
    const submissionResponse = async (value) => {
        var user = props.userData;
        setLoadSpinner(true)
        // Opts In
        if (value == "Opt In") {
            setPhoneNumError(false)
            var digits = phoneNumber.replace(/[()-]/g, "").replace(" ", "") //Replaces the parenthesis/hyphen and whitespace
            if (digits.length < 10) {
                setPhoneNumError(true)
            }
            else {
                var twilioUser = {
                    ApplicationNumber: null,
                    PolicyNumber: user.policyNumber,
                    PhoneNumber: digits,
                    State: stateAbbr(user.state),
                    Env: user.env,
                    OptInChannel: "MyAccount",
                    SystemID: user.systemId
                }
                twilioRegistration({ OptIn: twilioUser })
                storageData.setItem('optInOutAnswered', true)
                setThankYou(true)
            }
        }
        // Opts Out
        else if (value == "Opt Out") {
            var twilioUser = {
                ApplicationNumber: null,
                PolicyNumber: user.policyNumber,
                PhoneNumber: '',
                State: stateAbbr(user.state),
                Env: user.env,
                OptOutChannel: "MyAccount",
                SystemID: user.systemId
            }
            twilioRegistration({ OptOut: twilioUser })
            storageData.setItem('optInOutAnswered', true)
            closeModal();
        }
        // Remind later (will pop up the next time on login)
        else {
            storageData.setItem('optInOutAnswered', true)
            closeModal();
        }
        setLoadSpinner(false)
    }

    // Formats the phone number with dashes and parenthesis
    const formatPhoneNum = (value) => {
        if (!value) {
            return value;
        }
        const phoneNum = value.replace(/[^\d]/g, "");

        if (phoneNum.length < 4) {
            return phoneNum;
        }
        if (phoneNum.length < 7) {
            return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
        }
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
    }

    // Handles Phone Number Input
    const handleInput = (phoneNum) => {
        const formattedPhoneNum = formatPhoneNum(phoneNum);
        setPhoneNumber(formattedPhoneNum);
    };

    // Returns Abbreviated State
    const stateAbbr = (state) => {
        if (state == "Michigan") {
            return "MI"
        }
        else if (state == "New Jersey") {
            return "NJ"
        }
        else {
            return "PA"
        }
    }

    // Closes Opt In/Out Modal
    const closeModal = () => { props.setShowOptInModal(false) }

    // Triggers Terms and Conditions Modal
    const handleClose = () => setShowTerms(false);
    const handleShow = () => setShowTerms(true);

    return (
        <>
            <Modal
                dialogClassName='modal'
                show={props.showModal}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className={'modalContent'}>
                    {thankYou &&
                        <Modal.Body>
                            <div className={'bootstrap-dialog-body'}>
                                <div style={{ fontSize: '14px' }}>
                                    <div className={'dialogContent dialogContent1'}>
                                        <div className={'content-padding'}>
                                            <button className={'closeBtn'} onClick={closeModal}>x</button>

                                            {/* CURE Logo */}
                                            <div className={'roundyDown'} />
                                            <p className={'thanks1'}>Thank you for signing up!</p>
                                            <p className={'thanks2'}>Talk to you soon. Drive well.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    }
                    {!thankYou &&
                        <Modal.Body>
                            <div>
                                <div>
                                    <div className={'dialogContent1'}>
                                        {/* CURE Logo */}
                                        <div className={'roundyDown'} />

                                        {/* Greeting */}
                                        <p className={'dialogContentGreeting'}>Hello, {props.userData.firstName}</p>
                                        <p className={'dialogContentGreeting2'}>Want to stay in the loop?</p>
                                        <p className={'dialogContentCellPhoneText'}>
                                            Enter your cell phone number to sign up for text updates with important information about your policy.
                                        </p>

                                        {/* Phone Number Input */}
                                        <input className={'twilioPhoneField'} placeholder="(___) ___ - ____" onChange={(e) => handleInput(e.target.value)} value={phoneNumber} />

                                        {/* Phone Number Error */}
                                        {phoneNumError &&
                                            <p style={{ textAlign: 'center', width: '100%', fontSize: '14px', color: 'red', margin: '0px' }}>
                                                Phone number has to be 10 digits long.
                                            </p>
                                        }

                                        <p className={'termsDescription'}>Message and data rates may apply. Reply STOP to opt-out at any time.</p>
                                        <a className={'twilioTermsLink'} href='#' onClick={handleShow}>
                                            Terms and Condtions
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={'dialogContent1Buttonspanel'}>
                                {/* Okay */}
                                <button className={'dialogContent1Btn'} value={'Opt In'} onClick={(e) => submissionResponse(e.target.value)}>
                                    OKAY
                                </button>
                                {/* No Thanks */}
                                <button className={'dialogContent1Btn'} value={'Opt Out'} onClick={(e) => submissionResponse(e.target.value)}>
                                    NO THANKS
                                </button>
                                <div className={'laterBtnWrapper'}>
                                    {/* Remind Me Later */}
                                    <button className={'dialogContent1Btn'} value={'Remind Me Later'} onClick={(e) => submissionResponse(e.target.value)}>
                                        REMIND ME LATER
                                    </button>
                                </div>
                            </div>
                            {loadSpinner &&
                                <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Spinner animation="border" />
                                </div>
                            }
                        </Modal.Body>
                    }
                </div>
                <Modal.Footer className={'modalFooter'} />
            </Modal>

            {/* Display Terms & Conditions */}
            <TermsConditionsModal
                showTerms={showTerms}
                handleClose={handleClose}
            />
        </>
    );
}
export default OptInModal