import { useState } from "react"
import { storageData } from "../../../services/Services"
import ValidationHouseholdMember from "./ValidationHouseholdMember"

const EditHouseholdMember = (props) => {
    const [edit, setEdit] = useState(
        !("editType" in props.currHouseholdMember) ?
            {
                editType: "",
                editReason: "",
                displayChange: "",
                stateLicenseNumber: ""
            }
            :
            {
                editType: props.currHouseholdMember.editType,
                editReason: props.currHouseholdMember.editReason,
                displayChange: props.currHouseholdMember.displayChange,
                stateLicenseNumber: props.currHouseholdMember.stateLicenseNumber,
            }
    )

    // For displaying the type of change on front-end
    const displayEditChange = (label) => {
        var editChange = {
            "MOVED": "Resides Elsewhere",
            "INSURANCE": "Has Other Insurance",
            "AddAsDriver": "Adding As Driver"
        }
        return editChange[label]
    }

    // Saves Edit Change to Household Member
    const saveEdit = async () => {
        // Checks Validation
        var validationResponse = await ValidationHouseholdMember({
            edit,
            setShow: props.setShow,
            setValidationMsg: props.setValidationMsg,
            handleClose: props.handleClose,
            handleShow: props.handleShow,
            setValidationLoad: props.setValidationLoad,
            setValidationTitle: props.setValidationTitle
        })
        // Clears validation
        if (validationResponse) {
            var sessionDataCopy = props.sessionData
            var currHouseholdMember = props.currHouseholdMember
            currHouseholdMember = { ...currHouseholdMember, ...edit }

            // Adds to driver section
            if (edit.editReason == "AddAsDriver") {
                if (sessionDataCopy?.household?.drivers?.newDrivers[props.index]?.fullName != currHouseholdMember?.fullName) {
                    sessionDataCopy.household.drivers.newDrivers.push(currHouseholdMember)
                }
                else {
                    sessionDataCopy.household.drivers.newDrivers[props.index] = currHouseholdMember
                }

                var storedHHMs = sessionDataCopy.household.householdMembers.storedHouseholdMembers
                storedHHMs = storedHHMs.filter(filteredHHM =>
                    filteredHHM !== props.currHouseholdMember
                );
                sessionDataCopy.household.householdMembers.storedHouseholdMembers = storedHHMs
            }
            // Regular Edit
            else {
                var newDrivers = sessionDataCopy.household.drivers.newDrivers
                newDrivers = newDrivers.filter(driver =>
                    driver !== props.currHouseholdMember
                );
                sessionDataCopy.household.drivers.newDrivers = newDrivers
                sessionDataCopy.household.householdMembers.storedHouseholdMembers[props.index] = currHouseholdMember
            }

            props.setEditExistingHouseholdMemberMenu([])
            props.setSessionData({ ...sessionDataCopy })
            storageData.setItem("sessionDataRQ", JSON.stringify(props.sessionData))
        }
    }

    // Onchange update function
    const updateEdit = (key, value) => {
        var editCopy = edit
        if (key == "editType") {
            editCopy["editReason"] = ""
            if (value == "MOVED") {
                editCopy["displayChange"] = displayEditChange(value)
            }
        }
        if (key == "editReason") {
            editCopy["displayChange"] = displayEditChange(value)
        }

        editCopy[key] = value
        setEdit({ ...edit })
    }

    return (
        <>
            <div className="row mx-4">
                <div className="col-12 col-lg-4 ">
                    <p>What would you like to edit about this person?</p>
                </div>

                <div className="col-12 col-lg-8 px-4 mb-2">

                    {/* No Longer Resides in Household */}
                    <div className="col-12 mb-1">
                        <label className="form-check-label" style={{ display: 'flex' }}>
                            <input className="form-check-input" defaultChecked={edit.editType == "MOVED"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editHHM"
                                value={"MOVED"} onChange={(e) => updateEdit('editType', e.target.value)} />
                            <span className="ms-2 fw-bold" style={{ color: '#366898' }}>No longer resides in household.</span>
                        </label>
                    </div>

                    {/* Has a valid Driver's License */}
                    <div className="col-12">
                        <label className="form-check-label" style={{ display: 'flex' }}>
                            <input className="form-check-input" defaultChecked={edit.editType == "VALIDLICENSE"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editHHM"
                                value={"VALIDLICENSE"} onChange={(e) => updateEdit("editType", e.target.value)} />
                            <span className="ms-2 fw-bold" style={{ color: '#366898' }}>This person now has a Driver's License.</span>
                        </label>
                    </div>

                    {/* Valid driver's license */}
                    {edit.editType == "VALIDLICENSE" &&
                        <div className="col-12 ms-4 mt-2">

                            {/* Add As Driver */}
                            <div className="col-12 mb-1">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "AddAsDriver"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editValidLicense"
                                        value={"AddAsDriver"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>Add as a driver on to policy.</span>
                                </label>
                            </div>

                            {/* License Number */}
                            {edit.editReason == "AddAsDriver" &&
                                <div className="row my-1">
                                    <div className="ms-2">
                                        <input type="text" className="textFullName" value={edit.stateLicenseNumber} placeholder="License Number" maxLength={50} onChange={(e) => updateEdit("stateLicenseNumber", e.target.value)} />
                                        <div className="important-note">(Not required right now, but needed in next 30 days)</div>
                                    </div>
                                </div>
                            }

                            {/* Has Own Insurance */}
                            <div className="col-12">
                                <label className="form-check-label" style={{ display: 'flex' }}>
                                    <input className="form-check-input" defaultChecked={edit.editReason == "INSURANCE"} style={{ marginTop: '2px', flexShrink: 0 }} type={"radio"} name="editValidLicense"
                                        value={"INSURANCE"} onChange={(e) => updateEdit('editReason', e.target.value)} />
                                    <span className="ms-2 fw-bold" style={{ color: '#366898' }}>This person has their own car insurance.</span>
                                </label>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* Save/Cancel Stored Driver */}
            <div className="row justify-content-end p-3">
                <input type="submit" value={"Save"} className="saveButton" onClick={saveEdit} />
                <input type="submit" value={"Cancel"} className="cancelButton ms-3" onClick={() => props.setEditExistingHouseholdMemberMenu([])} />
            </div>
        </>
    )
}

export default EditHouseholdMember