export const ValidationForm = (newVehicle, drivers, state) => {
    // NJ
    if (state == "New Jersey" && newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.vehicleIsCoOwned == "") 
        return { title: "Oops!", message: 'Please select if the vehicle is co-owned.' };
    if (state == "New Jersey" && newVehicle.vehicleIsNJ == "") 
        return { title: "Oops!", message: 'Please select if the vehicle is registered in New Jersey.' };

    // PA
    if (state == "Pennsylvania" && newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.vehicleIsCoOwned == "") 
        return { title: "Oops!", message: 'Please select if the vehicle is co-owned.' };
    if (state == "Pennsylvania" && newVehicle.vehicleIsPA == "") 
        return { title: "Oops!", message: 'Please select if the vehicle is registered in Pennsylvania.' };
    
    // Owned vehicle
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && !newVehicle.vehicleIsCoOwned)
        return { title: "Oops!", message: 'Please select if the vehicle is co-owned.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.garaged == "")
        return { title: "Oops!", message: 'Please select if the vehicle is primarily kept at this address.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "")
        return { title: "Oops!", message: 'Please select if the vehicle will have "Other than Collision" coverage.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "1" && newVehicle.otherDeductible == "")
        return { title: "Oops!", message: 'Please choose your "Other than Collision" (Comprehensive) deductible.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "1" && newVehicle.wantsCollision == "")
        return { title: "Oops!", message: 'Please specify if you choose "Collision" coverage.' };
    if (state == "Michigan" && newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "1" && newVehicle.wantsCollision == "1" && newVehicle.collisionType == "")
        return { title: "Oops!", message: 'Please select a "Collision" type.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "1" && newVehicle.wantsCollision == "1" && newVehicle.collisionDeductible == "")
        return { title: "Oops!", message: 'Please select a "Collision" coverage deductible.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Owned' && newVehicle.wantsOtherThanCollision == "1" && newVehicle.rentalCoverage == "")
        return { title: "Oops!", message: 'Please select if rental reimbursement is needed.' };

    // Leased vehicle
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Leased' && !newVehicle.vehicleIsCoLeased)
        return { title: "Oops!", message: 'Please select if the vehicle is co-leased.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Leased' && newVehicle.vehicleLeasingCompany == "")
        return { title: "Oops!", message: 'Please select the name of the leasing company.' };

    // Financed vehicle
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Financed' && !newVehicle.vehicleIsCoOwned)
        return { title: "Oops!", message: 'Please select if the vehicle is co-owned.' };
    if (newVehicle.vehicleOwnedLeasedFinanced == 'Financed' && newVehicle.vehicleFinanceCompany == "")
        return { title: "Oops!", message: 'Please select the name of the financing company.' };

    // Leased/Financed vehicle
    if ((newVehicle.vehicleOwnedLeasedFinanced == 'Leased' || newVehicle.vehicleOwnedLeasedFinanced == 'Financed') && newVehicle.garaged == "")
        return { title: "Oops!", message: 'Please select if the vehicle is primarily kept at this address.' };
    if ((newVehicle.vehicleOwnedLeasedFinanced == 'Leased' || newVehicle.vehicleOwnedLeasedFinanced == 'Financed') && newVehicle.otherDeductible == "")
        return { title: "Oops!", message: 'Please choose your "Other than Collision" (Comprehensive) deductible.' };
    if (state == "Michigan" && (newVehicle.vehicleOwnedLeasedFinanced == 'Leased' || newVehicle.vehicleOwnedLeasedFinanced == 'Financed') && newVehicle.collisionType == "")
        return { title: "Oops!", message: 'Please select a "Collision" type.' };
    if ((newVehicle.vehicleOwnedLeasedFinanced == 'Leased' || newVehicle.vehicleOwnedLeasedFinanced == 'Financed') && newVehicle.collisionDeductible == "")
        return { title: "Oops!", message: 'Please select a "Collision" coverage deductible.' };
    if ((newVehicle.vehicleOwnedLeasedFinanced == 'Leased' || newVehicle.vehicleOwnedLeasedFinanced == 'Financed') && newVehicle.rentalCoverage == "")
        return { title: "Oops!", message: 'Please select if rental reimbursement is needed.' };

    // Applies to all
    if (newVehicle.vehicleRegistrant == "")
        return { title: "Oops!", message: 'Please select whose name in which this vehicle is registered, or is going to be registered.' };
    if (newVehicle.vehicleUse == "")
        return { title: "Oops!", message: 'Please select how this vehicle will be used.' };
    if (newVehicle.vehicleCustomization == "")
        return { title: "Oops!", message: 'Please select if the vehicle has any modifications or customizations.' };
    if (newVehicle.vehicleCommercial == "")
        return { title: "Oops!", message: 'Please select if this is a commercial vehicle.' };
    if (newVehicle.vehicleCommercialPurposes == "")
        return { title: "Oops!", message: 'Please select if this vehicle is used for commercial purposes.' };
    if (newVehicle.leasedRentToOthers == "")
        return { title: "Oops!", message: 'Please select if this vehicle is leased or rented to other.' };
    if (newVehicle.vehiclePlowSnow == "")
        return { title: "Oops!", message: 'Please select if this vehicle is used to plow snow for compensation.' };
    if (newVehicle.drivenExistingDriver == "")
        return { title: "Oops!", message: 'Please select if this vehicle will be driven by an existing driver on your policy.' };

    // Driver Assignment
    if (newVehicle.drivenExistingDriver == '1' && newVehicle.componentTracker == "VehicleDriverAssignment")
    {
        for (let i = 0; i < drivers.length; i++) {
            if (!("vehicle" in drivers[i]) || drivers[i]?.vehicle == '') {
                return { title: "Oops!", message: `Please select a vehicle for ${drivers[i]?.fullName}.` };
            }
        }
    }
}