import React from 'react';
import TooltipIcon from '../TooltipIcon/TooltipIcon';

const RadioButtonsRow = ({ name, question, value, onChange, onTooltipClick, indentedContent, noteContent, options = [], display = true, alignItemsCenter = true }) => {
  const radioOptions = options.length > 0 ? options : [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" }
  ];

  if (!display) {
    return null;
  }

  return (
    <div className={`row form-group form-group-sm my-3 ${alignItemsCenter ? "align-items-center": ""}`}>
      <div className="col-12 col-md-6 text-primary fw-bold mb-2 mb-md-0 fs-6">
        {question}
      {onTooltipClick && <TooltipIcon onClick={onTooltipClick} />}
      {indentedContent && <IndentedContent content={indentedContent} />}
    </div>
    <div className="col-12 col-md-6">
      {radioOptions.map((option) => (
        <RadioOption
          key={option.value}
          name={name}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          onChange={onChange}
        />
      ))}
      {noteContent && <NoteContent content={noteContent} />}
      </div>
    </div>
  );
};

const RadioOption = ({ name, value, label, checked, onChange }) => (
  <div className="radio-inline form-check-inline me-3" style={{ paddingLeft: 0 }}>
    <label style={{ fontSize: '13px' }}>
      <input 
        type="radio" 
        className="form-check-input me-2" 
        name={name} 
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  </div>
);

const IndentedContent = ({ content }) => (
  <div className="ms-3 mt-2 fw-bold" style={{ paddingRight: '20%' }}>
    {content}
  </div>
);

const NoteContent = ({ content }) => (
  <div className="mt-2">
    {content}
  </div>
);

export { RadioButtonsRow, IndentedContent, NoteContent };