const getAllYears = () => {
    const years = {
        value: "Year",
        years: []
    };
    
    const currentYear = new Date().getFullYear();
    const startYear = 1904;
    
    for (let year = currentYear; year >= startYear; year--) {
        years.years.push({ year: year.toString() });
    }

    return years
}

const years = getAllYears()

export default years