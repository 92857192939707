const EbillingTerms = ()=>{
    return(
        <>
        <div className="overflow-auto" style={{ height: 330 }}>
                <small className="text-muted">


                    <h6 className="text-start section-title">Electronic Transactions:</h6>
                    <p>State law permits parties which have agreed to conduct transactions by electronic means to enter into an agreement online provided the requirements of the applicable laws are met. State law requires CURE to obtain your consent to electronically sign insurance application documents and to provide you with an electronic copy of your policy related documents.</p>
                    <h6 className="text-start section-title">An Electronic Signature:</h6>
                    <p>An electronic signature is an electronic sound, symbol, or process attached to or logically associated with a record that is executed or adopted by a person to demonstrate their intent to sign the record. When you decide to apply online for insurance coverage with CURE, you are required to electronically sign the following documents: an Agreement to Conduct Business Electronically, an Application, and a Power of Attorney.</p>
                    <p>You also have the option to obtain a copy of your application documents in a non-electronic format if you so choose. To receive paper copies of documents that are delivered electronically, or to withdraw consent to receive such documents electronically, please follow the instructions set forth below in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."</p>
                    <p>Once coverage is bound, CURE will send you a Coverage Selection Form showing your coverage selections on which you may make any changes and/or corrections. For New Jersey Insureds, a Buyer's Guide, the Insurance Scenarios, and the Consumer Bill of Rights will also accompany the Coverage Selection Form.</p>

                    <h6 className="text-start section-title">Withdrawal of Consent and Request for Paper Documents:</h6>
                    <p>You may withdraw your consent to obtain your application documents electronically or request paper copies of documents that are delivered electronically by sending a written request to CURE, Attention: Underwriting Department, 214 Carnegie Center, Suite 301, Princeton, New Jersey 08540.
                    </p>
                    <h6 className="text-start section-title">Application to CURE by Telephone:</h6>
                    <p>If you have obtained a quote with CURE but do not want to apply for a policy using the electronic transaction option, please call us at 1-800-535-2873. Our representatives will be glad to assist you with your automobile insurance needs.
                    </p>
                    <h6 className="text-start section-title">Hardware and software requirements for access to and retention of Electronic Records:</h6>
                    <p>To access CUREs website and obtain electronic application documents, you must have the ability to access the Internet using a web browser such as Microsoft Edge, Google Chrome or Firefox. A valid e-mail address, Adobe Acrobat Reader and a printer are also required in order to receive, view, and print certain application documents. CURE uses modern encrypt protocols while accessing sensitive information during on-line transactions.</p>
                    <p>Should CURE change hardware and software requirements for access to and retention of electronic records, such that the change creates a material risk that you will not be able to access or retain your subsequent electronic records (the subject of your prior consent,) CURE will provide you with a statement of:</p>
                    <ul>
                        <li>The revised hardware and software requirements for access to and retention of your electronic records.</li>
                        <li>Your right to withdraw consent without imposition of a fee for such withdrawal and without the imposition of any condition or consequence that was not disclosed above.</li>
                    </ul>

                    <h6 className="text-start section-title">Statement of Understanding:</h6>
                    <p>I understand that my application documents will be sent to me electronically if I consent to electronically sign my application. I agree to accept all other documents relating to my policy electronically provided they are not required by law to be mailed, with the knowledge that I may withdraw consent to receive those insurance documents electronically by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."</p>

                    <p>I understand that I have the right to withdraw consent to receive my insurance application documents electronically, without imposition of a fee for such withdrawal and without the imposition of any condition or consequence that was not disclosed above, by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."</p>

                    <p>I understand that I have the option to obtain a copy of my application documents in a non-electronic format by following the instructions set forth above in the section titled "WITHDRAWAL OF CONSENT & REQUEST FOR PAPER DOCUMENTS."</p>

                    <p>I understand that withdrawal of consent will terminate the online transaction which I have begun.</p>
                </small>
            </div>
        </>
    )
}
export default EbillingTerms