import React, { useContext, useEffect, useRef, useState } from "react";
import { mStatus as mStatusOpt, gender as genderOpt, relation, insuranceType as insuranceTypeOpt, states, dlStatus, FormAuto } from "../..";
import ValidationModal from "../../../ValidationModal";
import AddMemberValidationMI from "../../../ChangeRequest_AddressChangeComp/ValidationConditions/AddMemberValidationMI";
import { storageData } from "../../../../services/Services";

const AddMemberMI = (props) => {
    var { newMembers, memberList, index, setAddMember, changeReqContext, reqChangeClass, setChangeClass } = props
    const context = useContext(changeReqContext)
    var changeClass = context[reqChangeClass]
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"

    const [state, setState] = useState((index >= memberList.length) ? {
        DOB: "",
        mStatus: "",
        gender: "",
        dLicense: "",
        licenseNum: "",
        licenseAge: "",
        licenseState: "Michigan",
        licenseOtherState: "",
        licenseCountry: "",
        fullName: "",
        moveDate: "",
        daySelect: "",
        insurance: "",
        insuranceType: "",
        ownInsurance: "",
        yearSelect: "",
        monthSelect: "",
        effectiveDate: context.getTodaysDate(true),
        licenseStatus: "",
        insuredRelationship: "",
        user: "household member",
        memberStatus: "New Resident",
        initialType: "New",
        hideBtn: false,
        toggleAuto: false,
        show: false,
        impairments: "",
        disorder: ""
    } : {
        ...memberList[index],
    })

    const validationMsg = useRef("")
    const validationTitle = useRef("")

    // Validation Modal
    const handleClose = () => setState({ ...state, show: false });
    const handleShow = () => setState({ ...state, show: true });

    // Retrieves Form Values
    const callback = (key, value) => {
        if (key == "licenseAge") { // Check if the value is < 1, reset to empty string
            value = Math.max(0, value)
            if (value == 0) { value = "" }
        }
        var info = { ...state };
        info[key] = value;
        setState({ ...info })
    }

    // Sets Validation Message
    const handleValidationMsg = (msg, title) => {
        validationMsg.current = msg
        validationTitle.current = title
    }

    // Adds member
    const addNewMember = (e) => {
        // Adds DOB format
        if (monthSelect && daySelect && yearSelect) {
            state.DOB = monthSelect + "/" + daySelect + "/" + yearSelect
        }

        var addedMember = state;
        if ((e.target.value) === "SAVE") {
            var check = AddMemberValidationMI({ infoFields: addedMember, handleValidationMsg, handleShow, userData: context.userData });
            if (check) {
                var changeClassCopy = changeClass
                var memberListContextCopy = changeClassCopy?.driversHHMs?.householdMembers?.newMembers
                var newMemberListCopy = memberList;

                // Adding New Member
                if (index > memberList.length) {
                    newMemberListCopy.push(state);
                }
                // Editng Member
                else {
                    newMemberListCopy[index] = state;
                }
                memberListContextCopy = newMemberListCopy
                setChangeClass(changeClassCopy)
                storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
                setAddMember(false)
            }
            else {
                return
            }
        }
        else {
            setAddMember(false)
        }
        context.setEditAddedMemberArr([])
    };

    useEffect(() => {
        // Toggles Auto Questions
        if (state.fullName && state.monthSelect && state.daySelect && state.yearSelect) {
            var DOB = `${state.monthSelect}/${state.daySelect}/${state.yearSelect}`
            var atLeast16 = (Date.now() - new Date(DOB).getTime()) >= (16 * 365 * 24 * 60 * 60 * 1000)
            var newState = { ...state, DOB }

            if (atLeast16) {
                newState.toggleAuto = true
            } else {
                newState.toggleAuto = false
            }
            setState(newState)
        }
    }, [state.monthSelect, state.daySelect, state.yearSelect])

    var { fullName, monthSelect, daySelect, yearSelect, mStatus, gender, moveDate, effectiveDate, insuredRelationship, user, dLicense, licenseStatus, insurance, insuranceType, ownInsurance, licenseState, licenseOtherState, licenseCountry, licenseNum, licenseAge, impairments, disorder } = state;
    return (
        <>
            <div className="slide-toggle edit-section mt-2">
                <div className="form-group form-group-xs">
                    <h6 className="text-center household-text-blue less-margin fw-bold">
                        NEW HOUSEHOLD MEMBER
                    </h6>
                </div>
                <FormAuto
                    form={
                        [
                            {
                                id: "fullName",
                                type: "text",
                                label: "Full Name:",
                                value: fullName,
                                callback
                            },
                            {
                                id: { y: "yearSelect", m: "monthSelect", d: "daySelect" },
                                type: "dateSelect",
                                label: "Date of Birth:",
                                value: { y: yearSelect, m: monthSelect, d: daySelect },
                                callback
                            },
                            {
                                id: "mStatus",
                                type: "select",
                                label: "Marital Status:",
                                value: mStatus,
                                options: mStatusOpt,
                                col: {
                                    label: { xl: 4 },
                                    input: { xl: 6 },
                                },
                                callback
                            },
                            {
                                id: "gender",
                                type: "select",
                                label: "Gender:",
                                value: gender,
                                options: (state.userData?.state == "New Jersey") ? [...genderOpt, "Unspecified"] : genderOpt,
                                col: {
                                    label: { xl: 4 },
                                    input: { xl: 6 },
                                },
                                callback
                            },
                            {
                                id: "moveDate",
                                type: "dateTextMask",
                                label: "When did this household member move in?*",
                                value: moveDate,
                                noMinMax: true,
                                col: {
                                    label: { xl: 12 },
                                    input: { xl: 8 }
                                },
                                className: {
                                    label: "my-1",
                                    input: "my-1",
                                    datePicker: "col-12"
                                },
                                callback
                            },
                            {
                                id: "effectiveDate",
                                type: "dateTextMask",
                                label: "Requested Effective Date",
                                value: effectiveDate,
                                col: {
                                    label: { xl: 12 },
                                    input: { xl: 8 },
                                },
                                className: {
                                    label: "my-1",
                                    input: "my-1",
                                    datePicker: "col-12"
                                },
                                callback
                            },
                            (new Date().getFullYear() - parseInt(yearSelect) >= 16) &&
                            {
                                id: "dLicense",
                                type: "radio",
                                label: "Does this household member have a valid driver's license?*",
                                value: dLicense,
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                col: {
                                    label: { xs: 8 },
                                    input: { xs: 2 },
                                },
                                callback
                            },
                            (dLicense == "1") &&
                            {
                                id: "licenseState",
                                type: "select",
                                label: "In what state is this driver licensed?*",
                                value: licenseState,
                                defaultValue: "Michigan",
                                options: states,
                                col: {
                                    label: { xs: 4 },
                                    input: { xs: 6 },
                                },
                                callback
                            },
                            (dLicense == "1") &&
                            {
                                id: "ownInsurance",
                                type: "radio",
                                label: "Does this household member maintain their own auto insurance?",
                                value: ownInsurance,
                                col: {
                                    label: { xs: 12 },
                                    input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback

                            },
                            (dLicense == "1") && (ownInsurance === "0") &&
                            {
                                id: "licenseNum",
                                type: "text",
                                label: "License Number:*",
                                value: licenseNum,
                                callback
                            },
                            (dLicense == "1") && (ownInsurance === "0") &&
                            {
                                id: "licenseAge",
                                type: "text",
                                inputType: "number",
                                maxLength: 2,
                                label: "What age was this driver first licensed?*",
                                value: licenseAge,
                                callback: (id, val) => { callback(id, (val > 99) ? 99 : val) }
                            },
                            (dLicense == "1") && (ownInsurance == "0") &&
                            {
                                id: "disorder",
                                type: "radio",
                                label: "Does this driver have a disorder or condition that may impair their ability to safely operate a motor vehicle, including but not limited to Narcolepsy, Syncope, Blindness or Seizures?*",
                                value: disorder,
                                col: {
                                    label: { xs: 12 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            },
                            (dLicense == "1") && (ownInsurance == "0") &&
                            {
                                id: "impairments",
                                type: "radio",
                                label: "Does this driver have any mental or physical impairments, other than listed above, that would impair their ability to operate a motor vehicle safely? (Not including prescription eyeglasses or contact lenses.)*",
                                value: impairments,
                                col: {
                                    label: { xs: 12 }, input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                callback
                            },
                            (dLicense === "0") &&
                            {
                                id: "licenseStatus",
                                type: "select",
                                label: "What is the status of this household member's driver's license?*",
                                value: licenseStatus,
                                col: {
                                    label: { xs: 12 }, input: { xs: 6 },
                                },
                                className: {
                                    margin: "my-3"
                                },
                                options: dlStatus,
                                callback
                            },
                            (dLicense === "0" && licenseStatus == "Valid Foreign License") &&
                            {
                                id: "licenseCountry",
                                type: "text",
                                label: "In what country is this household member licensed:",
                                value: licenseCountry,
                                className: {
                                    margin: "my-3",
                                    marginBottom: "my-1"
                                },
                                callback
                            },
                            (dLicense === "0" && licenseStatus == "Valid Other State License") &&
                            {
                                id: "licenseOtherState",
                                type: "select",
                                label: "In what state is this household member licensed?*",
                                value: licenseOtherState ,
                                options: states,
                                col: {
                                    label: { xs: 12 }, input: { xs: 6 },
                                },
                                className: {
                                    margin: "my-3",
                                    marginBottom: "my-1"
                                },
                                callback
                            },
                            (dLicense === "0" && (licenseStatus == "Valid Foreign License" || licenseStatus == "Valid Other State License")) &&
                            {
                                id: "ownInsurance",
                                type: "radio",
                                label: "Does this household member maintain their own auto insurance?*",
                                value: ownInsurance,
                                col: {
                                    label: { xs: 12 },
                                    input: { xs: 2 },
                                },
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                className: {
                                    margin: "my-3",
                                    marginBottom: "my-1"
                                },
                                callback
                            },
                            {
                                id: "insuredRelationship",
                                type: "select",
                                label: "Relationship to Insured:*",
                                col: {
                                    label: { xl: 4, lg: 12 },
                                    input: { xl: 6, lg: 12 },
                                },
                                caret: false,
                                value: insuredRelationship,
                                options: relation.relations,
                                callback
                            },
                            ((context?.userData?.pipOption >= 4 && context?.userData?.pipOption <= 6) && insuredRelationship != "Other" && insuredRelationship) &&
                            {
                                id: "insurance",
                                type: "radio",
                                label: "Does this household member have health insurance?*",
                                value: insurance,
                                options: [{ id: "1", value: "Yes" }, { id: "0", value: "No" }],
                                col: {
                                    label: { xs: 12 },
                                    input: { xs: 2 },
                                },
                                callback
                            },
                            ((context?.userData?.pipOption >= 4 && context?.userData?.pipOption <= 6) && insuredRelationship != "Other" && insuredRelationship && insurance == "1") && {
                                id: "insuranceType",
                                type: "select",
                                label: "Please confirm what type of health insurance this household member has.*",
                                value: insuranceType,
                                col: {
                                    label: { xs: 12 }, input: { xs: 6 },
                                },
                                options: insuranceTypeOpt,
                                callback
                            }
                        ]
                    }
                />
                <hr className="less-margin my-3" />
                <div className="text-right my-3" onClick={addNewMember}>
                    <input type={"button"} className="btn btn-default btn-sm editCancelBtn" value={"CANCEL"} />
                    <input type={"button"} className="btn btn-primary-grad btn-sm ms-1 editCancelBtn" value={"SAVE"} />
                </div>
                <ValidationModal
                    show={state.show}
                    handleClose={handleClose}
                    validationMsg={validationMsg.current}
                    title={validationTitle.current}
                />
            </div>
        </>
    )
}

export default AddMemberMI