const ValidationAgreement = (props) => {
    var check = props.sessionData.agreements
    // Checks responses
    if (!check.conductBusinessElectronicallyTermsCondition) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please agree to the Agreement to Conduct Business Electronically.")
        return false
    }
    else if (check.paperlessBillingResponse && check.paperlessBillingEmail == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please provide an email for Paperless Billing.")
        return false
    }
    else if (!check.termsAndConditionsRQ) {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please agree to the Agreement to Conduct Business Electronically.")
        return false
    }
    else if (check.fullName == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please finish signing the agreement.")
        return false
    }
    else if (check.date == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg("Please put today's date for the agreement")
        return false
    }
    return true
}

export default ValidationAgreement