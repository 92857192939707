import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'
import { storageData } from '../services/Services'

export const UserContext = createContext()

const initialState = {
  emailAddress: null
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_EMAIL':
      storageData.setItem('user', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload
      }
    case 'REMOVE_EMAIL':
      storageData.clear()
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export const UserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UserContext.Provider value={[state, dispatch]}>{props.children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
  children: PropTypes.object
}
