import { validateAddress } from "../../../services/Api"

const ResidenceValidation = async (props) => {
    // Response Check
    if (props.sessionData.currentResidence.response == "") {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg(`Please verify if this is the current residence of the insured driver.`)
        return false
    }

    var address;
    if (props.sessionData.currentResidence.response == "Yes") {
        address = {
            "addrTypeCd": "ContactAddr",
            "addr1": props.sessionData.currentResidence.streetAddress1,
            "city": props.sessionData.currentResidence.city,
            "postalCode": props.sessionData.currentResidence.zip,
            "stateProvCd": props.sessionData.currentResidence.state,
            "regionCd": "US",
            "regionISOCd": "US",
        }
    }
    else {
        address = {
            "addrTypeCd": "ContactAddr",
            "addr1": props.sessionData.currentResidence.newResidence.streetAddress1,
            "city": props.sessionData.currentResidence.newResidence.city,
            "postalCode": props.sessionData.currentResidence.newResidence.zip,
            "stateProvCd": props.sessionData.currentResidence.newResidence.state,
            "regionCd": "US",
            "regionISOCd": "US",
        }

        // Checks responses
        if (address.addr1 == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg("Please fill in your new address.")
            return false
        }
        else if (address.city == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg("Please fill in your new city.")
            return false
        }
        else if (address.stateProvCd == "" || address.stateProvCd == "State") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg("Please fill in your new state.")
            return false
        }
        else if (address.postalCode == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg("Please fill in your new zip code.")
            return false
        }
    }

    props.setLoad(true);
    // Validates Address
    var response = await validateAddress(address)
    if (response.data == "Valid") {
        props.setLoad(false);
        return true
    }
    else {
        props.handleShow()
        props.setValidationLoad(true)
        props.setValidationTitle("Oops!")
        props.setValidationMsg(`That is not a valid city/zip combination for ${address.stateProvCd}`)
        props.setLoad(false);
        return false
    }
}

export default ResidenceValidation