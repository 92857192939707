import React from "react";
import { storageData } from "../../../services/Services";
import DriverPolicy from "./DriverPolicy";

const styles = {
    indent: {
        marginLeft: "15px"
    },
    blueText: {
        color: "#046ab0",
        fontSize: '13.5px'
    }
}
let daysPerWeek = [
    "Please select...",
    "1 day per week",
    "2 days per week",
    "3 days per week",
    "4 days per week",
    "5 days per week",
    "6 days per week",
    "7 days per week"
]
let milesOneWay = [
    "Please select...",
    "Less than 3 miles one-way",
    "Less than 10 miles one-way",
    "10 or more miles one-way",
    "Pleasure use only",
    "Business use (traveling sales rep, etc.)",
    "Farm use"
]
class SelectVehicle extends React.Component {
    state = {
        allDrivers: [],
        drivers: this.props.drivers,
        milesDriven: [],
        vehicle: [],
        vehiclesPulled: [],
        indexCount: [],
        vehicleCount: 0
    }
    daysPerWeekOptions = () => {
        return (
            <>{daysPerWeek.map((option, index) => {
                return (<option className="text-primary" key={index} value={option}>{option}</option>)
            })}
            </>
        )
    }
    DaysPerWeek = (i) => <>{this.daysPerWeekOptions(i)}</>

    milesOneWayOptions = () => {
        return (
            <>{milesOneWay.map((option, index) => {
                return (<option className="text-primary" key={index} value={option}>{option}</option>)
            })}
            </>
        )
    }
    MilesOneWay = (i) => <>{this.milesOneWayOptions(i)}</>
    vehicleOptions = (props) => {
        return (
            <>{this.state.vehiclesPulled.length > 0 &&
                <>{
                    this.state.vehiclesPulled.map((vehicle, index) => {
                        return (
                            <>
                                <div className="radio m-1" key={index}>
                                    <label className="form-check-label text-uppercase">
                                        <input className="form-check-input" value={vehicle} name="vehicle" type={"radio"}
                                            onChange={(e) => { this.storeValue(props.driverIndex, "vehicle", e.target.value) }}
                                            disabled={props.stateVehicle.includes(vehicle)} checked={this.props.storedData[props.driverIndex].vehicle === vehicle}
                                        />
                                        <span className="m-2">{vehicle}</span>
                                    </label>
                                </div>
                            </>
                        )
                    })}
                    <div className="radio m-1">
                        <label className="form-check-label text-uppercase">
                            <input className="form-check-input" name="vehicle" type={"radio"} checked={this.props.storedData[props.driverIndex].vehicle === "NotPrimaryDriver"}
                                value={"NotPrimaryDriver"} onChange={(e) => { this.storeValue(props.driverIndex, "vehicle", e.target.value) }} />
                            <span className="m-2">NOT A PRIMARY DRIVER ON ANY CAR</span>
                        </label>
                    </div>
                </>
            }
                {this.state.vehiclesPulled.length === 0 &&
                    <p>Found no vehicles</p>
                }
            </>
        )
    }
    VehiclePerDriver = (stateVehicle, driverIndex) => <>{this.vehicleOptions(stateVehicle, driverIndex)}</>
    // Clears selection
    reset = () => {
        this.setState({ vehicle: [], milesDriven: [false, false], vehicleCount: 0 });
        for (let i = 0; i < Object.keys(this.props.storedData).length; i++) {
            this.props.storedData[i].vehicle = ""
        }
    }
    // Diables the vehicle for other drivers
    disableBtn = (value, index) => {
        // Converts driver data to array
        var arrToStore = [];
        var keys = Object.keys(this.props.storedData);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i]
            arrToStore[key] = this.props.storedData[key]
        }
        var vSelected = [];
        var vehicleObj = new Object();
        // var vehicleSelected = this.state.vehicle;
        var vehicleSelected = arrToStore;
        vehicleSelected.map((item, i) => {
            vehicleObj[i] = item.vehicle
        })
        vehicleObj[index] = value
        for (var i = 0; i < Object.keys(this.props.storedData).length; i++) {
            arrToStore[i].vehicle = vehicleObj[i]
            vSelected.push(vehicleObj[i])
        }
        this.props.setStoredData(arrToStore)
        this.setState({ vehicle: vSelected })
        if (!this.state.indexCount.includes(index)) {
            var indexCountCopy = this.state.indexCount;
            indexCountCopy.push(index)
            this.setState({ indexCount: indexCountCopy, vehicleCount: this.state.vehicleCount + 1 })
        }
    }
    // Sets vehicle for driver
    vehicleDriven = (value, index) => {
        this.disableBtn(value, index)
        this.props.setSelectVehicle(value)
        let arr = this.state.allDrivers.map((value, i) => {
            return ((i === index) || (this.state.milesDriven[i]))
        })
        this.setState({ milesDriven: arr })
    }
    storeValue = (key, name, value) => {
        var info = { ...this.props.storedData };
        var infoValue = {}
        infoValue[name] = value
        // Adds VIN if vehicle
        if (name === 'vehicle') {
            var vehicles = storageData.getItem('vehicleInfo')
            if (vehicles) {
                vehicles = JSON.parse(vehicles)
                for (var i = 0; i < vehicles.length; i++) {
                    var vehicleName = `${vehicles[i].Year} ${vehicles[i].Make} ${vehicles[i].Model}`
                    if (value === vehicleName) {
                        infoValue["Year"] = vehicles[i].Year
                        infoValue["Make"] = vehicles[i].Make
                        infoValue["Model"] = vehicles[i].Model
                        infoValue["VIN"] = vehicles[i].VIN
                    }
                }
            }
        }
        info[key] = { ...this.props.storedData[key], ...infoValue }
        this.props.setStoredData({ ...info })
    }
    // Stores drivers for validation
    storesDrivers = (drivers) => {
        this.props.driversExist(drivers)
        var info = { ...drivers };
        var infoValue = {}
        infoValue["vehicle"] = ""
        for (let i = 0; i < Object.keys(drivers).length; i++) {
            info[i] = { ...drivers[i], ...infoValue }
            info[i]["daysPerWeek"] = daysPerWeek[0]
            info[i]["milesOneWay"] = milesOneWay[0]
            this.props.setStoredData(info)
        }
        this.setState({ allDrivers: drivers })
    }
    componentDidMount() {
        // Gets vehicles
        var allVehicles = storageData.getItem('dbVehicles');
        var addedVehicles = storageData.getItem("newVehicles");
        var vehicleArr = [];
        // For both stored and added vehicles
        if (allVehicles && allVehicles !== "[]" && addedVehicles && addedVehicles !== "[]") {
            var combinedVehicles = [...JSON.parse(allVehicles), ...JSON.parse(addedVehicles)]
            for (var i = 0; i < combinedVehicles.length; i++) {
                if ("replacementVehicle" in combinedVehicles[i]) {
                    var vehicleInfo = `${combinedVehicles[i].replacementVehicle.vehicleYear} ${combinedVehicles[i].replacementVehicle.vehicleMake} ${combinedVehicles[i].replacementVehicle.vehicleModel}`;
                    vehicleArr.push(vehicleInfo);
                }
                else if ("vehicleMake" in combinedVehicles[i]) {
                    var vehicleInfo = `${combinedVehicles[i].vehicleYear} ${combinedVehicles[i].vehicleMake} ${combinedVehicles[i].vehicleModel}`;
                    vehicleArr.push(vehicleInfo);
                }
                else if ("michiganVehicleMake" in combinedVehicles[i]) {
                    var vehicleInfo = `${combinedVehicles[i].michiganVehicleYear} ${combinedVehicles[i].michiganVehicleMake} ${combinedVehicles[i].michiganVehicleModel}`;
                    vehicleArr.push(vehicleInfo);
                }
                else if (combinedVehicles[i].removalReason === "") {
                    var vehicleInfo = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                    vehicleArr.push(vehicleInfo);
                }
                else {
                    continue
                }
            }
        }
        // Only for added vehicles
        else if (addedVehicles && addedVehicles !== "[]") {
            addedVehicles = JSON.parse(addedVehicles)
            for (var i = 0; i < addedVehicles.length; i++) {
                var vehicleInfo = `${addedVehicles[i].vehicleYear} ${addedVehicles[i].vehicleMake} ${addedVehicles[i].vehicleModel}`;
                vehicleArr.push(vehicleInfo);
            }
        }
        // Only for stored vehicles
        else if (allVehicles && allVehicles !== "[]") {
            allVehicles = JSON.parse(allVehicles);
            for (var i = 0; i < allVehicles.length; i++) {
                if ("replacementVehicle" in allVehicles[i]) {
                    var vehicleInfo = `${allVehicles[i].replacementVehicle.vehicleYear} ${allVehicles[i].replacementVehicle.vehicleMake} ${allVehicles[i].replacementVehicle.vehicleModel}`;
                    vehicleArr.push(vehicleInfo);
                }
                else if (allVehicles[i].removalReason !== "" && "michiganVehicleMake" in allVehicles[i]) {
                    var vehicleInfo = `${allVehicles[i].michiganVehicleYear} ${allVehicles[i].michiganVehicleMake} ${allVehicles[i].michiganVehicleModel}`;
                    vehicleArr.push(vehicleInfo);
                }
                else if (allVehicles[i].removalReason === "") {
                    var vehicleInfo = `${allVehicles[i].Year} ${allVehicles[i].Make} ${allVehicles[i].Model}`;
                    vehicleArr.push(vehicleInfo);
                }
                else {
                    continue
                }
            }
        }
        this.setState({ vehiclesPulled: vehicleArr })

        // Gets stored drivers and pending drivers
        var storedDrivers = storageData.getItem('storedDrivers');
        if (storedDrivers && storedDrivers !== "[]") {
            storedDrivers = JSON.parse(storedDrivers);

            // Checks for any pending drivers
            const pendingDrivers = storageData.getItem('PendingDrivers');
            if (pendingDrivers) {
                var pendingAddedDrivers = JSON.parse(pendingDrivers);
                var addPendingDrivers = [];
                for (var i = 0; i < pendingAddedDrivers.length; i++) {
                    if (pendingAddedDrivers[i].SelectedType === "Driver") {
                        var pendingDriver = {};
                        pendingDriver["fullName"] = pendingAddedDrivers[i].FirstName
                        pendingDriver["DOB"] = pendingAddedDrivers[i].DOB
                        pendingDriver["gender"] = (pendingAddedDrivers[i].Sex === "850") ? "M" : "F"
                        pendingDriver["driverStatus"] = "pending"
                        pendingDriver["initialType"] = "Driver"
                        addPendingDrivers.push(pendingDriver)
                    }
                }
                var totalDrivers = [...storedDrivers, ...addPendingDrivers]
                this.storesDrivers(totalDrivers)
            }
            else {
                this.storesDrivers(storedDrivers);
            }
        }

        // Retrieves locally stored vehicles assigned
        var driverAssignmentData = storageData.getItem('updateDriverAssignmentData');
        if (driverAssignmentData) {
            driverAssignmentData = JSON.parse(driverAssignmentData).updateDriverAssignmentData;
            this.props.setStoredData(driverAssignmentData)
            var driverAssignmentValues = Object.values(driverAssignmentData)
            var storedVehicleArr = driverAssignmentValues.map((value) => {
                return value.vehicle
            })
            var arr = []
            for (var i = 0; i < driverAssignmentValues.length; i++) {
                if (driverAssignmentValues[i].vehicle !== "") {
                    arr.push(true)
                    this.state.vehicleCount++
                }
                else {
                    arr.push(undefined)
                }
            }
            this.setState({ milesDriven: arr, vehicle: storedVehicleArr })
            this.props.setSelectVehicle({ driverAssignmentValues })
        }
    }
    render() {
        const { milesDriven } = this.state;
        const vehicle = this.state.vehicle;
        var VehiclePerDriver = this.VehiclePerDriver
        var DaysPerWeek = this.DaysPerWeek
        var MilesOneWay = this.MilesOneWay
        return (
            <>
                <div style={styles.indent}>
                    <div className="mb-1">
                        {this.state.allDrivers.map((driver, index) => {
                            return (
                                <form key={index}>
                                    <strong style={styles.blueText}>
                                        Select the vehicle <span className="text-uppercase">{driver.fullName}</span> primarily drives.
                                        <span className="ms-2 fst-italic fw-light text-dark">{(driver.driverStatus === "pending") ? "(Add Pending)" : ""}</span>
                                    </strong>
                                    <div style={styles.indent} onChange={(e) => { this.vehicleDriven(e.target.value, index); }}>
                                        <VehiclePerDriver stateVehicle={vehicle} driverIndex={index} />
                                    </div>
                                    {/* Vehicle Selected */}
                                    {this.props.selectVehicle !== "" && milesDriven[index] && this.state.vehicle[index] !== "NotPrimaryDriver" &&
                                        <div style={styles.indent}>
                                            <div className="mb-1 mx-4">
                                                <strong style={styles.blueText}>
                                                    Number of days per week this car is used to drive to work/school/public transportation site?
                                                </strong>
                                            </div>
                                            <div className="mb-1">
                                                <div className="btn-group m-2 mx-4 custom-selector">
                                                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                                        value={this.props.storedData[index].daysPerWeek}
                                                        onChange={(e) => { this.storeValue(index, "daysPerWeek", e.target.value) }}>
                                                        <DaysPerWeek
                                                            index={index}
                                                        />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1 mx-4">
                                                <strong style={styles.blueText}>
                                                    How many miles one-way?
                                                </strong>
                                            </div>
                                            <div className="mb-1">
                                                <div className="btn-group m-2 mx-4 custom-selector">
                                                    <select className="col-sm-12 btn btn-default btn-group dropdown-toggle filter-option" style={{ width: "200px", fontSize: "12px" }}
                                                        value={this.props.storedData[index].milesOneWay}
                                                        onChange={(e) => { this.storeValue(index, "milesOneWay", e.target.value) }}>
                                                        <MilesOneWay
                                                            index={index}
                                                        />
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>
                            )
                        })}
                        {(this.state.vehicleCount >= this.state.vehiclesPulled.length) &&
                            <button className="btn btn-default mt-2" onClick={() => this.reset()}>
                                Clear
                            </button>
                        }
                        <DriverPolicy styles={styles}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default SelectVehicle