import React from "react";
import { Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

const FormDateText = (props) => {
    const {
        id,
        value,
        label,
        options,
        col = {
            label: {
                xs: 12,
            },
            input: {
                xs: 12, sm: 8
            }
        },
        className = {
            label: "",
            input: "",
            datePicker: "col-6"
        },
        callback
    } = props

    const formatDate = (date) => {
        var datePassed = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        return datePassed
    }
    return (
        <>
            <div className="row form-group form-group-sm align-items-center">
                <Col {...col.label}>
                    <div className="row form-group form-group-sm align-items-center">
                        <strong className="text-primary">{label}</strong>
                    </div>
                </Col>
                <Col {...col.input}>
                    <div className="row form-group form-group-sm align-items-center">
                        <div>
                            <ReactDatePicker
                                value={value}
                                placeholderText="MM/DD/YYYY"
                                className={`${className.datePicker} text-sm-start fw-light btn btn-default btn-group mt-1`}
                                selected={(value !== "") ? new Date(value) : new Date()}
                                onChange={(e) => callback(id, formatDate(e))}
                                onKeyDown={(e) => { e.preventDefault() }}
                            />
                        </div>
                    </div>
                </Col>
            </div>
        </>
    )
}

export default FormDateText