import { useContext } from "react"
import Additional_KeptOvernightResponse from "./Additional_KeptOvernightResponse"
import Additional_YesNoUnknownResponses from "./Additional_YesNoUnknownResponses"
import { storageData } from "services/Services"

const Additional_DriverStatuses = ({ changeReqContext, reqChangeClass, setChangeClass }) => {
    var context = useContext(changeReqContext)
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"
    var changeClass = context[reqChangeClass]

    // Sets the statuses of the additional page
    const setResponse = (key, value) => {
        var changeClassCopy = changeClass
        if (key == "keptOvernight") {
            changeClassCopy.additionalPage[key].response = value
        }
        else {
            changeClassCopy.additionalPage[key] = value
        }
        setChangeClass({ ...changeClassCopy })
        storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
    }

    // Sets the status to the driver
    const setStatusToDriver = (typeOfDriver, index, key, value) => {
        var changeClassCopy = changeClass
        var storedDrivers = changeClass?.driversHHMs?.drivers?.storedDrivers
        var newDrivers = changeClass?.driversHHMs?.drivers?.newDrivers
        var listCopy = changeClassCopy.additionalPage[key + "Drivers"]

        if (typeOfDriver == "stored") { //Sets to stored drivers
            storedDrivers[index][key] = value
            changeClassCopy.driversHHMs.drivers.storedDrivers = storedDrivers

            if (key != "impairmentExplanation") {
                if (value) { // Check driver
                    changeClassCopy.additionalPage[key + "Drivers"].push(storedDrivers[index])
                }
                else { // Unchecks driver
                    listCopy = listCopy?.filter((driver) => driver?.fullName != storedDrivers[index]?.fullName)
                    changeClassCopy.additionalPage[key + "Drivers"] = listCopy
                }
            }
        }
        else { //Sets to new drivers
            newDrivers[index][key] = value
            changeClassCopy.driversHHMs.drivers.newDrivers = newDrivers

            if (key != "impairmentExplanation") {
                if (value) { // Check driver
                    changeClassCopy.additionalPage[key + "Drivers"].push(newDrivers[index])
                }
                else { // Unchecks driver
                    listCopy = listCopy?.filter((driver) => driver?.fullName != newDrivers[index]?.fullName)
                    changeClassCopy.additionalPage[key + "Drivers"] = listCopy
                }
            }
        }
        setChangeClass({ ...changeClassCopy })
        storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
    }

    // Sets the address fields for where the policy vehicles are kept
    const setAddressFields = (key, value) => {
        var changeClassCopy = changeClass
        changeClassCopy.additionalPage.keptOvernight[key] = value
        setChangeClass({ ...changeClassCopy })
        storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
    }

    return (
        <>
            {/* Supspended License */}
            <div className="form-group form-group-sm">
                <strong className="text-primary">
                    Have any drivers' licenses been suspended in any state in the past 36 month?
                </strong>
            </div>
            <div className="form-group form-group-sm text-dark">
                <Additional_YesNoUnknownResponses type={"suspended"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
            </div>
            <hr className="no-padding" />
            {/* Conditions */}
            <div className="col-xl-12 form-group form-group-sm">
                <strong className="col-sm-12 text-primary">
                    Have any drivers experienced any of the following conditions within the past 36 months?
                </strong>
                <br /><br />
                <div className="ms-3">
                    {/* Seizure Disorders */}
                    <div className="row">
                        <div className="col-lg-12 text-dark">{"1) Seizure Disorders"}
                            <Additional_YesNoUnknownResponses type={"seizures"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
                        </div>
                    </div>
                    {/* Narcolepsy */}
                    <div className="row">
                        <div className="col-lg-12 text-dark">{"2) Narcolepsy"}
                            <Additional_YesNoUnknownResponses type={"narcolepsy"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
                        </div>
                    </div>
                    {/* Syncope */}
                    <div className="row">
                        <div className="col-lg-12 text-dark">{"3) Syncope (Fainting)"}
                            <Additional_YesNoUnknownResponses type={"syncope"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
                        </div>
                    </div>
                    {/* Blindness */}
                    <div className="row">
                        <div className="col-lg-12 text-dark">{"4) Blindness in one or both eyes"}
                            <br />
                            <em className="small">{"(Unless corrected by prescription eyeglasses or contact lenses)"}</em>
                            <Additional_YesNoUnknownResponses type={"blindness"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
                        </div>
                    </div>
                </div>
            </div>

            <hr className="no-padding" />

            {/* Impairments */}
            <div className="form-group form-group-sm col-12">
                <div className="mb-2">
                    <strong className="text-primary">
                        Do any of the drivers have any mental or physical impairments, other than listed above, that would impair their ability
                        to drive a motor vehicle safely?
                    </strong>

                    <em className="text-dark ms-2">(Not including prescription eyeglasses or contact lenses.)</em>
                </div>
                <div className="form-group form-group-sm text-dark">
                    <Additional_YesNoUnknownResponses type={"impairments"} setResponse={setResponse} setStatusToDriver={setStatusToDriver} changeClass={changeClass} setChangeClass={setChangeClass} />
                </div>
            </div>

            {/* Kept Overnight */}
            <div>
                <Additional_KeptOvernightResponse setResponse={setResponse} setAddressFields={setAddressFields} changeClass={changeClass} />
            </div>
        </>
    )
}

export default Additional_DriverStatuses