import Images from "../assets/images/Images";
import { storageData } from "../services/Services";
import { getDomain } from "../services/Utils";

const Header = () => {

  const secondBanner = {
    fontFamily: "GlacialIndifference !important",
    textAlign: "center",
    backgroundColor: "#366898",
    color: "white",
    fontSize: "1.5em",
    letterSpacing: "3px",
    marginBottom: "0.5em",
    minHeight: "64px",
    paddingLeft: "10%",
    paddingRight: "10%",
  }

  const checkLogout = () => {
    storageData.clear();
    window.location = getDomain();
  }

  return (
    <>
      <div style={secondBanner}>
        <div style={{ width: "97%", height: "1.5em", paddingTop: "0.15em", textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div style={{ width: "8em", textAlign: "right", margin: "auto" }}>
              <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={checkLogout}>
                <img alt="Cure Logo" style={{ width: "100%", cursor: "pointer", filter: 'brightness(0) invert(1)' }} src={Images.cureLogo} />
              </button>
            </div>

            <div style={{ textAlign: "right", margin: "auto", fontSize: "0.8em" }}>
              <span style={{ color: "white!important", textDecoration: "none!important" }}>
                <a className="text-light" href="tel:8005352873">{"800-535-CURE(2873)"}</a>
              </span>
            </div>

          </div>
        </div>
      </div>
      {/* <div className="bg-secondary d-flex justify-content-center container-fluid header-nav p-2">
        <div className="row col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 ">
          <div className="col-5 col-md-5 p-0" >
            <Link to='/'>
              <img className="container-fluid" alt="Cure Logo" style={{ maxWidth: 168 }} src={Images.cureLogo} />
            </Link>
          </div>

          <div className="col-7 col-md-7 header-font text-center row p-0">
            <div className="d-flex justify-content-end">
              <div className="row justify-content-center align-content-center">
                <span>
                  <a className="text-light" href="tel:8005352873">{"800-535-CURE(2873)"}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}


export default Header;
