import React, { useState, useEffect } from "react";
import { storageData } from "../../../services/Services";

export default function PhoneNumberInput(props) {
    const storeValue = (key, value) => {
        props.setStoredData(data => ({
            ...data,
            [key]: value
        }))
    }

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        props.setStoredData({"Phone Number": ""})
        var retrievedPhoneNum = storageData.getItem('vehicleSummaryChanges')
        if (retrievedPhoneNum) {
            retrievedPhoneNum = JSON.parse(retrievedPhoneNum)
            var localPhoneNum = retrievedPhoneNum.vehicleSummaryChanges
            props.setStoredData(localPhoneNum)
            setInputValue(localPhoneNum["Phone Number"])
        }
    }, [])
    const handleInput = (e) => {
        const formattedPhoneNum = formatPhoneNum(e.target.value);
        setInputValue(formattedPhoneNum);
    };
    return <input className="form-control text-center" onChange={(e) => { handleInput(e); storeValue("Phone Number", e.target.value) }} value={inputValue} />
}

const formatPhoneNum = (value) => {
    if (!value) {
        return value;
    }
    const phoneNum = value.replace(/[^\d]/g, "");

    if (phoneNum.length < 4) {
        return phoneNum;
    }
    if (phoneNum.length < 7) {
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }
    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
}
