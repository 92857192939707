import { useContext, useState } from "react";
import { HouseholdChangeContext } from "../HouseholdChangeContext";
import { useNavigate } from "react-router-dom";
import { storageData } from "services/Services";

const HouseholdConfirmationMain = () => {
    const context = useContext(HouseholdChangeContext)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const date = new Date();

    var storedDrivers = context?.householdChangeClass?.driversHHMs?.drivers?.storedDrivers
    var newDrivers = context?.householdChangeClass?.driversHHMs?.drivers?.newDrivers
    var storedMembers = context?.householdChangeClass?.driversHHMs?.householdMembers?.storedMembers
    var newMembers = context?.householdChangeClass?.driversHHMs?.householdMembers?.newMembers
    var allDrivers = [...storedDrivers, ...newDrivers]
    var allHHMs = [...storedMembers, ...newMembers]

    return (
        <>
            <div className="row px-2">
                <h4 className="text-center text-primary fw-bold">YOUR REQUEST HAS BEEN SUBMITTED.</h4><br /><br />
                <table style={{ width: "auto", margin: "0 auto" }}>
                    <tbody>
                        <tr>
                            <td className="fw-bold" style={{ paddingRight: "10px" }}>
                                Date:
                            </td>
                            <td>
                                {(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()}
                            </td>
                        </tr>
                        <tr>
                            <td className="small fst-italic" style={{ color: 'grey' }}>
                                NOTE: All changes are subject to review by underwriting.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-4 text-center">
                <h4 className="no-margin text-primary fw-bold">Overview</h4>
                <div>
                    {/* Drivers */}
                    {allDrivers?.map((driver, id) => {
                        return (
                            ("driverStatus" in driver && driver?.driverStatus != "") &&
                            <div key={id} className="small">
                                <strong className="text-uppercase text-primary">{driver?.fullName}</strong>
                                <span className="fst-italic"> - {driver?.driverStatus}</span>
                            </div>
                        )
                    })}

                    {/* HHMs */}
                    {allHHMs?.map((member, id) => {
                        return (
                            ("memberStatus" in member && member?.memberStatus != "") &&
                            <div key={id} className="small">
                                <strong className="text-uppercase text-primary">{member?.fullName}</strong>
                                <span className="fst-italic"> - {member?.memberStatus}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="text-center">
                <br /><br />
                <input type={"button"} className="btn btn-primary-grad" value={"Return to My Account"} onClick={() => {
                    navigate("/home");
                    // Removes changes for extra storage in local storage
                    storageData.removeItem("updateVehicleData");
                    storageData.removeItem("updateDriverAssignmentData");
                    storageData.removeItem("newVehicles");
                    storageData.removeItem("dbVehicles");
                    storageData.removeItem("vehicleSummaryChanges");
                    storageData.removeItem("HouseholdChangeConfirmationNumber");
                    storageData.removeItem("storedDrivers");
                    storageData.removeItem("storedHouseholdMembers");
                    storageData.removeItem("storedVehicles");
                    storageData.removeItem("PendingAddedVehicles");
                    storageData.removeItem("PendingRemovedVehicles");
                    storageData.removeItem("PendingAddedDrivers");
                    storageData.removeItem("PendingAddedHhms");
                    storageData.removeItem("PendingRemovedDrivers");
                    storageData.removeItem("PendingRemovedHhms");            
                    storageData.removeItem("PendingAddress");
                    storageData.removeItem("vehicleInfo");
                    storageData.removeItem("currResidence");
                    storageData.removeItem("HouseholdChangeClass");
                }} />
                <br /><br />
            </div>
        </>
    )
}

export default HouseholdConfirmationMain