import React, { useContext, useRef, useState } from "react";
import { FormAuto, states } from "../..";
import ValidationModal from "../../../ValidationModal";
import VehicleSelections from "../../VehicleSelections";
import NotSameHousehold from "../../NotSameHousehold";
import { storageData } from "services/Services";
import EditDriverValidationMI from "components/ChangeRequest_AddressChangeComp/ValidationConditions/EditDriverValidationMI";

const EditDriverMI = (props) => {
    var { driverList, driver, index, changeReqContext, reqChangeClass, setChangeClass, saveResponse } = props
    const context = useContext(changeReqContext)
    var reqChangeClassCopy = context[reqChangeClass]
    var typeOfClass = (reqChangeClass == "householdChangeClass") ? "HouseholdChangeClass" : "AddressChangeClass"

    const [state, setState] = useState({
        editDriver: "",
        obtainedOwnCarInsurance: "",
        effectiveDate: context?.getTodaysDate(true),
        licenseStatus: "",
        dateOccurred: "",
        takingVehicles: "",
        vehiclesTaken: [],
        vehiclesTakenVINs: [],
        residenceAddress: "",
        residenceCity: "",
        residenceState: "MI",
        residenceZip: "",
        moveDate: "",
        ...driver
    })

    const validationMsg = useRef("")
    const validationTitle = useRef("")

    const callback = (key, value) => {
        var info = { ...state };
        info[key] = value;
        setState({ ...info })
    }

    // Validation Modal
    const handleClose = () => setState({ ...state, show: false });
    const handleShow = () => setState({ ...state, show: true });

    // Sets Validation Message
    const handleValidationMsg = (msg, title) => {
        validationMsg.current = msg
        validationTitle.current = title
    }

    // Saves options for edited driver
    const saveEditOptions = (e) => {
        var editedDriver = state;
        if ((e.target.value) === "SAVE") {

            var check = EditDriverValidationMI({ editFields: editedDriver, handleValidationMsg, handleShow, userData: context.userData });
            if (check) {
                // Sets status of driver depending on edits
                editDriver = setEditedStatus(state)

                var changeClassCopy = reqChangeClassCopy
                var storedMemberListContextCopy = changeClassCopy?.driversHHMs?.drivers?.storedDrivers

                storedMemberListContextCopy[index] = state;

                setChangeClass(changeClassCopy)
                storageData.setItem(typeOfClass, JSON.stringify(changeClassCopy))
            }
            else {
                return
            }
        }
        context?.setShowMemberEditMenu(false);
        context?.setShowDriverEditMenu(false);
        context?.setEditStoredMemberArr([])
        context?.setEditStoredDriverArr([])
    };

    // Sets the vehicle and vehicle VIN to the driver
    const setVehicleToDriver = (type, typeOfVehicle, typeOfVehicleVINs, vehicle, checked) => {
        var info = { ...state }
        // Adds vehicle to array
        if (checked) {
            typeOfVehicle.push((vehicle != "None of the above") ? vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model : vehicle)
            info["vehiclesTaken"] = typeOfVehicle

            typeOfVehicleVINs.push(vehicle?.VIN)
            info["vehiclesTakenVINs"] = typeOfVehicleVINs
        }
        // Removes vehicle from array
        else {
            if (vehicle != "None of the above"){
                typeOfVehicle = typeOfVehicle.filter((veh) => veh != vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model)
            }
            else {
                typeOfVehicle = typeOfVehicle.filter((veh) => veh != vehicle)
            }
            
            info["vehiclesTaken"] = typeOfVehicle
            typeOfVehicleVINs = typeOfVehicleVINs.filter((VIN) => VIN != vehicle.VIN)
            info["vehiclesTakenVINs"] = typeOfVehicleVINs
        }
        setState({ ...info })
    }

    // Edited status that will be displayed on UI
    const setEditedStatus = (driver) => {
        // No longer drivers policy vehicle
        if (driver?.editDriver == "No longer drives policy vehicles and still resides in household.") {
            if (driver?.obtainedOwnCarInsurance == "1") {
                // Has own insurance
                driver.driverStatus = "Has Own Insurance"
                driver["editType"] = "Has Own Insurance"
            }
            else {
                driver.driverStatus = "No Longer Drives Policy Vehicles"
                driver["editType"] = "No Longer Drives Policy Vehicles"
            }
        }
        // Invalid license
        else if (driver?.editDriver == "No longer has valid driver's license.") {
            var obj = {
                "License was revoked": "License Revoked",
                "License was suspended": "License Suspended",
                "License expired": "License Expired",
                "License was surrendered": "License Surrendered"
            }
            driver.driverStatus = obj[driver?.licenseStatus]
            driver["editType"] = obj[driver?.licenseStatus]
        }
        // Resides elsewhere
        else if (driver?.editDriver == "No longer resides in household.") {
            driver.driverStatus = "Resides Elsewhere"
            driver["editType"] = "Resides Elsewhere"
        }
        // Reset driver status
        else {
            driver.driverStatus = ""
        }
        return driver
    }

    var { editDriver, obtainedOwnCarInsurance, effectiveDate, licenseStatus, dateOccurred, takingVehicles, vehiclesTaken, vehiclesTakenVINs, moveDate, residenceAddress, residenceCity, residenceState, residenceZip, residenceVehicles, residenceVehicleVINs } = state

    return (
        <>
            {/* Edit Form */}
            <div className="slide-toggle edit-section form-group">
                <div className="editMember form-group form-group-sm">
                    {/* Driver Edit */}
                    <FormAuto
                        form={
                            [
                                // Type of edit
                                {
                                    id: "editDriver",
                                    type: "select",
                                    label: "What would you like to edit about this driver?*",
                                    value: editDriver,
                                    options: [
                                        "Select from the following...",
                                        "No longer drives policy vehicles and still resides in household.",
                                        "No longer has valid driver's license.",
                                        "No longer resides in household."
                                    ],
                                    col: {
                                        label: { xl: 12 },
                                        input: { xl: 10 }
                                    },
                                    callback
                                },
                                // Obtained own car insurance
                                (editDriver == "No longer drives policy vehicles and still resides in household.") &&
                                {
                                    id: "obtainedOwnCarInsurance",
                                    type: "radio",
                                    label: "Has this driver obtained their own car insurance?*",
                                    col: {
                                        label: { xs: 12 },
                                        input: { xs: 2 },
                                    },
                                    value: obtainedOwnCarInsurance,
                                    options: [
                                        { value: "Yes", id: "1" },
                                        { value: "No", id: "0" },
                                    ],
                                    callback
                                },
                                // License status
                                (editDriver == "No longer has valid driver's license.") &&
                                {
                                    id: "licenseStatus",
                                    type: "radio",
                                    label: "Please confirm status of license.*",
                                    value: licenseStatus,
                                    options: [
                                        { id: "License was revoked", value: "License was revoked" },
                                        { id: "License was suspended", value: "License was suspended" },
                                        { id: "License expired", value: "License expired" },
                                        { id: "License was surrendered", value: "License was surrendered" }
                                    ],
                                    className: {
                                        margin: "my-2",
                                        marginBottom: "mb-1",
                                        marginSecond: "my-0"
                                    },
                                    callback
                                },
                                // Date occurred
                                (editDriver == "No longer has valid driver's license.") &&
                                {
                                    id: "dateOccurred",
                                    type: "dateTextMask",
                                    label: "When did this occur?",
                                    value: dateOccurred,
                                    noMinMax: true,
                                    col: {
                                        label: { xl: 6 },
                                        input: { xl: 6 },
                                        caret: false
                                    },
                                    className: {
                                        label: "mb-1",
                                        input: "mb-1"
                                    },
                                    callback
                                },
                                // Taking vehicles to new address
                                (editDriver == "No longer resides in household.") &&
                                {
                                    id: "takingVehicles",
                                    type: "radio",
                                    label: "Is this person taking any policy vehicles with them to their new address?*",
                                    col: {
                                        label: { xs: 12 },
                                        input: { xs: 2 },
                                    },
                                    value: takingVehicles,
                                    options: [
                                        { value: "Yes", id: "1" },
                                        { value: "No", id: "0" },
                                    ],
                                    callback
                                }
                            ]
                        }
                    />
                    {/* Vehicles list for driver no longer in household */}
                    {(editDriver == "No longer resides in household.") && (takingVehicles == "1") &&
                        <VehicleSelections type={"vehiclesTaken"} typeOfVehicle={vehiclesTaken} typeOfVehicleVINs={vehiclesTakenVINs} setVehicleToDriver={setVehicleToDriver}
                            changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                    }
                    {/* New address fields for driver no longer in household */}
                    {(editDriver == "No longer resides in household.") && (takingVehicles == "1") &&
                        <NotSameHousehold typeOfForm={"vehiclesTaken"} callback={callback} setState={setState} state={state}
                            changeReqContext={changeReqContext} reqChangeClass={reqChangeClass} setChangeClass={setChangeClass} />
                    }
                    <FormAuto
                        form={
                            [
                                // Person move date
                                (editDriver == "No longer resides in household.") &&
                                {
                                    id: "moveDate",
                                    type: "dateTextMask",
                                    label: "When did this person move?*",
                                    value: moveDate,
                                    noMinMax: true,
                                    col: {
                                        label: { xl: 6 },
                                        input: { xl: 6 },
                                        caret: false
                                    },
                                    className: {
                                        label: "mb-1",
                                        input: "mb-1"
                                    },
                                    callback
                                },
                                // Displays requested date
                                (editDriver != "") &&
                                {
                                    id: "effectiveDate",
                                    type: "dateTextMask",
                                    label: "Requested Effective Date*",
                                    col: {
                                        label: { xl: 6 },
                                        input: { xl: 6 },
                                        caret: false
                                    },
                                    value: effectiveDate,
                                    callback
                                }
                            ]
                        }
                    />
                </div>

                {/* Cancel/Save Function */}
                <hr className="less-margin my-3" />
                <div className="text-right my-3" >
                    <input type={"button"} className="btn btn-default btn-sm editCancelBtn" value={"CANCEL"} onClick={saveEditOptions} />
                    <input type={"button"} className="btn btn-primary-grad btn-sm ms-1 editCancelBtn" value={"SAVE"} onClick={saveEditOptions} />
                </div>
                <ValidationModal
                    show={state.show}
                    handleClose={handleClose}
                    validationMsg={validationMsg.current}
                    title={validationTitle.current} />
            </div>
        </>
    )
}

export default EditDriverMI