import React from "react";
import FullName from "./FullName";
import Occupation from "./Occupation";
import DateOfBirth from "./DOB";
import MaritalStatus from "./MaritalStatus";
import Gender from "./Gender";
import CurrentRelationship from "./CurrentRelationship";
import SameHousehold from "./SameHousehold";
import ValidLicense from "./ValidLicense";
import College from "./College";
import ValidationModal from "../../../ValidationModal";
import AddDriverValidation from "../../../ChangeRequest_AddressChangeComp/ValidationConditions/AddDriverValidationMI";
import MichiganLicenseQuest from "../../MichiganLicenseQuest";
import { storageData } from "../../../../services/Services";
import DisorderConditionQuest from "./DisorderConditionQuest";
import MentalPhysicalQuest from "./MentalPhysicalQuest";

class AddNewDriver extends React.Component {
    constructor(props) {
        super(props);
        this.newDriver = props.newDrivers;
        this.displayDrivers = props.displayDrivers;
        this.props.addNewDriver();
        this.hideBtn = props.hideBtn;
    }
    state = (this.props.index >= this.props.displayDrivers.length) ? {
        fullName: "",
        occupation: "",
        monthSelect: "",
        daySelect: "",
        yearSelect: "",
        DOB: "",
        mStatus: "",
        gender: "",
        currRel: "",
        sameHH: "",
        disorderCondition: "",
        mentalPhysical: "",
        ageLicensed: "",
        address: "",
        city: "",
        state: "Michigan",
        zip: "",
        dLicense: "",
        licenseState: "",
        licenseNum: "",
        licenseStatus: "",
        inCollege: "",
        schoolName: "",
        schoolCity: "",
        schoolState: "",
        vehicleSchool: "",
        over100: "",
        user: "driver",
        addDriver: false,
        hideBtn: false,
        ageMet: 0,
        driverStatus: "New Driver",
        initialType: "New",
        show: false,
        validationMsg: "",
        validationTitle: "",
        userData: "",
        michiganLicenseState: "",
        michiganLicenseNum: "",
        michiganLicenseDate: "",
        dateMoveIn: "",
        michiganDriverAddedPurpose: "",
        vehiclesAtNewAddress: [],
        vehicleSchool: [],
        healthInsurance: "",
        healthInsuranceType: ""
    } : {
        ...this.props.displayDrivers[this.props.index]
    };
    // Validation Modal
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    // Retrieves Form Values
    handleCallback = (key, value) => {
        var info = {};
        info[key] = value;
        this.setState(info)
    }
    // Sets Validation Message
    handleValidationMsg = (msg, title) => {
        this.setState({ validationMsg: msg, validationTitle: title })
    }
    // Adds Driver
    addNewDriver = (e) => {
        var testDriver = this.state;
        if ((e.target.value) === "SAVE") {
            var check = AddDriverValidation({ infoFields: testDriver, ageMet: this.state.ageMet, handleValidationMsg: this.handleValidationMsg, handleShow: this.handleShow })
            if (check) {
                var showDriver = this.props.displayDrivers;
                // New Driver
                if (this.props.index > this.props.displayDrivers.length) {
                    showDriver.push(this.state);
                }
                // Editing Driver
                else {
                    showDriver[this.props.index] = this.state;
                }
                this.props.newDrivers(showDriver);
                storageData.setItem('newDrivers', JSON.stringify(this.props.displayDrivers))
                this.props.hideBtnFunc(e);
            }
        }
        else {
            this.props.hideBtnFunc(e);
        }
    };
    componentDidUpdate(prevProps, prevState) {
        // Toggles College Question
        if (this.state.fullName && this.state.monthSelect && this.state.daySelect && this.state.yearSelect) {
            if (prevState.yearSelect !== this.state.yearSelect) {
                var newDateFormat = `${this.state.monthSelect}/${this.state.daySelect}/${this.state.yearSelect}`
                this.setState({ DOB: newDateFormat })
                // Checks age for toggle
                var userDOB = new Date(this.state.monthSelect + '-' + this.state.daySelect + '-' + this.state.yearSelect)
                var ageDiff = Date.now() - userDOB
                var ageDate = new Date(ageDiff)
                var ageReq = Math.abs(ageDate.getUTCFullYear() - 1970)
                if (ageReq < 26) {
                    this.setState({ ageMet: ageReq })
                }
                else {
                    this.setState({ ageMet: 0 })
                }
            }
        }
    }

    componentDidMount() {
        // Retrieve user information
        var retrievedUserData = storageData.getItem("userData");
        if (retrievedUserData) {
            retrievedUserData = JSON.parse(retrievedUserData)
            this.setState({ userData: retrievedUserData })
        }
    }
    render() {
        const {
            fullName,
            occupation,
            monthSelect,
            daySelect,
            yearSelect,
            mStatus,
            gender,
            currRel,
            sameHH,
            disorderCondition,
            mentalPhysical,
            ageLicensed,
            address,
            city,
            state,
            zip,
            user,
            dLicense,
            licenseState,
            licenseNum,
            licenseStatus,
            inCollege,
            schoolName,
            schoolCity,
            schoolState,
            vehicleSchool,
            over100,
            michiganLicenseState,
            michiganLicenseNum,
            michiganLicenseDate,
            dateMoveIn,
            michiganDriverAddedPurpose,
            vehiclesAtNewAddress,
            vehiclesAtSchool,
            healthInsurance,
            healthInsuranceType
        } = this.state;

        return (
            <>
                <div className="slide-toggle edit-section">
                    <div className="form-group form-group-xs">
                        <h6 className="text-center household-text-blue less-margin fw-bold">
                            NEW DRIVER
                        </h6>
                    </div>
                    <FullName
                        handleCallBack={this.handleCallback}
                        fullName={fullName} />
                    <DateOfBirth
                        handleCallBack={this.handleCallback}
                        monthSelect={monthSelect}
                        daySelect={daySelect}
                        yearSelect={yearSelect}
                        person={"driver"} />
                    <MaritalStatus
                        handleCallBack={this.handleCallback}
                        mStatus={mStatus} />
                    <Gender
                        handleCallBack={this.handleCallback}
                        gender={gender}
                        userData={this.state.userData} />
                    <SameHousehold
                        handleCallBack={this.handleCallback}
                        sameHH={sameHH}
                        address={address}
                        city={city}
                        state={state}
                        zip={zip}
                        dateMoveIn={dateMoveIn}
                        vehiclesAtNewAddress={vehiclesAtNewAddress}
                    />
                    <Occupation
                        handleCallBack={this.handleCallback}
                        occupation={occupation} />
                    <ValidLicense
                        handleCallBack={this.handleCallback}
                        user={user}
                        userData={this.state.userData}
                        dLicense={dLicense}
                        licenseState={licenseState}
                        licenseNum={licenseNum}
                        licenseStatus={licenseStatus}
                        handleShow={this.handleShow}
                        handleValidationMsg={this.handleValidationMsg} />
                    {dLicense === 1 && <MichiganLicenseQuest
                        handleCallBack={this.handleCallback}
                        michiganLicenseState={michiganLicenseState}
                        licenseNum={licenseNum}
                        ageLicensed={ageLicensed}
                        user={user}
                        userData={this.state.userData} />
                    }
                    <DisorderConditionQuest
                        handleCallBack={this.handleCallback}
                        disorderCondition={disorderCondition} />
                    <MentalPhysicalQuest
                        handleCallBack={this.handleCallback}
                        mentalPhysical={mentalPhysical} />
                    <College
                        handleCallBack={this.handleCallback}
                        inCollege={inCollege}
                        schoolName={schoolName}
                        schoolCity={schoolCity}
                        schoolState={schoolState}
                        vehicleSchool={vehicleSchool}
                        over100={over100}
                        vehiclesAtSchool={vehiclesAtSchool}
                    />
                    <CurrentRelationship
                        handleCallBack={this.handleCallback}
                        currRel={currRel}
                        pipOption={this.props.userData.pipOption}
                        healthInsurance={healthInsurance}
                        healthInsuranceType={healthInsuranceType}
                    />
                    <div className="text-right" onClick={this.addNewDriver}>
                        <hr className="less-margin" />
                        <input type={"button"} className="btn btn-default btn-sm" value={"CANCEL"} />
                        <input type={"button"} className="btn btn-primary-grad btn-sm ms-1" value={"SAVE"} />
                    </div>
                    <ValidationModal
                        show={this.state.show}
                        handleClose={this.handleClose}
                        validationMsg={this.state.validationMsg}
                        title={this.state.validationTitle} />
                </div>
            </>
        )
    }
}
export default AddNewDriver