export default {
    householdChangeID: "",
    policyUserId: "",
    policyNumber: "",
    effectiveDate: "",
    expirationDate: "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    emailAddress: "",
    policyState: "",
    policyAddressFields: {},
    vehicles: {
        vehiclesOnPolicy: {
            pendingNewVehicles: [],
            pendingRemovedVehicles: [],
            storedVehicles: [],
            newVehicles: []
        }
    },
    driversHHMs: {
        dateOfChange: "",
        drivers: {
            pendingNewDrivers: [],
            pendingRemovedDrivers: [],
            storedDrivers: [],
            newDrivers: []
        },
        householdMembers: {
            pendingNewMembers: [],
            pendingRemovedMembers: [],
            storedMembers: [],
            newMembers: []
        },
        pendingAddedDrivers: [],
        pendingAddedHhms: [],
        pendingRemovedDrivers: [],
        pendingRemovedHhms: []
    },
    driverAssignment: {
        drivers: {
            fullName: "",
            DOB: "",
            currRel: "",
            gender: "",
            initialType: "",
            licenseNum: "",
            mStatus: "",
            drivesVehicle: "",
            vehicleID: "",
            vehicleUse: "",
            vehicleUseExplanation: "",
            annualMileage: ""
        },
    },
    additionalPage: {
        suspended: "",
        suspendedDrivers: [],
        seizures: "",
        seizuresDrivers: [],
        narcolepsy: "",
        narcolepsyDrivers: [],
        syncope: "",
        syncopeDrivers: [],
        blindness: "",
        blindnessDrivers: [],
        impairments: "",
        impairmentsDrivers: [],
        keptOvernight: {
            response: "",
            address: "",
            city: "",
            state: "",
            zip: ""
        }
    },
    summary: {
        phoneNumber: ""
    },
    confirmation: {
        date: ""
    }
}