import React, { useContext } from "react"
import VehicleDA_Directions from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_Directions"
import VehicleDA_SelectVehicle from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_SelectVehicle"
import VehicleDA_DriverPolicy from "components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_DriverPolicy"
import ValidationModal from "components/ValidationModal"
import { AddressChangeContext } from "../AddressChangeContext"
import AddressChangeBtnNavigation from "../AddressChangeBtnNavigation"

export const AddressDA_StylesContext = React.createContext()

const AddressDA_Main = () => {
    const context = useContext(AddressChangeContext)

    const styles = {
        indent: {
            paddingLeft: "15px",
        },
        blueText: {
            color: "#046ab0",
            fontSize: '13.5px'
        }
    }
    
    return (
        <>
            <AddressDA_StylesContext.Provider
                value={{ styles }}>
                <div className="row text-muted bg-block">
                    <div className="col-12">
                        <VehicleDA_Directions />
                        <VehicleDA_SelectVehicle changeReqContext={AddressChangeContext} stylesContext={AddressDA_StylesContext} reqChangeClass={"addressChangeClass"} setChangeClass={context.setAddressChangeClass} />
                        <VehicleDA_DriverPolicy />
                    </div>
                </div>
            </AddressDA_StylesContext.Provider>

            <AddressChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default AddressDA_Main