const Additional_YesNoUnknownResponses = ({ type, setResponse, setStatusToDriver, changeClass }) => {
    var storedDrivers = changeClass?.driversHHMs?.drivers?.storedDrivers
    var newDrivers = changeClass?.driversHHMs?.drivers?.newDrivers

    return (
        <form>
            <div className="form-group form-group-sm text-dark ms-3">
                <div className="radio-inline form-check form-check-inline">
                    <label className="form-check-label">
                        <input type={"radio"} className="form-check-input" name={type} value={"Yes"}
                            checked={changeClass?.additionalPage[type] == "Yes"}
                            onChange={(e) => { setResponse(type, e.target.value) }}
                        />Yes
                    </label>
                </div>
                <div className="radio-inline form-check form-check-inline">
                    <label className="form-check-label">
                        <input type={"radio"} className="form-check-input" name={type} value={"No"}
                            checked={changeClass?.additionalPage[type] == "No"}
                            onChange={(e) => { setResponse(type, e.target.value) }}
                        />No
                    </label>
                </div>
                <div className="radio-inline form-check form-check-inline">
                    <label className="form-check-label">
                        <input type={"radio"} className="form-check-input" name={type} value={"Unknown"}
                            checked={changeClass?.additionalPage[type] == "Unknown"}
                            onChange={(e) => { setResponse(type, e.target.value) }}
                        />Unknown
                    </label>
                </div>
            </div>

            {/* Display which drivers */}
            <div className={`slide-up ${(changeClass?.additionalPage[type] === "Yes" || changeClass?.additionalPage[type] === "Unknown") ? 'slide-up-enter' : 'slide-up-exit'}`}>
                {(changeClass?.additionalPage[type] === "Yes" || changeClass?.additionalPage[type] === "Unknown") &&
                    <div className="slide-toggle ms-5">
                        <div className="form-group-sm fw-bold mb-1">
                            <small>Which drivers?</small>
                        </div>
                        {/* Stored Drivers */}
                        {storedDrivers.map((driver, index) => {
                            return (
                                <div className="mx-3 mt-1" key={index}>
                                    <label className="text-uppercase">
                                        <input type={"checkbox"} style={{ verticalAlign: 'middle' }}
                                            checked={type in driver ? driver[type] : false}
                                            onChange={(e) => setStatusToDriver("stored", index, type, e.target.checked)}
                                        />
                                        <span className="ms-2">
                                            {driver.fullName}
                                        </span>
                                    </label>

                                    {/* Only display for impairments and if driver is selected */}
                                    <div className={`slide-up ${(type == "impairments" && driver["impairments"]) ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {type == "impairments" && driver["impairments"] &&
                                            <div>
                                                <input type={"text"} className="form-control row m-1 mx-3" placeholder="Please explain"
                                                    value={driver?.impairmentExplanation ? driver?.impairmentExplanation : ""}
                                                    onChange={(e) => { setStatusToDriver("stored", index, "impairmentExplanation", e.target.value) }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {/* New Drivers */}
                        {newDrivers.map((driver, index) => {
                            return (
                                <div className="mx-3 mt-1" key={index}>
                                    <label className="text-uppercase">
                                        <input type={"checkbox"} style={{ verticalAlign: 'middle' }}
                                            checked={type in driver ? driver[type] : false}
                                            onChange={(e) => setStatusToDriver("new", index, type, e.target.checked)}
                                        />
                                        <span className="ms-2">
                                            {driver.fullName}
                                        </span>
                                    </label>

                                    {/* Only display for impairments and if driver is selected */}
                                    <div className={`slide-up ${(type == "impairments" && driver["impairments"]) ? 'slide-up-enter' : 'slide-up-exit'}`}>
                                        {type == "impairments" && driver["impairments"] &&
                                            <div>
                                                <input type={"text"} className="form-control row m-1 mx-3" placeholder="Please explain"
                                                    value={driver?.impairmentExplanation ? driver?.impairmentExplanation : ""}
                                                    onChange={(e) => { setStatusToDriver("new", index, "impairmentExplanation", e.target.value) }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        <div className="row">
                            <small>
                                <em className="text-danger">
                                    <br />
                                    (This may require follow-up from our Underwriting Department)
                                </em>
                            </small>
                        </div>
                    </div>
                }
            </div>
        </form>
    )
}

export default Additional_YesNoUnknownResponses