import { useEffect, useState } from "react"
import "../../../css/HeaderRQ.css"
import { storageData } from "../../../services/Services"
import AddAdditionalHouseholdMember from "./AddAdditionalHouseholdMember"
import EditHouseholdMember from "./EditHouseholdMember"

const HHM_RQ = (props) => {
    const [addHouseholdMemberMenu, setAddHouseholdMemberMenu] = useState(false)
    const [editExistingHouseholdMemberMenu, setEditExistingHouseholdMemberMenu] = useState([])
    const [editNewHouseholdMemberMenu, setNewHouseholdMemberMenu] = useState([])

    // Stored Household Member Edit
    const editStoredHouseholdMemberMenu = (member, index, action) => {
        if (action == "cancel") {
            var sessionDataCopy = props.sessionData
            delete member["editType"]
            delete member["editReason"]
            delete member["displayChange"]
            delete member["stateLicenseNumber"]
            sessionDataCopy.household.householdMembers.storedHouseholdMembers[index] = member
            props.setSessionData({ ...sessionDataCopy });
            storageData.setItem('sessionDataRQ', JSON.stringify(sessionDataCopy))
        }
        else {
            var editArr = props.sessionData.household.householdMembers.storedHouseholdMembers.map((value, i) => {
                return (index === i) ? true : false
            });
            setEditExistingHouseholdMemberMenu(editArr)
        }
    }

    // Added Household Member Edit
    const editAddHouseholdMemberMenu = (member, index, action) => {
        // Remove added household member
        if (action == "cancel") {
            var sessionDataCopy = props.sessionData
            var removedNewHouseholdMembers = sessionDataCopy.household.householdMembers.newHouseholdMembers
            removedNewHouseholdMembers = removedNewHouseholdMembers.filter(filteredMember =>
                filteredMember !== member
            );
            sessionDataCopy.household.householdMembers.newHouseholdMembers = removedNewHouseholdMembers
            props.setSessionData({ ...sessionDataCopy });
            storageData.setItem('sessionDataRQ', JSON.stringify(sessionDataCopy))
        }
        // Displays added household member form for edit
        else {
            var editArr = props.sessionData.household.householdMembers.newHouseholdMembers.map((value, i) => {
                return (index === i) ? true : false
            });
            setNewHouseholdMemberMenu(editArr)
        }
    }

    return (
        <>
            <div className="mt-4">
                <div className="sidebar-block">
                    <h5>Other Household Members</h5>

                    <div className="sidebar-block-inn">
                        <p className="driversTitle">
                            Our records indicate these are the people that live at the policy address (including children even if away at school):
                        </p>

                        {/* Stored Household Members */}
                        <div className="row">
                            {props?.sessionData?.household?.householdMembers?.storedHouseholdMembers.map((member, id) => {
                                return (
                                    <div key={id} className="name-plate">
                                        {("displayChange" in member) ?
                                            <>
                                                <h4 className="drivers ms-2 text-uppercase" style={{ color: '#585959', fontSize: '15px' }}>
                                                    {member.fullName}, {member.DOB} - {member.displayChange}
                                                </h4>

                                                {!addHouseholdMemberMenu && editExistingHouseholdMemberMenu.length == 0 && editNewHouseholdMemberMenu.length == 0 &&
                                                    <div className="fakeButton">
                                                        <a className="mx-1" onClick={() => editStoredHouseholdMemberMenu(member, id, "edit")}>[Edit]</a>
                                                        <a className="mx-1" onClick={() => editStoredHouseholdMemberMenu(member, id, "cancel")}>[Cancel]</a>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <h3 className="drivers ms-2 text-uppercase" style={{ color: '#046ab0' }}>
                                                    {member.fullName}, {member.DOB}
                                                </h3>
                                                {!addHouseholdMemberMenu && editExistingHouseholdMemberMenu.length == 0 && editNewHouseholdMemberMenu.length == 0 &&
                                                    <div className="fakeButton">
                                                        <a className="mx-1" onClick={() => editStoredHouseholdMemberMenu(member, id, "edit")}>[Edit]</a>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <br /><br />

                                        {/* Edit Stored/Existing Household Member */}
                                        {editExistingHouseholdMemberMenu[id] &&
                                            <EditHouseholdMember
                                                sessionData={props.sessionData}
                                                setSessionData={props.setSessionData}
                                                storedHouseholdMembers={props.sessionData.household.householdMembers.storedHouseholdMembers}
                                                currHouseholdMember={member}
                                                setShow={props.setShow}
                                                setValidationMsg={props.setValidationMsg}
                                                handleClose={props.handleClose}
                                                handleShow={props.handleShow}
                                                setValidationLoad={props.setValidationLoad}
                                                setValidationTitle={props.setValidationTitle}
                                                index={id}
                                                setEditExistingHouseholdMemberMenu={setEditExistingHouseholdMemberMenu}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {/* New Household Members */}
                        <div className="row">
                            {props?.sessionData?.household?.householdMembers?.newHouseholdMembers.map((member, id) => {
                                return (
                                    <div key={id} className="name-plate">
                                        <h3 className="drivers ms-2" style={{ color: '#678062' }}>
                                            <span className="text-uppercase">{member.fullName}, {member.DOB}</span> - New Resident
                                        </h3>

                                        {!addHouseholdMemberMenu && editExistingHouseholdMemberMenu.length == 0 && editNewHouseholdMemberMenu.length == 0 &&
                                            <div className="fakeButton">
                                                <a className="mx-1" onClick={() => editAddHouseholdMemberMenu(member, id, "edit")}>[Edit]</a>
                                                <a className="mx-1" onClick={() => editAddHouseholdMemberMenu(member, id, "cancel")}>[Cancel]</a>
                                            </div>
                                        }
                                        <br />
                                        {/* Edit New Household Member */}
                                        {editNewHouseholdMemberMenu[id] &&
                                            <AddAdditionalHouseholdMember
                                                userData={props.userData}
                                                sessionData={props.sessionData}
                                                setSessionData={props.setSessionData}
                                                newHouseholdMembers={props.sessionData.household.householdMembers.newHouseholdMembers}
                                                setShow={props.setShow}
                                                setValidationMsg={props.setValidationMsg}
                                                handleClose={props.handleClose}
                                                handleShow={props.handleShow}
                                                setValidationLoad={props.setValidationLoad}
                                                setValidationTitle={props.setValidationTitle}
                                                setAddHouseholdMemberMenu={setAddHouseholdMemberMenu}
                                                setNewHouseholdMemberMenu={setNewHouseholdMemberMenu}
                                                index={id}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {/* Add Additional Household Member */}
                        {addHouseholdMemberMenu && editExistingHouseholdMemberMenu.length == 0 &&
                            <AddAdditionalHouseholdMember
                                userData={props.userData}
                                sessionData={props.sessionData}
                                setSessionData={props.setSessionData}
                                newHouseholdMembers={props.sessionData.household.householdMembers.newHouseholdMembers}
                                setAddHouseholdMemberMenu={setAddHouseholdMemberMenu}
                                setShow={props.setShow}
                                setValidationMsg={props.setValidationMsg}
                                handleClose={props.handleClose}
                                handleShow={props.handleShow}
                                setValidationLoad={props.setValidationLoad}
                                setValidationTitle={props.setValidationTitle}
                                index={props.sessionData.household.householdMembers.newHouseholdMembers.length}
                            />
                        }

                        {!addHouseholdMemberMenu && editExistingHouseholdMemberMenu.length == 0 && editNewHouseholdMemberMenu.length == 0 &&

                            <div className="p-2">
                                <input type="button" value={'Add Additional Household Member'} className="additionalMember" onClick={setAddHouseholdMemberMenu} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default HHM_RQ