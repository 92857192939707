import React from "react";

class InsuredRelationship extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div className="row form-group form-group-sm align-items-center">
                    <div className="col-xl-4 col-lg-12 fw-bold text-blue">
                        Relationship to Insured:*
                    </div>
                    <div className="col-xl-6 col-lg-12">
                        <select className="btn btn-default btn-group dropdown-toggle filter-option dropdownSize"
                            defaultValue={this.props.insuredRelationship || "default"}
                            onChange={(e) => { this.props.handleCallBack("insuredRelationship", e.target.value) }}>
                            <option disabled value={"default"}>
                                Select from the following...
                            </option>
                            {["Spouse", "Child", "Parent", "Other Relative", "Other"].map((val, id) => (
                                <option key={id} value={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }
}

export default InsuredRelationship