import React, { useContext, useEffect } from 'react'
import VehicleDA_Directions from './VehicleDA_Directions'
import VehicleDA_SelectVehicle from './VehicleDA_SelectVehicle'
import ValidationModal from 'components/ValidationModal'
import { AddVehicleContext } from 'components/StraightThroughVehicleComp/AddVehicleContext/AddVehicleContext'
//import { VehicleDA_StylesContext } from 'components/ChangeRequest_VehicleChangeComp/2_DriverAssignment/VehicleDA_Main'

const VehicleDA = () => {
  const context = useContext(AddVehicleContext)

  const styles = {
    indent: {
      paddingLeft: "15px",
    },
    blueText: {
      color: "#046ab0",
      fontSize: '13.5px'
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="row text-muted">
        <div className="col-12">
          <VehicleDA_Directions />
          <VehicleDA_SelectVehicle addVehReqContext={AddVehicleContext} reqAddVehicleClass={"addVehicleClass"} setAddVehicleClass={context.setAddVehicleClass} />
        </div>
      </div>

      <ValidationModal
        title={context.validationTitle}
        show={context.showModal}
        handleClose={() => context.setShowModal(false)}
        validationMsg={context.validationMsg}
      />
    </>
  )
}

export default VehicleDA