import React, { useContext } from 'react';
import { AddVehicleContext } from '../AddVehicleContext/AddVehicleContext';

const OverviewSection = () => {
  const { addVehicleClass } = useContext(AddVehicleContext);

  return (
    <div className='row justify-content-center my-3' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      <div className='col-12 text-center'>
        <h4 className='fw-bold text-primary' style={{ display: 'flex' }}>Overview</h4>
        <p className='fw-bold m-0' style={{ display: 'flex', paddingBottom: '10px' }}>
          The following vehicle change(s) have been requested:
        </p>
        {addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles.map((vehicle, index) => (
          <p key={index} className='m-0' style={{ display: 'flex', paddingLeft: '7%' }}>
            <span className='ms-1 text-primary fw-bold'>
              {vehicle.vehicleYear} {vehicle.vehicleMake} {vehicle.vehicleModel}
            </span>
            <span style={{ color: 'grey' }}> - New Vehicle</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default OverviewSection;