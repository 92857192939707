import { useContext } from "react"
import { VehicleDA_SaveContext } from "../VehicleDA_SelectVehicle"

const VehicleDA_VehiclePerDriver = ({ vehicles }) => {
    const da_saveContext = useContext(VehicleDA_SaveContext)
    var drivers = da_saveContext?.drivers
    var driverIndex = da_saveContext?.driverIndex

    // Checks selected vehicles for other drivers to disable radio button
    const checkVehicleSelected = (vehicle) => {
        for (var i = 0; i < drivers.length; i++) {
            if (vehicle == drivers[i]?.vehicle) {
                return true
            }
        }
        return false
    }
    return (
        <>
            {vehicles?.length > 0 &&
                <>
                    {/* Vehicle selection for each driver */}
                    {vehicles?.map((vehicle, index) => {
                        var vehicleName = ("vehicleMake" in vehicle) ?
                            vehicle?.vehicleYear + " " + vehicle?.vehicleMake + " " + vehicle?.vehicleModel :
                            vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model
                        return (
                            <div key={index}>
                                <div className="radio m-1">
                                    <label className="form-check-label text-uppercase">
                                        <input className="form-check-input" name={driverIndex} type={"radio"}
                                            value={vehicle}
                                            onChange={() => { da_saveContext.saveVehicleToDriver(driverIndex, vehicle) }}
                                            checked={drivers[driverIndex]?.vehicle == vehicleName}
                                            disabled={checkVehicleSelected(vehicleName)}
                                        />
                                        {"vehicleYear" in vehicle ?
                                            <span className="m-2">{vehicle.vehicleYear} {vehicle.vehicleMake} {vehicle.vehicleModel} - {vehicle.vehicleVIN}</span>
                                            :
                                            <span className="m-2">{vehicle.Year} {vehicle.Make} {vehicle.Model} - {vehicle.VIN}</span>
                                        }
                                    </label>
                                </div>
                            </div>
                        )
                    })}
                    {/* Not a Primary Driver on Any Car */}
                    {drivers.length > vehicles.length &&
                        <div className="radio m-1">
                            <label className="form-check-label text-uppercase">
                                <input className="form-check-input" name={driverIndex} type={"radio"}
                                    value={"NotPrimaryDriver"}
                                    onChange={(e) => { da_saveContext.saveVehicleToDriver(driverIndex, e.target.value) }}
                                    checked={drivers[driverIndex]?.vehicle == "NotPrimaryDriver"}
                                />
                                <span className="m-2">NOT A PRIMARY DRIVER ON ANY CAR</span>
                            </label>
                        </div>
                    }
                </>
            }
            {vehicles?.length === 0 &&
                <p>Found no vehicles</p>
            }
        </>
    )
}

export default VehicleDA_VehiclePerDriver