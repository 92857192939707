import React, { useContext, useRef, useState } from "react"
import { Spinner } from 'react-bootstrap';
import { VehicleChangeContext } from "../../VehicleChangeContext"
import { StoredAddedVehicles } from "../VehiclesList"
import { storageData } from "../../../../services/Services";
import VehicleAddMI_1 from "./VehicleAddMI_1";
import VehicleAddMI_2 from "./VehicleAddMI_2";
import VehicleAddMI_3 from "./VehicleAddMI_3";
import { vinLookup } from "../../../../services/Api";
import VehicleValidation_MI from "../VehicleValidation_MI";

export const AddVehicleContext = React.createContext()

const VehicleAddMainMI = ({ index, type, saveEditOption }) => {
    const context = useContext(VehicleChangeContext)
    const storedAddedContext = useContext(StoredAddedVehicles)
    const vehiclesOnPolicy = context.vehicleChangeClass.vehicles.vehiclesOnPolicy
    const vinRef = useRef(null)

    // Gets the correct list of vehicles
    const getAllVehicles = () => {
        if (type == "add" || type == "edit") { return vehiclesOnPolicy.newVehicles } // New vehicles
        else { return vehiclesOnPolicy.storedVehicles } // Stored vehicles
    }
    const allVehicles = getAllVehicles()

    // Fields for adding a vehicle
    const newVehicleDetails = {
        vehicleVIN: "",
        vehicleYear: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleBody: "",
        requestedEffDt: storedAddedContext.getTodaysDate(true),
        dateObtained: storedAddedContext.getTodaysDate(),
        otherThanCollisionToggle: "",
        vehicleOwnedLeasedFinanced: "0",
        coOwned: "",
        coOwnerName: "",
        coOwnerAddress: "",
        coOwnerCity: "",
        coOwnerState: "MI",
        coOwnerZip: "",
        nameRegistered: "0",
        registrantName: "",
        registrantAddress: "",
        registrantCity: "",
        registrantState: "MI",
        registrantZip: "",
        stateRegistered: "MI",
        vehicleLeasingCompany: "",
        standardDeductibleComprehensive: "",
        rentalReimbursement: "",
        standardDeductibleCollision: "",
        vehicleFinanceCompany: "",
        collisionCoverageToggle: "",
        collisionCoverageType: "",
        coLeased: "",
        coLeaseeName: "",
        coLeaseeAddress: "",
        coLeaseeCity: "",
        coLeaseeState: "MI",
        coLeaseeZip: ""
    }

// State for a new/newly added vehicle
const [newVehicle, setNewVehicle] = useState(
    (index >= allVehicles.length) ? newVehicleDetails : allVehicles[index]
)

// Resolves issue when having to conjoin the Remove and Add forms
if (type == "replace" || type == "replaceEdit") {
    if (JSON.stringify(newVehicle) != JSON.stringify(allVehicles[index])) {
        setNewVehicle(allVehicles[index])
    }
}

// Saves values for adding vehicle
const saveAddition = (key, value) => {
    var newVehicleCopy = newVehicle
    newVehicleCopy[key] = value
    
    // Reset the "collision" coverage deductible when "collision" coverage changes
    if (key == "collisionCoverageType") {
        newVehicleCopy["standardDeductibleCollision"] = ""
    }

    setNewVehicle({ ...newVehicleCopy })

    // Replacing vehicles adjusts the stored vehicles list
    if (type == "replace" || type == "replaceEdit") {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehiclesCopyList = vehiclesOnPolicy.storedVehicles
        vehiclesCopyList[index] = newVehicleCopy
        vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesCopyList
        context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
    }
}

// Cancel Menu
const cancelMenu = () => {
    var vehChangeClass = JSON.parse(storageData.getItem("VehicleChangeClass"))

    if (type == "replace" || type == "replaceEdit") {
        var vehicleListCopy = vehChangeClass?.vehicles?.vehiclesOnPolicy?.storedVehicles
        allVehicles[index] = { ...vehicleListCopy[index] }
        saveEditOption()
    }
    else if (type == "edit") {
        var vehicleListCopy = vehChangeClass?.vehicles?.vehiclesOnPolicy?.newVehicles
        allVehicles[index] = { ...vehicleListCopy[index] }
        storedAddedContext.setEditAddedVehicleArr([])
        context.setShowQuestions(true)
    }
    else {
        context.setAddVehicleMenu(false)
    }
    storedAddedContext.setVehicleObtained(false)
}

// Adds vehicle to list
const submitNewVehicle = async () => {
    // Validation check
    var { title, message, valid } = VehicleValidation_MI({ type, vehicle: newVehicle })
    if (!valid) {
        context.setValidationMsgToggle(title, message)
        return
    }
    if (await checkVin(newVehicle?.vehicleVIN)) {
        var vehicleChangeClassCopy = context.vehicleChangeClass
        var vehiclesNewCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles
        var vehiclesStoredCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles

        // Adding new vehicle
        if (type == "add") {
            vehiclesNewCopyList.push(newVehicle)
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles = vehiclesNewCopyList
        }
        // Editing a newly added vehicle
        else if (type == "edit") {
            vehiclesNewCopyList[index] = newVehicle
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.newVehicles = vehiclesNewCopyList
            storedAddedContext.setEditAddedVehicleArr([])
        }
        // Replacing stored vehicle with newly added
        else if (type == "replace" || type == "replaceEdit") {
            if (type == "replace") {
                newVehicle["type"] = type
            }
            vehiclesStoredCopyList[index] = newVehicle
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesStoredCopyList
            storedAddedContext.saveEditOption()
        }
    }
    else {
        context.setValidationMsgToggle("Oops", "We are unable to verify the VIN that you entered. Please try again. If you continue to run into the same error, please call 800-535-2873 to proceed with your request.")
        return false
    }

    storageData.setItem("VehicleChangeClass", JSON.stringify(context.vehicleChangeClass))
    context.setVehicleChangeClass(vehicleChangeClassCopy)
    storedAddedContext.submittedVehicleChanges()
}

// Generates vehicle from vin
const checkVin = async (vin) => {
    context.setVinLoad(true)
    var vinResponse = await vinLookup(vin)

    // Successful vin input
    if (vinResponse?.data?.error == null && vinResponse != undefined && vinResponse?.data?.isEmpty != "1") {
        var vehicle = newVehicle
        vehicle.vehicleVIN = vinResponse.data?.Vin
        vehicle.vehicleYear = vinResponse.data?.Year
        vehicle.vehicleMake = vinResponse.data?.Make
        vehicle.vehicleModel = vinResponse.data?.Model
        vehicle.vehicleBody = vinResponse.data?.Body
        setNewVehicle({ ...vehicle })
        storedAddedContext.setVehicleObtained(true)

        if (type == "replace") {
            var vehicleChangeClassCopy = context.vehicleChangeClass
            var vehiclesCopyList = vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles
            vehiclesCopyList[index] = { ...newVehicleDetails, ...vehicle }
            vehicleChangeClassCopy.vehicles.vehiclesOnPolicy.storedVehicles = vehiclesCopyList
            context.setVehicleChangeClass({ ...vehicleChangeClassCopy })
        }
    }
    else {
        context.setValidationMsgToggle("Oops", "We are unable to verify the VIN that you entered. Please try again. If you continue to run into the same error, please call 800-535-2873 to proceed with your request.")
        context.setVinLoad(false)
        return false
    }
    context.setVinLoad(false)
    return true
}

return (
    <>
        {/* Title */}
        <div className="text-center text-primary mt-4">
            <strong>ADD A VEHICLE</strong>
        </div>
        <hr className="less-margin" />

        {/* All components for adding a vehicle */}
        <AddVehicleContext.Provider
            value={{
                newVehicle, setNewVehicle,
                newVehicleDetails, saveAddition,
                index, type,
                vinRef, checkVin
            }}>
            <VehicleAddMI_1 />
            <VehicleAddMI_2 />
            <VehicleAddMI_3 />
        </AddVehicleContext.Provider>

        {/* Spinner for retrieving vehicle info from vin */}
        {context.vinLoad &&
            <div className="text-center">
                <Spinner animation="border" />
            </div>
        }

        {/* Save/Cancel button for add vehicle menu */}
        {(context.addVehicleMenu || type == "replace" || type == "replaceEdit" || type == "edit") &&
            <div className={`text-right ${!context.showQuestions ? "mb-3" : "my-3"}`}>
                <input type={"button"} className="btn btn-default" value={"CANCEL"} onClick={() => cancelMenu()} />
                <input type={"button"} className="btn btn-primary-grad ms-1" value={"SAVE"} onClick={() => submitNewVehicle()} />
            </div>
        }
    </>
)
}

export default VehicleAddMainMI