import Images from 'assets/images/Images'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./DisclosureAgreement.css"
import { storageData } from 'services/Services'

const DisclosureAgreement = (props) => {
    const navigateBack = () => {
        storageData.removeItem("updateVehicleData");
        storageData.removeItem("updateDriverAssignmentData");
        storageData.removeItem("newVehicles");
        storageData.removeItem("dbVehicles");
        storageData.removeItem("vehicleSummaryChanges");
        storageData.removeItem("VehicleChangeConfirmationNumber");
        storageData.removeItem("storedDrivers");
        storageData.removeItem("storedHouseholdMembers");
        storageData.removeItem("storedVehicles");
        storageData.removeItem("PendingAddedVehicles");
        storageData.removeItem("PendingRemovedVehicles");
        storageData.removeItem("PendingAddedDrivers");
        storageData.removeItem("PendingAddedHhms");
        storageData.removeItem("PendingRemovedDrivers");
        storageData.removeItem("PendingRemovedHhms");
        storageData.removeItem("PendingAddress");
        storageData.removeItem("vehicleInfo");
        storageData.removeItem("currResidence");
        storageData.removeItem("AddVehicleClass");
        storageData.removeItem("responseData");
        window.location = "/home"
    }
    return (
        <>
            <Modal className='modal-spacing' show={props.showModal} onHide={() => { navigateBack(); props.handleClose(); }}>
                <Modal.Header closeButton style={{ backgroundColor: "#366898" }}>
                    <Modal.Title className='text-light' style={{ fontSize: "16px" }}>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='small'>
                    <ul>
                        <li>
                            Important note: If your option is not available online for this change, please call 800-535-2873 to discuss your change request with a CURE representative.
                        </li>
                        <br />
                        <li>
                            New vehicle coverage can be selected for next-day effective date.
                        </li>
                        <br />
                        <li>
                            Vehicles must be registered to you and/or your resident spouse to be eligible for coverage on this policy.
                        </li>
                        <br />
                        <li>
                            Vehicles must be registered in the state of {props.policyState} within 30 days from date of purchase.
                        </li>
                    </ul>
                </Modal.Body>
                <div className="d-flex justify-content-end">
                    {
                        <img src={Images.halfRoundy} alt="Roundy" />
                    }
                </div>
                <Modal.Footer>
                    <Button className='btn-decline' onClick={navigateBack}>
                        DECLINE
                    </Button>
                    <Button className='btn btn-primary-grad' onClick={props.handleClose}>
                        I AGREE
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DisclosureAgreement