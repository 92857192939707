import { useContext } from "react"

const VehicleSelections = ({ type, typeOfVehicle, typeOfVehicleVINs, setVehicleToDriver, changeReqContext, reqChangeClass }) => {
    const context = useContext(changeReqContext)
    var vehicles = context[reqChangeClass]?.vehicles?.vehiclesOnPolicy?.storedVehicles

    return (
        <div className="form-group form-group-sm">
            {type == "residence" &&
                <strong className="text-blue">
                    Will any of the vehicles on the policy be kept at this address?*
                </strong>
            }
            {type == "vehiclesTaken" &&
                <strong className="text-blue">
                    Please confirm which vehicles are being taken.*
                </strong>
            }

            {vehicles.map((vehicle, id) => {
                return (
                    <div key={id} className="mx-3 mt-1" style={checkBoxText}>
                        <label className="mb-2">
                            <input type="checkbox" style={{ verticalAlign: 'middle' }}
                                checked={(typeOfVehicleVINs?.includes(vehicle?.VIN))}
                                value={(typeOfVehicleVINs?.includes(vehicle?.VIN))}
                                onChange={(e) => setVehicleToDriver(type, typeOfVehicle, typeOfVehicleVINs, vehicle, e.target.checked)}
                            />
                            <span className="ms-3">{vehicle?.Year + " " + vehicle?.Make + " " + vehicle?.Model}</span>
                        </label>
                    </div>
                )
            })}

            {/* None of the above option (For different address ONLY) */}
            {type == "residence" &&
                <div className="mx-3 mt-1" style={checkBoxText}>
                    <label className="mb-2">
                        <input type="checkbox" style={{ verticalAlign: 'middle' }}
                            checked={(typeOfVehicle?.includes("None of the above"))}
                            value={(typeOfVehicle?.includes("None of the above"))}
                            onChange={(e) => setVehicleToDriver(type, typeOfVehicle, typeOfVehicleVINs, "None of the above", e.target.checked)}
                        />
                        <span className="ms-3">None of the above</span>
                    </label>
                </div>
            }
        </div>
    )
}

const checkBoxText = {
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '12px'
}

export default VehicleSelections