import React, { useContext, useState } from "react";
import './AddressChange.css';
import DatePick from "../../DatePicker";
import 'react-datepicker/dist/react-datepicker.css';
import { storageData } from "../../../services/Services";
import StateDropdown from "../../RenewalQuestionnaireComp/DriversOHM/StateDropdown";
import { AddressChangeContext } from "../AddressChangeContext";

const NewResidence_NJPA = () => {
  const context = useContext(AddressChangeContext)
  const [dateMoved, setDateMoved] = useState("")
  var addressChangeClass = context?.addressChangeClass

  // Values for form
  const [residenceData, setResidenceData] = useState({
    mailingAddress: "",
    mailingAddress2: "",
    mailingCity: "",
    mailingState: context?.getAbbrState(context?.userData?.state),
    mailingZip: "",
    dateMoved: "",
    vehiclesKept: "",
    vehicleLocation: "",
  })

  // Retrieves Form Values
  const storeValue = (key, value) => {
    var changeClassCopy = context?.addressChangeClass
    var info = { ...residenceData };
    info[key] = value;
    setResidenceData({ ...info })
    changeClassCopy.newResidence = { ...changeClassCopy.newResidence, ...info }
    context.setAddressChangeClass({ ...changeClassCopy })
  };


  return (
    <>
      <div className="d-flex align-content-center flex-column">
        <hr className="" />
        {/* Subtitle */}
        <h6 className="section-title">
          NEW RESIDENCE
        </h6>
        <form>
          <div className="row">

            {/* Column 1 */}
            <div className="col-sm-12 col-md-5">
              <div className="newAddress">

                {/* Street Address */}
                <div className="form-group mt-2 mb-2">
                  <input type="text" className="form-control col-md-2" placeholder="Street Address"
                    value={addressChangeClass?.newResidence?.mailingAddress ? addressChangeClass?.newResidence?.mailingAddress : residenceData?.mailingAddress}
                    onChange={(e) => { storeValue("mailingAddress", e.target.value) }} />
                </div>

                {/* Street Address 2 */}
                <div className="form-group mt-2 mb-2">
                  <input type="text" className="form-control" placeholder="Street Address Line 2"
                    value={addressChangeClass?.newResidence?.mailingAddress2 ? addressChangeClass?.newResidence?.mailingAddress2 : residenceData?.mailingAddress2}
                    onChange={(e) => { storeValue("mailingAddress2", e.target.value) }} />
                </div>
                <div className="row d-flex form-group mt-2 mb-2">

                  {/* City */}
                  <div className="col-7 col-sm-8">
                    <input type="text" className="form-control " placeholder="City"
                      value={addressChangeClass?.newResidence?.mailingCity ? addressChangeClass?.newResidence?.mailingCity : residenceData?.mailingCity}
                      onChange={(e) => { storeValue("mailingCity", e.target.value) }} />
                  </div>
                  <div className="col-5 col-sm-4">

                    {/* Zip */}
                    <input type="text" className="form-control " placeholder="Zip"
                      value={addressChangeClass?.newResidence?.mailingZip ? addressChangeClass?.newResidence?.mailingZip : residenceData?.mailingZip}
                      onChange={(e) => { storeValue("mailingZip", e.target.value) }} />
                  </div>
                </div>

                {/* State Dropdown */}
                <div className="form-group form-group-sm">
                  <div className="btn-group btn-default btn-stretch bootstrap-select custom-selector">
                    <select className="btn btn-default" style={{ textAlign: "left" }}
                      value={addressChangeClass?.newResidence?.mailingState ? addressChangeClass?.newResidence?.mailingState : residenceData?.mailingState}
                      onChange={(e) => { storeValue("mailingState", e.target.value) }}>
                      {StateDropdown()}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Column 2 */}
            <div className="col-sm-7">
              <div className="form-group form-group-sm">
                <strong className="text-blue">
                  When did/will you move to this new address?
                </strong>
              </div>
              <div className="col-12 col-sm-8 form-group p-0">
                <DatePick
                  dateSubmit={addressChangeClass?.newResidence?.dateMoved ? addressChangeClass?.newResidence?.dateMoved : residenceData?.dateMoved}
                  setDateSubmit={setDateMoved}
                  storeValue={storeValue}
                  nameValue={"dateMoved"}
                />
              </div>

              {/* Vehicle Kept Overnight */}
              <div className="form-group form-group-sm col-sm-12 col-md-10">
                <strong className="text-blue">
                  Will all of your vehicles be kept overnight at this new address?
                </strong>
              </div>
              <div className="form-group form-group-sm">
                <div className="radio-inline form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={"Yes"}
                      defaultChecked={(addressChangeClass?.newResidence?.vehiclesKept == "Yes") ? addressChangeClass?.newResidence?.vehiclesKept : ""}
                      onChange={(e) => { storeValue("vehiclesKept", e.target.value) }}
                    />
                    Yes
                  </label>
                </div>
                <div className="radio-inline form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" name="inlineRadioOptions" type={"radio"} value={"No"}
                      defaultChecked={(addressChangeClass?.newResidence?.vehiclesKept == "No") ? addressChangeClass?.newResidence?.vehiclesKept : ""}
                      onChange={(e) => { storeValue("vehiclesKept", e.target.value) }}
                    />
                    No
                  </label>
                </div>
              </div>

              {/* Vehicle(s) Location */}
              {(addressChangeClass?.newResidence?.vehiclesKept === "No") &&
                <div>
                  <div className="form-group form-group-sm">
                    <strong className="text-blue">
                      Please indicate where the vehicles are or will be kept:
                    </strong>
                  </div>
                  <div className="col-12 col-md-8 p-0">
                    <textarea className="form-control" style={{resize: 'none'}} rows={2}
                      value={addressChangeClass?.newResidence?.vehicleLocation ? addressChangeClass?.newResidence?.vehicleLocation : residenceData?.vehicleLocation}
                      onChange={(e) => { storeValue("vehicleLocation", e.target.value) }} 
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default NewResidence_NJPA