import React from "react";
import { states } from "../../";

class College extends React.Component {
    constructor(props) {
        super(props);
        this.state = states
    }
    render() {

        return (
            <>
                <div className="row form-group form-group-sm">
                    <div className="col-sm-12">
                        <hr className="less-margin" />
                    </div>
                    <div className="col-xl-8 text-blue fw-bold inputHeight">
                        Is this driver enrolled in a college or university?
                    </div>
                    <div className="col-xl-4 mt-2" onChange={(e) => { this.props.handleCallBack("inCollege", parseInt(e.target.value)) }}>
                        <div className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input className="form-check-input" name="College" type={"radio"} value={1}
                                    defaultChecked={this.props.inCollege === 1} />Yes
                            </label>
                        </div>
                        <div className="radio-inline form-check form-check-inline">
                            <label className="form-check-label">
                                <input className="form-check-input" name="College" type={"radio"} value={0}
                                    defaultChecked={this.props.inCollege === 0} />No&nbsp;&nbsp;
                            </label>
                        </div>
                    </div>
                </div>
                {/* If Enrolled */}
                {(this.props.inCollege === 1) &&
                    <div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="form-group form-group-sm">
                                    <input type={"text"} className="form-control my-1" name="driverSchoolName" placeholder="School Name" maxLength={100}
                                        value={this.props.schoolName}
                                        onChange={(e) => { this.props.handleCallBack("schoolName", e.target.value) }} />
                                </div>
                                <div className="row form-group form-group-sm">
                                    <div className="col-xl-8">
                                        <input type={"text"} className="form-control my-1" name="driverSchoolCity" placeholder="City" maxLength={50}
                                            value={this.props.schoolCity}
                                            onChange={(e) => { this.props.handleCallBack("schoolCity", e.target.value) }} />
                                    </div>
                                    <div className="col-xl-4">
                                        <select className="btn btn-group btn-default my-1 dropdown-toggle filter-option minimal"
                                            defaultValue={this.props.schoolState}
                                            onChange={(e) => { this.props.handleCallBack("schoolState", e.target.value) }}>
                                            {this.state.map((item, i) => (
                                                <option key={i} value={item}>
                                                    {item}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row form-group form-group-sm">
                            <div className="hidden-xs col-sm-12">
                                <hr className="less-margin" />
                            </div>
                            <div className="col-xl-8 fw-bold text-blue">
                                Is any vehicle on this policy kept at school?
                            </div>
                            <div className="col-xl-4 mt-2" onChange={(e) => this.props.handleCallBack("vehicleSchool", parseInt(e.target.value))}>
                                <div className="radio-inline form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" name="007" type={"radio"} value={1}
                                            defaultChecked={this.props.vehicleSchool === 1} />Yes
                                    </label>
                                </div>
                                <div className="radio-inline form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" name="007" type={"radio"} value={0}
                                            defaultChecked={this.props.vehicleSchool === 0} />No
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* If school is over 100 miles */}
                        {(this.props.vehicleSchool === 0) &&
                            <div className="row form-group form-group-sm">
                                <div className="hidden-xs col-sm-12">
                                    <hr className="less-margin" />
                                </div>
                                <div className="col-xl-8 fw-bold text-blue">
                                    Is the school over 100 miles from your address?
                                </div>
                                <div className="col-xl-4 mt-2" onChange={(e) => { this.props.handleCallBack("over100", parseInt(e.target.value)) }}>
                                    <div className="radio-inline form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input className="form-check-input" name="008" type={"radio"} value={1}
                                                defaultChecked={this.props.over100 === 1} />Yes
                                        </label>
                                    </div>
                                    <div className="radio-inline form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input className="form-check-input" name="008" type={"radio"} value={0}
                                                defaultChecked={this.props.over100 === 0} />No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

export default College