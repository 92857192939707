import { useState } from "react";
import { Link } from "react-router-dom";

const styles = {
    indent: {
        marginLeft: "15px"
    },
    blueText: {
        color: "#046ab0",
        fontSize: '13.5px'
    }
}

const VehicleDA_Policy = () => {
    const [policyDriver, setPolicyDriver] = useState("")
    return (
        <>
            <div style={styles.indent} className={(policyDriver === "No") ? "d-none" : "d-block"}>
                <hr />
                <div className="mb-1">
                    <strong style={styles.blueText}>
                        Driver is not listed on my policy. I need to add a driver.
                    </strong>
                </div>
                <div className="mb-1">
                    <Link to={"/update-drivers/update-household"} type="button" className="btn btn-success yes" value={"Yes"}
                        style={{ background: "linear-gradient(#88c149, #73a839, #699934)" }}>
                        Yes
                    </Link>
                    <button type="button" className="btn btn-default mx-1" value={"No"} onClick={(e) => setPolicyDriver(e.target.value)}>
                        No
                    </button>
                </div>
            </div>
        </>
    )
}

export default VehicleDA_Policy