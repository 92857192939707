import Images from 'assets/images/Images'
import React from 'react'
import "./BlockPage.css"

const EndorsementInfoNeeded = () => {
  // detect if the device is mobile
  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  // render the phone number as a clickable link on mobile
  const renderPhoneNumber = () => {
    if (isMobile()) {
      return (
        <a href="tel:800-535-2873">800-535-2873</a>
      );
    } else {
      return (
        <span>800-535-2873</span>
      );
    }
  }

  return (
    <>
      <p className='mt-2 text-center text-primary fw-bold blockInfo'>
        Before finalizing your request, additional information is needed. Please call{' '}
        {renderPhoneNumber()}{' '}
        at your convenience to speak with a licensed CURE representative.
      </p>

      <div className="d-flex justify-content-center">
        <img src={Images.roundyBlueSmiling} alt="Roundy" height={200} />
      </div>

      <p className='text-center fw-bold blockInfo'>
        We look forward to speaking with you!
      </p>
    </>
  )
}

export default EndorsementInfoNeeded;
