import { useContext } from "react"
import ValidationModal from "components/ValidationModal"
import Summary_PhoneNumber from "components/ChangeRequestSharedComp/Summary_PhoneNumber"
import { AddressChangeContext } from "../AddressChangeContext"
import AddressChangeBtnNavigation from "../AddressChangeBtnNavigation"
import AddressSummary_Changes from "./AddressSummary_Changes"

const AddressSummaryMain = () => {
    const context = useContext(AddressChangeContext)

    return (
        <>
            <div className="row text-mute">
                <div className="col-12">
                    <AddressSummary_Changes />
                    <Summary_PhoneNumber changeReqContext={AddressChangeContext} reqChangeClass={"addressChangeClass"} setChangeClass={context.setAddressChangeClass} />
                </div>
            </div>

            <AddressChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default AddressSummaryMain