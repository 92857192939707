const EditDriverValidation = (props) => {
    if (props.editFields?.editDriver == "") {
        props.handleShow()
        props.handleValidationMsg("Please select what you would like to edit about this driver.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer drives policy vehicles." && props.editFields?.editDriverNested == "") {
        props.handleShow()
        props.handleValidationMsg("Please select a reason why the driver no longer operates the policy vehicles.", "Oops!")
        return false
    }
    else if (props.editFields?.editDriver == "No longer has a valid driver's license." && props.editFields?.editDriverNested == "") {
        props.handleShow()
        props.handleValidationMsg("Please select a reason why the driver no longer operates the policy vehicles.", "Oops!")
        return false
    }
    else {
        return true
    }
}

export default EditDriverValidation