import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './TooltipModal.css';

const TooltipModal = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                centered
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Modal.Dialog
                    style={{
                        margin: '0',
                        width: '100%',
                        maxWidth: '500px',
                        border: 'none'
                    }}
                >
                    <Modal.Header closeButton style={{ backgroundColor: "#366898" }}>
                        <Modal.Title className='text-light' style={{fontSize: "16px"}}>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='small'>{props.msg}</Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-primary-grad' onClick={props.handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
        </>
    );
}
export default TooltipModal