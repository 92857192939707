import React, { useContext } from "react"
import { HouseholdChangeContext } from "../HouseholdChangeContext"
import ValidationModal from "components/ValidationModal"
import HouseholdChangeBtnNavigation from "../HouseholdChangeBtnNavigation"
import Additional_DriverStatuses from "components/ChangeRequestSharedComp/AdditionalPage/Additional_DriverStatuses"

export const HouseholdDA_StylesContext = React.createContext()

const HouseholdAdditional_Main = () => {
    const context = useContext(HouseholdChangeContext)

    const styles = {
        indent: {
            paddingLeft: "15px",
        },
        blueText: {
            color: "#046ab0",
            fontSize: '13.5px'
        }
    }
    
    return (
        <>
            <HouseholdDA_StylesContext.Provider
                value={{ styles }}>
                <div className="row text-muted">
                    <div className="col-12">
                        <Additional_DriverStatuses changeReqContext={HouseholdChangeContext} reqChangeClass={"householdChangeClass"} setChangeClass={context.setHouseholdChangeClass} />
                    </div>
                </div>
            </HouseholdDA_StylesContext.Provider>

            <HouseholdChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default HouseholdAdditional_Main