import MaintenanceCheck from 'components/MaintenanceCheck'
import React from 'react'
import { storageData } from 'services/Services';

const Maintenance = (props) => {
    const websiteMaintenance = JSON.parse(storageData?.getItem("websiteMaintenance"));

    return (
        <div className='text-center p-1' style={{ color: '#284c7c', marginTop: '100px' }}>
            <h3 className='fw-bold'>Thank you for choosing CURE Auto Insurance.</h3>

            <MaintenanceCheck websiteMsg={websiteMaintenance?.message} />
        </div>
    )
}

export default Maintenance