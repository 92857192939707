import { useEffect, useState } from "react"
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { storageData } from "../../../services/Services";
import VehiclesOnPolicy from "./VehiclesOnPolicy";
import VehicleQuestions from "./VehicleQuestions.";
import ValidationModal from "../../ValidationModal";
import ValidationVehicle from "./ValidationVehicle";

const VehicleInformation_Main = () => {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [sessionData, setSessionData] = useState({})
    const [userData, setUserData] = useState({})
    const [show, setShow] = useState(false)
    const [validationTitle, setValidationTitle] = useState("")
    const [validationMsg, setValidationMsg] = useState("")
    const [validationLoad, setValidationLoad] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Retrieves userData
        var storedUserData = storageData.getItem('userData')
        if (storedUserData) {
            storedUserData = JSON.parse(storedUserData)
            setUserData(storedUserData)
        }

        // Retrieves sessionData
        var storedSessionDataRQ = storageData.getItem('sessionDataRQ')
        if (storedSessionDataRQ) {
            storedSessionDataRQ = JSON.parse(storedSessionDataRQ)

            // Retrieves vehicles on policy
            if (storedSessionDataRQ?.vehicles?.vehiclesOnPolicy?.length == 0) {
                var storedVehicles = storageData.getItem("storedVehicles")
                if (storedVehicles && storedVehicles.length !== 0) {
                    storedVehicles = JSON.parse(storedVehicles)
                    storedSessionDataRQ.vehicles.vehiclesOnPolicy = storedVehicles
                }
            }
            setSessionData({ ...storedSessionDataRQ })
        }

    }, [])

    const navigateToPage = (button) => {
        if (button == "back") {
            storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
            navigate("/renewalQuestionnaire/additionalInfo")
        }
        else {
            var check = ValidationVehicle({ sessionData, setValidationMsg, handleShow, setValidationLoad, setValidationTitle, setLoad })
            if (check) {
                storageData.setItem("sessionDataRQ", JSON.stringify(sessionData))
                navigate("/renewalQuestionnaire/sign")
            }
        }
    }
    return (
        <>
            <ValidationModal
                show={show}
                handleClose={handleClose}
                validationMsg={validationMsg}
                title={validationTitle}
            />

            <VehiclesOnPolicy
                userData={userData}
                sessionData={sessionData}
            />

            <VehicleQuestions
                userData={userData}
                sessionData={sessionData}
                setSessionData={setSessionData}
                address={(sessionData?.currentResidence?.newResidence?.streetAddress1 != "") ? sessionData?.currentResidence?.newResidence : sessionData?.currentResidence}
                setShow={setShow}
                setValidationMsg={setValidationMsg}
                handleClose={handleClose}
                handleShow={handleShow}
                setValidationLoad={setValidationLoad}
                setValidationTitle={setValidationTitle}
            />

            {/* Next Button */}
            <div>
                <div className="pb-4 my-4">
                    {!load &&
                        <>
                            <input type="submit" value={""} className="backButton" onClick={() => navigateToPage("back")} />
                            <input type="submit" value={""} className="nextButton" onClick={() => navigateToPage("next")} />
                        </>
                    }
                    {load &&
                        <Spinner className='ms-3' animation="border" />
                    }
                </div>
            </div>
        </>
    )
}

export default VehicleInformation_Main