import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { storageData } from "../services/Services";
import { getDomain } from "../services/Utils";

const Login = (props) => {
    const [userData, setUserData] = useState(storageData.getItem("userData") !== null ? JSON.parse(storageData.getItem("userData")) : { complete: false })
    const [paymentData, setPaymentData] = useState(storageData.getItem("paymentData") !== null ? JSON.parse(storageData.getItem("paymentData")) : { complete: false })
    const navigate = useNavigate();
    if (storageData.getItem("userData")) {        
        navigate("/home", {user:{ userData, setUserData }, payment:{ paymentData, setPaymentData } });
    }

    window.location.href = `${getDomain()}/my-account`;
    return (
        <>          
        </>
    );
};

export default Login;