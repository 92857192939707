import React from "react";
import { storageData } from "../../../services/Services";
import PhoneNumberInput from "./PhoneNumber";

class Changes extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        vehiclesPulled: [],
    }

    displayRemovalReason(reason) {
        // For display purpose
        if (reason == "NotRunning") {
            return "Not Running";                      
        }
        else if (reason == "OtherPolicy") {
            return "Other Policy";                      
        }
        else {
            return reason;                          
        }
    }

    componentDidMount() {
        // Set Default Value
        var phoneNum = { "Phone Number": "" }
        this.props.setStoredData(phoneNum)

        // Gets vehicles
        var storedVehicles = storageData.getItem('dbVehicles');
        var addedVehicles = storageData.getItem("newVehicles");
        var vehiclesArr = [];

        // For both stored and added vehicles
        if (storedVehicles && storedVehicles !== "[]" && addedVehicles && addedVehicles !== "[]") {
            var combinedVehicles = [...JSON.parse(storedVehicles), ...JSON.parse(addedVehicles)]
            for (var i = 0; i < combinedVehicles.length; i++) {
                var objVehicleInfo = new Object;
                // NJ/PA
                if (this.props.userData.state === "New Jersey" || this.props.userData.state === "Pennsylvania") {
                    // Replaced Vehicle (NJ/PA)
                    if ("replacementVehicle" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "replaced";
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].replacementVehicle.removalExplanation;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].replacementVehicle.vehicleYear} ${combinedVehicles[i].replacementVehicle.vehicleMake} ${combinedVehicles[i].replacementVehicle.vehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";                        
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].replacementVehicle.removalReason)
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Added Vehicle (NJ/PA)
                    else if ("vehicleMake" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "new";
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].vehicleYear} ${combinedVehicles[i].vehicleMake} ${combinedVehicles[i].vehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Deleted Vehicle
                    else if (combinedVehicles[i].removalReason !== "") {
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["vehicleLevel"] = "removed";
                        objVehicleInfo["status"] = combinedVehicles[i].removalReason;
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
                // Michigan
                else if (this.props.userData.state === "Michigan") {
                    // Modified Vehicle (MI)
                    if (combinedVehicles[i].status === "MODIFIED") {
                        objVehicleInfo["vehicleLevel"] = "modified";
                        objVehicleInfo["modifyMichiganReasonForChange"] = combinedVehicles[i].modifyMichiganReasonForChange;
                        objVehicleInfo["modifyMichiganRequestedEffectiveDate"] = combinedVehicles[i].modifyMichiganRequestedEffectiveDate;
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["status"] = "Modified";
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Replaced Vehicle (MI)
                    else if ("Make" in combinedVehicles[i] && "michiganVehicleMake" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "replaced";
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].michiganVehicleYear} ${combinedVehicles[i].michiganVehicleMake} ${combinedVehicles[i].michiganVehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Added Vehicle (MI)
                    else if ("michiganVehicleMake" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "new";
                        objVehicleInfo["removalReason"] = "";
                        objVehicleInfo["removalExplanation"] = "";
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].michiganVehicleYear} ${combinedVehicles[i].michiganVehicleMake} ${combinedVehicles[i].michiganVehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Deleted Vehicle
                    else if (combinedVehicles[i].removalReason !== "") {
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["vehicleLevel"] = "removed";
                        objVehicleInfo["status"] = combinedVehicles[i].removalReason;
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
            }
            this.setState({ vehiclesPulled: vehiclesArr })
        }
        // Only for added vehicles
        else if (addedVehicles && addedVehicles !== "[]") {
            var combinedVehicles = JSON.parse(addedVehicles)
            for (var i = 0; i < combinedVehicles.length; i++) {
                console.log("all vehicles --------->", combinedVehicles[i])
                var objVehicleInfo = new Object;

                // NJ/PA
                if (this.props.userData.state === "New Jersey" || this.props.userData.state === "Pennsylvania") {
                    if ("vehicleMake" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "new";
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].vehicleYear} ${combinedVehicles[i].vehicleMake} ${combinedVehicles[i].vehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
                // Michigan
                else if (this.props.userData.state === "Michigan") {
                    if ("michiganVehicleMake" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "new";
                        objVehicleInfo["removalReason"] = "";
                        objVehicleInfo["removalExplanation"] = "";
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].michiganVehicleYear} ${combinedVehicles[i].michiganVehicleMake} ${combinedVehicles[i].michiganVehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
                // Removed Vehicle
                if (combinedVehicles[i].removalReason !== "") {
                    objVehicleInfo["vehicleLevel"] = "removed";
                    objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                    objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                    objVehicleInfo["status"] = combinedVehicles[i].removalReason;
                    objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                    vehiclesArr.push(objVehicleInfo)
                }
            }
            this.setState({ vehiclesPulled: vehiclesArr })
        }
        // Only for stored vehicles
        else if (storedVehicles && storedVehicles !== "[]") {
            var combinedVehicles = JSON.parse(storedVehicles);
            for (var i = 0; i < combinedVehicles.length; i++) {
                console.log("all vehicles --------->", combinedVehicles[i])
                var objVehicleInfo = new Object;
                // NJ/PA
                if (this.props.userData.state === "New Jersey" || this.props.userData.state === "Pennsylvania") {
                    if ("replacementVehicle" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "replaced";
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].replacementVehicle.removalReason)
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].replacementVehicle.removalExplanation;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].replacementVehicle.vehicleYear} ${combinedVehicles[i].replacementVehicle.vehicleMake} ${combinedVehicles[i].replacementVehicle.vehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
                // Michigan
                else if (this.props.userData.state === "Michigan") {
                    // Replaced Vehicle (MI)
                    if ("Make" in combinedVehicles[i] && "michiganVehicleMake" in combinedVehicles[i]) {
                        console.log('1', combinedVehicles[i])
                        objVehicleInfo["vehicleLevel"] = "replaced";
                        objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                        objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["newVehicle"] = `${combinedVehicles[i].michiganVehicleYear} ${combinedVehicles[i].michiganVehicleMake} ${combinedVehicles[i].michiganVehicleModel}`;
                        objVehicleInfo["status"] = "New Vehicle";
                        vehiclesArr.push(objVehicleInfo)
                    }
                    // Modified Vehicle (MI)
                    else if ("modifyMichiganReasonForChange" in combinedVehicles[i]) {
                        objVehicleInfo["vehicleLevel"] = "modified";
                        objVehicleInfo["modifyMichiganReasonForChange"] = combinedVehicles[i].modifyMichiganReasonForChange;
                        objVehicleInfo["modifyMichiganRequestedEffectiveDate"] = combinedVehicles[i].modifyMichiganRequestedEffectiveDate;
                        objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                        objVehicleInfo["status"] = "Modified";
                        vehiclesArr.push(objVehicleInfo)
                    }
                }
                // Removed Vehicle
                if (combinedVehicles[i].removalReason !== "" && !("michiganVehicleMake" in combinedVehicles[i])) {
                    objVehicleInfo["vehicleLevel"] = "removed";
                    objVehicleInfo["oldVehicle"] = `${combinedVehicles[i].Year} ${combinedVehicles[i].Make} ${combinedVehicles[i].Model}`;
                    objVehicleInfo["removalReason"] = this.displayRemovalReason(combinedVehicles[i].removalReason)
                    objVehicleInfo["removalExplanation"] = combinedVehicles[i].removalExplanation;
                    objVehicleInfo["status"] = combinedVehicles[i].removalReason;
                    vehiclesArr.push(objVehicleInfo)
                }
            }
            this.setState({ vehiclesPulled: vehiclesArr })
        }
    }
    render() {
        return (
            <>
                <div className="mt-3">
                    <h6 className="text-primary fw-bold col-7">SUMMARY OF VEHICLE CHANGES</h6>
                    <p className="small col-sm-8 col-6">
                        Please review your changes and click Confirm below.
                        All policy changes are subject to Underwriting review and approval.
                    </p>
                </div>
                <div>
                    {/* Changes of vehicles */}
                    <div className="form-group-sm">
                        {this.state.vehiclesPulled.map((vehicle, index) => {
                            return (
                                <>
                                    {(vehicle.vehicleLevel === "replaced") &&
                                        <div>
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>{vehicle.oldVehicle}</strong>
                                                <em className="small" style={{ color: '#555555' }}> — {(vehicle.removalExplanation === undefined || vehicle.removalExplanation === "") ? vehicle.removalReason : vehicle.removalExplanation}</em>
                                            </div>
                                            <div className="my-2">
                                                <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>{vehicle.newVehicle}</strong>
                                                <em className="small" style={{ color: '#555555' }}> — {vehicle.status}</em>
                                            </div>
                                        </div>
                                    }
                                    {(vehicle.vehicleLevel === "removed") &&
                                        <div className="my-2">
                                            <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>{vehicle.oldVehicle}</strong>
                                            <em className="small" style={{ color: '#555555' }}> — {(vehicle.removalExplanation === undefined || vehicle.removalExplanation === "") ? vehicle.removalReason : vehicle.removalExplanation}</em>
                                        </div>
                                    }
                                    {(vehicle.vehicleLevel === "new") &&
                                        <div className="my-2">
                                            <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>{vehicle.newVehicle}</strong>
                                            <em className="small" style={{ color: '#555555' }}> — {vehicle.status}</em>
                                        </div>
                                    }
                                    {(vehicle.vehicleLevel === "modified") &&
                                        <div className="my-2">
                                            <strong className="text-primary text-uppercase" style={{ fontSize: '14px' }}>{vehicle.oldVehicle}</strong>
                                            <em className="small" style={{ color: '#555555' }}> — {vehicle.status}</em>
                                        </div>
                                    }
                                </>
                            )
                        })
                        }
                    </div>
                </div>
                <p className="text-center small mt-3">Provide your current phone number.</p>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                        <PhoneNumberInput
                            storedData={{ ...this.props.storedData }}
                            setStoredData={this.props.setStoredData}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Changes