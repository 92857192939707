import { useContext, useEffect, useRef } from "react";

const Summary_PhoneNumber = ({ changeReqContext, reqChangeClass, setChangeClass }) => {
    const context = useContext(changeReqContext)
    const phoneNumberRef = useRef(null)

    useEffect(() => {
        const desktopThresholdWidth = 768;

        if (window.innerWidth > desktopThresholdWidth) {
            phoneNumberRef.current?.focus();
        }
    }, []);

    // Formats the input and sets value to the change class
    const handleInput = (e) => {
        const formattedPhoneNum = formatPhoneNum(e.target.value);
        phoneNumberRef.current.value = formattedPhoneNum
        storePhoneNum(phoneNumberRef.current.value)
    };

    // Sets the class to the phone number
    const storePhoneNum = (value) => {
        var changeClass = context[reqChangeClass]
        var summaryCopy = changeClass?.summary
        summaryCopy.phoneNumber = phoneNumberRef.current.value
        changeClass.summary = summaryCopy
        setChangeClass({ ...changeClass })
    }

    // Formats the phone number
    const formatPhoneNum = (value) => {
        if (!value) { return value; }
        const phoneNum = value.replace(/[^\d]/g, "");

        if (phoneNum.length < 4) {
            return phoneNum;
        }
        if (phoneNum.length < 7) {
            return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
        }
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
    }

    return (
        <>
            {reqChangeClass == "addressChangeClass" ?
                <p className="text-center text-primary fw-bold mt-3">Provide your current phone number.*</p>
                :
                <p className="text-center small mt-3">Provide your current phone number.</p>
            }
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                    <input className="form-control text-center" onChange={(e) => { handleInput(e) }} ref={phoneNumberRef} value={context[reqChangeClass]?.summary?.phoneNumber} />
                </div>
            </div></>
    )
}

export default Summary_PhoneNumber