import { useState } from "react"
import StateDropdown from "./StateDropdown"
import LicenseStatusDropdownHouseholdMember from "./LicenseStatusDropdownHouseholdMember"
import { storageData } from "../../../services/Services"
import ArraysDOB from "./ArraysDOB"
import ValidationHouseholdMember from "./ValidationHouseholdMember"

const AddAdditionalHouseholdMember = (props) => {
    const mStatus = ["Single (never been married)", "Married", "Widowed", "Divorced", "Separated", "Civil Union"]
    const [days, setDays] = useState(ArraysDOB.generateArrayOfDays)
    const [years, setYears] = useState(ArraysDOB.generateArrayOfYearsHouseholdMember)
    const [newHouseholdMember, setNewHouseholdMember] = useState(
        (props.index >= props.newHouseholdMembers.length) ?
            {
                fullName: "",
                dobMonth: "",
                dobDay: "",
                dobYear: "",
                DOB: "",
                age: "",
                mStatus: "",
                gender: "",
                validLicense: "",
                licenseStatus: "",
                otherLicenseState: "",
                otherStateLicenseNumber: "",
                maintainAutoInsurance: ""
            } :
            {
                ...props.newHouseholdMembers[props.index]
            })

    // Checks age for toggle
    const checksAgeToggle = (dob) => {
        var ageDiff = new Date(Date.now() - dob.getTime())
        return Math.abs(ageDiff.getUTCFullYear() - 1970)
    }

    const updateSessionData = (key, value) => {
        var newHouseholdMemberCopy = newHouseholdMember
        newHouseholdMemberCopy[key] = value

        // Adds DOB
        if (newHouseholdMemberCopy.dobMonth && newHouseholdMemberCopy.dobDay && newHouseholdMemberCopy.dobYear) {
            var dob = (newHouseholdMemberCopy.dobMonth + "/" + newHouseholdMemberCopy.dobDay + "/" + newHouseholdMemberCopy.dobYear)
            newHouseholdMemberCopy["age"] = checksAgeToggle(new Date(dob))
            newHouseholdMemberCopy["DOB"] = dob
        }
        setNewHouseholdMember({ ...newHouseholdMemberCopy })
    }

    const saveNewHouseholdMember = async (member, index, action) => {
        // Checks Validation
        var validationResponse = await ValidationHouseholdMember({
            newHouseholdMember,
            setShow: props.setShow,
            setValidationMsg: props.setValidationMsg,
            handleClose: props.handleClose,
            handleShow: props.handleShow,
            setValidationLoad: props.setValidationLoad,
            setValidationTitle: props.setValidationTitle
        })
        // Clears validation
        if (validationResponse) {
            var sessionDataCopy = props.sessionData
            if (action == "add") {
                sessionDataCopy.household.householdMembers.newHouseholdMembers.push(newHouseholdMember)
                props.setAddHouseholdMemberMenu(false)
            }
            else if (action == "edit") {
                sessionDataCopy.household.householdMembers.newHouseholdMembers[props.index] = newHouseholdMember
                props.setNewHouseholdMemberMenu([])
            }
            props.setSessionData(sessionDataCopy)
            storageData.setItem("sessionDataRQ", JSON.stringify(props.sessionData))
        }
    }

    return (
        <>
            <div className="mt-4">
                {/* Full Name */}
                <div className="row">
                    <div>
                        <input type="text" className="textFullName ms-2" value={newHouseholdMember.fullName} placeholder="Full Name" maxLength={150} onChange={(e) => updateSessionData("fullName", e.target.value)} />
                    </div>
                </div>

                {/* DOB */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Date of Birth:</p>
                    </div>

                    {/* Month */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newHouseholdMember.dobMonth} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobMonth", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Month</option>
                            {ArraysDOB.month.map((month, id) => {
                                return (
                                    <option value={month.id} key={id}>{month.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    {/* Day */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newHouseholdMember.dobDay} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobDay", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Day</option>
                            {days.map((day, id) => {
                                return (
                                    <option value={day} key={id}>{day}</option>
                                )
                            })}
                        </select>
                    </div>

                    {/* Year */}
                    <div className="col-12 col-md-3">
                        <select defaultValue={newHouseholdMember.dobYear} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("dobYear", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Year</option>
                            {years.map((year, id) => {
                                return (
                                    <option value={year} key={id}>{year}</option>
                                )
                            })}
                        </select>
                    </div><br />
                </div>

                {/* Marital Status */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Marital Status:</p>
                    </div>

                    <div className="col-12 col-md-8">
                        <select defaultValue={newHouseholdMember.mStatus} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("mStatus", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Marital Status</option>
                            {mStatus.map((status, id) => {
                                return (
                                    <option value={status} key={id}>{status}</option>
                                )
                            })}
                        </select>
                    </div><br />
                </div>


                {/* Gender */}
                <div className="mt-3 row">
                    <div className="col-12 col-md-3">
                        <p className="px-2 sameHouseholdLabel" style={{ fontSize: '12px' }}>Gender:</p>
                    </div>

                    <div className="col-12 col-md-8">
                        <select defaultValue={newHouseholdMember.gender} className="form-select-sm textDOB dobSelect px-0 col-12" onChange={(e) => updateSessionData("gender", e.target.value)} style={{ backgroundColor: 'white' }}>
                            <option value={''} disabled>Gender</option>
                            <option value={'Female'}>Female</option>
                            <option value={'Male'}>Male</option>
                        </select>
                    </div><br />
                </div>

                {/* Valid State License */}
                {(checksAgeToggle(new Date(newHouseholdMember.DOB)) >= 16) &&
                    <>
                        <div>
                            <div className="mt-3 row">
                                <div>
                                    <label className="sameHouseholdLabel px-2">
                                        <span>
                                            Does this household member have a valid {props.userData.state} driver's license?
                                        </span>
                                        <span className="radiotip">
                                            <a>
                                                <i className="fa fa-info-circle ms-2" onClick={() => {
                                                    props.handleShow();
                                                    props.setValidationTitle("Invalid License Notice")
                                                    props.setValidationMsg(`You must have a valid driver's license to be considered a licensed household member. 
                                                    This is a license that has been issued to you by the state, is not expired, suspended, or revoked, 
                                                    and includes all driving rights and privileges.`)
                                                }}></i>
                                            </a>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div className="mt-3 row">
                                <div className="p-0">
                                    <div className="radio-inline">
                                        <label className="form-check-label" onChange={(e) => updateSessionData("validLicense", e.target.value)}>
                                            <input className="form-check-input m-0" defaultChecked={newHouseholdMember.validLicense == "Yes"} style={{ verticalAlign: 'middle' }} name="rqValidLicenseHHM" type={"radio"} value={"Yes"} />
                                            <span className="ms-2">Yes</span>
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="form-check-label" onChange={(e) => updateSessionData("validLicense", e.target.value)}>
                                            <input className="form-check-input m-0" defaultChecked={newHouseholdMember.validLicense == "No"} style={{ verticalAlign: 'middle' }} name="rqValidLicenseHHM" type={"radio"} value={"No"} />
                                            <span className="ms-2">No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div><br />

                        {/* Status of Driver License */}
                        {(newHouseholdMember.validLicense != "" && newHouseholdMember.validLicense == "No") &&
                            <div className="row">
                                <div>
                                    <select defaultValue={newHouseholdMember.licenseStatus} className="col-8 px-0 ms-2 form-select-sm dobSelect textResidence" onChange={(e) => updateSessionData("licenseStatus", e.target.value)} style={{ backgroundColor: 'white' }}>
                                        <option value="" disabled>What is the current status of this driver's license?</option>
                                        {LicenseStatusDropdownHouseholdMember()}
                                    </select>
                                </div>
                            </div>
                        }

                        {/* Other State License */}
                        {(newHouseholdMember.validLicense == "No" && newHouseholdMember.licenseStatus == "Valid Other State License") &&
                            <div className="mt-3 row ps-5">
                                <div>
                                    <label className="sameHouseholdLabel">
                                        <span>
                                            Please provide the information for this household member's out of state license.
                                        </span>
                                    </label>
                                </div>

                                {/* Other License State */}
                                <div className="mt-3 row">
                                    <div className="col-12 col-md-3">
                                        <p className="">License State:</p>
                                    </div>

                                    <div className="col-12 col-md-9">
                                        <select defaultValue={newHouseholdMember.otherLicenseState} className="form-select-sm textDOB dobSelect px-0 col-md-4 col-7" onChange={(e) => updateSessionData("otherLicenseState", e.target.value)} style={{ backgroundColor: 'white' }}>
                                            <option value={''}>State</option>
                                            {StateDropdown()}
                                        </select>
                                    </div>
                                </div><br />

                                {/* Other License Number */}
                                <div className="mt-2 row">
                                    <div className="col-12 col-md-3">
                                        <p className="">License Number:</p>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <input type="text" className="textFullName" value={newHouseholdMember.otherStateLicenseNumber} placeholder="License Number" maxLength={50} onChange={(e) => updateSessionData("otherStateLicenseNumber", e.target.value)} />
                                        <div className="important-note">(Not required right now, but needed in next 30 days)</div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Maintain Auto Insurance */}
                        <div className="mt-3 row">
                            <div>
                                <label className="sameHouseholdLabel px-2">
                                    <span>
                                        Does this household member maintain his/her own auto insurance?
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="mt-3 row">
                            <div className="p-0">
                                <div className="radio-inline">
                                    <label className="form-check-label" onChange={(e) => updateSessionData("maintainAutoInsurance", e.target.value)}>
                                        <input className="form-check-input m-0" defaultChecked={newHouseholdMember.maintainAutoInsurance == "Yes"} style={{ verticalAlign: 'middle' }} name="rqPersonalInsurance" type={"radio"} value={"Yes"} />
                                        <span className="ms-2">Yes</span>
                                    </label>
                                </div>
                                <div className="radio-inline">
                                    <label className="form-check-label" onChange={(e) => updateSessionData("maintainAutoInsurance", e.target.value)}>
                                        <input className="form-check-input m-0" defaultChecked={newHouseholdMember.maintainAutoInsurance == "No"} style={{ verticalAlign: 'middle' }} name="rqPersonalInsurance" type={"radio"} value={"No"} />
                                        <span className="ms-2">No</span>
                                    </label>
                                </div>
                                <div className="radio-inline">
                                    <label className="form-check-label" onChange={(e) => updateSessionData("maintainAutoInsurance", e.target.value)}>
                                        <input className="form-check-input m-0" defaultChecked={newHouseholdMember.maintainAutoInsurance == "Unknown"} style={{ verticalAlign: 'middle' }} name="rqPersonalInsurance" type={"radio"} value={"Unknown"} />
                                        <span className="ms-2">Unknown</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* Save/Cancel New Household Member */}
                <div className="row justify-content-end p-3">
                    <input type="submit" value={"Save"} className="saveButton" onClick={() => saveNewHouseholdMember(newHouseholdMember, props.index, (props.index == props.newHouseholdMembers.length) ? "add" : "edit")} />
                    <input type="submit" value={"Cancel"} className="cancelButton ms-3" onClick={() => { (props.index == props.newHouseholdMembers.length) ? props.setAddHouseholdMemberMenu(false) : props.setNewHouseholdMemberMenu([]) }} />
                </div>
            </div>
        </>
    )
}

export default AddAdditionalHouseholdMember