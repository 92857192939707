const SecurityAlarmDropdown = () => {
    return (
        <>
            <option value={"0"} disabled>Type of security system/alarm *</option>
            <option value={"1500"}>None</option>
            <option value={"1510"}>Active Alarm</option>
            <option value={"1520"}>Passive Alarm</option>
            <option value={"1530"}>Recovery Device</option>
            <option value={"1540"}>Passive Disabling Device/VIN Etching</option>
            <option value={"1545"}>Multiple Alarms</option>
        </>
    )
}

export default SecurityAlarmDropdown