const FormContainer = (props) => {
    var { Content, params, saveRQ } = props
    Content = (Content) ? props.Content : () => <></>


    return (
        <>
            <div className="container col-12 col-lg-8">
                <div className="container border shadow-sm rounded-3 border-2 mt-1" style={{ paddingTop: '12px' }}>
                    <div className="d-flex justify-content-center flex-column">
                        {/* Content */}
                        <Content data={params} setData={saveRQ} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormContainer