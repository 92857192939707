import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        
        // Logs the error and info 
        console.error(error);
        console.error(info);
    }

    render() {
        if (this.state.hasError) {

            // Alternative UI when an error occurs
            return (
                <div className='text-center p-3'>
                    <p>Something went wrong. Please try again later.</p>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
