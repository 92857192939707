import { storageData } from "services/Services"

const VehicleUseList = ({state}) => {
    let options = []
    try {
        const dropdownOptions = JSON.parse(storageData.getItem('DropdownOptions'))
        const useOptions = dropdownOptions.rows.filter(row => (state == "Michigan") ? row.QuestionID === "151" : row.QuestionID === "146")

        if (useOptions.length > 0) {
            options = [
                <option key="default" disabled value="">Select from the following...</option>,
                ...useOptions
                .filter(answer => answer.AnswerText !== "Farm")
                .map(answer => (
                    <option key={answer.AnswerID} value={answer.AnswerText}>{answer.AnswerText}</option>
                ))
            ]
        }
    } catch (error) {
        console.error(error)
        options = [<option key="default" value="">Select from the following...</option>]
    }

    return <>{options}</>
}

export default VehicleUseList