import React, { useContext } from "react"
import ValidationModal from "components/ValidationModal"
import Additional_DriverStatuses from "components/ChangeRequestSharedComp/AdditionalPage/Additional_DriverStatuses"
import { AddressChangeContext } from "../AddressChangeContext"
import AddressChangeBtnNavigation from "../AddressChangeBtnNavigation"

export const AddressDA_StylesContext = React.createContext()

const AddressAdditional_Main = () => {
    const context = useContext(AddressChangeContext)

    const styles = {
        indent: {
            paddingLeft: "15px",
        },
        blueText: {
            color: "#046ab0",
            fontSize: '13.5px'
        }
    }
    
    return (
        <>
            <AddressDA_StylesContext.Provider
                value={{ styles }}>
                <div className="row text-muted">
                    <div className="col-12">
                        <Additional_DriverStatuses changeReqContext={AddressChangeContext} reqChangeClass={"addressChangeClass"} setChangeClass={context.setAddressChangeClass} />
                    </div>
                </div>
            </AddressDA_StylesContext.Provider>

            <AddressChangeBtnNavigation />
            <ValidationModal
                title={context.validationTitle}
                show={context.showModal}
                handleClose={() => context.setShowModal(false)}
                validationMsg={context.validationMsg}
            />
        </>
    )
}

export default AddressAdditional_Main