import { getRenewalQuestionnairePDF, getRenewalQuestionnaires } from "../services/Api"
import { Link } from "react-router-dom"
import { storageData } from "../services/Services"
import { useEffect, useState } from "react"

const Renewal = (props) => {
    const [renewalData, setRenewalData] = useState(props?.userData?.renewalData)

    useEffect(() => {
        if (renewalData?.length != 0) {
            getRenewalQuestionnaireInfo(props?.userData?.policyNumber)
        }
    }, [])

    const getRenewalQuestionnaireInfo = async (policyNumber) => {
        var response = await getRenewalQuestionnaires(policyNumber)
        setRenewalData(response.result)
    }

    return (
        <>
            {renewalData?.length > 0 &&
                <div>
                    <small>
                        <div className="section-title">
                            RENEWAL QUESTIONNAIRE
                        </div>
                        <div className="d-flex justify-content-center">
                            <table className="container-fluid" >
                                <tbody>
                                    <tr>
                                        <th className="text-center">Effective Date</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                    {renewalData.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center">
                                                    <small>
                                                        {FormatDate(value.expirationDate)}
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    <small>
                                                        {new Date(value.expirationDate) < new Date() ?
                                                            FormatStatus(value)
                                                            :
                                                            value.renewalCompleted == "True" ?
                                                                FormatStatus(value)
                                                                :
                                                                value.inProgress == "1" ?
                                                                    FormatStatus(value)
                                                                    :
                                                                    <Link to="/renewalQuestionnaire/landing" onClick={() => storageData.setItem("renewalQuestionnaireID", value.renewalId)}>Open</Link>
                                                        }
                                                    </small>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </small>
                </div>
            }
        </>
    )
}

const FormatDate = (value) => {
    var tempDate = new Date(value)
    var dateMonth = `${(tempDate.getMonth() + 1) < 10 ? '0' : ''}${tempDate.getMonth() + 1}`
    var dateDay = `${(tempDate.getDate() + 1) < 10 ? '0' : ''}${tempDate.getDate()}`
    var dateYear = `${tempDate.getFullYear()}`
    const date = `${dateMonth}/${dateDay}/${dateYear}`
    return date
}

const FormatStatus = (value) => {
    var status = "Complete"
    if (value.renewalCompleted == "True") {
        status = "Completed"
        // <a download
        //     onClick={async () => {
        //         var a = document.createElement("a");
        //         a.href = `data:application/octet-stream;base64,${await getRenewalQuestionnairePDF(value)}`;
        //         a.setAttribute("download", "Renewal");
        //         a.click();
        //     }}
        // >Completed</a>

    } else {
        if ((!value.openedByRepUntil && (new Date(value.openedByRepUntil).getTime() >= Date.now))) {
            status = "Expired"
        }
        else if (value.inProgress == "1") {
            status = <Link to="/renewalQuestionnaire/landing" onClick={() => storageData.setItem("renewalQuestionnaireID", value.renewalId)}>Incomplete</Link>
        }
        else {
            status = <a download
                onClick={async () => {
                    var a = document.createElement("a");
                    a.href = `data:application/octet-stream;base64,${await getRenewalQuestionnairePDF(value)}`;
                    a.setAttribute("download", "Renewal");
                    a.click();
                }}
            >Completed</a>
        }

    }


    return status
}

export default Renewal;
