import React from "react";

class EditDriver extends React.Component {
    constructor(props) {
        super(props);
    }
    // Edit Toggle
    handleFirstEdit = (e) => {
        this.props.firstSet(e.target.value)
    }
    handleSecondEdit = (e) => {
        this.props.addedEdit(e.target.value)
    }
    render() {
        return(
            <>
                {/* Edit Form */}
                <div className="slide-toggle edit-section form-group">
                    <div className="editDriver">
                        <div className="text-blue fw-bold">
                            What would you like to edit about this person?
                        </div>
                    </div>
                    <div className="editDriver form-group form-group-sm">
                        {/* No Longer Drives */}
                        <div className="form-check form-check-inline col-12">
                            <label className="form-check-label">
                                <input className="form-check-input" type={"radio"} name="driverEdit0" value={"NODRIVE"} onClick={(e) => {
                                    this.handleFirstEdit(e);
                                    this.handleSecondEdit(e);
                                }}/>
                                No longer drives policy vehicles.
                            </label>
                        </div>
                        {/* Nested Set 1*/}
                        {(this.props.vlQuestion1 === "NODRIVE") && 
                            <div className="slide-toggle form-group nested-question" onChange={this.handleSecondEdit}>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type={"radio"} name="editNoDrive0" value={"MOVED"} />
                                        No longer resides in household and does not have access to the vehicles.
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type={"radio"} name="editNoDrive0" value={"INSURANCE"} />
                                        Obtained own car and own insurance.
                                    </label>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        }
                        {/* No License */}
                        <div className="form-check form-check-inline col-12">
                            <label className="form-check-label">
                                <input className="form-check-input" type={"radio"} name="driverEdit0" value={"NOTVALID"} onClick={this.handleFirstEdit}/>
                                No longer has a valid Driver's License.
                            </label>
                        </div>
                        {/* Nested Set 2 */}
                        {(this.props.vlQuestion1 === "NOTVALID") &&
                            <div className="slide-toggle form-group nested-question" onChange={this.handleSecondEdit}>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type={"radio"} name="editNotValid0" value={"SUSPENDED"} />
                                        License was Suspended.
                                    </label>
                                </div><br/>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type={"radio"} name="editNotValid0" value={"REVOKED"} />
                                        License was Revoked.
                                    </label>
                                </div>
                            </div>
                        }
                        <div className="clearfix"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default EditDriver