import React, { useContext, useState } from "react"
import { storageData } from "services/Services"
import { AddVehicleContext } from "./AddVehicleContext"
import { ownedLeasedFinancedValidator } from "../1_Vehicle/AddVehicleBody/5_VehicleLeasedFinanced/validator"

export const NewVehicleContext = React.createContext()

const useModal = () => {
    const [modalTitle, setModalTitle] = useState("");
    const [modalMsg, setModalMsg] = useState("");
    const [showModal, setShowModal] = useState(false);

    const openModal = (title, message) => {
        setModalTitle(title);
        setModalMsg(message);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return {
        modalTitle,
        modalMsg,
        showModal,
        openModal,
        closeModal,
    };
};

export const NewVehicleContextComp = ({ children }) => {
    const addVehicleContext = useContext(AddVehicleContext)
    // Fields for adding a vehicle
    const initialVehicle = {
        componentTracker: "",
        vehicleVIN: "",
        vehicleYear: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleBody: "",
        requestedCovEffDate: addVehicleContext.getTodaysDate(true),
        datePurchased: addVehicleContext.getTodaysDate(),
        vehicleOwnedLeasedFinanced: "",
        vehicleIsCoOwned: "",
        vehicleCoOwnerName: "",
        vehicleCoOwnerAddress: "",
        vehicleIsCoLeased: "",
        vehicleLeasingCompany: "",
        vehicleFinanceCompany: "",
        garaged: "",
        vehicleRegistrant: "",
        vehicleIsNJ: "",
        vehicleIsPA: "",
        vehicleUse: "",
        daysDrivenPerWeek: "5 days per week",
        estimatedMileage: "9,001-12,000",
        otherDeductible: "",
        collisionType: "",
        collisionDeductible: "",
        rentalCoverage: "",
        antiTheftSystem: "None",
        vehicleCustomization: "",
        vehicleCommercial: "",
        vehicleCommercialPurposes: "",
        leasedRentToOthers: "",
        vehiclePlowSnow: "",
        drivenExistingDriver: "",
        wantsOtherThanCollision: "",
        wantsCollision: ""
    }

    const [newVehicle, setNewVehicle] = useState(addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles.length < 1 ?
        initialVehicle :
        addVehicleContext.addVehicleClass.vehicles.vehiclesOnPolicy.newVehicles[0]
    );
    const currResidenceBill = storageData.getItem("currResidenceBill")
    const policyStreetAddress = JSON.parse(currResidenceBill).address1
    const modal = useModal()
    const validateVehicles_3 = () => {
        return ownedLeasedFinancedValidator.validateForm(newVehicle, modal)
    }
    // Retrieves Form Values
    const newVehCallback = (key, value) => {
        var vehicleCopy = newVehicle
        vehicleCopy[key] = value
        setNewVehicle({ ...newVehicle, ...vehicleCopy });
    }

    return (
        <NewVehicleContext.Provider
            value={{
                newVehicle, setNewVehicle,
                initialVehicle,
                newVehCallback,
                modal, policyStreetAddress,
                validateVehicles_3
            }}>
            {children}
        </NewVehicleContext.Provider>
    )
}