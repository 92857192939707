const ValidationDriver = async (props) => {
    console.log(props)
    
    // Edit Menu Validation
    if ("edit" in props) {
        if (props.edit.editType == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please select what you would like to edit about the driver.`)
            return false
        }
        else if (props.edit.editType == "NODRIVE" && props.edit.editReason == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please select a reason why the driver no longer operates the policy vehicles.`)
            return false
        }
        else if (props.edit.editType == "NOTVALID" && props.edit.editReason == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please select a reason why the driver's license is no longer valid.`)
            return false
        }
        else {
            return true
        }
    }
    // Add New Driver Validation
    else {
        // Name
        if (props?.newDriver?.fullName == "") {
            props.handleShow()
            props.setValidationLoad(true)
            props.setValidationTitle("Oops!")
            props.setValidationMsg(`Please enter the driver's name.`)
            return false
        }
        else {
            // Checks if first name is valid
            var containsNumerical = /\d/;
            if (containsNumerical.test(props.newDriver.fullName)) {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg("Name contains a numeric character. Please enter a valid Name.")
                return false
            }
            var valid = /^(([a-zA-Z'-.]{2,})+ ([a-zA-Z'-.]+){2,})$/;
            if (!valid.test(props.newDriver.fullName)) {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg("Please enter a valid Full Name. First Name and Last Name must have two or more characters.")
                return false
            }
            // DOB
            else if (props.newDriver.dobMonth == "" || props.newDriver.dobDay == "" || props.newDriver.dobYear == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please complete the driver's date of birth.`)
                return false
            }
            // Marital Status
            else if (props.newDriver.mStatus == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the driver's Marital Status.`)
                return false
            }
            // Gender
            else if (props.newDriver.gender == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the driver's Gender.`)
                return false
            }
            // Same Household
            else if (props.newDriver.sameHousehold == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify if this driver resides in same household.`)
                return false
            }
            else if (props.newDriver.sameHousehold == "No" && props.newDriver.driverResidenceAddress == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the address for the driver's current residence.`)
                return false
            }
            else if (props.newDriver.sameHousehold == "No" && props.newDriver.driverResidenceCity == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please enter the city where this driver currently resides.`)
                return false
            }
            else if (props.newDriver.sameHousehold == "No" && props.newDriver.driverResidenceState == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the state where this driver currently resides.`)
                return false
            }
            else if (props.newDriver.sameHousehold == "No" && props.newDriver.driverResidenceZip == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the zip code where this driver currently resides.`)
                return false
            }
            // Valid License
            else if (props.newDriver.validLicense == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify whether or not the driver has a valid license.`)
                return false
            }
            else if (props.newDriver.validLicense == "No" && props.newDriver.licenseStatus == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify the current status of the driver's license.`)
                return false
            }
            else if (props.newDriver.validLicense == "No" && props.newDriver.licenseStatus == "Permit" && props.newDriver.permitNumber == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the permit number.`)
                return false
            }
            else if (props.newDriver.validLicense == "No" && props.newDriver.licenseStatus == "Valid Other State License" && props.newDriver.otherLicenseState == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the state of the driver's license.`)
                return false
            }
            else if (props.newDriver.validLicense == "No" && props.newDriver.licenseStatus == "Valid Other State License" && props.newDriver.otherStateLicenseNumber == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please provide the license number of the other state's license.`)
                return false
            }
            // School Info
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify whether or not the driver is enrolled in a college or university.`)
                return false
            }
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "Yes" && props.newDriver.collegeName == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please enter the name of the school that the driver is currently attending.`)
                return false
            }
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "Yes" && props.newDriver.collegeCity == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please enter the city of the school that the driver is currently attending.`)
                return false
            }
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "Yes" && props.newDriver.collegeState == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please select the state of the school that the driver is currently attending.`)
                return false
            }
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "Yes" && props.newDriver.keptAtCollege == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify if this driver has a vehicle that is kept at school.`)
                return false
            }
            else if (props.newDriver.age <= 26 && props.newDriver.enrolledInCollege == "Yes" && props.newDriver.keptAtCollege == "No" && props.newDriver.over100Miles == "") {
                props.handleShow()
                props.setValidationLoad(true)
                props.setValidationTitle("Oops!")
                props.setValidationMsg(`Please specify if this driver attends a school that is over 100 miles from your address.`)
                return false
            }
            return true
        }
    }
}

export default ValidationDriver