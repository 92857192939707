import React from 'react'

const breadcrumb = {
    borderRadius: '0.37rem',
    "--bs-breadcrumb-divider": "'>'",
    textDecoration: 'none'
}

const AddVehicleProgress = (props) => {
    const navs =
        [
            '1. Vehicle',
            '2. Confirm',
            '3. Summary'
        ]

    const isCurrent = ((value) => {
        return value.slice(3) === props.screen;
    });

    return (
        <>
            <div className="clearfix"></div>

            {/* Vehicle Progress Bar */}
            <div className="col-12">
                <nav>
                    <ol className="breadcrumb justify-content-center" style={breadcrumb}>
                        {
                            navs.map((nav, navIndex) => {
                                const active = isCurrent(nav) ? 'fw-bold text-blue' : 'disabled';
                                return (
                                    <li key={navIndex} className="breadcrumb-item align-items-center d-flex">
                                        <button className={`btn ${active}`}>
                                            {nav}
                                        </button>
                                    </li>
                                );
                            })
                        }
                    </ol>
                </nav>
            </div>

        </>
    )
}

export default AddVehicleProgress

